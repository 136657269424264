import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { SvgIcon } from '@material-ui/core';

import { Utils } from '../../src-core/resources/index';

import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class CreateTargetDoneOk extends Component {
  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className='container'>
        <Body>
          <SvgIcon
            color='primary'
            classes={transformClasses('CreateTargetDone_icon', classes)}
          >
            <path d='M18.5,10.6c-0.4,0-0.7,0.3-0.7,0.7V12c0,1.8-0.7,3.6-2,4.8c-1.3,1.3-3,2-4.8,2c0,0,0,0,0,0      c-3.8,0-6.9-3.1-6.9-6.9c0-1.8,0.7-3.6,2-4.8c1.3-1.3,3-2,4.8-2c0,0,0,0,0,0c1,0,1.9,0.2,2.8,0.6c0.4,0.2,0.8,0,0.9-0.4      c0.2-0.4,0-0.8-0.4-0.9C13.3,4,12.1,3.7,11,3.7c0,0,0,0,0,0c-2.2,0-4.3,0.9-5.8,2.4C3.6,7.7,2.7,9.8,2.7,12      c0,2.2,0.9,4.3,2.4,5.8c1.6,1.6,3.6,2.4,5.8,2.4c0,0,0,0,0,0c2.2,0,4.3-0.9,5.8-2.4c1.6-1.6,2.4-3.6,2.4-5.8v-0.7      C19.2,10.9,18.9,10.6,18.5,10.6z' />
            <path d='M19.8,4.7c-0.3-0.3-0.7-0.3-1,0L11,12.5l-1.8-1.8c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l2.3,2.3      c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l8.3-8.3C20,5.4,20,5,19.8,4.7z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('CreateTargetDone_typography', classes)}
          >
            ¡Has creado tu objetivo correctamente!
          </Typography>
          <Button
            color='secondary'
            variant='contained'
            classes={transformClasses('CreateTargetDone_button', classes)}
            onClick={() => this.props.history.push('/documents')}
            style={{
              maxWidth: 150,
            }}
          >
            Ver Contratos
          </Button>
        </Body>
        <Footer>
          <Button
            color='secondary'
            variant='contained'
            classes={transformClasses('CreateTargetDone_button', classes)}
            onClick={() => this.props.history.push('/home/resumen')}
          >
            Entendido
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = theme => ({
  CreateTargetDone_container: {
    minHeight: '640px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CreateTargetDone_typography_root: {
    fontSize: '45px',
    textAlign: 'center',
    fontWeight: 100,
    width: '274px',
  },
  CreateTargetDone_button_root: {
    width: '312px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  CreateTargetDone_button_label: {
    fontSize: '14px',
    fontWeight: 600,
  },
  CreateTargetDone_icon_root: {
    fontSize: '46px',
    //marginTop: '200px',
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetDoneOk);
