import { Search } from '@material-ui/icons';

/*
  Devuelve los objetivos de un usuario, targets
*/
export async function userTargets(baseUrl, idUser, token, allTargets = false) {
  return fetch(
    baseUrl +
      'users/' +
      idUser +
      '/targets' +
      (allTargets ? '?showExpired=true' : ''),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getTypes(baseUrl, idUser, token) {
  return fetch(baseUrl + 'users/' + idUser + '/targetTypes', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getTargets(baseUrl, idTarget, token) {
  return fetch(baseUrl + 'targets/' + idTarget, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getTemporaryID(baseUrl, type, token) {
  return fetch(baseUrl + 'targetTypes/identifier/' + type + '/temporary', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function createNewTemporary(baseUrl, type, token) {
  return fetch(baseUrl + 'targetTypes/identifier/' + type + '/temporary', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getTargetConfiguration(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/configuration', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function setTargetConfiguration(baseUrl, id, token, data) {
  console.log(data, 'DATA');
  return fetch(baseUrl + 'targets/' + id + '/configuration', {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function setTargetInfo(baseUrl, id, token, data) {
  return fetch(baseUrl + 'targets/' + id, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getMovements(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/movements/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getMovementDetail(baseUrl, id, token) {
  return fetch(baseUrl + 'movements/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function refundInfo(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/refundsInfo', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function nextMovement(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/movements/next', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getTargetProductInfo(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/product', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function refund(baseUrl, id, data, token) {
  return fetch(baseUrl + 'targets/' + id + '/refunds', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getTargetTypeSchemaFinal(baseUrl, type, token) {
  let f = fetch(
    baseUrl + 'targetTypes/identifier/' + type + '/configurationSchema',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
  //console.log("getTargetTypeSchema", f);
  return f;
}

/**
 Capa para modificar datos del servicio
 */
export function getTargetTypeSchema(baseUrl, type, token) {
  //console.log("TargetsAPI.getTargetTypeSchema type", type);
  return new Promise((resolve, reject) => {
    getTargetTypeSchemaFinal(baseUrl, type, token)
      .then(response => {
        if (response.status === 200) {
          const data = response.json();
          //console.log("TargetsAPI.getTargetTypeSchema", data);
          return data;
        } else {
          //console.log("TargetsAPI.getTargetTypeSchema reject");
          reject(response.status);
        }
      })
      .then(data => {
        //console.log("TargetsAPI.getTargetTypeSchema data", data);
        if (type === 'nextWish') {
          data.properties.initialContribution.minimum = 10;
        } else {
          data.properties.initialContribution.minimum = 50;
        }
        resolve(data);
      })
      .catch(error => {
        console.error(
          'TargetsAPI.getTargetTypeSchema: Error getting data',
          error,
        );
        reject(error);
      });
  });
}

export function setTargetAccounts(baseUrl, id, data, token) {
  return fetch(baseUrl + 'targets/' + id + '/accounts', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getContractDataSchema(baseUrl, type, token) {
  return fetch(
    baseUrl + 'targetTypes/identifier/' + type + '/contractDataSchema',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getContractCurrentData(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/currentData', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function setContractCurrentData(baseUrl, id, data, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/currentData', {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getContractAutocomplete(baseUrl, type, name, query, token) {
  return fetch(
    baseUrl +
      'targetTypes/identifier/' +
      type +
      '/field/' +
      name +
      '/autocomplete?search=' +
      query,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function createContract(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/remote', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getContractStatus(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/creationStatus', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getContractSigningInfo(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/signingInfo', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getDocuments(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/documents', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getChartData(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/charts', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getRecommendedSaving(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/recommendedSaving', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

// Obtener estado Traspaso Plan de Pensiones
export async function getStatusTPP(baseUrl, user, token, targetId) {
  return fetch(
    `${baseUrl}/indicators/indicatorDefinitions/identifier/userTransfers/user/${user}/output?input={"idTarget":"${targetId}"}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getPensionList(baseUrl, search, token) {
  // prettier-ignore
  return fetch(
    'https://api.evo.coinscrap.com/' +
      `indicators/indicatorDefinitions/identifier/planPensionsList/output?format=json&input=${JSON.stringify({search})}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}
export function getManagerList(baseUrl, search, token) {
  return fetch(
    'https://api.evo.coinscrap.com/' +
      `indicators/indicatorDefinitions/identifier/managerList/output?format=json`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function changeContributionStatuses(baseUrl, id, status, token) {
  return fetch(baseUrl + 'targets/' + id + '/changeContributionStatus', {
    method: 'POST',
    body: JSON.stringify({ status }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}
export function targetTransfer(baseUrl, id, sourcePensionPlan, token) {
  return fetch(baseUrl + 'targets/' + id + '/inputFromContract', {
    method: 'POST',
    body: JSON.stringify({ externalContract: sourcePensionPlan }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}
