import React, { Component } from 'react';
//import TextField from '@material-ui/core/TextField'
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
//import Button from '@material-ui/core/Button'
//import { targetInitialContribution_strings } from './text'
import { Utils } from '../../../resources/index';
//import Footer from '../../../ui/react/common/footer.component';

class TargetInitialContribution extends Component {
  constructor(props) {
    super(props);

    const flowState = Utils.getFlowState(this.props);
    console.log('tartargetInitialcontribution.render', flowState);

    this.state = {
      value:
        this.props.configuration.initialContribution !== undefined
          ? this.props.configuration.initialContribution
          : flowState.impInicial
          ? flowState.impInicial
          : this.props.schema.minimum,
    };
    this.handleChange = this.handleChange.bind(this);

    //Inicializa con valor por defecto
    this.props.setContribution(this.state.value);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });

    // Envia al comp. padre (targetContribution) el valor.
    this.props.setContribution(event.target.value);
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.targetInitialContribution_container}>
        <div
          className={
            classes.targetInitialContribution_body_targetSelector_container
          }
        >
          <div
            className={
              classes.targetInitialContribution_body_targetSelector_leftIco_container
            }
          >
            <SvgIcon
              color='primary'
              classes={transformClasses(
                'targetInitialContribution_body_targetSelector_leftIco_svgIcon',
                classes,
              )}
              viewBox='0 0 28 28'
            >
              <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
            </SvgIcon>
          </div>
          <div
            className={
              classes.targetInitialContribution_body_targetSelector_info_container
            }
          >
            <div
              className={
                classes.targetInitialContribution_body_targetSelector_info_firstRow
              }
            >
              <Typography
                variant='body1'
                color='secondary'
                classes={transformClasses(
                  'targetInitialContribution_body_targetSelector_info_firstRow_typography',
                  classes,
                )}
              >
                Importe
              </Typography>
            </div>
            <div
              className={
                classes.targetInitialContribution_body_targetSelector_info_secondRow
              }
            >
              <Input
                disableUnderline
                value={this.state.value}
                placeholder='Ejemplo 200 €'
                onChange={e => this.handleChange(e)}
                classes={transformClasses(
                  'targetInitialContribution_body_targetSelector_info_secondRow_input',
                  classes,
                )}
              />
            </div>
          </div>
        </div>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses(
            'targetInitialContribution_disclaimer_typography',
            classes,
          )}
        >
          Esta aportación es obligatoria
        </Typography>
      </div>
    );
  }
}

export default TargetInitialContribution;

/*
<TextField
          {...config.textField1}
          value={this.state.value}
          placeholder={this.props.schema.placeholder}
          classes={transformClasses(
            'targetinitialContribution_textField1',
            classes
          )}
          onChange={this.handleChange}
        />
*/
