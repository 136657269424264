import React from 'react';

import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';

import Flow from '../../../components/Flow';
import MyText from '../../../components/MyText';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';

import ConservadorBarsPPImage from './charts/ConservadorBarsPPImage';
import ConservadorBarsHuchaImage from './charts/ConservadorBarsHuchaImage';
import ConservadorGraphHuchaImage from './charts/ConservadorGraphHuchaImage';
import ConservadorGraphPPImage from './charts/ConservadorGraphPPImage';
import DinamicoGraphPPImage from './charts/DinamicoGraphPPImage';
import DinamicoBarsPPImage from './charts/DinamicoBarsPPImage';
import AgresivoBarsPPImage from './charts/AgresivoBarsPPImage';
import AgresivoGraphPPImage from './charts/AgresivoGraphPPImage';
import ModeradoGraphHuchaImage from './charts/ModeradoGraphHuchaImage';
import ModeradoBarsHuchaImage from './charts/ModeradoBarsHuchaImage';
import DinamicoGraphHuchaImage from './charts/DinamicoGraphHuchaImage';
import DinamicoBarsHuchaImage from './charts/DinamicoBarsHuchaImage';
import AnimadoGraphHuchaImage from './charts/AnimadoGraphHuchaImage';
import AnimadoBarsHuchaImage from './charts/AnimadoBarsHuchaImage';
import AgresivoGraphHuchaImage from './charts/AgresivoGraphHuchaImage';
import AgresivoBarsHuchaImage from './charts/AgresivoBarsHuchaImage';

import { retirementGeneralGraphData } from '../RetirementData/carteraData';

export default function ProductHistorical(props) {
  const params = Flow.getParams();
  const [openModalInfo1, setOpenModalInfo1] = React.useState(false);
  const [openModalInfo2, setOpenModalInfo2] = React.useState(false);

  // Modales -------------------------------------------------------------
  function ModalInfo1(props) {
    return (
      <Modal open={openModalInfo1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setOpenModalInfo1(false)}>
        <div style={{ width: '312px', backgroundColor: '#e9e9e9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '14px', borderRadius: '8px', '&:focus': { outline: 'unset' } }}>
          {params.objectiveType === 'retirement' ? (
            <p class='ng-binding'>{`Fuente para "Tu cartera": Retorno histórico anualizado de tu cartera, basado en datos de Morningstar. Datos de ${retirementGeneralGraphData.dateRangeStart} hasta ${retirementGeneralGraphData.dateRangeEnd}.`}</p>
          ) : (
            <>
              <p class='ng-binding'>Fuente para "Tu cartera": Retorno histórico anualizado de tu cartera, basado en datos de Morningstar. Datos de 01/01/2009 hasta 31/12/2018.</p>
              <p class='ng-binding'>Fuente para "Referencia de mercado": Inverco. Datos históricos agregados de la categoría de fondos de inversión de "FI Renta Fija Mixta Internacional". Fecha de 01/01/2009 hasta 31/12/2018.</p>
            </>
          )}
        </div>
      </Modal>
    );
  }

  function ModalInfo2(props) {
    return (
      <Modal open={openModalInfo2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setOpenModalInfo2(false)}>
        <div style={{ width: '312px', backgroundColor: '#e9e9e9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '14px', borderRadius: '8px', '&:focus': { outline: 'unset' } }}>
          {params.objectiveType === 'retirement' ? (
            <p class='ng-binding'>{`Rentabilidad histórica anualizada basada en el período del ${retirementGeneralGraphData.dateRangeStart} hasta ${retirementGeneralGraphData.dateRangeEnd}.`}</p>
          ) : (
            <p class='ng-binding'>Rentabilidad histórica anualizada basada en el período del 01/01/2009 hasta 31/12/2018.</p>
          )}{' '}
          <p>El efecto de las comisiones ya está reflejado en este cálculo.</p>{' '}
        </div>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <Header modal={true} title='Historial Cartera' history={props.history} />
      <Body vAlign='top'>
        <MyText id='productName' variant='h1'>{params.productName}</MyText>
        <MyText>Esta cartera de inversión ha obtenido una rentabilidad histórica del <strong>{(params.profitability * 100).toFixed(2)} % </strong> a lo largo de los últimos 15 años.</MyText>

        {/* PP */}
        {params.productName === 'Conservador' && params.objectiveType === 'retirement' && <ConservadorGraphPPImage />}
        {params.productName === 'Dinámico' && params.objectiveType === 'retirement' && <DinamicoGraphPPImage />}
        {params.productName === 'Agresivo' && params.objectiveType === 'retirement' && <AgresivoGraphPPImage />}

        {/* HUCHA */}
        {params.productName === 'Agresivo' && params.objectiveType !== 'retirement' && <AgresivoGraphHuchaImage />}
        {params.productName === 'Animado' && params.objectiveType !== 'retirement' && <AnimadoGraphHuchaImage />}
        {params.productName === 'Dinámico' && params.objectiveType !== 'retirement' && <DinamicoGraphHuchaImage />}
        {params.productName === 'Moderado' && params.objectiveType !== 'retirement' && <ModeradoGraphHuchaImage />}
        {params.productName === 'Conservador' && params.objectiveType !== 'retirement' && <ConservadorGraphHuchaImage />}

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
          <div style={{ width: 18, height: 18, float: 'left', marginLeft: 5, marginRight: 10, borderRadius: 10, boxShadow: '1px 1px 1px 1px', backgroundColor: '#118afa' }} />
          <MyText style={{ color: 'white', marginTop: 3, marginRight: 20 }}>Tu cartera</MyText>
          <div style={{ width: 18, height: 18, float: 'left', marginLeft: 5, marginRight: 10, borderRadius: 10, boxShadow: '1px 1px 1px 1px', backgroundColor: '#888' }} />
          <MyText style={{ color: 'white', marginTop: 3, marginRight: 20 }}>Referencia de mercado</MyText>
        </div>

        <MyText variant='h1' style={{ marginTop: 15 }}>Rentabilidad año por año</MyText>
        <MyText>Simulación año a año de la evolución de la cartera durante los últimos 15 años</MyText>

        {/* PP */}
        {params.productName === 'Conservador' && params.objectiveType === 'retirement' && <ConservadorBarsPPImage />}
        {params.productName === 'Dinámico' && params.objectiveType === 'retirement' && <DinamicoBarsPPImage />}
        {params.productName === 'Agresivo' && params.objectiveType === 'retirement' && <AgresivoBarsPPImage />}

        {/* HUCHA */}
        {params.productName === 'Agresivo' && params.objectiveType !== 'retirement' && <AgresivoBarsHuchaImage />}
        {params.productName === 'Animado' && params.objectiveType !== 'retirement' && <AnimadoBarsHuchaImage />}
        {params.productName === 'Dinámico' && params.objectiveType !== 'retirement' && <DinamicoBarsHuchaImage />}
        {params.productName === 'Moderado' && params.objectiveType !== 'retirement' && <ModeradoBarsHuchaImage />}
        {params.productName === 'Conservador' && params.objectiveType !== 'retirement' && <ConservadorBarsHuchaImage />}

        <MyText variant='h1'>Estrategia de inversión pasiva</MyText>
        <MyText>Nuestra estrategia de inversión se caracteriza por utilizar exclusivamente productos financieros indexados de <strong>gestión pasiva</strong>, como los fondos índice y los ETFs.</MyText>
        <br />
        <MyText>Diseñamos carteras <strong>muy eficientes en costes y altamente diversificadas</strong>, manejando el riesgo de manera muy disciplinada. En el pasado, esta estrategia ha presentado <strong>mejores resultados a largo plazo que el promedio de las carteras de gestión discrecional.</strong></MyText>
        <br />
        <MyText>Nota importante: rentabilidades pasadas no garantizan rentabilidades futuras.</MyText>
      </Body>
    </React.Fragment>
  );
}
