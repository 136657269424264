import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLef from '@material-ui/icons/KeyboardArrowLeft';
import { withStyles } from '@material-ui/core/styles';

import { Utils } from '../../src-core/resources/index';

const styles = theme => ({
  chatSupport_container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  chatContainer: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
    frameBorder: 0,
  },
});

class ChatSupport extends Component {
  goBack() {
    this.props.history.push(this.props.location.state.back);
  }
  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.chatSupport_container}>
        <div className={classes.header_container}>
          <IconButton
            color='primary'
            classes={transformClasses(
              'configureRule_headerIconButton1',
              classes,
            )}
            onClick={this.goBack.bind(this)}
          >
            <KeyboardArrowLef />
          </IconButton>
        </div>
        <iframe
          src={
            this.props.baseUrl + 'support/chat?tokenUser=' + this.props.token
          }
          title='iframe'
          className={classes.chatContainer}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChatSupport);
