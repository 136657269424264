import { connect } from 'react-redux';
import CreateTarget from '../views/CreateTarget/CreateTarget';
import * as Login2Actions from './actions';

function mapStateToProps(state) {
  const { token, baseUrl } = state.main;
  return {
    token,
    baseUrl,
  };
}

const mapDispatchToProps = {
  saveObjectiveId: Login2Actions.saveObjectiveId,
  saveNameAndImageRetirement: Login2Actions.saveNameAndImageRetirement,
  saveUserObject: Login2Actions.saveUserObject,
  saveProdsForUser: Login2Actions.saveProdsForUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTarget);
