import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputSwitch', {
  styles: {
    default: {
      thumbComponent: {
        width: 24,
        height: 24,
        backgroundColor: 'white',
      },
      trackComponent: {
        height: 25,
        borderRadius: 13,
        marginTop: -3,
        borderRadius: 26 / 2,
      },
      checkedMode: {
        thumbComponent: {
          backgroundColor: colors.green,
        },
        trackComponent: {
          backgroundColor: colors.green,
        },
      },
    },
    smallCircleSt: {
      labelComponent: {},
      containerComponent: {
        padding: 0,
        margin: 0,
        width: 60,
        marginBottom: 8,
      },
      switchContainerComponent: {
        width: 100,
        position: 'relative',
      },
      switchComponent: {
        // top: '-50%',
        left: 7,
        top: 4.5,
      },
      thumbComponent: {
        width: 19.2,
        height: 19.2,
        backgroundColor: 'white',
        // transform: ' translateX(8px)',
      },
      trackComponent: {
        height: 28.8,

        marginTop: -3,
        // borderRadius: 26 / 2,
        borderRadius: 14.4,
        width: '48px',
        padding: 0,
        backgroundColor: colors.grey2,
      },
      checkedMode: {
        trackComponent: {
          backgroundColor: '#34C95A',
        },
        thumbComponent: {
          backgroundColor: colors.white,
        },
      },
    },
    pauseTargetSt: {
      labelComponent: {},
      containerComponent: {
        padding: 0,
        margin: 0,
        // width: 60,
        // marginBottom: 8,
        width: '48px',
      },
      switchContainerComponent: {
        position: 'relative',
      },
      switchComponent: {
        // top: '-50%',
        // left: 7,
        // top: 4.5,
      },
      thumbComponent: {
        width: 20,
        height: 20,
        backgroundColor: colors.white,
        transform: ' translateY(-1px)',
      },
      trackComponent: {
        height: 18,

        backgroundColor: colors.black,
      },
      checkedMode: {
        trackComponent: {
          backgroundColor: colors.switchColorThumb,
        },
        thumbComponent: {
          backgroundColor: colors.switchColorThumb,
        },
      },
    },
    normalCircleSt: {
      labelComponent: {},
      // containerComponent: {
      //   padding: 0,
      //   margin: 0,
      //   width: 60,
      //   marginBottom: 8,
      // },
      // switchContainerComponent: {
      //   width: 'auto',
      //   position: 'relative',
      // },
      // switchComponent: {
      //   // top: '-50%',
      //   left: 7,
      //   top: 4.5,
      // },
      // thumbComponent: {
      //   width: 19.2,
      //   height: 19.2,
      //   backgroundColor: 'white',
      //   // transform: ' translateX(8px)',
      // },
      trackComponent: {
        // height: 28.8,

        // marginTop: -3,
        // // borderRadius: 26 / 2,
        // borderRadius: 14.4,
        // width: 48,
        // padding: 0,
        backgroundColor: colors.grey2,
      },
      checkedMode: {
        trackComponent: {
          backgroundColor: '#34C95A',
        },
        thumbComponent: {
          backgroundColor: colors.white,
        },
      },
    },
  },
});
