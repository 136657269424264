import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';

import { RuleInputStyles } from '../../src-core/rules/index';
//core/components/rules';
//import { RulesAPI } from '../components-core/sdk';
import { RulesAPI, TargetsAPI } from '../../src-core/sdk/index';
import { Utils } from '../../src-core/resources/index';

import Picker from '../../components/picker/picker.component';
import PickerStyles from '../../components/picker/picker.style';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import Animation from '../../components/MyAnimation';

class ConfigureRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: null,
      setting: false,
      rulesDefinition: null,
      autocomplete: null,
      howAutocomplete: false,
      fieldToAutocomplete: null,
      index: 0,
      valueGroups1: {
        title: { name: '1', id: '1' },
      },
      valueGroups2: {
        title: { name: '1', id: '1' },
      },
      valueGroups3: {
        title: '',
      },
      optionGroups1: {
        title: null,
      },
      optionGroups2: {
        title: null,
      },
      optionGroups3: {
        title: null,
      },
    };
  }

  componentDidMount() {
    if (this.props.match.params.id === 'new_rule') {
      this.getRulesDefinitions();
    } else {
      this.getRule();
    }
  }

  // componentDidUpdate() {
  //   if (this.state.rule !== null && !this.state.setting) {
  //     console.error('componentDidUpdate');
  //     this.setState({
  //       setting: true,
  //     });
  //     this.getRulesDefinitions();
  //   }
  // }

  //TODO: optimizar, pasar esto al followupMain, pq tambien se utiliza para la simulación

  getTarget(targetID) {
    console.log('getTarget1', this);

    const { baseUrl } = this.props;
    TargetsAPI.getTargets(baseUrl, targetID, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getTarget', data);
        this.setState({
          target: data,
        });
      })
      .catch(error => {
        console.log('error consiguiendo el target', error);
      });
  }

  getRule() {
    RulesAPI.getRule(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getRule', data);
        this.getTarget(data.target);
        this.setState({
          rule: data,
          setting: true,
        });

        if (
          data.value.transactionsAmountReductor &&
          data.value.transactionsAmountReductor.percentage
        ) {
          this.setState({
            valueGroups1: {
              title: {
                name: data.value.transactionsAmountReductor.percentage,
                id: data.value.transactionsAmountReductor.percentage,
              },
            },
          });
        }

        if (
          data.value.transactionsAmountReductor &&
          data.value.transactionsAmountReductor.round
        ) {
          this.setState({
            valueGroups2: {
              title: {
                name: data.value.transactionsAmountReductor.round,
                id: data.value.transactionsAmountReductor.round,
              },
            },
          });
        }

        if (data.value.fieldsValues) {
          this.setState({
            valueGroups3: {
              title: {
                name: data?.value?.fieldsValues[0]?.value,
                id: data?.value?.fieldsValues[0]?.value,
              },
            },
          });
        }

        this.getRulesDefinitions();
      })
      .catch(error => {
        console.log('error consiguiendo la regla ', error);
      });
  }

  async getRulesDefinitions() {
    const arr = [];
    RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token)
      .then(data => {
        const definitions = {};
        data.map(definition => {
          definitions[definition._id] = definition;
          return null;
        });
        if (
          this.props.match.params.defID !== undefined &&
          this.props.match.params.id === 'new_rule'
        ) {
          const def = [...arr, definitions[this.props.match.params.defID]];
          console.log('def[0]: ', def[0]);
          let rule = {};
          if (def[0].fields !== undefined) {
            rule = {
              ruleDefinition: def[0]._id,
              value: {
                fieldsValues: [],
              },
            };
            def[0].fields.forEach(field => {
              rule.value.fieldsValues.push({
                name: field.name,
                value: field.default,
              });
            });
          } else {
            rule = {
              ruleDefinition: def[0]._id,
              value: {
                transactionsAmountReductor: {
                  type: def[0].transactionsAmountReductor.type,
                },
              },
            };
            console.log(
              'Ñapa1',
              def[0].transactionsAmountReductor.type.toLowerCase(),
            );
            rule.value.transactionsAmountReductor[
              def[0].transactionsAmountReductor.type.toLowerCase()
            ] = def[0].transactionsAmountReductor.min;
            //ººº
          }
          this.setState({
            rulesDefinition: def,
            rule: rule,
          });
          console.log(
            'this is ok: ',
            definitions[this.props.match.params.defID],
          );
        } else {
          console.log(
            'definitions: ',
            definitions[this.state.rule.ruleDefinition],
          );
          this.setState({
            rulesDefinition: [
              ...arr,
              definitions[this.state.rule.ruleDefinition],
            ],
          });
        }
      })
      .catch(error => {
        console.log('error recuperando las rulesDefinitions ', error);
      });
  }

  goBack() {
    console.log('goBack', this.props.location.state);

    if (this.props.location.state !== undefined) {
      this.props.history.push(this.props.location.state.back);
    } else {
      this.props.history.push('/home/resumen');
    }
  }

  render() {
    //const { classes } = this.props
    //const transformClasses = Utils.transformClasses
    return (this.state.rule !== null ||
      this.props.match.params.id === 'new_rule') &&
      this.state.rulesDefinition !== null ? (
      <Fragment>
        <Header
          title='Reglas'
          rigthAction={() => this.props.history.goBack()}
        />
        {this.renderData()}
      </Fragment>
    ) : (
      <div />
    );
  }
  // new stuff
  ruleAlreadySet(id) {
    if (
      this.state.configuredRules.indexOf(id) >= 0 ||
      this.props.rulesTarget
        .map(e => {
          return e.ruleDefinition._id;
        })
        .indexOf(id) >= 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getAutocomplete(query, id, name) {
    RulesAPI.getAutocomplete(
      this.props.baseUrl,
      id,
      name,
      query,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          autocomplete: data,
        });
      })
      .catch(error => {
        console.log('error recuperando autocomplete', error);
      });
  }

  isValidData(id, pos) {
    if (this.state.rule.value.transactionsAmountReductor !== undefined) {
      console.log('Ñapa2', this.state.rule.value.transactionsAmountReductor);

      const value = this.state.rule.value.transactionsAmountReductor[
        this.state.rule.value.transactionsAmountReductor.type.toLowerCase()
      ];
      if (
        value >= this.state.rulesDefinition[0].transactionsAmountReductor.min &&
        value <= this.state.rulesDefinition[0].transactionsAmountReductor.max
      ) {
        return { valid: true };
      } else {
        return {
          valid: false,
          min: this.state.rulesDefinition[0].transactionsAmountReductor.min,
          max: this.state.rulesDefinition[0].transactionsAmountReductor.max,
        };
      }
    } else {
      const values = this.state.rule.value.fieldsValues.map(e => {
        return e.value;
      });
      const valid = values.map((e, index) => {
        if (this.state.rulesDefinition[0].fields[index].min !== undefined) {
          if (e >= this.state.rulesDefinition[0].fields[index].min) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      if (valid.indexOf(false) >= 0) {
        return {
          valid: false,
          min: this.state.rulesDefinition[0].fields[valid.indexOf(false)].min,
        };
      } else {
        return { valid: true };
      }
    }
  }

  genValue() {
    let value = {};
    if (this.state.rule.value.transactionsAmountReductor !== undefined) {
      console.log(
        'Ñapa3',
        this.state.rule.value.transactionsAmountReductor.type,
      );

      value = {
        transactionsAmountReductor: {
          type: this.state.rule.value.transactionsAmountReductor.type,
        },
      };
      value.transactionsAmountReductor[
        this.state.rule.value.transactionsAmountReductor.type.toLowerCase()
      ] = this.state.rule.value.transactionsAmountReductor[
        this.state.rule.value.transactionsAmountReductor.type.toLowerCase()
      ];
    } else {
      value = {
        fieldsValues: this.state.rule.value.fieldsValues.map(element => {
          return {
            name: element.name,
            value: element.value,
          };
        }),
      };
    }

    return value;
  }

  setRule(id, index) {
    const targetID =
      this.props.match.params.targetID !== undefined
        ? this.props.match.params.targetID
        : this.state.rule.target;
    if (this.isValidData(id, index).valid) {
      const data = {
        ruleDefinition: this.state.rulesDefinition[0]._id,
        value: this.genValue(),
      };
      RulesAPI.setRule(this.props.baseUrl, targetID, data, this.props.token)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.status;
          }
        })
        .then(data => {
          console.log('configRule.setRule service OK ');
          console.log('configRule.setRule try activateRule ');
          RulesAPI.setActive(
            this.props.baseUrl,
            this.state.rule._id,
            { status: true },
            this.props.token,
          );
          /*
          if (
            this.props.match.params.targetID === undefined &&
            !this.state.rule.isActive
          ) {
            this.activateRule(this.state.rule._id)

          }
          */

          // Si tiene locattion.state, vuelve para atras
          console.log('back?? ', this.props.location.state.back);
          if (
            this.props.location.state //&&
            //this.props.location.state.back.indexOf("/home/reglas/listConfRule/") === 0
          ) {
            this.goBack();
          }
          //console.log('done')
        })
        .catch(error => {
          console.log('error actualizando regla', error);
        });
    } else {
      this.setState({
        errorMessage:
          'Los datos introducidos no son los correctos, vuelva a intentarlo.',
        indexLatest: this.state.index,
        open: true,
      });
    }
  }

  activateRule(id) {
    const { baseUrl, token } = this.props;
    RulesAPI.getRule(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        if (!data.isActive) {
          const data = {
            status: true,
          };
          RulesAPI.setActive(baseUrl, id, data, token);
          this.goBack();
        }
      })
      .catch(error => {
        console.log('error activando la regla: ', error);
      });
  }

  handleChangeIndex(index, indexLatest) {
    if (
      this.state.rulesDefinitions[indexLatest].required &&
      !this.ruleAlreadySet(this.state.rulesDefinitions[indexLatest]._id) &&
      index > indexLatest
    ) {
      this.setState({
        errorMessage:
          'La regla anterior era obligatoria, por favor vuelva y configurela',
        open: true,
      });
    }
    this.setState({
      index: index,
      indexLatest: indexLatest,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      index: this.state.indexLatest,
    });
  }

  deactivateRule(id, index) {
    console.log(
      'configRule.deactivateRule back',
      this.props.location.state.back,
    );

    const { baseUrl, token } = this.props;
    if (this.state.rule.isActive) {
      const data = {
        status: false,
      };
      RulesAPI.setActive(baseUrl, this.state.rule._id, data, token)
        .then(response => {
          if (response.status === 200) {
            console.log('deactivated');
            this.goBack();
          } else {
            throw response.status;
          }
        })
        .catch(error => {
          console.log('error desactivando una regla: ', error);
        });
    }

    // Si viene de config Regla desde Lista de objetivos o desde Seguimiento,
    // vuelve para atras
    if (
      this.props.location.state //&&
      /*
      (
        this.props.location.state.back.indexOf("/home/reglas/listConfRule/") === 0
        ||
        this.props.location.state.back.indexOf("/followup/") === 0
      )
      */
    ) {
      this.goBack();
    }
  }

  renderFields(ruleDefinition) {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = ruleDefinition.fields.map((field, index) => {
      //const elements = ruleDefinition.fields.forEach((field, index) => {
      if (field.options === undefined) {
        if (ruleDefinition.movements.disableExtraordinaryContributions) {
          return (
            <div
              style={{
                width: '100%',
                padding: '0 48px',
                marginTop: '34px',
              }}
              key={field._id}
            >
              <div className={classes.swipeRules_body_targetSelector_container}>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_leftIco_container
                  }
                >
                  <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'swipeRules_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                    viewBox='0 0 28 28'
                  >
                    <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
                  </SvgIcon>
                </div>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      {field.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_secondRow
                    }
                  >
                    <Input
                      disableUnderline
                      value={this.state.rule.value.fieldsValues[index].value}
                      placeholder='Ejemplo 200 €'
                      onChange={e =>
                        this.handleChangeFields(e, ruleDefinition, index)
                      }
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              style={{
                width: '100%',
                padding: '0 48px',
                marginTop: '34px',
              }}
              key={field._id}
            >
              <div className={classes.swipeRules_body_targetSelector_container}>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_leftIco_container
                  }
                >
                  <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'swipeRules_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                    viewBox='0 0 28 28'
                  >
                    <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
                  </SvgIcon>
                </div>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      {field.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_secondRow
                    }
                  >
                    <Input
                      disableUnderline
                      value={
                        this.state.rule !== null
                          ? this.state.rule.value.fieldsValues[index].value
                          : field.default
                      }
                      onChange={e => {
                        // console.log('Cambio', e.target.value);
                        const obj = this.state.rule;
                        obj.value.fieldsValues[index].value = e.target.value;
                        this.setState({
                          rule: obj,
                        });
                      }}
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      } else {
        if (field.hasAutocomplete) {
          return (
            <div
              style={{
                width: '100%',
                padding: '0 48px',
                marginTop: '34px',
              }}
              key={field._id}
            >
              <div className={classes.swipeRules_body_targetSelector_container}>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_leftIco_container
                  }
                >
                  <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'swipeRules_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                    viewBox='0 0 28 28'
                    style={{ width: '36px', height: '36px' }}
                  >
                    <path d='M15.2,10.1V9.6h-0.9v0.5c0,0.3,0.2,0.5,0.5,0.5C14.9,10.6,15.2,10.4,15.2,10.1z M13.3,8.7h2.8v1.4      c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V8.7z M19.8,10.6v-3c0-0.5-0.3-1-0.8-1.2l-2.1-1.1c0,0.6,0.4,1.3,1,1.6l0.9,0.5v3.3      H19.8z M18,10.6V7.8l-0.4-0.2C16.5,7.1,15.9,6,16,5h-0.8v0.5c0,1.3-1,2.3-2.3,2.3s-2.3-1-2.3-2.3V5H9.7c0.1,1-0.5,2.2-1.5,2.6      L7.7,7.8v2.7h0.9V19H17v-8.4H18z M6.8,7.2l0.9-0.5c0.6-0.3,1-0.9,1-1.6L6.6,6.3C6.1,6.5,5.8,7,5.8,7.5v3h1V7.2z M11.4,5v0.5      c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4V5H11.4z M19.5,5.5c0.8,0.4,1.3,1.2,1.3,2.1v4H18v8.4H7.7v-8.4H4.9v-4      c0-0.9,0.5-1.7,1.3-2.1L9,4.1h1.5h4.6h1.5L19.5,5.5z' />
                  </SvgIcon>
                </div>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      {field.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_secondRow
                    }
                  >
                    <Input
                      disableUnderline
                      value={this.state.rule.value.fieldsValues[index].value}
                      placeholder='Ejemplo Barcelona'
                      onChange={e =>
                        this.handleChangeAutocomplete(
                          e,
                          ruleDefinition._id,
                          index,
                          field.name,
                        )
                      }
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          if (this.state.optionGroups3.title !== null) {
            return (
              <div
                className={classes.swipeRules_picker_container}
                key={field._id}
              >
                <Picker
                  optionGroups={this.state.optionGroups3}
                  valueGroups={this.state.valueGroups3}
                  onChange={(name, value) => {
                    let obj = this.state.rule;
                    if (obj === null) {
                      obj = {
                        value: {
                          fieldsValues: [],
                        },
                      };
                      obj['value']['fieldsValues'].push({});
                      obj['value']['fieldsValues'][index]['name'] =
                        ruleDefinition.fields[0].name;
                      obj['value']['fieldsValues'][index]['value'] = value.name;
                    } else {
                      console.log('obj: ', obj);
                      obj.value.fieldsValues[index].value = value.name;
                    }
                    this.setState(({ valueGroups3 }) => ({
                      valueGroups3: { ...valueGroups3, [name]: value },
                      rule: obj,
                    }));
                  }}
                  itemHeight={34}
                  height={100}
                  classes={classes}
                />
              </div>
            );
          } else {
            this.setState(({ optionGroups3 }) => ({
              optionGroups3: {
                ...optionGroups3,
                title: this.returnPickerFields(field),
              },
            }));
          }
        }
      }

      return null; // Añado este return para evitar warning de q no hay return
    });
    return elements;
  }

  returnPickerFields(field) {
    const elements = field.options.map(value => {
      return {
        name: value,
        id: value,
        unit: field.unit,
      };
    });
    return elements;
  }

  renderTransactionsAmountReductor(ruleDefinition) {
    const { classes } = this.props;

    if (ruleDefinition.transactionsAmountReductor.type === 'PERCENTAGE') {
      if (this.state.optionGroups1.title !== null) {
        return (
          <div
            className={classes.swipeRules_picker_container}
            key={ruleDefinition._id}
          >
            <Picker
              optionGroups={this.state.optionGroups1}
              valueGroups={this.state.valueGroups1}
              onChange={(name, value) => {
                let obj = this.state.rule;
                if (obj === null) {
                  obj = {
                    value: {
                      transactionsAmountReductor: {},
                    },
                  };
                  obj['value']['transactionsAmountReductor'][
                    ruleDefinition.transactionsAmountReductor.type.toLowerCase()
                  ] = value.name;
                  obj['value']['transactionsAmountReductor']['type'] =
                    ruleDefinition.transactionsAmountReductor.type;
                } else {
                  obj.value.transactionsAmountReductor[
                    ruleDefinition.transactionsAmountReductor.type.toLowerCase()
                  ] = value.name;
                }
                console.log(
                  'Ñapa5',
                  obj['value']['transactionsAmountReductor'],
                );
                this.setState(({ valueGroups1 }) => ({
                  valueGroups1: { ...valueGroups1, [name]: value },
                  rule: obj,
                }));
              }}
              itemHeight={34}
              height={125}
              classes={classes}
            />
          </div>
        );
      } else {
        this.setState(({ optionGroups1 }) => ({
          optionGroups1: {
            ...optionGroups1,
            title: this.renderOptionsTransactionsAmountReductor(
              ruleDefinition,
              '%',
            ),
          },
        }));
      }
    } else if (ruleDefinition.transactionsAmountReductor.type === 'ROUND') {
      if (this.state.optionGroups2.title !== null) {
        return (
          <div
            className={classes.swipeRules_picker_container}
            key={ruleDefinition._id}
          >
            <Picker
              optionGroups={this.state.optionGroups2}
              valueGroups={this.state.valueGroups2}
              onChange={(name, value) => {
                if (this.state.setting) {
                  console.log('onChange', {
                    name1: value.name,
                    ruleDefinition,
                    name,
                    value,
                  });
                  let obj = this.state.rule;
                  if (obj === null) {
                    obj = {
                      value: {
                        transactionsAmountReductor: {},
                      },
                    };
                    obj['value']['transactionsAmountReductor'][
                      ruleDefinition.transactionsAmountReductor.type.toLowerCase()
                    ] = value.name;
                    obj['value']['transactionsAmountReductor']['type'] =
                      ruleDefinition.transactionsAmountReductor.type;

                    this.setState(({ valueGroups2 }) => ({
                      valueGroups2: { ...valueGroups2, [name]: value },
                      rule: obj,
                    }));
                    console.log('ÑAPA8-----', name, value, this.state);
                  } else {
                    // Si cambio el valor, actualiza el state
                    if (
                      obj.value.transactionsAmountReductor.round !== value.id
                    ) {
                      obj.value.transactionsAmountReductor.round = value.id;
                      this.setState(({ valueGroups2 }) => ({
                        valueGroups2: { ...valueGroups2, [name]: value },
                        rule: obj,
                      }));
                    }
                    //ºº
                    // obj.value.transactionsAmountReductor[
                    //   ruleDefinition.transactionsAmountReductor.type.toLowerCase()
                    // ] = value.name;
                  }
                  console.log('ÑAPA8', name, value, this.state);
                }
              }}
              itemHeight={34}
              height={125}
              classes={classes}
            />
          </div>
        );
      } else {
        this.setState(({ optionGroups2 }) => ({
          optionGroups2: {
            ...optionGroups2,
            title: this.renderOptionsTransactionsAmountReductor(
              ruleDefinition,
              '€',
            ),
          },
        }));
      }
    } else {
      return (
        <TextField
          key={ruleDefinition._id}
          label={ruleDefinition.name}
          value={
            this.state.rules[ruleDefinition._id].value
              .transactionsAmountReductor[
              ruleDefinition.transactionsAmountReductor.type.toLowerCase()
            ]
          }
          onChange={e =>
            this.handleChangeTransactionsAmountReductor(e, ruleDefinition)
          }
        />
      );
    }
  }
  renderOptionsTransactionsAmountReductor(ruleDefinition, unit) {
    const arrAux = Array.from(
      {
        length:
          ruleDefinition.transactionsAmountReductor.max -
          ruleDefinition.transactionsAmountReductor.min +
          1,
      },
      (e, i) => ({
        name: ruleDefinition.transactionsAmountReductor.min + i,
        id: ruleDefinition.transactionsAmountReductor.min + i,
        unit: unit,
      }),
    );
    return arrAux;
  }

  /*
    ATENCION unas veces UTILIZA EL METODO DEL CORE y otras este !!!
  */
  renderData() {
    const { classes /*, config */ } = this.props;
    const transformClasses = Utils.transformClasses;
    //console.log('ruleDef en render: ', this.state.rulesDefinition)
    console.log('hello props', this.props, this.state);
    const elements = this.state.rulesDefinition.map((ruleDefinition, index) => (
      <Fragment key={index}>
        <Body vAlign='top'>
          <div key={ruleDefinition.id}>
            <Animation name={ruleDefinition.identifier} color='white' />
            <Typography
              color='secondary'
              //classes={transformClasses('cardSummary_typography1', classes)}
              className='textHeader'
            >
              {ruleDefinition.name}
            </Typography>
            <div className={classes.swipeRules_divContainer2}>
              <Typography
                color='primary'
                classes={transformClasses('cardSummary_typography3', classes)}
              >
                {ruleDefinition.title}
              </Typography>
              <Typography
                color='primary'
                classes={transformClasses('cardSummary_typography4', classes)}
              >
                {ruleDefinition.description}
              </Typography>
              {/* {console.log(
                'ruleDefinition.transactionsAmountReductor',
                ruleDefinition.transactionsAmountReductor,
              )}
              {console.log('this.state', this.state)} */}
              {ruleDefinition.transactionsAmountReductor === undefined
                ? this.renderFields(ruleDefinition)
                : this.renderTransactionsAmountReductor(ruleDefinition)}
              {this.state.showAutocomplete &&
              this.state.autocomplete !== null ? (
                <div style={{ overflow: 'auto', maxHeight: 200 }}>
                  <Collapse in={this.state.showAutocomplete} unmountOnExit>
                    <List>{this.renderAutocomplete()}</List>
                  </Collapse>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Body>

        <Footer>
          {(this.state?.target?.type === 'retirement' &&
            (!this.state.rulesDefinition[0].required ||
              this.state.rulesDefinition[0].identifier ===
                'PERIODIC_SAVING')) ||
          (this.state?.target?.type !== 'retirement' &&
            this.state.rulesDefinition[0].identifier !== 'PERIODIC_SAVING' &&
            !this.state.rulesDefinition[0].required) ? (
            <div className='footerButtons'>
              {' '}
              {/*classes.swipeRules_buttonContainer*/}
              <Button
                variant='contained'
                color='primary'
                classes={transformClasses('swipeRules_button1', classes)}
                onClick={() => this.deactivateRule(ruleDefinition._id, index)}
                id='noAplicar2'
              >
                No Aplicar
              </Button>
              <Button
                variant='contained'
                color='secondary'
                classes={transformClasses('swipeRules_button2', classes)}
                onClick={e => this.setRule(ruleDefinition._id, index, e)}
                id='aplicar3'
              >
                Aplicar
              </Button>
            </div>
          ) : (
            <div className='footerButtons'>
              {' '}
              {/*{classes.swipeRules_buttonContainer2}>*/}
              <Button
                variant='contained'
                color='secondary'
                classes={transformClasses('swipeRules_button3', classes)}
                onClick={e => this.setRule(ruleDefinition._id, index, e)}
                id='aplicar4'
              >
                Aplicar
              </Button>
            </div>
          )}
        </Footer>
      </Fragment>
    ));
    return elements;
  }

  goToNext() {
    this.props.history.push(this.props.next);
  }

  renderAutocomplete() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.autocomplete.options.map(element => {
      return (
        <ListItem key={element}>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'swipeRules_autocomplete_typography1',
              classes,
            )}
            onClick={e => this.handleClikcAutomcomplete(element, e)}
          >
            {element}
          </Typography>
        </ListItem>
      );
    });
    return elements;
  }

  handleChangeFields(event, ruleDefinition, index) {
    const obj = this.state.rule;
    obj.value.fieldsValues[index].value = event.target.value;
    this.setState({
      rule: obj,
    });
  }

  handleChangeAutocomplete(event, id, index, name) {
    this.getAutocomplete(event.target.value, id, name);
    const obj = this.state.rule;
    obj.value.fieldsValues[index].value = event.target.value;
    if (event.target.value !== '') {
      this.setState({
        rule: obj,
        fieldToAutocomplete: { idField: id, indexField: index },
        showAutocomplete: true,
      });
    } else {
      this.setState({
        rule: obj,
        fieldToAutocomplete: null,
        showAutocomplete: false,
      });
    }

    console.log(
      'Regla TEAM',
      this.state,
      obj.value.fieldsValues[index].value,
      event.target.value,
    );
  }

  handleClikcAutomcomplete(value) {
    const obj = this.state.rule;
    obj.value.fieldsValues[
      this.state.fieldToAutocomplete.indexField
    ].value = value;
    this.setState({
      rule: obj,
      showAutocomplete: false,
    });
  }
}

const styles = theme => ({
  ...RuleInputStyles(theme),
  ...PickerStyles(theme),
  configureRule_container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  dataContainer: {
    height: '90vh',
    minHeight: 511,
    //backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
  },
  ruleInput_typography1_root: {
    color: 'white',
  },
  swipeRules_mainContainer: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  swipeRules_divContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    justifyContent: 'center',
    flex: 1,
  },
  swipeRules_divContainer2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 24px',
  },
  swipeRules_buttonContainer: {
    padding: '0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  swipeRules_buttonContainer2: {
    padding: '0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  cardSummary_iconButton_root: {
    fontSize: 64,
  },
  cardSummary_paper_root: {
    display: 'flex',
    alignItems: 'center',
    height: '10vh',
    minHeight: 57,
    backgroundColor: 'transparent',
  },
  swipeRules_button1_root: {
    width: '152px',
    borderRadius: '4px',
    height: '40px',
    backgroundColor: '#5D5D5D',
  },
  swipeRules_button2_root: {
    width: '152px',
    height: '40px',
    borderRadius: '4px',
  },
  swipeRules_button3_root: {
    width: '312px',
    height: '40px',
    borderRadius: '4px',
  },
  swipeRules_fabutton1_root: {
    marginRight: 8,
  },
  swipeRules_fabutton2_root: {
    marginLeft: 8,
  },
  cardSummary_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    textTransform: 'capitalize',
    lineHeight: '45px',
    marginBottom: '21px',
    marginTop: '4px',
  },
  cardSummary_typography2_root: {
    fontWeight: 600,
  },
  cardSummary_typography3_root: {
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'center',
  },
  cardSummary_typography4_root: {
    fontSize: '16px',
    textAlign: 'center',
    //width: '290px',
    marginTop: '6px',
  },
  lastSummary_typography1_root: {
    fontWeight: 600,
  },
  lastSummary_typography2_root: {
    fontWeight: 600,
  },
  lastSummary_typography3_root: {
    fontWeight: 600,
  },
  lastSummary_typography4_root: {
    fontWeight: 600,
  },
  cardSummary_icon1_root: {
    height: '46px',
    width: '46px',
    marginTop: '122px',
  },
  swipeRules_picker_container: {
    overflow: 'hidden',
  },
  swipeRules_body_targetSelector_container: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    borderBottom: 'solid 1px rgba(255,255,255,.3)',
    marginBottom: '18px',
    textAlign: 'left',
  },
  swipeRules_body_targetSelector_leftIco_container: {
    width: '24.9px',
    height: '27.5px',
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  swipeRules_body_targetSelector_leftIco_svgIcon_root: {
    width: '100%',
    height: '100%',
  },
  swipeRules_body_targetSelector_info_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  swipeRules_body_targetSelector_info_firstRow_typography_root: {
    fontSize: '11px',
    lineHeight: '11.3px',
    letterSpacing: '0px',
    fontWeight: 600,
    marginLeft: '11.5px',
  },
  swipeRules_body_targetSelector_info_secondRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  swipeRules_body_targetSelector_info_secondRow_typography1_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  swipeRules_body_targetSelector_info_secondRow_input_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: theme.palette.primary.main,
    marginLeft: '11.5px',
    '&::placeholder': {
      color: 'rgba(255,255,255,.5)',
    },
  },
  swipeRules_body_targetSelector_info_secondRow_typography2_root: {
    fontSize: '16px',
    marginTop: '8px',
    lineHeight: '16.9px',
    color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  swipeRules_autocomplete_typography1_root: {
    fontSize: '16px',
  },
});

export default withStyles(styles, { withTheme: true })(ConfigureRule);

/* 
<RuleInput
          config={RuleInputConfig}
          classes={this.props.classes}
          baseUrl={this.props.baseUrl}
          token={this.props.token}
          rule={this.state.rule}
          definition={this.state.rulesDefinition}
          targetID={this.props.match.params.targetID}
        />
*/
