function generateStyles(theme){
    const styles = {
        listTargetMovements_divListContainer:{
            height: '55vh',
            minHeight: 312,
            overflow: 'auto',
            //background: theme.palette.primary.main
        },
        listTargetMovements_concepto_guion_root: {
            height: 8, 
            width: 8, 
            backgroundColor: '#f4137b',
            marginRight: 6
        },
        listTargetMovements_typography1_root: {
            fontSize: 12
        },

        listTargetMovements_typography2_root: {
            fontSize: 12
        },
        listTargetMovements_typography4_root: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8,
            backgroundColor: 'rgba(255,255,255,0.098)'
        },
        listTargetMovements_typography5_root: {
            paddingLeft: 16,
            paddingRight: 16
        },
        listTargetMovements_iconButton1_root:{
            padding: 'unset'
        }
    }
    return styles
}

export {
    generateStyles as ListTargetMovementsStyles
}