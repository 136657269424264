import { fonts } from './fonts';
import colors from './colors';
import componentsConfig from './components';

export default {
  ...componentsConfig,

  fontFamily: fonts,
  colors: {
    defaultFontColor: colors.black,
    main: colors.white,
    secondary: colors.pink,
  },
};
