function TargetRefundInfoStyles(theme) {
  return {
    container1: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 5,
    },
    container2: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      height: '25vh',
      minHeight: 142,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    targetRefundInfo_gridContainer2_container: {
      height: '45vh',
      minHeight: 256,
      marginTop: 5,
      border: 'solid 2px ' + theme.palette.secondary.main,
      padding: 8,
      alignItems: 'center',
    },
    targetRefundInfo_gridItemContainer1_item: {
      borderRight: '2px solid rgba(255,255,255,0.3)',
      padding: 5,
    },
    targetRefundInfo_gridItemContainer3_item: {
      marginTop: 10,
    },
    targetRefundInfo_gridItem1_item: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
    },
    targetRefundInfo_gridItem2_item: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
    },
    targetRefundInfo_gridItemContainer2_item: {
      padding: 5,
    },
    targetRefundInfo_gridItem3_item: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    targetRefundInfo_infoMoney_container: {
      backgroundColor: 'rgba(255,255,255,0.098)',
      padding: '10px',
      display: 'flex',
      borderRadius: '8px',
      width: '100%',
    },
    targetRefundInfo_gridItem4_item: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    targetRefundInfo_gridItem5_item: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    targetRefundInfo_gridItem6_item: {
      display: 'flex',
      justifyContent: 'center',
    },
    targetRefundInfo_gridItem9_item: {
      textAlign: 'center',
      marginTop: 10,
    },
    targetRefundInfo_typography1_root: {
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '18px',
      color: theme.palette.secondary.main,
    },
    targetRefundInfo_typography2_root: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 28,
    },
    targetRefundInfo_typography3_root: {
      textAlign: 'center',
    },
    targetRefundInfo_typography4_root: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 28,
    },
    targetRefundInfo_typography5_root: {
      marginTop: 10,
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 600,
    },
    targetRefundInfo_typography6_root: {
      textAlign: 'center',
      fontWeight: 600,
    },
    targetRefundInfo_typography7_root: {
      fontSize: 8,
    },
    targetRefundInfo_typography9_root: {
      fontWeight: 600,
    },
    targetRefundInfo_img1: {
      maxWidth: '100%',
    },
    targetRefundInfo_typographyPartial_root: {
      textAlign: 'center',
      fontWeight: 600,
      marginBottom: 15,
    },
    targetRefundInfo_input1_root: {
      width: '100%',
      border: 'solid 1px #000',
      marginTop: 10,
    },
    targetRefundInfo_input1_input: {
      textAlign: 'center',
    },
    targetRefundInfo_input1_disabled: {
      border: 'solid 1px #e8e8e8',
    },
    targetRefundInfo_button1_root: {
      borderRadius: 'unset',
      fontWeight: 600,
    },
    targetRefundInfo_button2_root: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      borderRadius: 'unset',
      fontWeight: 600,
      width: '100%',
    },
    targetRefundInfo_modal1_root: {
      display: 'flex',
      height: '100vh',
      minHeight: 568,
      overflow: 'auto',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    targetInfo_body_targetSelector_container: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      borderBottom: 'solid 1px rgba(255,255,255,.3)',
      marginBottom: '18px',
    },
    targetInfo_body_targetSelector_leftIco_container: {
      width: '28px',
      height: '28px',
      display: 'flex',
      padding: 0,
      margin: 0,
    },
    targetInfo_body_targetSelector_leftIco_svgIcon_root: {
      width: '100%',
      height: '100%',
    },
    targetInfo_body_targetSelector_info_container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    targetInfo_body_targetSelector_info_firstRow_typography_root: {
      fontSize: '11px',
      lineHeight: '11.3px',
      letterSpacing: '0px',
      fontWeight: 600,
      marginLeft: '11.5px',
      textAlign: 'left',
    },
    targetInfo_body_targetSelector_info_secondRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    targetInfo_body_targetSelector_info_secondRow_typography1_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
    targetInfo_body_targetSelector_info_secondRow_input_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: theme.palette.primary.main,
      marginLeft: '11.5px',
      '&::placeholder': {
        color: 'rgba(255,255,255,.5)',
      },
    },
    targetInfo_body_targetSelector_info_secondRow_typography2_root: {
      fontSize: '16px',
      marginTop: '8px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
  };
}

export default TargetRefundInfoStyles;
