function generateStyles(theme) {
  const styles = {
    dataContainer: {
      //backgroundColor: theme.palette.primary.main,
      height: '90%'
    },
    ruleInput_typography3_root:{
      textAlign: 'center'
    }
  }
  return styles
}

export { generateStyles as RuleInputStyles }
