const accounts_strings = {
  es: {
    accounts_time_text1: 'Actualizado hace',
    accounts_time_units1: 'minutos',
    accounts_time_units2: 'horas',
    accounts_time_units3: 'días',
    accounts_label_text1: 'redondeos',
    accounts_label_text2: 'cargos',
    accounts_button_text1: 'Save',
    accounts_button_text2: 'Ok',
    accounts_modal_text1:
      ' La cuenta de cargo es obligatoria y se necesita al menos una cuenta de redondeos',
  },
};

export default accounts_strings;
