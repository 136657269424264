export function validator(text, type) {
  const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const password = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w]{8,}$/;
  if (text === '' || text === null) {
    return true;
  } else {
    switch (type) {
      case 'email':
        return mail.test(String(text).toLocaleLowerCase());
      case 'password':
        return password.test(String(text));
      default:
        return true;
    }
  }
}

/*
  Formateo de números al estilo: "12,345.00 €"
*/
export function formatCurrency(num) {
  //return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  }).format(num); //.toFixed(2))
}

/**
 * @function
 * @param {string} filter
 * @param {object} classes
 * @return {object} returns component class correctly formated
 */
export function transformClasses(filter, classes) {
  const newClasses = {};

  Object.keys(classes).map(element => {
    if (element.indexOf(filter) > -1) {
      newClasses[element.substring(filter.length + 1)] = classes[element];
    }
    return null;
  });
  return newClasses;
}

/*
export function formatDate(date) {
  console.log("Utils.formatDate", date);

  const rawDate = new Date(date);
  console.log("Utils.formatDate raw", rawDate);

  const year = rawDate.getFullYear();
  console.log("Utils.formatDate raw", (1 + rawDate.getMonth()).toString());
  const month = (1 + rawDate.getMonth()).toString().padStart(2, '0');
  const day = (1 + rawDate.getDate()).toString().padStart(2, '0');
  return day +'/' + month + '/' + year.toString();
}
*/

export function formatDate(date) {
  //console.log('Utils.formatDate', date)
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

/*
  Función para tener una pila de navegación automática, para q al cerrar ventanas modales
  vuelva correctamente por el camino q hizo.
*/
export function flowNavigate(url, props, state) {
  //ºº Ver si utilizar sessionStorage

  let navigationStack = window.localStorage.getItem('navigationStack');
  let navigationState = window.localStorage.getItem('navigationState');
  console.log('flowNavigate ini', {
    props,
    state,
    navigationStack,
    navigationState,
  });

  navigationState = navigationState ? JSON.parse(navigationState) : null;

  if (!navigationStack) {
    // Si se crea la pila, añade la url actual como inicial
    navigationStack = [props.history.location.pathname];
  } else {
    navigationStack = JSON.parse(navigationStack);
    if (
      navigationStack[navigationStack.length - 1] !==
      props.history.location.pathname
    ) {
      navigationStack.push(props.history.location.pathname);
    }
  }
  // Añade a la pila de navegación
  navigationStack.push(url);
  navigationState = {
    ...navigationState,
    ...state,
  };

  window.localStorage.setItem(
    'navigationStack',
    JSON.stringify(navigationStack),
  );
  window.localStorage.setItem(
    'navigationState',
    JSON.stringify(navigationState),
  );

  console.log('flowNavigate fin', url, navigationStack, navigationState);

  props.history.push({
    pathname: url,
    state: navigationState,
  });

  /*
  this.props.history.push({
    pathname: url,
    state: {
      back: this.props.location.pathname,
    }
  })
  */
}

export function flowNavigateGoBack(history) {
  const navigationStack = JSON.parse(
    window.localStorage.getItem('navigationStack'),
  );
  const navigationState = JSON.parse(
    window.localStorage.getItem('navigationState'),
  );

  console.log('flowNavigateGoBack', navigationStack, navigationState);

  navigationStack.pop();
  window.localStorage.setItem(
    'navigationStack',
    JSON.stringify(navigationStack),
  );
  const back = navigationStack[navigationStack.length - 1];

  if (!history) {
    console.error(
      'Se llamó <Header modal={true}> y no se envio el parametro history={props.history}',
    );
  }
  console.log('flowNavigateGoBack fin', back, navigationStack, navigationState);
  history.push({
    pathname: back,
    state: navigationState,
  });
}

export function getFlowState() {
  return JSON.parse(window.localStorage.getItem('navigationState'));

  /*
  return props.history
    ? props.history.location
      ? props.history.location.state
      : {}
    : {}
  */
}
