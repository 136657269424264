import { connect } from 'react-redux';
import SurveyLanding from '../views/RiskSurvey/SurveyLanding';

function mapStateToProps(state) {
  console.log(state, 'STATE');
  const { token, baseUrl } = state.main;
  return {
    token,
    baseUrl,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyLanding);
