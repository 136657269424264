import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';

import { Utils } from '../../src-core/resources/index';
//import { BanksAPI, UsersAPI } from '../components-core/sdk';
import { BanksAPI } from '../../src-core/sdk/index';
import { UsersAPI } from '../../src-core/sdk/index';

import SwipeableViewsConfigurableConfig from '../swipeableViews/swipeableViewsConfigurable.config';
import SwipeableViewsConfigurableStyles from '../swipeableViews/swipeableViewsConfigurable.style';
import Flow from '../../components/Flow';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import Header from '../../components/MyHeader';
import ProductSelector from '../Product/ProductSelector';
import ProductSelectorStyle from '../Product/ProductSelector.style';
import ProductCard from '../Product/ProductCard';

/*
  Este componente monta los slides de productos que puede elegir
  el usuario, según su perfil de riesgo.
*/
class CreateTargetProducts extends Component {
  constructor(props) {
    super(props);

    const params = Flow.getParams();
    console.log('CreateTargetProducts.const params', params);
    this.state = {
      index: null,
      productId: params.productId ? params.productId : null,
      riskFactor: null,
      prodsForUser: null,
      lastProductId: params.lastProductId,
      doSurvey: false,
      surveyFetched: false,
    };
    console.log('CreateTargetProducts', props, this.state);
  }

  componentDidMount() {
    console.log('CreateTargetProduct.componentDidMount');
    this.getRiskFactor();

    //this.getSurveys();
    // if (this.props.doSurvey && this.props.match.params.type !== 'nextWish') {
    // 	this.props.history.push({
    // 		pathname: '/survey',
    // 		state: {
    // 			type: this.props.match.params.type,
    // 		},
    // 	});
    // }
  }

  // async getSurveys() {
  //   const response = await UsersAPI.getSurvey(
  //     this.props.baseUrl,
  //     'idd-evo',
  //     this.props.token,
  //   );
  //   if (response.status === 200) {
  //     const data = await response.json();
  //     const response2 = await UsersAPI.getUserSurveyResponse(
  //       this.props.baseUrl,
  //       'me',
  //       data._id,
  //       this.props.token,
  //     );
  //     if (response2.status === 200) {
  //       const data2 = await response2.json();
  //       console.log('CreateTargetProduct.getSurvey', data2);
  //       if (!data2.finished) {
  //         this.props.history.push({
  //           pathname: '/survey',
  //           state: {
  //             type: this.props.match.params.type,
  //             founds: this.props.founds,
  //           },
  //         });
  //       } else {
  //         console.log('CreateTargetProduct survey done');
  //       }
  //     } else if (response2.status === 404) {
  //       console.error('CreateTargetProduct response2 404');
  //       this.props.history.push({
  //         pathname: '/survey',
  //         state: {
  //           type: this.props.match.params.type,
  //           founds: this.props.founds,
  //         },
  //       });
  //     } else {
  //       console.error(
  //         'CreateTargetProduct error recuperando la respuesta de usuario a survey: ',
  //         response2.status,
  //       );
  //     }
  //   } else {
  //     console.error(
  //       'CreateTargetProduct error recuperando la survey: ',
  //       response.status,
  //     );
  //   }
  // }
  filterProducts() {
    //console.log("this.props", this.props);
    //console.log("this.state", this.state);
    console.log('filterProducts', this.props.founds, this.state.riskFactor);

    // Filtra los productos que el usuario puede utilizar
    let prodsForUser = {};
    Object.keys(this.props.founds).map(key => {
      // Filtro IDD
      if (this.props.founds[key].riskFactor <= this.state.riskFactor) {
        prodsForUser[key] = this.props.founds[key];
      }
      return null;
    });
    console.log(
      'filterProducts prodsForUser max',
      prodsForUser,
      prodsForUser.length,
    );
    this.setState({
      prodsForUser: prodsForUser,
      maxRiskFactor: Object.keys(prodsForUser).length,
    });
  }

  async getRiskFactor() {
    console.log('getRiskFactor 1');
    const response = await UsersAPI.getRiskFactor(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();

      console.log(data, 'DATA');
      this.setState({
        riskFactor: data.riskFactor,
      });
      // riskFactor === -1 Es que no hay encuesta del usuario
      // y hay que forzarle a hacerla
      if (data.riskFactor === -1) {
        this.props.history.push({
          pathname: '/survey',
          state: {
            type: this.props.match.params.type,
            founds: this.props.founds,
          },
        });
        return null;
      }

      console.log('getRiskFactor antes filterProducts');

      // Filtra los productos que pueda usar
      this.filterProducts();

      let productId = null;

      // Si tenemos guardado un productId, y entra dentro de sus posibles, intenta usarlo
      console.log(
        'getRiskFactor calc productId lastProductId',
        this.props.match.params.type,
        this.state.lastProductId,
        //this.state.prodsForUser[this.state.lastProductId],
      );
      console.log(
        'getRiskFactor calc productId lastProductId',
        this.state.prodsForUser[this.state.lastProductId],
      );
      if (
        //this.props.match.params.type !== 'nextWish' &&
        this.state.lastProductId &&
        this.state.prodsForUser[this.state.lastProductId]
      ) {
        productId = this.state.lastProductId;
      } else if (this.props.match.params.type === 'retirement') {
        if (data.riskFactor > 3) {
          Object.keys(this.state.prodsForUser).forEach((key, index) => {
            if (this.state.prodsForUser[key].riskFactor === 5) {
              productId = key;
            }
          });
          console.log('getRiskFactor calc productId retirement > 3', productId);
        } else {
          Object.keys(this.state.prodsForUser).forEach((key, index) => {
            if (this.state.prodsForUser[key].riskFactor === 2) {
              productId = key;
            }
          });
          console.log('getRiskFactor calc productId retirement <=2', productId);
        }

        // Sino coge el q puede
      } else {
        let bestProductForUser = null;
        console.error(this.state.prodsForUser, 'aqui');
        Object.keys(this.state.prodsForUser).forEach((key, index) => {
          //console.log(this.state.prodsForUser[key].riskFactor,data.riskFactor,);
          // Filtro IDD
          const item = this.state.prodsForUser[key];

          bestProductForUser = bestProductForUser
            ? item.riskFactor > bestProductForUser.riskFactor &&
              item.riskFactor <= data.riskFactor
              ? { ...item, productId: key }
              : bestProductForUser
            : { ...item, productId: key };
        });

        productId = bestProductForUser.productId;

        if (!productId) {
          console.error('getRiskFactor No encontró productId');
        }
      }

      console.log(
        'getRiskFactor productId: ',
        productId,
        this.state.prodsForUser,
        this.state.prodsForUser.constructor.name,
      );
      let index = 0;
      Object.keys(this.state.prodsForUser).forEach((key, i) => {
        if (this.state.prodsForUser[key] === productId) {
          index = i;
        }
      });
      this.setState({
        productId: productId,
        index: index,
      });
    } else {
      console.log(
        'getRiskFactor error recuperando el riskFactor del usuario ',
        response.status,
      );
    }
  }

  handleChange(index) {
    Flow.setParams({
      lastProductId: Object.keys(this.state.prodsForUser)[index],
    });
    this.setState({
      index: index,
      productId: Object.keys(this.state.prodsForUser)[index],
      lastProductId: Object.keys(this.state.prodsForUser)[index],
    });
  }

  setFound() {
    const data = {
      product: this.state.prodsForUser[this.state.productId].product,
      foundsConfiguration: [
        {
          foundCode: this.state.prodsForUser[this.state.productId].code,
          percentage: 100,
        },
      ],
    };

    console.log('patch data: ', data);

    BanksAPI.setProducts(
      this.props.baseUrl,
      this.props.targetID,
      data,
      this.props.token,
    ).then(response => {
      if (response.status === 200) {
        this.props.updateProduct(this.state.productId);
      } else {
        console.log('error with product');
      }
    });
  }

  goToNext() {
    this.setFound();

    this.props.history.push(this.props.next);
  }

  renderTabs() {
    const elements = Object.keys(this.state.prodsForUser).map(found => {
      return (
        <Tab
          key={this.state.prodsForUser[found].code}
          label={this.state.prodsForUser[found].name}
        />
      );
    });
    return elements;
  }

  onClickNewSurvey() {
    console.log('CreateTargetProduct.onClickNewSurvey');
    this.props.history.push({
      pathname: '/survey',
      state: {
        type: this.props.match.params.type,
        founds: this.props.founds,
      },
    });
  }

  renderProductRecomended() {
    console.log('renderProductRecomended 1', this.state);
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;

    // Si el conveniente es mayor q el posible, coge el anterior
    let recomendedItem = this.state.prodsForUser[this.state.productId];
    let product = this.state.prodsForUser[this.state.productId];
    if (!recomendedItem) {
      Object.keys(this.state.prodsForUser).forEach((key, i) => {
        recomendedItem = this.state.prodsForUser[key];
        product = this.state.prodsForUser[key];
        this.setState({ productId: key });
      });
    }

    console.log('CreateTargetProduct.renderProductRecomended', {
      prodForUser: this.state.prodsForUser,
      prodId: this.state.productId,
      recomendedItem: recomendedItem,
      state: this.state,
      product,
    });
    Flow.setParams({
      profitability: product.profitability,
      productName: product.name,
      pessimisticProfitability: product.pessimisticProfitability,
      optimisticProfitability: product.optimisticProfitability,
      metadata: this.props.metadata,
      productId: this.state.productId,
    });

    return (
      <Fragment>
        <Header
          title='Perfil de inversor'
          rigthAction={() =>
            this.props.history.push('/home/objetivos/familyTarget')
          }
        />
        <Body vAlign='top'>
          <div className={classes.targetProducts_container3}>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('targetProducts_typography1', classes)}
            >
              Este es tu perfil de inversor seleccionado.
            </Typography>
          </div>

          <ProductCard
            title={recomendedItem.title}
            name={recomendedItem.name}
            riskFactor={recomendedItem.riskFactor}
            maxRiskFactor={this.state.maxRiskFactor}
            profitability={recomendedItem.profitability}
            historicalPdf={recomendedItem.pdf_history}
            morePdf={recomendedItem.pdf_more}
            history={this.props.history}
            productId={this.state.productId}
            location={this.props.location}
          />

          <Button
            id='simularAportaciones'
            variant='contained'
            color='secondary'
            style={{
              marginTop: 15,
            }}
            //classes={transformClasses('targetProducts_button1', classes)}
            onClick={() => {
              this.setFound();

              const product = this.state.prodsForUser[this.state.productId];
              Flow.navigate('/product/simulatorTab/esperado', this.props, {
                profitability: product.profitability,
                productName: product.name,
                pessimisticProfitability: product.pessimisticProfitability,
                optimisticProfitability: product.optimisticProfitability,
                metadata: this.props.metadata,
                productId: this.state.productId,
              });
            }}
          >
            Simular aportaciones al plan
          </Button>

          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetProducts_recomended_typography1',
              classes,
            )}
            style={{ marginBottom: 60 }}
          >
            También puedes seleccionar{' '}
            <span
              onClick={() =>
                this.props.history.push(
                  '/create/target/' +
                    this.props.match.params.type +
                    '/products/selector',
                )
              }
              className={classes.targetProducts_recomended_typography2}
            >
              una configuración distinta
            </span>{' '}
            a la que te mostramos.
            <br />
            <br />
            Puedes volver a realizar tu{' '}
            <span
              onClick={() => this.onClickNewSurvey()}
              className={classes.targetProducts_recomended_typography2}
              id='repetirTest'
            >
              test de adecuación
            </span>
            .
          </Typography>
        </Body>

        <Footer>
          <Button
            variant='contained'
            color='secondary'
            classes={transformClasses('targetProducts_button1', classes)}
            onClick={this.goToNext.bind(this)}
            id='continueButton'
          >
            Continuar
          </Button>
        </Footer>
      </Fragment>
    );
  }

  renderProductSelection() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;

    let index = 0;
    Object.keys(this.state.prodsForUser).forEach((key, i) => {
      if (key === this.state.productId) {
        console.log(key, this.state.productId);
        index = i;
      }
    });
    console.log(
      'renderProductSelection',
      this.state.prodsForUser,
      this.state.productId,
      index,
    );

    return (
      <Fragment>
        <Header
          title='Perfil de inversor'
          rigthAction={() => {
            this.props.history.goBack();
          }}
        />
        <Body vAlign='top'>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetProducts_typography1', classes)}
          >
            Este es el listado de carteras disponibles según tu perfil inversor.
          </Typography>
          <ProductSelector
            prodsForUser={this.state.prodsForUser}
            config={{}}
            default={this.props.products}
            swipeableConfig={SwipeableViewsConfigurableConfig}
            classes={classes}
            byDefault={this.props.selected}
            targetID={this.props.targetID}
            token={this.props.token}
            baseUrl={this.props.baseUrl}
            updateProduct={this.props.updateProduct}
            index={index}
            handleChange={this.handleChange.bind(this)}
            maxRiskFactor={this.state.maxRiskFactor}
            userRiskFactor={this.state.riskFactor}
            history={this.props.history}
            location={this.props.location}
          />
          <div className={classes.stepperContainer}>
            <MobileStepper
              variant='dots'
              steps={Object.keys(this.state.prodsForUser).length}
              position='static'
              activeStep={index}
              classes={transformClasses('targetProducts_stepper', classes)}
            />
          </div>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetProducts_typography2', classes)}
          >
            *Rentabilidad neta anual. Recuerda que las rentabilidades pasadas no
            garantizan rentabilidads futuras.
          </Typography>
          <div style={{ height: 80 }} />
        </Body>

        <Footer>
          <Button
            variant='contained'
            color='secondary'
            classes={transformClasses('targetProducts_button1', classes)}
            onClick={() => {
              const product = this.state.prodsForUser[this.state.productId];
              Flow.navigate(
                '/create/target/' + this.props.match.params.type + '/products',
                this.props,
                {
                  profitability: product.profitability,
                  productName: product.name,
                  pessimisticProfitability: product.pessimisticProfitability,
                  optimisticProfitability: product.optimisticProfitability,
                  metadata: this.props.metadata,
                  productId: this.state.productId,
                },
              );
            }}
          >
            Seleccionar
          </Button>
        </Footer>
      </Fragment>
    );
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetsProduct_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  render() {
    return (
      <Switch>
        <Route
          path='/create/target/:type/products/selector'
          render={() =>
            this.state.prodsForUser !== null &&
            this.props.selected !== null &&
            this.state.riskFactor !== null
              ? this.renderProductSelection()
              : this.renderLoading()
          }
        />
        <Route
          render={() =>
            this.state.productId &&
            this.state.riskFactor &&
            this.state.prodsForUser
              ? this.renderProductRecomended()
              : this.renderLoading()
          }
        />
      </Switch>
    );
  }
}

const styles = theme => ({
  ...ProductSelectorStyle(theme),
  ...SwipeableViewsConfigurableStyles(theme),
  targetsProduct_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  targetProducts_recomended_container: {
    minHeight: '640px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
  },
  targetProducts_container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  targetProducts_container2: {
    marginTop: '26px',
    paddingLeft: '24px',
    paddingRight: '24px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetProducts_container3: {
    //marginTop: '26px',
    paddingLeft: '24px',
    paddingRight: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
  createTarget_headerButton_root: {
    color: '#000',
  },
  products_divImg: {
    width: '50%',
    height: '20vh',
    minHeight: 114,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  stepperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
  },
  targetProducts_stepper_dotActive: {
    backgroundColor: theme.palette.secondary.main + '!important',
    margin: '0 6px',
  },
  targetProducts_stepper_dot: {
    backgroundColor: theme.palette.primary.main,
    height: '10px',
    width: '11px',
    margin: '0 6px',
    borderRadius: 'unset',
  },
  targetProducts_containerHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  targetProducts_header_rigthIcon_root: {
    height: '19.8px',
    width: '19.8px',
    marginTop: '16px',
    marginRight: '23.2px',
  },
  targetProducts_header_leftIcon_root: {
    height: '19.8px',
    width: '19.8px',
    marginTop: '16px',
    marginLeft: '23.2px',
  },
  targetProducts_stepper_root: {
    backgroundColor: 'transparent',
  },
  productButton_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  productButton_container2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  targetProducts_button1_root: {
    width: '312px',
    height: '40px',
    marginBottom: '24px',
    marginTop: '34px',
  },
  targetProducts_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    width: '286px',
    marginTop: 5,
    marginBottom: 10,
  },
  targetProducts_typography2_root: {
    //marginTop: '29px',
    fontSize: '12px',
    textAlign: 'center',
  },
  swipeableViews_containerStyle: {
    width: '312px',
  },
  targetProducts_recomended_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: 0,
    lineHeight: '16px',
    marginTop: 20,
  },
  targetProducts_recomended_typography2: {
    textDecoration: 'underline',
  },
  products_historicoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 20,
    textDecoration: 'none',
  },
  products_fondosContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 10,
    borderLeft: 'solid 1px rgba(255,255,255,.3)',
    borderRight: 'solid 1px rgba(255,255,255,.3)',
    textDecoration: 'none',
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetProducts);
