import React from 'react';

import Body from '../../components/MyBody';
import Header from '../../components/MyHeader';
import MyText from '../../components/MyText';
import Flow from '../../components/Flow';

export default function ProductFund(props) {
  const params = Flow.getParams();
  console.log('params', params);
  return (
    <React.Fragment>
      <Header modal={true} title='Detalle del Fondo' history={props.history} />
      <Body vAlign='top' hAlign='initial'>
        <MyText id='fundName' style={{ fontSize: 30 }}>
          {params.fund.longName}
        </MyText>
        <MyText style={{ marginBottom: 30 }}>
          {params.fund.categoriaInversion} - {params.fund.divisa}
        </MyText>
        <MyText variant='h1'>Objetivos de Inversión</MyText>
        <MyText>{params.fund.objetivos}</MyText>

        <MyText variant='h1' style={{ marginTop: 20 }}>
          Características
        </MyText>

        {Object.keys(params.fund).map((name, i) => {
          console.log('caract', params.fund[name], i);
          let value = '';
          if (
            name !== 'objetivos' &&
            name !== 'url' &&
            name !== 'name' &&
            name !== 'notas' &&
            name !== 'longName'
          ) {
            value = (
              <Item
                key={i}
                name={mapeoNombres[name]}
                value={params.fund[name]}
              />
            );
          }
          return value;
        })}
        <MyText>{params.fund.notas}</MyText>
      </Body>
    </React.Fragment>
  );
}

function Item(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
      }}
    >
      <MyText variant={'bold'} style={{ width: '50%', textAlign: 'left' }}>
        {props.name}:
      </MyText>
      <MyText
        style={{
          lineHeight: '24px',
          width: '50%',
          textAlign: 'left',
          alignSelf: 'flex-end',
        }}
      >
        {'  '}
        {props.value}
      </MyText>
    </div>
  );
}

const mapeoNombres = {
  gestora: 'Gestora',
  codigoISIN: 'Código ISIM',
  codigoTicker: 'Código Ticker',
  divisa: 'Divisa clase de acción',
  claseCubierta: 'Clase Cubierta',
  reinversion: 'Reinversión de dividendos',
  fechaLanzamiento: 'Fecha de Lanzamiento',
  categoriaInversion: 'Categoría de Inversión',
  tamañoFondo: 'Tamaño del Fondo',
  claseFondo: 'Clase del Fondo',
  gastosCorrientes: 'Gastos corrientes totales de la clase del fondo',
};
