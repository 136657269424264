import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
//import Assignment from '@material-ui/icons/Assignment';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
//import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import SvgIcon from '@material-ui/core/SvgIcon';

import { Utils } from '../../../resources/index';

import { listDocuments_strings } from './text';

class ListDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIframe: false,
      iframeUrl: '',
      toggleTargets1: [],
      toggleTargets2: [],
      toggleTargets3: [],
    };
  }

  componentDidMount() {
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];
    this.props.targets.map((target, index) => {
      if (target.type === 'retirement') {
        arr3.push(false);
      }
      if (target.type === 'nextWish') {
        arr1.push(false);
      }
      if (target.type === 'familyTarget') {
        arr2.push(false);
      }
      return null;
    });
    this.setState({
      toggleTargets1: arr1,
      toggleTargets2: arr2,
      toggleTargets3: arr3,
    });
  }

  renderTargets(type) {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    console.log('Targets', this.props.targets);
    const elements = this.props.targets
      .filter(t => t.type === type)
      .map((target, index) => {
        return (
          <div
            key={target._id}
            className={classes.listDocuments_listItemContainer}
          >
            <ListItem
              {...config.listItem1}
              classes={transformClasses('listDocuments_listItem1', classes)}
              style={
                (type === 'retirement'
                ? this.state.toggleTargets3[index]
                : type === 'familyTarget'
                ? this.state.toggleTargets2[index]
                : this.state.toggleTargets1[index])
                  ? { paddingBottom: 0 }
                  : {}
              }
              onClick={() => this.toggle(type, index)}
            >
              <div className={classes.textTextContainer}>
                <Typography
                  {...config.typograhy2}
                  classes={transformClasses(
                    'listDocuments_typography3',
                    classes,
                  )}
                >
                  {target.name}
                </Typography>
              </div>
              <div className={classes.textActionContainer}>
                <IconButton
                  {...config.iconButton2}
                  classes={transformClasses(
                    'listDocuments_iconButton2',
                    classes,
                  )}
                >
                  <KeyboardArrowDown />
                </IconButton>
              </div>
            </ListItem>
            <Collapse
              {...config.collapse1}
              in={
                type === 'retirement'
                  ? this.state.toggleTargets3[index]
                  : type === 'familyTarget'
                  ? this.state.toggleTargets2[index]
                  : this.state.toggleTargets1[index]
              }
              classes={transformClasses('listDocuments_collapse1', classes)}
            >
              <List
                {...config.list2}
                classes={transformClasses('listDocuments_list2', classes)}
              >
                {this.renderTargetDocuments(target._id)}
              </List>
            </Collapse>
          </div>
        );
      });
    return elements;
  }

  renderTargetDocuments(id) {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    if (this.props.documents[id].length > 0) {
      const elements = this.props.documents[id].map(doc => {
        return (
          <ListItem
            {...config.listItem2}
            classes={transformClasses('listDocuments_listItem2', classes)}
            key={doc._id}
          >
            <div className={classes.docIconContainer}>
              <SvgIcon
                color='primary'
                {...config.iconButton3}
                classes={transformClasses('listDocuments_iconButton3', classes)}
              >
                <path d='M 19.923 0 C 21.027 0 21.93 0.931 21.93 2.068 L 21.93 21.932 C 21.93 23.069 21.027 24 19.923 24 L 5.006 24 C 3.903 24 3 23.069 3 21.932 L 3 5.218 L 3.01 5.22 C 3.038 5.027 3.128 4.844 3.285 4.709 L 8.489 0.197 C 8.576 0.122 8.674 0.069 8.777 0.038 L 8.779 0.028 L 8.942 0 L 19.923 0 Z M 20.525 21.932 L 20.525 2.068 C 20.525 1.732 20.25 1.448 19.923 1.448 L 10.215 1.448 L 10.213 7.556 L 6.693 7.556 L 6.693 6.109 L 8.81 6.109 L 8.81 2.08 L 4.405 5.899 L 4.405 21.932 C 4.405 22.268 4.68 22.552 5.006 22.552 L 19.923 22.552 C 20.25 22.552 20.525 22.268 20.525 21.932 Z M 17.037 15.821 L 7.807 15.821 C 7.42 15.821 7.105 15.497 7.105 15.097 C 7.105 14.698 7.42 14.374 7.807 14.374 L 17.037 14.374 C 17.425 14.374 17.739 14.698 17.739 15.097 C 17.739 15.497 17.425 15.821 17.037 15.821 Z M 17.037 19.147 L 7.807 19.147 C 7.42 19.147 7.105 18.823 7.105 18.423 C 7.105 18.024 7.42 17.7 7.807 17.7 L 17.037 17.7 C 17.425 17.7 17.739 18.024 17.739 18.423 C 17.739 18.823 17.425 19.147 17.037 19.147 Z M 17.037 12.512 L 7.807 12.512 C 7.42 12.512 7.105 12.188 7.105 11.788 C 7.105 11.389 7.42 11.065 7.807 11.065 L 17.037 11.065 C 17.425 11.065 17.739 11.389 17.739 11.788 C 17.739 12.188 17.425 12.512 17.037 12.512 Z' />
              </SvgIcon>
            </div>
            <div className={classes.docTextContainer}>
              <Typography
                {...config.typograhy3}
                classes={transformClasses('listDocuments_typography4', classes)}
              >
                {doc.name}
              </Typography>
              <Typography
                {...config.typograhy4}
                classes={transformClasses('listDocuments_typography5', classes)}
              >
                {this.mapDocStatus(doc)}
              </Typography>
              {this.renderDocAction(doc)}
            </div>
          </ListItem>
        );
      });
      return elements;
    } else {
      return (
        <ListItem
          {...config.listItem3}
          classes={transformClasses('listDocuments_listItem3', classes)}
          key={'empty'}
        >
          <Typography
            {...config.typograhy6}
            classes={transformClasses('listDocuments_typography6', classes)}
          >
            {listDocuments_strings['es'].listDocuments_empty_text}
          </Typography>
        </ListItem>
      );
    }
  }

  toggle(type, index) {
    const arr =
      type === 'retirement'
        ? this.state.toggleTargets3.slice()
        : type === 'familyTarget'
        ? this.state.toggleTargets2.slice()
        : this.state.toggleTargets1.slice();
    arr[index] = !arr[index];
    if (type === 'retirement') {
      this.setState({
        toggleTargets3: arr,
      });
    }
    if (type === 'nextWish') {
      this.setState({
        toggleTargets1: arr,
      });
    }
    if (type === 'familyTarget') {
      this.setState({
        toggleTargets2: arr,
      });
    }
  }
  mapDocStatus(doc) {
    switch (doc.status) {
      case 'SIGNED':
        return 'Firmado';
      case 'REJECTED':
        return 'Rechazado';
      case 'CANCELED':
        return 'Rechazado';
      default:
        return 'Pendiente';
    }
  }
  renderDocAction(doc) {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    console.log('DOC', doc);
    switch (doc.status) {
      case 'PENDING':
        return (
          <Typography
            {...config.typograhy5}
            classes={transformClasses('listDocuments_typography7', classes)}
            onClick={() => this.props.sign(doc.signUrl)}
          >
            {listDocuments_strings['es'].listDocuments_option_text1}
          </Typography>
        );
      case 'SIGNED':
      case 'CANCELED':
      case 'REJECTED':
        return (
          <a
            href={this.props.baseUrl.slice(0, -5) + doc.file}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.listDocuments_link}
          >
            {listDocuments_strings['es'].listDocuments_option_text2}
          </a>
        );
      default:
        return <div />;
    }
  }

  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.toggleTargets !== null ? (
      <div>
        <Typography
          {...config.typograhy5}
          classes={transformClasses('listDocuments_typography2', classes)}
        >
          Monedero
        </Typography>
        <List
          {...config.list1}
          classes={transformClasses('listDocuments_list1', classes)}
        >
          {this.renderTargets('nextWish')}
        </List>

        <Typography
          {...config.typograhy5}
          classes={transformClasses('listDocuments_typography2', classes)}
        >
          Hucha
        </Typography>
        <List
          {...config.list1}
          classes={transformClasses('listDocuments_list1', classes)}
        >
          {this.renderTargets('familyTarget')}
        </List>

        <Typography
          {...config.typograhy5}
          classes={transformClasses('listDocuments_typography2', classes)}
        >
          Caja Fuerte
        </Typography>
        <List
          {...config.list1}
          classes={transformClasses('listDocuments_list1', classes)}
        >
          {this.renderTargets('retirement')}
        </List>
      </div>
    ) : (
      <div />
    );
  }
}

export default ListDocuments;
