import React, { Component } from 'react';
import List from '@material-ui/core/List';
import { listTargetMovements_strings } from './text';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { Utils } from '../../../resources/index';
import { pensionTransferStatus } from '../../../../libs/pensionTransferStatus';
import * as S from './styles';
import colors from '../../../../config/colors';
import Icon from '../../../../components/MyIcon';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import { getCurrency } from '../../../../libs/Currency';
// import { MuiThemeProvider } from '@material-ui/core';

const regex_date = new RegExp('([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})');
class ListTargetsMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: null,
      openPensionTransferInfo: null,
      openPensionTransferStatus: null,
    };
  }

  renderListItems() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.movements.map(element => {
      return (
        <ListItem
          {...config.listItem1}
          key={element._id}
          classes={transformClasses('listTargetMovements_listItem1', classes)}
        >
          <div className={classes.movements_confe_container}>
            <div className={classes.movements_concepto_container}>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'listTargetMovements_typography1',
                  classes,
                )}
              >
                {element.concept}
              </Typography>
            </div>
            <div className={classes.movements_fecha_container}>
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'listTargetMovements_typography2',
                  classes,
                )}
              >
                {new Date(element.visibleDate).toLocaleDateString('es-ES')}
              </Typography>
            </div>
          </div>
          <div className={classes.movements_ammount_container}>
            <Typography
              {...config.typography3}
              classes={transformClasses(
                'listTargetMovements_typography3',
                classes,
              )}
            >
              {Utils.formatCurrency(element.amount)}
            </Typography>
          </div>
          <div className={classes.movements_icon_container}>
            <IconButton
              {...config.iconButton1}
              classes={transformClasses(
                'listTargetMovements_iconButton1',
                classes,
              )}
              onClick={() => this.props.goToDetails(element._id)}
            >
              <MoreVert />
            </IconButton>
          </div>
        </ListItem>
      );
    });
    return elements;
  }

  renderNext() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const element = this.props.nextMovement;
    return (
      <ListItem
        {...config.listItem1}
        key={element._id}
        classes={transformClasses('listTargetMovements_listItem1', classes)}
      >
        <div className={classes.pinkSquare} />
        <div className={classes.movements_confe_container}>
          <div className={classes.movements_concepto_container}>
            <Typography
              {...config.typography1}
              classes={transformClasses(
                'listTargetMovements_typography1',
                classes,
              )}
            >
              {element.concept}
            </Typography>
          </div>
          <div className={classes.movements_fecha_container}>
            <Typography
              {...config.typography2}
              classes={transformClasses(
                'listTargetMovements_typography2',
                classes,
              )}
            >
              {new Date(element.visibleDate).toLocaleDateString('es-ES')}
            </Typography>
          </div>
        </div>
        <div className={classes.movements_ammount_container}>
          <Typography
            {...config.typography3}
            classes={transformClasses(
              'listTargetMovements_typography3',
              classes,
            )}
          >
            {Utils.formatCurrency(element.amount)}
          </Typography>
        </div>
        <div className={classes.movements_icon_container}>
          <IconButton
            {...config.iconButton1}
            classes={transformClasses(
              'listTargetMovements_iconButton1',
              classes,
            )}
            onClick={() => this.props.goToNextDetails(element.movementLines)}
          >
            <MoreVert />
          </IconButton>
        </div>
      </ListItem>
    );
  }
  renderPensionTransfers() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;

    const elements = this.props.pensionTransfers.map(transfer => {
      const {
        IconStatus,
        status,
        importe,
        applicationDate,
      } = pensionTransferStatus(transfer);

      let formatDate = null;
      if (applicationDate) {
        // formatDate: [all , year , day , month]
        try {
          formatDate = regex_date.exec(applicationDate);
        } catch (error) {
          formatDate = null;
        }
      }

      console.log({ formatDate });
      return (
        <ListItem
          {...config.listItem1}
          key={transfer._id}
          classes={transformClasses('listTargetMovements_listItem1', classes)}
        >
          <S.PensionTransferWrapper>
            <S.PensionTransferStatus>
              <S.PensionTransferStatusItem>
                <p style={{ margin: '0px 5px 0px 0px' }}>
                  {transfer.ind_entrada_salida === 'E' ? 'Entrada' : 'Salida'}
                </p>
                <IconStatus
                  onClick={() =>
                    this.setState({
                      openPensionTransferStatus:
                        status === 'success'
                          ? 'Traspaso finalizado'
                          : status === 'rejected'
                          ? transfer.explicacion
                          : 'Traspaso en curso',
                    })
                  }
                  style={{
                    color:
                      status === 'success'
                        ? colors.iconGreen
                        : status === 'pending'
                        ? colors.iconYellow
                        : colors.pink,
                    paddingBottom: 5,
                  }}
                />
                {status === 'success' && (
                  <p style={{ margin: '0px 0px 0px 5px' }}>
                    {getCurrency(importe, 2)}
                  </p>
                )}
                {status === 'pending' && formatDate?.length > 3 && (
                  <p style={{ margin: '0px 0px 0px 5px' }}>
                    {moment(
                      `${formatDate[0]}`,
                      `YYYY-${formatDate[2].length === 1 ? 'M' : 'MM'}-${
                        formatDate[3].length === 1 ? 'D' : 'DD'
                      }`,
                    ).format('D/M/YYYY')}
                  </p>
                )}
              </S.PensionTransferStatusItem>
              <div
                onClick={() =>
                  this.setState({ openPensionTransferInfo: transfer })
                }
              >
                <Icon
                  name='info'
                  style={{
                    width: 15,
                  }}
                />
              </div>
            </S.PensionTransferStatus>
            {status === 'rejected' && (
              <>
                {/* <S.PensionTransferStatusSituation>
                  {transfer.SITUACION_c}
                </S.PensionTransferStatusSituation> */}
                <S.PensionTransferStatusExplication>
                  {transfer.explicacion}
                </S.PensionTransferStatusExplication>
              </>
            )}
            {/* {status === 'success' && (
              <>
                <S.PensionTransferStatusSituation>
                  Traspaso finalizado
                </S.PensionTransferStatusSituation>
                <S.PensionTransferStatusExplication>
                  {`Importe: ${getCurrency(importe, 2)}`}
                </S.PensionTransferStatusExplication>
              </>
            )} */}
            {/* {status === 'pending' && (
              <>
                <S.PensionTransferStatusSituation>
                  Traspaso en curso
                </S.PensionTransferStatusSituation>
                <S.PensionTransferStatusExplication>
                  {`Fecha solicitud: ${moment(applicationDate).format(
                    'D/M/YYYY',
                  )}`}
                </S.PensionTransferStatusExplication>
              </>
            )} */}

            {/* solicitud fecha: {moment(transfer.)} */}
          </S.PensionTransferWrapper>
        </ListItem>
      );
    });

    return elements;
  }

  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.listTargetMovements_divListContainer}>
        <Typography
          {...config.typography4}
          classes={transformClasses('listTargetMovements_typography4', classes)}
        >
          {listTargetMovements_strings['es'].listTargetMovements_header_text1}
        </Typography>
        <List children={this.renderNext()} />
        {this.props?.pensionTransfers?.length > 0 && (
          <>
            <Typography
              {...config.typography4}
              classes={transformClasses(
                'listTargetMovements_typography4',
                classes,
              )}
            >
              Traspasos
            </Typography>
            <List children={this.renderPensionTransfers()} />
            <ModalInfoStatus
              open={this.state.openPensionTransferStatus}
              onClose={() => this.setState({ openPensionTransferStatus: null })}
            >
              {this.state.openPensionTransferStatus}
            </ModalInfoStatus>
            <ModalInfo
              open={this.state.openPensionTransferInfo}
              onClose={() => this.setState({ openPensionTransferInfo: null })}
              transfer={this.state.openPensionTransferInfo}
            />
          </>
        )}
        <Typography
          {...config.typography4}
          classes={transformClasses('listTargetMovements_typography4', classes)}
        >
          {listTargetMovements_strings['es'].listTargetMovements_header_text2}
        </Typography>
        <List children={this.renderListItems()} />
      </div>
    );
  }
}

function ModalInfo(props) {
  return !props?.transfer ? null : (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => props.onClose(false)}
    >
      <S.ModalWrapper>
        {/* Origin */}
        <S.TableTitle>Origen</S.TableTitle>
        <S.TableContainer>
          <S.TableRow>
            {data.headings.map(label => (
              <S.TableHeading label={label}>{label}</S.TableHeading>
            ))}
          </S.TableRow>
          <S.TableRow>
            <S.TableItem style={{ fontWeight: 'bold' }}>Plan</S.TableItem>
            <S.TableItem>{props.transfer.dgs_plan_origen}</S.TableItem>
            <S.TableItem>{props.transfer.nombre_plan_origen}</S.TableItem>
          </S.TableRow>
          <S.TableRow>
            <S.TableItem style={{ fontWeight: 'bold' }}>Cartera</S.TableItem>
            <S.TableItem>{props.transfer.dgs_fondo_origen}</S.TableItem>
            <S.TableItem></S.TableItem>
          </S.TableRow>
          <S.TableRow>
            <S.TableItem style={{ fontWeight: 'bold' }}>Gestora</S.TableItem>
            <S.TableItem>{props.transfer.dgs_gestora_origen}</S.TableItem>
            <S.TableItem>{props.transfer.nombre_gestora_origen}</S.TableItem>
          </S.TableRow>
        </S.TableContainer>

        {/* destino */}
        <S.TableTitle>Destino</S.TableTitle>
        <S.TableContainer>
          <S.TableRow>
            {data.headings.map(label => (
              <S.TableHeading label={label}>{label}</S.TableHeading>
            ))}
          </S.TableRow>
          <S.TableRow>
            <S.TableItem style={{ fontWeight: 'bold' }}>Plan</S.TableItem>
            <S.TableItem>{props.transfer.dgs_plan_destino}</S.TableItem>
            <S.TableItem>{props.transfer.nombre_plan_destino}</S.TableItem>
          </S.TableRow>
          <S.TableRow>
            <S.TableItem style={{ fontWeight: 'bold' }}>Cartera</S.TableItem>
            <S.TableItem>{props.transfer.dgs_fondo_destino}</S.TableItem>
            <S.TableItem></S.TableItem>
          </S.TableRow>
          <S.TableRow>
            <S.TableItem style={{ fontWeight: 'bold' }}>Gestora</S.TableItem>
            <S.TableItem>{props.transfer.dgs_gestora_destino}</S.TableItem>
            <S.TableItem>{props.transfer.nombre_gestora_destino}</S.TableItem>
          </S.TableRow>
        </S.TableContainer>
      </S.ModalWrapper>
    </Modal>
  );
}
export function ModalInfoStatus({ children, open, onClose }) {
  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => onClose(false)}
    >
      <S.ModalWrapper>{children}</S.ModalWrapper>
    </Modal>
  );
}

export default ListTargetsMovements;

const data = {
  headings: ['', 'Codigos', 'Nombre'],
};
