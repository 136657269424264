import React, { useEffect } from 'react';

export default function MyCurrencyInput(props) {
	const [widthText, setWidthText] = React.useState(0);
	//const [font, setFont] = React.useState();
	const [inputRef, setInputRef] = React.useState(null);

	function getTextWidth(text, font) {
		// re-use canvas object for better performance
		var canvas =
			getTextWidth.canvas ||
			(getTextWidth.canvas = document.createElement('canvas'));
		var context = canvas.getContext('2d');
		context.font = font;
		var metrics = context.measureText(text);
		//console.log('Textw', text, metrics.width);
		return metrics.width;
	}
	//console.log('MyCurrencyInput', props);

	useEffect(() => {
		if ((props.value + '').length > 0) {
			let floatValue = parseFloat('0' + props.value);
			setWidthText(getTextWidth(floatValue + '', '16px Calibre') + 2);
		} else {
			setWidthText(
				getTextWidth(
					props.placeholder ? props.placeholder : '0',
					'16px Calibre',
				),
			);
		}
	}, [props.value]);

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'left',
				justifyContent: 'left',
				...props.style,
			}}
		>
			<input
				id={props.id}
				ref={e => {
					setInputRef(e);
				}}
				type='number'
				min='0'
				step='any'
				pattern='^\d+(?:\.\d{1,2})?$'
				className={props.class}
				style={{
					padding: '6px 0 7px',
					borderStyle: 'hidden',
					backgroundColor: '#ffffff00',
					fontFamily: 'Calibre',
					width: widthText,
					textAlign: 'right',
				}}
				placeholder={props.placeholder ? props.placeholder : '0'}
				onChange={event => {
					let floatValue = parseFloat('0' + event.target.value);
					event.target.value = floatValue;

					if ((floatValue + '').length > 0) {
						setWidthText(getTextWidth(floatValue + '', '16px Calibre') + 2);
					}

					// console.log(
					//   'onchange',
					//   event.target.value,

					//   floatValue,
					//   event.target.style,
					// );
					// setFont(
					//   event.target.style.fontFamily +
					//     ' ' +
					//     window
					//       .getComputedStyle(event.target, null)
					//       .getPropertyValue('font-size'),
					// );
					props.onChange(event, event.target, floatValue);
				}}
				value={props.value}
			/>
			<input
				className={props.class}
				//disabled
				style={{
					padding: '6px 0 7px',
					borderStyle: 'hidden',
					backgroundColor: '#ffffff00',
					fontFamily: 'Calibre',
					textAlign: 'left',
					width: '100%',
					marginLeft: 0,
				}}
				value={' ' + (props.suffix ? props.suffix : '€')}
				//+ widthText + ' ' + font}
				onClick={() => {
					inputRef.focus();
				}}
				onChange={() => {
					// React da un error si no hay onChange
					console.log();
				}}
			/>
		</div>
	);
}
