import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

//import { RulesAPI } from '../../core/
import { RulesAPI } from '../../src-core/sdk/index';
import { TargetRulesListStyles } from '../../src-core/rules/index';
//import { Utils } from '../../core/ces';
import { Utils } from '../../src-core/resources/index';

import { ListStyles } from '../../src-core/ui/index';
//import { ListStyles } from '../../src-core/ui/index'

import Currency from '../../components/Currency';

class FollowupRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: null,
      openDialog: false,
    };
  }

  componentDidMount() {
    if (this.props.rules === null) {
      this.getTargetRules();
    }
    if (this.props.rulesDefinitions === null) {
      this.getRulesDefinitions();
    }
  }

  /*
  getTargetRules() {
    RulesAPI.getTargetRules(
      this.props.baseUrl,
      this.props.targetID,
      this.props.token
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw response.status
        }
      })
      .then(data => {
        console.log('rules: ', data)
        this.props.updateRules(data)
      })
      .catch(error => {
        console.log('error recuperando las reglas del target ', error)
      })
  }
  */

  async getTargetRules() {
    console.log('followupRules.getTargetRules', {
      baseUrl: this.props.baseUrl,
      targetId: this.props.targetID,
      token: this.props.token,
    });
    const data = await RulesAPI.getTargetRules(
      this.props.baseUrl,
      this.props.targetID,
      this.props.token,
    );
    console.log('followupRules.getTargetRules', data);
    this.props.updateRules(data);
  }

  getRulesDefinitions() {
    /*
    RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw response.status
        }
      })
      */
    RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token)
      .then(data => {
        console.log('FollowupRules.ruleDefinitions: ', data);
        this.props.updateRulesDefinitions(data);
      })
      .catch(error => {
        console.log('error recuperando las definiciones de reglas ', error);
      });
  }

  activateRule(id, index) {
    RulesAPI.setActive(
      this.props.baseUrl,
      id,
      { status: true },
      this.props.token,
    );
    const rules = this.props.rules;
    rules[[index]].isActive = true;
    this.setState({
      rules: rules,
    });
  }

  deactivateRule(id, index) {
    RulesAPI.setActive(
      this.props.baseUrl,
      id,
      { status: false },
      this.props.token,
    );
    const rules = this.props.rules;
    rules[[index]].isActive = false;
    this.setState({
      rules: rules,
    });
  }

  configureRule(id, ruleDef) {
    if (id !== 'new_rule') {
      //console.log('configureRule new back: ', this.props.location.pathname)
      this.props.history.push({
        pathname: '/configure/rule/' + id,
        state: {
          back: this.props.location.pathname,
        },
      });
    } else {
      console.log('no rule man');
      this.props.history.push({
        pathname:
          '/configure/rule/' +
          id +
          '/' +
          ruleDef +
          '/' +
          this.props.match.params.id,
        state: {
          back: this.props.location.pathname,
        },
      });
    }
  }

  movements(id) {
    this.props.history.push(
      '/followup/' + this.props.match.params.id + '/Reglas/movimientos/' + id,
    );
  }

  createRule(id) {
    if (this.props.rules.length < this.props.rulesDefinitions.length) {
      this.props.history.push('/create/rule/' + id);
    } else {
      this.setState({
        openDialog: true,
      });
    }
  }

  closeDialog() {
    this.setState({
      openDialog: false,
    });
  }

  renderTitleList() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.followupRules_titleListContainer}>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('followupRules_title_typography1', classes)}
        >
          REGLAS ACTIVAS
        </Typography>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('followupRules_title_typography2', classes)}
        >
          Total
        </Typography>
      </div>
    );
  }

  checkDisabled() {
    if (this.props.status === 'FINISHED' || this.props.status === 'CANCELED') {
      return true;
    } else {
      return false;
    }
  }

  nothing() {
    return null;
  }

  renderActiveRules() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    console.log(this.props);
    const elements = [];
    this.props.rules.forEach(rule => {
      console.log({ rule });
      if (rule.isActive) {
        elements.push(
          <div
            className={classes.followupRules_ruleListContainer}
            key={rule._id}
          >
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'followupRules_ruleTitle_typography1',
                classes,
              )}
            >
              {rule.ruleDefinition.name}
            </Typography>
            <div className={classes.totalContainer}>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'followupRules_ruleTitle_typography2',
                  classes,
                )}
              >
                {Currency(rule.ruleAmounts.total, 2)}
              </Typography>
              <KeyboardArrowRight
                color='primary'
                onClick={() =>
                  rule?.ruleDefinition?.identifier === 'PUNTUAL_SAVING'
                    ? this.props.history.push(`/ahorroHot/${rule?.target}`)
                    : this.checkDisabled()
                    ? this.nothing()
                    : this.configureRule(rule._id)
                }
              />
            </div>
          </div>,
        );
      }
    });
    return elements;
  }

  renderTitleList2() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.followupRules_titleListContainer}>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('followupRules_title_typography1', classes)}
        >
          REGLAS INACTIVAS
        </Typography>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('followupRules_title_typography2', classes)}
        >
          Total
        </Typography>
      </div>
    );
  }

  renderInactiveRules() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;

    const elements = [];
    console.log(this.props);
    this.props.rules.forEach(rule => {
      if (
        rule.ruleDefinition.identifier === 'COVID_SAVING' ||
        rule.ruleDefinition.identifier === 'CANCER_DONATION' ||
        rule.ruleDefinition.identifier === 'COFFEE_SAVING' ||
        rule.ruleDefinition.identifier === 'SMOKE_SAVING'
      )
        return;

      if (
        this.props.type === 'retirement' &&
        (rule.ruleDefinition.identifier === 'NOMINA_SAVING' ||
          rule.ruleDefinition.identifier === 'TEAM' ||
          rule.ruleDefinition.identifier === 'COINSCRAP_ROUNDS')
      )
        return;
      if (!rule.isActive) {
        elements.push(
          <div
            className={classes.followupRules_ruleListContainer}
            key={rule._id}
          >
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'followupRules_ruleInactiveTitle_typography1',
                classes,
              )}
            >
              {rule.ruleDefinition.name}
            </Typography>
            <div className={classes.totalContainer}>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'followupRules_ruleInactiveTitle_typography2',
                  classes,
                )}
              >
                {Currency(rule.ruleAmounts.total, 2)}
              </Typography>
              <KeyboardArrowRight
                color='primary'
                onClick={() =>
                  this.checkDisabled()
                    ? this.nothing()
                    : this.configureRule(rule._id)
                }
              />
            </div>
          </div>,
        );
      }
    });
    return elements;
  }

  renderNotConfiguredRules() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const inactive = [];
    this.props.rulesDefinitions
      .filter(item => {
        if (
          item.identifier === 'COVID_SAVING' ||
          item.identifier === 'CANCER_DONATION' ||
          item.identifier === 'COFFEE_SAVING' ||
          item.identifier === 'SMOKE_SAVING' ||
          (this.props.type === 'retirement' &&
            (item.identifier === 'NOMINA_SAVING' ||
              item.identifier === 'TEAM' ||
              item.identifier === 'COINSCRAP_ROUNDS'))
        )
          return false;
        else return true;
      })
      .forEach(ruleDef => {
        let exists = false;
        this.props.rules.forEach(rule => {
          if (ruleDef._id === rule.ruleDefinition._id) {
            exists = true;
          }
        });
        if (!exists) {
          inactive.push(ruleDef);
        }
      });

    const elements = inactive.map(rule => (
      <div className={classes.followupRules_ruleListContainer} key={rule._id}>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses(
            'followupRules_ruleInactiveTitle_typography1',
            classes,
          )}
        >
          {rule.name}
        </Typography>
        <div className={classes.totalContainer}>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'followupRules_ruleInactiveTitle_typography2',
              classes,
            )}
          >
            {0} €
          </Typography>
          <KeyboardArrowRight
            color='primary'
            onClick={() =>
              this.checkDisabled()
                ? this.nothing()
                : this.configureRule('new_rule', rule._id)
            }
          />
        </div>
      </div>
    ));
    return elements;
  }

  render() {
    const { classes } = this.props;
    console.log('followupRules.render', this.props.rulesDefinitions);
    return this.props.rules !== null && this.props.rulesDefinitions !== null ? (
      <Fragment>
        <div className={classes.followupRules_shortList}>
          {this.renderTitleList()}
          {this.renderActiveRules()}
        </div>
        <div className={classes.followupRules_shortList}>
          {this.renderTitleList2()}
          {this.renderInactiveRules()}
          {this.renderNotConfiguredRules()}
        </div>
      </Fragment>
    ) : (
      <div className={classes.followupRules_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

const styles = theme => ({
  ...TargetRulesListStyles(theme),
  ...ListStyles(theme),
  targetRulesList_button2_root: {
    width: '100%',
    margin: 'unset',
    marginTop: 5,
    fontWeight: 600,
    borderRadius: 'unset',
  },
  targetRulesList_cardActions1_root: {
    padding: 'unset',
    display: 'block',
  },
  targetRulesList_cardActionsDiv: {
    margin: 'unset',
  },
  followupRules_shortList: {
    width: '100%',
    padding: '10px 12px',
    overflow: 'auto',
  },
  followupRules_shortListButton: {
    width: '100%',
    textAlign: 'center',
  },
  followupRules_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  targetRulesList_iconButton1_root: {
    fontWeight: 600,
  },
  followupRules_titleListContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    marginBottom: '12px',
    borderBottom: '1px solid rgba(255,255,255,0.098)',
  },
  followupRules_title_typography1_root: {
    fontWeight: 600,
    fontSize: '14px',
  },
  followupRules_title_typography2_root: {
    fontSize: '12px',
  },
  followupRules_ruleListContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 0',
  },
  followupRules_ruleTitle_typography1_root: {
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '4px',
  },
  followupRules_ruleTitle_typography2_root: {
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '4px',
  },
  followupRules_ruleInactiveTitle_typography1_root: {
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '4px',
    color: 'rgba(255,255,255,.5)',
  },
  followupRules_ruleInactiveTitle_typography2_root: {
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '4px',
    color: 'rgba(255,255,255,.5)',
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles, { withTheme: true })(FollowupRules);
