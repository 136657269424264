module.exports = {
  // This configuration is dynamic by environment: production, staging and local
  apiUrl:
    window.__CLIENT_ENV && window.__CLIENT_ENV.CLIENT_ENV_API_BASE_URL
      ? window.__CLIENT_ENV.CLIENT_ENV_API_BASE_URL
      : 'https://api.evo.coinscrap.com/api/',
  // '--apiUrl': 'https://api.evo.coinscrap.com/api/',
  '--apiUrl': 'https://api.evo-staging.coinscrap.com/api/',
  dev: {
    familyTargetActivo: true,
  },
  prod: {
    familyTargetActivo: false,
  },
};
