import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import { Utils } from '../../src-core/resources/index';

import MyIcon from '../../components/MyIcon';
import Icons from '../../components/MyIcon.config';
import DocView from '../../components/MyDocView';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

export default function CreateTargetConditionsWish(props) {
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalUrl, setModalUrl] = React.useState(null);
  const [modalTitle, setModalTitle] = React.useState(null);
  const [readSeveralDocs, setReadSeveralDocs] = React.useState({});

  const { classes } = props;
  const transformClasses = Utils.transformClasses;

  console.log('CreateTargetConditionsWish', props);

  return (
    <div className='container'>
      <Header
        title={'Resumen ' + props.target.name}
        rigthAction={() => props.history.push('/home/objetivos')}
      />
      <Body vAlign='top'>
        <MyIcon name='check' style={{ height: 80 }} />

        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses(
            'targetSummary_company_label_typography',
            classes,
          )}
        >
          {props.founds[props.product + props.productIdentifier].label}
        </Typography>
        <div
          className={classes.targetSummary_renta_container}
          style={{ paddingBottom: 10 }}
        >
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetSummary_renta_typography1',
              classes,
            )}
          >
            Tipo de Interes Técnico
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetSummary_renta_typography2',
              classes,
            )}
          >
            {(
              props.founds[props.product + props.productIdentifier]
                .profitability * 100
            ).toFixed(2)}{' '}
            %
          </Typography>
          {/*ºº
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('targetSummary_renta_typography3',classes)}
            >
              *Rentabilidad acumulado del fondo según ficha de la cartera.
            </Typography>
            */}
        </div>

        {/* Datos fundamentales */}
        <div
          style={{
            marginTop: 40,
            width: '100%',
            display: 'none',
            /* ºº Se oculta para monedero, hay que controlar despues para mostrarlo en los otros casos */
          }}
        >
          <div
            className={classes.cargos_login_disclaimer_link}
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={() => {
              setModalUrl(
                'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/caser/Nota+Informativa+%2B+CCPP.PDF',
              );
              setModalTitle('Datos Fundamentales');
              setOpenModal(true);
            }}
          >
            <Typography className='textH1'>
              DATOS FUNDAMENTALES
              <SvgIcon
                style={{
                  float: 'right',
                  fontSize: 43,
                  marginRight: -25,
                }}
              >
                <path d={Icons.arrowRight.data} />
              </SvgIcon>
            </Typography>
            <p className='textNormal'>
              Información exigida por ley para ayudarle a comprender la
              naturaleza y todos los detalles de este producto.
            </p>
          </div>
        </div>

        <div
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          <div
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={() => {
              setReadSeveralDocs({ ...readSeveralDocs, 0: true });
              setModalUrl(
                'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/caser/Nota+Informativa+%2B+CCPP.PDF',
              );
              setModalTitle('Condiciones Particulares');
              setOpenModal(true);
            }}
          >
            <Typography className='textH1'>
              CONDICIONES PARTICULARES
              <SvgIcon
                style={{
                  float: 'right',
                  fontSize: 43,
                  marginRight: -25,
                }}
              >
                <path d={Icons.arrowRight.data} />
              </SvgIcon>
            </Typography>
            <p className='textNormal'>Términos de contratación.</p>
          </div>
        </div>

        <div
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          <div
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={() => {
              setReadSeveralDocs({ ...readSeveralDocs, 1: true });
              setModalUrl(
                'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/caser/Adeudo+SEPA.PDF',
              );
              setModalTitle('Adeudo SEPA');
              setOpenModal(true);
            }}
          >
            <Typography className='textH1'>
              ADEUDO SEPA
              <SvgIcon
                style={{
                  float: 'right',
                  fontSize: 43,
                  marginRight: -25,
                }}
              >
                <path d={Icons.arrowRight.data} />
              </SvgIcon>
            </Typography>
            <p className='textNormal'>Condiciones de domiciliación bancaria.</p>
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 10,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Checkbox
            onChange={(e, value) => {
              setButtonDisabled(
                !value ||
                  readSeveralDocs[0] === false ||
                  readSeveralDocs[1] === false,
              );
            }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              paddingTop: 5,
            }}
            id='checkTerminos'
          />
          <Typography className='textNormal'>
            He leído y acepto los términos y condiciones.
          </Typography>
        </div>

        <div
          style={{
            marginTop: 0,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            marginBottom: 50,
            justifyContent: 'center',
          }}
        >
          {!(readSeveralDocs[0] === true && readSeveralDocs[1] === true) ? (
            <Typography style={{ color: '#c60', fontSize: '12px' }}>
              Es necesario leer las <br />
              "Condiciones Particulares" <br />y el "Adeudo SEPA"
            </Typography>
          ) : (
            ''
          )}
        </div>
      </Body>
      <Footer>
        <Button
          id='okButton'
          variant='contained'
          color='secondary'
          classes={transformClasses('targetSummary_button1', classes)}
          onClick={() => props.history.push(props.next)}
          disabled={
            buttonDisabled ||
            !(readSeveralDocs[0] === true && readSeveralDocs[1] === true)
          }
        >
          Ok todo correcto
        </Button>
      </Footer>

      {/* Ventana Modal */}
      <DocView
        open={openModal}
        title={modalTitle}
        onClose={() => setOpenModal(false)}
        url={modalUrl}
        styleType='black'
        leftIcon={Icons.disquete}
        disk
      />
    </div>
  );
}
