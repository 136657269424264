import React, { Component } from 'react';
import { Utils } from '../../../resources/index';
import Popover from '@material-ui/core/Popover';

class PopoverCustom extends Component {
  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <Popover
        {...config}
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        classes={transformClasses('popoverCustom_popover1', classes)}
      />
    );
  }
}

export default PopoverCustom;
