const config = {
    typography1: {
      variant: 'body1',
      color: 'default'
    },
    typography2: {
      variant: 'body1',
      color: 'default'
    },
    typography4: {
      variant: 'body1',
      color: 'default'
    },
    gridContainer1: {
      container: true,
      spacing: 16,
      direction: 'row',
      alignItems: 'center'
    },
    gridContainer2: {
      container: true,
      spacing: 8,
      direction: 'row',
      alignItems: 'center'
    },
    gridItem1: {
      item: true,
      xs: 9
    },
    gridItem2: {
      item: true,
      xs: 3
    },
    gridItem3: {
      item: true,
      xs: 12
    },
    gridItem4: {
      item: true,
      xs: 12
    },
    gridItem5: {
      item: true,
      xs: 9
    },
    gridItem6: {
      item: true,
      xs: 3
    }
  }
  
  export { config as ListRuleMovementsConfig }
  