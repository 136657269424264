import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

function MyText(props) {
  let myStyle = {
    fontSize: 14,
  };

  if (props.variant === 'h1') {
    myStyle = {
      fontSize: 22,
      marginBottom: 15,
    };
  } else if (props.variant === 'bold') {
    myStyle = {
      fontWeight: 650,
    };
  } else if (props.variant === 'link') {
    myStyle = {
      textDecorationLine: 'underline',
    };
  } else if (props.variant === 'headline') {
    myStyle = {
      fontSize: 40,
      fontWeight: 100,
      marginTop: 10,
      marginBottom: 10,
      lineHeight: 1,
    };
  }

  myStyle = {
    ...myStyle,
    ...props.style,
  };

  //console.log('MyText', myStyle, props.children);

  return (
    <Typography
      id={props.id}
      variant='body1'
      color={props.color ? props.color : 'primary'}
      style={myStyle}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(MyText);
