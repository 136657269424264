import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

//import { TargetsAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

import { Utils } from '../../src-core/resources/index';

import TargetInfo from '../targetInfo/targetInfo.component';
import TargetInfoConfig from '../targetInfo/targetInfo.config';
import TargetInfoStyles from '../targetInfo/targetInfo.style';
import { configureTarget_strings } from './text';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class TargetBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.target.name,
      price: this.props.targetConfiguration.price,
      months: this.props.targetConfiguration.months,
      formIsOk: false,
    };
  }

  saveInfo() {
    console.log('saveInfo', this.refs);
    const name = { name: this.refs.targetInfo.getName() };
    const values = this.refs.targetInfo.getValues();
    const data = {};
    Object.keys(values).map(value => {
      data[value] = values[value];
      return null;
    });

    TargetsAPI.setTargetConfiguration(
      this.props.baseUrl,
      this.props.target._id,
      this.props.token,
      data,
    );
    TargetsAPI.setTargetInfo(
      this.props.baseUrl,
      this.props.target._id,
      this.props.token,
      name,
    )
      .then(response => {
        if (response.status === 200) {
          this.props.refreshTarget();
          return null;
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error guardando la configuración de target ', error);
      });
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const customTargetInfoConfig = {
      ...TargetInfoConfig,
      textField1: {
        label: configureTarget_strings['es'].targetBasic_label_text1,
        fullWidth: true,
      },
      typography1: {
        variant: 'body1',
        color: 'default',
      },
      textField2: {
        label: configureTarget_strings['es'].targetBasic_label_text2,
        fullWidth: true,
        InputProps: {
          endAdornment: <InputAdornment position='end'>€</InputAdornment>,
        },
      },
      textField3: {
        label: configureTarget_strings['es'].targetBasic_label_text3,
        fullWidth: true,
      },
    };
    const data = {
      name: this.state.name,
      //price: this.state.price,
      //months: this.state.months
    };

    if (this.props.schema.properties.price !== undefined) {
      data['price'] = this.props.targetConfiguration.price;
    } else {
      data['salaryPercent'] = this.props.targetConfiguration.salaryPercent;
    }

    if (this.props.schema.properties.months !== undefined) {
      data['months'] = this.props.targetConfiguration.months; //+ ' meses';
    } else if (this.props.schema.properties.years !== undefined) {
      data['years'] = this.props.targetConfiguration.years; //+ ' años';
    } else {
      data['retirementAge'] = this.props.targetConfiguration.retirementAge;
    }

    console.log('TargetBasic Config', data);
    return (
      /*<div className='container'>  {classes.targetBasic_container}> 
        <div className='body'>*/
      <Fragment>
        <Body vAlign='top'>
          <TargetInfo
            targetType={this.props.target.type}
            ref='targetInfo'
            classes={classes}
            image={null}
            data={data}
            imgClick={null}
            config={customTargetInfoConfig}
            schema={this.props.schema}
            setFormIsOk={value => {
              console.log('setFormIsOk', value);
              this.setState({
                formIsOk: value,
              });
            }}
            retirementOnChange={value => this.setState(value)}
          />
          <div className={classes.targetBasic_disclaim_container}>
            <SvgIcon
              color='secondary'
              classes={transformClasses(
                'homeTargets_subTabs_infoIcon',
                classes,
              )}
            >
              <path d='M 12 24 C 5.373 24 0 18.627 0 12 C 0 5.373 5.373 0 12 0 C 18.627 0 24 5.373 24 12 C 24 18.627 18.627 24 12 24 Z M 12 22.286 C 17.681 22.286 22.286 17.681 22.286 12 C 22.286 6.319 17.681 1.714 12 1.714 C 6.319 1.714 1.714 6.319 1.714 12 C 1.714 17.681 6.319 22.286 12 22.286 Z M 11.143 9.429 L 12.857 9.429 L 12.857 18.857 L 11.143 18.857 L 11.143 9.429 Z M 12 5.143 C 12.71 5.143 13.286 5.718 13.286 6.429 C 13.286 7.139 12.71 7.714 12 7.714 C 11.29 7.714 10.714 7.139 10.714 6.429 C 10.714 5.718 11.29 5.143 12 5.143 Z' />
            </SvgIcon>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('targetBasic_disclaimer', classes)}
            >
              Selecciona el campo para editar
            </Typography>
          </div>
        </Body>

        <Footer>
          <Button
            disabled={!this.state.formIsOk}
            variant='contained'
            color='secondary'
            className={classes.targetBasic_button1}
            onClick={this.saveInfo.bind(this)}
          >
            {configureTarget_strings['es'].targetBasic_button}
          </Button>
        </Footer>
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...TargetInfoStyles(theme),
  targetInfo_textField1_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField2_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField3_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textFieldsub11_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub21_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub31_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub11_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub21_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub31_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub11_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub21_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub31_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetBasic_container: {
    paddingTop: 30,
  },
  targetInfo_container: {
    //backgroundColor: theme.palette.primary.main,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    maxWidth: 350,
    paddingTop: 10,
  },
  targetBasic_typography1: {
    padding: '10px 20px',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '16px',
  },
  targetBasic_textContainer: {
    height: '10vh',
    minHeight: 57,
    alignItems: 'center',
    display: 'flex',
  },
  targetBasic_button1_container: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: '0 10px 10px',
  },
  targetBasic_button1: {
    width: '100%',
    borderRadius: 'unset',
    fontWeight: 600,
    boxShadow: 'unset',
  },
  targetInfo_helpTextContainer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  targetInfo_helpTextIcon: {
    display: 'inline-flex',
    color: theme.palette.secondary.main,
  },
  targetInfo_helpTextTypography1_root: {
    fontSize: '0.65rem',
  },
  homeTargets_subTabs_infoIcon_root: {
    fontSize: '26px',
    marginLeft: '24px',
  },
  targetBasic_disclaimer_root: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 100,
    marginLeft: '12px',
  },
  targetBasic_disclaim_container: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles, { withTheme: true })(TargetBasic);
