import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import SvgIcon from '@material-ui/core/SvgIcon';
import CircularProgressIcon from '@material-ui/core/CircularProgress';

//import { TargetsAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

import { Utils } from '../../src-core/resources/index';

import Currency from '../../components/Currency';

import { targesRefundInfo_strings } from './text';

// const types = ['partial', 'total'];
const refundStatusTypes = [
  'AVAILABLE',
  'AMOUNT_NOT_ENOUGHT',
  'BLOCKED',
  'INITIAL_CARENCY',
  'REFUND_CARENCY',
  'ERROR',
  'NOT_SUPPORTED',
];

let fran = 0;

class TargetRefundInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      modalOpen: false,
      open2: false,
      type: 'partial',
      modalMessage: null,
      refundInProgress: false,
    };
    this.modalClose = this.modalClose.bind(this);
    this.modalOpen = this.modalOpen.bind(this);

    //ºº Mockup pruebas
    // this.props.info.partial.status = refundStatusTypes[0];
    // this.props.info.total.status = refundStatusTypes[0];
    // this.props.info.partial.minAmount = 5;
    // this.props.info.partial.maxAmount = 100;
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  modalOpen(modalMessage) {
    this.setState({
      modalOpen: true,
      modalMessage: modalMessage,
    });
  }

  async refund() {
    if (this.state.refundInProgress) {
      this.modalOpen('Un  momento, estamos procesando su petición.');

      return false;
    }
    this.setState({ refundInProgress: true });

    console.log('refund()targetRefundInfo', this.props, this.state);
    // Rescate parcial?
    if (this.props.index === 0) {
      if (this.props.info.partial.status !== refundStatusTypes[0]) {
        this.modalOpen(this.props.info.partial.message);
        this.setState({ refundInProgress: false });

        return false;
      } else {
        if (
          !(
            this.state.value >= this.props.info.partial.minAmount &&
            this.state.value <= this.props.info.partial.maxAmount
          )
        ) {
          this.modalOpen(
            'Valor incorrecto, debe estar comprendido entre ' +
              Currency(this.props.info.partial.minAmount) +
              ' y ' +
              Currency(this.props.info.partial.maxAmount) +
              '',
          );
          this.setState({ refundInProgress: false });

          return false;
        }
      }
    }

    // Rescate total?
    if (this.props.index === 1) {
      if (this.props.info.total.status !== refundStatusTypes[0]) {
        this.modalOpen(this.props.info.total.message);
        this.setState({ refundInProgress: false });

        return false;
      }
    }

    const hoy = new Date();
    let lastRefundDate = window.localStorage.getItem('lastRefundDate');
    if (!lastRefundDate) {
      lastRefundDate = { year: 0, month: 0, day: 0 };
    } else {
      lastRefundDate = JSON.parse(lastRefundDate);
    }
    console.log('refund', lastRefundDate);

    if (
      lastRefundDate.year !== hoy.getFullYear() ||
      lastRefundDate.month !== hoy.getMonth() + 1 ||
      lastRefundDate.day !== hoy.getDate()
    ) {
      const { baseUrl, id, token } = this.props;
      const data = {
        payload: {
          ammount: this.props.index === 0 ? this.state.value : 0,
          type: this.props.index === 0 ? 'PARTIAL' : 'TOTAL',
        },
      };
      try {
        const response = await TargetsAPI.refund(baseUrl, id, data, token);
        if (response.status === 200) {
          window.localStorage.setItem(
            'lastRefundDate',
            JSON.stringify({
              year: hoy.getFullYear(),
              month: hoy.getMonth() + 1,
              day: hoy.getDate(),
            }),
          );
          this.modalOpen(
            'Perfecto, el reembolso se ha registrado correctamente!',
          );
          this.setState({ refundInProgress: false });
        } else {
          this.setState({ refundInProgress: false });
          throw response.status;
        }
      } catch (error) {
        this.modalOpen(
          'Ha ocurrido un error con el reembolso. Intentalo mas tarde o ponte en contacto con el servicio de soporte',
        );
        console.log('error durante el refund ', error);
        this.setState({ refundInProgress: false });
      }
    } else {
      this.modalOpen(
        'Lo sentimos pero solo se puede realizar un reembolso diario.',
      );
      this.setState({ refundInProgress: false });
    }
  }

  getAmount() {
    console.log('-----------------------_>', { info: this.props });
    let amount = 0;
    if (this.props.index === 0) {
      amount = this.props.info?.partial?.maxAmount || 0;
      // this.props.info.partial.status === refundStatusTypes[0]
      //   ? this.props.info.partial.maxAmount
      //   : 0;
    } else {
      amount = this.props.moneyAmounts.consolided;
    }
    return amount;
  }

  renderAvailableFields() {
    const { classes, config, info, moneyAmounts } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <Grid
        {...config.gridContainer1}
        classes={transformClasses('targetRefundInfo_gridContainer1', classes)}
      >
        <div className={classes.targetRefundInfo_infoMoney_container}>
          <Grid
            {...config.gridItemContainer1}
            classes={transformClasses(
              'targetRefundInfo_gridItemContainer1',
              classes,
            )}
          >
            <Grid
              {...config.gridItem1}
              classes={transformClasses('targetRefundInfo_gridItem1', classes)}
            >
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'targetRefundInfo_typography1',
                  classes,
                )}
              >
                {targesRefundInfo_strings['es'].ahorrado}
              </Typography>
            </Grid>
            <Grid
              {...config.gridItem2}
              classes={transformClasses('targetRefundInfo_gridItem2', classes)}
            >
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'targetRefundInfo_typography2',
                  classes,
                )}
              >
                {Currency(moneyAmounts.saved)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            {...config.gridItemContainer2}
            classes={transformClasses(
              'targetRefundInfo_gridItemContainer2',
              classes,
            )}
          >
            <Grid
              {...config.gridItem3}
              classes={transformClasses('targetRefundInfo_gridItem3', classes)}
            >
              <Typography
                {...config.typography3}
                classes={transformClasses(
                  'targetRefundInfo_typography3',
                  classes,
                )}
              >
                {targesRefundInfo_strings['es'].disponible}
              </Typography>
            </Grid>
            <Grid
              {...config.gridItem4}
              classes={transformClasses('targetRefundInfo_gridItem4', classes)}
            >
              <Typography
                {...config.typography4}
                classes={transformClasses(
                  'targetRefundInfo_typography4',
                  classes,
                )}
              >
                {(() => {
                  let amount = this.getAmount().toString();
                  let pointIndex = amount.indexOf('.');
                  if (pointIndex === -1) {
                    amount += '.00';
                  } else {
                    amount = amount.slice(0, pointIndex + 3);
                    if (amount.length - pointIndex < 3) {
                      amount += '0';
                    }
                  }
                  return amount;
                })()}
                {/* {this.props.info.partial.status === refundStatusTypes[0]
                  ? Currency(info.partial.maxAmount)
                  : Currency(0)} */}
              </Typography>
            </Grid>
          </Grid>
        </div>

        {this.props.index === 0 && (
          <Fragment>
            <Grid
              {...config.gridItem5}
              classes={transformClasses('targetRefundInfo_gridItem5', classes)}
            >
              <Typography
                {...config.typography5}
                classes={transformClasses(
                  'targetRefundInfo_typography5',
                  classes,
                )}
              >
                {targesRefundInfo_strings['es'].introduce_cantidad}
              </Typography>
            </Grid>
            <div className={classes.targetInfo_body_targetSelector_container}>
              <div
                className={
                  classes.targetInfo_body_targetSelector_leftIco_container
                }
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses(
                    'targetInfo_body_targetSelector_leftIco_svgIcon',
                    classes,
                  )}
                  viewBox='0 0 28 28'
                >
                  <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
                </SvgIcon>
              </div>
              <div
                className={
                  classes.targetInfo_body_targetSelector_info_container
                }
              >
                <div
                  className={
                    classes.targetInfo_body_targetSelector_info_firstRow
                  }
                >
                  <Typography
                    variant='body1'
                    color='secondary'
                    classes={transformClasses(
                      'targetInfo_body_targetSelector_info_firstRow_typography',
                      classes,
                    )}
                  >
                    IMPORTE
                  </Typography>
                </div>
                <div
                  className={
                    classes.targetInfo_body_targetSelector_info_secondRow
                  }
                >
                  <Input
                    disableUnderline
                    value={this.state.value}
                    placeholder='0,00 €'
                    onChange={event =>
                      this.setState({
                        value: event.target.value,
                      })
                    }
                    classes={transformClasses(
                      'targetInfo_body_targetSelector_info_secondRow_input',
                      classes,
                    )}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <Grid
          {...config.gridItemContainer3}
          classes={transformClasses(
            'targetRefundInfo_gridItemContainer3',
            classes,
          )}
        >
          <Grid
            {...config.gridItem7}
            classes={transformClasses('targetRefundInfo_gridItem7', classes)}
          >
            <SvgIcon color='secondary'>
              <path d='M 12 24 C 5.373 24 0 18.627 0 12 C 0 5.373 5.373 0 12 0 C 18.627 0 24 5.373 24 12 C 24 18.627 18.627 24 12 24 Z M 12 22.286 C 17.681 22.286 22.286 17.681 22.286 12 C 22.286 6.319 17.681 1.714 12 1.714 C 6.319 1.714 1.714 6.319 1.714 12 C 1.714 17.681 6.319 22.286 12 22.286 Z M 11.143 9.429 L 12.857 9.429 L 12.857 18.857 L 11.143 18.857 L 11.143 9.429 Z M 12 5.143 C 12.71 5.143 13.286 5.718 13.286 6.429 C 13.286 7.139 12.71 7.714 12 7.714 C 11.29 7.714 10.714 7.139 10.714 6.429 C 10.714 5.718 11.29 5.143 12 5.143 Z' />
            </SvgIcon>
          </Grid>
          <Grid
            {...config.gridItem8}
            classes={transformClasses('targetRefundInfo_gridItem8', classes)}
          >
            <Typography
              {...config.typography7}
              classes={transformClasses(
                'targetRefundInfo_typography7',
                classes,
              )}
            >
              {targesRefundInfo_strings['es'].aviso_legal_sepa}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    // console.log('********************', this.props);
    console.log(this.props, 'PROPS');
    const { classes, config, info } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.mainContainer}>
        <Modal
          open={this.state.modalOpen}
          style={{
            //height: 'min-content',
            backgroundColor: '#ffffffb8',
            borderRadius: 5,
            padding: 15,
            //top: '30%',
            //margin: 15,
          }}
          onClose={this.modalClose}
        >
          <div
            style={{
              height: 175,
              backgroundColor: 'white',
              borderRadius: 5,
              padding: 37,
              //top: '30%',
              display: 'flex',
              flexFlow: 'column',
              margin: 15,
              marginTop: '50%',
            }}
          >
            <Typography style={{ color: '#353535' }}>
              {this.state.modalMessage}
            </Typography>
            <Button
              style={{
                backgroundColor: '#f4137b',
                color: 'white',

                borderColor: '#9E9E9E',
                borderStyle: 'solid',
                borderWidth: 1,
                float: 'right',
                margin: 15,
              }}
              onClick={() => {
                if (this.state.modalOnCloseBack) {
                  this.props.history.goBack();
                }
                this.setState({ modalOpen: false });
              }}
            >
              Cerrar
            </Button>
          </div>
        </Modal>

        {this.renderAvailableFields()}

        <Button
          {...config.button2}
          classes={transformClasses('targetRefundInfo_button2', classes)}
          disabled={this.state.refundInProgress}
          onClick={async () => {
            await this.refund();
          }}
        >
          {!this.state.refundInProgress &&
            (this.props.index === 0
              ? info.partial.status === refundStatusTypes[0]
                ? targesRefundInfo_strings['es'].aplicar_reembolso_parcial
                : targesRefundInfo_strings['es'].reembolso_parcial_no_disponible
              : info.total.status === refundStatusTypes[0]
              ? targesRefundInfo_strings['es'].solicitar_reembolso_total
              : targesRefundInfo_strings['es'].reembolso_total_no_disponible)}

          {this.state.refundInProgress && <CircularProgressIcon />}
        </Button>
      </div>
    );
  }
}

export default TargetRefundInfo;
