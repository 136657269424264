import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// import SvgIcon from '@material-ui/core/SvgIcon';
// import Typography from '@material-ui/core/Typography';
//import { withStyles } from '@material-ui/core/styles'

//import { Utils } from '../../src-core/resources/index'
// import { Utils } from '../../../resources/index';

class Body extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let innerContent = '';
    if (this.props.withoutInnerDiv) {
      innerContent = <Fragment>{this.props.children}</Fragment>;
    } else {
      innerContent = (
        <div
          style={{
            maxWidth: this.props.maxWidth,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 20,
          }}
        >
          {this.props.children}
        </div>
      );
    }

    return (
      <div
        className='bodyComp'
        style={{
          display: 'flex',
          overflow: 'auto',
          flex: 'auto',
          width: '100%',
          //justifyContent: 'center',
          flexFlow: 'column',
          alignItems: this.props.align === 'center' ? 'center' : 'flex-start',
          justifyContent:
            this.props.align === 'center' ? 'center' : 'flex-start',
          ...this.props.style,
        }}
        /*
        className='body'
        style={this.props.align == 'center' 
          ? {
              justifyContent: 'center',
              alignItems: 'center',
            } 
          : {
              justifyContent: 'center',
              alignItems: 'flex-start',
            }
        }
      */
      >
        {this.props.align === 'center' ? (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              maxWidth: this.props.maxWidth,
              width: '100%',
              //height: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {this.props.children}
          </div>
        ) : (
          <Fragment>{innerContent}</Fragment>
        )}
      </div>
    );
  }
}

Body.propTypes = {
  // por defecto 'center'
  align: PropTypes.oneOf(['center', 'top']),
  maxWith: PropTypes.number,
  withoutInnerDiv: PropTypes.bool,
};

Body.defaultProps = {
  align: 'center',
  maxWidth: 350,
  withoutInnerDiv: false,
};

/*
const styles = theme => ({
  container: {
  },
  title: {
    fontWeight: 100,
    fontSize: '35px',
    lineHeight: '45px',
    textAlign: 'center',
    color: color
  },
})
*/

//export default withStyles(styles, { withTheme: true })(Header)
export default Body;
