export const cargos_strings = {
    es: {
        cargos_header_text: 'Cuentas',
        cargos_informativeText1: '¿No aparece en la lista?',
        cargos_informativeText2: 'Busca tu banco',
        cargos_closeText: 'Cerrar',
        cargos_loginText: 'Login',
        cargosInitial_informativeText1: '¿No aparece en la lista?',
        cargosInitial_informativeText2: 'Busca tu banco',
        cargosInitial_header_text1_option1: 'Añadir otro banco',
        cargosInitial_header_text1_option2: 'Añadir otro banco',
        cargosInitial_header_text1_option3: 'Añadir cuentas',
        cargosInitial_header_text2_option1: 'Busca tu banco',
        cargosInitial_header_text2_option2: 'Introduce tus claves',
        cargosInitial_header_text2_option3: 'Selecciona tus cuentas',
        cargosInitial_button_text_option1: 'Siguiente',
        cargosInitial_button_text_option2: 'Volver',
        cargosInitial_button_text_option3: 'Login',
        cargosInitial_button_text_option4: 'Terminar'
    }
}