import React, { Component } from 'react';

import Accounts from '../accounts/accounts.component';
import AccountsConfig from '../accounts/accounts.config';
import AccountsStyles from '../accounts/accounts.style';

import CircularProgress from '@material-ui/core/CircularProgress';
//import { TargetsAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

import { withStyles } from '@material-ui/core/styles';

class TargetBanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBanks: [],
    };
  }

  setTargetAccounts(data) {
    TargetsAPI.setTargetAccounts(
      this.props.baseUrl,
      this.props.idTarget,
      data,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          this.props.getBanks();
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error configurando las cuentas del objetivo ', error);
      });
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetAccounts_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      //<div style={{display: 'flex', justifyContent: 'center', marginTop: '8px', overflow: 'auto', flex: 1}}>
      <Accounts
        banks={this.props.banks}
        idTarget={this.props.idTarget}
        token={this.props.token}
        renderLoading={this.renderLoading.bind(this)}
        setAccounts={this.setTargetAccounts.bind(this)}
        classes={classes}
        config={AccountsConfig}
        history={this.props.history}
        next={this.props.next}
        tab={this.props.toShow}
      />
      //</div>
    );
  }
}

const styles = theme => ({
  ...AccountsStyles(theme),
  targetAccounts_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  accounts_container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  accounts_tabsDiv: {
    height: '10vh',
    minHeight: 57,
    padding: '0 12px',
  },
  accounts_gridContainer1_container: {
    maxHeight: '55vh',
    minHeight: 312,
    overflow: 'auto',
  },
  accounts_button_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    //backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderRadius: 'unset',
    padding: '0 12px 12px',
  },
  bankContainer: {
    height: '55vh',
    minHeight: 312,
    width: '100%',
    overflow: 'auto',
  },
  accounts_button1_root: {
    width: '100%',
    fontWeight: 600,
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  targetBasic_button1_container: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: '10px',
  },
});

export default withStyles(styles, { withTheme: true })(TargetBanks);
