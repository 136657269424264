function generateStyles(theme) {
  const styles = {
    notificationsList_listItem1_root: {
      display: 'flex',
      flexDirection: 'column'
    },
    notificationsList_titleContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'strech'
    },
    notificationsList_iconButton1_root: {
      color: theme.palette.secondary.main,
      borderRadius: 'unset',
      padding: 'unset',
      marginLeft: 12
    },
    notificationsList_iconButton2_root: {
      color: 'green',
      borderRadius: 'unset',
      padding: 'unset',
      marginLeft: 12
    },
    notificacionsList_collpase1_container: {
      paddingRight: 12,
      paddingLeft: 12,
      backgroundColor: theme.palette.primary.dark
    },
    notificacionsList_collpase1_wrapperInner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 12,
      paddingBottom: 12
    },
    notificationsList_button1_root: {
      width: '30%',
      fontWeight: 600,
      boxShadow: 'unset',
      borderRadius: 'unset'
    },
    notificationsList_typography2_root:{
      fontSize: 8,
      color: theme.palette.primary.dark
    },
    notificationsList_typography1_root:{
      color: theme.palette.primary.dark
    },
    notificationsList_typography3_root:{
      color: theme.palette.primary.main
    },
    notificationsList_titleIconContainer: {
      minWidth: 40
    },
    notificationsList_titleTextContainer:{
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      justifyContent: 'baseline',
      paddingLeft: 12
    }
  }
  return styles
}

export { generateStyles as NotificationsListStyles }
