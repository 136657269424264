function generateStyles(theme) {
  const styles = {
    listTargetsUser_img: {
      maxWidth: '100%',
    },
    listTargetsUser_button1_root: {
      width: '100%',
      borderRadius: 'unset',
      margin: 0,
      fontWeight: 600,
    },
    listTargetsUser_divContainer: {
      width: '312px',
      height: '128px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: '16px',
      // El flex en Android hace que se solapen los objetivos
      //display: 'flex',
      //flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative',
    },
    listTargetsUser_listItem1_root: {
      padding: 0,
    },
    listTargetsUser_divImage: {
      position: 'relative',
      width: '100vw',
      height: '25vh',
      minHeight: 142,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    listTargetsUser_divImage_overlay: {
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    listTargetsUser_textDiv: {
      paddingLeft: 15,
      paddingTop: 10,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    listTargetsUser_typography1_root: {
      fontWeight: 600,
      fontSize: '11px',
      textTransform: 'uppercase',
    },
    listTargetsUser_typography2_root: {
      fontSize: '33px',
      fontWeight: 100,
      lineHeight: '39.4px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    listTargetsUser_typography3_root: {
      fontSize: '16px',
      letterSpacing: '0px',
      lineHeight: '16px',
    },
    listTargetsUser_typography4_root: {
      fontSize: '16px',
      lineHeight: '16px',
    },
    listTargetsUser_progress_container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'auto',
    },
    listTargetsUser_progress_container_firstRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    listTargetsUser_card1_root: {
      borderRadius: 'unset',
    },
    listTargetsUser_cardContent1_root: {
      textAlign: 'center',
    },
    listTargetsUser_textCompleteDiv: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      textAlign: 'center',
      zIndex: 9999,
    },
    listTargetsUser_cardActions1_root: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingTop: 3,
    },
    listTargetsUser_progress_container_secondRow_nextWish: {
      height: '8px',
      backgroundColor: theme.palette.secondary.main,
    },
    listTargetsUser_progress_container_secondRow_familyTarget: {
      height: '8px',
      backgroundColor: '#50e3c2',
    },
    listTargetsUser_progress_container_secondRow_retirement: {
      height: '8px',
      backgroundColor: '#ffc000',
    },
    listTargetsUser_progress_container_secondRow: {
      width: '100%',
      height: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(255,255,255,.2)',
      marginBottom: '4px',
    },
    listTargetsUser_progress_container_thirdRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    listTargetsUser_typography5_root: {
      fontSize: '12px',
      letterSpacing: '0px',
      lineHeight: '12px',
    },
    listTargetsUser_typography6_root: {
      fontSize: '12px',
      letterSpacing: '0px',
      lineHeight: '12px',
    },
    listTargetUser_progress_svgIcon_root: {
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
  };
  return styles;
}

export default generateStyles;
