import React, { Fragment, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import Currency from '../../../components/Currency';

import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import MyIcon from '../../../components/MyIcon';
import Flow from '../../../components/Flow';

function ProductSimulatorUnitLinkedAnnual(props) {
  const [showInfoEdadActuarial, setShowInfoEdadActuarial] = useState(false);

  console.log('ProductSimulatorUnitLinkedAnnual', props);
  const params = Flow.getParams();
  //  const [totalSeguro, setTotalSeguro] = useState(0)

  function getTotalSeguro() {
    let total = 0;
    for (let i = params.edad; i < params.años + params.edad; i++) {
      total += params.seguroPorAños[i];
    }
    return total;
  }

  function createTableAños() {
    let table = [];

    for (let i = params.edad; i < params.años + params.edad; i++) {
      table.push(
        <tr key={i}>
          <td className={props.classes.tableCell}>{i} años</td>
          <td className={props.classes.tableCell}>
            {params.seguroPorAños[i]} €
          </td>
        </tr>,
      );
    }

    return table;
  }

  function InfoEdadActuarial() {
    return (
      <Modal
        open={showInfoEdadActuarial}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setShowInfoEdadActuarial(false)}
      >
        <div
          style={{
            width: '312px',
            backgroundColor: '#e9e9e9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '14px',
            borderRadius: '8px',
            '&:focus': {
              outline: 'unset',
            },
            fontFamily: 'Calibre',
          }}
        >
          <strong>Edad actuarial</strong> Edad más cercana de tu cumpleaños. Si
          quedan menos de 6 meses para tu cumpleaños, la edad actuarial es la
          edad que vas a cumplir.
        </div>
      </Modal>
    );
  }

  return (
    <Fragment>
      <Header
        modal={true}
        title='Prima Anual Unit Linked'
        history={props.history}
      />
      <Body vAlign='top'>
        <div
          style={{
            color: 'white',
            fontFamily: 'Calibre',
          }}
        >
          <p
            style={{
              color: 'white',
            }}
          >
            El pago del seguro es anual y se basa en la edad actuarial.{' '}
            <MyIcon
              style={{ width: 20, height: 20, marginBottom: -4 }}
              name='infoCirculo'
              onClick={() => setShowInfoEdadActuarial(true)}
            />
            <InfoEdadActuarial />
          </p>
          <p
            style={{
              color: 'white',
            }}
          >
            En esta tabla puedes ver el coste que tendrá el seguro cada año.
            Particularmente, el coste es de{' '}
            <strong>{Currency(params.seguroPorAños[params.edad], 2)}</strong>{' '}
            para personas de <strong>{params.edad} años</strong>, pagando un
            total de <strong>{Currency(getTotalSeguro(), 2)}</strong> si
            mantienes activo tu Plan de Ahorro{' '}
            <strong>durante los {params.años} años previstos.</strong>
          </p>
          <p
            style={{
              color: 'white',
            }}
          >
            Una vez se haya realizado el cargo podrás ver el detalle en tu
            listado de movimientos.
          </p>
        </div>
        <div
          style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'white',
            padding: 15,
            backgroundColor: '#e9e9e9',
            color: 'black',
            borderRadius: 5,
          }}
        >
          <table
            style={{
              width: '100%',
              fontFamily: 'Calibre',
              borderCollapse: 'collapse',
              borderSpacing: 0,
            }}
          >
            <tbody>
              <tr
                style={{
                  fontWeight: 600,
                }}
              >
                <td>Edad actuarial</td>
                <td>Prima</td>
              </tr>
              {createTableAños()}
            </tbody>
          </table>
        </div>
      </Body>
    </Fragment>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 32,
    width: '100%',
    height: '100%',
  },
  expandDown: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandUp: {
    transform: 'rotate(180deg)',
  },
  tableCell: {
    border: '1px solid silver',
    padding: '16px 0px 10px 0px',
    textAlign: 'center',
  },
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(
  ProductSimulatorUnitLinkedAnnual,
);
