import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputSlider', {
  styles: {
    default: {
      railComponent: {
        height: 5,
        backgroundColor: '#979797',
        borderRadius: 15,
      },
      trackComponent: {
        height: 5,
        backgroundColor: colors.green,
        borderRadius: 15,
      },
      thumbComponent: {
        width: 42,
        height: 42,
        marginTop: -21,
        color: '#ffffff',
        backgroundColor: '#ffffff',
        borderWidth: 18,
        borderColor: colors.green,
        borderStyle: 'solid',
      },
    },
    noMarginSt: {
      containerComponent: {
        marginTop: 0,
      },
    },
    biggerSt: {
      containerComponent: {
        marginTop: 46,
      },
      inputComponent: {
        fontSize: 35,
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
  },
});
