const config = {
  gridContainer1: {
    container: true,
    spacing: 0,
  },
  gridContainer2: {
    container: true,
    spacing: 0,
    alignItems: 'center',
  },
  gridContainer3: {
    container: true,
    spacing: 0,
  },
  gridContainer4: {
    container: true,
    spacing: 0,
    alignItems: 'center',
  },
  gridItem1: {
    item: true,
    xs: 3,
  },
  gridItem2: {
    item: true,
    xs: 9,
  },
  gridItem3: {
    item: true,
    xs: 8,
  },
  gridItem4: {
    item: true,
    xs: 2,
  },
  gridItem5: {
    item: true,
    xs: 2,
  },
  button1: {
    variant: 'contained',
    color: 'secondary',
  },
  button2: {
    variant: 'contained',
    color: 'secondary',
  },
  typography1: {
    variant: 'h6',
    color: 'default',
  },
  typography2: {
    variant: 'body2',
    color: 'default',
  },
  typography3: {
    variant: 'body1',
    color: 'default',
  },
  typography4: {
    variant: 'body1',
    color: 'default',
  },
  typography5: {
    variant: 'body1',
    color: 'default',
  },
  tabs1: {
    fullWidth: true,
    indicatorColor: 'secondary',
    textColor: 'secondary',
  },
};

export default config;
