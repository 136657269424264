import colors from '../colors';
import fonts from '../fonts';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('enfatize', {
  styles: {
    default: {
      color: colors.white,
      fontSize: 18,
      lineHeight: 1.2,
      fontWeight: 500,
    },

    greenSt: {
      color: colors.green,
    },

    violetSt: {
      color: colors.red,
    },

    blackSt: {
      color: '#444444',
    },

    whiteSt: {
      color: '#ffffff',
    },

    normalSt: {
      fontStyle: 'initial',
    },

    modalSt: {
      color: '#444444',
      fontSize: 26,
      LineHeight: '30px',
      marginBottom: '15px',
    },

    blackLightSt: {
      color: colors.blackLight,
    },

    smallLabelVioletBoldSt: {
      color: colors.red,
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'SantanderTextRegular',
      textAlign: 'left',
      padding: 0,
    },

    titleGrupoHeaderSt: {
      fontSize: 22,
      fontFamily: fonts.SantanderTextRegular,
      color: colors.black,
    },

    regularSt: {
      fontFamily: 'SantanderTextRegular',
      fontWeight: 'normal',
    },
    regularBoldSt: {
      fontFamily: 'SantanderTextRegular',
      fontWeight: 'bold',
    },
    santanderRegularSt: {
      fontFamily: fonts.SantanderTextRegular,
    },
    santanderRegularBoldSt: {
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 'bold',
    },
    santanderItalicSt: {
      fontFamily: fonts.SantanderTextItalic,
    },
    santanderHeadlineRegularSt: {
      fontFamily: fonts.SantanderHeadlineRegular,
    },
    santanderHeadlineBoldSt: {
      fontFamily: fonts.SantanderHeadlineBold,
    },

    font14St: {
      fontSize: 14,
    },
    font15St: {
      fontSize: 15,
    },
    font16St: {
      fontSize: 16,
    },
    font17St: {
      fontSize: 17,
    },
    font18St: {
      fontSize: 18,
    },
    font22St: {
      fontSize: 22,
    },
    font28St: {
      fontSize: 28,
    },
    font30St: {
      fontSize: 30,
    },
    font32St: {
      fontSize: 32,
    },

    whiteSt: {
      color: colors.white,
    },
    darkSt: {
      color: colors.black,
    },
  },
});
