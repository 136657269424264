import React, { Component } from 'react';

import Chart from 'chart.js';

import { Utils } from '../../../../resources/index';

class FilledLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: null,
    };
  }

  componentDidMount() {
    //console.log("FilledLine componentDidMount 2", );
    this.changeData();
  }

  /**
   * @function
   * @return {array} returns arra with dates properly formated
   */
  formatDates() {
    const dates = this.props.info.labels.map(date => {
      //console.log("FilledLine.formatDate", date);
      /*
      const rawDate = new Date(date)
      const year = rawDate.getFullYear()
      const month = (1 + rawDate.getMonth()).toString().padStart(2, '0')
      return month + '/' + year.toString().slice(-2)
      */
      return Utils.formatDate(date);
    });
    return dates;
  }

  /**
   * @function
   * @param {object} ahorroColor color for savings
   * @param {object} objetivoColor color for target
   * @return {object} return data to fill the graph
   */
  generateData(ahorroColor, objetivoColor) {
    const data = {
      labels: this.formatDates(),
      datasets: [],
    };

    this.props.info.data.map((dat, index) => {
      data['datasets'].push({
        label: this.props.info.series[index].title,
        data: dat,
        cubicInterpolationMode: 'monotone',
        backgroundColor:
          this.props.info.series[index].title.toLowerCase() === 'ahorro'
            ? ahorroColor
            : this.props.info.series[index].title.toLowerCase() === 'objetivo'
            ? objetivoColor
            : null,
        borderColor:
          this.props.info.series[index].title.toLowerCase() === 'ahorro'
            ? 'rgb(255, 20, 123)'
            : this.props.info.series[index].title.toLowerCase() === 'objetivo'
            ? 'rgb(0, 222, 186)'
            : 'rgb(	250, 250, 250)',
        borderWidth: 2,
        fill: this.props.info.series[index].type === 'AREA',
        pointStyle: 'circle',
        pointBackgroundColor: '#fafafa',
        pointBorderColor: '#fafafa',
        pointRadius: 0,
        steppedLine: this.props.info.series[index].dashed === true,
        spanGaps: true,
        borderDash:
          this.props.info.series[index].dashed === true ? [1, 0.5] : [],
      });
      return null;
    });

    return data;
  }

  changeData() {
    if (this.props.info === undefined || this.node === undefined) {
      return false;
    }

    const node = this.node;
    console.log('changeData', this.props.info, node);

    const gradientAhorro = node
      .getContext('2d')
      .createLinearGradient(0, 0, 0, node.height);
    gradientAhorro.addColorStop(0, 'rgba(255, 20, 123,1)');
    gradientAhorro.addColorStop(1, 'rgba(255, 20, 123,0)');

    const gradientObjetivo = node
      .getContext('2d')
      .createLinearGradient(0, 0, 0, node.height);
    gradientObjetivo.addColorStop(0, 'rgba(0, 233, 204,1)');
    gradientObjetivo.addColorStop(1, 'rgba(0, 233, 204,0)');

    const chart = new Chart(node, {
      type: this.props.info.type.toLowerCase(),
      data: this.generateData(gradientAhorro, gradientObjetivo),
      options: {
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            fontColor: '#fafafa',
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                color: '#fafafa',
              },
              ticks: {
                fontColor: '#fafafa',
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                color: '#fafafa',
              },
              ticks: {
                fontColor: '#fafafa',
              },
            },
          ],
        },
      },
    });
    console.log('FilledLine.changeData', chart);
  }

  render() {
    // const { classes } = this.props;
    // let r = null;
    console.log('FilledLine.render', this.props);
    this.changeData();

    return (
      <canvas
        style={{
          // height: '100%',
          width: '100%',
          minHeight: 200,
          height: this.props.height,
        }}
        ref={node => (this.node = node)}
      />
    );
  }
}

export default FilledLine;
