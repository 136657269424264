export const targesRefundInfo_strings = {
  es: {
    targesRefundInfo_info_text1: 'refund creado correctamente',
    ahorrado: 'AHORRADO',
    disponible: 'DISPONIBLE',
    introduce_cantidad: 'Introduce la cantidad que quieras reembolsar',
    aviso_legal_sepa:
      'Solo se puede realizar un reembolso diario. Debido a cuestiones legales de las normas SEPA sólo puedes reembolsar cargos efectuados desde hace 56 días.',
    targesRefundInfo_info_text6: 'Para más información',
    reembolso_parcial: 'Reembolso parcial',
    targesRefundInfo_mail_text: 'contratos@coinscrap.com',
    solicitar_reembolso_total: 'Solicitar reembolso total',
    reembolso_total_no_disponible: 'Reembolso total no disponible',
    aplicar_reembolso_parcial: 'Aplicar reembolso parcial',
    reembolso_parcial_no_disponible: 'Reembolso parcial no disponible',
  },
};
