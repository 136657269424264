const colors = {
  white: '#ffffff',
  pink: '#f4137b',
  green: '#4e484a',
  iconPurple: '#f4137b',
  iconGreen: '#50e3c2',
  iconYellow: '#ffc000',
  iconBlue: '#a842e6',
  black: 'black',
  switchColorThumb: '#f4137b',
};

export default colors;
