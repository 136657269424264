/*
  Formateo de números al estilo: "12,345.00 €"
*/

export default function Currency(num, decimales, noSymbol) {
  //console.log('Currency', num, typeof num);
  num = typeof num == 'number' ? num : parseFloat(num);
  decimales = decimales || 0;
  noSymbol = noSymbol || false;

  let result = 0;
  let numFinal;
  let exp = 0;

  //console.log('Currency', num, decimales, exp, numFinal);
  if (decimales >= 0) {
    numFinal = num.toFixed(decimales);
  } else {
    exp = Math.pow(10, decimales * -1);
    numFinal = (num / exp).toFixed(0) * exp;
  }

  //console.log('Currency', num, decimales, exp, numFinal);
  if (noSymbol) {
    result = Intl.NumberFormat('es-ES').format(numFinal);
  } else {
    result = Intl.NumberFormat('es-ES').format(numFinal) + ' €';
  }

  return result;
}

export const getCurrency = (num, decimales, noSymbol) => {
  //console.log('Currency', num, typeof num);
  num = typeof num == 'number' ? num : parseFloat(num);
  decimales = decimales || 0;
  noSymbol = noSymbol || false;
  let result = 0;

  //console.log('Currency', num, decimales, exp, numFinal);
  if (noSymbol) {
    result = Intl.NumberFormat('de-DE', {
      useGrouping: true,
      maximumFractionDigits: decimales,
      minimumFractionDigits: decimales,
    }).format(num);
  } else {
    result = Intl.NumberFormat('de-DE', {
      style: 'currency',
      useGrouping: true,
      maximumFractionDigits: decimales,
      minimumFractionDigits: decimales,
      currency: 'EUR',
    }).format(num);
  }

  return result;
};
