function portfolio() {
  return {
    title: 'Conservador',
    $$hashKey: 'object:118',
    benchmark: { id: 'imff', name: 'FI Renta Fija Mixta Internacional' },
    equity: 0,
    historicPerformance: 5.0779,
    id: 'ed24ec39-db61-11e8-ba5d-08002793726b',
    minAmount: 100,
    minTimeframe: 3,
    name: 'Conservador',
    performance: 4.97,
    productId: 'evo_caser_unit_linked',
    recommended: false,
    selected: false,
    tolerance: 1,
    typicalDeviation: {
      1: 7.157,
      2: 5.2696,
      3: 4.4765,
      4: 4.1224,
      5: 3.8818,
      6: 3.6542,
      7: 3.485,
      8: 3.3747,
      9: 3.2035,
      10: 3.0465,
      11: 2.9302,
      12: 2.8016,
      13: 2.6873,
      14: 2.5773,
      15: 2.4766,
      16: 2.3614,
      17: 2.2603,
      18: 2.1431,
      19: 2.0215,
      20: 1.9059,
      21: 1.7815,
      22: 1.6793,
      23: 1.5806,
      24: 1.4427,
      25: 1.3295,
      26: 1.2243,
      27: 1.108,
      28: 0.9957,
      29: 0.8953,
      30: 0.7573,
      31: 0.5621,
      32: 0.4369,
      33: 0.3673,
      34: 0.1861,
      35: 0.1225,
      36: 0.0908,
      37: 0.0686,
      38: 0.0444,
      39: 0.0444,
      40: 0.0444,
      default: 0.0444,
    },
  };
}
export default portfolio;
