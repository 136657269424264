export const updatedValues = productId => {
  switch (productId) {
    // plan de pension
    // agresivo / atrevido
    case 'default30854':
      return {
        profitability: 0.094,
      };
    // dinamico / equilibrado
    case 'default30853':
      return {
        profitability: 0.067,
      };
    // conservador
    case 'default30855':
      return {
        profitability: 0.040,
      };

    // Huchas
    // agresivo / atrevido
    case 'F156295903':
      return {
        profitability: 0.094,
      };
    // animado / decidido
    case 'F155295903':
      return {
        profitability: 0.081,
      };
    // dinamico / equilibrado
    case 'F154295903':
      return {
        profitability: 0.067,
      };

    // moderado / cauto
    case 'F153295903':
      return {
        profitability: 0.054,
      };

    // conservador
    case 'F152295903':
      return {
        profitability: 0.040,
      };

    default:
      return {};
  }
};
