import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputDate', {
  styles: {
    default: {
      containerComponent: {
        width: '100%',

        backgroundColor: '#FAFBFC',
        display: 'flex',
      },

      inputContainerComponent: {
        height: '48px',
        border: '1px solid #EEF0F2',
        boxSizing: 'border-box',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      inputComponent: {
        // paddingBottom: '25px',
        boxSizing: 'border-box',
        paddingRight: 0,
        height: '100%',
        // paddingLeft: 5,
        fontSize: 17,
        color: colors.blackLight,
        lineHeight: '20px',
        fontFamily: 'SantanderTextRegular',
      },
      iconContainerComponent: {
        padding: 0,
        height: 48,
        width: 26,
      },
      dayComponent: {
        color: colors.grey,
        fontFamily: 'SantanderTextRegular',
      },

      daySelectedComponent: {
        backgroundColor: colors.red,
        color: colors.white,
      },
      actionTextComponent: {
        display: 'none',
      },
    },
    /*default: {
      containerComponent: {
        marginTop: 15,
        marginBottom: 15,
        width: '100%',
      },

      inputContainerComponent: {
        backgroundColor: 'transparent',
      },

      iconComponent: {
        color: colors.green,
        height: '100%',
      },
      inputComponent: {
        fontSize: '17px',
        fontWeight: 400,
        textAlign: 'left',
      },
      toolbarComponent: {
        backgroundColor: colors.green,
        display: 'none',
      },

      dayComponent: {
        color: colors.grey,
        fontFamily: 'SantanderTextRegular',
      },

      daySelectedComponent: {
        backgroundColor: colors.red,
        color: colors.white,
      },
      actionTextComponent: {
        color: colors.green,
        paddingBottom: 0,
        display: 'none',
      },
    },*/
    // default: {
    //   containerComponent: {
    //     marginTop: 15,
    //     marginBottom: 15,
    //   },
    //   inputContainerComponent: {
    //     backgroundColor: 'transparent',
    //   },
    //   iconComponent: {
    //     color: colors.green,
    //     fontSize: 30,
    //   },
    //   inputComponent: {
    //     fontSize: '25px',
    //     fontWeight: 400,
    //     textAlign: 'left',
    //   },
    //   toolbarComponent: {
    //     backgroundColor: colors.green,
    //   },
    //   dayComponent: {
    //     color: colors.green,
    //   },
    //   daySelectedComponent: {
    //     backgroundColor: colors.green,
    //   },
    //   actionTextComponent: {
    //     color: colors.green,
    //     paddingBottom: 0,
    //   },
    // },
    fullWidthSt: {
      containerComponent: {
        width: '100%',
      },
    },
    borderBottomBlueSt: {
      containerComponent: {
        height: '48px',
        width: '100%',
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
        margin: '0px 0px 20px',
      },
      inputContainerComponent: {
        backgroundColor: colors.greyBcInput,
        borderRadius: '0px',
        border: '1px solid #EEF0F2',
        borderBottomColor: '#8CBABD',
        width: '100%',
        paddingRight: 0,
      },
      inputComponent: {
        width: '90%',
        color: colors.black,
        fontSize: 17,
        fontFamily: 'SantanderTextRegular',
      },
      iconContainerComponent: {
        padding: '12px 10px',
      },
      iconComponent: {
        color: colors.green,
        borderLeft: '1px solid #E5E5E5',
      },
    },

    dateWithBorderSt: {
      inputContainerComponent: {
        backgroundColor: '#FAFBFC',
        borderBottom: '1px solid #9BC3D3',
        paddingRight: 6,
      },
      containerComponent: {
        marginTop: 0,
        marginBottom: 0,
        borderBottom: 'none',
      },
      labelComponent: {
        color: '#727272',
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        marginTop: '-2px',
        fontSize: 17,
      },
      iconComponent: {
        borderLeft: '1px solid #E5E5E5',
        paddingLeft: 10,
        paddingRight: 4,
        height: '28px',
        boxSizing: 'content-box',
        width: '24px',
      },
    },

    inputMovementSt: {
      inputContainerComponent: {
        height: '55px',
      },
    },
  },
});
