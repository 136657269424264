import React, { useState, Fragment } from 'react';

import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Utils } from '../src-core/resources/index';

function MyTabs(props) {
  const [currentTab, setCurrentTab] = useState(props.currentTab);

  function renderTabs(tabs) {
    const elements = tabs.map((tab, index) => {
      //console.log('renderTabs', tab)
      return (
        <Tab
          label={tab.label}
          key={index}
          icon={tab.icon}
          //onClick={e => tab.onClick(tab.name)}
          onClick={() => {
            //console.log('onClick push', tab.path);
            props.history.replace(tab.path);
            setCurrentTab(index);

            props.onChangeTab && props.onChangeTab(index);
          }}
          classes={Utils.transformClasses('targetsTabs_tab1', props.classes)}
        />
      );
    });
    return elements;
  }

  function renderSwitch(tabs) {
    const elements = tabs.map((tab, index) => {
      //console.log('renderSwitch', index, tab);
      return <Route key={index} path={tab.path} render={tab.render} />;
    });
    return elements;
  }

  return (
    <Fragment>
      <Tabs
        textColor='primary'
        value={currentTab}
        style={{
          display: 'flex',
          alignSelf: 'center',
          //width: '100%',
          alignItems: 'center',
          flexFlow: 'column',
          marginTop: 15,
          marginBottom: 15,
          borderBottom: 0, //'solid 1px rgba(255,255,255,.3)',
          minHeight: 33,
          ...props.style,
        }}
        id='tabs'
        classes={Utils.transformClasses('targetsTabs_tabs1', props.classes)}
        centered
      >
        {renderTabs(props.tabs)}
      </Tabs>

      <Switch>{renderSwitch(props.tabs)}</Switch>
    </Fragment>
  );
}

const styles = theme => ({
  /*
  targetsTabs_tabs1_root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(255,255,255,.3)',
    //minHeight: '30px'
    marginTop: -10,
    marginLeft: 10,
    marginRight: 10
  },
  */
  targetsTabs_tab1_textColorPrimary: {
    fontSize: '14px',
    color: 'rgba(255,255,255,.5)',
    textTransform: 'uppercase',
  },
  targetsTabs_tab1_selected: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    minHeight: 'unset',
  },
  targetsTabs_tab1_root: {
    //padding: '0 22px', // 12
    lineHeight: 1,
    marginBottom: 7,
    minHeight: 'unset',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },

  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(MyTabs);
