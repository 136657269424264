import React, { useEffect, useContext } from 'react';
import { View, Text, Button, Layout } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import routes from '../../../../config/routes';
import colors from '../../../../config/colors';
import Sofa from '../../../common/svgs/Sofa';
import { StoreContext } from '../../../../pensionStore/Store';
import navConfig from '../../config/navigation';

export default props => {
  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  useEffect(() => {
    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
  }, []);
  return (
    <Layout.Container>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
        //
      />
      <View bodySt justifyCenterSt>
        <View width85St style={{ marginTop: 20 }}>
          <Text bottomTextSt>
            Los datos de la simulación son ficticios y variarán con respecto a
            los importes en el futuro.
          </Text>
        </View>
        <View width85St flexSt>
          <View>
            <View></View>
            <View iconMarginBottomSt>
              <Sofa style={{ color: colors.iconPurple }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              Tu jubilación
            </Text>
            <Text centerSt font16St>
              Para empezar, vamos a calcular tu edad de jubilación. Para ello
              solo necesitamos un par de datos sobre ti.
            </Text>
            <Text centerSt font16St style={{ marginTop: 12 }}>
              ¿Listo?
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() => props.history.push(routes.retirementSimulationAge)}
          >
            ¡VAMOS ALLÁ!
          </Button>
        </View>
      </Footer>
    </Layout.Container>
  );
};
