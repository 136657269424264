function generateStyles(theme) {
  const styles = {
    targetsTabs_tabs1_root: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: 'solid 1px rgba(255,255,255,.3)',
      //  padding: '0 12px'
    },
    targetsTabs_tabs1_flexContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    targetsTabs_tabs1_indicator: {
      height: '4px',
    },
    targetsTabs_tab1_textColorPrimary: {
      fontSize: '14px',
      color: 'rgba(255,255,255,.5)',
      textTransform: 'uppercase',
    },
    targetsTabs_tab1_selected: {
      fontWeight: 600,
      color: theme.palette.primary.main,
      padding: '0 8px',
    },
  };
  return styles;
}

export default generateStyles;
