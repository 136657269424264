function generateStyles(theme) {
    const styles = {
        swipeableViews_containerStyle: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflow:'auto',
            minHeight: 568,
            justifyContent: 'center'
        }
    }
    return styles
  }
  
  export default generateStyles 
  