import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Switch from '@material-ui/core/Switch';

import { RulesAPI } from '../../../sdk/index';
import { List } from '../../../ui/index';
import { Utils } from '../../../resources/index';

class ListTargetsForRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesByUserAndRuleDef: this.props.rulesByUserAndRuleDef,
    };
    //console.log("ListTargetsForRules.rulesByUserAndRuleDef ", this.state.rulesByUserAndRuleDef)
  }

  onClickListItemEdit(targetId, ruleId) {
    // let ruleDefId = null;
    let rules = this.state.rulesByUserAndRuleDef;
    const ruleIndex = rules.findIndex(rule => rule.target === targetId);

    if (ruleIndex >= 0) {
      ruleId = rules[ruleIndex]._id;
      //console.log("onClickListItemEdit rules", rules);
      //console.log("onClickListItemEdit ruleId", ruleId);
      //console.log("onClickListItemEdit index", ruleIndex);
    } else {
      ruleId = 'new_rule';
    }

    this.goToEditRule(targetId, ruleId);
  }

  goToEditRule(targetId, ruleId) {
    const ruleDefId = this.props.ruleDefId;

    console.log('back', this.props.location.pathname);
    // '/configure/rule/:id/:defID?/:targetID?'
    this.props.history.push({
      pathname: '/configure/rule/' + ruleId + '/' + ruleDefId + '/' + targetId,
      state: {
        back: this.props.location.pathname,
      },
    });
  }

  onChangeListItemSwitch(targetId, value) {
    const rules = this.state.rulesByUserAndRuleDef;
    const ruleIndex = this.state.rulesByUserAndRuleDef.findIndex(
      rule => rule.target === targetId,
    );

    if (ruleIndex === -1) {
      this.goToEditRule(targetId, 'new_rule');
    } else {
      //console.log("targetId", targetId, "ruleIndex" , ruleIndex);
      //console.log("rules", rules);
      rules[ruleIndex].isActive = value;

      if (rules[ruleIndex].isActive) {
        this.changeRuleActiveOnServer(rules[ruleIndex]._id, rules, true);
      } else {
        this.changeRuleActiveOnServer(rules[ruleIndex]._id, rules, false);
      }

      this.setState({ rulesByUserAndRuleDef: rules });
    }
  }

  changeRuleActiveOnServer(ruleId, rules, value) {
    value = { status: value };
    console.log('setActiveOnServer', ruleId, value);
    RulesAPI.setActive(this.props.baseUrl, ruleId, value, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return null;
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error cambiando el estado de activacion ', error);
      });
  }

  getExistRuleForTarget(targetId) {
    if (
      this.state.rulesByUserAndRuleDef.findIndex(
        rule => rule.target === targetId,
      ) >= 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getIsActiveRuleForTarget(targetId) {
    const ruleIndex = this.state.rulesByUserAndRuleDef.findIndex(
      rule => rule.target === targetId,
    );

    if (ruleIndex >= 0) {
      return this.state.rulesByUserAndRuleDef[ruleIndex].isActive;
    } else {
      return false;
    }
  }

  renderListItems() {
    const { classes /* config, progressBarConfig */ } = this.props;
    const transformClasses = Utils.transformClasses;
    console.log({ props: this.props });
    const items = this.props.targets
      .filter(item => {
        if (item.type === 'retirement') {
          if (
            (this.props.ruleDef.identifier === 'COINSCRAP_ROUNDS' ||
              this.props.ruleDef.identifier === 'TEAM' ||
              this.props.ruleDef.identifier === 'NOMINA_SAVING') &&
            item.status !== 'ACTIVE'
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .map((element, index) => {
        console.log({ targets: this.props.targets });
        return (
          <div
            className={classes.listTargetsUser_divContainer}
            key={element._id}
            style={{
              backgroundImage: 'url(' + element.image + ')',
              textAlign: 'left',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(0,0,0,.6)',
                width: '312px',
                height: '128px',
                padding: '16px',
                position: 'relative',
              }}
            >
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography1',
                  classes,
                )}
              >
                {element.name.toLowerCase()}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography3',
                  classes,
                )}
              >
                TOTAL ACUMULADO
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography2',
                  classes,
                )}
              >
                {Utils.formatCurrency(element.progress.completed)}
              </Typography>
              {this.getExistRuleForTarget(element._id) === true ? (
                <Fragment>
                  {this.props?.ruleDef?.identifier === 'PERIODIC_SAVING' &&
                  element?.type === 'familyTarget' ? null : (
                    <>
                      <Typography
                        color='primary'
                        classes={transformClasses(
                          'listTargetsUser_typography4',
                          classes,
                        )}
                      >
                        {this.getIsActiveRuleForTarget(element._id)
                          ? 'Desactivar'
                          : 'Activar'}
                      </Typography>
                      <Switch
                        onChange={(e, value) =>
                          this.onChangeListItemSwitch(element._id, value)
                        }
                        classes={transformClasses(
                          'listTargetsUser_switch',
                          classes,
                        )}
                        checked={this.getIsActiveRuleForTarget(element._id)}
                        disabled={this.props.ruleDef.required}
                      />
                    </>
                  )}
                  <Typography
                    color='primary'
                    classes={transformClasses(
                      'listTargetsUser_typography5',
                      classes,
                    )}
                  >
                    Editar
                  </Typography>
                  <SvgIcon
                    color={
                      this.getExistRuleForTarget(element._id)
                        ? 'primary'
                        : 'disabled'
                    }
                    onClick={() => this.onClickListItemEdit(element._id)}
                    classes={transformClasses(
                      'listTargetsUser_editIcon',
                      classes,
                    )}
                  >
                    <path d='M18.8,9.3L15,5.6c0.5-0.4,1.1-0.6,1.7-0.6c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9      C19.4,8.3,19.2,8.9,18.8,9.3z M9.5,18.6L8,17.1l8.6-8.6l1.5,1.5L9.5,18.6z M14.3,6.2l1.6,1.6l-8.6,8.6l-1.6-1.6L14.3,6.2z       M19.3,5.1c-1.4-1.4-3.7-1.4-5.1,0l-9.4,9.4c-0.1,0.1-0.1,0.2-0.1,0.3l-0.7,5.2c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1      c0,0,0,0,0.1,0L7.5,20c0.3,0,0.4-0.3,0.4-0.5c0-0.3-0.3-0.4-0.5-0.4l-2.5,0.3l0.5-3.6l3.8,3.8c0.1,0.1,0.2,0.1,0.3,0.1      s0.2,0,0.3-0.1l9.4-9.4c0.7-0.7,1.1-1.6,1.1-2.6S20,5.8,19.3,5.1z' />
                  </SvgIcon>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    color='primary'
                    classes={transformClasses(
                      'listTargetsUser_typography5',
                      classes,
                    )}
                  >
                    Crear
                  </Typography>
                  <SvgIcon
                    color='primary'
                    onClick={() => this.onClickListItemEdit(element._id)}
                    classes={transformClasses(
                      'listTargetsUser_editIcon',
                      classes,
                    )}
                  >
                    <path d='M18.8,9.3L15,5.6c0.5-0.4,1.1-0.6,1.7-0.6c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9      C19.4,8.3,19.2,8.9,18.8,9.3z M9.5,18.6L8,17.1l8.6-8.6l1.5,1.5L9.5,18.6z M14.3,6.2l1.6,1.6l-8.6,8.6l-1.6-1.6L14.3,6.2z       M19.3,5.1c-1.4-1.4-3.7-1.4-5.1,0l-9.4,9.4c-0.1,0.1-0.1,0.2-0.1,0.3l-0.7,5.2c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1      c0,0,0,0,0.1,0L7.5,20c0.3,0,0.4-0.3,0.4-0.5c0-0.3-0.3-0.4-0.5-0.4l-2.5,0.3l0.5-3.6l3.8,3.8c0.1,0.1,0.2,0.1,0.3,0.1      s0.2,0,0.3-0.1l9.4-9.4c0.7-0.7,1.1-1.6,1.1-2.6S20,5.8,19.3,5.1z' />
                  </SvgIcon>
                </Fragment>
              )}
            </div>
          </div>
        );
      });
    return items;
  }

  render() {
    const { listConfig, classes } = this.props;
    const listConfigCustom = {
      ...listConfig,
      children: this.renderListItems(),
    };
    return <List config={listConfigCustom} classes={classes} />;
  }
}

ListTargetsForRules.propTypes = {
  targets: PropTypes.array,
  ruleDefId: PropTypes.string,
  rulesByUserAndRuleDef: PropTypes.array,
  history: PropTypes.any,
  classes: PropTypes.any,
  //theme: PropTypes.any,
  config: PropTypes.any,
  listConfig: PropTypes.any,
  location: PropTypes.any,
  //match: PropTypes.any,
  token: PropTypes.string,
  baseUrl: PropTypes.string,
};

export default ListTargetsForRules;

/*  
  renderNewListItems() {
    const { classes, config } = this.props
    const transformClasses = Utils.transformClasses
    const items = this.props.targets.map(element => {
      return (
        <div
          className={classes.listTargetForRules_divContainer}
          key={element._id}
        >
          <ListItem
            {...config.listItem}
            classes={transformClasses('listTargetForRules_listItem1', classes)}
          >
            <div
              className={classes.listTargetForRules_divImage}
              style={{ backgroundImage: 'url(' + element.image + ')' }}
            >
              <div className={classes.listTargetForRules_divImage_overlay}>
                
                <div className={classes.listTargetForRules_leftPanel_container}>
                  <Typography
                    {...config.typographyTitle1}
                    classes={transformClasses(
                      'listTargetForRules_typographyTitle1',
                      classes
                    )}
                  >
                    {element.name}
                  </Typography>
                </div>
                <div
                  className={classes.listTargetForRules_rightPanel_container}
                />
              </div>
            </div>
          </ListItem>
        </div>
      )
    })
    return items
  }
*/

/*
  renderListItems() {
    const { classes, config } = this.props
    const transformClasses = Utils.transformClasses
    const items = this.props.targets.map(element => {
      return (
        <div
          className={classes.listTargetForRules_divContainer}
          key={element._id}
        >
          <ListItem
            {...config.listItem}
            classes={transformClasses('listTargetForRules_listItem1', classes)}
          >
            <div
              className={classes.listTargetForRules_divImage}
              style={{ backgroundImage: 'url(' + element.image + ')' }}
            >
              <div className={classes.listTargetForRules_divImage_overlay} />
              <div className={classes.listTargetForRules_titleContainer}>
                <Typography
                  {...config.typographyTitle1}
                  classes={transformClasses(
                    'listTargetForRules_typographyTitle1',
                    classes
                  )}
                >
                  {element.name}
                </Typography>
              </div>
              <div className={classes.listTargetForRules_optionsContainer}>
                <div className={classes.listTargetForRules_optionTextContainer}>
                  <div className={classes.listTargetForRules_optionText}>
                    {this.state.rulesConfigured.findIndex(
                      rule => rule.target === element._id
                    ) >= 0 ? (
                      <Typography
                        {...config.typographyAmount1}
                        classes={transformClasses(
                          'listTargetForRules_typographyAmount1',
                          classes
                        )}
                      >
                        {this.state.ruleName + ' Acumulado'}
                      </Typography>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div className={classes.listTargetForRules_optionText}>
                    <Typography
                      {...config.typographyActive1}
                      classes={transformClasses(
                        'listTargetForRules_typographyActivet1',
                        classes
                      )}
                    >
                      {
                        listTargetForRules_strings['es']
                          .listTargetForRules_option_text1
                      }
                    </Typography>
                  </div>
                  <div className={classes.listTargetForRules_optionText}>
                    {this.props.rules[
                      this.props.rules.findIndex(
                        rule => rule._id === this.props.match.params.id
                      )
                    ].eventDefinition !== null &&
                    this.state.rulesConfigured.findIndex(
                      rule => rule.target === element._id
                    ) >= 0 ? (
                      <Typography
                        {...config.editTypography1}
                        classes={transformClasses(
                          'listTargetForRules_editTypography1',
                          classes
                        )}
                      >
                        {
                          listTargetForRules_strings['es']
                            .listTargetForRules_option_text2
                        }
                      </Typography>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
                <div
                  className={classes.listTargetForRules_optionControlContainer}
                >
                  <div className={classes.listTargetForRules_optionControl}>
                    {this.state.rulesConfigured.findIndex(
                      rule => rule.target === element._id
                    ) >= 0 ? (
                      <Typography
                        {...config.typographyAmount2}
                        classes={transformClasses(
                          'listTargetForRules_typographyAmount2',
                          classes
                        )}
                      >
                        {Utils.formatCurrency(
                          this.state.rulesConfigured[
                            this.state.rulesConfigured.findIndex(
                              rule => rule.target === element._id
                            )
                          ].ruleAmounts.total
                        )}
                      </Typography>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div className={classes.listTargetForRules_optionControl}>
                    <Switch
                      {...config.activeSwitch}
                      classes={transformClasses(
                        'listTargetForRules_switchActivet1',
                        classes
                      )}
                      disabled={
                        this.props.rules[
                          this.props.rules.findIndex(
                            rule => rule._id === this.props.match.params.id
                          )
                        ].required
                      }
                      checked={
                        this.state.rulesConfigured.findIndex(
                          rule => rule.target === element._id
                        ) >= 0
                          ? this.state.rulesConfigured[
                              this.state.rulesConfigured.findIndex(
                                rule => rule.target === element._id
                              )
                            ].isActive
                          : false
                      }
                      onChange={
                        this.state.rulesConfigured.findIndex(
                          rule => rule.target === element._id
                        ) < 0 ||
                        this.props.rules[
                          this.props.rules.findIndex(
                            rule => rule._id === this.props.match.params.id
                          )
                        ].required
                          ? () =>
                              this.goToEdit(
                                this.state.rulesConfigured.findIndex(
                                  rule => rule.target === element._id
                                ) >= 0
                                  ? this.state.rulesConfigured[
                                      this.state.rulesConfigured.findIndex(
                                        rule => rule.target === element._id
                                      )
                                    ]._id
                                  : 'new_rule',
                                this.props.match.params.id,
                                element._id
                              )
                          : e =>
                              this.onChangeSwitch(
                                e,
                                this.state.rulesConfigured.findIndex(
                                  rule => rule.target === element._id
                                )
                              )
                      }
                    />
                  </div>
                  <div className={classes.listTargetForRules_optionControl}>
                    {this.props.rules[
                      this.props.rules.findIndex(
                        rule => rule._id === this.props.match.params.id
                      )
                    ].eventDefinition !== null &&
                    this.state.rulesConfigured.findIndex(
                      rule => rule.target === element._id
                    ) >= 0 ? (
                      <Typography
                        {...config.editTypography2}
                        classes={transformClasses(
                          'listTargetForRules_editTypography2',
                          classes
                        )}
                      >
                        {Utils.formatCurrency(
                          this.props.rules[
                            this.props.rules.findIndex(
                              rule => rule._id === this.props.match.params.id
                            )
                          ].fields[0].default
                        )}
                      </Typography>
                    ) : (
                      <IconButton
                        {...config.editIconButton1}
                        classes={transformClasses(
                          'listTargetForRules_editIconButton1',
                          classes
                        )}
                        className={
                          this.state.rulesConfigured.findIndex(
                            rule => rule.target === element._id
                          ) < 0
                            ? 'hide'
                            : ''
                        }
                        onClick={() =>
                          this.goToEdit(
                            this.state.rulesConfigured.findIndex(
                              rule => rule.target === element._id
                            ) >= 0
                              ? this.state.rulesConfigured[
                                  this.state.rulesConfigured.findIndex(
                                    rule => rule.target === element._id
                                  )
                                ]._id
                              : 'new_rule',
                            this.props.match.params.id,
                            element._id
                          )
                        }
                      >
                        <Create />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ListItem>
        </div>
      )
    })
    return items
  }
*/
