function generateStyles(theme) {
  const styles = {
    container: {
      height: '100vh',
      minHeight: 568,
      width: '100%',
      overflow: 'auto'
    },
    menu_container: {
      height: '90vh',
      minHeight: 511,
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      overflow: 'auto'
    },
    menuItems_container:{
      minWidth: 300,
      padding: theme.spacing(4)
    },
    userName_container:{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      paddingTop: 20,
      paddingBottom: 30,
      alignItems: 'center',
      justifyContent: 'center'
    },
    menu_tableCell1_root: {
      padding: '0!important',
      textAlign: 'center',
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    menu_tableCell2_root: {
      padding: '0!important',
      textAlign: 'center'
    },
    menu_tableCell3_root: {
      padding: '0!important',
      textAlign: 'center',
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    menu_tableCell4_root: {
      padding: '0!important',
      textAlign: 'center'
    },
    menu_tableCell5_root: {
      padding: '0!important',
      textAlign: 'center',
      borderBottom: 0,
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    menu_tableCell6_root: {
      padding: '0!important',
      textAlign: 'center',
      borderBottom: 0
    },
    menu_iconButton1_root: {
      color: '#000'
    },
    menu_iconButton2_root: {
      color: '#000'
    },
    menu_iconButton3_root: {
      color: '#000'
    },
    menu_iconButton4_root: {
      color: '#000'
    },
    menu_iconButton5_root: {
      color: '#000'
    },
    menu_iconButton6_root: {
      color: '#000'
    },
    menu_icon1_root: {
      fontSize: 64
    },
    menu_icon2_root: {
      fontSize: 64
    },
    menu_icon3_root: {
      fontSize: 64
    },
    menu_icon4_root: {
      fontSize: 64
    },
    menu_icon5_root: {
      fontSize: 64
    },
    menu_icon6_root: {
      fontSize: 64
    },
    menu_badge1_badge: {
      top: 5,
      right: 5,
      fontWeight: 600
    },
    menu_typography1_root:{
      fontSize:14,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    menu_typography2_root:{
      fontSize:14,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    menu_typography3_root:{
      fontSize:14,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    menu_typography4_root:{
      fontSize:14,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    menu_typography5_root:{
      fontSize:14,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    menu_typography6_root:{
      fontSize:14,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    menu_typography7_root:{
      fontSize:45,
      fontWeight: 100,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      textTransform: 'capitalize',
      lineHeight: 1,
    },
    menu_typography8_root:{
      fontWeight: 600,
      fontSize: 11,
      display: 'flex',
      marginTop: -10,
      justifyContent: 'center',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    menu_typography9_root:{
      fontWeight: 600,
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      textTransform: 'uppercase'
    },
    menu_iconButton_root: {
      marginRight: 32,
      marginTop: -1
    },
    menu_sponsors: {
      display: 'flex',
      flexDirection: 'column',
      width: '100$',
      paddingTop: 20,
      paddingBottom: 20,
      borderTop: '1px solid rgba(200,200,200,0.25)',
      borderBottom: '1px solid rgba(200,200,200,0.25)',
    },
    menu_listItem_root: {
      display: 'flex',
      alignItems: 'center'
    },
    menu_sponsor_logo:{
      width: '60px',
    },
    menu_sponsor_logo_container:{
      minWidth: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    menu_sponsors_item: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
    menu_sponsors_text: {
      marginLeft: 20,

    },
    menu_typography10_root:{
      lineHeight: 1
    },
    menu_typography11_root:{
    },
  }
  return styles
}

export { generateStyles as MenuStyles }
