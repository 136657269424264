import React, { Component, Fragment } from 'react';
// import { Route } from 'react-router-dom';
// import { Switch } from 'react-router';

import { BanksAPI } from '../../src-core/sdk/index';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  View,
  Text,
  Enfatize,
  Button as CoreButton,
} from 'coinscrap-webapp-core';
import routes from '../../config/routes';

import { Utils } from '../../src-core/resources/index';
//import { BanksAPI, UsersAPI } from '../components-core/sdk';
// import { BanksAPI } from '../../src-core/sdk/index';
// import { UsersAPI } from '../../src-core/sdk/index';

import SwipeableViewsConfigurableConfig from '../swipeableViews/swipeableViewsConfigurable.config';
import SwipeableViewsConfigurableStyles from '../swipeableViews/swipeableViewsConfigurable.style';
import Flow from '../../components/Flow';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import Header from '../../components/MyHeader';
import ProductSelector from '../Product/ProductSelector';
import ProductSelectorStyle from '../Product/ProductSelector.style';
import ProductCard from '../Product/ProductCard';

/*
  Este componente monta los slides de productos que puede elegir
  el usuario, según su perfil de riesgo.
*/
class CreateTargetRetirement extends Component {
  constructor(props) {
    super(props);

    const params = Flow.getParams();
    console.log('CreateTargetProducts.const params', params);
    this.state = {
      index: null,
      productId: params.productRetirementId ? params.productRetirementId : null,
      riskFactor: null,
      prodsForUser: this.props.founds,
      lastProductId: params.productRetirementId
        ? params.productRetirementId
        : null,
      doSurvey: false,
      surveyFetched: false,
      hidePlanInfo: false,
    };
    console.log(this.state.prodsForUser[this.state.productId]);
    console.log(this.state.prodsForUser);
    console.log(this.state.productId);
    console.log('CreateTargetProducts', props, this.state);
  }

  componentDidMount() {
    console.log('CreateTargetProduct.componentDidMount');

    this.filterProducts();
  }

  hideInfo() {
    this.setState(state => ({
      hidePlanInfo: !state.hidePlanInfo,
    }));
  }

  filterProducts() {
    console.log('filterProducts', this.props.founds, this.state.riskFactor);

    // Filtra los productos que el usuario puede utilizar
    let prodsForUser = {};
    Object.keys(this.props.founds).map(key => {
      prodsForUser[key] = this.props.founds[key];

      return null;
    });
    console.log(this.props.founds);
    console.log(prodsForUser);
    if (!this.state.productId) {
      this.handleChange(0);
    }

    this.setState({
      prodsForUser: prodsForUser,
    });
  }

  handleChange(index) {
    this.setState({
      index: index,
      productId: Object.keys(this.state.prodsForUser).sort(
        (a, b) =>
          this.state.prodsForUser[a].riskFactor -
          this.state.prodsForUser[b].riskFactor,
      )[index],
      lastProductId: Object.keys(this.state.prodsForUser).sort(
        (a, b) =>
          this.state.prodsForUser[a].riskFactor -
          this.state.prodsForUser[b].riskFactor,
      )[index],
    });
    Flow.setParams({
      productRetirementId: Object.keys(this.state.prodsForUser).sort(
        (a, b) =>
          this.state.prodsForUser[a].riskFactor -
          this.state.prodsForUser[b].riskFactor,
      )[index],
    });
  }

  setFound() {
    // console.log('setFound', this.state);
    // const data = {
    //   product: this.state.prodsForUser[this.state.productId].product,
    //   foundsConfiguration: [
    //     {
    //       foundCode: this.state.prodsForUser[this.state.productId].code,
    //       percentage: 100,
    //     },
    //   ],
    // };
    // console.log('patch data: ', data);
    // BanksAPI.setProducts(
    //   this.props.baseUrl,
    //   this.props.targetID,
    //   data,
    //   this.props.token,
    // ).then(response => {
    //   if (response.status === 200) {
    //     this.props.updateProduct(this.state.productId);
    //   } else {
    //     console.log('error with product');
    //   }
    // });
  }

  goToNext() {
    this.setFound();

    this.props.history.push(this.props.next);
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;

    let index = 0;
    Object.keys(this.state.prodsForUser)
      .sort(
        (a, b) =>
          this.state.prodsForUser[a].riskFactor -
          this.state.prodsForUser[b].riskFactor,
      )
      .forEach((key, i) => {
        if (key === this.state.productId) {
          console.log(key, this.state.productId);
          index = i;
        }
      });

    const flowParams = Flow.getParams();

    let navigate =
      flowParams.mode === 'retirementManual'
        ? '/create/retirement/manual/calculator'
        : '/create/retirement/simulation/summary';

    let back =
      flowParams.mode === 'retirementManual'
        ? routes.retirementObjective
        : routes.retirementSimulationSavingsPerfil;

    console.log(this.state);

    return (
      <Fragment>
        <View />
        <Header
          title='Simulador pensiones'
          rigthAction={() => {
            this.props.history.goBack();
          }}
          leftAction={() => this.props.history.goBack()}
        />
        <Body vAlign='top'>
          <View style={{ marginBottom: 25 }}>
            <>
              {flowParams.flujo === 'transfer' ? (
                <>
                  <Text
                    headlineSt
                    centerSt
                    headlineSpaceSt
                    style={{ marginTop: 10 }}
                  >
                    Tipos de planes
                  </Text>
                  <Text centerSt font16St>
                    Estos son los diferentes planes de pensiones que tenemos en
                    EVO.
                    <Enfatize> Elige el tuyo.</Enfatize>
                  </Text>
                </>
              ) : (
                <Text
                  headlineSt
                  centerSt
                  headlineSpaceSt
                  style={{ marginTop: 10, fontSize: 32 }}
                >
                  ¿Cuál es tu perfil de riesgo?
                </Text>
              )}

              <View topSt style={{ width: '90%' }}>
                <View flexRowSt spaceBetweenSt>
                  <Text font16St boldSt>
                    MENOR RIESGO
                  </Text>
                  <Text font16St boldSt>
                    MAYOR RIESGO
                  </Text>
                </View>
                <View flexRowSt spaceBetweenSt>
                  <Text font12St>Rendimiento</Text>
                  <Text font12St>Rendimiento</Text>
                </View>

                <View flexRowSt spaceBetweenSt style={{ marginTop: -3 }}>
                  <Text font12St style={{ padding: 0 }}>
                    potencialemente menor
                  </Text>
                  <Text font12St style={{ padding: 0 }}>
                    potencialemente mayor
                  </Text>
                </View>
                <View flexRowSt spaceBetweenSt style={{ marginTop: 10 }}>
                  <View boldSt planesBoxSt>
                    1
                  </View>
                  <View boldSt planesBoxSt>
                    2
                  </View>
                  <View boldSt planesBoxSt>
                    3
                  </View>
                  <View
                    boldSt
                    planesBoxSt
                    planesBoxActiveSt={
                      this.state.productId &&
                      (this.state.prodsForUser[this.state.productId].name ===
                        'Conservador' ||
                        this.state.prodsForUser[this.state.productId].name ===
                          'Dinámico')
                    }
                  >
                    4
                  </View>
                  <View
                    boldSt
                    planesBoxSt
                    planesBoxActiveSt={
                      this.state.productId &&
                      this.state.prodsForUser[this.state.productId].name ===
                        'Agresivo'
                    }
                  >
                    5
                  </View>
                  <View boldSt planesBoxSt>
                    6
                  </View>
                  <View boldSt planesBoxSt>
                    7
                  </View>
                  <View
                    style={{
                      width: 35,
                      height: 35,
                      backgroundColor: '#5a4b44',
                      borderRadius: '3px',
                    }}
                    onClick={() => this.hideInfo()}
                  >
                    {this.state.hidePlanInfo ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )}
                  </View>
                </View>

                {this.state.hidePlanInfo ? null : (
                  <>
                    <View
                      style={{
                        backgroundColor: 'white',
                        padding: 10,
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: 'black',
                          textAlign: 'justify',
                          fontSize: 14,
                        }}
                      >
                        El nivel de riesgo del Plan de Pensiones es de{' '}
                        {(this.state.productId &&
                          this.state.prodsForUser[this.state.productId].name ===
                            'Conservador') ||
                        (this.state.productId &&
                          this.state.prodsForUser[this.state.productId].name ===
                            'Dinámico')
                          ? 4
                          : 5}{' '}
                        en una escala de 1 a 7. La categoría 1 no significa que
                        la inversión esté libre de riesgo.
                      </Text>
                      <Text
                        style={{
                          color: 'black',
                          textAlign: 'justify',
                          fontSize: 14,
                        }}
                      >
                        El perfil de riesgo señalado es un dato meramente
                        indicativo del plan y está calculado en base a datos
                        históricos de volatilidad que, no obstante, pueden no
                        constituir una indicación fiable del futuro perfil de
                        riesgo del plan, por lo que podría variar a lo largo del
                        tiempo.
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: 'white',
                        padding: 10,
                        marginTop: 10,
                      }}
                    >
                      <View flexRowSt topSt>
                        <img
                          alt=''
                          style={{
                            height: 17,
                            marginRight: 5,
                          }}
                          src='/images/bullets.png'
                        />
                        <Text
                          style={{
                            color: 'black',
                            textAlign: 'justify',
                            fontSize: 14,
                            padding: 0,
                          }}
                        >
                          El cobro de la prestación o el ejercicio del derecho
                          de rescate sólo es posible en caso de acaecimiento de
                          alguna de las contingencias o supuestos excepcionales
                          de liquidez regulados en la normativa de planes y
                          fondos de pensiones.
                        </Text>
                      </View>
                      <View flexRowSt topSt style={{ marginTop: 10 }}>
                        <img
                          alt=''
                          style={{
                            height: 17,
                            marginRight: 5,
                          }}
                          src='/images/bullets.png'
                        />
                        <Text
                          style={{
                            color: 'black',
                            textAlign: 'justify',
                            fontSize: 14,
                            padding: 0,
                          }}
                        >
                          El valor de los derechos de movilización, de las
                          prestaciones y de los supuestos excepcionales de
                          liquidez depende del valor de mercado de los activos
                          del fondo de pensiones y puede provocar pérdidas
                          relevantes.
                        </Text>
                      </View>
                    </View>
                  </>
                )}
              </View>
            </>
          </View>
          <ProductSelector
            prodsForUser={this.state.prodsForUser}
            config={{}}
            default={this.props.products}
            swipeableConfig={SwipeableViewsConfigurableConfig}
            classes={classes}
            byDefault={this.props.selected}
            targetID={this.props.targetID}
            token={this.props.token}
            baseUrl={this.props.baseUrl}
            updateProduct={this.props.updateProduct}
            index={index}
            handleChange={this.handleChange.bind(this)}
            maxRiskFactor={this.state.maxRiskFactor}
            userRiskFactor={this.state.riskFactor}
            history={this.props.history}
            location={this.props.location}
            retirement
          />
          <div className={classes.stepperContainer}>
            <MobileStepper
              variant='dots'
              steps={Object.keys(this.state.prodsForUser).length}
              position='static'
              activeStep={index}
              classes={transformClasses('targetProducts_stepper', classes)}
            />
          </div>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetProducts_typography2', classes)}
          >
            *Rentabilidad neta anual. Recuerda que las rentabilidades pasadas no
            garantizan rentabilidads futuras.
          </Typography>
          <div style={{ height: 80 }} />
        </Body>

        <Footer>
          <View style={{ padding: 15 }}>
            <CoreButton
              pinkButtonSt
              onClick={() => {
                const product = this.state.prodsForUser[this.state.productId];
                const data = {
                  product: product.product,
                  foundsConfiguration: [
                    {
                      foundCode: product.code,
                      percentage: 100,
                    },
                  ],
                };

                this.props.saveProdsForUser(this.state.prodsForUser);

                BanksAPI.setProducts(
                  this.props.baseUrl,
                  this.props.targetID,
                  data,
                  this.props.token,
                ).then(response => {
                  if (response.status === 200) {
                    this.props.updateProduct(this.state.productId);
                  } else {
                    console.log('error with product');
                  }
                });
                Flow.navigate(navigate, this.props, {
                  profitability: product.profitability,
                  productName: product.name,
                  pessimisticProfitability: product.pessimisticProfitability,
                  optimisticProfitability: product.optimisticProfitability,
                  metadata: this.props.metadata,
                  productRetirementId: this.state.productId,
                });
              }}
            >
              SELECCIONAR CARTERA
            </CoreButton>
          </View>
        </Footer>
      </Fragment>
    );
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetsProduct_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

const styles = theme => ({
  ...ProductSelectorStyle(theme),
  ...SwipeableViewsConfigurableStyles(theme),
  targetsProduct_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  targetProducts_recomended_container: {
    minHeight: '640px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
  },
  targetProducts_container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  targetProducts_container2: {
    marginTop: '26px',
    paddingLeft: '24px',
    paddingRight: '24px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetProducts_container3: {
    //marginTop: '26px',
    paddingLeft: '24px',
    paddingRight: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
  createTarget_headerButton_root: {
    color: '#000',
  },
  products_divImg: {
    width: '50%',
    height: '20vh',
    minHeight: 114,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  stepperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
  },
  targetProducts_stepper_dotActive: {
    backgroundColor: theme.palette.secondary.main + '!important',
    margin: '0 6px',
  },
  targetProducts_stepper_dot: {
    backgroundColor: theme.palette.primary.main,
    height: '10px',
    width: '11px',
    margin: '0 6px',
    borderRadius: 'unset',
  },
  targetProducts_containerHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  targetProducts_header_rigthIcon_root: {
    height: '19.8px',
    width: '19.8px',
    marginTop: '16px',
    marginRight: '23.2px',
  },
  targetProducts_header_leftIcon_root: {
    height: '19.8px',
    width: '19.8px',
    marginTop: '16px',
    marginLeft: '23.2px',
  },
  targetProducts_stepper_root: {
    backgroundColor: 'transparent',
  },
  productButton_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  productButton_container2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  targetProducts_button1_root: {
    width: '312px',
    height: '40px',
    marginBottom: '24px',
    marginTop: '34px',
  },
  targetProducts_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    width: '286px',
    marginTop: 5,
    marginBottom: 10,
  },
  targetProducts_typography2_root: {
    //marginTop: '29px',
    fontSize: '12px',
    textAlign: 'center',
  },
  swipeableViews_containerStyle: {
    width: '312px',
  },
  targetProducts_recomended_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: 0,
    lineHeight: '16px',
    marginTop: 20,
  },
  targetProducts_recomended_typography2: {
    textDecoration: 'underline',
  },
  products_historicoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 20,
    textDecoration: 'none',
  },
  products_fondosContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 10,
    borderLeft: 'solid 1px rgba(255,255,255,.3)',
    borderRight: 'solid 1px rgba(255,255,255,.3)',
    textDecoration: 'none',
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetRetirement);
