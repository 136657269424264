function generateStyles(theme) {
	return {
		listDocuments_listItemContainer: {
			backgroundColor: 'rgba(255,255,255,0.098)',
			margin: 10,
			borderRadius: 10,
		},
		listDocuments_listItem1_root: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingRight: 35,
		},
		textTextContainer: {
			display: 'flex',
			width: '100%',
			flexDirection: 'column',
		},
		listDocuments_listItem2_root: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			borderBottom: '1px solid rgba(200,200,200,0.098)',
			marginLeft: 'auto',
			marginRight: 'auto',
			width: '100%',
			paddingLeft: 10,
			paddingRight: 10,
		},
		listDocuments_listItem3_root: {
			display: 'flex',
			paddingLeft: 28,
		},
		docTextContainer: {
			display: 'flex',
			width: '85%',
			flexDirection: 'column',
		},
		docActionContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
		},
	};
}

export default generateStyles; // as ListDocumentsStyles
