function generateStyles(theme) {
  const styles = {
    modalWithState_modal1_root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 300,
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4) 
    }
  }
  return styles
}

export { generateStyles as ModalStyles }
