const routes = {
  retirementObjective: '/create/retirement',

  retirementBeneficiario: '/create/retirement/beneficiario',
  // retirement/simulation/
  retirementSimulation: '/create/retirement/simulation',
  retirementSimulationAge: '/create/retirement/simulation/age',
  retirementSimulationContribution:
    '/create/retirement/simulation/contribution',
  retirementSimulationCongratulations:
    '/create/retirement/simulation/congratulations/year',

  // retirement/simulation/pension
  retirementSimulationPensionInfo:
    '/create/retirement/simulation/pension/intro',
  retirementSimulationPensionEmployment:
    '/create/retirement/simulation/pension/employment',
  retirementSimulationPensionEmploymentSalary:
    '/create/retirement/simulation/pension/employment/salary',
  retirementSimulationPensionCongratulations:
    '/create/retirement/simulation/pension/congratulatons',

  // retirement/simulation/savings
  retirementSimulationSavingsInfo: '/create/retirement/simulation/savings/info',
  retirementSimulationSavingsAmount:
    '/create/retirement/simulation/savings/amount',
  retirementSimulationSavingsCongratulations:
    '/create/retirement/simulation/savings/congratulations',
  retirementSimulationSavingsPerfil:
    '/create/retirement/simulation/savings/perfil',

  // retirement/simulation/summary
  retirementSimulationSummary: '/create/retirement/simulation/summary',
  // retirement/simulation/calculations
  retirementSimulationCalculations:
    '/create/retirement/simulation/calculations',

  // retirement/simulation/results
  retirementSimulationResults: '/create/retirement/simulation/results',
  // retirement/simulation/genial
  retirementSimulationGenial: '/create/retirement/simulation/genial',

  // retirement/manual/calculator

  retirementManualCalculator: '/create/retirement/manual/calculator',

  // retirement/transfer/selectplan
  retirementTransferSelectPlan: '/transfer/retirement/selectplan',
};

export default routes;
