import { connect } from 'react-redux';
import * as Login2Actions from './actions';
import Login from '../views/login/login.component';

function mapStateToProps(state) {
	const { token, baseUrl } = state.main;
	return {
		token,
		baseUrl,
	};
}

const mapDispatchToProps = {
	fetchToken: Login2Actions.fetchToken,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Login);
