import React, { Component, Fragment } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
//import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';

//import { RulesAPI } from '../src-core/sdk/index';
import { RulesAPI } from '../../src-core/sdk/index';

//import { TargetsAPI, UsersAPI } from '../src-core/sdk/index';
import { TargetsAPI } from '../../src-core/sdk/index';
import { UsersAPI } from '../../src-core/sdk/index';

import Flow from '../../components/Flow';
import Currency from '../../components/Currency';
import Icon from '../../components/MyIcon';

import FollowupGraphsSimulation from './followupGraphsSimulation';
import FollowupGraphsProgress from './followupGraphsProgress';
import FollowupGraphsHistorical from './followupGraphsHistorical';
import { transformClasses } from '../../src-core/resources/utils';

class FollowupGraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'progress',
      product: null,
      //aportacionMensual: null,
      amountTotal: 0,
      percentTotal: 0,
      percentModel: 10.09,
      openInfo: false,
      dataProgress: null,
      dataHistoricalPercent: null,
      dataHistoricalAmount: null,
    };
    console.log('FollowupGraphs.constructor', props);
  }

  // TODO: Cambiar esto al Main, esto se repite en info.
  componentDidMount() {
    this.getProduct();
    this.getUserData();
    this.getChartsData();
    this.getMovements();
  }

  getMovements() {
    const flowParams = Flow.getParams(this.props);
    console.log('target --->', this.props.target.progress);

    TargetsAPI.getMovements(
      flowParams.baseUrl,
      this.props.targetID,
      flowParams.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log("FollowupMovements.getMovemenet", data)
        // this.props.updateMovements(data);

        this.setState({
          currentValueTarget: data.reduce((previousValue, currentValue) => {
            if (currentValue.concept === 'RENDIMIENTO NETO')
              return previousValue;
            else return previousValue + currentValue.amount;
          }, 0),
        });

        // console.log('movements ---->', data,
        // data.reduce(
        //   (previousValue, currentValue) =>{
        //     if (currentValue.concept === "RENDIMIENTO NETO")
        //       return previousValue
        //     else
        //       return previousValue + currentValue.amount

        //   }, 0)
        // )
      })
      .catch(error => {
        console.log('error recuperando los movimientos de usuario', error);
      });
  }

  getChartsData() {
    const flowParams = Flow.getParams(this.props);

    TargetsAPI.getChartData(
      flowParams.baseUrl,
      this.props.targetID,
      flowParams.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getChartsData', data);
        if (data[1]) {
          this.setState({
            dataProgress: data[0],
            dataHistoricalPercent: data[1],
            percentTotal: (
              data[1].data[0][data[1].data[0].length - 1] || 0
            ).toFixed(2),
            dataHistoricalAmount: data[2], // Coge el ultimo valor
            amountTotal: data[2].data[0][data[2].data[0].length - 1], // Coge el ultimo valor
          });
        } else if (data[0]) {
          this.setState({
            dataProgress: data[0],
          });
        }
      })
      .catch(error => {
        console.log('error consiguiendo el target', error);
      });
  }

  getUserData() {
    let params = Flow.getParams();

    //console.log('getUserData', params);
    UsersAPI.getUserData(params.baseUrl, 'me', params.token)
      .then(response => {
        if (response.status === 200) {
          //console.log('getUserData 1', response);
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log('FollowupGraphs getUserData ', data);
        this.setState({ edad: this.calcularEdad(data.nif.dateOfBirth) });
      })
      .catch(error => {
        console.error('getUserData error recuperando datos de usuario ', error);
      });
  }

  calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    //console.log('calcularEdad', fecha, edad);
    return edad;
  }

  getProduct() {
    //console.log('FollowupGraphs getProduct', this.props);
    TargetsAPI.getTargetProductInfo(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log('FollowupGraphs getProduct', data);
        this.setState({ product: data });
        this.getTargetRules();
      })
      .catch(error => {
        console.log(
          'error recuperando datos del producto asociado al target ',
          error,
        );
      });
  }

  filterFounds() {
    //console.log('FollowupGraphs.filterFounds', this.props);
    let pos = null;
    this.state.product.foundsConfiguration.map((config, index) => {
      if (config.percentage === 100) {
        pos = index;
      }
      return null;
    });
    return this.state.product.founds[pos];
  }

  async getTargetRules() {
    //let params = Flow.getParams();
    // console.log('FollowupGraphs getTargetRules', {
    //   baseUrl: params.baseUrl,
    //   targetId: this.props.match.params.id,
    //   token: params.token,
    // });

    const data = await RulesAPI.getTargetRules(
      this.props.baseUrl,
      this.props.targetID,
      this.props.token,
    );

    data.forEach(rule => {
      if (rule.ruleDefinition.identifier === 'PERIODIC_SAVING') {
        // console.log(
        //   'FollowupGraphs getTargetRules identifier === PERIODIC_SAVING',
        //   rule,
        // );
        //ºº Esto esta temporal dice Fran no es este campo
        this.setState({
          aportacionMensual: 30, //rule.ruleAmounts.notConsolided,
        });
      }
    });
    //console.log('FollowupGraphs getTargetRules', data);
  }

  /*
    Ventana Modal ------------------------------------------
  */
  ModalInfo(props) {
    return (
      <Modal
        open={props.open}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => props.onClose(false)}
      >
        <div
          style={{
            width: '312px',
            backgroundColor: '#e9e9e9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '14px',
            borderRadius: '8px',
            '&:focus': {
              outline: 'unset',
            },
            fontFamily: 'Calibre',
          }}
        >
          La rentabilidad ponderada por dinero (en inglés se denomina
          “Money-weighted Rate of Return” y en la aplicación de Finizens sería
          la “Rentabilidad de tu Plan”).
          <br />
          <strong>Money-weighted Rate of Return</strong>
          La rentabilidad ponderada por dinero es la rentabilidad de tu cartera
          teniendo en cuenta el patrón de tus aportaciones y tus reembolsos. Es
          decir, este cálculo tiene en cuenta el dinero que has invertido en
          cada momento, ponderando los resultados en el tiempo.  Esta forma de
          cálculo de la rentabilidad es la forma óptima para evaluar la
          evolución del patrimonio de tu plan, pero no es la mejor medida para
          evaluar el desempeño del gestor de tus inversiones. Esto se debe a
          que, según este criterio, el cálculo de la rentabilidad se verá
          afectado por el momento en el que se realizan las aportaciones o los
          reembolsos y su volumen, ambos factores sobre los cuales el gestor no
          tiene control directo ya que dependen del comportamiento de cada
          cliente. Desde el punto de vista técnico, refleja el mismo concepto
          que la tasa interna de retorno (TIR). 
        </div>
      </Modal>
    );
  }

  render() {
    const { classes /*, targetID*/ } = this.props;
    //const product = this.state.product ? this.filterFounds() : null;
    console.log('FollowupGraphs render prod', {
      product: this.props.product,
      currentTab: this.state.currentTab,
      dataHistoricalAmount: this.state.dataHistoricalAmount,
      dataHistoricalPercent: this.state.dataHistoricalPercent,
      dataProgress: this.state.dataProgress,
    });

    const styleNumbers1 = { color: 'white', fontSize: 23, lineHeight: 1 };
    const styleNumbers2 = { color: 'white' };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          //justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        {this.props.target.type === 'familyTarget' && (
          <Fragment>
            <div
              style={{
                display:
                  this.state.currentTab !== 'simulation' ? 'flex' : 'none',
                flexFlow: 'row',
                width: '100%',
                justifyContent: 'space-around',
                marginBottom: 5,
                marginTop: 5,
              }}
            >
              <div style={{ display: 'flex', flexFlow: 'column' }}>
                <Typography style={styleNumbers1}>
                  {Currency(
                    this.props.target.progress.completed -
                      this.state.currentValueTarget,
                    2,
                  )}
                </Typography>
                <Typography style={styleNumbers2}>Rentabilidad</Typography>
              </div>
              <div
                style={{ display: 'flex', flexFlow: 'column' }}
                onClick={() => this.setState({ openInfo: true })}
              >
                <Typography style={styleNumbers1}>
                  {// this.state.percentTotal
                  (
                    ((this.props.target.progress.completed -
                      this.state.currentValueTarget) /
                      (this.state.currentValueTarget || 1)) *
                    100
                  ).toFixed(2)}
                  {' %'}
                </Typography>
                <Typography style={styleNumbers2}>
                  Tu Cartera{' '}
                  <Icon
                    name='info'
                    style={{
                      width: 15,
                      marginBottom: -4,
                      marginLeft: 7,
                    }}
                  />
                </Typography>
              </div>
              <this.ModalInfo
                open={this.state.openInfo}
                onClose={() => this.setState({ openInfo: false })}
              />
            </div>

            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                classes={{
                  select: classes.selectGraficaSelect,
                  outline: classes.selectGraficaOutline,
                  icon: classes.selectGraficaIcon,
                }}
                value={this.state.currentTab}
                onChange={event => {
                  this.setState({ currentTab: event.target.value });
                }}
                input={
                  <OutlinedInput
                    //labelWidth={this.state.labelWidth}
                    name='currentTab'
                    id='outlined-age-simple'
                  />
                }
              >
                <MenuItem value={'progress'}>Total de tu plan</MenuItem>
                {/* <MenuItem value={'amount'}>
                  Rentabilidad de tu plan (€)
                </MenuItem>
                <MenuItem value={'percent'}>
                  Rentabilidad en porcentaje
                </MenuItem> */}
                <MenuItem value={'simulation'}>Simula tu ahorro</MenuItem>
              </Select>
            </FormControl>
          </Fragment>
        )}

        {console.log('*** ', this.state)}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            //height: 250,
            paddingTop: 5,
          }}
        >
          {!console.log('PROGRESS', this.state.dataProgress) &&
            !this.state.dataProgress && <CircularProgress />}

          {/* este se carga siempre en NewWish y FamilyTarget */}
          {this.state.currentTab === 'progress' && (
            <FollowupGraphsProgress
              {...this.props}
              //targetID={this.props.targetID}
              data={this.state.dataProgress}
            />
          )}
          {this.state.currentTab === 'percent' && (
            <FollowupGraphsHistorical
              type={this.state.currentTab}
              data={this.state.dataHistoricalPercent}
            />
          )}
          {this.state.currentTab === 'amount' && (
            <FollowupGraphsHistorical
              type={this.state.currentTab}
              data={this.state.dataHistoricalAmount}
            />
          )}
          {this.state.currentTab === 'simulation' && (
            <FollowupGraphsSimulation
              target={this.props.target}
              product={this.props.product}
              edad={this.state.edad}
              aportacionMensual={this.state.aportacionMensual}
              type={this.state.currentTab}
            />
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    //minWidth: 120,
    width: '100%',
    marginBottom: 0,
  },
  selectEmpty: {
    //marginTop: theme.spacing(2),
  },
  selectGraficaSelect: {
    color: 'white',
    fontSize: 21,
    marginTop: 5,
    marginLeft: -9,
  },
  selectGraficaOutline: {
    fontSize: 20,
    color: 'white',
  },
  selectGraficaIcon: {
    color: 'white',
    fontSize: 25,
    marginBottom: -10,
  },
});

export default withStyles(styles)(FollowupGraphs);
