import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
// import CardActions from '@material-ui/core/CardActions';
// import IconButton from '@material-ui/core/IconButton';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Collapse from '@material-ui/core/Collapse';

import { Utils } from '../../src-core/resources/index';

import MyIcon from '../../components/MyIcon';
import DocView from '../../components/MyDocView';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import MyText from '../../components/MyText';
import Container from '../../components/MyContainer';
import Icons from '../../components/MyIcon.config';
import { InputCheck } from 'coinscrap-webapp-core';

import Flow from '../../components/Flow';

export default function CreateTargetConditionsFamily(props) {
  // const [collapse1, setCollapse1] = useState(false);
  // const [collapse2, setCollapse2] = useState(false);
  // const [collapse3, setCollapse3] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [checkConsciente, setCheckConsciente] = useState(false);
  const [checkTerminos, setCheckTerminos] = useState(false);
  const [readSeveralDocs, setReadSeveralDocs] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const { classes } = props;
  const transformClasses = Utils.transformClasses;

  const flowParams = Flow.getParams(props);

  console.log('CreateTargetConditionsFamily', props);

  // function ExpandItem(props) {
  //   return (
  //     <Fragment>
  //       <CardActions
  //         disableSpacing
  //         onClick={() => props.onClick()}
  //         style={{
  //           width: '100%',
  //           borderTopWidth: 1,
  //           borderTopStyle: 'dotted',
  //           borderTopColor: 'white',
  //           marginTop: 5,
  //         }}
  //       >
  //         <MyIcon
  //           name={props.iconName}
  //           style={{ width: 40, height: 40, marginTop: 4 }}
  //         />
  //         <MyText
  //           id={props.id}
  //           style={{
  //             fontSize: 18,
  //             fontWeight: 600,
  //             width: '90%',
  //             paddingTop: 10,
  //             //              color: 'black',
  //           }}
  //         >
  //           {props.title}
  //         </MyText>
  //         <IconButton
  //           style={{
  //             width: '10%',
  //             //color: 'black',
  //           }}
  //           color='primary'
  //           //aria-expanded={openCollapse1}
  //           aria-label='show more'
  //         >
  //           <ExpandMoreIcon
  //             style={
  //               props.show
  //                 ? {
  //                     transform: 'rotate(180deg)',
  //                     transition: 'transform 2s',
  //                   }
  //                 : {
  //                     transform: 'rotate(0deg)',
  //                     transition: 'transform 2s',
  //                   }
  //             }
  //           />
  //         </IconButton>
  //       </CardActions>
  //       <Collapse
  //         in={props.show}
  //         style={{
  //           width: '100%',
  //           textAlign: 'left',
  //           fontFamily: 'Calibre',
  //         }}
  //       >
  //         {props.children}
  //       </Collapse>
  //     </Fragment>
  //   );
  // }

  console.log(readSeveralDocs[1], 'check');
  console.log(readSeveralDocs, 'checks');
  return (
    <div className='container'>
      <Header
        //title={'Resumen ' + props.target.name}
        title='Condiciones'
        rigthAction={() => props.history.push('/home/objetivos')}
      />
      <Body vAlign='top'>
        <MyIcon name='check' style={{ height: 80 }} />
        <MyText variant='headline'>¡Ya estamos en marcha!</MyText>
        <MyText
          style={{
            marginTop: 20,
            //marginBottom: 35
          }}
        >
          Prepárate para el futuro. Tus ahorros se traspasarán al Plan de
          Inversión Inteligente EVO.
        </MyText>

        {/*
				<ExpandItem
					id='expan1'
					show={collapse1}
					onClick={() => setCollapse1(!collapse1)}
					title='EL MÁS LISTO DE LA CLASE'
					iconName='lapizRegla'
				>
					<MyText>
						Es tan listo que utiliza la tecnología robot-advisor y selecciona
						por ti donde es mejor invertir a través de algoritmos financieros.
						Estás invirtiendo en más de 135 paises y en miles de empresas.
					</MyText>
				</ExpandItem>

				<ExpandItem
					id='expan2'
					show={collapse2}
					onClick={() => setCollapse2(!collapse2)}
					title='UN PLAN MUY FLEXIBLE'
					iconName='muelle'
				>
					<MyText>
						Tanto que evalúa tu perfil inversor y te recomienda cual de tus
						opciones te conviene más. Todo para que darle vida a tu dinero sea
						pan comido.
					</MyText>
				</ExpandItem>

				<ExpandItem
					id='expan3'
					show={collapse3}
					onClick={() => setCollapse3(!collapse3)}
					title='TE SENTIRÁS LIBRE'
					iconName='muelle'
				>
					<MyText>
						Adios a las ataduras. Tú dinero está siempre disponible y sin
						permanencia.
					</MyText>
				</ExpandItem>
				*/}
        <Container
          noPadding
          style={{
            //borderTop: '1px dotted white'
            marginTop: -35,
          }}
        >
          <Container
            horizontal
            noPadding
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <MyIcon
              title='Datos Fundamentales'
              name='documentoLupa'
              style={{
                width: 140,
                height: 35,
                marginTop: 15,
                marginBottom: 15,
              }}
              onClick={() => {
                let url = '';
                if (flowParams.productName === 'Agresivo') {
                  url =
                    'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/products/pensiones/agresivo/DDF-EVO-AGRESIVO.pdf';
                } else if (flowParams.productName === 'Animado') {
                  url =
                    '';
                } else if (flowParams.productName === 'Dinámico') {
                  url =
                    'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/products/pensiones/dinamico/DDF-EVO-DINAMICO.pdf';
                } else if (flowParams.productName === 'Moderado') {
                  url =
                    '';
                } else if (flowParams.productName === 'Conservador') {
                  url =
                    'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/products/pensiones/conservador/DDF-EVO-CONSERVADOR.pdf';
                } else {
                  console.error(
                    'ProductName desconocido',
                    flowParams.productName,
                  );
                }

                setModalUrl(url);
                setModalTitle('Documentos');
                setOpenModal(true);
              }}
            />
          </Container>
          <MyText>
            Aquí puedes descargar toda la documentación del Plan de Inversión
            Inteligente de EVO.
          </MyText>
        </Container>

        <div
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          <div
            id='condiciones'
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={() => {
              setReadSeveralDocs({ ...readSeveralDocs, 0: true });
              setModalUrl(
                //'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/caser/Nota+Informativa+%2B+CCPP.PDF',
                `https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/products/pensiones/${flowParams.productName === 'Agresivo'
                  ? 'agresivo'
                  : flowParams.productName === 'Conservador'
                    ? 'conservador'
                    : flowParams.productName === 'Dinámico'
                      ? 'dinamico'
                      : flowParams.productName
                }/especificaciones.pdf`,
              );
              setModalTitle('Especificaciones de producto');
              setOpenModal(true);
            }}
          >
            <Typography className='textH1'>
              ESPECIFICACIONES DE PRODUCTO
              <Checkbox
                checked={readSeveralDocs[0] === true}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                style={{
                  color: 'white',
                  float: 'right',
                  paddingTop: 5,
                }}
                id='checkConsciente'
              />
            </Typography>
            <p className='textNormal'>Términos de contratación.</p>
          </div>
        </div>

        <div
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          <div
            id='adeudoSepa'
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={() => {
              setReadSeveralDocs({ ...readSeveralDocs, 1: true });
              setModalUrl(
                'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/caser/Adeudo+SEPA.PDF',
              );
              setModalTitle('Adeudo SEPA');
              setOpenModal(true);
            }}
          >
            <Typography className='textH1'>
              ADEUDO SEPA
              <Checkbox
                checked={readSeveralDocs[1] === true}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                style={{
                  color: 'white',
                  float: 'right',
                  paddingTop: 5,
                }}
                id='checkConsciente'
              />
            </Typography>
            <p className='textNormal'>Condiciones de domiciliación bancaria.</p>
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Checkbox
            onChange={(e, value) => {
              setCheckConsciente(value);
              setButtonDisabled(!value || !checkTerminos);
            }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              paddingTop: 5,
            }}
            id='checkConsciente'
          />
          <Typography className='textNormal'>
            Soy consciente de que para que mi inversión tenga rentabilidad,
            siempre se ha de asumir ciertos riesgos y que rentabilidades pasadas
            no garantizan rentabilidades futuras.
          </Typography>
        </div>
        <div
          style={{
            marginTop: 20,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            // marginBottom: 60,
          }}
        >
          <Checkbox
            onChange={(e, value) => {
              setCheckTerminos(value);
              setButtonDisabled(!value || !checkConsciente);
            }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              paddingTop: 5,
            }}
            id='checkTerminos'
          />
          <Typography className='textNormal'>
            Deseo contratar el producto en este momento.
          </Typography>
        </div>
        <div
          style={{
            marginTop: 10,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            marginBottom: 50,
            justifyContent: 'center',
          }}
        >
          <Typography style={{ color: '#c60' }}>
            Es necesario leer las
            <br />
            "Condiciones Particulares"
            <br />y el "Adeudo SEPA"
          </Typography>
        </div>
      </Body>
      <Footer>
        <Button
          id='okButton'
          variant='contained'
          color='secondary'
          classes={transformClasses('targetSummary_button1', classes)}
          onClick={() => props.history.push(props.next)}
          disabled={
            buttonDisabled ||
            !(readSeveralDocs[0] === true && readSeveralDocs[1] === true)
          }
        >
          Ok todo correcto
        </Button>
      </Footer>
      {/* Ventana Modal */}
      {openModal && (
        <DocView
          open={openModal}
          title={modalTitle}
          onClose={() => setOpenModal(false)}
          url={modalUrl}
          styleType='black'
          leftIcon={Icons.disquete}
          disk
        />
      )}
    </div>
  );
}
