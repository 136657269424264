import React, { Component } from 'react';
import {
  ListRuleMovements,
  ListRuleMovementsConfig,
  ListRuleMovementsStyles,
} from '../../src-core/rules/index';
import Header from './header.component';
//import { RulesAPI } from '../../components-core/sdk';
import { RulesAPI } from '../../src-core/sdk/index';

import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  ...ListRuleMovementsStyles(theme),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
});

class RuleMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: null,
    };
  }

  componentDidMount() {
    this.getMovements(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    );
  }

  getMovements(baseUrl, id, token) {
    RulesAPI.getRuleMovements(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //ºº No entiendo esta llamada a la API devuelve siempre null??
        return null;
      })
      .catch(error => {
        console.log('error recuperando los movimientos de regla ', error);
      });
  }

  goToDetails(id) {
    this.props.history.push(
      '/followup/' + this.props.targetID + '/Movimientos/' + id + '/detalles',
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          classes={classes}
          history={this.props.history}
          prev='/home/reglas'
        />
        {this.state.movements !== null ? (
          <ListRuleMovements
            classes={classes}
            config={ListRuleMovementsConfig}
            movements={this.props.movements}
            history={this.props.history}
            goToDetails={this.goToDetails.bind(this)}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RuleMovements);
