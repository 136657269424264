import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { Utils } from '../../../../src-core/resources/index'

class ListRuleMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: null,
    };
  }

  renderListItems() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.movements.map(element => {
      return (
        <ListItem
          {...config.listItem1}
          key={element._id}
          classes={transformClasses('listRuleMovements_listItem1', classes)}
        >
          <div className={classes.movements_confe_container}>
            <div className={classes.movements_concepto_container}>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'listRuleMovements_typography1',
                  classes,
                )}
              >
                {new Date(element.endDate).toLocaleDateString('es-ES')}
              </Typography>
            </div>
          </div>
          <div className={classes.movements_ammount_container}>
            <Typography
              {...config.typography3}
              classes={transformClasses(
                'listRuleMovements_typography3',
                classes,
              )}
            >
              {Utils.formatCurrency(element.amount)}
            </Typography>
          </div>
          <div className={classes.movements_icon_container} />
        </ListItem>
      );
    });
    return elements;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.listRuleMovements_divListContainer}>
        <List children={this.renderListItems()} />
      </div>
    );
  }
}

export default ListRuleMovements;
