import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {
  NotificationsList,
  NotificationsListConfig,
  NotificationsListStyles,
} from '../../src-core/users/index';

//import { UsersAPI } from '../../core/
import { UsersAPI } from '../../src-core/sdk/index';

import Header from '../../components/MyHeader';

const styles = theme => ({
  ...NotificationsListStyles(theme),
  container: {
    height: '100vh',
    minHeight: 568,
    width: '100%',
    overflow: 'auto',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  notifications_container: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
    paddingLeft: 32,
    paddingRight: 32,
    overflow: 'auto',
  },
  header_centerIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notifications_headerTypography1_root: {
    fontSize: 9,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  notificationsList_typography2_root: {
    ...NotificationsListStyles(theme).notificationsList_typography2_root,
    alignItems: 'center',
    display: 'flex',
    fontSize: 11,
  },
  notificationsList_typography1_root: {
    ...NotificationsListStyles(theme).notificationsList_typography1_root,
    alignItems: 'center',
    fontSize: 13,
    flexGrow: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notificationsList_typography3_root: {
    ...NotificationsListStyles(theme).notificationsList_typography3_root,
    alignItems: 'flex-start',
    fontSize: 16,
    flexBasis: '100%',
  },
  notificationsList_listItem1_root: {
    ...NotificationsListStyles(theme).notificationsList_listItem1_root,
    borderTop: '1px solid rgba(200,200,200,0.25)',
  },
  notificationsList_list1_root: {
    ...NotificationsListStyles(theme).notificationsList_list1_root,
    paddingTop: 0,
  },
  notifications_headerIconButton2_root: {
    padding: 'unset',
  },
  notificationsList_titleTextContainer: {
    ...NotificationsListStyles(theme).notificationsList_titleTextContainer,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  notificationsList_iconButton1_root: {
    ...NotificationsListStyles(theme).notificationsList_iconButton1_root,
    marginLeft: 0,
  },
  notificationsList_iconButton2_root: {
    ...NotificationsListStyles(theme).notificationsList_iconButton2_root,
    marginLeft: 0,
  },
  notificationsList_titleIconContainer: {
    ...NotificationsListStyles(theme).notificationsList_titleIconContainer,
    marginTop: 15,
    minWidth: 25,
  },
});

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: null,
    };
  }

  goChat() {
    this.props.history.push({
      pathname: '/chatSupport',
      state: {
        back: this.props.location.pathname,
      },
    });
  }

  componentDidMount() {
    UsersAPI.getNotifications(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          notifications: data,
        });
      })
      .catch(error => {
        console.log('error recuperando las notificaciones ', error);
      });
  }

  checkNotification(id) {
    UsersAPI.checkNotifications(this.props.baseUrl, 'me', id, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return null;
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error marcando la notificación ', error);
      });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;
    //const transformClasses = Utils.transformClasses
    return (
      <div className={classes.container}>
        <Header title={'Notificaciones'} rigthAction={() => this.goBack()} />
        {this.state.notifications !== null ? (
          <div className={classes.notifications_container}>
            <NotificationsList
              config={NotificationsListConfig}
              classes={classes}
              notifications={this.state.notifications}
              checkNotification={this.checkNotification.bind(this)}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Notifications);
