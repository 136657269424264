import React, { Component } from 'react';
import List from '@material-ui/core/List';
import { Utils } from '../../../resources/index';

class ListWithState extends Component {
  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <List
        {...config}
        classes={transformClasses('listWithState_list', classes)}
      />
    );
  }
}

export default ListWithState;
