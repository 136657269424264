import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputCheck', {
  styles: {
    default: {
      containerComponent: {
        width: 'auto',
        flexDirection: 'row',
      },
      labelComponent: {
        flexDirection: 'row',
      },
      checkComponent: {
        color: colors.green1,

        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
});
