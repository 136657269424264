import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { Utils } from '../../../resources/index';

class ModalWithState extends Component {
  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <Modal
        {...config}
        classes={transformClasses('modalWithState_modal1', classes)}
      >
        {this.props.renderData()}
      </Modal>
    );
  }
}

export default ModalWithState;
