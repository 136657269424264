import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

import TargetsTabsStyles from '../targetsTabs/targetsTabs.style';
//import { ModalStyles } from '../../core/
import { ModalStyles } from '../../src-core/ui/index';
//import { UsersAPI, TargetsAPI, RulesAPI } from '../../core/
import { UsersAPI } from '../../src-core/sdk/index';
import { TargetsAPI } from '../../src-core/sdk/index';
import { RulesAPI } from '../../src-core/sdk/index';

import Header from '../../components/MyHeader';

import HomeSummary from './HomeSummary';
import HomeTargets from './HomeTargets';
import HomeRules from './HomeRules';
import HomeHeaderConfigInfo from './HomeHeaderConfigInfo';
import Flow from '../../components/Flow';
import { sortTransfers } from '../../libs/sortTransfers';

// let bannerAmazon = false;

class Home extends Component {
  constructor(props) {
    super(props);
    if (props.token === null) {
      props.history.push('/landing');
    }
    const path = this.props.location.pathname.split('/');
    let current = tabs.findIndex(tab => path.indexOf(tab.label) > 0);
    if (current < 0) {
      current = 0;
    }
    this.state = {
      summary: null,
      userTargets: null,
      userRules: null,
      currentTab: current,
      open: false,
      summaryIds: {
        rules: [],
        targets: [],
      },
      end: false,
      notificationsCounter: null,
      user: null,
      enabledRetirement: null,
    };
  }

  componentDidMount() {
    console.error('home.componentDidMount');
    let promoCajaFuerte = global.localStorage.getItem('promoCajaFuerteShowed');
    if (promoCajaFuerte !== 'true') {
      // global.localStorage.setItem('promoChequeAmazonShowed', 'true');

      this.props.history.push('/promoCajaFuerte');
    } else {
      console.error('home.componentDidMount2');
      const { baseUrl, token } = this.props;
      this.summary(baseUrl, 'me', token);
      this.targetsUser(baseUrl, 'me', token);
      this.getRulesDefinitions(baseUrl, token);
      this.getUserData(baseUrl, token);
      this.getTargetConfigurationSchema();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const path = this.props.location.pathname.split('/');
      let current = tabs.findIndex(tab => path.indexOf(tab.label) > 0);
      if (current < 0) {
        current = 0;
      }
      this.setState({
        currentTab: current,
      });
    }
  }

  openMenu() {
    this.props.history.push('/menu');
  }

  closeMenu() {
    this.setState({ open: false });
  }

  handleClick(route, current, event) {
    event.preventDefault();
    this.setState({
      currentTab: tabs.map(tab => tab.filter).indexOf(current),
    });
    this.props.history.push(route);
  }

  renderTabs() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = tabs.map(tab => {
      return (
        <Tab
          label={tab.label}
          key={tab.filter}
          icon={tab.icon}
          onClick={e => this.handleClick('/home/' + tab.filter, tab.filter, e)}
          classes={transformClasses('targetsTabs_tab1', classes)}
        />
      );
    });
    return elements;
  }

  render() {
    console.log({ targets: this.state.userTargets });
    console.log('Home.render', this.state.summaryIds);
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    /*
    const configurationMenu = {
      ...ModalConfig,
      onClose: this.closeMenu.bind(this),
      open: this.state.open
    }
    */
    const renderSummary = () =>
      this.state.summary !== null && this.state.userRules !== null ? (
        <HomeSummary
          summary={this.state.summary}
          open={this.state.open}
          openMenu={this.openMenu.bind(this)}
          closeMenu={this.closeMenu.bind(this)}
          history={this.props.history}
          events={this.state.userRules.filter(
            rule => rule.eventDefinition !== null,
          )}
          summaryIds={this.state.summaryIds}
          baseUrl={this.props.baseUrl}
          token={this.props.token}
        />
      ) : (
        <div className={classes.home_loadingContainer}>
          <CircularProgress color='secondary' />
        </div>
      );

    const renderTabsSelector = (
      <Tabs
        textColor='primary'
        value={this.state.currentTab}
        id='tabs'
        classes={transformClasses('targetsTabs_tabs1', classes)}
      >
        {this.renderTabs()}
      </Tabs>
    );

    const renderSwitch = (
      <Switch>
        <Route path='/home/resumen/:filter?' render={renderSummary} />

        <Route
          path='/home/objetivos/:filter?'
          render={props =>
            this.state.userTargets !== null &&
              this.state.user !== null &&
              this.state.enabledRetirement !== null ? (
              <HomeTargets
                targetsUser={this.state.userTargets}
                history={this.props.history}
                user={this.state.user}
                location={this.props.location}
                enabledRetirement={this.state.enabledRetirement}
                baseUrl={this.props.baseUrl}
                token={this.props.token}
              />
            ) : (
              <div className={classes.home_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />

        <Route
          path='/home/reglas/'
          render={props =>
            this.state.userRules !== null &&
              this.state.end &&
              this.state.userTargets ? (
              <HomeRules
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                rules={this.state.userRules}
                history={this.props.history}
                summaryIds={this.state.summaryIds}
                targets={this.state.userTargets}
                user={this.state.user}
                location={this.props.location}
              />
            ) : (
              <div className={classes.home_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />

        <Route render={renderSummary} />
      </Switch>
    );

    return (
      <Fragment>
        <Header title={'Hucha inteligente EVO'}>
          <HomeHeaderConfigInfo history={this.props.history} />
          {renderTabsSelector}
        </Header>
        {renderSwitch}
      </Fragment>
    );
  }

  getTargetConfigurationSchema() {
    TargetsAPI.getTypes(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log('type: ', data)
        this.setState({
          enabledRetirement: data.filter(
            element => element.identifier === 'retirement',
          )[0].available,
        });
      })
      .catch(error => {
        console.log(
          'error recuperando el esquema de configuración de targetType ',
          error,
        );
      });
  }

  summary(url, user, token) {
    UsersAPI.getTargetsSummary(url, user, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log('summary: ', data)
        this.setState({
          summary: data,
        });
      })
      .catch(error => {
        console.log('error getting the summary', error);
      });
  }

  async targetsUser(url, user, token) {
    try {
      const response = await TargetsAPI.userTargets(url, user, token);

      let data = await response.json();
      let totalDeposits = 0;
      const addTransferToPP = data.map(async target => {
        console.log("addTransferToPP ------------", { target });
        if (target.type === 'retirement') {
          let dataTransfer = [];

          try {
            const responseTransfer = await TargetsAPI.getStatusTPP(
              url,
              target.user,
              token,
              target._id,
            );
            const dataTransferJson = await responseTransfer.json();
            dataTransfer = dataTransferJson;
            console.log('responseTransfer', dataTransferJson)
          } catch (error) {
            dataTransfer = [];
          }

          const responseMovements = await TargetsAPI.getMovements(
            this.props.baseUrl,
            target._id,
            this.props.token,
          );

          const dataTargets = await responseMovements.json();
          console.log("clog230216 responseMovements ------------:", { dataTargets });
          const beginningOfYear = moment()
            .startOf('year')
            .valueOf();

          const notIncludedWords = ['traslado', 'rendimiento']

          const totalMovements = dataTargets
            .filter(
              item =>
                item?.accountingDate &&
                moment(item.accountingDate).valueOf() > beginningOfYear,
            ).filter(
              //item => (!notIncludedWords.includes(item.concept.toLowerCase()) && item.amount > 0)
              item => (
                !item.concept.toLowerCase().includes('traslado')
                && !item.concept.toLowerCase().includes('rendimiento')
                && item.amount > 0
              )
            ).reduce((previousValue, currentValue) => {
              if (currentValue.concept === 'RENDIMIENTO NETO') {
                return previousValue;
              } else {
                return previousValue + currentValue.amount;
              }
            }, 0);

          console.log('clog230216 totalMovements ------------: ', totalMovements)

          totalDeposits = totalDeposits + totalMovements;
          /* const sortedTransfers = dataTransfer.sort((a, b) => parseInt(b?.codigo) - parseInt(a?.codigo)) */
          const sortedTransfers = dataTransfer.length > 0 ? sortTransfers(dataTransfer) : dataTransfer;
          console.log('sortedTransfers before: ', dataTransfer)
          console.log('sortedTransfers after: ', sortedTransfers)

          return {
            ...target,
            transfers: dataTransfer.length > 0 ? sortedTransfers.slice(0, 1) : dataTransfer,
          };
        }

        return target;
      });

      await Promise.all(addTransferToPP).then(values => {
        Flow.setParams({
          totalDeposits,
        });

        console.log({ values, totalDeposits });
        this.setState({
          userTargets: values,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  //   .then(response => {
  //     if (response.status === 200) {
  //       return response.json();
  //     } else {
  //       throw response.status;
  //     }
  //   })
  //   .then(data => {
  //     console.log('data->', data);
  //     TargetsAPI.getStatusTPP(url, data[0].user, token, data[0]._id)
  //       .then(response => {
  //         if (response.status === 200) {
  //           return response.json();
  //         } else {
  //           throw response.status;
  //         }
  //       })
  //       .then(data2 => console.log('data2:', data2));

  //     this.setState({
  //       userTargets: data,
  //     });
  //   })
  //   .catch(error => {
  //     console.log('error getting the user Targets');
  //   });
  // }

  /*
  getrulesDefinitions(url, token) {
    RulesAPI.getRulesDefinitions(url, token)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw response.status
        }
      })
      .then(data => {
        //console.log("getRulesDefinitions", data);
        this.setState({
          userRules: data
        })
        data.forEach(element => {
          this.getRulesByDefinition(url, token, element._id, data);
        })
      })
      .catch(error => {
        console.error('error getting the user Rules', error)
      })
  }
  */
  getRulesDefinitions(url, token) {
    RulesAPI.getRulesDefinitions(url, token).then(data => {
      console.log('getRulesDefinitions', data);
      console.log(data);

      const reglaRetirementTraspaso = {
        name: 'Traspasar',
        title: 'Traspasar plan de pension a Evo',
        description: 'Traspasar un plan de pensiones existente a su plan Evo',
        eventDefinition: null,
        traspaso: true,
      };
      this.setState({
        userRules: [...data, reglaRetirementTraspaso],
      });
      data.forEach(element => {
        //ºº Creo q estas llamadas repetitivas sobran porque
        // en RuleList no utiliza el culesCount de aquí, sino q lo carga de nuevo.
        this.getRulesByDefinition(url, token, element._id, data);
      });
    });
  }

  getRulesByDefinition(url, token, ruleDefId, definitions) {
    RulesAPI.getRulesByDefinition(url, 'me', ruleDefId, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('Home.getRulesByDefinition ruleDefId:', ruleDefId, data);

        let summaryIds = this.state.summaryIds;
        if (!summaryIds) {
          summaryIds = {
            rules: [],
            targets: [],
          };
        }
        data.forEach(rule => {
          if (rule.isActive) {
            // Si no existe la rule, la crea, sino le añade los targets
            if (!summaryIds.rules[rule.ruleDefinition]) {
              Object.defineProperty(summaryIds.rules, rule.ruleDefinition, {
                value: {
                  targets: [],
                },
                writable: true,
                enumerable: true,
                configurable: true,
              });
              ++summaryIds.rules.length;
              //console.log("añade rule1", summaryIds)
            }
            if (!summaryIds.rules[rule.ruleDefinition].targets[rule.target]) {
              summaryIds.rules[rule.ruleDefinition].targets.push(rule.target);
              //console.log("añade rule2", summaryIds)
            }

            // Si no existe el target, lo crea, sino le añade las rules
            if (!summaryIds.targets[rule.target]) {
              Object.defineProperty(summaryIds.targets, rule.target, {
                value: {
                  rules: [],
                },
                writable: true,
                enumerable: true,
                configurable: true,
              });
              ++summaryIds.targets.length;
              //console.log("añade targets1", summaryIds)
            }
            if (!summaryIds.targets[rule.target].rules[rule.ruleDefinition]) {
              summaryIds.targets[rule.target].rules.push(rule.ruleDefinition);
              //console.log("añade targets2", summaryIds)
            }
          }

          this.setState({
            summaryIds: summaryIds,
          });
        });

        console.log('Home.getRulesByDefinition summaryIds', summaryIds);
        this.setState({
          end: true,
        });
      });
  }

  getUserData(url, token) {
    UsersAPI.getUserData(url, 'me', token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getUserData', data);
        //global.myApp = { user: data };
        window.localStorage.setItem('userId', data._id);

        this.setState({
          user: data,
        });
      })
      .catch(error => {
        console.log('error recuperando datos de usuario: ', error);
      });
  }
}

const styles = theme => ({
  ...TargetsTabsStyles(theme),
  ...ModalStyles(theme),
  home_movilScreen: {
    minHeight: '640px',
    height: '100vh',
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  home_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 10,
    boxSizing: 'border-box',
    zIndex: 100,
  },
  home_logo: {
    maxHeight: '100%',
  },
  home_icon: {
    position: 'absolute',
    right: 0,
    paddingRight: 12,
  },
  home_iconHeader_root: {
    fontSize: 32,
    color: theme.palette.primary.main,
  },
  home_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  home_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  home_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  home_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '30px',
  },
  targetsTabs_tabs1_root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(255,255,255,.3)',
    //minHeight: '30px'
    marginTop: -10,
    marginLeft: 10,
    marginRight: 10,
  },
  targetsTabs_tab1_textColorPrimary: {
    fontSize: '14px',
    color: 'rgba(255,255,255,.5)',
    textTransform: 'uppercase',
  },
  targetsTabs_tab1_selected: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    minHeight: 'unset',
  },
  targetsTabs_tab1_root: {
    //padding: '0 22px', // 12
    lineHeight: 1,
    marginBottom: 7,
    minHeight: 'unset',
  },
  home_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
    //width: '93px'
  },
  targetsTabs_tabs1_flexContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
});

const tabs = [
  { label: 'resumen', icon: null, filter: 'resumen' },
  { label: 'objetivos', icon: null, filter: 'objetivos' },
  { label: 'reglas', icon: null, filter: 'reglas' },
];

export default withStyles(styles, { withTheme: true })(Home);
