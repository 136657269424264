// Agresivo / Atrevido - 5
export const agresivo = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Liquidez'
    ],
    data: [
      18.00,
      77.15,
      3.85,
      1.00
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Mercados emergentes',
      'Global',
      'Japón',
      'Pacífico',
    ],
    data: [
      37.88,
      31.48,
      12.63,
      10.71,
      3.65,
      3.65
    ],
  },

  fondos: {
    labels: [
      'Vanguard US Government Bond Index Fund H',
      'Vanguard Euro Government Bond Index Fund Institutional Plus',
      'Vanguard Global Short-Term Bond Index Fund',
      'Vanguard U S Investment Grade Credit Index H',
      'Vanguard Eurozone Inflation-Linked Bond Index',
      'Vanguard Global Short-Term Corporate Bond Index Fund H',
      'Vanguard Euro Investment Grade Bond Index Fund',
      'iShares Emerging Markets Government Bond Index H',
      'Vanguard US 500 Stock Index Fund Institutional Plus',
      'Vanguard European Stock Index Fund Institutional Plus',
      'Vanguard Emerging Market Index',
      'Vanguard Global Small-Cap Index Fund',
      'Vanguard Japan Stock Index',
      'Vanguard Pacific ex-Japan Stock Index Fund',
      'Amundi IS FTSE EPRA NAREIT Global IE-C',
      'Liquidez',
    ],
    data: [
      2.98,
      2.97,
      2.89,
      2.50,
      2.42,
      1.95,
      1.81,
      0.48,
      32.40,
      19.43,
      12.15,
      5.87,
      3.65,
      3.65,
      3.85,
      1.00,
    ],
  },
};

// Animado / Decidido - 4
export const animado = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Liquidez'
    ],
    data: [
      31.50,
      64.29,
      3.21,
      1.00
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Japón',
      'Pacífico',
    ],
    data: [
      36.60,
      33.02,
      13.38,
      10.92,
      3.04,
      3.04,
    ],
  },

  fondos: {
    labels: [
      'Vanguard US Government Bond Index Fund H',
      'Vanguard Euro Government Bond Index Fund Institutional Plus',
      'Vanguard Global Short-Term Bond Index Fund',
      'Vanguard U S Investment Grade Credit Index H',
      'Vanguard Eurozone Inflation-Linked Bond Index',
      'Vanguard Global Short-Term Corporate Bond Index Fund H',
      'Vanguard Euro Investment Grade Bond Index Fund',
      'iShares Emerging Markets Government Bond Index H',
      'Vanguard US 500 Stock Index Fund Institutional Plus',
      'Vanguard European Stock Index Fund Institutional Plus',
      'Vanguard Emerging Market Index',
      'Vanguard Global Small-Cap Index Fund',
      'Vanguard Japan Stock Index',
      'Vanguard Pacific ex-Japan Stock Index Fund',
      'Amundi IS FTSE EPRA NAREIT Global IE-C',
      'Liquidez',
    ],
    data: [
      5.22,
      5.21,
      5.07,
      4.38,
      4.23,
      3.42,
      3.17,
      0.80,
      27.00,
      16.20,
      10.12,
      4.89,
      3.04,
      3.04,
      3.21,
      1.00,
    ],
  },
};

// Dinamico / Equilibrado - 3
export const dinamico = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Liquidez',
    ],
    data: [
      46.50,
      50.01,
      2.49,
      1.00,
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Japón',
      'Pacífico',
    ],
    data: [
      35.18,
      34.70,
      16.34,
      9.06,
      2.36,
      2.36,
    ],
  },

  fondos: {
    labels: [
      'Vanguard US Government Bond Index Fund H',
      'Vanguard Euro Government Bond Index Fund Institutional Plus',
      'Vanguard Global Short-Term Bond Index Fund',
      'Vanguard U S Investment Grade Credit Index H',
      'Vanguard Eurozone Inflation-Linked Bond Index',
      'Vanguard Global Short-Term Corporate Bond Index Fund H',
      'Vanguard Euro Investment Grade Bond Index Fund',
      'iShares Emerging Markets Government Bond Index H',
      'Vanguard US 500 Stock Index Fund Institutional Plus',
      'Vanguard European Stock Index Fund Institutional Plus',
      'Vanguard Emerging Market Index',
      'Vanguard Global Small-Cap Index Fund',
      'Vanguard Japan Stock Index',
      'Vanguard Pacific ex-Japan Stock Index Fund',
      'Amundi IS FTSE EPRA NAREIT Global IE-C',
      'Liquidez',
    ],
    data: [
      7.71,
      7.68,
      7.48,
      6.47,
      6.25,
      5.05,
      4.68,
      1.18,
      21.00,
      12.60,
      7.88,
      3.81,
      2.36,
      2.36,
      2.49,
      1.00,
    ],
  },
};

// Moderado / Cauto - 2
export const moderado = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Liquidez',
    ],
    data: [
      60.00,
      37.15,
      1.85,
      1.00,
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Japón',
      'Pacífico',
    ],
    data: [
      36.22,
      33.88,
      19.00,
      7.38,
      1.76,
      1.76,
    ],
  },

  fondos: {
    labels: [
      'Vanguard US Government Bond Index Fund H',
      'Vanguard Euro Government Bond Index Fund Institutional Plus',
      'Vanguard Global Short-Term Bond Index Fund',
      'Vanguard U S Investment Grade Credit Index H',
      'Vanguard Eurozone Inflation-Linked Bond Index',
      'Vanguard Global Short-Term Corporate Bond Index Fund H',
      'Vanguard Euro Investment Grade Bond Index Fund',
      'iShares Emerging Markets Government Bond Index H',
      'Vanguard US 500 Stock Index Fund Institutional Plus',
      'Vanguard European Stock Index Fund Institutional Plus',
      'Vanguard Emerging Market Index',
      'Vanguard Global Small-Cap Index Fund',
      'Vanguard Japan Stock Index',
      'Vanguard Pacific ex-Japan Stock Index Fund',
      'Amundi IS FTSE EPRA NAREIT Global IE-C',
      'Liquidez',
    ],
    data: [
      9.94,
      9.92,
      9.65,
      8.34,
      8.06,
      6.52,
      6.04,
      1.53,
      15.60,
      9.35,
      5.85,
      2.83,
      1.76,
      1.76,
      1.85,
      1.85,
    ],
  },
};

// Conservador - 1
export const conservador = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Liquidez',
    ],
    data: [
      75.00,
      22.86,
      1.14,
      1.00,
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Japón',
      'Pacífico',
    ],
    data: [
      37.93,
      32.46,
      21.95,
      5.50,
      1.08,
      1.08,
    ],
  },

  fondos: {
    labels: [
      'Vanguard US Government Bond Index Fund H',
      'Vanguard Euro Government Bond Index Fund Institutional Plus',
      'Vanguard Global Short-Term Bond Index Fund',
      'Vanguard U S Investment Grade Credit Index H',
      'Vanguard Eurozone Inflation-Linked Bond Index',
      'Vanguard Global Short-Term Corporate Bond Index Fund H',
      'Vanguard Euro Investment Grade Bond Index Fund',
      'iShares Emerging Markets Government Bond Index H',
      'Vanguard US 500 Stock Index Fund Institutional Plus',
      'Vanguard European Stock Index Fund Institutional Plus',
      'Vanguard Emerging Market Index',
      'Vanguard Global Small-Cap Index Fund',
      'Vanguard Japan Stock Index',
      'Vanguard Pacific ex-Japan Stock Index Fund',
      'Amundi IS FTSE EPRA NAREIT Global IE-C',
      'Liquidez',
    ],
    data: [
      12.43,
      12.40,
      12.06,
      10.43,
      10.07,
      8.15,
      7.56,
      1.90,
      9.60,
      5.76,
      3.60,
      1.74,
      1.08,
      1.08,
      1.14,
      1.00,
    ],
  },
};

export const funds = [

  {
    name: 'Vanguard Euro Govt Bd Idx Ins EUR Acc',
    longName: 'Vanguard Euro Government Bond Index Fund Institutional',
    objetivos: 'Replicar el comportamiento del índice de referencia "Barclays Euro Government Float Adjusted Bond Index". Invierte en bonos gubernamentales de la zona euro de alta calificación crediticia ("investment grade") denominados en euros, con vencimientos superiores a un año. La zona euro está compuesta por todos los países de la Unión Europea que han adoptado el euro como divisa propia.',
    gestora: 'Vanguard',
    codigoISIN: 'IE0007472990',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '29/09/2000',
    categoriaInversion: 'Renta Fija Gobiernos Euro',
    tamañoFondo: '5.072 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,20% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'SSgA Euro Corporate Bond Index',
    longName: 'SSgA Euro Corporate Bond Index Fund',
    objetivos: "Replicar el comportamiento del índice de referencia 'Barclays Euro Corporate Bond Index'. Invierte en bonos de alta calificación crediticia (clase 'investment grade') de interés fijo emitidos en euros por corporaciones europeas que operan principalmente en los sectores industriales, de servicios y financiero.",
    gestora: 'State Street Global Advisors',
    codigoISIN: 'LU0438092701',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '21/07/2009',
    categoriaInversion: 'Renta Fija Euro Corporativa',
    tamañoFondo: '2.454 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,20% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'Vanguard Global Bond Index Ins EURH Acc',
    longName: 'Vanguard IS Plc - Global Bond Index - Institutional "Euro Hedged" Shares',
    objetivos: 'Replicar el comportamiento del índice de referencia "BBgBarc Global Agg Float Adj TR Hdg". Invierte en emisiones de renta fija a nivel global tanto de empresas como de gobiernos',
    gestora: 'Vanguard',
    codigoISIN: 'IE00B18GC888',
    divisa: '€ euro',
    claseCubierta: 'Sí',
    reinversion: 'Sí',
    fechaLanzamiento: '27/02/2014',
    categoriaInversion: 'Renta Fija Agregada Global',
    tamañoFondo: '8.304 millones de Euros*',
    gastosCorrientes: '0,15%',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'Vanguard US 500 Stock Index Ins EUR Acc',
    longName: 'Vanguard U.S. 500 Stock Index Fund Institutional',
    objetivos: 'Replicar el comportamiento del índice de referencia "S&P 500 Index". Invierte principalmente en acciones de empresas de Estados Unidos con gran capitalización bursátil.',
    gestora: 'Vanguard',
    codigoISIN: 'IE0032126645',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '26/09/2002',
    categoriaInversion: 'Renta Variable USA',
    tamañoFondo: '5.114 millones de Euros',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,10% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'Vanguard European Stock Idx Ins EUR Acc',
    longName: 'Vanguard European Stock Index Institutional',
    objetivos: 'Replicar el comportamiento del índice de referencia "MSCI Europe NR USD". Invierte en empresas de mediana y gran capitalización bursátil que están localizadas en Austria, Bélgica, Dinamarca, Finlandia, Francia, Alemania, Grecia, Irlanda, Italia, Países Bajos, Noruega, Portugal, España, Suecia, Suiza y Reino Unido.',
    gestora: 'Vanguard',
    codigoISIN: 'IE0007987708',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '08/06/2000',
    categoriaInversion: 'Renta Variable Europea',
    tamañoFondo: '3.941 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,30% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'Vanguard Japan Stock Index Ins EUR Acc',
    longName: 'Vanguard Japan Stock Index Fund Institutional',
    objetivos: 'Replicar el comportamiento del índice de referencia “MSCI Japan Index”. Invierte en empresas de Japón de mediana y gran capitalización bursátil.',
    gestora: 'Vanguard',
    codigoISIN: 'IE0007286036',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '29/03/2006',
    categoriaInversion: 'Renta Variable Japonesa',
    tamañoFondo: '3.081 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,23% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'Vanguard Emerg Mkts Stk Idx Ins EUR Acc',
    longName: 'Vanguard Emerging Markets Stock Index Fund Institutional',
    objetivos: 'Replicar el comportamiento del índice de referencia "MSCI Emerging Markets NR USD". Invierte en empresas de mediana y gran capitalización bursátil de países emergentes.',
    gestora: 'Vanguard',
    codigoISIN: 'IE0031786696',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '27/02/2014',
    categoriaInversion: 'Renta Variable Emergente',
    tamañoFondo: '8.655 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,27% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'BlackRock ISF - iShares Dev. Real Estate Index Fund (IE) - Institutional',
    longName: 'BlackRock ISF - iShares Dev. Real Estate Index Fund (IE)',
    objetivos: 'Replicar el comportamiento del índice de referencia "FTSE EPRA/NAREIT Developed NR EUR". Invierte en acciones de empresas inmobiliarias cotizadas de países emergentes y desarrollados.',
    gestora: 'BlackRock',
    codigoISIN: 'IE00B83YJG36',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '13/02/2013',
    categoriaInversion: 'Bienes Raíces Globales',
    tamañoFondo: '1.619 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,22% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'iShares Gold Trust $',
    longName: 'iShares Gold Trust',
    objetivos: 'El objetivo de este ETF es ofrecer exposición al precio "spot" del lingote de oro, replicando el comportamiento del índice de referencia "LBMA Gold Price PM USD". Los "P-ETC" son certificados que están respaldados por lingotes de oro, y están guardados en las cámaras acorazadas del banco JP Morgan Chase en Londres, Nueva York y Toronto.',
    gestora: 'BlackRock',
    codigoISIN: 'US4642851053',
    codigoTicker: 'IAU US Equity',
    divisa: '$ usd',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '21/01/2005',
    categoriaInversion: 'Oro Físico / Commodities',
    tamañoFondo: '9.467 millones de Euros*',
    gastosCorrientes: '0,25% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Abril de 2018',
  },
  {
    name: 'iShares Global High Yield Corporate Bond ETF USD',
    longName: 'iShares Global High Yield Corporate Bond ETF USD',
    gestora: 'BlackRock',
    codigoISIN: 'US4642861789',
    codigoTicker: 'GHYG US',
    divisa: '$ usd',
    claseCubierta: 'No',
    reinversion: 'No',
    fechaLanzamiento: '03/04/2012',
    categoriaInversion: 'Renta Fija Global Bonos Corporativos Alto Rendimiento',
    tamañoFondo: '191 millones de Euros*',
    gastosCorrientes: '0,40% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Septiembre de 2018',
  },
  {
    name: 'Lyxor Barclays Float Rate EUR 0-7Y ETF C-EUR',
    longName: 'Lyxor Barclays Float Rate EUR 0-7Y ETF C-EUR',
    objetivos: 'Replicar el comportamiento del índice de referencia "BBgBarc Eur Corp FRN 0-7Y EnhDur TR EUR".',
    gestora: 'Lyxor',
    codigoISIN: 'FR0012386696',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '07/01/2015',
    categoriaInversion: 'Renta Fija Euro Corporativa',
    tamañoFondo: '1.235 millones de Euros*',
    gastosCorrientes: '0,14% anual',
    notas: '*Tamaño del instrumento financiero calculado a cierre de Septiembre de 2018',
  },
];