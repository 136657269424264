import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import MyText from '../../components/MyText';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import Header from '../../components/MyHeader';
import Button from '@material-ui/core/Button';

const PromoChequeAmazon = props => {
  global.localStorage.setItem('promoChequeAmazonShowed', 'true');
  console.error('promoCheque');

  return (
    <Fragment>
      <Header
        rigthAction={() => {
          props.history.push('/home');
        }}
      ></Header>
      <Body vAlign='center'>
        <div style={{ maxWidth: 300, width: '90%' }}>
          <img
            alt='amazon cheques'
            src='/images/amazon-cheques.png'
            style={{ width: '80%', marginTop: -65 }}
          />
          <div>
            <MyText
              style={{
                fontWeight: 100,
                fontSize: 35,
                lineHeight: '45px',
                textAlign: 'center',
                // color: textColor,
                paddingTop: 7,
                marginTop: 15,
                height: 50,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontFamily: 'Calibre',
              }}
            >
              ¿Quieres un Cheque
            </MyText>
            <MyText
              style={{
                fontWeight: 100,
                fontSize: 35,
                lineHeight: '45px',
                textAlign: 'center',
                // color: textColor,
                paddingTop: 0,
                height: 50,
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                // whiteSpace: 'nowrap',
                fontFamily: 'Calibre',
              }}
            >
              Regalo en
              <img
                alt='amazon logo'
                src='/images/amazon-logo.png'
                style={{
                  width: 137,
                  position: 'relative',
                  top: 14,
                  left: 6,
                  marginRight: 10,
                }}
              />
              ?
            </MyText>
          </div>
          {/* <MyText
            style={{
              marginTop: 40,
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Crea tu hucha y obten un cheque de 10 € de Amazon.
          </MyText> */}
          <MyText
            style={{
              marginTop: 45,
            }}
          >
            Ahora con la Hucha Inteligente EVO si creas tu primer reto en la
            categoría Hucha, ese cheque será tuyo. Promoción válida hasta el
            31/10/2021{' '}
            <a
              href='https://www.evobanco.com/servicios-digitales/smartphone-banking/hucha-inteligente/ 
'
            >
              +info
            </a>
          </MyText>
        </div>
      </Body>
      <Footer>
        {/*classes.targetBasic_button1_container*/}
        <Button
          variant='contained'
          color='secondary'
          onClick={() => props.history.push('/create/target/familyTarget')}
        >
          ¡VAMOS!
        </Button>
      </Footer>
    </Fragment>
  );
};

// class PromoChequeAmazon extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       index: 0,
//       checked: false,
//     };
//   }
//   render() {
//     const { classes } = this.props;
//     const transformClasses = Utils.transformClasses;
//     return (
//       <Fragment>
//         <Body vAlign='center'>Hola</Body>

//         <Footer areButtons={false}>
//           {this.state.index !== 2 && (
//             <MobileStepper
//               activeStep={this.state.index}
//               steps={3}
//               variant='dots'
//               classes={transformClasses('tour_stepper', classes)}
//             />
//           )}
//           {this.state.index === 2 && (
//             <div className='footerButtons'>
//               <Button
//                 className={this.state.index !== 2 ? classes.tour_hidden : ''}
//                 variant='contained'
//                 color='secondary'
//                 onClick={this.nextIndex.bind(this)}
//                 //classes={transformClasses('tour_button1', classes)}
//               >
//                 {this.state.index !== 2 ? 'Empezar' : '¿Empezamos?'}
//               </Button>
//             </div>
//           )}
//         </Footer>
//       </Fragment>
//     );
//   }
// }

const styles = theme => ({
  container: {
    minHeight: 640,
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexFlow: 'column',
    alignContent: 'center',
    height: '100%',
  },
});

export default withStyles(styles, { withTheme: true })(PromoChequeAmazon);
