import React, { Component } from 'react';
//import { RulesAPI, TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';
import { RulesAPI } from '../../src-core/sdk/index';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import {
//   RuleInput,
//   RuleInputConfig,
//   RuleInputStyles,
// } from '../../core/;
import { RuleInput } from '../../src-core/rules/index';
//components-core/rules/react/ruleInput/ruleInput.component';
import { RuleInputConfig } from '../../src-core/rules/index';
//'../../components-core/rules/react/ruleInput/ruleInput.config';
import { RuleInputStyles } from '../../src-core/rules/index';
//'../../components-core/rules/react/ruleInput/ruleInput.style';

import { withStyles } from '@material-ui/core/styles';

class CreateRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      definition: null,
      type: null,
    };
  }

  componentDidMount() {
    TargetsAPI.getTargets(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          type: data.type,
        });
      })
      .catch(error => {
        console.log('error recuperando el target ', error);
      });
  }

  componentDidUpdate() {
    if (this.state.type !== null && this.state.definition === null) {
      RulesAPI.getRuleDefinitionForTargetType(
        this.props.baseUrl,
        this.state.type,
        this.props.match.params.idRule,
        this.props.token,
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.status;
          }
        })
        .then(data => {
          this.setState({
            definition: data,
          });
        })
        .catch(error => {
          console.log(
            'error recuperando la configuración de regla para el tipo de target ',
            error,
          );
        });
    }
  }

  goBack() {
    if (this.props.location.state !== undefined) {
      this.props.history.push(this.props.location.state.return);
    } else {
      this.props.history.push('/home/resumen');
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.header_container}>
          <IconButton color='primary' onClick={() => this.goBack()}>
            <KeyboardArrowLeft />
          </IconButton>
        </div>
        <div className={classes.body_container}>
          {console.log('las props ' + this.props)}
          {this.state.definition !== null ? (
            <RuleInput
              classes={this.props.classes}
              baseUrl={this.props.baseUrl}
              token={this.props.token}
              targetID={this.props.match.params.id}
              rule={'no_rule'}
              definition={this.state.definition}
              config={RuleInputConfig}
              /* TODO: Hay q quitar toda lo de Lotie de aqui */
              /*renderAnimations={this.returnAnimations.bind(this)}*/
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  ...RuleInputStyles(theme),
  container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
  },
  body_container: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
  },
  dataContainer: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
  ruleInput_typography2_root: {
    textAlign: 'center',
  },
});

export default withStyles(styles, { withTheme: true })(CreateRule);
