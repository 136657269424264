import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';

import MyIconConfig from './MyIcon.config';

const MyIcon = props => {
  //console.log('MyIcon', props, MyIconConfig[props.name]);
  if (
    !MyIconConfig[props.name] ||
    !MyIconConfig[props.name].data ||
    !MyIconConfig[props.name].viewBox
  ) {
    console.error(
      'MyIcon',
      'No se ha configurado el icono en MyIcon.config (data y viewBox). name:' +
        props.name,
      {
        name: MyIconConfig[props.name],
        data: MyIconConfig[props.name].data,
        vieBox: MyIconConfig[props.name].viewBox,
      },
    );
  }

  function LocalIcon() {
    let styleParent = props.title ? null : props.style;

    return (
      <SvgIcon
        id={props.id}
        color='primary'
        viewBox={MyIconConfig[props.name].viewBox}
        style={{
          width: '100%',
          height: '100%',
          ...styleParent,
        }}
        onClick={props.onClick ? () => props.onClick() : null}
      >
        <path d={MyIconConfig[props.name].data} />
        {MyIconConfig[props.name].data2 && (
          <path d={MyIconConfig[props.name].data2} />
        )}
      </SvgIcon>
    );
  }

  return (
    <React.Fragment>
      {props.title ? (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            ...props.style,
          }}
        >
          <LocalIcon />
          <Typography
            color='primary'
            style={
              {
                //marginTop: -12
              }
            }
          >
            {props.title}
          </Typography>
        </div>
      ) : (
        <LocalIcon />
      )}
    </React.Fragment>
  );
};

MyIcon.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default MyIcon;
