import React, { useContext, useEffect } from 'react';
import { View, Text, Button, Enfatize } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import routes from '../../../../config/routes';
import colors from '../../../../config/colors';
import Check from '../../../common/svgs/Check';

import { StoreContext } from '../../../../pensionStore/Store';
import navConfig from '../../config/navigation';

export default props => {
  const context = useContext(StoreContext);
  const { state } = context;

  useEffect(() => {
    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
  }, []);
  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt>
        <View width85St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Check style={{ color: colors.iconPurple }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              ¡Enhorabuena!
            </Text>
            <Text centerSt font16St>
              Según nuestros cálculos{' '}
              <Enfatize font16St>podras jubilarte</Enfatize> a los{' '}
              {state.retirementAge} años, en el{' '}
              <Enfatize>año {state.simulation.year}</Enfatize>.
            </Text>
            <Text centerSt style={{ marginTop: 12 }} font16St>
              Ya queda menos para disfrutar de tu tiempo libre.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() =>
              props.history.push(routes.retirementSimulationPensionInfo)
            }
          >
            CONTINUAR
          </Button>
        </View>
      </Footer>
    </>
  );
};
