import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Button, TextField } from 'coinscrap-webapp-core';
import { calculateComplementaryAmountUsingPeriodicContributions } from '@coinscrap/retirement-calculations';

import Header from '../../../components/MyHeader';
import Footer from '../../../components/MyFooter';
import Flow from '../../../components/Flow';
import routes from '../../../config/routes';

import CajaFuerte from '../../common/svgs/CajaFuerte';
import Lapiz from '../../common/svgs/Lapiz';
import {
  onChangeAmountHandler,
  amountStringToNumber,
} from '../../../libs/currencyInput';

import colors from '../../../config/colors';

import { StoreContext } from '../../../pensionStore/Store';
import * as Types from '../../../pensionStore/types';
import { useSelector } from 'react-redux';
import { TargetsAPI } from '../../../src-core/sdk/index';
import navConfig from '../config/navigation';

export default props => {
  const [age, setAge] = useState(0);
  const [objetivoAnual, setObjetivoAnual] = useState(0);
  const [objetivoMensual, setObjetivoMensual] = useState(0);
  const [aportacionInicial, setAportacionInicial] = useState(30);
  const [total, setTotal] = useState(0);

  const context = useContext(StoreContext);
  const { state, dispatch } = context;
  const store = useSelector(state => state.main);
  const { manual, pensionPlan } = state;
  console.log({ pensionPlan });
  const { prodsForUser } = store;
  const formatNumber = value => {
    return Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
    }).format(value);
  };

  const flowParams = Flow.getParams();

  useEffect(() => {
    setObjetivoAnual(formatNumber(state.manual.yearlyContribution || 0));
    setObjetivoMensual(formatNumber(state.manual.monthlyContribution || 0));
    setAportacionInicial(formatNumber(state.manual.savings || 30));
    setAge(state.manual.numberOfYears || 0);
  }, []);

  useEffect(() => {
    let findProduct;
    for (const property in prodsForUser) {
      if (prodsForUser[property].name === manual.profileInvestor) {
        findProduct = prodsForUser[property];
      }
    }

    const aportacionMensual = amountStringToNumber(objetivoMensual);
    const initialAportacion = amountStringToNumber(aportacionInicial);
    const profitability = findProduct ? 0.03 : 0;
    console.log(aportacionInicial, age, profitability, aportacionMensual);
    const total = calculateComplementaryAmountUsingPeriodicContributions(
      initialAportacion,
      age,
      profitability,
      aportacionMensual,
    );

    setTotal(formatNumber(total.toFixed(2)));
  }, [objetivoMensual, aportacionInicial, age]);

  useEffect(() => {
    if (!state.manual.started) {
      props.history.push(navConfig.notStarted);
    }

    const flowParams = Flow.getParams();

    if (flowParams.productName) {
      dispatch({
        type: Types.NEW_PLAN_MANUAL,
        payload: {
          profileInvestor: flowParams.productName,
        },
      });
    }
  }, []);

  const saveObjective = async () => {
    let data;

    flowParams.flujo === 'transfer'
      ? (data = {
          // initialContribution: 50,
          retirementAge: 67,
          transferPensionPlan: pensionPlan.id,
        })
      : (data = {
          // initialContribution: amountStringToNumber(aportacionInicial),
          retirementAge: 67,
        });

    try {
      await TargetsAPI.setTargetConfiguration(
        store.baseUrl,
        flowParams.temporaryObjectiveId,
        store.token,
        data,
      );

      props.history.push(routes.retirementSimulationGenial);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: Types.NEW_PLAN_MANUAL,
      payload: {
        yearlyContribution: amountStringToNumber(objetivoAnual),
        monthlyContribution: amountStringToNumber(objetivoMensual),
        savings: amountStringToNumber(aportacionInicial),
        numberOfYears: age,
        total: amountStringToNumber(total),
      },
    });
  }, [objetivoAnual, objetivoMensual, aportacionInicial, age, total]);
  return (
    <>
      <Header
        title='Añadir objetivo'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt style={{ marginBottom: 135, marginTop: 25 }}>
        <View width85St>
          <View>
            <Text headlineSt centerSt headlineSpaceSt>
              Define tu objetivo
            </Text>
            <Text
              font16St
              centerSt
              style={{ lineHeight: 1.1, marginBottom: 10 }}
            >
              A continuación puedes configurar tu Plan de Pensiones de ahorro
              periódico.
            </Text>
            <Text
              font16St
              centerSt
              style={{ lineHeight: 1.1, marginBottom: 10 }}
            >
              Establece la cantidad anual o mensual que habías pensado, y el
              número de años durante los cuales quieres hacer la aportación.
            </Text>
          </View>

          <View summaryCardSt style={{ padding: '20px 20px', height: 'auto' }}>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                marginBottom: 15,
              }}
            >
              <Text font16St boldSt>
                PLAN DE APORTACIONES PERIÓDICAS
              </Text>
              <CajaFuerte style={{ color: 'white', width: 24, height: 24 }} />
            </View>

            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: '1px solid rgba(255,255,255,0.1)',
              }}
            >
              <View autoWidthSt topSt>
                <Text font16St weight500St style={{ lineHeight: 1 }}>
                  Aportación inicial
                </Text>
                <Text font16St style={{ lineHeight: 1 }}>
                  Min 30 €
                </Text>
              </View>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={aportacionInicial}
                  onChange={e => {
                    const value = e.target.value;
                    onChangeAmountHandler(value, setAportacionInicial);
                  }}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                    inputComponent: {
                      color:
                        parseInt(aportacionInicial) <= 30
                          ? 'rgba(255,255,255,.5)'
                          : colors.white,
                    },
                  }}
                />
                <Text
                  style={{
                    marginLeft: 1,
                    marginRight: 1,
                    color:
                      parseInt(aportacionInicial) <= 30
                        ? 'rgba(255,255,255,.5)'
                        : colors.white,
                  }}
                >
                  €
                </Text>
              </View>
            </View>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: '2px solid rgba(255,255,255,0.1)',
              }}
            >
              <Text font16St>Objetivo anual</Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={objetivoAnual}
                  onChange={e => {
                    const value = e.target.value;
                    onChangeAmountHandler(value, setObjetivoAnual);

                    if (isNaN(+value)) {
                      return;
                    }
                    const valueNumber = amountStringToNumber(value);

                    let yearValue = +valueNumber / 12;
                    yearValue = formatNumber(yearValue.toFixed(2));
                    setObjetivoMensual(yearValue || 0);
                  }}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                    inputComponent: {
                      color:
                        objetivoAnual.toString() === '0'
                          ? 'rgba(255,255,255,.5)'
                          : colors.white,
                    },
                  }}
                />
                <Text
                  style={{
                    marginLeft: 1,
                    marginRight: 1,
                    color:
                      objetivoAnual.toString() === '0'
                        ? 'rgba(255,255,255,.5)'
                        : colors.white,
                  }}
                >
                  €
                </Text>
              </View>
            </View>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: '2px solid rgba(255,255,255,0.1)',
              }}
            >
              <Text font16St>Objetivo mensual</Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={objetivoMensual}
                  onChange={e => {
                    const value = e.target.value;
                    onChangeAmountHandler(value, setObjetivoMensual);

                    if (isNaN(+value)) {
                      return;
                    }
                    const valueNumber = amountStringToNumber(value);
                    const yearValue = +valueNumber * 12;
                    if (yearValue === 0) {
                      yearValue = '0';
                    }
                    onChangeAmountHandler(yearValue, setObjetivoAnual);
                  }}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                    inputComponent: {
                      color:
                        objetivoMensual.toString() === '0'
                          ? 'rgba(255,255,255,.5)'
                          : colors.white,
                    },
                  }}
                />
                <Text
                  style={{
                    marginLeft: 1,
                    marginRight: 1,
                    color:
                      objetivoMensual.toString() === '0'
                        ? 'rgba(255,255,255,.5)'
                        : colors.white,
                  }}
                >
                  €
                </Text>
              </View>
            </View>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: `2px solid ${colors.pink}`,
              }}
            >
              <Text font16St>Número de años</Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={age}
                  onChange={e => {
                    const integer =
                      parseInt(e.target.value) === NaN ||
                      !parseInt(e.target.value)
                        ? 0
                        : parseInt(e.target.value);

                    setAge(integer);
                  }}
                  smallInputSt
                  style={{
                    containerComponent: {
                      backgroundColor: 'transparent',
                      padding: 0,
                      margin: 0,
                      width: '45%',
                      paddingTop: 5,
                    },
                    inputComponent: {
                      color:
                        age.toString() === '0'
                          ? 'rgba(255,255,255,.5)'
                          : colors.white,
                    },
                  }}
                />
                <Text
                  style={{
                    marginLeft: 1,
                    marginRight: 1,
                    color:
                      age.toString() === '0'
                        ? 'rgba(255,255,255,.5)'
                        : colors.white,
                  }}
                >
                  años
                </Text>
              </View>
            </View>

            <View flexRowSt spaceBetweenSt style={{ marginTop: 5 }}>
              <Text weight500St font16St style={{ width: 120 }}>
                Ahorro total a conseguir
              </Text>
              <Text
                font24St
                style={{
                  color:
                    total.toString() === '0'
                      ? 'rgba(255,255,255,.5)'
                      : colors.white,
                }}
              >
                {total} €
              </Text>
            </View>
          </View>
        </View>
        <div style={{ height: 80 }} />
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            onClick={() => {
              saveObjective();
              // dispatch({
              //   type: Types.NEW_PLAN_MANUAL,
              //   payload: {
              //     total: amountStringToNumber(total),
              //     savings: amountStringToNumber(aportacionInicial),
              //     yearlyContribution: amountStringToNumber(objetivoAnual),
              //     monthlyContribution: amountStringToNumber(objetivoMensual),
              //     numberOfYears: age,
              //   },
              // });
            }}
          >
            CONTINUAR
            {/* ¡VAMOS AllÁ! */}
          </Button>
        </View>
      </Footer>
    </>
  );
};
