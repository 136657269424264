import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

//import { UsersAPI } from '../components-core/sdk';
import { UsersAPI } from '../../src-core/sdk/index';
import { BanksAPI } from '../../src-core/sdk/index';

import { Utils } from '../../src-core/resources/index';

import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import MyText from '../../components/MyText';
import Flow from '../../components/Flow';
import DocView from '../../components/MyDocView';
import Icons from '../../components/MyIcon.config';

import { useSelector } from 'react-redux';

const config = require('../../config/app.js');

const SurveyComplete = props => {
  const [riskFactor, setRiskFactor] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [dataFounds, setdataFounds] = useState(null);
  // const [buttonDisabled, setButtonDisabled] = useState(true);

  const [readInfoAdecuacion, setReadInfoAdecuacion] = useState(false);
  const [readResultadoTest, setReadResultadoTest] = useState(false);
  const [alertReadInfoMediador, setAlertReadInfoMediador] = useState(false);
  const [checkConditionsAccpeted, setCheckConditionsAccpeted] = useState(false);
  const state = useSelector(state => state.main);

  useEffect(() => {
    getTypeProducts();
    // const userId = global.myApp.user._id;
    // apiCallWithoutSecurity('GET', 'users/' + userId + '/iddDocument');
  }, []);

  // constructor(props) {
  //   super(props);
  //   state = {
  //     riskFactor: null,
  //   };
  //   console.log('SurveyComplete', state, props);
  // }

  const getTypeProducts = () => {
    console.log(state);
    const { baseUrl, token } = state;
    // TODO: philip asegurate que 'props.location.state.type' tiene el valor correcto
    console.log(props, 'SURVEY COMPLETE');
    BanksAPI.getTypeProducts(baseUrl, 'me', props.location.state.type, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        let founds = {};
        data.map(product => {
          product.founds.map(found => {
            founds[found.code + product.identifier] = {
              ...found,
              product: product.identifier,
            };
            return null;
          });
          return null;
        });
        console.log('founds: ', founds);
        setdataFounds(founds);
      })
      .catch(error => {
        console.error(
          'CreateTarget: error recuperando la lista de productos',
          error,
        );
      });
  };

  console.log(dataFounds);
  useEffect(() => {
    console.log('>', config.apiUrl, props.token);
    UsersAPI.getRiskFactor(config.apiUrl, 'me', props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log(data);
        console.log('riskfactor: ', data);
        // setState({
        //   riskFactor: data,
        // });
        setRiskFactor(data);

        // Obtiene el titulo
        // console.log('Bucle1', { props: props, data });
        console.log(dataFounds);
        const founds =
          (props.location.state && props.location.state.founds) || dataFounds;
        console.log(founds);
        Object.keys(founds).forEach(key => {
          console.log(key, 'key');
          console.log('Bucle', founds[key], data.riskFactor);
          if (founds[key].riskFactor === data.riskFactor) {
            // setState({
            //   profileName: founds[key].name,
            // });
            setProfileName(founds[key].name);
          }
          return null;
        });
      })
      .catch(error => {
        console.log('error recuperando el riskFactor ', error);
      });
  }, [dataFounds]);

  console.log(profileName);

  function goToSummary() {
    if (props.location.state === undefined) {
      props.history.push('/home/summary');
    } else {
      props.history.push(
        '/create/target/' + props.location.state.type + '/products',
        //'/create/target/' + props.location.state.type,
      );
    }
  }

  function repeatSurvey() {
    if (props.location.state === undefined) {
      props.history.push('/survey/answer');
    } else {
      props.history.push({
        pathname: '/survey/answer',
        state: {
          type: props.location.state.type,
          founds: props.location.state.founds,
        },
      });
    }
  }

  const { classes } = props;
  const transformClasses = Utils.transformClasses;

  const flowParams = Flow.getParams();

  console.log(profileName);

  return riskFactor !== null ? (
    <>
      <Header
        title='Perfil de Inversor'
        rigthAction={() => props.history.push('/home/objetivos/familyTarget')}
      />
      <Body style={{ flexFlow: 'none' }}>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('surveyComplete_typography1', classes)}
        >
          En base a tus respuestas este es el resultado de tu perfil inversor:
        </Typography>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('surveyComplete_typography3', classes)}
        >
          {riskFactor.riskFactor + '/' + riskFactor.maxRiskFactor}
        </Typography>
        <MyText>Nombre del perfil</MyText>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('surveyComplete_typography4', classes)}
        >
          {profileName}
        </Typography>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('surveyComplete_typography2', classes)}
        >
          {riskFactor.disclaimer}
        </Typography>
        <div
          className={classes.surveyComplete_cardContainer}
          onClick={() => repeatSurvey()}
        >
          <SvgIcon
            color='secondary'
            classes={transformClasses('surveyComplete_icon', classes)}
          >
            <path d='M15.21,16.5l.64.65a.48.48,0,0,1,0,.7.48.48,0,0,1-.7,0l-.65-.64-.65.64a.48.48,0,0,1-.7,0,.48.48,0,0,1,0-.7l.64-.65-.64-.65a.49.49,0,0,1,.7-.7l.65.64.65-.64a.49.49,0,0,1,.7.7Zm1.14-4.85a.48.48,0,0,1,0,.7l-1.5,1.5a.48.48,0,0,1-.7,0l-1-1a.49.49,0,0,1,.7-.7l.65.64,1.15-1.14A.48.48,0,0,1,16.35,11.65Zm0-3a.48.48,0,0,1,0,.7l-1.5,1.5a.48.48,0,0,1-.7,0l-1-1a.49.49,0,0,1,.7-.7l.65.64,1.15-1.14A.48.48,0,0,1,16.35,8.65ZM11,16a.5.5,0,0,1,0,1H9a.5.5,0,0,1,0-1Zm0-3.5a.5.5,0,0,1,0,1H9a.5.5,0,0,1,0-1ZM11,9a.5.5,0,0,1,0,1H9A.5.5,0,0,1,9,9Zm7,9.5h0V6.5a.5.5,0,0,0-.5-.5H16v.5a.5.5,0,0,1-.5.5h-6A.5.5,0,0,1,9,6.5V6H7.5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h10A.5.5,0,0,0,18,18.5Zm-8-13V6h5V5.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5.5.5,0,0,0-1,0,.5.5,0,0,1-.5.5h-1A.5.5,0,0,0,10,5.5ZM17.5,5A1.5,1.5,0,0,1,19,6.5v12A1.5,1.5,0,0,1,17.5,20H7.5A1.5,1.5,0,0,1,6,18.5V6.5A1.5,1.5,0,0,1,7.5,5H9.09A1.5,1.5,0,0,1,10.5,4h.59a1.49,1.49,0,0,1,2.82,0h.59a1.5,1.5,0,0,1,1.41,1Z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'surveyComplete_card_typography',
              classes,
            )}
          >
            Repetir Test
          </Typography>
        </div>
        <div
          style={{
            textDecoration: 'none',
            color: 'white',
            marginTop: 25,
            width: '100%',
            alignItems: 'center',
          }}
          onClick={() => {
            setModalTitle('Declaración de Adecuación');
            setModalUrl(
              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/adecuacion.pdf',
            );

            setOpenModal(true);
            setReadInfoAdecuacion(true);
            setAlertReadInfoMediador(false);
          }}
        >
          <Checkbox
            checked={readInfoAdecuacion}
            // disabled={!readInfoMediador}
            // onChange={(e, value) => {
            //   // setButtonDisabled(!value);
            //   setCheckConditionsAccpeted(!checkConditionsAccpeted);
            // }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',

              paddingTop: 0,
              paddingBottom: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
            id='checkTerminos'
          />
          <Typography
            className='textH1'
            style={{ textDecoration: 'underline' }}
          >
            Declaración de Adecuación
          </Typography>
        </div>
        <div
          style={{
            textDecoration: 'none',
            color: 'white',
            marginTop: 25,
            width: '100%',
            alignItems: 'center',
          }}
          onClick={() => {
            setModalTitle('Resultado test');
            const userId = window.localStorage.getItem('userId');
            console.log({
              doc: config.apiUrl + 'users/' + userId + '/iddDocument',
            });
            setModalUrl(config.apiUrl + 'users/' + userId + '/iddDocument');
            setReadResultadoTest(true);
            setOpenModal(true);
          }}
        >
          <Checkbox
            checked={readResultadoTest}
            // disabled={!readInfoMediador}
            // onChange={(e, value) => {
            //   // setButtonDisabled(!value);
            //   setCheckConditionsAccpeted(!checkConditionsAccpeted);
            // }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',

              paddingTop: 0,
              paddingBottom: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
            id='checkTerminos'
          />
          <Typography
            className='textH1'
            style={{ textDecoration: 'underline' }}
          >
            Resultado Test
          </Typography>
        </div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 50,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Checkbox
            checked={checkConditionsAccpeted}
            disabled={!readInfoAdecuacion && !readResultadoTest}
            onChange={(e, value) => {
              // setButtonDisabled(!value);
              setCheckConditionsAccpeted(!checkConditionsAccpeted);
            }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              paddingTop: 5,
            }}
            id='checkTerminos'
          />
          <Typography
            className='textNormal'
            onClick={value => {
              setAlertReadInfoMediador(true);
              if (readInfoAdecuacion) {
                setAlertReadInfoMediador(false);
                setCheckConditionsAccpeted(!checkConditionsAccpeted);
              }
            }}
          >
            He leído y acepto los términos y condiciones.
          </Typography>
        </div>
        <Typography style={{ color: '#c60', marginLeft: '30px' }}>
          {alertReadInfoMediador
            ? 'Debes leer antes la información del mediador'
            : ''}
        </Typography>
      </Body>
      <Footer>
        <Button
          // disabled={buttonDisabled}
          disabled={!checkConditionsAccpeted}
          variant='contained'
          color='secondary'
          classes={transformClasses('surveyComplete_button1', classes)}
          onClick={() =>
            flowParams.mode && flowParams.mode === 'retirementSimulation'
              ? Flow.navigate(
                  '/create/target/familyTarget/Edit',
                  props,
                  { profileName: profileName },
                )
              : goToSummary()
          }
        >
          Ok
        </Button>
      </Footer>
      {openModal && (
        <DocView
          open={openModal}
          title={modalTitle}
          onClose={() => setOpenModal(false)}
          url={modalUrl}
          styleType='black'
          leftIcon={Icons.disquete}
          disk
        />
      )}
    </>
  ) : (
    <div />
  );
};

const styles = theme => ({
  container: {
    height: '100vh',
    minHeight: '640px',
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '0 24px',
  },
  buttonContainer: {
    marginTop: 'auto',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 24px',
  },
  surveyComplete_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    //marginTop: '104px',
    maxWidth: 250,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  surveyComplete_typography2_root: {
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '18px',
    marginBottom: '50px',
  },
  surveyComplete_typography3_root: {
    marginTop: '50px',
    marginBottom: 30,
    fontSize: 40,

    borderBottom: 'solid 1px rgba(255,255,255,0.098)',
    fontWeight: 100,
    width: 'fit-content',
  },
  surveyComplete_typography4_root: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    letterSpacing: 0,
    lineHeight: '45px',
    marginTop: '8px',
  },
  surveyComplete_button1_root: {
    width: '100%',
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  surveyComplete_button1_label: {
    fontWeight: 600,
  },
  surveyComplete_icon_root: {
    height: '46px',
    width: '46px',
  },
  surveyComplete_cardContainer: {
    width: '152px',
    height: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.098)',
  },
  surveyComplete_card_typography_root: {
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
});

export default withStyles(styles, { withTheme: true })(SurveyComplete);
