import React, { Component } from 'react';

import { Utils } from '../../../resources/index';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { RulesAPI } from '../../../sdk/index';

class RuleInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: props.rule,
      definition: props.definition,
      autocomplete: null,
      showAutocomplete: false,
      fieldToAutocomplete: null,
      data: null,
      targetID: null,
    };
    this.setRule = this.setRule.bind(this);
  }

  componentDidMount() {
    if (this.state.rule === 'no_rule') {
      const data = {
        ruleDefinition: this.state.definition._id,
      };
      if (this.state.definition.type === 'TransactionsRuleDefinition') {
        data['value'] = {
          transactionsAmountReductor: {
            ...this.props.definition['transactionsAmountReductor'],
          },
        };
        data.value.transactionsAmountReductor[
          data.value.transactionsAmountReductor.type.toLowerCase()
        ] = data.value.transactionsAmountReductor.min;
        delete data.value.transactionsAmountReductor.min;
        delete data.value.transactionsAmountReductor.max;
      } else {
        data['value'] = {
          fieldsValues: this.props.definition['fields'].map(e => ({ ...e })),
        };
        data.value.fieldsValues.map((field, index) => {
          data.value.fieldsValues[index]['value'] =
            data.value.fieldsValues[index].default;
          delete data.value.fieldsValues[index]._id;
          delete data.value.fieldsValues[index].type;
          delete data.value.fieldsValues[index].unit;
          delete data.value.fieldsValues[index].default;
          delete data.value.fieldsValues[index].min;
          delete data.value.fieldsValues[index].required;
          delete data.value.fieldsValues[index].title;
          delete data.value.fieldsValues[index].options;
          delete data.value.fieldsValues[index].hide;
          delete data.value.fieldsValues[index].hasAutocomplete;
          return null;
        });
      }
      this.setState({
        rule: data,
        targetID: this.props.targetID,
      });
    } else {
      this.setState({
        targetID: this.props.rule.target,
      });
    }
  }

  handleChangeAutocomplete(event, id, index, field) {
    const rule = this.state.rule;
    RulesAPI.getAutocomplete(
      this.props.baseUrl,
      id,
      field,
      event.target.value,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          autocomplete: data,
        });
      })
      .catch(error => {
        console.log('error recuperando los campos autocomplete ', error);
      });
    rule.value.fieldsValues[index].value = event.target.value;
    if (event.target.value !== '') {
      this.setState({
        rule: rule,
        fieldToAutocomplete: { idField: id, indexField: index },
        showAutocomplete: true,
      });
    } else {
      this.setState({
        rules: rule,
        fieldToAutocomplete: null,
        showAutocomplete: false,
      });
    }
  }

  handleChangeTransactionsAmountReductor(event, ruleDefinition) {
    const rule = this.state.rule;
    rule.value.transactionsAmountReductor[
      ruleDefinition.transactionsAmountReductor.type.toLowerCase()
    ] = event.target.value;
    this.setState({
      rules: rule,
    });
  }

  handleChangeFields(event, index) {
    const rule = this.state.rule;
    rule.value.fieldsValues[index].value = event.target.value;
    this.setState({
      rules: rule,
    });
  }

  handleClikcAutomcomplete(value) {
    const rule = this.state.rule;
    rule.value.fieldsValues[
      this.state.fieldToAutocomplete.indexField
    ].value = value;
    this.setState({
      rules: rule,
      showAutocomplete: false,
    });
  }

  setRule() {
    let data;
    if (this.props.rule === 'no_rule') {
      data = this.state.rule;
    } else {
      data = {
        ruleDefinition: this.state.rule.ruleDefinition,
      };
      if (this.state.rule.value.type === 'TransactionsRuleValue') {
        data['value'] = {
          transactionsAmountReductor: this.state.rule.value[
            'transactionsAmountReductor'
          ],
        };
      } else {
        data['value'] = {
          fieldsValues: this.state.rule.value['fieldsValues'],
        };
        data.value.fieldsValues.map((field, index) => {
          delete data.value.fieldsValues[index]._id;
          delete data.value.fieldsValues[index].type;
          delete data.value.fieldsValues[index].options;
          return null;
        });
      }
    }
    RulesAPI.setRule(
      this.props.baseUrl,
      this.state.targetID,
      data,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return null;
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error al configurar la regla ', error);
      });
  }

  renderFields() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const ruleDefinition = this.state.definition;
    const elements = ruleDefinition.fields.map((field, index) => {
      if (field.options === undefined) {
        if (ruleDefinition.movements.disableExtraordinaryContributions) {
          return (
            <TextField
              {...config.textField1}
              key={field.name}
              id={field._id}
              label={field.name}
              color='primary'
              value={this.state.rule.value.fieldsValues[index].value}
              onChange={e => this.handleChangeFields(e, index)}
              classes={transformClasses('ruleInput_textField1', classes)}
            />
          );
        } else {
          return <div key={ruleDefinition._id} />;
        }
      } else {
        if (field.hasAutocomplete) {
          return (
            <TextField
              {...config.textField2}
              key={ruleDefinition._id}
              value={this.state.rule.value.fieldsValues[index].value}
              label={field.title}
              onChange={e =>
                this.handleChangeAutocomplete(
                  e,
                  ruleDefinition._id,
                  index,
                  field.name,
                )
              }
              classes={transformClasses('ruleInput_textField2', classes)}
            />
          );
        } else {
          return (
            <NativeSelect
              {...config.nativeSelect1}
              key={ruleDefinition._id}
              value={this.state.rule.value.fieldsValues[index].value}
              onChange={e => this.handleChangeFields(e, index)}
              classes={transformClasses('ruleInput_nativeSelect1', classes)}
            >
              {this.renderOptionsFields(field.options)}
            </NativeSelect>
          );
        }
      }
    });
    return elements;
  }

  renderTransactionsAmountReductor(ruleDefinition) {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    if (
      ruleDefinition.transactionsAmountReductor.type === 'PERCENTAGE' ||
      ruleDefinition.transactionsAmountReductor.type === 'ROUND'
    ) {
      return (
        <NativeSelect
          {...config.nativeSelect2}
          key={ruleDefinition._id}
          value={
            this.state.rule.value.transactionsAmountReductor[
              ruleDefinition.transactionsAmountReductor.type.toLowerCase()
            ]
          }
          onChange={e =>
            this.handleChangeTransactionsAmountReductor(e, ruleDefinition)
          }
          classes={transformClasses('inputRule_nativeSelect2', classes)}
        >
          {this.renderOptionsTransactionsAmountReductor(ruleDefinition)}
        </NativeSelect>
      );
    } else {
      return (
        <TextField
          {...config.textField3}
          key={ruleDefinition._id}
          label={ruleDefinition.name}
          value={
            this.state.rule.value.transactionsAmountReductor[
              ruleDefinition.transactionsAmountReductor.type.toLowerCase()
            ]
          }
          onChange={e =>
            this.handleChangeTransactionsAmountReductor(e, ruleDefinition)
          }
          classes={transformClasses('ruleInput_textField3', classes)}
        />
      );
    }
  }

  renderOptionsTransactionsAmountReductor(ruleDefinition) {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const arr = Array.from(
      {
        length:
          ruleDefinition.transactionsAmountReductor.max -
          ruleDefinition.transactionsAmountReductor.min +
          1,
      },
      (e, i) => ruleDefinition.transactionsAmountReductor.min + i,
    );
    const options = arr.map(option => {
      return (
        <option
          {...config.option1}
          key={option}
          value={option}
          classes={transformClasses('ruleInput_option1', classes)}
        >
          {option}
        </option>
      );
    });
    return options;
  }

  renderOptionsFields(options) {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = options.map(element => {
      return (
        <option
          {...config.option2}
          key={element}
          value={element}
          classes={transformClasses('ruleInput_option2', classes)}
        >
          {element}
        </option>
      );
    });
    return elements;
  }

  renderData() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.dataContainer}>
        <Typography
          {...config.typography1}
          classes={transformClasses('ruleInput_typography2', classes)}
        >
          {this.state.definition.name}
        </Typography>
        <Typography
          {...config.typography2}
          classes={transformClasses('ruleInput_typography3', classes)}
        >
          {this.state.definition.description}
        </Typography>
        <div>
          {this.state.definition.type === 'TransactionsRuleDefinition'
            ? this.renderTransactionsAmountReductor(this.props.definition)
            : this.renderFields(this.props.definition)}
        </div>
        {this.state.showAutocomplete && this.state.autocomplete !== null ? (
          <div className={classes.autocompleteContainer}>
            <Collapse
              {...config.collapse1}
              in={this.state.showAutocomplete}
              unmountOnExit
              classes={transformClasses('ruleInput_collapse1', classes)}
            >
              <List
                {...config.list1}
                classes={transformClasses('ruleInput_list1', classes)}
              >
                {this.renderAutocomplete()}
              </List>
            </Collapse>
          </div>
        ) : (
          <div />
        )}
        <Button
          {...config.button1}
          onClick={this.setRule}
          classes={transformClasses('ruleInput_button1', classes)}
        >
          Guardar cambios
        </Button>
      </div>
    );
  }

  renderAutocomplete() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.autocomplete.options.map(element => {
      return (
        <ListItem
          {...config.listItem1}
          key={element}
          classes={transformClasses('ruleInput_listItem1', classes)}
        >
          <Typography
            {...config.typography3}
            classes={transformClasses('ruleInput_typography3', classes)}
            onClick={e => this.handleClikcAutomcomplete(element, e)}
          >
            {element}
          </Typography>
        </ListItem>
      );
    });
    return elements;
  }

  render() {
    return this.state.rule !== 'no_rule' ? this.renderData() : <div />;
  }
}

export default RuleInput;
