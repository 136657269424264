import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Slide from '@material-ui/core/Slide';
//import { BanksAPI } from '../../core/
import { BanksAPI } from '../../src-core/sdk/index';

import SwipeableViewsConfigurable from '../swipeableViews/swipeableViewsConfigurable.component';
import ProductCard from './ProductCard';

/*
  Los productos son niveles de riesgo q puede tener el usuario,
  cada nivel esta asociado a un tipo de seguro/ahorro 
  
  Este componentes muestra cada producto financiero de 
  los que puede elegir el usuario en los slides.
 */

class ProductSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.default,
      open: false,
      claim: null,
      aceptance: null,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOnChange(event) {
    if (this.state.product !== event.target.value) {
      this.setState({
        product: event.target.value,
        open: true,
        claim: this.props.prodsForUser[event.target.value].selectedClaim,
        aceptance: this.props.prodsForUser[event.target.value].aceptanceText,
      });
    }
  }

  handleClose(acceptation) {
    if (acceptation) {
      const data = {
        product: this.props.prodsForUser[this.state.product].product,
        foundsConfiguration: [
          {
            foundCode: this.state.product,
            percentage: 100,
          },
        ],
      };
      BanksAPI.setProducts(
        this.props.baseUrl,
        this.props.targetID,
        data,
        this.props.token,
      ).then(response => {
        if (response.status === 200) {
          this.props.updateProduct(this.state.product);
        } else {
          console.log('error with product');
        }
      });
      this.setState({
        open: false,
        claim: null,
        aceptance: null,
      });
    } else {
      this.setState({
        open: false,
        claim: null,
        aceptance: null,
        product: null,
      });
    }
  }

  transition(props) {
    return <Slide direction='up' {...props} />;
  }

  renderProducts() {
    const { classes, founds } = this.props;
    return (
      <div className={classes.products_divContainer}>
        {this.renderFounds(founds)}
      </div>
    );
  }

  renderFounds(founds) {
    //const { classes, config } = this.props;
    //const transformClasses = Utils.transformClasses;
    console.log('products', this.props);
    let elementsCards = [];

    if (this.props.retirement) {
      elementsCards = Object.keys(this.props.prodsForUser).sort(
        (a, b) =>
          this.props.prodsForUser[a].riskFactor -
          this.props.prodsForUser[b].riskFactor,
      );
    } else {
      elementsCards = Object.keys(this.props.prodsForUser);
    }

    const elements = elementsCards.map((key, id) => {
      return (
        <ProductCard
          title={this.props.prodsForUser[key].title}
          name={this.props.prodsForUser[key].name}
          keyAux={id}
          riskFactor={this.props.prodsForUser[key].riskFactor}
          maxRiskFactor={this.props.maxRiskFactor}
          profitability={this.props.prodsForUser[key].profitability}
          history={this.props.history}
          location={this.props.location}
          historicalPdf={this.props.prodsForUser[key].pdf_history}
          morePdf={this.props.prodsForUser[key].pdf_more}
        />
      );
    });

    return elements;
  }

  render() {
    const { classes, swipeableConfig } = this.props;
    const newConfig = {
      ...swipeableConfig,
      containerStyle: {
        width: '312px',
      },
    };
    return (
      <SwipeableViewsConfigurable
        index={this.props.index}
        handleChangeIndex={this.props.handleChange}
        config={newConfig}
        child={this.renderFounds(this.props.prodsForUser)}
        classes={classes}
        slideStyle={{ padding: '0 10px' }}
      />
    );
  }
}

ProductSelector.propTypes = {
  prodsForUser: PropTypes.any,
  config: PropTypes.any,
  default: PropTypes.any,
  swipeableConfig: PropTypes.any,
  classes: PropTypes.any,
  byDefault: PropTypes.any,
  targetID: PropTypes.any,
  token: PropTypes.any,
  baseUrl: PropTypes.any,
  updateProduct: PropTypes.any,
  index: PropTypes.any,
  handleChange: PropTypes.any,
  maxRiskFactor: PropTypes.any,
};

export default ProductSelector;
