function SavedTotalStyles(theme) {
  const styles = {
    savedTotal_container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 22px',
    },
    savedTotal_firstRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    savedTotal_secondRow: {
      width: '100%',
      height: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(255,255,255,.2)',
      marginTop: '11px',
      marginBottom: '7px',
    },
    savedTotal_thirdRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '13px',
    },
    savedTotal_thirdRow_typography1_root: {
      fontSize: '12px',
      lineHeight: '16.9px',
      letterSpacing: '0px',
      width: '71px',
    },
    savedTotal_thirdRow_typography2_root: {
      fontSize: '12px',
      lineHeight: '16.9px',
      letterSpacing: '0px',
      width: '66px',
    },
    savedTotal_paper1_root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
    },
    savedTotal_typography1_root: {
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'upperCase',
      textAlign: 'center',
      width: 85,
      marginTop: '8.7px',
      marginBottom: 5,
    },
    savedTotal_typography2_root: {
      fontWeight: 100,
      fontSize: '45px',
      lineHeight: '39.4px',
      textAlign: 'center',
      letterSpacing: '0px',
    },
    savedTotal_typography3_root: {
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'upperCase',
      textAlign: 'center',
      lineHeight: '18px',
      letterSpacing: '0px',
    },
    savedTotal_total_resalt: {
      fontSize: '14px',
    },
    savedTotal_left_icon_root: {
      height: '28px',
      width: '28px',
      marginTop: '24px',
    },
    savedTotal_right_icon_root: {
      height: '28px',
      width: '28px',
      marginTop: '24px',
    },
    savedTotal_center_container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    savedTotal_center_icon_root: {
      height: '80px', //46
      width: '80px',
      marginTop: '18px',
      backgroundColor: 'transparent',
    },
    savedTotal_percentage_nextWish: {
      height: '8px',
      backgroundColor: theme.palette.secondary.main,
    },
    savedTotal_percentage_familyTarget: {
      height: '8px',
      backgroundColor: '#50e3c2',
    },
    savedTotal_percentage_retirement: {
      height: '8px',
      backgroundColor: '#ffc000',
    },
  };
  return styles;
}

export default SavedTotalStyles;
