import React from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from './MyHeader';
import Icons from './MyIcon.config';

const DocView = props => {
  //const [openModal, setOpenModal] = React.useState(props.open);
  //const transformClasses = Utils.transformClasses
  const [iFrameRef, setIFrameRef] = React.useState(null);
  const [iFrameLoaded, setIFrameLoaded] = React.useState(false);

  let backgroundImage = 'url(/images/bg_body.png)';
  if (props.styleType === 'white') {
    backgroundImage = '';
    //color = 'black';
  }
  const url =
    'https://docs.google.com/viewer?url=' +
    encodeURIComponent(props.url) +
    '&embedded=true';

  React.useEffect(() => {
    console.log('iframe useEffect ', url);
    if (props.open && iFrameRef) {
      iFrameRef.src = url;
    }

    const timer = setInterval(() => {
      console.log('iFrameLoaded timer', props.open, iFrameRef, iFrameLoaded);
      if (props.open && iFrameRef && !iFrameLoaded) {
        console.log('iFrameLoaded Recarga', iFrameLoaded);
        iFrameRef.src = url;
      }
      if (iFrameLoaded) {
        console.log('iFrameLoaded clear', timer);
        clearTimeout(timer);
      }
    }, 3000);

    return () => {
      console.log('useEfect return');
      clearTimeout(timer);
    };
  });

  function onLoadIFrame(event) {
    setIFrameLoaded(true);

    console.log('IFrame Load', iFrameLoaded);
  }

  const theme = createMuiTheme({
    overrides: {
      MuiDialog: {
        paper: {
          background: backgroundImage,
        },
      },
    },
  });

  function onDiskClick() {
    window.open(props.url);
  }

  //console.log('DocView', props, url);

  return (
    <MuiThemeProvider
      theme={theme}
      //className={styles.DocView_container}
    >
      <Dialog
        fullScreen
        open={props.open}
        onClose={() => props.onClose()}
        TransitionComponent={Transition}
      >
        <Header
          rigthAction={() => {
            props.onClose();
          }}
          title={props.title}
          styleType={props.styleType}
          leftAction={props.disk ? () => onDiskClick() : null}
          leftIcon={Icons.disquete}
        />

        {props.url && !iFrameLoaded && (
          <div
            style={{
              with: '100%',
              height: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <CircularProgress
              color='secondary'
              style={{
                marginTop: 10,
                marginButton: 10,
                width: 40,
                height: 40,
              }}
            />
          </div>
        )}

        {props.url ? (
          <iframe
            ref={node => setIFrameRef(node)}
            title='myFrame'
            //src={IFrameSrc}
            frameBorder='0'
            height='100%'
            width='100%'
            onLoad={e => onLoadIFrame(e)}
          />
        ) : (
          props.children
        )}
      </Dialog>
    </MuiThemeProvider>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

/*
const styles = theme => ({
  DocView_container: {
    backgroundColor: "url(/images/bg_body.png)",
  },
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const classes = useStyles();
*/

/*
const theme = createMuiTheme({
  palette: {
    primary: {
      light: purple[300],
      main: purple[500],
      dark: purple[700]
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700]
    },
    background: {
      default: "#ff0000"
    }
  }
});
*/

//ºº Al exportar con withStyles, no hereda del theme modificado para Coinscrap
// por mapear el theme a pelo arriba
//export default withStyles(styles, { withTheme: true })(DocView)

export default DocView;
