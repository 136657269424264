import React, { Fragment, useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

//import { Utils } from '../../core/ces';
import { Utils } from '../../src-core/resources/index';

import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import DocView from '../../components/MyDocView';
import Icons from '../../components/MyIcon.config';
import Checkbox from '@material-ui/core/Checkbox';
// import { colors } from '@material-ui/core';

const SurveyLanding = ({ classes, history, location }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalUrl, setModalUrl] = React.useState(null);
  const [modalTitle, setModalTitle] = React.useState(null);
  // const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [readInfoMediador, setReadInfoMediador] = useState(false);
  const [readRightsObligations, setReadRightsObligations] = useState(false);
  const [alertReadInfoMediador, setAlertReadInfoMediador] = useState(false);
  const [checkConditionsAccpeted, setCheckConditionsAccpeted] = useState(false);


  // function goToSurvey() {
  //   //console.log('state surveyLanding: ', this.props.location);
  //   this.props.history.push({
  //     pathname: '/survey/answer',
  //     state: {
  //       type: this.props.location.state.type,
  //       founds: this.props.location.state.founds,
  //     },
  //   });
  // }

  function goToSurvey() {
    history.push({
      pathname: '/survey/answer',
      state: {
        type: location.state.type,
        founds: location.state.founds,
      },
    });
  }

  console.log(readRightsObligations , readInfoMediador)
  
  // const { classes } = this.props;
  const transformClasses = Utils.transformClasses;
  return (
    <Fragment>
      <Body>
        <SvgIcon
          color='primary'
          classes={transformClasses('surveyLanding_icon', classes)}
        >
          <path d='M15.21,16.5l.64.65a.48.48,0,0,1,0,.7.48.48,0,0,1-.7,0l-.65-.64-.65.64a.48.48,0,0,1-.7,0,.48.48,0,0,1,0-.7l.64-.65-.64-.65a.49.49,0,0,1,.7-.7l.65.64.65-.64a.49.49,0,0,1,.7.7Zm1.14-4.85a.48.48,0,0,1,0,.7l-1.5,1.5a.48.48,0,0,1-.7,0l-1-1a.49.49,0,0,1,.7-.7l.65.64,1.15-1.14A.48.48,0,0,1,16.35,11.65Zm0-3a.48.48,0,0,1,0,.7l-1.5,1.5a.48.48,0,0,1-.7,0l-1-1a.49.49,0,0,1,.7-.7l.65.64,1.15-1.14A.48.48,0,0,1,16.35,8.65ZM11,16a.5.5,0,0,1,0,1H9a.5.5,0,0,1,0-1Zm0-3.5a.5.5,0,0,1,0,1H9a.5.5,0,0,1,0-1ZM11,9a.5.5,0,0,1,0,1H9A.5.5,0,0,1,9,9Zm7,9.5h0V6.5a.5.5,0,0,0-.5-.5H16v.5a.5.5,0,0,1-.5.5h-6A.5.5,0,0,1,9,6.5V6H7.5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h10A.5.5,0,0,0,18,18.5Zm-8-13V6h5V5.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5.5.5,0,0,0-1,0,.5.5,0,0,1-.5.5h-1A.5.5,0,0,0,10,5.5ZM17.5,5A1.5,1.5,0,0,1,19,6.5v12A1.5,1.5,0,0,1,17.5,20H7.5A1.5,1.5,0,0,1,6,18.5V6.5A1.5,1.5,0,0,1,7.5,5H9.09A1.5,1.5,0,0,1,10.5,4h.59a1.49,1.49,0,0,1,2.82,0h.59a1.5,1.5,0,0,1,1.41,1Z' />
        </SvgIcon>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('surveyLanding_typography1', classes)}
        >
          Perfil del inversor
        </Typography>
        <Typography
          variant='body1'
          color='primary'
          classes={transformClasses('surveyLanding_typography2', classes)}
        >
          Para poder ofrecerte el mejor producto donde invertir el dinero para
          tu meta, es necesario que respondas a unas sencillas preguntas.
        </Typography>

        <div
          style={{ textDecoration: 'none', color: 'white', marginTop: 25, width: '100%', alignItems: 'center' }}
          onClick={() => {
            setModalUrl(
              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/INFORMACIO%CC%81N+DEL+MEDIADOR+MAQUETADA.pdf',
            );
            setModalTitle('Información del Mediador');
            setOpenModal(true);
            setReadInfoMediador(true);
            setAlertReadInfoMediador(false);
          }}
        >
        
           <Checkbox
            checked={readInfoMediador}
            // disabled={!readInfoMediador}
            // onChange={(e, value) => {
            //   // setButtonDisabled(!value);
            //   setCheckConditionsAccpeted(!checkConditionsAccpeted);
            // }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              
              paddingTop: 0,
              paddingBottom: 0,
              marginTop: 0,
              marginBottom: 0,
             
              
            }}
            id='checkTerminos'
          />
          
          <Typography
            className='textH1'
            style={{ textDecoration: 'underline' }}
          >
            Información del Mediador
          </Typography>
        </div>
        <div
          style={{ textDecoration: 'none', color: 'white', width: '100%', alignItems: 'center' }}
          onClick={() => {
            setModalUrl(
              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/CONTRATO_DE_DERECHOS_Y_OBLIGACIONES.pdf',
            );
            setModalTitle('Contrato de derechos y obligaciones');
            setOpenModal(true);
            setReadRightsObligations(true);
            setAlertReadInfoMediador(false);
          }}
        >
          <Checkbox
            checked={readRightsObligations}
            // disabled={!readInfoMediador}
            // onChange={(e, value) => {
            //   // setButtonDisabled(!value);
            //   setCheckConditionsAccpeted(!checkConditionsAccpeted);
            // }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              
              paddingTop: 0,
              paddingBottom: 0,
              marginTop: 0,
              marginBottom: 0,
            
              
            }}
            id='checkTerminos'
          />
          <Typography
            className='textH1'
            style={{ textDecoration: 'underline' }}
          >
            Contrato de derechos y obligaciones
          </Typography>
        </div>
        <div
          style={{
            marginTop: 20,
            // marginBottom: 50,
            width: '100%',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Checkbox
            checked={checkConditionsAccpeted}
            disabled={!readRightsObligations || !readInfoMediador}
            onChange={(e, value) => {
              // setButtonDisabled(!value);
              setCheckConditionsAccpeted(!checkConditionsAccpeted);
            }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            style={{
              color: 'white',
              float: 'left',
              paddingTop: 5,
            }}
            id='checkTerminos'
          />
          <Typography
            className='textNormal'
            onClick={value => {
              setAlertReadInfoMediador(true);
              if (readRightsObligations && readInfoMediador) {
                setAlertReadInfoMediador(false);
                setCheckConditionsAccpeted(!checkConditionsAccpeted);
              }
            }}
          >
            He leído y acepto los términos y condiciones.
          </Typography>
        </div>
        <Typography style={{ color: '#c60', marginLeft: '30px' }}>
          {alertReadInfoMediador
            ? 'Debes leer antes la información del mediador y el contrato de derechos y obligaciones'
            : ''}
        </Typography>
      </Body>
      <Footer>
        <Button
          disabled={!checkConditionsAccpeted}
          color='secondary'
          variant='contained'
          classes={transformClasses('surveyLanding_button', classes)}
          onClick={() => goToSurvey()}
        >
          ¡Vamos allá!
        </Button>
      </Footer>
      {openModal && (
        <DocView
          open={openModal}
          title={modalTitle}
          onClose={() => setOpenModal(false)}
          url={modalUrl}
          styleType='black'
          leftIcon={Icons.disquete}
          disk
        />
      )}
    </Fragment>
  );
};

const styles = theme => ({
  movilScreen: {
    minHeight: '640px',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  surveyLanding_icon_root: {
    height: '46px',
    width: '46px',
    //marginTop: '160px'
  },
  surveyLanding_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    lineHeight: '45px',
    width: '280px',
  },
  surveyLanding_typography2_root: {
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '16px',
    width: '280px',
    marginTop: '15px',
  },
  surveyLanding_button_root: {
    width: '312px',
    height: '40px',
    borderRadius: '4px',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  surveyLanding_button_label: {
    fontSize: '14px',
    fontWeight: 600,
  },
});

export default withStyles(styles, { withTheme: true })(SurveyLanding);
