import React, { Component } from 'react';

// import { withStyles } from '@material-ui/core/styles'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import Modal from '@material-ui/core/Modal'

// import SvgIcon from '@material-ui/core/SvgIcon'
// import Typography from '@material-ui/core/Typography'
// import Input from '@material-ui/core/Input'
// import TextField from '@material-ui/core/TextField'
// import Collapse from '@material-ui/core/Collapse'
// import List from '@material-ui/core/List'
// import Button from '@material-ui/core/Button'
// import ListItem from '@material-ui/core/ListItem'
import Dialog from '@material-ui/core/Dialog';
// import AppBar from '@material-ui/core/AppBar'
import Slide from '@material-ui/core/Slide';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

import Header from './header.component';

class ModalView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    //console.log("ModalView", props);
  }

  onClickClose() {
    this.props.onClose();
  }

  render() {
    const { classes } = this.props;
    console.log('ModalView.render', classes);
    //const transformClasses = Utils.transformClasses
    let backgroundImage = 'url(/images/bg_body.png)';
    // let color = 'white';
    //console.log("ModalView.render", this.state, this.props);
    if (this.props.styleType === 'white') {
      backgroundImage = '';
      // color = 'black';
    }

    const url =
      'https://docs.google.com/viewer?url=' +
      encodeURIComponent(this.props.url) +
      '&embedded=true';
    console.log('url view', url);
    console.log('leftIcon', this.props.leftIcon);

    const theme = createMuiTheme({
      overrides: {
        MuiDialog: {
          paper: {
            background: backgroundImage,
          },
        },
      },
    });
    ///ºº Con este modo de hacer styles, no es compatible para tener hijos de tipo
    // withStyle, hay q ver como hacerlo compatible para poder reutilizar la clase.
    // mientras hay q utilizar <Modal>
    return (
      <MuiThemeProvider
        theme={theme}
        //className={styles.modalView_container}
      >
        <Dialog
          fullScreen
          open={true}
          onClose={() => this.onClickClose()}
          TransitionComponent={Transition}
        >
          <Header
            rigthAction={() => this.onClickClose()}
            //rightAction={this.props.rightAction}
            title={this.props.title}
            styleType={this.props.styleType}
            leftAction={
              this.props.leftAction ? () => this.props.leftAction() : null
            }
            leftIcon={this.props.leftIcon}
          />
          {this.props.url ? (
            <iframe
              title=''
              src={url}
              frameBorder='0'
              height='100%'
              width='100%'
            />
          ) : (
            this.props.children
          )}
          {/*
          <iframe 
            src={this.props.url}
            target="_blank" 
            rel="noopener noreferrer" 
            height="100%"
            width="100%"
          />
          */}
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

/*
const styles = theme => ({
  modalView_container: {
    backgroundColor: "url(/images/bg_body.png)",
  },
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const classes = useStyles();
*/

/*
const theme = createMuiTheme({
  palette: {
    primary: {
      light: purple[300],
      main: purple[500],
      dark: purple[700]
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700]
    },
    background: {
      default: "#ff0000"
    }
  }
});
*/

//ºº Al exportar con withStyles, no hereda del theme modificado para Coinscrap
// por mapear el theme a pelo arriba
//export default withStyles(styles, { withTheme: true })(ModalView)

export default ModalView;
