import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';

import Button from '@material-ui/core/Button';
//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

import Currency from '../../components/Currency';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
//import MyText from '../../components/MyText';

class CreateTargetInitSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommended: null,
    };
  }

  componentDidMount() {
    this.fetchRecommendedSaving();
  }

  getResumeText() {
    let text = '';
    let tiempo = '';
    let tiempoTipo = '';

    if (this.props.match.params.type === 'retirement') {
      text = Currency(this.state.recommended.actualMonthSavingNeeded);
    } else {
      if (this.props.temporaryConfiguration.months !== undefined) {
        text = Currency(
          (this.props.temporaryConfiguration.price -
            this.props.temporaryConfiguration.initialContribution) /
            this.props.temporaryConfiguration.months,
        );
        tiempo = this.props.temporaryConfiguration.months;
        tiempoTipo = 'meses';
      } else {
        text = Currency(
          (this.props.temporaryConfiguration.price -
            this.props.temporaryConfiguration.initialContribution) /
            this.props.temporaryConfiguration.years /
            12,
        );
        tiempo = this.props.temporaryConfiguration.years;
        tiempoTipo = 'años';
      }
    }

    return (
      <React.Fragment>
        {tiempoTipo !== '' ? (
          <React.Fragment>
            <div>
              <Typography
                display='inline'
                color='primary'
                style={{ fontSize: 45, fontWeight: 100 }}
              >
                {text}
              </Typography>
              <Typography
                display='inline'
                color='primary'
                style={{ fontSize: 25, fontWeight: 100 }}
              >
                {' '}
                al mes
              </Typography>
            </div>
            <Typography
              display='inline'
              color='primary'
              style={{ marginTop: -10 }}
            >
              durante
            </Typography>
            <div>
              <Typography
                display='inline'
                color='primary'
                style={{ fontSize: 35, fontWeight: 100 }}
              >
                {tiempo}
              </Typography>
              <Typography
                display='inline'
                color='primary'
                style={{ fontSize: 25, fontWeight: 100 }}
              >
                {' '}
                {tiempoTipo}
              </Typography>
            </div>
          </React.Fragment>
        ) : (
          <Typography
            display='inline'
            style={{ display: 'inline-block', fontSize: 45, fontWeight: 100 }}
          >
            {text}
          </Typography>
        )}
      </React.Fragment>
    );
  }

  async fetchRecommendedSaving() {
    const response = await TargetsAPI.getRecommendedSaving(
      this.props.baseUrl,
      this.props.id,
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      this.setState({
        recommended: data,
      });
      console.log('data: ', data);
    } else {
      console.log('error recuperando cantidad conveniente de ahorro');
    }
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.recommended !== null ? (
      <div className='container'>
        {' '}
        {/*classes.movilScreen*/}
        <Header
          title='Añadir Objetivo'
          rigthAction={() => this.props.history.push('/home/objetivos')}
        />
        <Body>
          <SvgIcon
            color='primary'
            classes={transformClasses(
              'targetAmountToSave_slideTitle_icon',
              classes,
            )}
          >
            <path d='m 13.2,8.4 c 0,0 0,0 0.2,0 0.7,0 2,-0.7 2.5,-1.2 0.4,-0.4 0.4,-1.1 0,-1.6 C 15.5,5.2 14.8,5.2 14.4,5.6 13.7,6.4 13,8.1 13.2,8.4 Z M 8.4,7.3 c 0.4,0.4 1.8,1.2 2.5,1.2 0.1,0 0.2,0 0.2,0 C 11.3,8.3 10.7,6.5 10,5.8 9.8,5.5 9.5,5.4 9.2,5.4 8.9,5.4 8.6,5.5 8.4,5.7 8,6.1 8,6.8 8.4,7.3 Z m -3.3,4.2 h 6.6 v -2 H 5.1 Z m 1,7.1 h 5.6 V 12.5 H 6.1 Z m 6.6,0 h 5.6 v -6.1 h -5.6 z m 0,-7.1 h 6.6 v -2 h -6.6 z m 6.6,-3.1 c 0.6,0 1,0.5 1,1 V 12 c 0,0.3 -0.2,0.5 -0.5,0.5 h -0.5 v 6.1 c 0,0.6 -0.5,1 -1,1 H 6.1 c -0.6,0 -1,-0.5 -1,-1 V 12.5 H 4.5 C 4.3,12.5 4,12.3 4,12 V 9.4 c 0,-0.6 0.5,-1 1,-1 H 8.2 C 8,8.3 7.8,8.1 7.7,8 6.9,7.2 6.9,5.8 7.7,5 c 0.8,-0.8 2.2,-0.8 3,0 0.4,0.4 1.6,2.2 1.4,3.4 h 0.1 c -0.2,-1.2 1,-3 1.4,-3.4 0.8,-0.8 2.2,-0.8 3,0 0.8,0.8 0.8,2.2 0,3 -0.1,0.1 -0.3,0.3 -0.6,0.5 h 3.3 z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetAmountSave_typography1', classes)}
          >
            Si quieres este capricho necesitarás ahorrar
          </Typography>

          {this.getResumeText()}

          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetAmountSave_typography3', classes)}
          >
            Para conseguir este capricho que tanto deseas te vamos a ofrecer una
            serie de reglas de ahorro que te ayudarán a conseguirlo de forma más
            fácil. <br />
            ¡Ánimo!
          </Typography>
        </Body>
        <Footer>
          <Button
            id='continuar'
            variant='contained'
            color='secondary'
            onClick={() => this.props.history.push(this.props.next)}
          >
            Continuar
          </Button>
        </Footer>
      </div>
    ) : (
      <div />
    );
  }
}

const styles = theme => ({
  movilScreen: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '640px',
    overflow: 'auto',
    alignItems: 'center',
    height: '100vh',
  },
  targetAmountToSave_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  targetAmountToSave_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  targetAmountToSave_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '18px',
  },
  targetAmountToSave_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
  },
  targetAmountToSave_slideTitle_icon_root: {
    width: '50px', //46
    height: '50px',
    //marginBottom: '5px',
    //marginTop: '127px'
  },
  targetAmountSave_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0px',
    width: '184px',
    marginTop: '18px',
  },
  targetAmountSave_typography2_root: {
    fontSize: '45px',
    fontWeight: 100,
    // lineHeight: '34px',
    letterSpacing: '-1px',
    marginTop: '8px',
  },
  targetAmountSave_typography3_root: {
    fontSize: '16px',
    textAlign: 'center',
    width: '280px',
    marginBottom: 60,
  },
  targetAmountSave_button_root: {
    width: '312px',
    height: '40px',
    marginBottom: '24px',
    marginTop: 'auto',
    borderRadius: '4px',
  },
  targetAmountSave_button_label: {
    fontSize: '14px',
    fontWeight: 600,
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetInitSummary);
