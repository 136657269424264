import React, { Component, Fragment } from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';

//import { TargetsAPI } from '../components-core/sdk';
import Chart from 'chart.js';

//import MyTextForInput from '../../components/MyTextForInput';
//import Flow from '../../components/Flow';
import ProgressBarStyles from '../../components/progressBar.style';
import MyChartJS from '../../components/MyChartJS';
import MyText from '../../components/MyText';
import ScenariosSimulator from '../Product/Simulator/ScenariosSimulator';

class FollowupGraphSimulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      years: 10,
      data: null,
      options: null,
      aportacionMensual: this.props.aportacionMensual,
    };

    console.log('FollowupGraphSimulation.constr', this.props, this.state);
  }

  componentDidMount() {
    this.getChartInfo();
    this.setState({
      years: this.diffYears(
        this.props.target.objectiveDate,
        this.props.target.activationDate
          ? this.props.target.activationDate
          : new Date(),
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.type !== this.props.type ||
      prevState.years !== this.state.years ||
      prevState.aportacionMensual !== this.state.aportacionMensual
    ) {
      this.getChartInfo();
    }
  }

  getChartInfo() {
    if (
      //!this.state.data &&
      this.props.product &&
      this.props.target &&
      this.state.aportacionMensual &&
      this.props.target.objectiveDate
    ) {
      console.log('FollowupGraphSimulation getChart', {
        target: this.props.target,
        name: this.props.product.name,
        aportIni: this.props.target.moneyAmounts.contributed, //aport Inicial
        aportMen: this.state.aportacionMensual, //aport Mensual
        years: this.state.years,
        age: this.props.edad,
        type: this.props.type,
      });
      let simulator = new ScenariosSimulator();
      let data = simulator.getGraphData(
        this.props.product.name,
        this.props.target.moneyAmounts.contributed, //aport Inicial
        this.state.aportacionMensual, //aport Mensual
        this.state.years,
        this.props.edad,
        'amount',
      );

      console.log('FollowupGraphSimulation data', data);
      //let first = 0;
      this.setState({
        data: data,
        options: {
          responsive: true,
          legend: {
            display: true,

            labels: {
              usePointStyle: true,
              fontColor: 'white',

              generateLabels: function(chart) {
                let newLabels = [];
                let labels = Chart.defaults.global.legend.labels.generateLabels(
                  chart,
                );

                console.log(
                  'generateLabels labels',
                  JSON.parse(JSON.stringify(labels)),
                );
                labels.map(label => {
                  if (label.text.includes('ExpectedTo')) {
                    label.text = 'Más probable';
                    newLabels.push(label);
                  } else if (label.text.includes('ExpectedFrom')) {
                  } else if (label.text.includes('OptimisticTo')) {
                    label.text = 'Menos probable';
                    newLabels.push(label);
                  } else if (label.text.includes('PessimisticFrom')) {
                  } else {
                    console.error(
                      'Chart, tipo etiqueta no contemplado. ',
                      label.text,
                    );
                  }
                  return null;
                });

                console.log(
                  'generateLabels return',
                  JSON.parse(JSON.stringify(newLabels)),
                );
                return newLabels;
              },
              // filter: function(item, chart) {
              //   // Logic to remove a particular legend item goes here
              //   if (item.text.includes('ExpectedTo')) {
              //     item.text = 'Más probable';
              //   } else if (item.text.includes('ExpectedFrom')) {
              //     item.text = '';
              //     item.fillStyle = '';
              //     item.lineWidth = 0;
              //     item.pointStyle = null;
              //   } else if (item.text.includes('OptimisticTo')) {
              //     item.text = 'Menos probable';
              //   } else if (item.text.includes('PessimisticFrom')) {
              //     item.hidden = true;
              //     item.text = '';
              //     item.fillStyle = '';
              //     item.pointStyle = null;
              //     item.lineWidth = 0;
              //   } else {
              //     console.error(
              //       'Chart, tipo etiqueta no contemplado. ',
              //       item.text,
              //     );
              //   }
              //   console.log('GG', item);
              //   return true;
              // },
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: 'white',
                  //fontSize: 18,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Años',
                  fontColor: 'white',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  callback: function(label, index, labels) {
                    return label + ' €';
                  },

                  fontColor: 'white',
                  //fontSize: 18,
                },
                scaleLabel: {
                  display: false,
                  //labelString: this.props.type == 'percent' ? '%' : '€',
                },
              },
            ],
          },
        },
      });
    } else {
      console.error(
        'FollowupGraphSimulation getChart: No se han cargado alguno de estos datos: props.product, props.target, state.aportacionMensual, props.target.objectiveDate, props.target.activationDate',
        this.props,
      );
    }
  }

  diffYears(fecha1, fecha2) {
    fecha1 = new Date(fecha1);
    fecha2 = new Date(fecha2);
    var años = fecha1.getFullYear() - fecha2.getFullYear();
    var m = fecha1.getMonth() - fecha2.getMonth();

    if (m < 0 || (m === 0 && fecha1.getDate() < fecha2.getDate())) {
      años--;
    }

    //console.log('calcularEdad', fecha, edad);
    return años;
  }

  render() {
    //const { classes /*, targetID*/ } = this.props;

    console.log('FollowupGraphSimulation.render', this.props, this.state);

    return (
      <Fragment>
        {this.state.data ? (
          <Fragment>
            <div>
              <div></div>
              <div></div>
            </div>
            <MyChartJS
              type='line'
              data={this.state.data}
              options={this.state.options}
              height={150}
            />
            <Slider
              value={this.state.years}
              onChange={value => this.setState({ years: value })}
              min={1}
              max={40}
              trackStyle={{ backgroundColor: '#f4137b', height: 10 }}
              handleStyle={{
                borderColor: 'silver',
                height: 28,
                width: 28,
                marginLeft: -14,
                marginTop: -9,
                backgroundColor: 'white',
              }}
              railStyle={{ backgroundColor: 'white', height: 10 }}
              style={{
                marginBottom: 15,
                marginTop: 5,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography style={{ color: 'white' }}>0</Typography>
              <Typography style={{ color: 'white' }}>
                Cambia los años de simulación
              </Typography>
              <Typography style={{ color: 'white' }}>40</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 0,
              }}
            >
              <Typography style={{ color: 'white', paddingTop: 15 }}>
                Prueba otra aportación mensual al plan
              </Typography>
              <Input
                id='impMensual'
                style={{
                  width: 70,
                  color: 'white',
                  padding: '13px 22px 1px',
                  borderBottomColor: 'white',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'double',
                  textAlign: 'right',
                }}
                type='number'
                disableUnderline
                value={this.state.aportacionMensual}
                placeholder='Ej: 30'
                onChange={e =>
                  this.setState({ aportacionMensual: parseInt(e.target.value) })
                }
              />
              <MyText variant='body' style={{ marginTop: 17, marginLeft: 5 }}>
                €
              </MyText>
            </div>
            <MyText style={{ marginTop: 20 }}>
              Simulación generada en base tu perfil selecionado{' '}
              <strong>{this.props.product.name}</strong>
            </MyText>
          </Fragment>
        ) : (
          <Typography style={{ color: 'white' }}>
            El objetivo aún no esta preparado.
          </Typography>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...ProgressBarStyles(theme),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 12,
    transition: 0.2,
    zIndex: 99999,
    backgroundColor: 'transparent',
  },
  followup_container: {
    //height: '100%',
    height: '100vh',
    display: 'flex',
    //justifyContent: 'space-evenly',
    flexFlow: 'column',
  },
  followup_movilScreen: {
    maxWidth: 400,
    margin: 'auto',
    maxHeight: 'inherit',
  },
  followup_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  followup_imgDiv: {
    position: 'relative',
    maxWidth: '100%',
    height: '25vh',
    minHeight: 142,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  progressBar_mobileStepper_progress: {
    /*position: 'absolute',
    bottom: 0,*/
    height: 10,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  listTargetMovements_divListContainer: {
    backgroundColor: 'none',
    flex: 1,
  },
  listTargetMovementsDetails_divListContainer: {
    backgroundColor: 'none',
  },
  /*
  followup_imgDiv_overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
*/

  chart: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  /*
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //marginTop: '100px',
    height: '100%',
    width: '100%',
    padding: 20,
  },
  */
  headersContainer: {
    //height: '15vh',
    //minHeight: 85,
    width: '100%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  followup_header_iconButton1_root: {
    padding: 'unset',
  },
  followup_header_iconButton2_root: {
    padding: 'unset',
  },
  followup_targetInfoContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },
  followup_targetName_root: {
    fontSize: 45,
    fontWeight: 200,
    textTransform: 'lowercase',
    lineHeight: '30px',
    paddingTop: 15,
  },
  followup_percentage_root: {
    fontSize: 25,
    fontWeight: 300,
    textTransform: 'lowercase',
    lineHeight: '30px',
  },
  followup_progress_root: {
    fontSize: 20,
    fontWeight: 300,
    textTransform: 'lowercase',
    lineHeight: '12px',
  },
  targetNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
  },
});

export default withStyles(styles, { withTheme: true })(FollowupGraphSimulation);
