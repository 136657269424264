export function getTargetsSummary(baseUrl, idUser, token) {
  console.log('Users.getTargetsSummary', baseUrl, idUser, token);

  return fetch(baseUrl + 'users/' + idUser + '/targetsSummary', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getUserData(baseUrl, idUser, token) {
  return fetch(baseUrl + 'users/' + idUser, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getPendingNotifications(baseUrl, idUser, token) {
  return fetch(baseUrl + 'users/' + idUser + '/pendingNotificationsCounter', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getNotifications(baseUrl, idUser, token) {
  return fetch(baseUrl + 'users/' + idUser + '/notifications', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function checkNotifications(baseUrl, idUser, idNotification, token) {
  return fetch(
    baseUrl +
      'users/' +
      idUser +
      '/notifications/' +
      idNotification +
      '/checked',
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getReferral(baseUrl, idUser, token) {
  return fetch(baseUrl + 'users/' + idUser + '/referral/info', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getSurvey(baseUrl, name, token) {
  console.log(baseUrl, name, token, 'TRIAL');
  return fetch(baseUrl + 'surveys/name/' + name, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getSurveyAnswers(baseUrl, idSurvey, token) {
  return fetch(baseUrl + 'surveys/' + idSurvey + '/solutions', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getUserSurveyResponse(baseUrl, idUser, idSurvey, token) {
  return fetch(
    baseUrl + 'users/' + idUser + '/surveys/' + idSurvey + '/response',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function setQuestion(baseUrl, idSurvey, idQuestion, data, token) {
  return fetch(
    baseUrl + 'surveys/' + idSurvey + '/questions/' + idQuestion + '/solution',
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getQuestionItems(baseUrl, idQuestion, query, token) {
  return fetch(baseUrl + 'questions/' + idQuestion + '/items?title=' + query, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function registerUser(baseUrl, data) {
  return fetch(baseUrl + 'users/', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function loginUser(baseUrl, data) {
  return fetch(baseUrl + 'identityProviders/password', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getRiskFactor(baseUrl, idUser, token) {
  return fetch(baseUrl + 'users/' + idUser + '/riskFactor', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}
