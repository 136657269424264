import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

import Header from '../../components/MyHeader';

class Faqs extends Component {
  constructor(props) {
    super(props);
    const arr = new Array(faq['empezando'].length).fill(false);
    this.state = {
      selected: 'empezando',
      toggle: arr,
    };
  }

  goChat() {
    this.props.history.push({
      pathname: '/chatSupport',
      state: {
        back: this.props.location.pathname,
      },
    });
  }

  handleChange(event) {
    const arr = new Array(faq[event.target.value].length).fill(false);
    this.setState({
      selected: event.target.value,
      toggle: arr,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  handleToggle(index) {
    let togg = this.state.toggle.slice();
    togg[index] = !togg[index];
    this.setState({
      toggle: togg,
    });
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetAccounts_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  renderMenuItems(transformClasses, classes) {
    const elements = menu.map(item => {
      return (
        <MenuItem
          key={item.key}
          value={item.key}
          classes={transformClasses('faq_select1MenuListItem', classes)}
        >
          {item.title}
        </MenuItem>
      );
    });
    return elements;
  }

  renderFaq() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.faq_container}>
        {faq[this.state.selected].map((element, index) => {
          return (
            <div key={element.title} className={classes.elements_container}>
              <div
                className={classes.faqElement}
                onClick={() => this.handleToggle(index)}
              >
                <Typography
                  variant='body1'
                  color='default'
                  classes={transformClasses('faq_typography1', classes)}
                >
                  {element.title}
                </Typography>
                <IconButton
                  classes={
                    this.state.toggle[index]
                      ? transformClasses('faq_iconButton1Selected', classes)
                      : transformClasses('faq_iconButton1', classes)
                  }
                >
                  <KeyboardArrowDown />
                </IconButton>
              </div>
              <Collapse
                in={this.state.toggle[index]}
                classes={transformClasses('faq_collapse1', classes)}
              >
                <Typography
                  variant='body1'
                  color='default'
                  classes={transformClasses('faq_typography2', classes)}
                >
                  {element.body}
                </Typography>
                {element.items !== undefined ? (
                  <div>{this.renderItems(element.items)}</div>
                ) : (
                  <div />
                )}
              </Collapse>
            </div>
          );
        })}
      </div>
    );
  }

  renderItems(items) {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = items.map((item, index) => {
      return (
        <Typography
          key={index}
          variant='body1'
          color='default'
          classes={transformClasses('faq_typography3', classes)}
        >
          {'* ' + item}
        </Typography>
      );
    });
    return elements;
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.container}>
        <Header
          title='Preguntas frecuentes'
          rigthAction={() => this.goBack()}
        />
        <div className={classes.faqs_container}>
          <div className={classes.selector_container}>
            <FormControl
              classes={transformClasses('faq_formControl1', classes)}
            >
              <Select
                value={this.state.selected}
                onChange={this.handleChange.bind(this)}
                disableUnderline
                classes={transformClasses('faq_select1', classes)}
                MenuProps={{
                  className: classes.faq_select1Menu_root,
                  classes: transformClasses('faq_select1Menu', classes),
                }}
                inputProps={{
                  classes: transformClasses('faq_select1Input', classes),
                }}
              >
                {this.renderMenuItems(transformClasses, classes)}
              </Select>
            </FormControl>
          </div>
          {this.renderFaq()}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  targetAccounts_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  container: {
    height: '100vh',
    minHeight: 568,
    width: '100%',
    overflow: 'auto',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  faqs_container: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
    overflow: 'auto',
  },
  header_centerIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  faqs_headerTypography1_root: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  faqs_headerIconButton2_root: {
    padding: 'unset',
  },
  faq_container: {
    height: '80vh',
    minHeight: 454,
    width: '100%',
    overflow: 'auto',
    paddingTop: 24,
  },
  selector_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 12px',
  },
  faq_formControl1_root: {
    width: '100%',
    borderBottom: 'unset',
  },
  faq_select1_root: {
    borderBottom: 'unset',
  },
  faq_select1_input: {
    backgroundColor: 'orange',
  },
  faq_select1Input_root: {
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
  faq_select1Input_icon: {
    fill: theme.palette.primary.main,
  },
  faq_select1Menu_root: {
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  faq_select1Menu_paper: {
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
  faq_select1Menu_list: {
    backgroundColor: 'orange',
  },
  faq_select1MenuListItem_root: {
    color: theme.palette.primary.main,
  },
  faq_select1MenuListItem_selected: {
    color: theme.palette.secondary.main,
  },
  faqElement: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid rgba(200,200,200,0.25)',
  },
  faq_collapse1_container: {
    padding: '0 12px',
  },
  elements_container: {
    padding: '0 12px',
  },
  faq_typography1_root: {
    fontFamily: 'Calibre',
    fontWeight: 400,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  faq_typography2_root: {
    textAlign: 'justify',
    fontFamily: 'Calibre',
    fontSize: 18,
    fontWeight: 200,
    color: theme.palette.primary.main,
  },
  faq_typography3_root: {
    margin: '4px 0',
    fontFamily: 'Calibre',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  faq_iconButton1_root: {
    color: theme.palette.primary.main,
    paddingRight: 0,
  },
  faq_iconButton1Selected_root: {
    color: theme.palette.secondary.main,
    paddingRight: 0,
  },
});

const menu = [
  {
    key: 'empezando',
    title: 'Empezando',
  },
  /*
  {
    key: 'objetivos',
    title: 'Objetivos'
  },
  {
    key: 'redondeos',
    title: 'Redondeos'
  },
  {
    key: 'productos',
    title: 'Productos de ahorro'
  },
  {
    key: 'seguridad',
    title: 'Seguridad'
  },
  {
    key: 'amigo',
    title: 'Plan Amigo'
  }
  */
];

const faq = {
  empezando: [
    {
      title: '¿Dónde se traspasa el dinero en tu  Hucha Inteligente EVO?',
      body: 'Hay tres opciones: ',
      items: [
        'Monedero: Todos los traspasos realizados a la categoría Monedero  se traspasará a un producto Seguro de vida individual de la modalidad “Mixto. Seguro de Vida- Ahorro de CASER –Caja de Seguros Reunidos, Compañía de Seguros y Reaseguros S.A., con NIF; A28013050 y domicilio en Calle de Don Ramón de la Cruz, 84 2º planta, 28050 Madrid, inscrito en el registro de entidades de la DGSyFP con el número C0031. EVO Banco, S.A., operador de Banca-Seguros Vinculado, inscrita en el registro de administrativo de mediadores de Seguros, Corredores de Reaseguros y de sus Altos Cargos a cargo de la DGSyFP con el nº OV-0075, www.dgsfp.mineco.es). Consulta las entidades aseguradoras con las que colabora EVO BANCO en https://www.evobanco.com/ayuda/entidades-de-seguros-colaboradoras/. ',
        'Hucha: En el caso de la categoría Hucha el importe se traspasaran al Plan de Inversión Inteligente EVO. Seguro Unit Linked en el que el tomador asume íntegramente el riesgo de la inversión. También puedes consultar los documentos de los datos fundamentales en www.caser.es. EVO Banco, S.A., operador de Banca-Seguros Vinculado, inscrita en el registro de administrativo de mediadores de Seguros, Corredores de Reaseguros y de sus Altos Cargos a cargo de la DGSyFP con el nº OV-0075, www.dgsfp.mineco.es). Consulta las entidades aseguradoras con las que colabora EVO BANCO en https://www.evobanco.com/ayuda/entidades-de-seguros-colaboradoras/.  ',
        'Caja Fuerte: Para la categoría de Caja Fuerte El dinero de tu Caja Fuerte estará en un Plan de Pensiones EVO: Entidad Depositaria: CECA, Entidad Promotora: EVO Banco, Entidad Gestora: Caser Pensiones, Entidad Comercializadora: EVO Banco. Puedes consultar indicadores de riesgo y alertas de liquidez en  www.caser.es. Consulta la Ficha de producto, el Informe de Gestión y Datos Fundamentales para el partícipe en https://www.evobanco.com/planes-de-pensiones/.',
      ],
    },
    {
      title: '¿Cuántas categorías hay?',
      body: 'La Hucha Inteligente EVO tiene 3 categorías:',
      items: [
        'Monedero: Es un objetivo a corto plazo (menos de 12 meses). Puedes ajustar a hasta dónde quieres redondear (al euro más cercano, a los 2 euros….), una cantidad mensual, el importe y el número de meses en que lo quieres conseguir. Al alcanzar el objetivo puedes retirar el importe para gastarlo en lo que quieras. ',
        'Hucha: todos tenemos en mente metas futuras que suelen tener un importe elevado (la universidad de tus hijos, comprar un coche…). Es un objetivo a medio plazo (más de 12 meses). La configuración para dar de alta el proceso de contratación es similar al punto anterior. Sólo tienes que determinar el importe y definir cómo se va a realizar tus aportaciones. ',
        'Caja Fuerte: te ayudamos a planificar tu jubilación a partir de las preguntas que te hacemos al iniciar el proceso. Determinamos cuánto necesitarías complementar aproximadamente a tu pensión de la seguridad social. Ese importe lo puedes cubrir a través de tus redondeos y de una cantidad fija mensual.',
      ],
    },
    {
      title: '¿Cómo funcionan los redondeos?',
      body:
        'Se calcula la diferencia entre la compra realizada y el euro al que has escogido redondear . Por ejemplo, si has escogido redondear al euro más cercano y realizas una compra de 1,25€, serán añadidos a tu lista de redondeos los 0,75€ de diferencia. Si has escogido redondear a los 2€ más cercanos, serán añadidos 1,75€ y así sucesivamente.',
    },
    {
      title: '¿Cuándo se traspasan mis redondeos?',
      body:
        'Los redondeos se calculan semanalmente (cada viernes) y se cargarán en la cuenta asociada a tu objetivo siempre que hayas alcanzado un mínimo de 10€. Si el mínimo no es alcanzado se intentará el cargo la semana siguiente y así sucesivamente hasta que el importe mínimo se cumpla. El cargo de los redondeos se hará 2 días hábiles después del último viernes de cálculo de redondeos.',
    },
    {
      title: '¿Cómo funcionan las reglas? ',
      body:
        'Puedes dar de alta todas las reglas que la Hucha Inteligente pone a tu disposición. Eso sí, tienes que tener en cuenta que todas las aportaciones que se hagan a tu objetivo serán mediante un recibo emitido todos los viernes por Caser con cargo a la cuenta que tu elijas cuando el importe de todas tus reglas (aportaciones, redondeos, % sobre tus ingreso, gane tu equipo de fútbol…) sea como mínimo de 10€. ',
    },
    {
      title: '¿Cómo pedir el reembolso?',
      body: 'Sí, ¿cómo? ',
      items: [
        'Monedero: Podrás hacer retiradas o cancelar tu Monedero sin ningún tipo de compromiso ni comisiones. Debes tener en cuenta que Caser aplica una medida antifraude por la cual solo podrás retirar el dinero que lleve más de 56 días en tu plan. Los recibos con menos de 56 días podrás realizar tu mismo la devolución a la cuenta de origen. Las retiradas parciales o cancelación de tu Monedero tardarán en procesarse entre 4 y 6 días laborables.',
        'Hucha: Podrás hacer retiradas o cancelar el Plan de Inversión Inteligente sin ningún tipo de compromiso ni comisiones. Debes tener en cuenta que Caser aplica una medida antifraude por la cual solo podrás retirar el dinero que lleve más de 56 días en tu plan. Los recibos con menos de 56 días podrás realizar tu mismo la devolución a la cuenta de origen. Las retirada parciales deberán ser como máximo del 80% del valor del Plan (para mayores importes debe proceder a un reembolso total), y como mínimo de 300 euros. Las retiradas parciales o cancelación del Plan de Inversión Inteligente tardarán en procesarse entre 4 y 6 días laborables. Debes tener en cuenta que el tomador del seguro es quien asume íntegramente los riesgos del producto. ',
        'Caja Fuerte: Para el caso de los reembolso sobre los objetivos generados Caja Fuerte se aplicará los criterios relativos a Planes de Pensiones donde el cobro de la prestación o el ejercicio del derecho de rescate sólo es posible en caso de acaecimiento de alguna de las contingencias  o supuestos excepcionales de liquidez regulados en la  Normativa aplicable a Planes Pensiones para más información consulta www.caser.es. Debes tener en cuenta que Caser aplica una medida antifraude por la cual solo podrás retirar el dinero que lleve más de 56 días en tu plan. Los recibos con menos de 56 días podrás realizar tu mismo la devolución a la cuenta origen.',
      ],
    },
    {
      title: '¿Dónde van mis redondeos?',
      body: 'El traspaso de tus categorías se realizará en: ',
      items: [
        'Monedero: Seguro de vida individual de la modalidad “Mixto”.',
        'Hucha: Plan de Inversión Inteligente EVO.',
        'Caja fuerte: Planes de Pensiones EVO.',
      ],
    },
    {
      title: '¿Obtengo rentabilidad por mi seguro de ahorro de Caser?',
      body: 'El seguro tiene interés técnico del 0,02%.',
    },
  ],
  objetivos: [
    {
      title: '¿Como establecer un objetivo?',
      body:
        'Los objetivos representan las características financieras para alcanzar tus deseos: Importe, periodo, redondeo, aportaciones mensuales, parte de sus ingresos dedicados a gastos.Dentro de cada objetivo puedes ajustar tus preferencias y alcanzarlo a tu propio ritmo. Para ello tienes que ajustar:',
      items: [
        'Precio (importe) correspondiente al producto o servicio que te quieres comprar, o el importe que quieres ahorrar o el complemento a la pensión de jubilación que esperas cobrar.',
        'Periodo: en el caso de un capricho o de una meta de medio plazo (red de seguridad o meta familiar) el periodo representa en cuánto tiempo deseas conseguir el importe elegido antes. En el caso de objetivo para jubilación, se trata de la edad en la cual esperas jubilarte.',
        'Aportaciones mensuales (Aport. Mes.): representa el complemento fijo del ahorro mensual que quieres añadir a tus redondeos para alcanzar tu objetivo cuanto antes (cuanto más aportes al mes, antes completarás el objetivo).',
        'Redondeo: esta opción permite escoger a qué altura quieres hacer el redondeo: al euro más cercano (1), a los dos euros (2), a los cinco euros (5) o a los diez euros más cercanos (10). Por ejemplo, si pagas 1,25€ en tu compra y estableces el redondeo a los 2€, ahorrarías 0,75€ en tu lista de redondeos. Con un redondeo a los 5€ ahorrarías 3,75€ y con un redondeo a los 10€ ahorrarías 8,75€.',
      ],
    },
    {
      title: '¿Cuantos objetivos hay?',
      body: 'COINSCRAP dispone de 4 objetivos financieros:',
      items: [
        'Mi próximo capricho: Es un objetivo a corto plazo (menos de 12 meses). Puedes ajustar a hasta dónde quieres redondear (al euro más cercano, a los 2 euros….), una cantidad mensual, el importe y el número de meses en que lo quieres conseguir. Al alcanzar el objetivo puedes retirar el importe para gastarlo en lo que quieras.',
        'Red de seguridad: Es conveniente tener 6 meses de salario acumulados en ahorro para posibles imprevistos que afecten a tu situación financiera. La configuración es similar al anterior. Sólo tienes que determinar el importe y la manera en la que quieres ahorrar.',
        'Meta familiar: Todos necesitamos ahorrar para metas futuras pero que suelen tener un importe elevado (la universidad de tus hijos, comprar un coche…). La configuración es similar a los dos puntos anteriores. Sólo tienes que determinar el importe y la manera en la que quieres ahorrar.',
        'Ahorra para tu jubilación: En este objetivo, te ahorramos a planificar tu jubilación. A partir de las preguntas que te hacemos al iniciar la aplicación determinamos cuánto necesitarías complementar aproximadamente tu pensión de la seguridad social. Ese importe lo puedes cubrir a través de tus redondeos y de una cantidad fija mensual.',
      ],
    },
    {
      title: '¿Se puede añair un banco a mi objetivo?',
      body:
        '¡Por supuesto! La idea es ahorrar lo máximo en un tiempo mínimo, por lo que si dispones de otra tarjeta o de otra cuenta bancaria a tu nombre, mejor aprovechar tus gastos para redondear e incrementar tus ahorros.',
    },
    {
      title: '¿Añadir una tarjeta o añadir una cuenta bancaria?',
      body:
        'Queremos darte la oportunidad de elegir a qué ritmo quieres ahorrar. Por ello, te damos la posibilidad de sincronizar tu objetivo a una tarjeta de crédito, con el fin de redondear sólo los pagos operados con dicha tarjeta. Podrás igualmente sincronizar tu cuenta bancaria de tal manera que haremos el redondeo sobre cualquier pago domiciliado en tu cuenta. Así que para completar un objetivo lo antes posible es conveniente añadir una cuenta bancaria más que una tarjeta. Y por supuesto, cuantas más cuentas, más ahorras.',
    },
    {
      title: '¿Como acceder a mis ahorros?',
      body:
        'Para acceder a tus fondos (obtener la liquidación de tu seguro de ahorro), solo tienes que confirmar tu decisión en el menú “REEMBOLSOS”. Una vez confirmado recibirás los fondos en un plazo de 3 días laborales. Puedes pedir tantos reembolsos como quieras, siempre y cuando no superen el 80% de la cantidad originalmente establecida (en cuyo caso sería necesario pedir un reembolso total). CoinScrap además, no añade comisión para la liquidación de tu seguro de ahorro.',
    },
  ],
  redondeos: [
    {
      title: '¿Cómo funcionan los redondeos?',
      body:
        'CoinScrap calcula la diferencia entre la compra realizada y el euro al que has escogido redondear . Por ejemplo, si has escogido redondear al euro más cercano y realizas una compra de 1,25€, serán añadidos a tu lista de redondeos los 0,75€ de diferencia. Si has escogido redondear a los 2€ más cercanos, serán añadidos 1,75€ y así sucesivamente.',
    },
    {
      title: '¿Cúando se añaden mis redondeos al seguro de vida-ahorro',
      body:
        'Los redondeos se calculan semanalmente (cada viernes) y se cargarán en la cuenta de ahorro de tu objetivo siempre que hayas alcanzado un mínimo de 10€. Si el mínimo no es alcanzado se intentará el cargo la semana siguiente y así sucesivamente hasta que el importe mínimo se cumpla. El cargo de los redondeos se hará 2 días hábiles después del último viernes de cálculo de redondeos.',
    },
    {
      title: '¿Cómo funciona el reembolso de mis ahorros',
      body:
        'REEMBOLSO: Consecuencias & Regulación, caso de reembolso antes del vencimiento del objetivo de ahorro',
      items: [
        'El producto pierde su rentabilidad',
        'Transferencias SEPA',
        'Los Direct Debit Transfers (Transferencias SEPA) tienen por ley una especificidad dado el derecho del usuario a poder pedir la devolución del importe cobrado durante un plazo de 70 días. Por esta razón y para evitar posibles movimientos duplicados,CASER Seguros aplica un periodo de carencia de 70 días a cada transferencia',
      ],
    },
    {
      title: '¿Cómo pedir el reembolso de mis ahorro',
      body:
        'El usuario puede a través de la App pedir el reembolso parcial o total de sus ahorros. También puede pedirlo escribiendo un correo electrónico a info@coinscrap.com. CoinScrap tras su petición , en un plazo inferior a 24 horas hábiles, transmite la petición de reembolso al sistema de CASER seguros. CASER trata la petición diferenciando los cargos SEPA de más o menos de 70 días como sigue:',
      items: [
        'Devolución inmediata (plazo de 3 días hábiles) de los recibos con más de 70 días',
        'Aplicación de la carencia (los días restantes para cumplir 70 días) a cada recibo que tiene menos de 70 días*.',
        'Cada recibo será reembolsado una vez se haya cumplido el periodo de carencia.',
      ],
    },
    {
      title: '¿Que banco y/o tarjeta puede utilizarse para redondear',
      body:
        'Disponemos de acceso exclusivo a más de 95% de los bancos y proveedores de tarjetas operando en España.',
    },
    {
      title: '¿Puedo utilizar la misma cuenta para redondear y para cobros?',
      body:
        'El importe de los redondeos utilizado para cumplir tu objetivo de ahorro, viene de la cuenta de cobro elegida, cuando configuras tu objetivo, con el fin de realizar el ingreso en tu plan de ahorro. En algunos casos no podrás utilizar la misma fuente para los redondeos y para los cobros de dichos redondeos, algunas de ellas no disponen de número IBAN por lo tanto no son cuentas de depósitos.',
    },
    {
      title: 'Reembolos parcial',
      body:
        'Debido a cuestiones legales relacionados con los mandatos SEPA que realizamos para guardar tus ahorros, sólo puedes reembolsar cargos realizados hace 70 días.',
    },
    {
      title: '¿Cómo conecto mi banco?',
      body:
        'Para conectar tu banco debes conocer tu usuario y tu clave de banca electrónica, que según la entidad bancaria variará. Por ejemplo: si tu banco es el BBVA deberás introducir tu DNI de usuario y tu clave de acceso a la banca electrónica. Una vez hayas introducido tus credenciales, te aparecerán tus cuentas y tarjetas disponibles, por tanto, en ningún momento debes introducir tu número de tarjeta y tu PIN ya que aparecen automáticamente.',
    },
  ],
  productos: [
    {
      title: '¿En dónde se ahorran mis redondeos?',
      body:
        'El ahorro de tu objetivo “mi próximo capricho” se realizará en un seguro de Vida-Ahorro de CASER.',
    },
    {
      title: '¿Cómo contratar el seguro de vida-ahorro de Caser?',
      body:
        'La contratación del seguro de Vida-Ahorro de CASER es muy sencilla. Puedes hacerlo de manera totalmente digital a través de la app dado que Coinscrap Finance S.L. es una agencia de seguros exclusiva de la citada Compañía y estamos inscritos como tales en el correspondiente registro de la Dirección general de Seguros y Fondos de Pensiones.Puedes conocer con detalle las características fundamentales del contrato de seguro (tipo de interés garantizado, derecho de rescate, régimen de aportaciones) en la nota informativa previa que tenemos a tu disposición en la app, y a la que podrás acceder antes de darte de alta. Es muy importante que leas y entiendas su contenido antes de darte de alta.',
    },
    {
      title: '¿Obtengo rentabilidad por mi seguro de ahorro de Caser?',
      body:
        'El seguro tiene una rentabilidad del 0,06% por lo que tu ahorro genera intereses.',
    },
    {
      title: '¿Puedo retirar el dinero ahorrado?',
      body:
        'Si, por supuesto. En cualquier momento puedes retirar el ahorro que llevas acumulado. No hay comisión por reembolso.',
    },
  ],
  seguridad: [
    {
      title: '¿Por qué debo confiar en coinscrap?',
      body:
        'CoinScrap es un agente exclusivo de CASER y estamos inscritos como tal en el registro de mediadores de la Dirección General de Seguro y Fondos de Pensiones: http://www.dgsfp.mineco.es/mediadores/. Además, contamos con especialistas con más de diez años de experiencia en banca privada y desarrollo software.',
    },
    {
      title: '¿Es seguro introducir mis claves bancarias?',
      body:
        'Para poder calcular los redondeos necesitamos conocer tus movimientos. Por eso en la aplicación el último paso es introducir tus claves bancarias. Estas claves son sólo de lectura y están encriptadas con máxima seguridad de 256 bits. Los datos nunca son almacenados en ningún dispositivo. La seguridad es una prioridad para nosotros.',
    },
    {
      title: '¿Cómo accedeis a mis movimientos?',
      body:
        'Según la directiva europea (Payment Service Directive 2015/2366) los datos bancarios pertenecen a los titulares de las cuentas y no a los bancos, por lo que se puede habilitar a terceros el acceso a los mismos. Los movimientos se obtienen a través de un proceso denominado webscraping, utilizado por empresas de renombre del sector Fintech.',
    },
  ],
  amigo: [
    {
      title: '¿En qué consiste Plan Amigo?',
      body:
        'El Plan Amigo es una promoción que tenemos disponible para que nuestros usuarios os beneficiéis de compartir COINSCRAP con vuestros amigos y conocidos. Por cada amigo que invites, ambos os lleváis 5€.',
    },
    {
      title: '¿Qué pasos debo seguir para beneficiarme del Plan Amigo?',
      body: '',
      items: [
        'Comparte la App con un amigo: por whatsapp, email, redes sociales… Para compartir la App simplemente entra en Configuración en la sección Plan Amigo. En la parte inferior de la pantalla verás que pone Envía el código, elige entre las distintas formas disponibles para compartirlo y envía tu código a tus familiares y amigos.',
        'Tu invitado debe descargar la App y registrarse con tu código.',
        'Ambos ahorráis con cualquier clase de objetivo durante 90 días.',
        'COINSCRAP os ingresa 5€ en vuestra cuenta.',
      ],
    },
    {
      title: '¿Cómo comparto la App de COINSCRAP?',
      body:
        'Para compartir la App simplemente entra en Configuración en la sección Plan Amigo. En la parte inferior de la pantalla verás que pone Envía el código, elige entre las distintas formas disponibles para compartirlo y envía tu código a tus familiares y amigos. Puedes compartir la App por whatsapp, por redes sociales, por email…',
    },
    {
      title: 'Me han enviado un código, ¿qué hago ahora?',
      body:
        'Una vez te has descargado COINSCRAP, cuando te hay un apartado que pone Código de referido, ahí debes poner el código que te ha enviado tu amigo.',
    },
    {
      title: '¿Cuántos amigos puedo invitar?',
      body:
        '¡No hay un límite de amigos! Cuantos más amigos invites, más puedes beneficiarte del Plan Amigo. Si invitas a un amigo y ambos mantenéis un objetivo durante 90 días, ambos recibiréis 5€. Si sucede lo mismo con dos invitados, ellos se llevarán 5€ cada uno, y tú te llevarás 10€. Si fuesen cinco invitados, tú recibirás 25€, y así sucesivamente.',
    },
    {
      title: '¿Cuándo recibiré el dinero del Plan Amigo?',
      body:
        'Para recibir los 5€ del Plan Amigo, tanto el usuario que invita como el invitado debéis cumplir el requisito de llevar 90 días ahorrando con COINSCRAP en cualquiera de los objetivos disponibles. Una vez cumplido el plazo, COINSCRAP os hará un ingreso de 5€ en vuestra cuenta. Si queréis añadir los 5€ recibidos a vuestro objetivo de ahorro, sólo tenéis usar Aportación Inmediata a través de la App para hacer una aportación extra.',
    },
  ],
};

export default withStyles(styles, { withTheme: true })(Faqs);
