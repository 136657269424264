function generateStyles(theme) {
  return {
    products_divContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      margin: '0 20px 0 20px',
    },
    products_divImgContainer: {
      width: '100%',
      backgroundColor: 'rgba(255,255,255,0.098)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
    },
    products_divImg: {
      width: '100%',
      height: '20vh',
      minHeight: 114,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    products_titleContainer: {
      textAlign: 'center',
      marginBottom: 20,
    },
    products_bodyContainer: {
      width: '100%',
      display: 'flex',
      marginTop: '28px',
      marginBottom: '26px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    products_disclaimerContainer: {
      margin: '10px 20px 10px 20px',
      padding: 15,
      border: '4px solid #000',
      textAlign: 'center',
    },
    products_typography1_root: {
      fontSize: '11px',
      fontWeight: 600,
      marginTop: '20px',
    },
    products_typography2_root: {
      fontSize: '45px',
      fontWeight: 100,
      textAlign: 'center',
    },
    products_infoContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    products_currentProfileContainer1: {
      width: '128px',
      marginRight: '8px',
      backgroundColor: 'rgba(255,255,255,0.098)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      paddingTop: '29px',
      paddingBottom: '18px',
    },
    products_currentProfileContainer2: {
      width: '128px',
      backgroundColor: 'rgba(255,255,255,0.098)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      paddingTop: '29px',
      paddingBottom: '18px',
    },
    products_typography3_root: {
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    products_typography4_root: {
      fontSize: '33px',
      textAlign: 'center',
      fontWeight: 100,
    },
    products_typography5_root: {
      fontSize: '12px',
      textAlign: 'center',
    },
    products_historicoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '20px',
      textDecoration: 'none',
    },
    products_fondosContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 20px',
      borderLeft: 'solid 1px rgba(255,255,255,.3)',
      borderRight: 'solid 1px rgba(255,255,255,.3)',
      textDecoration: 'none',
    },
    products_info2Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '20px',
      textDecoration: 'none',
    },
    products_historicoIcon_root: {
      backgroundSize: '28px 28px',
    },
    products_fondosIcon_root: {
      fontSize: '36px',
      marginBottom: '-9px',
    },
  };
}

export default generateStyles;
