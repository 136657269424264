import React, { Component } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Utils } from '../src-core/resources/index';

class ProgressBar extends Component {
  render() {
    const { classes, completed, total, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const newConfig = {
      ...config,
      steps: total,
      activeStep: completed,
    };
    return (
      <MobileStepper
        {...newConfig}
        classes={transformClasses('progressBar_mobileStepper', classes)}
      />
    );
  }
}

export default ProgressBar;
