import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLef from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';
// import {
//   ListTargetMovementsDetails,
//   ListTargetMovementsDetailsConfig,
//   ListTargetMovementsDetailsStyles
// } from '../../core/
import { ListTargetMovementsDetails } from '../../src-core/targets/index';
import { ListTargetMovementsDetailsConfig } from '../../src-core/targets/index';
import { ListTargetMovementsDetailsStyles } from '../../src-core/targets/index';
//import {Utils} from '../../core/
import { Utils } from '../../src-core/resources/index';

import { followupTarget_strings } from './text';

const styles = theme => ({
  ...ListTargetMovementsDetailsStyles(theme),
  followupMovementsDetails_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  followupMovementsDetails_button: {
    padding: 'unset',
    color: theme.palette.primary.main,
  },

  followupMovementsDetails_headerContainer: {
    display: 'flex',
    backgroundColor: 'rgba(255,255,255,0.098)',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  followup_headerTitle_root: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: -6,
  },
  listTargetMovementsDetails_iconButton1_root: {
    padding: 'unset',
    color: theme.palette.primary.main,
  },
  listTargetMovementsDetails_typography4_root: {
    padding: 12,
    fontSize: 12,
    fontWeight: 400,
    marginBottom: -6,
  },
  listTargetMovementsDetails_typography1_root: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    marginBottom: -6,
  },
  listTargetMovementsDetails_typography2_root: {
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: -6,
    textTransform: 'lowercase',
  },
  listTargetMovementsDetails_typography3_root: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 200,
    marginBottom: -6,
  },
  listTargetMovementsDetails_listItem1_root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
  movementsDetails_confe_container: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  movementsDetails_concepto_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movementsDetails_fecha_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movementsDetails_ammount_container: {
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  movementsDetails_icon_container: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pinkSquare: {
    height: '8px',
    width: '8px',
    backgroundColor: theme.palette.secondary.main,
    marginRight: '8px',
  },
});

class FollowupMovementsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movementLines: null,
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  goBack() {
    this.props.history.replace(
      '/followup/' + this.props.match.params.id + '/Movimientos',
    );
  }

  goToLines(id, ruleExecution) {
    this.props.history.replace({
      pathname:
        '/followup/' +
        this.props.match.params.id +
        '/Movimientos/' +
        this.props.match.params.idMovement +
        '/detalles/' +
        id +
        '/transacciones',
      state: {
        ruleExecution: ruleExecution,
      },
    });
  }

  getDetail() {
    const movementID = this.props.match.params.idMovement;
    const { baseUrl, token } = this.props;
    if (movementID === 'next') {
      if (this.props.location.state !== undefined) {
        this.setState({
          movementLines: this.props.location.state.movementLines,
        });
      } else {
        TargetsAPI.nextMovement(baseUrl, this.props.match.params.id, token)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw response.status;
            }
          })
          .then(data => {
            this.setState({
              movementLines: data.movementLines,
            });
          })
          .catch(error => {
            console.log('error recuperando los movimientos de usuario', error);
          });
      }
    } else {
      TargetsAPI.getMovementDetail(baseUrl, movementID, token)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.status;
          }
        })
        .then(data => {
          this.setState({
            movementLines: data.movementLines,
          });
        })
        .catch(error => {
          console.log('error consiguiendo los detalles de movimiento: ', error);
        });
    }
  }

  render() {
    const { classes, token } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.movementLines !== null ? (
      <div>
        <div className={classes.followupMovementsDetails_headerContainer}>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('followup_headerTitle', classes)}
          >
            {followupTarget_strings['es'].followupMovementsDetails_header_text}
          </Typography>
          <IconButton
            className={classes.followupMovementsDetails_button}
            onClick={this.goBack.bind(this)}
          >
            <KeyboardArrowLef />
          </IconButton>
        </div>
        <div className={classes.listTargetMovements_divListContainer}>
          <ListTargetMovementsDetails
            classes={classes}
            token={token}
            targetID={this.props.match.params.id}
            config={ListTargetMovementsDetailsConfig}
            movementLines={this.state.movementLines}
            goToLines={this.goToLines.bind(this)}
          />
        </div>
      </div>
    ) : (
      <div className={classes.followupMovementsDetails_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  FollowupMovementsDetails,
);
