import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
export const pensionTransferStatus = transfer => {
  // pending
  let defaultObj = {
    status: '',
    IconStatus: () => { },
  };

  if (!transfer) {
    return defaultObj;
  }

  // success
  if (transfer.SITUACION_c.toLowerCase() === 'enviada transferencia') {
    return {
      status: 'transferred',
      IconStatus: CheckCircleIcon,
      label: 'Transferencia enviada',
    };
  } else if (
    transfer.SITUACION_c.toLowerCase().includes('finalizado')
    // parseInt(transfer.importe_solicitado) > 0 &&
    // parseInt(transfer.motivo_rechazo) < 1
  ) {
    return {
      status: 'success',
      IconStatus: CheckCircleIcon,
      label: 'Traspaso finalizado',
      importe: transfer.importe_solicitado,
    };
  } else if (parseInt(transfer.motivo_rechazo) > 0) {
    return {
      status: 'rejected',
      IconStatus: CancelIcon,
      label: 'Traspaso rechazado',
    };
  }
  // transfer.fecha_recepcion_rechazo === '1970-01-01T00:00:00.000Z' &&
  // transfer.fecha_cobro_entrada === '1970-01-01T00:00:00.000Z' &&
  // parseInt(transfer.motivo_rechazo) < 1
  else {
    return {
      status: 'pending',
      IconStatus: QueryBuilderIcon,
      label: 'Traspaso en curso',
      applicationDate: transfer.fecha_solicitud_cliente,
    };
  }

  return defaultObj;
};
