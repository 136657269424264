/*
  Formateo de números al estilo: "12,345.00 €"
*/

const getCurrencyDecimals = (num, symbol, fraction) => {
  //console.log('Currency', num, typeof num);

  const number = typeof num == 'number' ? num : parseFloat(num);

  let result = '0,00';

  //console.log('Currency', num, decimales, exp, numFinal);
  if (number && symbol) {
    result = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: fraction ? 0 : 2,
      style: 'currency',

      currency: 'EUR',
    }).format(number);
  } else if (number) {
    result = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: fraction ? 0 : 2,
    }).format(number);
  }

  return result;
};

export default getCurrencyDecimals;
