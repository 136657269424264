import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Body from '../../components/MyBody';
import Header from '../../components/MyHeader';
import { View, Text, Button, Enfatize, Image } from 'coinscrap-webapp-core';

import closeIcon from '../common/svgs/promoClose.svg';
import savingsbox from '../common/svgs/promoSavingsBox.svg';
import piggyBank from '../common/svgs/promoPiggyBank.svg';
import money from '../common/svgs/promoMoney.svg';
import graph from '../common/svgs/promoGraph.svg';

import routes from '../../config/routes';

const CajaFuertePromo = props => {
  global.localStorage.setItem('promoCajaFuerteShowed', 'true');
  console.error('promoCheque');

  return (
    <Fragment>
      <Header
        rigthAction={() => {
          props.history.push('/home');
        }}
      ></Header>
      <Body vAlign='center'>
        <View spaceBetweenSt style={{ marginTop: 50 }}>
          <View width85St>
            <View>
              <View style={{ marginTop: 30, marginBottom: 40 }}>
                <Image src={savingsbox} style={{ width: 88, height: 'auto' }} />
              </View>

              <Text centerSt font16St>
                Aunque no sepamos qué pasará en el futuro, dar con la clave para
                vivir con tranquilidad tu mañana, puede ser un poco más fácil
                con tu <Enfatize>nueva Caja Fuerte.</Enfatize>
              </Text>
            </View>
            <View style={{ marginTop: 35 }}>
              <View startSt topSt flexRowSt>
                <View style={{ width: 40 }}>
                  <Image
                    src={piggyBank}
                    style={{ width: 34, height: 'auto' }}
                  />
                </View>
                <Text style={{ marginLeft: 10, textAlign: 'left' }}>
                  Bajas comisiones.
                </Text>
              </View>
              <View startSt topSt flexRowSt style={{ marginTop: 15 }}>
                <View style={{ width: 40 }}>
                  <Image src={money} style={{ width: 29, height: 'auto' }} />
                </View>
                <Text style={{ marginLeft: 10, textAlign: 'left' }}>
                  Mayor diversificación para tu dinero.
                </Text>
              </View>
              <View startSt topSt flexRowSt style={{ marginTop: 15 }}>
                <View style={{ width: 40 }}>
                  <Image src={graph} style={{ width: 29, height: 'auto' }} />
                </View>
                <Text style={{ marginLeft: 10, textAlign: 'left' }}>
                  Plan de Pensiones que invierte en un amplio abanico de Fondos
                  de inversión.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 15, marginBottom: 50 }}>
          <Text font24St boldSt>
            Todo 100% online
          </Text>
          <View style={{ padding: 15 }}>
            <Button
              style={{
                width: 240,
                height: 55,
                borderRadius: 0,
                textComponent: {
                  color: '#DB147C',
                  fontSize: 24,
                  textTransform: 'uppercase',
                  fontWeight: 600,
                },
                backgroundColor: 'white',
              }}
              pinkButtonSt
              onClick={() => props.history.push('/create/target/retirement')}
            >
              HAZ LA SIMULACIÓN
            </Button>
          </View>
        </View>
      </Body>
    </Fragment>
  );
};

export default CajaFuertePromo;
