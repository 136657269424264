import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ListTargetsUser from '../Home/ListTargetsUser';
import ListTargetsUserConfig from '../Home/ListTargetsUser.config';

//import { ListConfig } from '../../core/ct/list/list.config'
import { ListConfig } from '../../src-core/ui/index';
//import { ListConfig } from '../../src-core/ui/index';

//import { Utils } from '../../core/

import { Utils } from '../../src-core/resources/index';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';

import ProgressBarConfig from '../../components/progressBar.config';
import Header from '../../components/MyHeader';
import { styles as HomeTargetStyles } from '../Home/HomeTargets';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTargets: null,
    };
  }

  goBack() {
    this.props.history.goBack();
  }

  goToConfig(id) {
    this.props.history.push('/followup/' + id + '/Gráficos');
  }

  filterTargetByType(type) {
    return this.state.userTargets.filter(t => t.type === type);
  }

  targetsUser(url, user, token) {
    TargetsAPI.userTargets(url, user, token, true)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('DATA', data);
        this.setState({
          userTargets: data.filter(
            t => t.status === 'CANCELED' || t.status === 'FINISHED',
          ),
        });
      })
      .catch(error => {
        console.log('error getting the user Targets');
      });
  }
  componentDidMount() {
    const { baseUrl, token } = this.props;
    this.targetsUser(baseUrl, 'me', token);
  }

  render() {
    const { classes, theme } = this.props;
    const transformClasses = Utils.transformClasses;

    return (
      <div className={classes.container}>
        <Header
          title={'Histórico de objetivos'}
          modal={true}
          history={this.props.history}
        />
        {this.state.userTargets ? (
          <div className={classes.contentContainer}>
            <Typography
              classes={transformClasses('history_typography2', classes)}
            >
              Monedero
            </Typography>
            <ListTargetsUser
              config={ListTargetsUserConfig}
              classes={classes}
              targets={this.filterTargetByType('nextWish')}
              theme={theme}
              listConfig={ListConfig}
              progressBarConfig={ProgressBarConfig}
              goToConfig={this.goToConfig.bind(this)}
              type={'nextWish'}
              history={this.props.history}
            />
            <Typography
              classes={transformClasses('history_typography2', classes)}
            >
              Hucha
            </Typography>
            <ListTargetsUser
              config={ListTargetsUserConfig}
              classes={classes}
              targets={this.filterTargetByType('familyTarget')}
              theme={theme}
              listConfig={ListConfig}
              progressBarConfig={ProgressBarConfig}
              goToConfig={this.goToConfig.bind(this)}
              type={'nextWish'}
              history={this.props.history}
            />
            <Typography
              classes={transformClasses('history_typography2', classes)}
            >
              Caja Fuerte
            </Typography>
            <ListTargetsUser
              config={ListTargetsUserConfig}
              classes={classes}
              targets={this.filterTargetByType('retirement')}
              theme={theme}
              listConfig={ListConfig}
              progressBarConfig={ProgressBarConfig}
              goToConfig={this.goToConfig.bind(this)}
              type={'nextWish'}
              history={this.props.history}
            />
          </div>
        ) : (
          <div className={classes.history_loadingContainer}>
            <CircularProgress color='secondary' />
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  ...HomeTargetStyles(theme),
  container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
  },
  history_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  history_typography2_root: {
    fontSize: 15,
    fontWeight: 600,
    width: '100%',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  contentContainer: {
    height: '90vh',
    minHeight: 511,
    paddingLeft: 15,
    paddingRight: 15,
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    maxWidth: 380,
    margin: 'auto',
  },
});

export default withStyles(styles, { withTheme: true })(History);
