import React from 'react';
import { View, Text, Button, Enfatize } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import routes from '../../../../config/routes';
import Flow from '../../../../components/Flow';
import Wallet from '../../../common/svgs/Wallet';
import navConfig from '../../config/navigation';

export default props => {
  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt bodyTwoButtonsSt justifyCenterSt>
        <View width85St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Wallet style={{ color: 'purple' }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              Tu perfil inversor
            </Text>
            <Text centerSt>
              Para poder ofrecerte el mejor producto donde invertir el dinero
              para tu meta, necesitamos conocer tu perfil inversor.
            </Text>
            <Text centerSt style={{ marginTop: 15 }}>
              Puedes decírnoslo eligiéndolo tú mismo o respondiendo unas
              sencillas preguntas.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() => {
              Flow.navigate(
                '/create/target/familyTarget/products/selector',
                props,
                { mode: 'retirementSimulation' },
              );
            }}
          >
            ELEGIR MANUALMENTE
          </Button>
          <Text
            style={{
              marginTop: 15,
              textDecoration: 'underline',
              fontSize: 14,
              marginBottom: 15,
            }}
            onClick={() => {
              Flow.navigate('/survey', props, { mode: 'retirementSimulation' });
            }}
          >
            REALIZAR TEST
          </Text>
        </View>
      </Footer>
    </>
  );
};
