import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  View,
  Text,
  Button,
  Layout,
  InputRadio,
  RadioOption,
  TextField,
} from 'coinscrap-webapp-core';

import Header from '../../components/MyHeader';
import Footer from '../../components/MyFooter';
import routes from '../../config/routes';
import colors from '../../config/colors';
import navConfig from './config/navigation';
import Flow from '../../components/Flow';

import { TargetsAPI } from '../../src-core/sdk/index';

export default props => {
  const store = useSelector(state => state.main);
  const flowParams = Flow.getParams();

  const [designateBeneficiary, setDesignateBeneficiary] = useState('no');
  const [name, setName] = useState('');
  useEffect(() => {}, []);

  const saveObjective = async () => {
    const data = {
      beneficiary: designateBeneficiary === 'no' ? 'no' : name,
    };

    try {
      // await TargetsAPI.setTargetConfiguration(
      //   store.baseUrl,
      //   store.objectiveId,
      //   store.token,
      //   data,
      // );

      const nextPage =
        flowParams.flujo === 'transfer'
          ? routes.retirementTransferSelectPlan
          : routes.retirementObjective;

      props.history.push(nextPage);
      // Flow.navigate(
      //   flujo === 'transfer'
      //     ? routes.retirementTransferSelectPlan
      //     : routes.retirementObjective,
      //   this.props,
      //   {
      //     flujo: flujo,
      //     objectiveType: this.props.type,
      //   },
      // );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout.Container>
      <Header
        title='Beneficiario'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt style={{ marginTop: 20 }}>
        <View width93St>
          <Text centerSt mediumHeadlineSt>
            {/* ¿Deseas designar expresamente a uno o varios beneficiarios? */}
            Designación de beneficiarios
          </Text>

          {/* 
          <InputRadio
            value={designateBeneficiary || 'no'}
            onChange={e => setDesignateBeneficiary(e.target.value)}
            style={{ width: '100%' }}
          >
            <RadioOption value={'yes'}>{'Si'}</RadioOption>
            {designateBeneficiary === 'yes' ? (
              <View style={{ width: '100%' }}>
                <Text style={{ width: '100%', marginLeft: 60 }}>
                  Designación expresa del beneficiario
                </Text>
                <TextField
                  placeholder='Beneficiario'
                  selectPlanSt
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </View>
            ) : (
              ''
            )}
            <RadioOption value={'no'}>{'No'}</RadioOption>
            {designateBeneficiary === 'no' ? (
              <Text style={{ marginLeft: 30 }}>
                En defecto de designación expresa será beneficiario por orden
                preferente y excluyente:
                <ul style={{ margin: 16, padding: 0 }}>
                  <li style={{ margin: '10px 0', fontSize: 15 }}>
                    1.- Cónyuge no separado legalmente por resolución judicial
                    del asegurado
                  </li>
                  <li style={{ margin: '10px 0', fontSize: 15 }}>
                    2.- Hijos supervivientes del asegurado a partes iguales
                  </li>
                  <li style={{ margin: '10px 0', fontSize: 15 }}>
                    3.- Padres supervivientes del asegurado a partes iguales,
                  </li>
                  <li style={{ margin: '10px 0', fontSize: 15 }}>
                    4.- Herederos legales del asegurado.{' '}
                  </li>
                </ul>
              </Text>
            ) : (
              ''
            )}
          </InputRadio> */}
        </View>
        <View style={{ flexBasis: '100%' }}>
          <Text style={{ marginLeft: 30 }}>
            En defecto de designación expresa será beneficiario por orden
            preferente y excluyente:
            <ul style={{ margin: 16, padding: 0 }}>
              <li style={{ margin: '10px 0', fontSize: 15 }}>
                1.- Cónyuge no separado legalmente por resolución judicial del
                asegurado
              </li>
              <li style={{ margin: '10px 0', fontSize: 15 }}>
                2.- Hijos supervivientes del asegurado a partes iguales
              </li>
              <li style={{ margin: '10px 0', fontSize: 15 }}>
                3.- Padres supervivientes del asegurado a partes iguales,
              </li>
              <li style={{ margin: '10px 0', fontSize: 15 }}>
                4.- Herederos legales del asegurado.{' '}
              </li>
            </ul>
          </Text>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            disabled={designateBeneficiary === 'yes' && !name.trimEnd()}
            pinkButtonSt
            onClick={() => saveObjective()}
          >
            CONTINUAR
          </Button>
        </View>
      </Footer>
    </Layout.Container>
  );
};
