function generateStyles(theme){
    const styles = {
        listTargetMovementsDetailsLines_divListContainer:{
            overflow: 'auto',
            //background: theme.palette.primary.main
        },
        listTargetMovementsDetailsLines_typography1_root: {
            fontSize: 12
        },
        listTargetMovementsDetailsLines_typography2_root: {
            fontSize: 12
        },
        listTargetMovementsDetailsLines_iconButton1_root:{
            padding: 'unset'
        }
    }
    return styles
}

export {
    generateStyles as ListTargetMovementsDetailsLinesStyles
}