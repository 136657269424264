function portfolio() {
  return {
    title: 'Agresivo',
    $$hashKey: 'object:114',
    benchmark: { id: 'imef', name: 'FI Renta Variable Mixta Internacional' },
    equity: 52,
    historicPerformance: 7.2815,
    id: 'a210bf52-04e1-11e7-8555-080027628e9b',
    minAmount: 100,
    minTimeframe: 3,
    name: 'Agresivo',
    performance: 9.72,
    productId: 'evo_caser_unit_linked',
    recommended: true,
    selected: false,
    tolerance: 5,
    typicalDeviation: {
      1: 13.6072,
      2: 9.8029,
      3: 7.8857,
      4: 6.9305,
      5: 6.1509,
      6: 5.3222,
      7: 4.8791,
      8: 4.7344,
      9: 4.6506,
      10: 4.4366,
      11: 4.3366,
      12: 4.2569,
      13: 4.1221,
      14: 3.9993,
      15: 3.8739,
      16: 3.6851,
      17: 3.423,
      18: 3.1979,
      19: 3.0255,
      20: 2.8677,
      21: 2.7501,
      22: 2.6715,
      23: 2.5269,
      24: 2.3347,
      25: 2.1606,
      26: 1.947,
      27: 1.6921,
      28: 1.4776,
      29: 1.3434,
      30: 1.1323,
      31: 0.9142,
      32: 0.8098,
      33: 0.6941,
      34: 0.4576,
      35: 0.4348,
      36: 0.4795,
      37: 0.3647,
      38: 0.333,
      39: 0.3634,
      40: 0.3184,
      default: 0.3184,
    },
  };
}

export default portfolio;
