import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('imageSelector', {
  styles: {
    default: {
      buttonComponent: {
        width: 'auto',
        minWidth: '150px',
      },
    },
    circleSt: {
      imageComponent: {
        width: 78,
        height: 78,
        minHeight: 78,
        borderRadius: 100,
        boxShadow: 0,
        borderColor: colors.greyLightBorders2,
        borderWidth: 1.4,
        borderStyle: 'solid',
      },
      buttonComponent: {
        display: 'none',
      },
    },
  },
});
