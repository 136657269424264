import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputSelect', {
  styles: {
    default: {
      containerComponent: {
        width: '100%',
      },

      selectContainerComponent: {
        height: '48px',
        border: '1px solid #EEF0F2',
        boxSizing: 'border-box',
        backgroundColor: '#FAFBFC',
        borderRadius: '0px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        width: '100%',
        paddingRight: 0,
      },

      labelComponent: {
        fontSize: 17,
        color: colors.blackLight,
        lineHeight: '20px',
        // fontFamily: 'SantanderTextRegular',
      },

      selectComponent: {
        width: '100%',
      },
      iconComponent: {},
    },

    borderGreenSt: {
      selectContainerComponent: {
        borderBottom: '1px solid #8CBABD',
      },
    },

    desplegableSt: {
      containerComponent: {
        height: '48px',
        width: '100%',
        backgroundColor: 'transparent',
        margin: 0,
        border: 0,
        borderWidth: 0,
      },
      selectContainerComponent: {
        backgroundColor: 'transparent',
        borderRadius: '0px',
        border: 0,
        borderWidth: 0,
        width: '100%',
        paddingRight: 0,
      },
      selectComponent: {
        width: '100%',
        margin: 0,
        padding: 0,
        border: 0,
        borderWidth: 0,
        padding: 5,
      },
      menuContainerComponent: {
        backgroundColor: colors.greyLight,
      },
    },

    selectWithBorderSt: {
      containerComponent: {
        height: 56,
        width: '100%',
        boxSizing: 'border-box',
      },
      selectContainerComponent: {
        borderBottom: '2px solid' + colors.greyLightBorders2,
        height: '100%',

        border: '1px solid #EEF0F2',
        boxSizing: 'border-box',
        backgroundColor: '#FAFBFC',
        borderRadius: '0px',

        width: '100%',
        paddingRight: 0,
      },
      selectComponent: {
        color: colors.blackLight,
        fontSize: 17,
        lineHeight: '20px',
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        width: '100%',
        flexShrink: 1,
      },
      labelComponent: {
        color: '#727272',
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        fontSize: 17,
        lineHeight: '20px',
        marginTop: '-4px',
      },
      iconComponent: {
        borderLeft: '1px solid #E5E5E5',
        paddingLeft: 10,
        paddingRight: 4,
        // paddingTop: 15,
        // paddingBottom: 5,
        height: '28px',
        boxSizing: 'content-box',

        top: '50%',
        transform: 'translateY(-50%)',
        color: colors.green,
      },
    },
    reglaTraspasoSt: {
      containerComponent: {
        height: 56,
        width: '250px',
        boxSizing: 'border-box',
        background: 'transparent',
        marginTop: -10,
        position: 'relative',
      },
      selectContainerComponent: {
        height: '100%',
        border: 'none',
        borderBottom: `2px solid ${colors.pink}`,
        boxSizing: 'border-box',
        borderRadius: '0px',
        background: 'transparent',
        width: '100%',
        paddingRight: 0,
      },
      selectComponent: {
        color: colors.white,
        fontSize: 20,
        fontWeight: 100,
        lineHeight: '20px',
        width: '100%',
        // flexShrink: 1,
        textAlign: 'center',
        opacity: 0.5,
        paddingBottom: 0,
        paddingRight: '0px !important',
      },
      labelComponent: {
        color: '#727272',
        // fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        fontSize: 17,
        lineHeight: '20px',
        marginTop: '-4px',
      },
      iconComponent: {
        borderLeft: '1px solid #E5E5E5',
        paddingLeft: 10,
        paddingRight: 4,
        // paddingTop: 15,
        // paddingBottom: 5,
        height: '28px',
        boxSizing: 'content-box',

        top: '50%',
        transform: 'translateY(-50%)',
        color: colors.green,
      },
      menuContainerComponent: {
        position: 'absolute',
        backgroundColor: colors.white,
        color: colors.black,
        width: '100%',
      },
    },
    selectWithBorderGreenSt: {
      containerComponent: {
        height: '48px',
        width: '100%',
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
        margin: '0px 0px 20px',
      },
      selectContainerComponent: {
        backgroundColor: colors.greyBcInput,
        borderRadius: '0px',
        border: '1px solid #EEF0F2',
        borderBottomColor: '#8CBABD',
        width: '100%',
        paddingRight: 0,
      },
      selectComponent: {
        width: '90%',
        color: colors.black,
        fontSize: 17,
        fontFamily: 'SantanderTextRegular',
      },
      iconComponent: {
        color: colors.green,
        width: 35,
        borderLeft: '1px solid #E5E5E5',
        paddingLeft: 10,
      },
    },
  },
});
