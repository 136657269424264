import React from 'react';

import KeyboardArrowLef from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';

//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

function backButton(history, prev) {
  history.push(prev);
}

export default props => {
  return (
    <div className={props.classes.header}>
      <IconButton
        onClick={e => backButton(props.history, props.prev, e)}
        classes={Utils.transformClasses(
          'createTarget_headerButton',
          props.classes,
        )}
      >
        <KeyboardArrowLef
          {...props.config.icon}
          classes={Utils.transformClasses(
            'createTarget_headerIcon',
            props.classes,
          )}
        />
      </IconButton>
    </div>
  );
};
