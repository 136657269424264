const config = {
  list: {
    component: 'div',
    disablePadding: true
  },
  listItem: {
    button: true,
    disableGutters: true
  },
  typography1: {
    variant: 'h4'
  },
  typography2: {
    variant: 'body1'
  },
  collapse: {
    timeout: 'auto',
    unmountOnExit: true
  },
  button: {
    size: 'large',
    variant: 'contained',
    color: 'secondary',
    margin: 'auto'
  }
}

export { config as ListTargetsTypesConfig }
