/*
  Devuelve los tipo de reglas que se pueden aplicar a un tipo de Objetivo, RuleDefs
*/
import CurrencyDecimals from '../../libs/CurrencyDecimals';

export function getRulesDefinitionsByTypeFinal(baseUrl, type, token) {
  //console.log("RuleAPI.getRulesDefinitionsByType");
  return fetch(
    baseUrl + 'targetTypes/identifier/' + type + '/ruleDefinitions',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getRulesDefinitionsByType(
  baseUrl,
  type,
  token,
  state,
  flowParams,
) {
  //console.log("RuleAPI.getRulesDefinitionsByType");
  return new Promise((resolve, reject) => {
    //console.log("RuleAPI.getRulesDefinitionsByType");
    getRulesDefinitionsByTypeFinal(baseUrl, type, token)
      .then(response => {
        if (response.status === 200) {
          const data = response.json();
          //console.log("RuleAPI.getRulesDefinitionsByType", data);
          return data;
        } else {
          //console.log("RuleAPI.getRulesDefinitionsByType reject");
          reject(response.status);
        }
      })
      .then(data => {
        //console.log("RulesAPI.getRulesDefinitionsByType", data);

        resolve(changeEvoText(data, type, state, flowParams));
      })
      .catch(error => {
        console.error(
          'RulesAPI.getRulesDefinitionsByType: Error getting the user RulesDef',
          error,
        );
        reject(error);
      });
  });
}

/*
  Devuelve los tipos de reglas que se pueden aplicar a un tipo de Objetivo, RuleDefs
*/
export function getRuleDefinitionForTargetType(baseUrl, type, id, token) {
  return fetch(
    baseUrl + 'targetTypes/identifier/' + type + '/ruleDefinitions/' + id,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

/*
  Devuelve todos los tipos de reglas posibles, RulDefs
*/
export function getRulesDefinitionsFinal(baseUrl, token) {
  //console.log("RulesAPI.getRulesDefinitionsFinal");
  return fetch(baseUrl + 'ruleDefinitions', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

/*
  Capa para cambiar textos para EVO sin tener q hacerlo en la API
*/

export function getRulesDefinitions(baseUrl, idUser, idDefinition, token) {
  console.log('RuleAPI.getRulesDefinitions');
  return new Promise((resolve, reject) => {
    //console.log("RuleAPI.getRulesDefinitions");
    getRulesDefinitionsFinal(baseUrl, idUser, idDefinition, token)
      .then(response => {
        if (response.status === 200) {
          const data = response.json();
          //console.log("RuleAPI.getRulesDefinitions", data);
          return data;
        } else {
          //console.log("RuleAPI.getRulesDefinitions reject");
          reject(response.status);
        }
      })
      .then(data => {
        //console.log("RulesAPI.getRulesDefinitions", data);
        resolve(changeEvoText(data));
      })
      .catch(error => {
        console.error(
          'RulesAPI.getRulesDefinitions: Error getting the user RulesDef',
          error,
        );
        reject(error);
      });
  });
}

/*
  Devuelve una regla en concreto de un usuario, Rule
*/
export function getRule(baseUrl, id, token) {
  return fetch(baseUrl + 'rules/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

/*
  Devuelve las reglas de un objetivo de un usuario, Rules  
*/
export function getTargetRulesFinal(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/rules', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getTargetRules(baseUrl, id, token) {
  //console.log("RuleAPI.getTargetRules");
  return new Promise((resolve, reject) => {
    getTargetRulesFinal(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          //console.log("getTargetRules1", response);
          return response.json();
        } else {
          //console.log("getTargetRules2", response);
          throw response.status;
        }
      })
      .then(data => {
        //console.log('RulesAPI.getTargetRules: ', data);
        data.forEach((element, index, array) => {
          if (element.ruleDefinition.identifier === 'PUNTUAL_SAVING') {
            data[index].ruleDefinition.name = 'Aportación inmediatas';
          } else if (element.ruleDefinition.identifier === 'PERIODIC_SAVING') {
            data[index].ruleDefinition.name = 'Aportación periódica';
          }
        });
        //console.log('RulesAPI.getTargetRules2: ', data);

        resolve(data);
      })
      .catch(error => {
        console.log('error recuperando las reglas del target ', error);
      });
  });
}

/*
  Devuelve los movimientos de una regla de un usuario, RulesExecutions
*/
export function getRuleMovements(baseUrl, id, token) {
  return fetch(baseUrl + 'rules/' + id + '/ruleExecutions', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

/*
  Carga la reglas de un usuario de un tipo de regla, Rules
*/
export function getRulesByDefinition(baseUrl, idUser, idDefinition, token) {
  console.log('RuleAPI.getRulesByDefinition');
  return fetch(
    baseUrl + 'users/' + idUser + '/ruleDefinition/' + idDefinition + '/rules',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

/*
export function getRulesByDefinition(baseUrl, idUser, idDefinition, token) {
  return new Promise((resolve, reject) => {
    //console.log("RuleAPI.getRulesByDefinition");
    getRulesByDefinitionFinal(baseUrl, idUser, idDefinition, token)
      .then(response => {
        if (response.status === 200) {
          const data = response.json();
          console.log("RuleAPI.getRulesByDefinition", data);
          resolve(data);        
        } else {
          console.log("RuleAPI.getRulesByDefinition reject");
          reject(response.status);
        }
      })
      .catch(error => {
        console.error("RulesAPI.getRulesByDefinition: Error getting the user Rules", error);
      })
  })
}
*/

/*
  Activa una regla de un objetivo
*/
export function setActive(baseUrl, id, data, token) {
  return fetch(baseUrl + 'rules/' + id + '/isActive', {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getAutocomplete(baseUrl, id, field, query, token) {
  return fetch(
    baseUrl +
      'ruleDefinitions/' +
      id +
      '/fields/' +
      field +
      '/autocomplete?search=' +
      query,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function setRule(baseUrl, id, data, token) {
  console.log({ data });
  return fetch(baseUrl + 'targets/' + id + '/rules', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

function changeEvoText(data, type, state, flowParams) {
  //console.log("changeEvoText", data)

  data.forEach((element, index, array) => {
    if (element.identifier === 'COINSCRAP_ROUNDS') {
      data[index].title = 'Ahorra mientras gastas';
    } else if (element.identifier === 'PERIODIC_SAVING') {
      data[index].title = 'Ahorra mes a mes';
      if (type === 'retirement' && flowParams) {
        data[
          index
        ].description = `Para darle vida a tus ilusiones del mañana, tu simulación ha calculado que debes aportar mensualmente ${
          flowParams.mode === 'retirementManual'
            ? CurrencyDecimals(state.manual.monthlyContribution, true, false)
            : CurrencyDecimals(
                state.simulation.monthlyContribution,
                true,
                false,
              )
        }. 
          
          Recuerda que la aportación mínima son 30€.`;
      } else {
        data[index].description =
          'Para darle vida a tus ilusiones del mañana necesitas hacer una aportación mínima de 30€.';
      }
    } else if (element.identifier === 'PUNTUAL_SAVING') {
      data[index].name = 'Aportación inmediata';
      data[index].title = '¿Quieres hacer un ahorro puntual?';
    } else if (element.identifier === 'NOMINA_SAVING') {
      data[index].name = '% de ingresos';
      data[index].title =
        'Que no solo sume tu cuenta corriente haz crecer tu objetivo';
    } else if (element.identifier === 'CANCER_DONATION') {
      data[index].title = 'Dona contra el cancer';
      data[index].description =
        '¿Quieres donar a una buena causa un % de tu ahorro? La Hucha Inteligente EVO dona el 100% de tu aportación mensual a la lucha contra el cáncer. Gracias a tu aportación ayudarás a personas enfermas y a sus familias además de financiar proyectos de investigación que permitirán un mejor diagnóstico y tratamiento.';
    } else if (element.identifier === 'COFFEE_SAVING') {
      data[index].title = 'Ahorrate el café';
      data[index].description =
        'Cada vez que tomes un café de menos, que no le va nada bien a tu insomnio, premiate ahorrando 1€ y ve crecer tu monedero.';
    } else if (element.identifier === 'SMOKE_SAVING') {
      data[index].title = 'Recupera tu salud';
    } else if (element.identifier === 'TEAM') {
      data[index].title = 'Ahorra con tu equipo';
    }
  });

  //console.log("changeEvoText resultado", data)
  return data;
}
