function generateStyles(theme) {
  const styles = {
    pickerContainer: {
      zIndex: '10001',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
    'pickerContainer, pickerContainer *, pickerContainer *:before, pickerContainer *:after': {
      boxSizing: 'border-box',
    },
    pickerInner: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      padding: '0 20px',
      fontSize: '1.2em',
      W:
        'linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent)',
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
    pickerColumn: {
      flex: '1 1',
      position: 'relative',
      maxHeight: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
    pickerScroller: {
      transition: '300ms',
      transitionTimingFunction: 'ease-out',
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
    pickerItem: {
      textAlign: 'center',
      fontSize: '16px',
      position: 'relative',
      whiteSpace: 'nowrap',
      color: 'rgba(255,255,255,.3)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
    pickerItemSelected: {
      textAlign: 'center',
      marginTop: '3px',
      color: theme.palette.primary.main,
      fontSize: '24px',
      fontWeight: 100,
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
    pickerHighlight: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      width: '72px',
      pointerEvents: 'none',
      borderTop: 'solid 1px rgba(255,255,255,.3)',
      borderBottom: 'solid 1px rgba(255,255,255,.3)',
      '&::after': {
        boxSizing: 'border-box',
      },
      '&::before': {
        boxSizing: 'border-box',
      },
    },
  };
  return styles;
}

export default generateStyles;
