export const followupTarget_strings = {
    es: {
        followupMovements_header_text: 'LISTADO DE MOVIMIENTOS',
        followupMovementsDetails_header_text: ' DETALLES DE MOVIMIENTO',
        followupMovementsDetailsLines_header_text: 'DETALLES DE LA REGLA',
        followupRules_header_text: 'CREAR REGLA',
        followupRules_modal_text1: 'No hay nuevas reglas para este objetivo',
        followupRules_modal_button: 'Ok',
        followupRulesMovements_header_text: 'Movimientos'
    }
}