import React, { Component } from 'react';
import { List } from '../../../ui/index';
import { Utils } from '../../../resources/index';
import { listTargetTypes_strings } from './text';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

class ListTargetsTypes extends Component {
  constructor(props) {
    super(props);
    const object = {};
    this.props.types.map(type => {
      object[type.identifier] = false;
      return null;
    });
    this.state = {
      show: object,
    };
  }

  toggle(identifier, event) {
    event.preventDefault();
    const object = this.state.show;
    object[identifier] = !object[identifier];
    this.setState({ show: object });
  }

  handleSelect(identifier, event) {
    event.preventDefault();
    if (!this.props.doSurvey || identifier === 'nextWish') {
      this.props.history.push(this.props.base + identifier);
    } else {
      this.props.history.push({
        pathname: '/survey',
        state: { next: this.props.base + identifier },
      });
    }
  }

  renderListItems() {
    const { classes, config, types } = this.props;
    const transformClasses = Utils.transformClasses;
    const typesArray = types.map(target => {
      return (
        <div
          key={target.identifier}
          className={classes.targetsTypesList_container}
        >
          <ListItem
            {...config.listItem}
            key={target.identifier}
            onClick={e => this.toggle(target.identifier, e)}
            classes={transformClasses('listTargetsTypes_listItem1', classes)}
          >
            <div
              className={classes.listTargetsTypes_divImage}
              style={{
                backgroundImage: this.state.show[target.identifier]
                  ? 'url(' + target.visitedImage + ')'
                  : 'url(' + target.image + ')',
              }}
            />
          </ListItem>
          <Collapse
            {...config.collapse}
            in={this.state.show[target.identifier]}
            classes={transformClasses('listTargetsTypes_collapse1', classes)}
          >
            <Card
              {...config.card}
              classes={transformClasses('listTargetsTypes_card1', classes)}
            >
              <CardContent
                {...config.cardContent}
                classes={transformClasses(
                  'listTargetsTypes_cardContent1',
                  classes,
                )}
              >
                <Typography
                  {...config.typography1}
                  classes={transformClasses(
                    'listTargetsTypes_typography1',
                    classes,
                  )}
                >
                  {target.name}
                </Typography>
                <Typography
                  {...config.typography2}
                  classes={transformClasses(
                    'listTargetsTypes_typography1',
                    classes,
                  )}
                >
                  {target.description}
                </Typography>
              </CardContent>
              <CardActions
                {...config.cardActions}
                classes={transformClasses(
                  'listTargetsTypes_cardActions1',
                  classes,
                )}
              >
                <Button
                  {...config.button}
                  onClick={e => this.handleSelect(target.identifier, e)}
                  classes={transformClasses(
                    'listTargetsTypes_button1',
                    classes,
                  )}
                >
                  {listTargetTypes_strings['es'].listTargetTypes_button_text}
                </Button>
              </CardActions>
            </Card>
          </Collapse>
        </div>
      );
    });
    return typesArray;
  }

  render() {
    const { classes, listConfig, config } = this.props;
    const customConfig = {
      ...listConfig.list,
      ...config.list,
      children: this.renderListItems(),
    };
    return <List config={customConfig} classes={classes} />;
  }
}

export default ListTargetsTypes;
