function generateStyles(theme){
    const styles = {
        listTargetMovementsDetails_divListContainer:{
           // height: '55vh',
            //minHeight: 312,
            overflow: 'auto',
            //background: theme.palette.primary.main
        },
        listTargetMovementsDetails_typography1_root: {
            fontSize: '16px',
            color: theme.palette.primary.main
        },
        listTargetMovementsDetails_typography2_root: {
            fontSize: '16px',
            color: theme.palette.primary.main
        },
        listTargetMovementsDetails_iconButton1_root:{
            padding: 'unset'
        }
    }
    return styles
}

export {
    generateStyles as ListTargetMovementsDetailsStyles
}