function portfolio() {
  return {
    title: 'Moderado',
    $$hashKey: 'object:117',
    benchmark: { id: 'imff', name: 'FI Renta Fija Mixta Internacional' },
    equity: 0,
    historicPerformance: 5.1783,
    id: 'a2106752-04e1-11e7-8555-080027628e9b',
    minAmount: 100,
    minTimeframe: 3,
    name: 'Moderado',
    performance: 5.27,
    productId: 'evo_caser_unit_linked',
    recommended: false,
    selected: false,
    tolerance: 2,
    typicalDeviation: {
      1: 6.7095,
      2: 4.6235,
      3: 3.754,
      4: 3.3499,
      5: 2.9877,
      6: 2.6779,
      7: 2.4954,
      8: 2.4096,
      9: 2.2865,
      10: 2.21,
      11: 2.1773,
      12: 2.1074,
      13: 2.0086,
      14: 1.8971,
      15: 1.8057,
      16: 1.7312,
      17: 1.6778,
      18: 1.6088,
      19: 1.5256,
      20: 1.4492,
      21: 1.3696,
      22: 1.3067,
      23: 1.2331,
      24: 1.1149,
      25: 1.0114,
      26: 0.9343,
      27: 0.8745,
      28: 0.8242,
      29: 0.7632,
      30: 0.6732,
      31: 0.5318,
      32: 0.4143,
      33: 0.3315,
      34: 0.1924,
      35: 0.1886,
      36: 0.242,
      37: 0.2652,
      38: 0.2625,
      39: 0.2259,
      40: 0.1473,
      default: 0.1473,
    },
  };
}

export default portfolio;
