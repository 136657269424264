import { Component } from 'react'


class CargosInitialConnect extends Component{
    render(){
        return(
                this.props.renderInputs()
            
        )
    }
}

export default CargosInitialConnect