import React, { useState, Fragment } from 'react';

// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import MobileStepper from '@material-ui/core/MobileStepper';

// import Grow from '@material-ui/core/Grow';
// import Modal from '@material-ui/core/Modal';
// import InfoIcon from '@material-ui/icons/Info';
// import Typography from '@material-ui/core/Typography';

// //import { UsersAPI } from '../../core/
// import { UsersAPI } from '../../../src-core/sdk/index';

// import Currency from '../../../components/Currency';
// import SwipeableViewsConfigurableStyles from '../../swipeableViews/swipeableViewsConfigurable.style';
// import MyText from '../../../components/MyText';
// import MyTextForInput from '../../../components/MyTextForInput';
// import SwipeableViewsConfigurable from '../../swipeableViews/swipeableViewsConfigurable.component';
// import MyTabs from '../../../components/MyTabs';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import Footer from '../../../components/MyFooter';
import Flow from '../../../components/Flow';
// import Simulator from './ScenariosSimulator';
// import Config from './ScenariosConfig';

import SwipeableViews from 'react-swipeable-views';
// import ProductCard from './ProductCard';

function SimulatorPensionPlanInput(props) {
  const [anhos, setAnhos] = useState('');
  const [salario, setSalario] = useState('');
  const [anhosCotizados, setAnhosCotizados] = useState('');
  const [ahorrado, setAhorrado] = useState('');
  const [deuda, setDeuda] = useState('');
  const [conyuge, setConyuge] = useState('');
  const [anhosConyuge, setAnhosConyuge] = useState('');
  const [tengoHijos, setTengoHijos] = useState('');

  const [tabIndex, setTabIndex] = useState(0);

  // const newConfig = {
  //   // ...swipeableConfig,
  //   containerStyle: {
  //     width: 250,
  //   },
  // };

  const myDivStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '0px solid #fff',
    borderRadius: 7,
    width: '95%',
    height: '100%',
    color: '#fff',
    fontSize: '1rem',
    //textAlign: 'left',
    padding: '1rem',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  };

  const lineStyle = { color: '#fff', fontSize: 14, padding: 15 };

  return (
    <Fragment>
      <Header
        title='Mi Pensión'
        rigthAction={() => {
          props.history.goBack();
        }}
      />

      <Body>
        <SwipeableViews
          index={tabIndex}
          onChangeIndex={index => {
            setTabIndex(index);
          }}
          style={{
            width: '100%',
            height: '100%',
            minHeight: 250,
            //padding: '0px 15px',
          }}
        >
          {/* Panel 1 ******************************************/}
          <div id='ds1' style={myDivStyle}>
            <div style={lineStyle}>
              Tengo
              <Input
                id='anhos'
                //ref={inputImpInicialRef}
                classes={{
                  input: props.classes.inputNumberStyle,
                }}
                style={{
                  width: 50,
                  margin: '0 0.5rem',
                }}
                type='number'
                value={anhos}
                placeholder='Ej: 34'
                onChange={e =>
                  setAnhos(
                    !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : '',
                  )
                }
              />
              años
            </div>

            <div style={lineStyle}>
              cobro
              <Input
                id='salario'
                //ref={inputImpInicialRef}
                classes={{
                  input: props.classes.inputNumberStyle,
                }}
                style={{
                  width: 62,
                  margin: '0 0.5rem',
                }}
                type='number'
                value={salario}
                placeholder='Ej: 25.000'
                onChange={e =>
                  setSalario(
                    !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : '',
                  )
                }
              />
              euros anuales
            </div>

            <div style={lineStyle}>
              y llevo
              <Input
                id='anhosCotizados'
                //ref={inputImpInicialRef}
                classes={{
                  input: props.classes.inputNumberStyle,
                }}
                style={{
                  width: 50,
                  margin: '0 1rem',
                }}
                type='number'
                value={anhosCotizados}
                placeholder='Ej: 15'
                onChange={e => {
                  console.log('**********' + parseInt(e.target.value));
                  setAnhosCotizados(
                    !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : '',
                  );
                }}
              />
              años cotizados
            </div>
          </div>

          <div id='ds2' style={myDivStyle}>
            <div style={lineStyle}>
              Tengo ahorrado
              <Input
                id='ahorrado'
                //ref={inputImpInicialRef}
                classes={{
                  input: props.classes.inputNumberStyle,
                }}
                style={{
                  width: 50,
                  margin: '0 1rem',
                }}
                type='number'
                value={ahorrado}
                placeholder='Ej: 47.000'
                onChange={e =>
                  setAhorrado(
                    !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : '',
                  )
                }
              />
              euros
            </div>
            <div style={lineStyle}>
              y debo
              <Input
                id='deuda'
                //ref={inputImpInicialRef}
                classes={{
                  input: props.classes.inputNumberStyle,
                }}
                style={{
                  width: 62,
                  margin: '0 1rem',
                }}
                type='number'
                value={deuda}
                placeholder='Ej: 25.000'
                onChange={e =>
                  setDeuda(
                    !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : '',
                  )
                }
              />
              euros al banco
            </div>
          </div>

          {/* Panel 3 ******************************************/}
          <div id='ds3' style={myDivStyle}>
            <div style={lineStyle}>
              <Select
                style={{ color: '#900', marginRight: '0.5rem', width: 69 }}
                classes={{}}
                value={conyuge}
                onChange={e => {
                  setConyuge(e.target.value);
                }}
              >
                <MenuItem value={'si'}>Sí</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
              </Select>
              tengo cónyuge
            </div>
            <div style={lineStyle}>
              y tiene
              <Input
                id='anhoconyuge'
                //ref={inputImpInicialRef}
                classes={{
                  input: props.classes.inputNumberStyle,
                }}
                style={{
                  width: 50,
                  margin: '0 1rem',
                }}
                type='number'
                value={anhosConyuge}
                placeholder='Ej: 34'
                onChange={e =>
                  setAnhosConyuge(
                    !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : '',
                  )
                }
              />
              años
            </div>
            <div style={lineStyle}>
              <Select
                style={{ color: '#900', marginRight: '0.5rem', width: 69 }}
                classes={{}}
                value={tengoHijos}
                onChange={e => {
                  setTengoHijos(e.target.value);
                }}
              >
                <MenuItem value={'si'}>Sí</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
              </Select>
              tengo hijos
            </div>
          </div>
        </SwipeableViews>

        <MobileStepper
          variant='dots'
          steps={3}
          position='static'
          style={{
            // border: '1px solid #f60',
            background: 'transparent',
          }}
          activeStep={tabIndex}
          // classes={transformClasses('targetProducts_stepper', classes)}
        />
      </Body>

      <Footer>
        {tabIndex !== 2 && (
          <Button
            variant='contained'
            color='secondary'
            style={{
              margin: 10,
            }}
            onClick={() => {
              setTabIndex(tabIndex + 1);
            }}
          >
            Siguiente
          </Button>
        )}

        {tabIndex === 2 && (
          <Button
            variant='contained'
            color='secondary'
            style={{
              margin: 10,
            }}
            //classes={transformClasses('targetProducts_button1', classes)}
            onClick={() => {
              Flow.navigate('/product/simulatorPensionPlanResult', props, {});
            }}
          >
            Calcular
          </Button>
        )}
      </Footer>
    </Fragment>
  );
}

const styles = theme => ({
  modal: {
    width: '312px',
    backgroundColor: 'rgba(65,65,65,1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px',
    borderRadius: '8px',
    '&:focus': {
      outline: 'unset',
    },
  },

  inputNumberStyle: {
    color: 'white',
    padding: '4px 0 1px',
    borderBottomColor: 'white',
    borderBottomWidth: 1,
    borderBottomStyle: 'double',
    textAlign: 'right',
  },
  //...ProductsStyles(theme),
  // ...SwipeableViewsConfigurableStyles(theme),
});

export default withStyles(styles, { withTheme: true })(
  SimulatorPensionPlanInput,
);
