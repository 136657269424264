export const targesRefundInfo_strings = {
    es: {
        targesRefundInfo_info_text1: 'refund creado correctamente',
        targesRefundInfo_info_text2: 'AHORRADO',
        targesRefundInfo_info_text3: 'DISPONIBLE',
        targesRefundInfo_info_text4: 'Introduce la cantidad que quieras reembolsar',
        targesRefundInfo_info_text5: 'Debido a cuestiones legales de las normas SEPA sólo puedes reembolsar cargos efectuados desde hace 56 días.',
        targesRefundInfo_info_text6: 'Para más información',
        targesRefundInfo_info_text7: 'REEMBOLSO PARCIAL',
        targesRefundInfo_mail_text: 'contratos@coinscrap.com',
        targesRefundInfo_button_text1: 'Solicitar reembolso total',
        targesRefundInfo_button_text2: 'Reembolso total no disponible',
        targesRefundInfo_button_text3: 'Aplicar reembolso parcial',
        targesRefundInfo_button_text4: 'Reembolso parcial no disponible'
    }
}