import React from 'react';
import config from './config';

export default function Tareas(props) {
  return (
    <svg
      width='15px'
      height='15px'
      viewBox='0 0 15 15'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ ...props.style }}
    >
      <title>ic_lapiz</title>
      <g
        id='Page-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='ic_lapiz'
          transform='translate(-4.000000, -4.000000)'
          fill={props.style.color || 'white'}
          fill-rule='nonzero'
        >
          <path
            d='M8.18426052,6.56210307 L8.14151831,8.68875777 C8.13689432,8.71587304 8.13464506,8.7290628 8.13253894,8.7386374 C8.13435158,8.75054456 8.13619025,8.76860229 8.1400466,8.80647572 L8.00087369,15.8711196 C7.97659227,17.1703784 8.45913353,18.3812189 9.36020781,19.2822932 L10.8719729,20.7940583 C11.1544061,21.0764915 11.6222886,21.0672743 11.9160732,20.7734896 L13.4885986,19.2009643 C14.4259232,18.2636397 14.9565326,17.032819 14.9841803,15.7317376 L15.1675436,6.4239093 C15.204776,4.50043151 13.6689228,2.96412643 11.7457261,3.00063754 C9.8216655,3.03991746 8.22413952,4.63671158 8.18426052,6.56210307 Z M13.5078797,15.7594424 C13.4885616,16.6649628 13.1177727,17.5247269 12.4650669,18.1774327 L11.4248761,19.2176235 L10.4248768,18.2176242 C9.79750627,17.5902537 9.460166,16.7445205 9.47650043,15.8402384 L9.6024662,9.44600459 L13.633785,9.36658801 L13.5078797,15.7594424 Z M13.6912199,6.45278249 L13.6629005,7.89032368 L9.63158049,7.96974029 L9.63621642,7.73441257 L9.65987219,6.53360889 C9.68250881,5.42317514 10.6050986,4.50106984 11.715391,4.47839896 C12.826044,4.45724428 13.7123204,5.34301677 13.6912199,6.45278249 Z'
            transform='translate(11.584103, 12.000000) scale(-1, 1) rotate(-315.000000) translate(-11.584103, -12.000000) '
          ></path>
        </g>
      </g>
    </svg>
  );
}
