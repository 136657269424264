const config = {
  listItem: {
    button: true,
    disableGutters: true,
  },
  typography1: {
    variant: 'body1',
    color: 'primary',
  },
  typography2: {
    variant: 'body1',
    color: 'primary',
  },
  collapse: {
    timeout: 'auto',
    unmountOnExit: true,
  },
  button: {
    size: 'large',
    variant: 'contained',
    color: 'secondary',
  },
  typographyTitle1: {
    variant: 'h5',
    color: 'secondary',
  },
  secondSlideInput1: {
    type: 'number',
  },
};

export default config;
