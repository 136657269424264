import React, { Component } from 'react';

import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { Utils } from '../../src-core/resources/index';

import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class CreateTargetStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    console.log('CreateTargetStart.componentDidMount ', {
      survey: this.props.doSurvey,
      type: this.props.match.params.type,
      new: this.props.new,
      founds: Object.keys(this.props.founds).length,
    });
    if (this.props.doSurvey && this.props.match.params.type !== 'nextWish') {
      this.props.history.push({
        pathname: '/survey',
        state: {
          type: this.props.match.params.type,
        },
      });
    } else if (this.props.new) {
      if (Object.keys(this.props.founds).length > 1) {
        console.log('CreateTargetStart: went to target products 1');
        this.props.history.push(this.props.next);
      } else {
        console.log('CreateTargetStart: went to CreateTargetBasic 1');
        this.props.history.push(this.props.next2);
      }
    } else {
      this.setState({
        open: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.new) {
      if (Object.keys(this.props.founds).length > 1) {
        console.log('CreateTargetStart: went to target products 2');
        this.props.history.push(this.props.next);
      } else {
        console.log('CreateTargetStart: went to CreateTargetBasic 2');
        this.props.history.push(this.props.next2);
      }
    }
  }

  onClickNo() {
    this.setState({
      open: false,
    });
    if (Object.keys(this.props.founds).length > 1) {
      console.log('CreateTargetStart: onClickNo1', this.props.next);
      this.props.history.push(this.props.next);
    } else {
      console.log('CreateTargetStart: onClickNo2', this.props.next2);
      this.props.history.push(this.props.next2);
    }
  }

  render() {
    const { classes } = this.props;
    //console.log("TargetStartConfig.render", classes);

    const transformClasses = Utils.transformClasses;
    return (
      <Modal
        open={this.state.open}
        disablePortal
        //className={transformClasses('CreateTargetStart_modal1', classes)} //'container' //
      >
        <div className='container'>
          <Body vAlign='center'>
            <SvgIcon
              color='primary'
              classes={transformClasses('CreateTargetStart_icon', classes)}
            >
              <path
                d='M17,13.8h-2l1-1.5L17,13.8z M11.3,11.5H9l1.2-1.8L11.3,11.5z M12.9,4.5c-0.2,0.2-0.2,0.5,0,0.7l0.6,0.7
                h-2.8V3.8h2.8L12.9,4.5z M4.7,18.1l3.6-5.5H12l3.6,5.5H4.7z M16.9,18.1l-2.1-3.3h2.9l2.1,3.3H16.9z M21.2,18.4l-4.8-7.4
                c-0.1-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2l-1.6,2.5l-3.2-5V7h4c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4-0.1-0.6L14,4.9
                l1.1-1.2c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.2-0.3-0.3-0.5-0.3h-4.5C9.9,2.7,9.6,3,9.6,3.3v5.2l-6.4,9.8c-0.1,0.2-0.1,0.4,0,0.6
                c0.1,0.2,0.3,0.3,0.5,0.3h17.1c0.2,0,0.4-0.1,0.5-0.3C21.4,18.7,21.4,18.5,21.2,18.4z'
              />
            </SvgIcon>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'CreateTargetStart_typography1',
                classes,
              )}
            >
              ¿Deseas crear un nuevo objetivo?
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'CreateTargetStart_typography2',
                classes,
              )}
            >
              Ya existe un objetivo en proceso.
              {/*createTarget_strings['es'].CreateTargetStart_modal_text*/}
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'CreateTargetStart_typography2',
                classes,
              )}
              style={{ marginTop: 20 }}
            >
              “Nuevo” Crear nuevo objetivo
              {/*createTarget_strings['es'].CreateTargetStart_modal_text*/}
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'CreateTargetStart_typography2',
                classes,
              )}
            >
              “Recuperar” Continuar con el objetivo anterior
              {/*createTarget_strings['es'].CreateTargetStart_modal_text*/}
            </Typography>
          </Body>

          <Footer areButtons={true}>
            <div
              className='footerButtons'
              // style={{ backgroundColor: '#332823' }}
            >
              <Button
                id='noButton'
                variant='contained'
                color='primary'
                onClick={() => this.onClickNo()}
              >
                Recuperar
              </Button>
              <Button
                id='yesButton'
                variant='contained'
                color='secondary'
                onClick={() => {
                  this.setState({
                    open: false,
                  });
                  this.props.createNew();
                }}
              >
                Nuevo
              </Button>
            </div>
          </Footer>
        </div>
      </Modal>
    );
  }
}

const styles = theme => ({
  CreateTargetStart_container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
    backgroundImage: 'url("/images/bg_body.png")!important',
    backgroundColor: 'WHITE',
    //backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CreateTargetStart_modal1_root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CreateTargetStart_modalDiv: {
    height: '100%',
    minHeight: 640,
    width: '100%',
    //backgroundImage: 'url("/images/bg_body.png")!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    textAlign: 'center',
    position: 'relative',
  },
  CreateTargetStart_buttonDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
  CreateTargetStart_button1_root: {
    width: '152px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#5D5D5D',
    marginRight: '7px',
    marginBottom: '24px',
  },
  CreateTargetStart_button1_label: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  CreateTargetStart_button2_root: {
    width: '152px',
    height: '40px',
    borderRadius: '4px',
    marginBottom: '24px',
  },
  CreateTargetStart_button2_label: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  CreateTargetStart_typography1_root: {
    fontWeight: 200,
    fontSize: '45px',
    width: '300px',
  },
  CreateTargetStart_typography2_root: {
    fontWeight: 400,
    fontSize: '16px',
    width: '300px',
  },
  CreateTargetStart_icon_root: {
    width: '50px',
    height: '50px',
    marginTop: 50,
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetStart);
