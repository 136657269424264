import React, { Component, Fragment } from 'react';

//import Finance from 'financejs';

import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

//import Typography from '@material-ui/core/Typography';

import MyChartJS, {
  transformDataFromService,
  chartColors,
} from '../../components/MyChartJS';

import getGraphData from '../Product/Simulator/CaserImport';
const caserData = require('../Product/Simulator/CaserData.json');

class FollowupGraphHistorical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthFilter: null,
      percentModel: 10.09,
      openInfo: false,
      data: this.props.data,
    };

    console.log('FollowupGraphHistorical constr', this.props);
  }

  // // componentDidMount() {
  // //   this.getData();
  // // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.type !== this.props.type) {
  //     //this.setState({})
  //     this.getData();
  //   }
  //   if (prevState.monthFilter !== this.state.monthFilter) {
  //     this.getData();
  //   }
  // }

  // Coge los datos.
  __getData() {
    console.log('FollowupGraphProgress.getData', this.props, this.state);

    let dateFrom = null;
    const today = new Date();
    if (this.state.monthFilter) {
      let month = today.getMonth() - this.state.monthFilter;
      console.log('FollowupGraphProgress.getData month', month);

      today.setMonth(month);
      dateFrom = today;
    }

    let dataGraph, percent, amount;
    [dataGraph, percent, amount] = getGraphData(
      caserData,
      dateFrom,
      this.props.type,
    );

    this.setState({
      percent: percent,
      amount: amount,
      data: dataGraph,
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              // scaleLabel: {
              //   display: true,
              //   labelString: 'Años',
              // },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                stepSize: 0.2,
                // type: 'time',
                // time: {
                //   unit: 'month',
                // },
              },
            },
          ],
          // yAxes: [
          //   {
          //     scaleLabel: {
          //       display: true,
          //       labelString: this.props.type == 'percent' ? '%' : '€',
          //     },
          //   },
          // ],
        },
        elements: {
          line: {
            tension: 0.5,
          },
        },
      },
    });
  }

  render() {
    const buttonGroupStyle = {
      borderWidth: 1,
      borderColor: 'white',
      borderStyle: 'double',
    };

    const buttonGroupLeftStyle = {
      borderBottomLeftRadius: 20,
      borderTopLeftRadius: 20,
    };

    const buttonGroupRightStyle = {
      borderBottomRightRadius: 20,
      borderTopRightRadius: 20,
    };

    // const styles = theme => ({
    // 	radioLabel: {
    // 		fontSize: 15,
    // 		color: 'white',
    // 		marginTop: 5,
    // 		marginLeft: -8,
    // 	},
    // });

    //const { classes /*, targetID*/ } = this.props;
    //console.log('followupGraphProgress render', this.props.data);
    let data = {};
    let dataService = {};
    if (this.props.data) {
      const today = new Date();
      let since = null;
      const regex_date = new RegExp('([0-9]*)/([0-9]*)/([0-9]*)')

      console.log('monthFilter', this.state.monthFilter)

      if (this.state.monthFilter > 0) {
        since = new Date();
        since.setMonth(since.getMonth() - this.state.monthFilter);
      } else {
        since = new Date(this.props.data.data[0][0]);
        // console.log('data.label --->', this.props.data.labels[0])
        // console.log('since --->', since)
        // const [all, year, month, day] = regex_date.exec(this.props.data.labels[0][0])
        // since = new Date(`${year}/${+month+1}/${day}`);
      }
      // console.log('data.data --->', this.props.data.data[0][0])

      // console.log('filtrado antes', {
      //   data: this.props.data,
      //   today,
      //   monthFilter: this.state.monthFilter,
      //   since,
      // });
      let newData = [];
      let newLabels = [];
      // Filtra los datos

      this.props.data.labels.map((el, i) => {
        const [all, year, month, day] = regex_date.exec(el)
        // console.log(all, year, month, day, `${year}/${+month+1}/${day}`)
        // console.log(el, regex_date.exec(el), data[1], +data[2]+1, data[3] )
        
        let date = new Date(`${year}/${+month+1}/${day}`);

        if (date >= since) {
          //console.log('entra ', el, date);
          newData.push(this.props.data.data[0][i]);
          if (date.getFullYear() === today.getFullYear()) {
            // console.log('***DATE***', date, date.getDate() + '/' + date.getMonth())
            newLabels.push(date.getDate() + '/' + (+date.getMonth()+1));
            // console.log('***newLabels****', newLabels)
          } else {
            newLabels.push(date.getDate() + '/' + (+date.getMonth()+1) + '/' + date.getFullYear());
          }
        } else {
          //console.log('no entra ', el, date);
        }
        return null;
      });
      dataService = JSON.parse(JSON.stringify(this.props.data));
      //console.log('Filtrado aa', newData, newLabels);
      dataService.data[0] = newData;
      dataService.labels = newLabels;

      //console.log('Filtrado', JSON.parse(JSON.stringify(dataService)));
      [data] = transformDataFromService(dataService);
      console.log(
        'Filtrado',
        JSON.parse(JSON.stringify(dataService)),
        JSON.parse(JSON.stringify(data)),
      );

      data.datasets[0].backgroundColor = chartColors.white;
      data.datasets[0].pointRadius = 0;
      data.datasets[0].pointStyle = 'circle';
    }

    const graphType = this.props.type;

    console.log('followGraphTarget.render', data);
    return (
      <Fragment>
        <MyChartJS
          type='line'
          data={data}
          options={{
            responsive: true,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  // scaleLabel: {
                  //   display: true,
                  //   labelString: 'Años',
                  // },
                  ticks: {
                    fontColor: 'white',
                    //fontSize: 18,
                  },

                  autoSkip: true,
                  maxTicksLimit: 5,
                  stepSize: 0.2,
                  // type: 'time',
                  // time: {
                  //   unit: 'month',
                  // },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    callback: function(label, index, labels) {
                      return label + (graphType === 'percent' ? '%' : '€');
                    },

                    fontColor: 'white',
                    //fontSize: 18,
                  },

                  scaleLabel: {
                    display: false,
                    labelString: '€',
                  },
                },
              ],
            },
            elements: {
              line: {
                tension: 0.5,
              },
            },
          }}
          height={150}
        />
        <div>
          <Button
            style={{
              backgroundColor:
                this.state.monthFilter === null ? 'white' : '#00000000',
              color: this.state.monthFilter === null ? 'black' : 'white',
              ...buttonGroupStyle,
              ...buttonGroupLeftStyle,
            }}
            onClick={() => this.setState({ monthFilter: null })}
          >
            Todo
          </Button>
          <Button
            style={{
              backgroundColor:
                this.state.monthFilter === 12 ? 'white' : '#00000000',
              color: this.state.monthFilter === 12 ? 'black' : 'white',
              ...buttonGroupStyle,
            }}
            onClick={() => this.setState({ monthFilter: 12 })}
          >
            12 meses
          </Button>
          <Button
            style={{
              backgroundColor:
                this.state.monthFilter === 3 ? 'white' : '#00000000',
              color: this.state.monthFilter === 3 ? 'black' : 'white',
              ...buttonGroupStyle,
            }}
            onClick={() => this.setState({ monthFilter: 3 })}
          >
            3 meses
          </Button>
          <Button
            style={{
              backgroundColor:
                this.state.monthFilter === 1 ? 'white' : '#00000000',
              color: this.state.monthFilter === 1 ? 'black' : 'white',
              ...buttonGroupStyle,
              ...buttonGroupRightStyle,
            }}
            onClick={() => this.setState({ monthFilter: 1 })}
          >
            1 mes
          </Button>
        </div>
      </Fragment>
    );
  }
}

// const buttonGroupStyle = {
// 	borderWidth: 1,
// 	borderColor: 'white',
// 	borderStyle: 'double',
// };

// const buttonGroupLeftStyle = {
// 	borderBottomLeftRadius: 20,
// 	borderTopLeftRadius: 20,
// };

// const buttonGroupRightStyle = {
// 	borderBottomRightRadius: 20,
// 	borderTopRightRadius: 20,
// };

const styles = theme => ({
  radioLabel: {
    fontSize: 15,
    color: 'white',
    marginTop: 5,
    marginLeft: -8,
  },
});

export default withStyles(styles, { withTheme: true })(FollowupGraphHistorical);
