import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Body extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let innerContent = '';
    if (this.props.withoutInnerDiv) {
      innerContent = <Fragment>{this.props.children}</Fragment>;
    } else {
      innerContent = (
        <div
          style={{
            maxWidth: this.props.maxWidth,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 20,
          }}
        >
          {this.props.children}
        </div>
      );
    }

    return (
      <div
        className='bodyComp'
        style={{
          display: 'flex',
          overflow: 'auto',
          // overflowY: 'auto',
          // overflowX: 'hidden',
          flex: 'auto',
          width: '100%',
          //justifyContent: 'center',
          flexFlow: 'column',
          alignItems: this.props.vAlign === 'center' ? 'center' : 'flex-start',
          justifyContent:
            this.props.vAlign === 'center' ? 'center' : 'flex-start',
          ...this.props.style,
          paddingTop: 10,
          paddingBottom: 20,
        }}
        /*
        className='body'
        style={this.props.vAlign == 'center' 
          ? {
              justifyContent: 'center',
              alignItems: 'center',
            } 
          : {
              justifyContent: 'center',
              alignItems: 'flex-start',
            }
        }
      */
      >
        {/* TODO: Mejorar, entre top y center solo cambia el height=100% */}
        {this.props.vAlign === 'center' ? (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              maxWidth: this.props.maxWidth,
              width: '100%',
              //height: '100%', //
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {this.props.children}
          </div>
        ) : (
          <Fragment>
            {this.props.vAlign === 'top' ? (
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: this.props.hAlign ? this.props.hAlign : 'center',
                  justifyContent: 'start',

                  maxWidth: this.props.maxWidth,
                  width: '100%',
                  //height: '100%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                {this.props.children}
              </div>
            ) : (
              <Fragment>{innerContent}</Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

Body.propTypes = {
  // por defecto 'center'
  align: PropTypes.oneOf(['center', 'top']),
  maxWith: PropTypes.number,
  withoutInnerDiv: PropTypes.bool,
};

Body.defaultProps = {
  vAlign: 'center',
  maxWidth: 350,
  withoutInnerDiv: false,
};

/*
const styles = theme => ({
  container: {
  },
  title: {
    fontWeight: 100,
    fontSize: '35px',
    lineHeight: '45px',
    textAlign: 'center',
    color: color
  },
})
*/

//export default withStyles(styles, { withTheme: true })(Header)
export default Body;
