/*
  Objeto para tener una pila de navegación automática, para q al cerrar ventanas modales
  vuelva correctamente por el camino q hizo.
*/

class Flow {
  static navigate(url, props, params) {
    //ºº Ver si utilizar sessionStorage

    let navigationStack = window.localStorage.getItem('navigationStack');
    let navigationParams = window.localStorage.getItem('navigationParams');
    // console.log('Flow.Navigate 1', {
    //   props,
    //   params,
    //   //navigationStack,
    //   navigationParams,
    // });

    navigationParams = navigationParams ? JSON.parse(navigationParams) : null;

    if (!navigationStack) {
      // Si se crea la pila, añade la url actual como inicial
      navigationStack = [props.history.location.pathname];
    } else {
      navigationStack = JSON.parse(navigationStack);
      if (
        props.location &&
        navigationStack[navigationStack.length - 1] !== props.location.pathname
      ) {
        navigationStack.push(props.location.pathname);
      }
    }

    // Añade a la pila de navegación
    navigationStack.push(url);
    navigationParams = {
      ...navigationParams,
      ...params,
    };

    this.setStack(navigationStack);
    this.setParams(navigationParams);

    // console.log('Flow.navigate 2', url, navigationParams);
    // Ojo: No se puede enviar en el param state, el propio props.history, sino rompe.
    props.history.push({
      pathname: url,
      state: navigationParams,
    });
  }

  static goBack(history) {
    const navigationStack = JSON.parse(
      window.localStorage.getItem('navigationStack'),
    );
    const navigationParams = JSON.parse(
      window.localStorage.getItem('navigationParams'),
    );

    console.log('Flow.goBack', { navigationStack, navigationParams });

    navigationStack.pop();
    this.setStack(navigationStack);
    //const back = navigationStack[navigationStack.length - 1];

    if (!history) {
      console.error(
        'Se llamó <Header modal={true}> y no se envio el parametro history={params.history}',
      );
    }
    // console.log('Flow.goBack fin', {
    //   back,
    //   navigationStack,
    //   navigationParams,
    //   history,
    // });

    /*
    delete navigationParams.history;
    history.push({
      pathname: back,
      state: navigationParams,
    });
    */
    history.goBack();
  }

  static setParams(params) {
    const prevParams = this.getParams();

    window.localStorage.setItem(
      'navigationParams',
      JSON.stringify({ ...prevParams, ...params }),
    );
    console.log('Flow.setParams', params, { ...prevParams, ...params });
  }

  static getParams() {
    const params = JSON.parse(window.localStorage.getItem('navigationParams'));

    return params ? params : {};
  }

  static setStack(stack) {
    window.localStorage.setItem('navigationStack', JSON.stringify(stack));
  }

  static initParams() {
    window.localStorage.setItem('navigationStack', JSON.stringify([]));
  }
}

export default Flow;
