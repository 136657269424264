import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Wallet = ({ style }) => {
  return (
    <div>
      <SvgIcon
        color='primary'
        viewBox='0 0 28 28'
        style={{ ...style, color: 'white', height: 36, width: 36 }}
      >
        <path d='M 27.153778,12.520278 C 27.620833,12.520278 28,12.9045 28,13.377778 v 8.005278 c 0,0.473666 -0.379167,0.857888 -0.846222,0.857888 h -1.189222 v 2.450778 C 25.964556,26.516 24.5,28 22.699833,28 H 3.264333 C 1.463778,28 0,26.516 0,24.691722 V 10.069889 C 0,8.245222 1.463778,6.761611 3.264333,6.761611 h 19.4355 c 1.800167,0 3.264723,1.483611 3.264723,3.308278 v 2.450389 z m -2.882056,12.171444 v -2.450778 h -7.788278 c -1.800166,0 -3.264333,-1.483611 -3.264333,-3.308277 v -3.104111 c 0,-1.824667 1.464167,-3.308278 3.264333,-3.308278 h 7.788278 V 10.069889 C 24.271722,9.191778 23.566667,8.477 22.699833,8.477 h -19.4355 c -0.866444,0 -1.5715,0.714778 -1.5715,1.592889 v 14.621833 c 0,0.878111 0.705056,1.592889 1.5715,1.592889 h 19.4355 c 0.866834,0 1.571889,-0.714778 1.571889,-1.592889 z m 2.035445,-4.166166 v -6.289889 h -9.823723 c -0.866055,0 -1.571888,0.714389 -1.571888,1.592889 v 3.104111 c 0,0.878111 0.705833,1.592889 1.571888,1.592889 z M 17.572722,2.275389 11.091111,5.290833 H 7.033444 L 18.404944,0 20.801278,5.290833 h -1.862389 z m 0.390834,14.991667 c 0,0.608611 -0.487278,1.1025 -1.088112,1.1025 -0.600444,0 -1.088111,-0.493889 -1.088111,-1.1025 0,-0.609 0.487667,-1.102889 1.088111,-1.102889 0.600834,0 1.088112,0.493889 1.088112,1.102889 z' />
      </SvgIcon>
    </div>
  );
};

export default Wallet;
