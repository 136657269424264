import React, { Component } from 'react';
import { Swipe } from '../../../ui/index';
import { swipeSummary_strings } from './text';
import Paper from '@material-ui/core/Paper';
import Redeem from '@material-ui/icons/Redeem';
import Typography from '@material-ui/core/Typography';
import { Utils } from '../../../resources/index';

class SwipeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      last: this.props.data.targetsInfo.length + 2,
    };
    this.renderData = this.renderData.bind(this);
  }

  renderData() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    let elements = [];
    elements = this.props.data.targetsInfo.map(element => (
      <div key={element.target._id}>
        <div className={classes.cardSummary_iconContainer}>
          <Redeem
            {...config.summaryCard.iconButton}
            classes={transformClasses('cardSummary_iconButton', classes)}
          />
        </div>
        <Paper
          {...config.summaryCard.paper}
          classes={transformClasses('cardSummary_paper', classes)}
        >
          <Typography
            {...config.summaryCard.typography1}
            classes={transformClasses('cardSummary_typography1', classes)}
          >
            {Utils.formatCurrency(element.target.progress.completed)}
          </Typography>
        </Paper>
        <Typography
          {...config.summaryCard.typography2}
          classes={transformClasses('cardSummary_typography2', classes)}
        >
          {element.savingMessage.explanation}
        </Typography>
        <Typography
          {...config.summaryCard.typography3}
          classes={transformClasses('cardSummary_typography3', classes)}
        >
          {element.savingMessage.explanationTitle}
        </Typography>
      </div>
    ));
    elements.push(
      <div key='endSummary'>
        <Typography
          {...config.lastSummary.typography1}
          classes={transformClasses('lastSummary_typography1', classes)}
        >
          {swipeSummary_strings['es'].swipeSummary_text1}
        </Typography>
        <Typography
          {...config.lastSummary.typography2}
          classes={transformClasses('lastSummary_typography2', classes)}
        >
          {Utils.formatCurrency(this.props.data.acumulatedWeek)}
        </Typography>
        <Typography
          {...config.lastSummary.typography3}
          classes={transformClasses('lastSummary_typography3', classes)}
        >
          {swipeSummary_strings['es'].swipeSummary_text2}
        </Typography>
        <Typography
          {...config.lastSummary.typography4}
          classes={transformClasses('lastSummary_typography4', classes)}
        >
          {Utils.formatCurrency(this.props.data.weekylMeanComunity)}
        </Typography>
      </div>,
    );
    elements.unshift(<div key='first' />);
    elements.push(<div key='last' />);
    return elements;
  }

  render() {
    const { classes, swipeConfig, data, theme } = this.props;
    return (
      <Swipe
        classes={classes}
        config={swipeConfig}
        renderData={this.renderData}
        data={data}
        theme={theme}
        last={this.state.last}
      />
    );
  }
}

export default SwipeSummary;
