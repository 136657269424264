const TargetRefundInfoConfig = {
  typography1: {
    variant: 'body1',
    color: 'initial',
  },
  typography2: {
    variant: 'body1',
    color: 'secondary',
  },
  typography3: {
    variant: 'body1',
    color: 'initial',
  },
  typography4: {
    variant: 'body1',
    color: 'secondary',
  },
  typography5: {
    variant: 'body1',
    color: 'initial',
  },
  typography6: {
    variant: 'body1',
    color: 'initial',
  },
  typography7: {
    variant: 'body1',
    color: 'initial',
  },
  typography9: {
    variant: 'body1',
    color: 'secondary',
  },
  gridContainer1: {
    container: true,
  },
  gridContainer2: {
    container: true,
  },
  gridItemContainer1: {
    item: true,
    container: true,
    xs: 6,
  },
  gridItemContainer2: {
    item: true,
    container: true,
    xs: 6,
  },
  gridItemContainer3: {
    item: true,
    container: true,
    xs: 12,
  },
  gridItem1: {
    item: true,
    xs: 12,
  },
  gridItem2: {
    item: true,
    xs: 12,
  },
  gridItem3: {
    item: true,
    xs: 12,
  },
  gridItem4: {
    item: true,
    xs: 12,
  },
  gridItem5: {
    item: true,
    xs: 12,
  },
  gridItem6: {
    item: true,
    xs: 12,
  },
  gridItem7: {
    item: true,
    xs: 1,
  },
  gridItem8: {
    item: true,
    xs: 11,
  },
  gridItem9: {
    item: true,
    xs: 12,
  },
  input1: {
    fullWidth: true,
    disableUnderline: true,
  },
  button1: {
    variant: 'contained',
    color: 'secondary',
  },
  button2: {
    variant: 'contained',
    color: 'secondary',
  },
};

export default TargetRefundInfoConfig;
