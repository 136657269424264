import React from 'react';
import * as InputConfig from './styles';

const Input = ({
  value,
  onChange,
  placeholder,
  disabled,
  type,
  style,
  onBlur,
}) => {
  return (
    <InputConfig.InputWrapper
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      onBlur={onBlur}
      style={{ ...style }}
    />
  );
};

export default Input;
