import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('textCurrency', {
  styles: {
    default: {
      entireComponent: {
        fontWeight: 'bold',
        fontSize: 23,
        lineHeight: 1.1,
        fontFamily: 'SantanderHeadlineBold',

        color: colors.black,
        fontWeight: 'bold',
        fontSize: 18,
      },
      decimalComponent: {
        fontFamily: 'SantanderHeadlineBold',
        width: 'max-content',
        lineHeight: 1.1,

        color: colors.black,
        fontWeight: 'bold',
        fontSize: 18,
      },
    },
    leftSt: {
      containerComponent: {
        justifyContent: 'flex-end',
      },
    },
    rigthSt: {
      containerComponent: {
        justifyContent: 'flex-start',
      },
    },
    actividadSt: {
      color: colors.blue,
      fontSize: 16,
      fontFamily: 'SantanderTextRegular',
      fontWeight: 'bold',
      paddingBottom: 0,
    },
    greyTextSt: {
      entireComponent: {
        color: colors.grey,
        fontSize: 22,
        lineHeight: '30px',
        margin: 0,
        padding: 0,
      },
      decimalComponent: {
        color: colors.grey,
      },
    },
    greenTextSt: {
      color: colors.green,
      fontSize: 22,
      entireComponent: {
        color: colors.green,
        fontWeight: 'bold',
        fontSize: 22,
      },
      decimalComponent: {
        color: colors.green,
      },
    },
  },
});
