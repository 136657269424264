import {connect} from 'react-redux'
import ConfigureTarget from '../views/configureTarget/targetsConfig.component'

function mapStateToProps(state){
    const { token, baseUrl } = state.main
    return {
        token,
        baseUrl
    }
}

const mapDispatchToProps = {

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureTarget)