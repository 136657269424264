const config = {
    listItem1: {
      disableGutters: true
    },
    iconButton1:{
      color: 'primary'
    },
    iconButton2: {
      color: 'inherit'
    },
    iconButton3: {
      color: 'secondary'
    },
    button1:{
      variant: 'contained',
      color: 'secondary'
    },
    typograpy1: {
      variant: 'body1',
      color: 'default'
    },
    typography2: {
      variant: 'body1',
      color: 'default'
    },
    typography3: {
      variant: 'body1',
      color: 'default'
    }
  }
  
  export { config as NotificationsListConfig }
  