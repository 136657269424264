import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { withStyles } from '@material-ui/core/styles';

import { ListConfig } from '../../src-core/ui/index';
import { ListStyles } from '../../src-core/ui/index';

import { RulesAPI } from '../../src-core/sdk/index';

import RulesList from '../rulesList/rulesList.component';
import RulesListConfig from '../rulesList/rulesList.config';
import RulesListStyles from '../rulesList/rulesList.style';

class HomeRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesTarget: null,
      spliced: false,
      rulesDefinitions: null,
      summaryIds: null,
    };
    console.log('HomeRules.const', props);
  }

  componentDidMount() {
    this.getTargetRules();

    this.getRulesDefinitions();
  }

  componentDidUpdate() {
    if (
      this.state.rulesTarget !== null &&
      this.state.rulesDefinitions !== null &&
      !this.state.spliced
    ) {
      let position = null;
      this.state.rulesTarget.map(rule => {
        position = null;
        this.state.rulesDefinitions.map((definition, index) => {
          if (rule.ruleDefinition._id === definition._id) {
            position = index;
          }
          return null;
        });
        if (position !== null) {
          this.state.rulesDefinitions.splice(position, 1);
        }
        return null;
      });
      this.setState({
        spliced: true,
      });
    }
  }

  /*
  RulesAPI.getTargetRules(
    this.props.baseUrl,
    this.props.match.params.id,
    this.props.token
  )
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        throw response.status
      }
    })
    .then(data => {
      console.log("getTargetRules", data);

      this.setState({
        rulesTarget: data
      })
    })
    .catch(error => {
      console.log('error recuperando las reglas de objetivo ', error)
    })
  */

  async getTargetRules() {
    const data = await RulesAPI.getTargetRules(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    );
    this.setState({
      rulesTarget: data,
    });
  }

  /*  
    RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw response.status
        }
      })
    */

  getRulesDefinitions() {
    RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token)
      .then(data => {
        console.error('HomeRules.getRulesDefinitions ', data);
        console.error(
          'ººSi se muestra esto IMPORTANTE: HAY QUE REFACTORIZARLO!!!!! ',
          data,
        );

        let summaryIds = [];
        data.forEach(rule => {
          // Si no existe la rule, la crea, sino le añade los targets
          if (!summaryIds.rules[rule._id]) {
            summaryIds.rules[rule._id] = 0;
          }
          summaryIds.rules[rule._id][rule.target] = 0;
        });
        console.log(
          'HomeRules.getRulesDefinitions summaryIds.rules',
          summaryIds.rules,
        );
        this.setState({
          rulesDefinitions: data,
          summaryIds: summaryIds.rules,
        });
      })
      .catch(error => {
        console.log('error recuperando las definiciones de reglas ', error);
      });
  }

  createRule(idRule) {
    this.props.history.push({
      pathname: '/create/rule/' + this.props.match.params.id + '/' + idRule,
      state: {
        return: '/create/rule/' + this.props.match.params.id,
      },
    });
  }

  goBack() {
    this.props.history.push(
      '/followup/' + this.props.match.params.id + '/Reglas',
    );
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.homeRules_divContainer}>
        <div className={classes.header_container}>
          <IconButton color='primary' onClick={() => this.goBack()}>
            <KeyboardArrowLeft />
          </IconButton>
        </div>
        {this.state.spliced ? (
          <RulesList
            rules={this.state.rulesDefinitions}
            classes={classes}
            theme={theme}
            config={RulesListConfig}
            listConfig={ListConfig}
            applyAction={this.createRule.bind(this)}
            summaryIds={this.props.summaryIds}
            targets={[]}
            // renderAnimations={this.returnAnimations.bind(this)}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  ...RulesListStyles(theme),
  ...ListStyles(theme),
  homeRules_divContainer: {
    height: '100%',
    minHeight: 568,
    overflow: 'auto',
  },
  header_container: {
    height: '10%',
    minHeight: 57,
    width: '100%',
    display: 'flex',
  },
});

// const animations = {
//   COINSCRAP_ROUNDS: {
//     subText: 'Ahorra mientras gastas',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: cerditoAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   PERIODIC_SAVING: {
//     subText: 'Ahorra mes a mes',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: calendarioAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   NOMINA_SAVING: {
//     subText: 'Que no solo sume tu cuenta corriente haz crecer tu objetivo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: maletinAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   CANCER_DONATION: {
//     subText: 'Dona contra el cancer',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: lazoAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   COFFEE_SAVING: {
//     subText: 'Ahorrate el café',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: cafeDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   COVID_SAVING: {
//     subText: '#QuedateEnCasa',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: quedateEnCasa.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//         viewBoxSize: '-450 -450 1500 1500',
//       },
//     },
//   },
//   SMOKE_SAVING: {
//     subText: 'Recupera tu salud',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: tabacoDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   TEAM: {
//     subText: 'Ahorra con tu equipo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: camisetaAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
//   PUNTUAL_SAVING: {
//     subText: 'Ahorro directo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: ahorroHotDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//     },
//   },
// };

export default withStyles(styles, { withTheme: true })(HomeRules);
