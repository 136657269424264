const ListTargetsUserConfig = {
  list: {
    component: 'div',
    disablePadding: true,
  },
  listItem: {
    disableGutters: true,
  },
  typography1: {
    variant: 'h6',
    color: 'primary',
  },
  typography2: {
    variant: 'h6',
    color: 'secondary',
  },
  typography3: {
    variant: 'body1',
  },
  typography4: {
    variant: 'body1',
    color: 'primary',
  },
  collapse: {
    timeout: 'auto',
    unmountOnExit: true,
  },
  button: {
    size: 'large',
    variant: 'contained',
    color: 'secondary',
    margin: 'auto',
  },
};

export default ListTargetsUserConfig;
