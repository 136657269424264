export const dataManagerList = [
  {
    id: 'G0001',
    name: 'MEDVIDA PARTNERS'
  },
  {
    id: 'G0002',
    name: 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    id: 'G0003',
    name: 'Unicorp Vida'
  },
  {
    id: 'G0006',
    name: 'BANKINTER SEGUROS VIDA'
  },
  {
    id: 'G0010',
    name: 'FIATC, MUTUA DE SEGUROS Y REASEGUROS'
  },
  {
    id: 'G0012',
    name: 'SA NOSTRA CIA DE SEGUROS DE VIDA SA'
  },
  {
    id: 'G0021',
    name: 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    id: 'G0037',
    name: 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  /* {
    id: 'G0048',
    name: 'CCM VIDA Y PENSIONES S.A. DE SEGUROS Y REASEGUROS'
  }, */
  {
    id: 'G0067',
    name: 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  /* {
    id: 'G0071',
    name: 'CASER',
  }, */
  {
    id: 'G0079',
    name: 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    id: 'G0080',
    name: 'SANTANDER PENSIONES'
  },
  {
    id: 'G0082',
    name: 'BBVA PENSIONES'
  },
  {
    id: 'G0085',
    name: 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    id: 'G0091',
    name: 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    id: 'G0105',
    name: 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    id: 'G0111',
    name: 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    id: 'G0121',
    name: 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    id: 'G0124',
    name: 'LORETO MUTUA'
  },
  {
    id: 'G0131',
    name: 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    id: 'G0133',
    name: 'GPP'
  },
  {
    id: 'G0135',
    name: 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    id: 'G0137',
    name: 'ARQUIPENSIONES SA'
  },
  {
    id: 'G0148',
    name: 'PREVISION SANITARIA NACIONAL'
  },
  {
    id: 'G0153',
    name: 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    id: 'G0154',
    name: 'Seguros El Corte Inglés Vida, Pensiones y Reaseguros, S.A.'
  },
  {
    id: 'G0162',
    name: 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    id: 'G0172',
    name: 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    id: 'G0177',
    name: 'AXA PENSIONES'
  },
  {
    id: 'G0179',
    name: 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    id: 'G0180',
    name: 'LIBERBANK PENSIONES, S.G.F.P., S.A.'
  },
  {
    id: 'G0185',
    name: 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    id: 'G0187',
    name: 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    id: 'G0190',
    name: 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    id: 'G0195',
    name: 'CAI VIDA Y PENSIONES SEGUROS Y REASEGUROS S.A.'
  },
  {
    id: 'G0197',
    name: 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    id: 'G0198',
    name: 'PUEYO PENSIONES E.G.F.P., S.A.'
  },
  {
    id: 'G0202',
    name: 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    id: 'G0207',
    name: 'FINECO PREVISION'
  },
  {
    id: 'G0211',
    name: 'SVRNE, MUTUA DE SEGUROS Y REASEGUROS A PRIMA FIJA'
  },
  {
    id: 'G0214',
    name: 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    id: 'G0217',
    name: 'CAJA LABORAL PENSIONES'
  },
  {
    id: 'G0219',
    name: 'CASER PENSIONES'
  },
  {
    id: 'G0224',
    name: 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    id: 'G0225',
    name: 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    id: 'G0230',
    name: 'AEGON ESPAÑA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS'
  },
  /* {
    id: 'G0231',
    name: 'LIBERBANK VIDA Y PENSIONES, SEGUROS Y REASEGUROS, S.A.',
  }, */
  {
    id: 'G0232',
    name: 'HERMANDAD NACIONAL DE ARQUITECTOS, ARQUITECTOS TÉCNICOS Y QUÍMICOS, MPS'
  },
  {
    id: 'G0233',
    name: 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    id: 'G0234',
    name: 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    id: 'G0236',
    name: 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    id: 'G0237',
    name: 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    id: 'G0238',
    name: 'COBAS PENSIONES, SOCIEDAD GESTORA DE FONDOS DE PENSIONES, S. A.'
  },
  {
    id: 'G0239',
    name: 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    id: 'G0240',
    name: 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    id: 'G8766',
    name: 'BWCI Pension Trustees Limited',
  },
];

export const dataPlanList = [
  {
    'id': 'N1665-FE0010-G8766',
    'name': 'BP ESPAÑA',
    'fundDescription': 'OFP BP PENSIOENFONDS',
    'managerId': 'G8766',
    'managerName': 'BWCI Pension Trustees Limited'
  },
  {
    'id': 'N1808-FE0010-G8766',
    'name': 'BP OIL ESPAÑA',
    'fundDescription': 'OFP BP PENSIOENFONDS',
    'managerId': 'G8766',
    'managerName': 'BWCI Pension Trustees Limited'
  },
  {
    'id': 'N2350-FE0010-G8766',
    'name': 'BP SOLAR ESPAÑA',
    'fundDescription': 'OFP BP PENSIOENFONDS',
    'managerId': 'G8766',
    'managerName': 'BWCI Pension Trustees Limited'
  },
  {
    'id': 'N0003-F0001-G0001',
    'name': 'PLAN DE PENSIONES DE NCR ESPAÑA S.A.',
    'fundDescription': 'INVERPENSIONES F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N1213-F0001-G0001',
    'name': 'PLAN DE PENSIONES DE RCI ESPAÑA S.A.',
    'fundDescription': 'INVERPENSIONES F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N1395-F0001-G0001',
    'name': 'Fipen II',
    'fundDescription': 'INVERPENSIONES F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4106-F0001-G0001',
    'name': 'PLAN DE PENSIONES DE TERADATA IBERIA S.L.',
    'fundDescription': 'INVERPENSIONES F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N0394-F0328-G0001',
    'name': 'EXTREMADURA, PLAN DE PENSIONES DE EMPLEADOS DE LA CAJA DE AHORROS DE EXTREMADURA',
    'fundDescription': 'CAJA DE AHORROS DE EXTREMADURA EMPLEADOS, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N3192-F0799-G0001',
    'name': 'MEDVIDA PARTNERS HORIZONTE-DECIDIDO',
    'fundDescription': 'MEDVIDA PARTNERS DECIDIDO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4979-F0799-G0001',
    'name': 'MEDVIDA PARTNERS DECIDIDO',
    'fundDescription': 'MEDVIDA PARTNERS DECIDIDO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5057-F0799-G0001',
    'name': 'MEDVIDA PARTNERS GESTIÓN MIXTA',
    'fundDescription': 'MEDVIDA PARTNERS DECIDIDO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5203-F0799-G0001',
    'name': 'MEDVIDA PARTNERS AUDAZ',
    'fundDescription': 'MEDVIDA PARTNERS DECIDIDO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N2848-F0935-G0001',
    'name': 'MEDVIDA PARTNERS FUTURO V',
    'fundDescription': 'MEDVIDA PARTNERS ALTO RENDIMIENTO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N3193-F0935-G0001',
    'name': 'MEDVIDA PARTNERS HORIZONTE-ALTO RENDIMIENTO',
    'fundDescription': 'MEDVIDA PARTNERS ALTO RENDIMIENTO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4978-F0935-G0001',
    'name': 'MEDVIDA PARTNERS ALTO RENDIMIENTO',
    'fundDescription': 'MEDVIDA PARTNERS ALTO RENDIMIENTO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5055-F0935-G0001',
    'name': 'MEDVIDA PARTNERS GESTIÓN DINÁMICA',
    'fundDescription': 'MEDVIDA PARTNERS ALTO RENDIMIENTO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5201-F0935-G0001',
    'name': 'MEDVIDA PARTNERS ACTIVO',
    'fundDescription': 'MEDVIDA PARTNERS ALTO RENDIMIENTO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4684-F1024-G0001',
    'name': 'TRESSIS CARTERA SOSTENIBLE EQUILIBRADO PP',
    'fundDescription': 'MEDVIDA PARTNERS EQUILIBRADO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4228-F1485-G0001',
    'name': 'PLAN VALOR AMBICION',
    'fundDescription': 'CAJA RURAL IMPERIAL AMBICION, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4226-F1486-G0001',
    'name': 'PLAN VALOR CRECIMIENTO',
    'fundDescription': 'CAJA RURAL IMPERIAL CRECIMIENTO, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4227-F1487-G0001',
    'name': 'PLAN VALOR SOLIDEZ',
    'fundDescription': 'CAJA RURAL IMPERIAL SOLIDEZ, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4229-F1488-G0001',
    'name': 'PLAN VALOR CONFIANZA',
    'fundDescription': 'CAJA RURAL IMPERIAL CONFIANZA, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4702-F1820-G0001',
    'name': 'TRESSIS CARTERA SOSTENIBLE CRECIMIENTO, PP',
    'fundDescription': 'MEDVIDA PARTNERS CRECIMIENTO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N3311-F1821-G0001',
    'name': 'P.P.E. TOKIO MARINE EUROPE S.A. SUCURSAL EN ESPAÑA',
    'fundDescription': 'MEDVIDA PARTNERS EMPRESARIAL 1',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5022-F1850-G0001',
    'name': 'GESCONSULT EQUILIBRADO PENSIONES, PP',
    'fundDescription': 'MEDVIDA PARTNERS PREVISION EQUILIBRADO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5054-F1850-G0001',
    'name': 'GESCONSULT 30-75 PENSIONES, P.P.',
    'fundDescription': 'MEDVIDA PARTNERS PREVISION EQUILIBRADO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4923-F1920-G0001',
    'name': 'PLAN DE PENSIONES VALOR TRANQUILIDAD 13',
    'fundDescription': 'CAJA RURAL IMPERIAL PROTECCION 2, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5113-F2041-G0001',
    'name': 'GF GOLDEN PLUS, P.P.',
    'fundDescription': 'GF AURUM FUTURO, F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5092-F2042-G0001',
    'name': 'URSUS PENSION FLEXIBLE GLOBAL PP',
    'fundDescription': 'MEDVIDA PARTNERS  AHORRO PENSION',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4987-F2054-G0001',
    'name': 'CS CRECIMIENTO FUTURO PP',
    'fundDescription': 'CS AHORRO DINÁMICO F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N4983-F2055-G0001',
    'name': 'CS RENDIMIENTO FUTURO PP',
    'fundDescription': 'CS AHORRO CONSERVADOR  F.P.',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5398-F2056-G0001',
    'name': 'DIAPHANUM RV GLOBAL PP',
    'fundDescription': 'MEDVIDA PARTNERS AHORRO DINÁMICO',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N5288-F2077-G0001',
    'name': 'FINTUP SELECCIÓN PLUS PP',
    'fundDescription': 'MEDVIDA PARTNERS AHORRO OBJETIVO III',
    'managerId': 'G0001',
    'managerName': 'MEDVIDA PARTNERS'
  },
  {
    'id': 'N0808-F0062-G0002',
    'name': 'UNIVERSIDAD DE SANTIAGO DE COMPOSTELA',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N0876-F0062-G0002',
    'name': 'AYTO. DE VILAGARCIA DE AROUSA',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N1146-F0062-G0002',
    'name': 'AYTO. VIGO Y SUS ORGANISMOS AUTONOMOS',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N1334-F0062-G0002',
    'name': 'UNIVERSIDAD DE VIGO',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N1577-F0062-G0002',
    'name': 'AYTO. DE NIGRAN',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2313-F0062-G0002',
    'name': 'XESGALICIA, SDAD.GEST.ENTD. CAPT. RIESGO',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2613-F0062-G0002',
    'name': 'EMPLEADOS DIPUTACION DE OURENSE',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2815-F0062-G0002',
    'name': 'DIPUTACION PONTEVEDRA, PP',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3066-F0062-G0002',
    'name': 'O.R.A.L.',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3295-F0062-G0002',
    'name': 'NOVAPLAN PYMES',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3398-F0062-G0002',
    'name': 'POMPAS FUNEBRES',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3411-F0062-G0002',
    'name': 'NOVAPLAN SECTOR PUBLICO',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3475-F0062-G0002',
    'name': 'EMPLEADOS DE LA AUTORIDAD PORTUARIA DE VILLAGARCIA',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3764-F0062-G0002',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE LALIN',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3500-F0062-G0002',
    'name': 'PLAN DE PENSIONES CONCELLO DE CAMBRE',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3920-F0062-G0002',
    'name': 'PONTEV. Y ORG. AUT PP',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N4344-F0062-G0002',
    'name': 'GRUP. DISTRIBARIA',
    'fundDescription': 'ABANCA EMPLEO,  F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N0002-F0151-G0002',
    'name': 'ABANCA RENTA FIJA MIXTA MODERADO PP',
    'fundDescription': 'ABANCA RENTA FIJA MIXTA MODERADO FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5293-F0151-G0002',
    'name': 'ABANCA RENTA FIJA MIXTA PERSONAL, PP',
    'fundDescription': 'ABANCA RENTA FIJA MIXTA MODERADO FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N4990-F0250-G0002',
    'name': 'PP EMPLEADOS ABANCA',
    'fundDescription': 'FONDO DE PENSIONES \'A\' DEL PERSONAL ABANCA',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N1285-F0473-G0002',
    'name': 'ABANCA RENTA VARIABLE PP',
    'fundDescription': 'ABANCA RENTA VARIABLE FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N1466-F0474-G0002',
    'name': 'ABANCA RENTA FIJA MIXTA CONSERVADOR PP',
    'fundDescription': 'ABANCA RENTA FIJA MIXTA CONSERVADOR FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2035-F0475-G0002',
    'name': 'ABANCA RENTA VARIABLE MIXTA DECIDIDO PP',
    'fundDescription': 'ABANCA RENTA VARIABLE MIXTA DECIDIDO FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2743-F0825-G0002',
    'name': 'EMPLEADOS AYTO. A CORUÑA',
    'fundDescription': 'ABANCA JUBILACIÓN IV',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N4259-F0825-G0002',
    'name': 'EMPLEADOS INSTITUTO MUNICIPAL CORUÑA ESPECTACULOS (IMCE)',
    'fundDescription': 'ABANCA JUBILACIÓN IV',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2746-F0826-G0002',
    'name': 'EMPLEADOS DEL CONCELLO DE CULLEREDO, PLAN DE PENSIONES',
    'fundDescription': 'ABANCA JUBILACION II, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2784-F0826-G0002',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL PARLAMENTO DE GALICIA',
    'fundDescription': 'ABANCA JUBILACION II, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2880-F0826-G0002',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL VALEDOR DO POBO',
    'fundDescription': 'ABANCA JUBILACION II, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2426-F0827-G0002',
    'name': 'EMPLEADOS AYUNTAMIENTO DE LUGO',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2779-F0827-G0002',
    'name': 'ABANCA CORPORACIONES',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3071-F0827-G0002',
    'name': 'ABANCA PYMES',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3775-F0827-G0002',
    'name': 'PLAN DE EMPLEADOS DEL CONCELLO DE ARTEIXO',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5159-F0827-G0002',
    'name': 'IMANTIA  SGIIC',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5355-F0827-G0002',
    'name': 'PLAN DE PENSIONES LA FE',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5438-F0827-G0002',
    'name': 'GRUPO INTERNACO',
    'fundDescription': 'ABANCA JUBILACION III, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2809-F0846-G0002',
    'name': 'ABANCA RENTA VARIABLE MIXTA DINAMICO PP',
    'fundDescription': 'ABANCA RENTA VARIABLE MIXTA DINAMICO FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N2922-F0847-G0002',
    'name': 'ABANCA RENTA FIJA FLEXIBLE PP',
    'fundDescription': 'ABANCA RENTA FIJA FLEXIBLE FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3314-F1013-G0002',
    'name': 'ADMINISTRACION LOCAL DE LUGO',
    'fundDescription': 'ABANCA JUBILACION VIII, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5471-F1014-G0002',
    'name': 'ABANCA JUBILO GARANTIZADO 2028',
    'fundDescription': 'ABANCA PREVISION SOCIAL III, F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3117-F1015-G0002',
    'name': 'EXCMA. DIPUTACION PROVINCIAL DE LA CORUÑA',
    'fundDescription': 'ABANCA JUBILACION VII, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N3914-F1131-G0002',
    'name': 'ABANCA SEGURIDAD ACTIVA 2031',
    'fundDescription': 'ABANCA Prev Social V',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N1949-F1132-G0002',
    'name': 'CORPORACIÓN RADIO E TELEVISIÓN DE GALICIA, P.P',
    'fundDescription': 'ABANCA JUBILACION XII, FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5292-F1553-G0002',
    'name': 'ABANCA Júbilo Garantizado',
    'fundDescription': 'ABANCA Previsión Social VI',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N4427-F1587-G0002',
    'name': 'ABANCA 33',
    'fundDescription': 'AHORRO PENSION CINCUENTA Y CINCO FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5500-F1661-G0002',
    'name': 'ABANCA JUBILO GARANTIZADO 2029',
    'fundDescription': 'ABANCA PREVISION SOCIAL XI, F.P.',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N5123-F1886-G0002',
    'name': 'ABANCA RENTA FIJA A CORTO PLAZO PP',
    'fundDescription': 'ABANCA RENTA FIJA A CORTO PLAZO FP',
    'managerId': 'G0002',
    'managerName': 'ABANCA VIDA Y PENSIONES de Seguros y Reaseguros, S.A.'
  },
  {
    'id': 'N0104-F0047-G0003',
    'name': 'UNIPLAN RENTA FIJA MIXTA 15',
    'fundDescription': 'UNIFONDO RENTA FIJA MIXA 15',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1165-F0053-G0003',
    'name': 'UNIPLAN RENTA VARIABLE MIXTA 70',
    'fundDescription': 'UNIFONDO RENTA VARIABLE MIXTA 70',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1780-F0065-G0003',
    'name': 'ASAJA FUTURESPAÑA, P.P.',
    'fundDescription': 'FUTURESPAÑA, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1782-F0065-G0003',
    'name': 'ASAJA ESPAÑA MEDIO, P.P.',
    'fundDescription': 'FUTURESPAÑA, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N0144-F0083-G0003',
    'name': 'LIBERBANK ESTABILIDAD P.P',
    'fundDescription': 'LIBERBANK I F.P. ABIERTO',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1166-F0088-G0003',
    'name': 'UNIPLAN RENTA VARIABLE  MIXTA 50',
    'fundDescription': 'UNIFONDO RENTA VARIABLE  MIXTA 50',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1234-F0088-G0003',
    'name': 'AHORRO PREVISION, P.P.',
    'fundDescription': 'UNIFONDO RENTA VARIABLE  MIXTA 50',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1568-F0088-G0003',
    'name': 'AGROESPAÑA, P.P.',
    'fundDescription': 'UNIFONDO RENTA VARIABLE  MIXTA 50',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1775-F0088-G0003',
    'name': 'AGROUPA, P.P.',
    'fundDescription': 'UNIFONDO RENTA VARIABLE  MIXTA 50',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1776-F0088-G0003',
    'name': 'AGROUPA PLUS, P.P.',
    'fundDescription': 'UNIFONDO RENTA VARIABLE  MIXTA 50',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1783-F0088-G0003',
    'name': 'ASAJA ESPAÑA DINAMICO',
    'fundDescription': 'UNIFONDO RENTA VARIABLE  MIXTA 50',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N0152-F0089-G0003',
    'name': 'CASTILLA LA MANCHA',
    'fundDescription': 'FONDO POPULAR DE PENSIONES',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N0939-F0384-G0003',
    'name': 'EMPLEADOS CAJA DE AHORROS DE CASTILLA LA MANCHA',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO CCM',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1343-F0471-G0003',
    'name': 'Astosan, plan de empleo',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1915-F0471-G0003',
    'name': 'Serv. G. Tribut. Huelv.,plan de empleo',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3070-F0471-G0003',
    'name': 'Ayto. Castillo Locubín,plan de empleo',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3617-F0471-G0003',
    'name': 'EMPLEADOS UNICORP VIDA',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3724-F0471-G0003',
    'name': 'UNIPLAN PYMES',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3815-F0471-G0003',
    'name': 'SERVICIOS TRIBUTARIOS DE CEUTA',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3937-F0471-G0003',
    'name': 'P.P.E. PROCASA',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4072-F0471-G0003',
    'name': 'FUNDACION CUEVA DE NERJA',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4135-F0471-G0003',
    'name': 'OBIMASA',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2683-F0471-G0003',
    'name': 'TRABAJ. AYTO. DE ALJARAQUE, PLAN DE PENSIONES',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4175-F0471-G0003',
    'name': 'UNIPLAN PROMOCION CONJUNTA',
    'fundDescription': 'Unifondo II',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1453-F0546-G0003',
    'name': 'LIBERBANK EQUILIBRADO P.P',
    'fundDescription': 'LIBERBANK II FONDO DE PENSIONES',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2019-F0575-G0003',
    'name': 'UNIPLAN RENTA VARIABLE GLOBAL',
    'fundDescription': 'UNIFONDO RENTA VARIABLE GLOBAL',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5427-F0576-G0003',
    'name': 'UNIPLAN FUTURO SOSTENIBLE, P.P.',
    'fundDescription': 'UNIFONDO PENSIONES III, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1506-F0577-G0003',
    'name': 'UNIPLAN RENTA FIJA MIXTA 30',
    'fundDescription': 'UNIFONDO RENTA FIJA MIXTA 30',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1620-F0589-G0003',
    'name': 'UNIPLAN RENTA FIJA LARGO PLAZO',
    'fundDescription': 'UNIFONDO RENTA FIJA LARGO PLAZO',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1781-F0589-G0003',
    'name': 'ASAJA ESPAÑA MODERADO, P.P.',
    'fundDescription': 'UNIFONDO RENTA FIJA LARGO PLAZO',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1379-F0747-G0003',
    'name': 'CASTILLA LA MANCHA R.V.',
    'fundDescription': 'FONDO POPULAR DE PENSIONES RENTA VARIABLE I',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2266-F0748-G0003',
    'name': 'LIBERBANK OPORTUNIDAD P.P',
    'fundDescription': 'LIBERBANK III F.P. ABIERTO',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2000-F0781-G0003',
    'name': 'Uniplan Proteccion,plan de pensiones',
    'fundDescription': 'Unifondo VI',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2997-F0782-G0003',
    'name': 'Empleados de Unicaja ,plan de empleo',
    'fundDescription': 'Unifondo V',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2482-F0809-G0003',
    'name': 'LIBERBANK TRANQUILIDAD P.P',
    'fundDescription': 'LIBERBANK IV F.P. ABIERTO',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2314-F0821-G0003',
    'name': 'FONDEMPLEO CAJA ESPAÑA',
    'fundDescription': 'FONDEMPLEO CAJA ESPAÑA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2662-F0856-G0003',
    'name': 'UNIPLAN RENTA VARIABLE EUROPA',
    'fundDescription': 'UNIFONDO RENTA VARIABLE EUROPA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2663-F0857-G0003',
    'name': 'UNIPLAN RENTA VARIABLE ESPAÑA',
    'fundDescription': 'UNIFONDO RENTA VARIABLE ESPAÑA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2890-F0876-G0003',
    'name': 'CASTILLA LA MANCHA R.F.',
    'fundDescription': 'FONDO POPULAR DE PENSIONES SEGURIDAD I',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3626-F0975-G0003',
    'name': 'FUTURESPAÑA EQUILIBRIO, P.P.',
    'fundDescription': 'FUTURESPAÑA EMPLEO, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3040-F0976-G0003',
    'name': 'FUTURESPAÑA HORIZONTE I, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE I, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3269-F0977-G0003',
    'name': 'FUTURESPAÑA HORIZONTE II, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE II, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4393-F1018-G0003',
    'name': 'UNIPLANPROTECCION 2015, P.P.',
    'fundDescription': 'UNIFONDO VII, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1014-F1019-G0003',
    'name': 'E.P.Puertos de Andalucía, plan de empleo',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3313-F1019-G0003',
    'name': 'HOSPITAL ALTO GUADALQUIVIR',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3394-F1019-G0003',
    'name': 'HOSPITAL COSTA DEL SOL',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3583-F1019-G0003',
    'name': 'HERMANOS SANCHEZ-LAFUENTE',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4211-F1019-G0003',
    'name': 'EPES',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4487-F1019-G0003',
    'name': 'AYUNTAMIENTO DE LORA DEL RIO',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4496-F1019-G0003',
    'name': 'UNED MALAGA, P.P.',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2497-F1019-G0003',
    'name': 'Eléctrica DE CADIZ, PLAN DE EMPLEO',
    'fundDescription': 'UNIFONDO VIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3703-F1020-G0003',
    'name': 'UNIPLAN HORIZONTE 2.016',
    'fundDescription': 'UNIFONDO IX',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3312-F1021-G0003',
    'name': 'ASOCIACION PRENSA DE CADIZ',
    'fundDescription': 'UNIFONDO X',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3215-F1025-G0003',
    'name': 'LIBERBANK DINAMICO P.P',
    'fundDescription': 'LIBERBANK XV F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2259-F1063-G0003',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO DE CASTILLA LA MANCHA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3534-F1063-G0003',
    'name': 'PLAN DE PENSIONES ADMINISTRACION LOCAL DE ALBACETE',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO DE CASTILLA LA MANCHA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3555-F1063-G0003',
    'name': 'PLAN DE PENSIONES DIPUTACION PROVINCIAL DE TOLEDO',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO DE CASTILLA LA MANCHA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3968-F1063-G0003',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE Toledo',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO DE CASTILLA LA MANCHA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3969-F1063-G0003',
    'name': 'PLAN DE PENSIONES AYUNT PUERTOLLANO',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO DE CASTILLA LA MANCHA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4012-F1063-G0003',
    'name': 'PLAN DE PENSIONES AYUNT TOMELLOSO',
    'fundDescription': 'FONDO PENSIONES DE EMPLEO DE CASTILLA LA MANCHA',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5333-F1266-G0003',
    'name': 'PLAN DE PENSIONES HORIZONTE PREVISIÓN 2025',
    'fundDescription': 'FONDO DE PENSIONES HORIZONTE PREVISIÓN 2025',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4025-F1269-G0003',
    'name': 'EXCMA.DIPUTACION LEON',
    'fundDescription': 'FUTURESPAÑA EMPLEO II, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3763-F1270-G0003',
    'name': 'FUTURESPAÑA HORIZONTE III, P.P.',
    'fundDescription': 'UNIFONDO 2038',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5332-F1270-G0003',
    'name': 'UNIPLAN CONTIGO 2038, PLAN DE PENSIONES',
    'fundDescription': 'UNIFONDO 2038',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N1531-F1328-G0003',
    'name': 'MEDICOS DE CASTILLA LEON',
    'fundDescription': 'CAJA ESPAÑA VIDA ASOCIADO, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N2451-F1328-G0003',
    'name': 'MEDICOS CASTILLA-LEON 75',
    'fundDescription': 'CAJA ESPAÑA VIDA ASOCIADO, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3808-F1342-G0003',
    'name': 'UNIPLAN RENTA FIJA CORTO PLAZO',
    'fundDescription': 'UNIFONDO RENTA FIJA CORTO PLAZO',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3926-F1343-G0003',
    'name': 'UNIPLAN RESPUESTA II',
    'fundDescription': 'UNIFONDO PENSIONES XII  F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5047-F1343-G0003',
    'name': 'UNIPLAN PROTECCIÓN 2025',
    'fundDescription': 'UNIFONDO PENSIONES XII  F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4320-F1376-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE V, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N3873-F1377-G0003',
    'name': 'Futurespaña Horizonte 10',
    'fundDescription': 'UNIFONDO 2030',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5331-F1377-G0003',
    'name': 'UNIPLAN CONTIGO 2030, PLAN',
    'fundDescription': 'UNIFONDO 2030',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5334-F1378-G0003',
    'name': 'PLAN DE PENSIONES HORIZONTE PREVISIÓN 2035',
    'fundDescription': 'FONDO DE PENSIONES HORIZONTE PREVISIÓN 2035',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4268-F1447-G0003',
    'name': 'DIPUTACION PROVINCIAL DE MALAGA',
    'fundDescription': 'UNIFONDO PENSIONES XV, F.P',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4386-F1447-G0003',
    'name': 'AYUNTAMIENTO DE RONDA',
    'fundDescription': 'UNIFONDO PENSIONES XV, F.P',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4387-F1447-G0003',
    'name': 'EMPROVIMA',
    'fundDescription': 'UNIFONDO PENSIONES XV, F.P',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4388-F1447-G0003',
    'name': 'PATR.RECAU.PROV.MALAGA',
    'fundDescription': 'UNIFONDO PENSIONES XV, F.P',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4249-F1494-G0003',
    'name': 'UNIPLAN RESPUESTA',
    'fundDescription': 'UNIFONDO PENSIONES XVI, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4389-F1496-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD II',
    'fundDescription': 'UNIFONDO 2046',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5336-F1496-G0003',
    'name': 'UNIPLAN CONTIGO 2046, PLAN DE PENSIONES',
    'fundDescription': 'UNIFONDO 2046',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4533-F1576-G0003',
    'name': 'UNIPLAN PROTECCION 2016 II, P.P.',
    'fundDescription': 'UNIFONDO XVIII, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4450-F1607-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD III, P.P.',
    'fundDescription': 'UNIFONDO 2054',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5337-F1607-G0003',
    'name': 'UNIPLAN CONTIGO 2054, PLAN DE PENSIONES',
    'fundDescription': 'UNIFONDO 2054',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4536-F1608-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD IV, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE VIII, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4610-F1710-G0003',
    'name': 'UNIPLAN PROTECCION 2018, P.P.',
    'fundDescription': 'UNIFONDO XIX, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4639-F1732-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD VI, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE IX F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4668-F1733-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD VII, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE X, FP',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4643-F1736-G0003',
    'name': 'Uniplan Protección 2016 III',
    'fundDescription': 'UNIFONDO PENSIONES XXI FONDO DE PENSIONES',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4642-F1737-G0003',
    'name': 'Uniplan Protección 2015 II',
    'fundDescription': 'UNIFONDO PENSIONES XX FONDO DE PENSIONES',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5046-F1737-G0003',
    'name': 'UNIPLAN PROTECCIÓN 2025 II',
    'fundDescription': 'UNIFONDO PENSIONES XX FONDO DE PENSIONES',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4689-F1787-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD VIII, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE XII, FP',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4911-F1790-G0003',
    'name': 'FUTURESPAÑA ESTABILIDAD XI, P.P.',
    'fundDescription': 'FUTURESPAÑA HORIZONTE XV, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4852-F1834-G0003',
    'name': 'Uniplan Protección 2019',
    'fundDescription': 'UNIFONDO XXVII, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4998-F1835-G0003',
    'name': 'UNIPLAN PROTECCION 2024 III, P.P.',
    'fundDescription': 'UNIFONDO XXV, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4718-F1836-G0003',
    'name': 'UNIPLAN Protección 2015  III',
    'fundDescription': 'UNIFONDO XXIII, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4797-F1837-G0003',
    'name': 'UNIPLAN Protección 2018 II',
    'fundDescription': 'UNIFONDO PENSIONES XXVI',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5494-F1838-G0003',
    'name': 'UNIPLAN HORIZONTE 2028',
    'fundDescription': 'UNIFONDO XXIV, FP',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4892-F1925-G0003',
    'name': 'UNIPLAN PROTECCIÓN 2020',
    'fundDescription': 'UNIFONDO XXVIII',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4945-F1926-G0003',
    'name': 'UNIPLAN PROTECCIÓN 2021',
    'fundDescription': 'UNIFONDO XXIX',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4968-F1927-G0003',
    'name': 'UNIPLAN PROTECCIÓN 2024',
    'fundDescription': 'UNIFONDO PENSIONES XXX F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N4982-F1928-G0003',
    'name': 'UNIPLAN PROTECCIÓN 2024 II',
    'fundDescription': 'UNIFONDO PENSIONES XXXI F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5335-F1971-G0003',
    'name': 'PLAN DE PENSIONES HORIZONTE PREVISIÓN 2045',
    'fundDescription': 'FONDO DE PENSIONES HORIZOEPREVISIÓN 2045',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5237-F2007-G0003',
    'name': 'TU PLAN LIBERBANK 2025',
    'fundDescription': 'LIBERBANK VIDA 2025, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5238-F2008-G0003',
    'name': 'TU PLAN LIBERBANK 2035',
    'fundDescription': 'LIBERBANK VIDA 2035, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N5239-F2009-G0003',
    'name': 'TU PLAN LIBERBANK 2045',
    'fundDescription': 'LIBERBANK VIDA 2045, F.P.',
    'managerId': 'G0003',
    'managerName': 'Unicorp Vida'
  },
  {
    'id': 'N0157-F0006-G0006',
    'name': 'BK RENTA FIJA LARGO PLAZO PLAN DE PENSIONES',
    'fundDescription': 'BK RENTA FIJA LARGO PLAZO FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N0158-F0007-G0006',
    'name': 'BK MIXTO 75 EUROPA BOLSA PLAN DE PENSIONES',
    'fundDescription': 'BK MIXTO 75 EUROPA BOLSA FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1149-F0436-G0006',
    'name': 'INICIATIVAS DE MEDIOS',
    'fundDescription': 'BK GESTION F.P.',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1083-F0443-G0006',
    'name': 'BK RENTA FIJA CORTO PLAZO PLAN DE PENSIONES',
    'fundDescription': 'BK RENTA FIJA CORTO PLAZO FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1180-F0452-G0006',
    'name': 'BK VARIABLE ESPAÑA PLAN DE PENSIONES',
    'fundDescription': 'BK VARIABLE ESPAÑA FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1179-F0453-G0006',
    'name': 'BK MIXTO 50 ESPAÑA BOLSA PLAN DE PENSIONES',
    'fundDescription': 'BK MIXTO 50 ESPAÑA BOLSA FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1260-F0479-G0006',
    'name': 'BK MIXTO 20 EUROPA BOLSA PLAN DE PENSIONES',
    'fundDescription': 'BK MIXTO 20 EUROPA BOLSA FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1249-F0480-G0006',
    'name': 'BANKINTER INVERSION SEGURIDAD PLAN DE PENSIONES',
    'fundDescription': 'BANKINTER INVERSION SEGURIDAD FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1777-F0649-G0006',
    'name': 'BK VARIABLE EUROPA PLAN DE PENSIONES',
    'fundDescription': 'BK VARIABLE EUROPA FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1778-F0650-G0006',
    'name': 'BK PENSION DIVIDENDO  PLAN DE PENSIONES',
    'fundDescription': 'BK PENSION DIVIDENDO FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N2257-F0726-G0006',
    'name': 'BK VARIABLE INTERNACIONAL PLAN DE PENSIONES',
    'fundDescription': 'BK VARIABLE INTERNACIONAL FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N4760-F0991-G0006',
    'name': 'BK COMPROMISO 2016 Plan de Pensiones',
    'fundDescription': 'BK COMPROMISO FFPP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N4489-F1096-G0006',
    'name': 'BK EMPRESAS II PLAN DE PROMOCION CONJUNTA',
    'fundDescription': 'BK EMPRESAS II, FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N3409-F1172-G0006',
    'name': 'BK VARIABLE AMERICA P.P',
    'fundDescription': 'BK VARIABLE AMERICA F.P.',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N3407-F1173-G0006',
    'name': 'BK VARIABLE ASIA P.P.',
    'fundDescription': 'BK VARIABLE ASIA F.P',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N3408-F1174-G0006',
    'name': 'BK REVALORIZACION EUROPA 2022 PP',
    'fundDescription': 'BK REVALORIZACION EUROPA 2022 FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N3453-F1218-G0006',
    'name': 'BK PROTECCION P.P.',
    'fundDescription': 'BK PROTECCION F.P.',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N3454-F1221-G0006',
    'name': 'BK MERCADO EUROPEO 2026 P.P.',
    'fundDescription': 'BK MERCADO EUROPEO 2026 F.P.',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N4620-F1725-G0006',
    'name': 'BANKINTER TRANQUILIDAD 2017 P.P.',
    'fundDescription': 'BANKINTER TRANQUILIDAD 2017 F.P.',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N4688-F1794-G0006',
    'name': 'BK CONFIANZA PLAN  DE PENSIONES',
    'fundDescription': 'BK CONFIANZA FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N4896-F1942-G0006',
    'name': 'BK SOLIDEZ PLAN  DE PENSIONES',
    'fundDescription': 'BK SOLIDEZ FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5156-F2062-G0006',
    'name': 'BK JUBILACION 2030 FP',
    'fundDescription': 'BK JUBILACION 2030 FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5154-F2063-G0006',
    'name': 'BK JUBILACION 2040 FP',
    'fundDescription': 'BK JUBILACION 2040 FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5299-F2112-G0006',
    'name': 'BK INDICE REVALORIZACION PLAN DE PENSIONES',
    'fundDescription': 'BK REVALORIZACION 2030 FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5306-F2113-G0006',
    'name': 'BANKINTER ROBOADVISOR DINAMICO PP',
    'fundDescription': 'BANKINTER ROBOADVISOR DINAMICO FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5305-F2114-G0006',
    'name': 'BANKINTER ROBOADVISOR MODERADO PP',
    'fundDescription': 'BANKINTER ROBOADVISOR MODERADO FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5304-F2115-G0006',
    'name': 'BANKINTER ROBOADVISOR CONSERVADOR PP',
    'fundDescription': 'BANKINTER ROBOADVISOR CONSERVADOR FP',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N5371-F2129-G0006',
    'name': 'PLAN DE PENSIONES BK MIXTO SOSTENIBLE',
    'fundDescription': 'BK MIXTO SOSTENIBLE FONDO DE PENSIONES',
    'managerId': 'G0006',
    'managerName': 'BANKINTER SEGUROS VIDA'
  },
  {
    'id': 'N1148-F0130-G0010',
    'name': 'PLANFIATC-1, PLAN DE PENSIONES',
    'fundDescription': 'FONDFIATC, FONDO DE PENSIONES',
    'managerId': 'G0010',
    'managerName': 'FIATC, MUTUA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1243-F0459-G0010',
    'name': 'PLANFIATC 5 INDIVIDUAL, PLAN DE PENSIONES',
    'fundDescription': 'FONDFIATC VARIABLE 70, F.P.',
    'managerId': 'G0010',
    'managerName': 'FIATC, MUTUA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3082-F0992-G0010',
    'name': 'PLANFIATC 6 SOLID, PLAN DE PENSIONES',
    'fundDescription': 'FONDFIATC RENTA FIJA MIXTA, F.P.',
    'managerId': 'G0010',
    'managerName': 'FIATC, MUTUA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0227-F0071-G0021',
    'name': 'Plan de Pensiones de Exel Industrial, E.P.E., S.A.',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0450-F0071-G0021',
    'name': 'Plan de Pensiones de Coprima',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0671-F0071-G0021',
    'name': 'PLAN DE PENSIONES DE TI GROUP AUTOMOTIVE SYSTEMS, S.A.',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0691-F0071-G0021',
    'name': 'Plan de Pensiones de Aigües de MatarO',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2928-F0071-G0021',
    'name': 'Plan de Pensiones de EMT de Tarragona',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4331-F0071-G0021',
    'name': 'PP Ako Electromecánica S.A.L.',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4376-F0071-G0021',
    'name': 'IGUZZINI ILLUMINAZIONE, PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 25, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0802-F0116-G0021',
    'name': 'GAMMA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 86, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1382-F0117-G0021',
    'name': 'DSM España, S.A. Plan de Pensiones',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2273-F0117-G0021',
    'name': 'PLAN DE PENSIONES DE ULTRAPOLYMERS SPAIN, SL',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2665-F0117-G0021',
    'name': 'Plan de Pensiones Bouygues Inmobiliaria, S.A.',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2761-F0117-G0021',
    'name': 'Plan de Pensiones de PromociOn Conjunta Coface Ibérica',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2789-F0117-G0021',
    'name': 'Plan de Pensiones de los Empleados de CLB BEHRING, S.A',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2818-F0117-G0021',
    'name': 'SABIC MARKETING IBERICA, S.A. Plan de Pensiones',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2889-F0117-G0021',
    'name': 'Plan de Pensiones de Johnson´s Wax Española S.A.',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2968-F0117-G0021',
    'name': 'Plan de Pensiones de Hitachi Data Systems, S.A.',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3016-F0117-G0021',
    'name': 'Plan de Pensiones de los Empleados de Omya Clariana, S.A.',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3051-F0117-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA \'COLECTIVO EMPRESAS - 1',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3134-F0117-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA \'COLECTIVO EMPRESAS - 2',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3172-F0117-G0021',
    'name': 'Euroresins, S.A. Plan de Pensiones',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4505-F0117-G0021',
    'name': 'PLAN DE PENSIONES DE EUROCHEM AGRO IBERIA, S.L.',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4817-F0117-G0021',
    'name': 'PLAN DE PENSIONES DE COMPO EXPERT SPAIN, S.L.',
    'fundDescription': 'ALFA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2427-F0118-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE TOWERS WATSON DE ESPAÑA, S.A.',
    'fundDescription': 'Pensions Caixa 88, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3489-F0118-G0021',
    'name': 'PLAN DE EMPLEADOS WILLIS, PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 88, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0933-F0121-G0021',
    'name': 'Plan de Pensiones de Aigües de Barcelona',
    'fundDescription': 'Aigües de Barcelona nou, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0351-F0124-G0021',
    'name': 'Plan de Pensiones de los Empleados de CARGILL S.L.U.',
    'fundDescription': 'Pensions Caixa 27, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3990-F0144-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE QUIRONPREVENCIÓN',
    'fundDescription': 'Pensions Caixa 4, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0244-F0145-G0021',
    'name': 'Plan Colectivo Empleados CAPB',
    'fundDescription': 'Pensions Caixa 2, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0249-F0146-G0021',
    'name': 'PLAN DE PENSIONES DE SALICRU',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0740-F0146-G0021',
    'name': 'PLAN DE PENSIONES DE LOS TRABAJADORES DEL AJUNTAMENT PREMIÀ DE MAR',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3115-F0146-G0021',
    'name': 'DIPUTACIO DE GIRONA, PLA DE PENSIONS',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5103-F0146-G0021',
    'name': 'PLAN DE PENSIONES DE FUNDACIÓ CATALUNYA-LA PEDRERA, FUNDACIÓ ESPECIAL',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3046-F0146-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE AREAS, S.A.U.',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3130-F0146-G0021',
    'name': 'PLAN DE PENSIONES DE OLYMPUS IBERA, S.A.U.',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0807-F0146-G0021',
    'name': 'PLA DE PENSIONS AIGUES DE MANRESA - CONSERVADOR',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3055-F0146-G0021',
    'name': 'P.P. COMARCAL DEL MARESME',
    'fundDescription': 'PENSIONS CAIXA 3, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5082-F0155-G0021',
    'name': 'CABK DESTINO 2050 PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 5, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5084-F0155-G0021',
    'name': 'CABK DESTINO 2050 PREMIUM PLAN PENSIONES',
    'fundDescription': 'Pensions Caixa 5, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5470-F0156-G0021',
    'name': 'PPES AUTONOMOS - ATA',
    'fundDescription': 'Pensions Caixa 6, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0230-F0157-G0021',
    'name': 'P.P. COLEGIO ECONOMISTAS MADRID',
    'fundDescription': 'COLEGIO DE ECONOMISTAS DE MADRID, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2301-F0163-G0021',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE PROMOCIÓN CONJUNTA DEL GRUPO MERCK',
    'fundDescription': 'PENSIONS CAIXA 26, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2770-F0176-G0021',
    'name': 'Plan de Pensiones Bankia Multipyme Global',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3769-F0176-G0021',
    'name': 'Plan de Pensiones Bankia Multipyme Global V',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3831-F0176-G0021',
    'name': 'Plan de Pensiones de Asociaciones para la Inserción Laboral de Personas con Discapacidad',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5121-F0176-G0021',
    'name': 'Plan de pensiones Bankia Mutipyme Global VIII',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1139-F0176-G0021',
    'name': 'BANKIA PLAN COLECTIVO',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5235-F0176-G0021',
    'name': 'BANKIA PYME MEDITERRANEO I',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4276-F0176-G0021',
    'name': 'BANKIA MULTIPYME CAUTO',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3679-F0176-G0021',
    'name': 'P.P. CORPORACIONES LOCALES',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2508-F0176-G0021',
    'name': 'P.P. PYMES IV',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5031-F0176-G0021',
    'name': 'BANKIA MULTIPYME GLOBAL VI',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5228-F0176-G0021',
    'name': 'BANKIA MULTIPYME GLOBAL VII',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5230-F0176-G0021',
    'name': 'BANKIA PYMES LEVANTE',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3638-F0176-G0021',
    'name': 'Plan de Empleo de Promoción Conjunta Granada Empresas',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3794-F0176-G0021',
    'name': 'Plan de Empleo de Promoción Conjunta Granada Organismos Públicos',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2841-F0176-G0021',
    'name': 'Plan de pensiones BANKIA PYMES MURCIA',
    'fundDescription': 'BANKIA PENSIONES PYME, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0400-F0199-G0021',
    'name': 'PLAN DE PENSIONES DE CEMENTOS PORTLAND VALDERRIVAS',
    'fundDescription': 'Ahorro Navarra, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0815-F0199-G0021',
    'name': 'Plan de Pensiones Koxka',
    'fundDescription': 'Ahorro Navarra, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3086-F0200-G0021',
    'name': 'PLAN DE PROMOCION CONJUNTA MULTIGRUPO FONDIMED',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0345-F0200-G0021',
    'name': 'PLAN DE PENSIONES DE PLAYMOBIL',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3108-F0200-G0021',
    'name': 'GRUAS GIL GIL,S.L. PLAN DE PENSIONES DE EMPLEADOS',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4703-F0200-G0021',
    'name': 'PLAN DE PENSIONES CAM ACTIVIDAD NO FINANCIERA',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5300-F0200-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS DE MEDITERRANEO VIDA',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5147-F0200-G0021',
    'name': 'UTE SICE AUPLASA ORA GRUA BENIDORM',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0759-F0200-G0021',
    'name': 'PLAN DE PENSIONES CÁMARA OF. COMERCIO, INDUSTRIA, SERVICIOS Y NAVEGACIÓN DE ALICANTE',
    'fundDescription': 'FONDIMED F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0328-F0207-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE LAS EMPRESAS DEL GRUPO NESTLÉ',
    'fundDescription': 'NESTLÉ, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0282-F0214-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA GRUPO UBE',
    'fundDescription': 'FONDOUBE PENSIONES, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0308-F0239-G0021',
    'name': 'Plan de Pensiones Casbega S.A.',
    'fundDescription': 'Casbega, S.A. Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0299-F0241-G0021',
    'name': 'PPPC de los Empleados del Grupo Naturgy',
    'fundDescription': 'FP GAS NATURAL FENOSA, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0202-F0314-G0021',
    'name': 'Plan de Pensiones de  Enthone OMI (España), S.A.',
    'fundDescription': 'Omy Internacional Sant Just Desvern, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0451-F0315-G0021',
    'name': 'Plan de Pensiones de Sanofi Aventis S.A.',
    'fundDescription': 'Sanofi Aventis Pensiones, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4595-F0315-G0021',
    'name': 'FAMAR HEALTH CARE SERVICES MADRID',
    'fundDescription': 'Sanofi Aventis Pensiones, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5458-F0315-G0021',
    'name': 'PLAN PENSIONES DE OPELLA HEALTHCARE SPAIN',
    'fundDescription': 'Sanofi Aventis Pensiones, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0384-F0323-G0021',
    'name': 'P.P.S.E.CARBUROS me<x>taLICOS',
    'fundDescription': 'S.E.CARBUROS METALICOS F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4172-F0323-G0021',
    'name': 'AIR PRODUCTS SERVICES EUROPE',
    'fundDescription': 'S.E.CARBUROS METALICOS F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3361-F0348-G0021',
    'name': 'UNIVERSIDAD MIGUEL HERNANDEZ DE ELCHE, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3571-F0348-G0021',
    'name': 'UNIVERSIDAD DE ALICANTE, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3839-F0348-G0021',
    'name': 'PLAN PENSIONES CONSORCIO BOMBEROS ALICANTE SPEIS',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3875-F0348-G0021',
    'name': 'PLAN PENSIONES AYUNTAMIENTO ALFAFAR',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3837-F0348-G0021',
    'name': 'PLAN DE PENSIONES DEL SINDIC DE GREUGES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0810-F0348-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL AYUNT. DE SANTA POLA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3836-F0348-G0021',
    'name': 'PLAN DE PENSIONES DE IDELSA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3997-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO TEULADA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3838-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO SALINAS',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3998-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO NOVELDA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4128-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE XAVEA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4264-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE SANTA EULALIA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4309-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE IBIZA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4161-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE ELS POBLETS',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4131-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE ELCHE',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4263-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE BENEIXAMA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4293-F0348-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE ASPE',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4153-F0348-G0021',
    'name': 'AYUNTAMIENTO TORREDEMBARRA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1565-F0348-G0021',
    'name': 'AYUNTAMIENTO DE XIXONA, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0746-F0348-G0021',
    'name': 'AYUNTAMIENTO DE SILLA, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3564-F0348-G0021',
    'name': 'AYUNTAMIENTO DE PUERTO LUMBRERAS, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2477-F0348-G0021',
    'name': 'AYUNTAMIENTO DE PINOSO, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2767-F0348-G0021',
    'name': 'AYUNTAMIENTO DE PILAR DE HORADADA, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1857-F0348-G0021',
    'name': 'AYUNTAMIENTO DE MONOVAR, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0979-F0348-G0021',
    'name': 'AYUNTAMIENTO DE FINESTRAT, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3601-F0348-G0021',
    'name': 'AYUNTAMIENTO DE DENIA, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3335-F0348-G0021',
    'name': 'AYUNTAMIENTO DE BUSOT, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2476-F0348-G0021',
    'name': 'AYUNTAMIENTO DE BAÑERES DE MARIOLA, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1913-F0348-G0021',
    'name': 'AYUNTAMIENTO DE ALGUAZAS, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1569-F0348-G0021',
    'name': 'AYUNTAMIENTO DE ALFAZ DEL PÍ, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3363-F0348-G0021',
    'name': 'AYUNTAMIENTO DE ALCOY, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1302-F0348-G0021',
    'name': 'AYUNTAMIENTO DE AGOST, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5411-F0348-G0021',
    'name': 'PLAN DE PENSIONES EMPLEADOS AYUNTAMIENTO DE BENIDORM',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5412-F0348-G0021',
    'name': 'PLAN DE PENSIONES DEL EXCMO. AYUNTAMIENTO DE ALTEA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4157-F0348-G0021',
    'name': 'AYUNTAMIENTO DE CIEZA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1391-F0348-G0021',
    'name': 'AYUNTAMIENTO DE LORCA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3123-F0348-G0021',
    'name': 'AYUNTAMIENTO DE LORQUÍ',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1991-F0348-G0021',
    'name': 'PLAN DE PENSIONES CORPORACIONES LOCALES-RÍO MULA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3267-F0348-G0021',
    'name': 'AYUNTAMIENTO DE SANTOMERA',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1403-F0348-G0021',
    'name': 'AYUNTAMIENTO DE TORREPACHECO',
    'fundDescription': 'PREVICORP F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0804-F0365-G0021',
    'name': 'Plan de Pensiones de Imprex Europe, S.L.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0960-F0365-G0021',
    'name': 'Plan de Pensiones de Kemira Ibérica, S.A.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1171-F0365-G0021',
    'name': 'PPPC de Barwill Unitor Ships Service',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1929-F0365-G0021',
    'name': 'PPPC BMW BANK GMBH SUCURSAL EN ESPAÑA',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1930-F0365-G0021',
    'name': 'PPPC de BMW Ibérica-España',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1931-F0365-G0021',
    'name': 'PLAN DE PENSIONES DE MUTUA INTERCOMARCAL',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1932-F0365-G0021',
    'name': 'PLAN DE PENSIONES EDAR PINEDO',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2526-F0365-G0021',
    'name': 'Plan de Pensiones Bio-Rad Laboratories, S.A.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2623-F0365-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE SOFTWAREONE SPAIN S.A.U.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2624-F0365-G0021',
    'name': 'Plan de Pensiones de Elizabeth Arden España, S.L.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3147-F0365-G0021',
    'name': 'Plan de Pensiones Trabajadores del Grupo Casinos de Catalunya',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3288-F0365-G0021',
    'name': 'Plan de Pensiones de PromociOn Conjunta Grupo Clariant España',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3368-F0365-G0021',
    'name': 'Plan de Pensiones JALPAK INTERNATIONAL, (Spain) S.A.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3814-F0365-G0021',
    'name': 'ARKEMA QUIMICA',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3843-F0365-G0021',
    'name': 'Plan de Pensiones de DSM Anti-Infectives Chemferm, S.A.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4829-F0365-G0021',
    'name': 'PLAN DE PENSIONES DE ALPHABET ESPAÑA FLEET MANAGEMENT, S.A.',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4946-F0365-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DEL GRUPO ARCHROMA ESPAÑA',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5404-F0365-G0021',
    'name': 'PLAN DE PENSIONES DE AVIENT COLORANTS SPAIN',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0942-F0365-G0021',
    'name': 'SESTIBA, PLAN DE PENSIONES',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5483-F0365-G0021',
    'name': 'PPE DE SOCIEDAD DE ESTIBA Y DESESTIBA CASTELLO CENTRO PORTUARIO',
    'fundDescription': 'DELTALIFE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5321-F0409-G0021',
    'name': 'CABK Protegido Renta Premium XI, PP',
    'fundDescription': 'Bankia Pensiones VIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2664-F0410-G0021',
    'name': 'Plan de Pensiones de la Institución Ferial de Madrid',
    'fundDescription': 'Bankia Pensiones IX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2996-F0410-G0021',
    'name': 'Plan de Pensiones de  Redsys, S.L. y Servired, S.A.',
    'fundDescription': 'Bankia Pensiones IX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4864-F0412-G0021',
    'name': 'CABK Protegido Renta Premium, PP',
    'fundDescription': 'Bankia Pensiones VII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0172-F0427-G0021',
    'name': 'Plan de Pensiones Esade',
    'fundDescription': 'Pensions Caixa 89, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1099-F0447-G0021',
    'name': 'CABK EQUILIBRIO PREMIUM',
    'fundDescription': 'Pensions Caixa RF Mixto, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1750-F0447-G0021',
    'name': 'CABK EQUILIBRIO',
    'fundDescription': 'Pensions Caixa RF Mixto, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4360-F0448-G0021',
    'name': 'PlanCaixa Agrario de Castilla y LeOn',
    'fundDescription': 'Pensions Caixa 8, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2810-F0449-G0021',
    'name': 'CABK AMBICIÓN CORTO PLAZO PREMIUM',
    'fundDescription': 'Pensions Caixa Dinero, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3597-F0449-G0021',
    'name': 'CABK AMBICIÓN CORTO PLAZO',
    'fundDescription': 'Pensions Caixa Dinero, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2293-F0463-G0021',
    'name': 'EMPLEADOS DE ENTERPRISE SOLUTIONS PROCESOS DE NEGOCIO ESPAÑA',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3137-F0463-G0021',
    'name': 'INSTITUTO VALENCIANO FINANZAS',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0894-F0463-G0021',
    'name': 'AYUNTAMIENTO DE ONDA',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1708-F0463-G0021',
    'name': 'AYUNTAMIENTO PICASSENT',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3993-F0463-G0021',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE ALGEMESÍ',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2329-F0463-G0021',
    'name': 'AGUAS MUNICIPALIZADAS ALICANTE',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4307-F0463-G0021',
    'name': 'AYUNTAMIENTO DE VALENCIA',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3304-F0463-G0021',
    'name': 'CORTS VALENCIANES',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3977-F0463-G0021',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE DIPUTACION DE CASTELLON',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3770-F0463-G0021',
    'name': 'PLAN DE PENSIONES CONSORCIO DE PALACIO DE CONGRESOS DE VALENCIA',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3771-F0463-G0021',
    'name': 'PLAN DE PENSIONES FUNDACION TURISMO VALENCIA CONVENTION BUREAU',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3643-F0463-G0021',
    'name': 'PLAN DE PENSIONES UNIVERSIDAD JAIME I DE CASTELLON',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4273-F0463-G0021',
    'name': 'PLAN DE PENSIONES UNIVERSITAT DE VALENCIA',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0688-F0463-G0021',
    'name': 'AYUNTAMIENTO DE ALAQUAS, PLAN DE PENSIONES',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2410-F0463-G0021',
    'name': 'AYUNTAMIENTO FOIOS',
    'fundDescription': 'PENSIOVAL III',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1287-F0482-G0021',
    'name': 'Plan de Pensiones de Ferrocarril Metropolità de Barcelona, S.A.',
    'fundDescription': 'Pensions Caixa 10, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4921-F0484-G0021',
    'name': 'PLANCAIXA PROYECCION 2024',
    'fundDescription': 'PENSIONS CAIXA GESTIÓN GLOBAL, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4922-F0485-G0021',
    'name': 'PLANCAIXA PROYECCION 2029',
    'fundDescription': 'PENSIONS CAIXA GESTIÓN 50, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3807-F0504-G0021',
    'name': 'PPC AYUNTAMIENTO DE JEREZ Y EMPRESAS MUNICIPALES',
    'fundDescription': 'ANDALSUR FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3970-F0521-G0021',
    'name': 'PLAN DE PENSIONES 3M ESPAÑA, S.L.',
    'fundDescription': 'F.P. DE LOS EMPLEADOS DE 3M ESPAÑA, S.L., F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1580-F0523-G0021',
    'name': 'PLAN DE PENSIONES LA SEDA DE BARCELONA, S.A.',
    'fundDescription': 'Pensions Caixa 14, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4162-F0524-G0021',
    'name': 'PlanCaixa 103 RV Mixta',
    'fundDescription': 'Pensions Caixa RV Mixto, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2279-F0525-G0021',
    'name': 'CABK RV EURO',
    'fundDescription': 'Pensions Caixa Bolsa Euro, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0805-F0535-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE MC MUTUAL ? MC PREVENCIÓN',
    'fundDescription': 'MIDAT CYCLOPS, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4205-F0537-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE LOS EMPLEADOS DE BNP PARIBA',
    'fundDescription': 'PENSIONS CAIXA 87, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1706-F0561-G0021',
    'name': 'P.P. DE EMPLEADOS DEL GRUPO BANKIA',
    'fundDescription': 'EMPLEADOS GRUPO BANKIA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4468-F0565-G0021',
    'name': 'CABK TENDENCIAS',
    'fundDescription': 'Pensions Caixa Tendencias, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2390-F0605-G0021',
    'name': 'Plan de Pensiones  Pymes II',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2545-F0605-G0021',
    'name': 'Plan de Pensiones Pymes V',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2668-F0605-G0021',
    'name': 'Plan de Pensiones de Empleados del Colegio Oficial de Ingenieros Industriales de Madrid',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3290-F0605-G0021',
    'name': 'Plan de Pensiones de los Empleados de BECTON DICKINSON, S.A',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3291-F0605-G0021',
    'name': 'Plan de Pensiones de  Iberia Cards',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3488-F0605-G0021',
    'name': 'Plan de Pensiones de  Mc Cain España, S.A.',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4524-F0605-G0021',
    'name': 'Plan de Pensiones de los Empleados de Medline Internacional Iberia, S.L.',
    'fundDescription': 'BANKIA PENSIONES XX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1722-F0614-G0021',
    'name': 'CABK RV NACIONAL',
    'fundDescription': 'Pensions Caixa Bolsa, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5085-F0615-G0021',
    'name': 'CABK DESTINO 2040 PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 17, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5086-F0615-G0021',
    'name': 'CABK DESTINO 2040 PREMIUM PLAN PENSIONES',
    'fundDescription': 'Pensions Caixa 17, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1767-F0616-G0021',
    'name': 'CABK RV INTERNACIONAL',
    'fundDescription': 'Pensions Caixa Bolsa Internacional, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1749-F0617-G0021',
    'name': 'Plan de Pensiones de Transports de Barcelona',
    'fundDescription': 'Pensions Caixa 19, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5363-F0619-G0021',
    'name': 'PPI CABK Selección Futuro Sostenible',
    'fundDescription': 'Pensions Caixa 15, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4830-F0621-G0021',
    'name': 'CABK Protegido Renta 2025, PP',
    'fundDescription': 'Bankia Pensiones LIX, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5461-F0624-G0021',
    'name': 'CABK GARANTIZADO 2028, PP',
    'fundDescription': 'PENSIONS CAIXA DINERO II, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1456-F0643-G0021',
    'name': 'GOMEZ DE LA FLOR II',
    'fundDescription': 'BANKIA MULTIFONDO PROFESIONAL II, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2546-F0643-G0021',
    'name': 'COL. ECONOMISTAS ALICANTE II',
    'fundDescription': 'BANKIA MULTIFONDO PROFESIONAL II, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1810-F0643-G0021',
    'name': 'COL. ECONOMISTAS VALENCIA II',
    'fundDescription': 'BANKIA MULTIFONDO PROFESIONAL II, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0682-F0660-G0021',
    'name': 'PLAN DE PENSIONES ECONOMISTAS',
    'fundDescription': 'ECONOMISTAS CRECIMIENTO, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1821-F0662-G0021',
    'name': 'CABK RV EMERGENTES',
    'fundDescription': 'PENSIONSCAIXA PRIVADA BOLSA EMERGENTE, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1491-F0663-G0021',
    'name': 'COLT TECHNOLOGY SERVICES S.A.U., PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 125, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1820-F0666-G0021',
    'name': 'CABK OPORTUNIDAD',
    'fundDescription': 'PENSIONSCAIXA PRIVADA ACTIVO OPORTUNIDAD FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5227-F0666-G0021',
    'name': 'CABK OPORTUNIDAD PREMIUM',
    'fundDescription': 'PENSIONSCAIXA PRIVADA ACTIVO OPORTUNIDAD FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1877-F0673-G0021',
    'name': 'ECONOMISTAS FUTURO, PLAN DE PENSIONES',
    'fundDescription': 'ECONOMISTAS FUTURO, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1247-F0677-G0021',
    'name': 'Plan de Pensiones de L´Ajuntament de Palafrugell',
    'fundDescription': 'Pensions Caixa 22, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1951-F0678-G0021',
    'name': 'Plan de Pensiones de Torres, S.A.',
    'fundDescription': 'Pensions Caixa 23, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5087-F0679-G0021',
    'name': 'CABK DESTINO 2030 PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 24, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5088-F0679-G0021',
    'name': 'CABK DESTINO 2030 PREMIUM PLAN PENSIONES',
    'fundDescription': 'Pensions Caixa 24, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1902-F0680-G0021',
    'name': 'Plan de Pensiones de Vidacaixa, S.A.',
    'fundDescription': 'Pensions Caixa 21, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1904-F0680-G0021',
    'name': 'Plan de Pensiones de Segurcaixa, S.A.',
    'fundDescription': 'Pensions Caixa 21, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2283-F0680-G0021',
    'name': 'Plan de Pensiones de Agencaixa, S.A.',
    'fundDescription': 'Pensions Caixa 21, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1892-F0685-G0021',
    'name': 'Plan de Pensiones de los trabajadores de las empresas acogidas al Acuerdo Marco',
    'fundDescription': 'Fondomega Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2011-F0685-G0021',
    'name': 'Plan de Pensiones PPI Deleg',
    'fundDescription': 'Fondomega Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4035-F0685-G0021',
    'name': 'PPPC de los Trabajadores de las Empresas del Grupo ba<x>sell acogidas al Acuerdo Marco',
    'fundDescription': 'Fondomega Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0803-F0691-G0021',
    'name': 'CABK CRECIMIENTO',
    'fundDescription': 'Pensions Caixa 124, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3599-F0691-G0021',
    'name': 'CABK CRECIMIENTO PREMIUM',
    'fundDescription': 'Pensions Caixa 124, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2277-F0709-G0021',
    'name': 'CAIXABANK CRECIMIENTO EMPRESA',
    'fundDescription': 'Pensions Caixa 28, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3930-F0709-G0021',
    'name': 'CAIXABANK CRECIMIENTO PLUS EMPRESA',
    'fundDescription': 'Pensions Caixa 28, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5089-F0710-G0021',
    'name': 'CABK DESTINO PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 29, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5090-F0710-G0021',
    'name': 'CABK DESTINO PREMIUM PLAN PENSIONES',
    'fundDescription': 'Pensions Caixa 29, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2285-F0711-G0021',
    'name': 'Plan de Pensiones de Empleo de Promoción Conjunta de la Caja de Ahorros y Pensiones de Barcelona y Caixabank, S.A.',
    'fundDescription': 'Pensions Caixa 30, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1596-F0712-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA JOHNSON&JOHNSON',
    'fundDescription': 'PENSIONS CAIXA 31, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1595-F0712-G0021',
    'name': 'PLAN DE PENSIONES EMPLEO JANSSEN CILAG',
    'fundDescription': 'PENSIONS CAIXA 31, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2899-F0712-G0021',
    'name': 'PLAN DE PENSIONES MCNEIL IBERICA',
    'fundDescription': 'PENSIONS CAIXA 31, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5496-F0713-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA JOHNSON&JOHNSON - CONSERVADOR',
    'fundDescription': 'PENSIONS CAIXA 32, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1595-F0713-G0021',
    'name': 'PLAN DE PENSIONES EMPLEO JANSSEN CILAG - CONSERVADOR',
    'fundDescription': 'PENSIONS CAIXA 32, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2899-F0713-G0021',
    'name': 'PLAN DE PENSIONES MCNEIL IBERICA - CONSERVADOR',
    'fundDescription': 'PENSIONS CAIXA 32, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0327-F0714-G0021',
    'name': 'Plan de Pensiones de Grupo Rhodia',
    'fundDescription': 'Fondo Grupo Rhodia, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0771-F0715-G0021',
    'name': 'Plan de Pensiones de Tersa',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1686-F0715-G0021',
    'name': 'Plan de Pensiones de Merial Laboratorios',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1982-F0715-G0021',
    'name': 'SUPERFOS, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1996-F0715-G0021',
    'name': 'MONTBLANC IBERIA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2424-F0715-G0021',
    'name': 'Plan de Pensiones de  Alier',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2822-F0715-G0021',
    'name': 'Plan de Pensiones de Kromschroeder',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3165-F0715-G0021',
    'name': 'PLAN DE PENSIONES DE ALPLA IBERICA, S.A.',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3673-F0715-G0021',
    'name': 'PP EMPLEADOS DE LAS ADMINISTRACIONES DE GUADALAJARA',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2401-F0715-G0021',
    'name': 'PPE EMPLEADOS COMPAÑIA CERVECERA CANARIAS S.A.',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1748-F0715-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE LA EMPRESA MIXTA DE AGUAS DE SANTA GRUZ DE TENERIFE, S.A.',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1305-F0715-G0021',
    'name': 'EXCELEN. DIPT. PROV. DE AVILA',
    'fundDescription': 'PENSIONS CAIXA 34, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4890-F0727-G0021',
    'name': 'PLANCAIXA FUTURO 160',
    'fundDescription': 'PENSIONS CAIXA 130, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4875-F0728-G0021',
    'name': 'PLANCAIXA FUTURO 170',
    'fundDescription': 'PENSIONS CAIXA 128, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4976-F0731-G0021',
    'name': 'PLACAIXA PROYECCIÓN BOLSA',
    'fundDescription': 'PENSIONS CAIXA 131, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4380-F0792-G0021',
    'name': 'CABK Renta Fija Corto Plazo, PP',
    'fundDescription': 'Bankia Pensiones XXII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3889-F0810-G0021',
    'name': 'AHORROBURGOS SUPERACION, P.P.',
    'fundDescription': 'PENSIONS CAIXA 36, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4448-F0810-G0021',
    'name': 'CANARIPLAN VALOR ALZA 2027, P.P.',
    'fundDescription': 'PENSIONS CAIXA 36, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5433-F0811-G0021',
    'name': 'CABK SI Impacto 30/60 RV, PP',
    'fundDescription': 'Pensions Caixa 35, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2992-F0868-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE ASEPEYO',
    'fundDescription': 'PENSIONS CAIXA 37, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2847-F0880-G0021',
    'name': 'Plan de Pensiones de Torraspapel, S.A.',
    'fundDescription': 'Torraspapel Futuro, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5091-F0881-G0021',
    'name': 'CABK RV USA PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 39, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1408-F0887-G0021',
    'name': 'Plan de Pensiones Universal Music Spain',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2459-F0887-G0021',
    'name': 'Plan de Pensiones  Universal Music Publishing',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3027-F0887-G0021',
    'name': 'Perseo, Plan de Pensiones',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4142-F0887-G0021',
    'name': 'Plan de Pensiones de los Empleados de Informa',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5326-F0887-G0021',
    'name': 'Global Talent Services Music Entertainment Spain',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2700-F0887-G0021',
    'name': 'P.P. PARKER HANNIFIN ESPAÑA',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2769-F0887-G0021',
    'name': 'P.P. CODENTA',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1927-F0887-G0021',
    'name': 'P.P. ELECTROQUIMICA DEL NOROESTE',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2801-F0887-G0021',
    'name': 'P.P. MERCAMADRID',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5268-F0887-G0021',
    'name': 'ALTAMIRA ELECTROQUIMICA DEL CANTABRICO',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3183-F0887-G0021',
    'name': 'P.P. WFS SERVICIOS AEROPORTUARIOS',
    'fundDescription': 'BANKIA PENSIONES XXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2857-F0891-G0021',
    'name': 'CABK SI Impacto 5/30 RV, PP',
    'fundDescription': 'Bankia Pensiones XXVI F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0988-F0908-G0021',
    'name': 'Plan de Pensiones de Ajuntament de Castellà del VallEs',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1672-F0908-G0021',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE AGUSTÍ I MASOLIVER, S.A.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2930-F0908-G0021',
    'name': 'Plan de Pensiones de Emasagra',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2970-F0908-G0021',
    'name': 'Plan de Pensiones de Aguas de Valladolid',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3093-F0908-G0021',
    'name': 'Plan de Pensiones de Avery Dennison Ibérica, S.A.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3145-F0908-G0021',
    'name': 'Plan de Pensiones Ajuntament de Cambrils',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3146-F0908-G0021',
    'name': 'Plan de Pensiones Ayuntamiento de Cuenca',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3169-F0908-G0021',
    'name': 'Plan de Pensiones de los Empleados de Arcelormittal Zaragoza, S.A.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3274-F0908-G0021',
    'name': 'Plan de Pensiones de CorporaciOn de Prácticos de Sevilla y Ria del Guadalquivir, S.L.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3375-F0908-G0021',
    'name': 'Plan de Pensiones de L´Ajuntament de Sant Just Desvern',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3939-F0908-G0021',
    'name': 'Plan de Pensiones del Ayuntamiento de Coslada',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4707-F0908-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE ARCONVERT, S.A.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5044-F0908-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE MANE IBÉRICA, S.A.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4123-F0908-G0021',
    'name': 'SECO TOOLS ESPAÑA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2942-F0908-G0021',
    'name': 'PPE DE MANUFACTURE DE MAROQUINERIE ET ACCESSOIRES LOUIS VUITTON',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3596-F0908-G0021',
    'name': 'Plan de Pensiones de Castellar Vidrio, S.A.',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3602-F0908-G0021',
    'name': 'PP GRUPO MUSAAT',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2673-F0908-G0021',
    'name': 'P.P. AYUNTAMIENTO DE COLMENAR VIEJO',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3652-F0908-G0021',
    'name': 'ADMINISTRACION PUBLICA DE CEUTA',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3486-F0908-G0021',
    'name': 'PP DE LOS EMPLEADOS DE VISOGSA',
    'fundDescription': 'PENSIONS CAIXA 41, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2875-F0911-G0021',
    'name': 'P.P. GRUPO BARCLAYS EN ESPAÑA',
    'fundDescription': 'GRUPO BARCLAYS EN ESPAÑA F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3294-F0948-G0021',
    'name': 'Plan de Pensiones del Personal de las Cortes Generales',
    'fundDescription': 'BANKIA PENSIONES XXXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3566-F0948-G0021',
    'name': 'Plan de Pensiones de los Empleados de la  Oficina del Defensor del Pueblo',
    'fundDescription': 'BANKIA PENSIONES XXXIII, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3022-F0964-G0021',
    'name': 'DIPUTACION DE ALICANTE, PLAN DE PENSIONES',
    'fundDescription': 'PREVICORP II F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2974-F0974-G0021',
    'name': 'Plan de Pensiones de Plan empleados de Serveis Funeraris de Barcelona, S.L.',
    'fundDescription': 'Pensions Caixa 45, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3092-F0999-G0021',
    'name': 'Plan de Pensiones Nuclenor',
    'fundDescription': 'Pensions Caixa 47, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1824-F1048-G0021',
    'name': 'Plan de Pensiones de los Empleados de DSM Nutritional Products Iberia',
    'fundDescription': 'PENSIONS CAIXA 51, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1828-F1048-G0021',
    'name': 'P.P DE LOS EMPLEADOS DE ROCHE FARMA',
    'fundDescription': 'PENSIONS CAIXA 51, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5253-F1048-G0021',
    'name': 'PLAN DE PENSIONES RECIPHARM LEGANES',
    'fundDescription': 'PENSIONS CAIXA 51, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5158-F1049-G0021',
    'name': 'CABK INVERSION FLEXIBLE',
    'fundDescription': 'Pensions Caixa 52, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5303-F1050-G0021',
    'name': 'CABK MONETARIO',
    'fundDescription': 'Pensions Caixa 57, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3302-F1051-G0021',
    'name': 'Plan de Pensiones de Sistema de Empleo de Danone, S.A.',
    'fundDescription': 'Pensions Caixa 58, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3458-F1051-G0021',
    'name': 'Plan de Pensiones de los Empleados de Font Vella S.A.',
    'fundDescription': 'Pensions Caixa 58, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3351-F1052-G0021',
    'name': 'P.P AIR EUROPA',
    'fundDescription': 'PENSIONS CAIXA 59, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0317-F1053-G0021',
    'name': 'PLAN DE PENSIONES DE LA MUTUALIDAD DEL CLERO ESPAÑOL',
    'fundDescription': 'Pensions Caixa 60, FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': '---F1059-G0021',
    'name': '--',
    'fundDescription': 'Pensions Caixa 55, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3326-F1062-G0021',
    'name': 'CABK SELECCIÓN',
    'fundDescription': 'Pensions Caixa SelecciOn, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2265-F1101-G0021',
    'name': 'PLAN DE PENSIONES PARA EL PERSONAL DE AGILENT TECHNOLOGIES SPAIN, S.L.',
    'fundDescription': 'PENSIONS CAIXA 132, F.P',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0675-F1128-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DEL GRUPO ENCE',
    'fundDescription': 'FONDO SERVIRENTA II, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0166-F1133-G0021',
    'name': 'PLAN DE PENSIONES DE SAINT-GOBAIN WEBER CEMARKSA',
    'fundDescription': 'Pensions Caixa 104, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0792-F1136-G0021',
    'name': 'PLAN DE PENSIONES COLEGIO DE GEOLOGOS',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0887-F1136-G0021',
    'name': 'TRAMERPE',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1116-F1136-G0021',
    'name': 'PLAN DE PENSIONES DE LOS FARMACEUTICOS DE ANDALUCIA',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2698-F1136-G0021',
    'name': 'HOSTELERIA DE CANTABRIA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3430-F1136-G0021',
    'name': 'Plan de Pensiones del Sector Agrario',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3984-F1136-G0021',
    'name': 'PlanCaixa Colegios Profesionales y Asociaciones',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5040-F1136-G0021',
    'name': 'PPPC ASOCIADO DE LA COORDINADORA DE TRABAJADORES DEL MAR',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2543-F1136-G0021',
    'name': 'Plan de Pensiones de la Asociación de Prejubilados de Telefónica de Canarias',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1097-F1136-G0021',
    'name': 'P.P. UNIVERSITAS COMPLUTENSE',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1279-F1136-G0021',
    'name': 'P.P. COLEGIO MEDICOS  CACERES',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3219-F1136-G0021',
    'name': 'AMAS DE CASA COMUNIDAD VALENCIANA',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1523-F1136-G0021',
    'name': 'PROMOCION CONJUNTA EMPLEADOS DE NOTARIA',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1444-F1136-G0021',
    'name': 'GOMEZ DE LA FLOR I',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1811-F1136-G0021',
    'name': 'COL. ECONOMISTAS VALENCIA I',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0214-F1136-G0021',
    'name': 'Asociado de la demarcación de Castellón del Colegio Oficial Arquitectos de la Comunidad Valenciana',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0213-F1136-G0021',
    'name': 'PLAMED CASTELLON',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0938-F1136-G0021',
    'name': 'COL. ECONOMISTAS DE ALICANTE',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1482-F1136-G0021',
    'name': 'COL. APAREJADORES ALICANTE',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4044-F1136-G0021',
    'name': 'PLAN DE PENSIONES DE MÉDICOS DE SALAMANCA, VALLADOLID Y PALENCIA',
    'fundDescription': 'PENSIONS CAIXA 62, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3482-F1138-G0021',
    'name': 'PlanCaixa Corporaciones Locales',
    'fundDescription': 'Pensions Caixa 64, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3561-F1138-G0021',
    'name': 'CAIXABANK EQUILIBRIO EMPRESA',
    'fundDescription': 'Pensions Caixa 64, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3340-F1138-G0021',
    'name': 'CAIXABANK AMBICIÓN FLEXIBLE EMPRESA',
    'fundDescription': 'Pensions Caixa 64, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': '11511-F1151-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE LAS EMPRESAS DEL GRUPO NESTLÉ',
    'fundDescription': 'NESTLÉ CONSERVADOR, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3760-F1180-G0021',
    'name': 'PLAN DE PENSIONES DE LA ADMINISTRACIÓN PÚBLICA DE LA REGIÓN DE MURCIA',
    'fundDescription': 'PENSIONS CAIXA 66, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3496-F1183-G0021',
    'name': 'CABK Renta Fija Largo Plazo Premium, PP',
    'fundDescription': 'Pensions Caixa AmbiciOn, Fondo de PensiOn',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1766-F1183-G0021',
    'name': 'CABK Renta Fija Largo Plazo, PP',
    'fundDescription': 'Pensions Caixa AmbiciOn, Fondo de PensiOn',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2762-F1184-G0021',
    'name': 'P.P. AYUNTAMIENTO DE SALOU',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3520-F1184-G0021',
    'name': 'Plan de Pensiones del Ayuntamiento de Cádiz',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3521-F1184-G0021',
    'name': 'Plan de Pensiones del Ayuntamiento de Gavà',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3522-F1184-G0021',
    'name': 'Plan de Pensiones del Consell Comarcal de L´Alt Empordà',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3537-F1184-G0021',
    'name': 'Plan de Pensiones del Ayuntamiento de Calonge',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3631-F1184-G0021',
    'name': 'Plan de Pensiones Consell Insular D´Eivissa',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3796-F1184-G0021',
    'name': 'Plan de Pensiones del Sistema de Empleo para los Empleados de Saba Aparcamientos S.A.',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3974-F1184-G0021',
    'name': 'PP Institut Català del Sòl',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3975-F1184-G0021',
    'name': 'PP de los Empleados del Ayuntamiento de Vila-seca',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4165-F1184-G0021',
    'name': 'PP de Empleo de la Cámara Oficial de Comercio, Industria y NavegaciOn de Barcelona',
    'fundDescription': 'Pensions Caixa 70, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1721-F1185-G0021',
    'name': 'AYUNTAMIENTO DE LAS TORRES DE COTILLAS, PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 71, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3844-F1185-G0021',
    'name': 'PLAN DE PENSIONES DE MOMENTIVE',
    'fundDescription': 'Pensions Caixa 71, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4156-F1185-G0021',
    'name': 'Plan de Pensiones del Ayuntamiento de Pinto',
    'fundDescription': 'Pensions Caixa 71, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4418-F1185-G0021',
    'name': 'EMPLEADOS ALIMENT. ANIMAL NANTA',
    'fundDescription': 'Pensions Caixa 71, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5186-F1185-G0021',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE IBERALBION',
    'fundDescription': 'Pensions Caixa 71, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3971-F1186-G0021',
    'name': 'PP DE ?AGENCIA CATALANA DE TURISME?',
    'fundDescription': 'Pensions Caixa 72, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4115-F1186-G0021',
    'name': 'PPPC del personal al servicio de la DiputaciOn de Lleida y de sus Organismos y Entidades',
    'fundDescription': 'Pensions Caixa 72, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4189-F1186-G0021',
    'name': 'PPPC del personal al servicio del Ayuntamiento de Lleida y de sus Organismos y Entidades',
    'fundDescription': 'Pensions Caixa 72, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3776-F1187-G0021',
    'name': 'PLA DE PENSIONS DOCUPACIÓ DE PROMOCIÓ CONJUNTA DE LÀMBIT DE LA GENERALITAT DE CATALUNYA',
    'fundDescription': 'FONS DE PENSIONS DE L´ÀMBIT DE LA GENERALITAT DE CATALUNYA, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3712-F1188-G0021',
    'name': 'PPPC PARA EMPRESAS ORGANIZADORAS DEL JUEGO DEL BINGO',
    'fundDescription': 'Flortplant Empleo, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3849-F1188-G0021',
    'name': 'Plan de Pensiones de los Trabajadores del Comercio de Flores y Plantas',
    'fundDescription': 'Flortplant Empleo, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4011-F1188-G0021',
    'name': 'PP de PromociOn Conjunta de los trabajadores de centros de jardinería',
    'fundDescription': 'Flortplant Empleo, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1398-F1239-G0021',
    'name': 'PLAN DE PENSIONES DE LA EMPRESA MUNICIPAL DE AGUAS DE LA CORUÑA, S.A. - EMALCSA',
    'fundDescription': 'PENSIONS CAIXA 138, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0907-F1252-G0021',
    'name': 'PLA DE PENSIONS DE L´AJUNTAMENT DE PALAMÒS',
    'fundDescription': 'Pensions Caixa 77, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3723-F1254-G0021',
    'name': 'Plan de Pensiones de la Administración Municipal del Ayuntamiento de  Madrid',
    'fundDescription': 'FONDO DE PENSIONES DEL AYUNTAMIENTO DE MADRID',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5342-F1258-G0021',
    'name': 'CABK Destino 2026 Plan de Pensiones',
    'fundDescription': 'Pensions Caixa 78, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0959-F1260-G0021',
    'name': 'BACARDI ESPAÑA, PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 81, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3834-F1331-G0021',
    'name': 'CABK RETORNO ABSOLUTO',
    'fundDescription': 'Pensions Caixa 83, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2942-F1332-G0021',
    'name': 'PPE DE MANUFACTURE DE MAROQUINERIE ET ACCESSOIRES LOUIS VUITTON',
    'fundDescription': 'Pensions Caixa 85, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5457-F1357-G0021',
    'name': 'CABK DESTINO 2060, PLAN DE PENSIONES',
    'fundDescription': 'Pensions Caixa 92, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5015-F1358-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE ALCATEL-LUCENT ENTERPRISE SPAIN SLU',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4773-F1358-G0021',
    'name': 'PLAN DE PENSIONES KEM ONE HERNANI',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5215-F1358-G0021',
    'name': 'PLAN DE PENSIONES DE INDORAMA VENTURES QUIMICA',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4017-F1358-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE OMRON ELECTRONICS IBERIA, S.A.U.',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0168-F1358-G0021',
    'name': 'PP HOTEL WELLINGTON',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3154-F1358-G0021',
    'name': 'ARCELORMITTAL MADRID S.L.',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0952-F1358-G0021',
    'name': 'P.P.CIFSA',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4955-F1358-G0021',
    'name': 'BEKA FINANCE, P.P.',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5395-F1358-G0021',
    'name': 'PP DE LOS EMPLEADOS DEL AYUNTAMIENTO DE TREBUJENA',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5097-F1358-G0021',
    'name': 'P.P. DE COVESTRO',
    'fundDescription': 'Pensions Caixa 93, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2439-F1359-G0021',
    'name': 'Plan de Pensiones de los empleados de Nokia Spain, S.A.',
    'fundDescription': 'Pensions Caixa 94, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2692-F1360-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE AUTOPISTAS ABERTIS',
    'fundDescription': 'Pensions Caixa 95, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3613-F1360-G0021',
    'name': 'Plan de Pensiones de PromociOn Conjunta de Abertis',
    'fundDescription': 'Pensions Caixa 95, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5456-F1416-G0021',
    'name': 'CABK DESTINO 2035, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 120, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1646-F1427-G0021',
    'name': 'PATENTES TALGO S.A.',
    'fundDescription': 'Pensions Caixa 96, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1928-F1428-G0021',
    'name': 'AGUAS DE MURCIA',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2258-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE BAUSCH & LOMB, S.A.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2363-F1428-G0021',
    'name': 'PPPC EVOLUTIO CLOUD ENABLER SAU',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2506-F1428-G0021',
    'name': 'CEMENTOS ESPEC. DE LAS ISLAS',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2703-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE STORA ENSO BARCELONA, S.A.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2794-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL GRUPO DE EMPRESAS DEL AYUNTAMIENTO DE ALCOBENDAS Y PATRONATOS',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4113-F1428-G0021',
    'name': 'PP del Grupo Yahoo',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4361-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA XL INSURANCE, SUCURSAL EN ESPAÑA',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4711-F1428-G0021',
    'name': 'PP EVO FINANCE, ENTIDAD FINANCIERA DE CREDITO, S.A.U.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5002-F1428-G0021',
    'name': 'PPPC EMPLEADOS DE LYNTIA NETWORKS Y GUNALTA ITG',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5101-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE EVO BANCO, S.A.U.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5007-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE LA ANTICIPA REAL ESTATE SLU',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5160-F1428-G0021',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE IATA ESPAÑA S.L.U.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2305-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS DE REALIA BUSINESS',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1878-F1428-G0021',
    'name': 'PLAN DE PENSIONES PALL ESPAÑA, S.A.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5003-F1428-G0021',
    'name': 'MEDIOLANUM, PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': '14281-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE SPC JEVSA SLU',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5284-F1428-G0021',
    'name': 'PLAN DE PENSIONES ABELLO LINDE',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5296-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE UFINET LATAM, S.L.U.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3531-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE QUAKER CHEMICAL',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0228-F1428-G0021',
    'name': 'PPO PROMOCION CONJUNTA CONJUNT EMPRESES',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4114-F1428-G0021',
    'name': 'Plan de Pensiones de Empleo de Brother Iberia S.L.',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5372-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE BT GLOBAL ICT BUSINESS SPAIN SL',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5127-F1428-G0021',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE LOS EMPLEADOS DE BLUESUN',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0807-F1428-G0021',
    'name': 'PLA DE PENSIONS AIGUES DE MANRESA',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5502-F1428-G0021',
    'name': 'PLAN DE PENSIONES LINDE MEDICA Y LINDE MEDICINAL',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5062-F1428-G0021',
    'name': 'PLAN DE PENSIONES HISCOX EUROPE',
    'fundDescription': 'PENSIONS CAIXA 97, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1160-F1429-G0021',
    'name': 'PPPC EMPLEADOS TOTALENERGIES EN ESPAÑA',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DE LAS EMPRESAS DE TOTALENERGIES EN ESPAÑA',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4819-F1429-G0021',
    'name': 'PLAN DE PENSIONES DE AS 24 ESPAÑOLA, S.A.',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DE LAS EMPRESAS DE TOTALENERGIES EN ESPAÑA',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4835-F1454-G0021',
    'name': 'PLANCAIXA FUTURO 2024',
    'fundDescription': 'PENSIONS CAIXA 123, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2518-F1455-G0021',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO PARA LOS EMPLEADOS DE AMGEN, S.A.',
    'fundDescription': 'PENSIONS CAIXA 133, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4909-F1499-G0021',
    'name': 'CABK Protegido Renta Premium II, PP',
    'fundDescription': 'Bankia Pensiones LV,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4345-F1508-G0021',
    'name': 'Plan de Pensiones para el Personal Empleado Público de la Generalitat.',
    'fundDescription': 'PENSIOVAL VII',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1337-F1521-G0021',
    'name': 'SAP ESPAÑA',
    'fundDescription': 'Pensions Caixa 102, Fondo de Pensiones',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4833-F1562-G0021',
    'name': 'PLANCAIXA FUTURO 2026',
    'fundDescription': 'PENSIONS CAIXA 122, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1123-F1563-G0021',
    'name': 'GRUPO PERNOD RICARD ESPAÑA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONES CAIXA PRE, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0300-F1568-G0021',
    'name': 'ASLAND, PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'PENSIONS CAIXA 110, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0812-F1568-G0021',
    'name': 'PLAN DE PENSIONES LAFARGE ARIDOS Y HORMIGONES, S.A.U.',
    'fundDescription': 'PENSIONS CAIXA 110, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0985-F1569-G0021',
    'name': 'ENUSA INDUSTRIAS AVANZADAS',
    'fundDescription': 'PENSIONS CAIXA 111, FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1694-F1571-G0021',
    'name': 'GENERAL OPTICA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 113, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2993-F1572-G0021',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE ROCHE DIAGNOSTICS, S.L.',
    'fundDescription': 'PENSIONS CAIXA 114, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5323-F1596-G0021',
    'name': 'CABK Protegido Renta Premium XII, PP',
    'fundDescription': 'Bankia Pensiones LXXXV, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1406-F1658-G0021',
    'name': 'Plan de Pensiones del Exmo. Ayuntamiento de Cartagena',
    'fundDescription': 'CAJAMURCIA XXII FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2979-F1715-G0021',
    'name': 'PLASTICS ESPAÑA',
    'fundDescription': 'PENSIONS CAIXA 115, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2680-F1716-G0021',
    'name': 'PLA DE PENSIONS DE PROMOCIÓ CONJUNTA DE L?AJUNTAMENT DE L?HOSPITALET I ELS SEUS ORGANISMES',
    'fundDescription': 'PENSIONS CAIXA 116, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3099-F1716-G0021',
    'name': 'PLA PENSIONS SISTEMA OCUPACIO AREA METROPOLITANA DE BARCELONA',
    'fundDescription': 'PENSIONS CAIXA 116, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3105-F1716-G0021',
    'name': 'PLA DE PENSIONS DEL PERSONAL AL SERVEI DE LA DIPUTACIÓ DE BARCELONA',
    'fundDescription': 'PENSIONS CAIXA 116, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4092-F1717-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE RABOBANK',
    'fundDescription': 'PENSIONS CAIXA 117, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2952-F1913-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE EMAYA',
    'fundDescription': 'PENSIONS CAIXA 136, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2706-F1914-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE LA CÁMARA OFICIAL DE COMERCIO, INDUSTRIA Y NAVEGACIÓN DE VALENCIA',
    'fundDescription': 'PENSIONS CAIXA 137, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1033-F1914-G0021',
    'name': 'AYUNTAMIENTO DE TORRENT, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 137, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1086-F1914-G0021',
    'name': 'PP DEL CONSORCIO PROVINCIAL PARA SPEIS VALENCIA',
    'fundDescription': 'PENSIONS CAIXA 137, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0373-F1914-G0021',
    'name': 'EMPLEADOS DE BANCAJA',
    'fundDescription': 'PENSIONS CAIXA 137, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4930-F1960-G0021',
    'name': 'CABK Protegido Renta Premium III, PP',
    'fundDescription': 'Bankia Pensiones LXIV,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5027-F1961-G0021',
    'name': 'CABK Jubilación, PP',
    'fundDescription': 'Bankia Pensiones LXVI,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4956-F1962-G0021',
    'name': 'CABK Protegido Renta Premium IV, PP',
    'fundDescription': 'Bankia Pensiones LXV',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5122-F1990-G0021',
    'name': 'CABK Protegido Renta Premium V, PP',
    'fundDescription': 'Bankia Pensiones LXVII',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5151-F1993-G0021',
    'name': 'CABK Protegido Renta Premium VI, PP',
    'fundDescription': 'Bankia Pensiones LXVIII,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5162-F1994-G0021',
    'name': 'CABK Protegido Renta Premium VII, PP',
    'fundDescription': 'Bankia Pensiones LXIX,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5181-F1995-G0021',
    'name': 'CABK Protegido Renta Premium VIII, PP',
    'fundDescription': 'Bankia Pensiones LXX,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5191-F1996-G0021',
    'name': 'CABK Protegido Renta Premium IX, PP',
    'fundDescription': 'Bankia Pensiones LXXI,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0764-F1997-G0021',
    'name': 'RETEVISION',
    'fundDescription': 'PENSIONS CAIXA 145, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4424-F1997-G0021',
    'name': 'TRADIA, PLAN DE PENSIONES',
    'fundDescription': 'PENSIONS CAIXA 145, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': '19981-F1998-G0021',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE LA EMPRESA MIXTA DE AGUAS DE SANTA CRUZ DE TENERIFE, S.A. - CONSERVADOR',
    'fundDescription': 'PENSIONS CAIXA 146, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0391-F1999-G0021',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE EXOLUM CORPORATION, SA',
    'fundDescription': 'PENSIONS CAIXA 147 FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1329-F1999-G0021',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE EXOLUM AVIATION, S.A.',
    'fundDescription': 'PENSIONS CAIXA 147 FONDO DE PENSIONES',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1256-F2005-G0021',
    'name': 'PLAN DE PENSIONES DE LA FUNDACIO',
    'fundDescription': 'PENSIONS CAIXA 143, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2786-F2006-G0021',
    'name': 'PLAN DE PENSIONES DE IKEA',
    'fundDescription': 'PENSIONS CAIXA 144, F.P.',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5287-F2086-G0021',
    'name': 'CABK Protegido Renta Premium X, PP',
    'fundDescription': 'Bankia Pensiones LXXII,FP',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5347-F2125-G0021',
    'name': 'CABK Protegido Renta Premium XIV, PP',
    'fundDescription': 'Bankia Pensiones LXXXII',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5362-F2127-G0021',
    'name': 'CABK Protegido Renta Premium XV, PP',
    'fundDescription': 'Bankia Pensiones LXXXIV',
    'managerId': 'G0021',
    'managerName': 'VIDACAIXA, S.A. DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0051-F0170-G0037',
    'name': 'GENERALI MIXTO RENTA FIJA, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI UNO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N0097-F0170-G0037',
    'name': 'PLAN 5 DE PENSIONES, GENERALI',
    'fundDescription': 'GENERALI UNO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N0099-F0170-G0037',
    'name': 'PLAN 5 DE PENSIONES NACIONAL HISPANICA, GENERALI',
    'fundDescription': 'GENERALI UNO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N0737-F0170-G0037',
    'name': 'GENERALI MIXTO RENTA FIJA 2, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI UNO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N1254-F0171-G0037',
    'name': 'GENERALI MIXTO RENTA VARIABLE 2, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI DOS, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N1255-F0171-G0037',
    'name': 'GENERALI MIXTO RENTA VARIABLE, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI DOS, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N1405-F0171-G0037',
    'name': 'PLAN 30 DE PENSIONES, GENERALI',
    'fundDescription': 'GENERALI DOS, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N0995-F0306-G0037',
    'name': 'Plan de Pensiones Empleados Jimten, S.A.',
    'fundDescription': 'GENERALI EMPLEO CINCO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2859-F0699-G0037',
    'name': 'Plan de Pensiones del Personal al Servicio del Ayuntamiento de Roquetas de Mar',
    'fundDescription': 'GENERALI EMPLEO SEIS, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N3485-F0699-G0037',
    'name': 'Vitalicio PYME',
    'fundDescription': 'GENERALI EMPLEO SEIS, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2343-F0701-G0037',
    'name': 'Plan de pensiones de los empleados de Finanzauto, S.A.',
    'fundDescription': 'GENERALI EMPLEO OCHO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2944-F0702-G0037',
    'name': 'Plan de Empleados de PrevisiOn Mútua de Aparejadores y Arquitectos Técnicos',
    'fundDescription': 'GENERALI EMPLEO NUEVE, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2953-F0702-G0037',
    'name': 'Plan de Pensiones Empleados Banca di Roma, s.p.a - Sucursal en España',
    'fundDescription': 'GENERALI EMPLEO NUEVE, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2481-F0838-G0037',
    'name': 'GENERALI RENTA FIJA CORTO PLAZO, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI TRES, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2620-F0838-G0037',
    'name': 'GENERALI RENTA FIJA, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI TRES, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N3061-F0971-G0037',
    'name': 'Plan empleados Grupo Estrella',
    'fundDescription': 'GENERALI EMPLEO TRES, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N1072-F1034-G0037',
    'name': 'PLAN DE PENSIONES ASOCIADO PRIUS-1',
    'fundDescription': 'GENERALI CINCO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N3442-F1091-G0037',
    'name': 'Plan de Pensiones Empleados de Sandvik Española',
    'fundDescription': 'GENERALI EMPLEO DIEZ, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N3695-F1093-G0037',
    'name': 'P.P. AsociaciOn Inspectores Hacienda Estado',
    'fundDescription': 'GENERALI SEIS, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N2480-F1126-G0037',
    'name': 'PLAN 75  DE PENSIONES, GENERALI',
    'fundDescription': 'GENERALI CUATRO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N3364-F1126-G0037',
    'name': 'GENERALI RENTA VARIABLE, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI CUATRO, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N5453-F2169-G0037',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA GENERALI PYME',
    'fundDescription': 'GENERALI EMPLEO PYME F.P.',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N5442-F2170-G0037',
    'name': 'GENERALI 2035, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI 2035, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N5443-F2173-G0037',
    'name': 'GENERALI 2045, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI 2045, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N5444-F2174-G0037',
    'name': 'GENERALI 2055, PLAN DE PENSIONES INDIVIDUAL',
    'fundDescription': 'GENERALI 2055, FONDO DE PENSIONES',
    'managerId': 'G0037',
    'managerName': 'GENERALI ESPAÑA, S.A. DE SEGUROS Y REASEGUROS.'
  },
  {
    'id': 'N4023-F0017-G0067',
    'name': 'CRONOS PREVISION RENTA VARIABLE P.P',
    'fundDescription': 'CRONOS PREVISION RENTA VARIABLE F.P',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N0053-F0173-G0067',
    'name': 'PLAN DE PENSIONES INDIVIDUAL CAMINOS UNO',
    'fundDescription': 'CAUCE 2000 FONDO DE PENSIONES',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N0701-F0327-G0067',
    'name': 'PLANDISVA PLAN DE PENSIONES',
    'fundDescription': 'TEBAIDA FONDO DE PENSIONES',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N0700-F0327-G0067',
    'name': 'PENSUR PLAN DE PENSIONES',
    'fundDescription': 'TEBAIDA FONDO DE PENSIONES',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N1806-F0664-G0067',
    'name': 'PLAN DE PENSIONES INDIVIDUAL CAMINOS BOLSA',
    'fundDescription': 'CAUCE MIXTO VARIABLE FONDO DE PENSIONES',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N4024-F0816-G0067',
    'name': 'CRONOS PREVISION RENTA FIJA P.P',
    'fundDescription': 'CRONOS PREVISION RENTA FIJA F.P',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N2666-F0904-G0067',
    'name': 'MG JUBILACION ORO PLAN DE PENSIONES',
    'fundDescription': 'MG BOLSA JUBILACION ORO FONDO DE PENSIONES',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N4136-F1080-G0067',
    'name': 'PLAN DE PENSIONES INDIVIDUAL CAMINOS DINERO',
    'fundDescription': 'CAUCE DINERO FONDO DE PENSIONES',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N1340-F1527-G0067',
    'name': 'THE BOSTON CONSULTING GROUP, PLAN DE PENSIONES',
    'fundDescription': 'CRONOS PREVISION EMPRESAS, F.P.',
    'managerId': 'G0067',
    'managerName': 'GESPENSION CAMINOS, S.A. E.G.F.P.'
  },
  {
    'id': 'N0074-F0023-G0079',
    'name': 'Plan Ibercaja de Pensiones I Renta Fija',
    'fundDescription': 'Ibercaja Pensiones, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N0420-F0246-G0079',
    'name': 'Plan de Pensiones de Empleados de Ibercaja',
    'fundDescription': 'Ibercaja Empleados, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N0704-F0337-G0079',
    'name': 'Plan de Pensiones de las Cortes de AragOn',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N0896-F0337-G0079',
    'name': 'Plan de Pensiones del Ayuntamiento de Alcalá de Henares',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1124-F0337-G0079',
    'name': 'Plan de Pensiones del Ayuntamiento de Navalcarnero',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1608-F0337-G0079',
    'name': 'Plan de Pensiones de El Justicia de AragOn',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N2941-F0337-G0079',
    'name': 'AYUNTAMIENTO DE PARLA',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N3000-F0337-G0079',
    'name': 'Plan de Pensiones del Ayuntamiento de Logroño',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4062-F0337-G0079',
    'name': 'Plan de Pensiones de PromociOn Conjunta de los ex Empleados de Diario El País, S.L.',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N2853-F0337-G0079',
    'name': 'Plan de Pensiones Ibercaja Miempleo 30',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N5348-F0337-G0079',
    'name': 'Plan de Pensiones Empleados Grupo Financiero de Ibercaja',
    'fundDescription': 'Ibercaja Colectivos, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N0990-F0387-G0079',
    'name': 'BANCO DE ESPAÑA',
    'fundDescription': 'FONDEBE, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1098-F0444-G0079',
    'name': 'Plan Ibercaja de Pensiones Ahorro Renta Fija',
    'fundDescription': 'Ibercaja Pensiones II, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1207-F0465-G0079',
    'name': 'Plan Ibercaja de Pensiones Flexible Europa 30-50',
    'fundDescription': 'Ibercaja Pensiones Flexible Europa 30-50, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1368-F0505-G0079',
    'name': 'Plan Ibercaja de Pensiones Gestión Audaz',
    'fundDescription': 'Ibercaja Pensiones Gestión Audaz, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1675-F0632-G0079',
    'name': 'Plan de Pensiones de EXIDE TECHNOLOGIES S.A',
    'fundDescription': 'Fondo de Pensiones de los trabajadores de Exide Technologies S.A., F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1789-F0644-G0079',
    'name': 'Plan Ibercaja de Pensiones Gestión Evolución',
    'fundDescription': 'IBERCAJA PENSIONES GESTIÓN EVOLUCIÓN, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N1400-F0644-G0079',
    'name': 'Plan de Pensiones SATSE 1',
    'fundDescription': 'IBERCAJA PENSIONES GESTIÓN EVOLUCIÓN, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N2677-F0686-G0079',
    'name': 'Plan de Pensiones de la D.G.A.. Administraciones Locales y otras Entidades e Instituciones de la Comunidad AutonOmica de AragOn',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS PUBLICOS EN LA COMUNIDAD AUTONOMA DE ARAGON, FP',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N2602-F0830-G0079',
    'name': 'Plan Ibercaja de Pensiones Gestión Crecimiento',
    'fundDescription': 'IBERCAJA PENSIONES GESTIÓN CRECIMIENTO, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N2603-F0831-G0079',
    'name': 'Plan Ibercaja de Pensiones Gestión Equilibrada',
    'fundDescription': 'IBERCAJA PENSIONES GESTION EQUILIBRADA, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N2676-F0852-G0079',
    'name': 'Plan Ibercaja de Pensiones Global Brands',
    'fundDescription': 'Ibercaja Pensiones Bolsa Global, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N3078-F0888-G0079',
    'name': 'Plan de Pensiones Ibercaja Miempleo 10',
    'fundDescription': 'Ibercaja Empresas Pymes Renta Fija, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N3528-F1240-G0079',
    'name': 'Plan de pensiones de los Empleados del Grupo Endesa',
    'fundDescription': 'PrevisiOn Social, Empleados del Grupo Endesa, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4735-F1277-G0079',
    'name': 'Plan Ibercaja de Pensiones Horizonte 2024',
    'fundDescription': 'Ibercaja Pensiones Destino, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N3933-F1391-G0079',
    'name': 'Plan Ibercaja de Pensiones Europa Sostenible',
    'fundDescription': 'Ibercaja Pensiones Europa Sostenible, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4154-F1470-G0079',
    'name': 'Plan Ibercaja de Pensiones Sostenible y Solidario',
    'fundDescription': 'IBERCAJA PENSIONES SOSTENIBLE, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4353-F1480-G0079',
    'name': 'Plan Ibercaja de Pensiones Bolsa USA',
    'fundDescription': 'Ibercaja Pensiones Bolsa USA, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4456-F1540-G0079',
    'name': 'Plan Ibercaja de Pensiones Megatrends',
    'fundDescription': 'Ibercaja Pensiones Megatrends, Fondo de Pensiones',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4471-F1612-G0079',
    'name': 'Plan Ibercaja de Pensiones Valor 2027',
    'fundDescription': 'IBERCAJA PENSIONES VALOR, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N4582-F1613-G0079',
    'name': 'Plan Ibercaja de Pensiones Horizonte 2028',
    'fundDescription': 'IBERCAJA PENSIONES PORVENIR, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N5377-F1882-G0079',
    'name': 'Plan Ibercaja de Pensiones Confianza Sostenible',
    'fundDescription': 'Ibercaja Pensiones Confianza, F.P.',
    'managerId': 'G0079',
    'managerName': 'Ibercaja Pensión, S.A., E.G.F.P.'
  },
  {
    'id': 'N0010-F0002-G0080',
    'name': 'PLAN DE PENSIONES SANTANDER ASG RENTA FIJA',
    'fundDescription': 'SANTANDER ASG RENTA FIJA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1031-F0002-G0080',
    'name': 'OPENBANK RENTA FIJA',
    'fundDescription': 'SANTANDER ASG RENTA FIJA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0003-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER FUTURO 2020 PENSIONES, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0744-F0010-G0080',
    'name': 'FUTURO AGRARIO',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1888-F0010-G0080',
    'name': 'AGROPECUARIO CASTILLA Y LEON',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0799-F0010-G0080',
    'name': 'DOMINICAS DE LA ANUNCIATA',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1905-F0010-G0080',
    'name': 'DOM.ANUNCIATA PROV.STO. DOMINGO',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0892-F0010-G0080',
    'name': 'CARMELITAS DE SAN JOSE',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0889-F0010-G0080',
    'name': 'DOMINICAS SAN RAIMUNDO DE PEÑAFORT',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0750 -F0010-G0080',
    'name': 'SAGRADA FAMILIA DE VILLEFRANCHE',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1727-F0010-G0080',
    'name': 'CONGREGACION DEL VERBO DIVINO',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0977-F0010-G0080',
    'name': 'CIA. MARIA NTRA SRA. PROV ANDALUCIA',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1676-F0010-G0080',
    'name': 'DOM.ANUNCIATA PROV.STA.CATALINA',
    'fundDescription': 'EUROPOPULAR-VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0195-F0012-G0080',
    'name': 'PLAN DE PENSIONES COAIB',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0232-F0012-G0080',
    'name': 'DISTRITO DE MADRID-PLADIMA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1155-F0012-G0080',
    'name': 'COOPERATIVA LAVINIA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1701-F0012-G0080',
    'name': 'COL. MEDICOS VALENCIA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1995-F0012-G0080',
    'name': 'FARMACEUTICOS DE MURCIA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2797-F0012-G0080',
    'name': 'P.P. FAPE',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3190-F0012-G0080',
    'name': 'SANTANDER UNIVERSIDADES RENTA FIJA MIXTA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3773-F0012-G0080',
    'name': 'COLEGIO OFICIAL DE FARMACEUTICOS DE ALICANTE P.P.',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3960-F0012-G0080',
    'name': 'PP ASOCIADO DE LA ASOCIACION DE MUTUALISTAS ACTIVOS DE LA MUTUALIDAD A PRIMA FIJA DE PREVISION SOCIAL DEL PERSONAL DE LA EXTINGUIDA COMISARIA GENERAL DE ABASTECIMIENTOS Y TRANSPORTES',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4848-F0012-G0080',
    'name': 'PP COLEGIO DE FISOTERAPEUTAS DE ANDALUCIA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4849-F0012-G0080',
    'name': 'PP COL.LEGI DE FISIOTERAPEUTES DE LA COMUNITAT VALENCIANA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4872-F0012-G0080',
    'name': 'PP DEL COLEGIO NACIONAL DE OPTICOS-OPTOMESTRISTAS',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5004-F0012-G0080',
    'name': 'PP DEL COLEGIO OFICIAL DE GRADUADOS SOCIALES DE VALENCIA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0189-F0012-G0080',
    'name': 'PP COMERÇPLA PLA DE PENSIONS',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1074-F0012-G0080',
    'name': 'P.P. COLEGIO MEDICOS BADAJOZ',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5343-F0012-G0080',
    'name': 'PLAN DE PENSIONES DEL COLEGIO DE MÉDICOS DE ÁVILA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5397-F0012-G0080',
    'name': 'COLEGIO OFICIAL FARMACEUTICOS DE BURGOS PP',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1554-F0012-G0080',
    'name': 'COL.GRADUADOS SOCIALES ALICANTE',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3010-F0012-G0080',
    'name': 'ASOC.EMP.COFARES RENTA FIJA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4853-F0012-G0080',
    'name': 'MEDICOS DE SALAMANCA, VALLADOLID Y PALENCIA RENTA FIJA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1192-F0012-G0080',
    'name': 'DIPLOM.EN ENFERMERIA MADRID',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1416-F0012-G0080',
    'name': 'ASOCIACION EMPLEADOS COFARES',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1113-F0012-G0080',
    'name': 'BANCO PASTOR II',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1114-F0012-G0080',
    'name': 'BANCO PASTOR I',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA FIJA MIXTA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3012-F0014-G0080',
    'name': 'PLAN EMPLEADOS COMMERZBANK ESPAñA',
    'fundDescription': 'GENERALI EMPLEO UNO, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0054-G0080',
    'name': '---',
    'fundDescription': 'BANESTO, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0235-F0096-G0080',
    'name': 'PPE DEL GRUPO LUFTHANSA, L.A.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0254-F0096-G0080',
    'name': 'APPLE MARKETING IBERIA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0255-F0096-G0080',
    'name': 'MARQUES DE RISCAL I',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0319-F0096-G0080',
    'name': 'PP DE LOS EMPLEADOS DE LAS EMPRESAS DE TUI ESPAÑA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0457-F0096-G0080',
    'name': 'CONDOR FLUGDIENST GMBH',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0458-F0096-G0080',
    'name': 'AMBASSADOR TOURS',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0749-F0096-G0080',
    'name': 'AYUNTAMIENTO DE CARMONA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0782-F0096-G0080',
    'name': 'CEMENTOS ALFA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0941-F0096-G0080',
    'name': 'AGUAS DE MONTEJURRA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1863-F0096-G0080',
    'name': 'TRABAJADORES BRAUN ESPAÑA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1864-F0096-G0080',
    'name': 'P.P. EMPLEADOS DE DIAGEO ESPAÑA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2014-F0096-G0080',
    'name': 'P.P. BUDELPACK SANT JUST, S.L.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2015-F0096-G0080',
    'name': 'DE LOS EMPLEADOS DE GENERAL MILLS IBÉRICA, S.A.U.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2290-F0096-G0080',
    'name': 'DE LOS EMPL. DE BURGUER KING ESPAÑA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2344-F0096-G0080',
    'name': 'Plan de Pensiones del Sistema de Empleo para los trabajadores de CHEVRON ESPAÑA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2645-F0096-G0080',
    'name': 'UNIVAR IBERIA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2835-F0096-G0080',
    'name': 'PLAN DE EMPLEADOS DE KENFARMA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3068-F0096-G0080',
    'name': 'CSG SILICON IBERIA, S.L.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3423-F0096-G0080',
    'name': 'GRUPO CIATESA  PLAN DE PROMOCION CONJUNTA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3476-F0096-G0080',
    'name': 'SHELL ATLANTICA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3477-F0096-G0080',
    'name': 'DISA PENINSULA, S.L.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3515-F0096-G0080',
    'name': 'LIMANCAR',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3865-F0096-G0080',
    'name': 'PLAN DE PENSIONES DE EMPLEO HANES BRANDS, S.A.U.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3143-F0096-G0080',
    'name': 'PP GENERAL MILLS SAN ADRIAN, S.L.U',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5222-F0096-G0080',
    'name': 'ENGIE CARTAGENA,S.L.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5259-F0096-G0080',
    'name': 'PP EMPLEO DE LAS EMPRESAS DEL GRUPO HIPRA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5302-F0096-G0080',
    'name': 'PP EMPLEO ALCON HEALTHCARE, SA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2999-F0096-G0080',
    'name': 'P.P. EMPLEADOS NETCRACKER TECHNOLOGY SPAIN',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5445-F0096-G0080',
    'name': 'PLAN DE PENSIONES DEL PARLAMENTO DE CANARIAS',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0331-F0096-G0080',
    'name': 'PLAN DE PENSIONES ALCON CUSI APORTACION DEFINIDA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5467-F0096-G0080',
    'name': 'PP DE EMPLEO SIEGFRIED BARBERA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4857-F0096-G0080',
    'name': 'NATIXIS CONSERVADOR',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2355-F0096-G0080',
    'name': 'P.P.DE LOS EMPLEADOS ESCUELA EUROPEA ADMON.DE EMPRESAS - EAP',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2535-F0096-G0080',
    'name': 'AYUNTAMENT DE FIGUERES',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1429-F0096-G0080',
    'name': 'SIEMENS PLM SOLUTIONS',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2276-F0096-G0080',
    'name': 'AIR FRANCE',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3992-F0096-G0080',
    'name': 'ASOCIACION ESPAÑOLA DE BANCA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 1, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0156-F0097-G0080',
    'name': 'SANTANDER INVERPLUS RENTA VARIABLE MIXTA A',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0183-F0097-G0080',
    'name': 'ACOFAR',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0260-F0097-G0080',
    'name': 'ASAJA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1217-F0097-G0080',
    'name': 'PPE GRUPO PUMSA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1381-F0097-G0080',
    'name': 'ITIPLAN II',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1550-F0097-G0080',
    'name': 'ASOCIADO DE AUSBANC',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1551-F0097-G0080',
    'name': 'MUTUALIDAD CUERPO CORREDORES COMERCIO',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1638-F0097-G0080',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE ASTELLAS PHARMA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1908-F0097-G0080',
    'name': 'AIAF MERCADO DE RENTA FIJA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1922-F0097-G0080',
    'name': 'PP DE EMPLEO HEXION SPECIALTY CHEMICAL BARBASTRO, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2346-F0097-G0080',
    'name': 'DE LOS EMPLEADOS DE IECA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2348-F0097-G0080',
    'name': 'DE LOS EMPLEADOS DE OFICEMEN',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2361-F0097-G0080',
    'name': 'DE I. F. F. BENICARLO, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2652-F0097-G0080',
    'name': 'P.P. EMPLEADOS DE BUCK HEISSMANN',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2654-F0097-G0080',
    'name': 'P.P. ASOCIADO DE LA FEMP',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2959-F0097-G0080',
    'name': 'PP DE EMPLEO DE PROMOCION CONJUNTA DEL GRUPO GALP',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3989-F0097-G0080',
    'name': 'PPE NUTRITION & SANTE IBERIA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4240-F0097-G0080',
    'name': 'Exxonmobil Chemical Iberia, S.L.U.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5028-F0097-G0080',
    'name': 'PP DE PROMOCIÓN CONJUNTA SANTANDER EMPRESAS RVM 2',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5429-F0097-G0080',
    'name': 'PP PROMOCIÓN CONJUNTA DE ANGELINI',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5486-F0097-G0080',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS DEL AYUNTAMIENTO DE SABIÑÁNIGO',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3487-F0125-G0080',
    'name': 'EUROPOPULAR EMPLEO PYMES IV',
    'fundDescription': 'EUROPOPULAR COLECTIVO',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1540-F0162-G0080',
    'name': 'PLAN DE PENSIONES AMEX I',
    'fundDescription': 'PASTOR MIXTO, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3959-F0164-G0080',
    'name': 'RAP PP ASOCIADO RETIREMENT AVIATION',
    'fundDescription': 'SANTANDER AHORRO 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2972-F0165-G0080',
    'name': 'WIZINK GESTIÓN, A.I.E',
    'fundDescription': 'SANTANDER AHORRO 8, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3079-F0165-G0080',
    'name': 'CITIBANK INTERNACIONAL PLC, SUCURSAL EN ESPAÑA',
    'fundDescription': 'SANTANDER AHORRO 8, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3080-F0165-G0080',
    'name': 'PP DE LOS EMPLEADOS DE CITISPAIN, S.A.',
    'fundDescription': 'SANTANDER AHORRO 8, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3301-F0165-G0080',
    'name': 'CITIFIN, S.A. EFC',
    'fundDescription': 'SANTANDER AHORRO 8, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0167-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER AHORRO 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2739-F0167-G0080',
    'name': 'PP SIST. EMPLEO DE PROM. CONJ. DEL GRUPO PRAXAIR',
    'fundDescription': 'SANTANDER AHORRO 2, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0190-F0168-G0080',
    'name': 'PLANHOTEL',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0448-F0168-G0080',
    'name': 'SANTANDER INVERPLUS RENTA FIJA MIXTA A',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0974-F0168-G0080',
    'name': 'FUNDACION CENTRO SAN CEBRIÁN PLAN DE PROMOCION CONJUNTA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1206-F0168-G0080',
    'name': 'AJUNTAMENT SANT FELIU GUIXOLS',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1424-F0168-G0080',
    'name': 'AYUNTAMIENTO DE VIVEIRO',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1592-F0168-G0080',
    'name': 'AJUNTAMENT CASTELL-PLATJA DARO',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2322-F0168-G0080',
    'name': 'TAPSER',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2544-F0168-G0080',
    'name': 'P.P. EMPLEO GRUPO VOLCONSA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2598-F0168-G0080',
    'name': 'PP DE EMPLEADOS DE UNISYS ESPAÑA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2649-F0168-G0080',
    'name': 'P.P. DE EMPLEO ARRAN',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2943-F0168-G0080',
    'name': 'SEMPSA JOYERIA PLATERIA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2947-F0168-G0080',
    'name': 'HISPANO FOXFILM, S.A.E.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2949-F0168-G0080',
    'name': 'TWENTIETH CENTURY FOX HOME ENTERTAINMENT ESP.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2998-F0168-G0080',
    'name': 'DIPUTACION PROVINCIAL DE SEGOVIA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3019-F0168-G0080',
    'name': 'PLAN DE PENSIONES GRUPO ONO',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3296-F0168-G0080',
    'name': 'LABORATORIOS CASEN FLEET, S.L.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3299-F0168-G0080',
    'name': 'P.P.E. CIA. GENERAL DE CANTERAS, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3328-F0168-G0080',
    'name': 'COMBE EUROPA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3397-F0168-G0080',
    'name': 'PP DE LOS EMPLEADOS DE ISDEFE',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3518-F0168-G0080',
    'name': 'GRUPO COLGATE PALMOLIVE PLAN DE PROMOCION CONJUNTA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3565-F0168-G0080',
    'name': 'PLAN DE PENSIONES CIN VALENTINE, S.A.U',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3607-F0168-G0080',
    'name': 'GRUPO LUNDBECK PLAN DE PROMOCION CONJUNTA',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA FIJA MIXTA 2',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4586-F0196-G0080',
    'name': 'PP BANIF G. TIPO FIJO',
    'fundDescription': 'SANTANDER G RENTA FIJA 6 PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0298-F0206-G0080',
    'name': 'RED ELÉCTRICA DE ESPAÑA',
    'fundDescription': 'SANTANDER AHORRO 5 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0699-F0206-G0080',
    'name': 'PLAN DE PENSIONES REFINITIV, S.L.',
    'fundDescription': 'SANTANDER AHORRO 5 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1450-F0206-G0080',
    'name': 'PPE OPERADOR MERCADO IBERICO DE ENERGIA-POLO ESPAÑOL, SA',
    'fundDescription': 'SANTANDER AHORRO 5 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2030-F0206-G0080',
    'name': 'SANTANDER 5',
    'fundDescription': 'SANTANDER AHORRO 5 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2384-F0206-G0080',
    'name': 'RED ELÉCTRICA TELECOMUNICACIONES',
    'fundDescription': 'SANTANDER AHORRO 5 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0284-F0215-G0080',
    'name': 'PREVIFUTURO',
    'fundDescription': 'PREVIFUTURO, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0181-F0226-G0080',
    'name': 'SARA LEE HOUSEHOLD&BODY CARE ESPAÑA S.L.',
    'fundDescription': 'D.E. COFFEE & TEA SOUTHERN EUROPE PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0715-F0226-G0080',
    'name': 'PP DE COFFEE & TEA SOUTHERN EUROPE',
    'fundDescription': 'D.E. COFFEE & TEA SOUTHERN EUROPE PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4031-F0226-G0080',
    'name': 'P.P.SARA LEE IBERIA S.L.',
    'fundDescription': 'D.E. COFFEE & TEA SOUTHERN EUROPE PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0340-F0233-G0080',
    'name': 'ANTONIO PUIG, S.A.',
    'fundDescription': 'DIAGONAL, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0437-F0233-G0080',
    'name': 'SOCIEDAD BARCELONESA DE PROMOCION DE EMPRESAS',
    'fundDescription': 'DIAGONAL, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0762-F0233-G0080',
    'name': 'KINESIA, S.A.',
    'fundDescription': 'DIAGONAL, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4570-F0254-G0080',
    'name': 'PP MI PLAN SANTANDER DECIDIDO',
    'fundDescription': 'SANTANDER PERFIL DECIDIDO PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0331-G0080',
    'name': '---',
    'fundDescription': 'BANESTO RENTA FIJA PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0206-F0339-G0080',
    'name': 'TETRA PAK HISPANIA',
    'fundDescription': 'SANTANDER AHORRO 7 FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1284-F0339-G0080',
    'name': 'TETRA PAK ENVASES',
    'fundDescription': 'SANTANDER AHORRO 7 FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0349-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER RTA VARIABLE MIXTA PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1880-F0366-G0080',
    'name': 'SANTANDER INVERPLUS RENTA VARIABLE MIXTA C',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2719-F0366-G0080',
    'name': 'SANTANDER EMPRESAS R.V.M.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5256-F0366-G0080',
    'name': 'PP VICTORIA ASSET MANAGEMENT',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2905-F0366-G0080',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE HIJOS DE RIVERA, S.A.',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5220-F0366-G0080',
    'name': 'PPE MICRO FOCUS SOFTWARE SPAIN, SL',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4857-F0366-G0080',
    'name': 'NATIXIS DECIDIDO',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5484 -F0366-G0080',
    'name': 'PLAN DE PENSIONES DE EMPLEO CHINA THREE GORGES SPAIN SLU',
    'fundDescription': 'SANTANDER COLECTIVOS RENTA VARIABLE MIXTA 3, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0971-F0376-G0080',
    'name': 'MUTUA UNIVERSAL PLAN DE PENSIONES',
    'fundDescription': 'MUTUA UNIVERSAL MUGENAT, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0414-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER CONSOLIDACION PENSIONES FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2382-F0415-G0080',
    'name': 'DE AVAYA COMUNICACION ESPAÑA S.L.',
    'fundDescription': 'SANTANDER AHORRO 10, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2655-F0415-G0080',
    'name': 'CLUB DE EMPLEADOS DE MICROELECTRONICA',
    'fundDescription': 'SANTANDER AHORRO 10, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4571-F0416-G0080',
    'name': 'PLAN DE PENSIONES MI PLAN SANTANDER CRECIMIENTO',
    'fundDescription': 'SANTANDER PERFIL CRECIMIENTO PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5220-F0446-G0080',
    'name': 'SUBPLAN MICRO FOCUS CRECIMIENTO',
    'fundDescription': 'SANTANDER AHORRO 68 FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0454-G0080',
    'name': '---',
    'fundDescription': 'BANIF BOLSA GLOBAL PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1349-F0457-G0080',
    'name': 'PP SANTANDER SOSTENIBLE RENTA VARIABLE GLOBAL',
    'fundDescription': 'SANTANDER SOSTENIBLE RENTA VARIABLE GLOBAL PENSIONES, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1223-F0458-G0080',
    'name': 'CEPSA',
    'fundDescription': 'GRUPO CEPSA, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1358-F0458-G0080',
    'name': 'PLAN DE PENSIONES DE CEPSA QUÍMICA',
    'fundDescription': 'GRUPO CEPSA, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2328-F0458-G0080',
    'name': 'PROM. CONJUNTA EMP. GRUPO CEPSA',
    'fundDescription': 'GRUPO CEPSA, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0488-G0080',
    'name': '---',
    'fundDescription': 'BANESTO RENTA VARIABLE GLOBAL PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0853-F0492-G0080',
    'name': 'P.P. AYUNTAMIENTO DE TORREJON',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1172-F0492-G0080',
    'name': 'PP DEL PERSONAL DE ZIMMER BIOMET SPAIN',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1537-F0492-G0080',
    'name': 'SIALSA',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1562-F0492-G0080',
    'name': 'COLEGIO HEIDELBERG',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2312-F0492-G0080',
    'name': 'FUNDACIO JOSEP FINESTRES',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3164-F0492-G0080',
    'name': 'GRUPO CALCINOR PLAN DE EMPRESAS',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3170-F0492-G0080',
    'name': 'SANTANDER EMPRESAS RFM',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3171-F0492-G0080',
    'name': 'EURALIS SEMILLAS, S.A.',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3331-F0492-G0080',
    'name': 'CANARIENSIS',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3549-F0492-G0080',
    'name': 'PLAN DE PENSIONES HELLA S.A.',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3586-F0492-G0080',
    'name': 'BANESTO PYMES DE LEVANTE RENTA FIJA MIXTA',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3595-F0492-G0080',
    'name': 'CIT GROUP (NORDIC) AB., P.P.',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3616-F0492-G0080',
    'name': 'P.P. PROMOCIO CONJUNTA PERSONAL AJUNTAMENT DE VIC Y SUS ORGANISMOS',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3719-F0492-G0080',
    'name': 'EXCMO. CABILDO INSULAR DE EL HIERRO',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4737-F0492-G0080',
    'name': 'PP EMPLEADOS DE BIOMET 3i DENTAL IBÉRICA',
    'fundDescription': 'SANTANDER AHORRO 16 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0496-G0080',
    'name': '---',
    'fundDescription': 'BANESTO RENTA FIJA MIXTA 1 PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0497-G0080',
    'name': '---',
    'fundDescription': 'BANESTO RENTA VARIABLE MIXTA PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1548-F0555-G0080',
    'name': 'PLAN DE PENSIONES SANTANDER FUTURE WEALTH',
    'fundDescription': 'SANTANDER FUTURE WEALTH PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1815-F0593-G0080',
    'name': 'VODAFONE PLAN DE PENDIONES',
    'fundDescription': 'SANTANDER AHORRO 17, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1597-F0594-G0080',
    'name': 'PPPC de SYNGENTA ESPAÑA, S.A.',
    'fundDescription': 'SANTANDER AHORRO 18, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1593-F0595-G0080',
    'name': 'STAHL IBÉRICA, S.L.',
    'fundDescription': 'SANTANDER AHORRO 19 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1916-F0596-G0080',
    'name': 'SHELL ESPAÑA',
    'fundDescription': 'SHELL ESPAÑA PENSIONES,FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0597-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER GESTION ALTERNATIVA PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0599-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER G RENTA VARIABLE 6 PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4550-F0603-G0080',
    'name': 'PP MI PROYECTO SANTANDER 2040',
    'fundDescription': 'MI PROYECTO SANTANDER 2040, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5282-F0603-G0080',
    'name': 'PLAN DE PENSIONES EMPLEADOS SANTANDER 2040',
    'fundDescription': 'MI PROYECTO SANTANDER 2040, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5026-F0630-G0080',
    'name': 'PPC FUNDACIÓN OBRA SOCIAL Y MONTE DE PIEDAD DE MADRID',
    'fundDescription': 'SANTANDER AHORRO 26, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0631-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER FUTURO 2025, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5508-F0687-G0080',
    'name': 'PPES, SANTANDER AUTÓNOMOS RVM',
    'fundDescription': 'EUROPOPULAR EMPLEO FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0872-F0688-G0080',
    'name': 'COLEGIO DE MEDICOS DE MADRID',
    'fundDescription': 'AMIGOS MEDICOS MADRID',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1963-F0694-G0080',
    'name': 'PLAN DE PENSIONES SANTANDER ASG RENTA VARIABLE EUROPA',
    'fundDescription': 'SANTANDER ASG RENTA VARIABLE EUROPA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2033-F0694-G0080',
    'name': 'PLAN DE PENSIONES SPB BOLSA EUROPA',
    'fundDescription': 'SANTANDER ASG RENTA VARIABLE EUROPA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3355-F0694-G0080',
    'name': 'OPENBANK RENTA VARIABLE EUROPA',
    'fundDescription': 'SANTANDER ASG RENTA VARIABLE EUROPA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2337-F0696-G0080',
    'name': 'DE EMPLEO DOW CHEMICAL IBÉRICA',
    'fundDescription': 'SANTANDER AHORRO 23, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3073-F0704-G0080',
    'name': 'FERROCARRILS DE LA GENERALITAT VALENCIANA',
    'fundDescription': 'EMPLEADOS DE FFCC DE LA GENERALITAT VALENCIANA, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0729-F0706-G0080',
    'name': 'TRABAJ. AYUNT. ALCORCON',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0832-F0706-G0080',
    'name': 'AYTO. DE ARCHIDONA',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0966-F0706-G0080',
    'name': 'DIPUTACION DE CUENCA PLAN DE PROMOCION CONJUNTA',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0978-F0706-G0080',
    'name': 'AYUNTAMIENTO DE SAN VICENTE DEL RASPEIG, PLAN DE PENSIONES',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1613-F0706-G0080',
    'name': 'BINTER CANARIAS, PLAN DE PENSIONES',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2684-F0706-G0080',
    'name': 'RIJK ZWAAN',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3286-F0706-G0080',
    'name': 'AJUNTAMENT D´ARENYS DE MUNT',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3343-F0706-G0080',
    'name': 'P.P AUTORIDAD PORTUARIA DE VIGO',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3572-F0706-G0080',
    'name': 'PP DE PROMOCION CONJUNTA DEL AYUNTAMIENTO DE ALICANTE Y ORGANISMOS DEPENDIENTE',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4416-F0706-G0080',
    'name': 'PP PROM.CONJUNTA SANTANDER EMPRESAS RFM2',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4587-F0706-G0080',
    'name': 'ERLING KLINGER SAU',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4631-F0706-G0080',
    'name': 'LACTALIS NUTRICION IBERIA, SLU',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5114-F0706-G0080',
    'name': 'PP. PROMOCION CONJUNTA DE METROVACESA',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5277-F0706-G0080',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE PELI PRODUCTS',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5301-F0706-G0080',
    'name': 'PP ALLFUNDS BANK',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1407-F0706-G0080',
    'name': 'AYUNTAMIENTO DE ALBATERA, PLAN DE PENSIONES',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5008-F0706-G0080',
    'name': 'PP DE LA COMPAÑÍA DE CORTO Y MEDIO RADIO IBERIA EXPRESS, S.A.',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1697  -F0706-G0080',
    'name': 'PLAN DE PENSIONES SISTEMA PENSIONES SERTIC',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2905-F0706-G0080',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO DE HIJOS DE RIVERA, S.A.',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1873-F0706-G0080',
    'name': 'CBRE PROJECT MANAGEMENT, S.A.',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2479-F0706-G0080',
    'name': 'ILTMO.AYUNTAMIENTO DE ROTA',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1610-F0706-G0080',
    'name': 'PLA DE PENSIONS DE L´AJUNTAMENT DE TARRAGONA',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4298-F0706-G0080',
    'name': 'MODUS ROTA',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5029-F0706-G0080',
    'name': 'ESCOLA SOLC, P.P.',
    'fundDescription': 'SANTANDER AHORRO 15, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3382-F0757-G0080',
    'name': 'SANTANDER DIVIDENDO',
    'fundDescription': 'SANTANDER DIVIDENDO PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4547-F0758-G0080',
    'name': 'PP MI PROYECTO SANTANDER 2035',
    'fundDescription': 'MI PROYECTO SANTANDER 2035, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5281-F0758-G0080',
    'name': 'PLAN DE PENSIONES EMPLEADOS SANTANDER 2035',
    'fundDescription': 'MI PROYECTO SANTANDER 2035, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0772-G0080',
    'name': '---',
    'fundDescription': 'BANESTO PORVENIR 2025 PENSIONES F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2588-F0776-G0080',
    'name': 'DE LOS EMPLEADOS DE TARGOBANK',
    'fundDescription': 'EUROPOPULAR INTEGRAL FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2589-F0776-G0080',
    'name': 'WIZINK BANK S.A., PP. EMPLEO',
    'fundDescription': 'EUROPOPULAR INTEGRAL FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4549-F0785-G0080',
    'name': 'PP MI PROYECTO SANTANDER 2025',
    'fundDescription': 'MI PROYECTO SANTANDER 2025, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5279-F0785-G0080',
    'name': 'PLAN DE PENSIONES EMPLEADOS SANTANDER 2025',
    'fundDescription': 'MI PROYECTO SANTANDER 2025, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0786-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER G RENTA FIJA 4 PENSIONES F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F0788-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER FUTURO CONSERVADOR PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5507-F1066-G0080',
    'name': 'PPES, SANTANDER AUTÓNOMOS RFM',
    'fundDescription': 'EUROPOPULAR EMPLEO II FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4159-F1067-G0080',
    'name': 'PLADIMA II',
    'fundDescription': 'EUROPOPULAR ASOCIADO VIDA',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0395-F1070-G0080',
    'name': 'EMPLEADOS DE TEREOS STARCH & SWEETENERS IBERIA, S.A.U.',
    'fundDescription': 'EUROPOPULAR EMPLEO EVOLUCION, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0716-F1070-G0080',
    'name': 'MONTELNOR',
    'fundDescription': 'EUROPOPULAR EMPLEO EVOLUCION, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3591-F1072-G0080',
    'name': 'PP SANTANDER ASG RENTA FIJA CORTO PLAZO',
    'fundDescription': 'SANTANDER ASG RENTA FIJA CORTO PLAZO, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3634-F1079-G0080',
    'name': 'PLAN DE PENSIONES SANTANDER ASG ACCIONES ESPAÑOLAS',
    'fundDescription': 'SANTANDER ASG ACCIONES ESPAÑOLAS',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1314-F1082-G0080',
    'name': 'UNIVERS. MURCIA',
    'fundDescription': 'UNIVERSIDAD DE MURCIA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1881-F1116-G0080',
    'name': 'SANTANDER INVERPLUS RENTA FIJA',
    'fundDescription': 'SANTANDER AHORRO 27, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1879-F1117-G0080',
    'name': 'SANTANDER INVERPLUS RENTA VARIABLE INTERNACIONAL',
    'fundDescription': 'SANTANDER AHORRO 28, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3367-F1119-G0080',
    'name': 'PROMOCION CONJUNTA DE LAS EMPRESAS NOVARTIS',
    'fundDescription': 'SANTANDER AHORRO 30, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1146-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER CONSOLIDACION 2 PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1156-G0080',
    'name': '---',
    'fundDescription': 'BANESTO AHORRO 1, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4052-F1157-G0080',
    'name': 'BANESTO WELCOME 2015, PP',
    'fundDescription': 'BANESTO PORVENIR 2015 PENSIONES F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4051-F1158-G0080',
    'name': 'BANESTO WELCOME 2010, PP',
    'fundDescription': 'SANTANDER SOSTENIBLE RENTA FIJA 1-3 PENSIONES, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4725-F1158-G0080',
    'name': 'PP SANTANDER SOSTENIBLE RENTA FIJA 1-3',
    'fundDescription': 'SANTANDER SOSTENIBLE RENTA FIJA 1-3 PENSIONES, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0309-F1159-G0080',
    'name': 'GENERAL ELECTRIC HEALTHCARE ESPAÑA, S.A.',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1628-F1159-G0080',
    'name': 'GENERAL ELECTRIC LIGHTING / APPLIANCES',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2687-F1159-G0080',
    'name': 'GE ENERGY SPAIN',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3570-F1159-G0080',
    'name': 'GENERAL ELECTRIC POWER MANAGEMENT',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3573-F1159-G0080',
    'name': 'GENERAL ELECTRIC CAPITAL BANK',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3718-F1159-G0080',
    'name': 'PP GE PENSION PLAN PROMOCION CONJUNTA',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3795-F1159-G0080',
    'name': 'GENERAL ELECTRIC INTERNATIONAL, INC',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3817-F1159-G0080',
    'name': 'GE  WIND ENERGY',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3832-F1159-G0080',
    'name': 'TIP TRAILERS ESPAÑA S.A.',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3961-F1159-G0080',
    'name': 'UNIVERSAL PICTURES IBERIA',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4199-F1159-G0080',
    'name': 'GE HEALTHCARE BIO-SCIENCE',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4275-F1159-G0080',
    'name': 'PLAN DE PENSIONES DE JENBACHER',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4362-F1159-G0080',
    'name': 'P.P. UNIVERSAL PICTURES INTERNATIONAL SPAIN',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3155-F1159-G0080',
    'name': 'GE HEALTHCARE EUROPE GMBH',
    'fundDescription': 'SANTANDER AHORRO 38 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1598-F1160-G0080',
    'name': 'ASTRAZENECA FARMACEUTICA PLAN DE GRUPO',
    'fundDescription': 'GRUPO ASTRAZENECA CONSERVADOR PENSIONES, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3828-F1161-G0080',
    'name': 'GOBIERNO DE CANTABRIA',
    'fundDescription': 'GOBIERNO DE CANTABRIA FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4499-F1162-G0080',
    'name': 'PP RETORNO ABSOLUTO',
    'fundDescription': 'SANTANDER REVALORIZACION PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1609-F1170-G0080',
    'name': 'PLAN DE PENSIONES EMPLEADOS HSBC',
    'fundDescription': 'AHORROPENSION DIECISEIS, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3730-F1204-G0080',
    'name': 'PLAN DE PENSIONES SPB MUTUACTIVOS',
    'fundDescription': 'SANTANDER AHORRO 45, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1207-G0080',
    'name': '---',
    'fundDescription': 'SCH Ahorro 41 Fondo de Pensiones',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1209-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER AHORRO 46 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1210-G0080',
    'name': '---',
    'fundDescription': 'SANTANDER MULTIGESTION 2005 PENSIONES, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3684-F1212-G0080',
    'name': 'PREVIFUTURO II',
    'fundDescription': 'PREVIFUTURO II F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4548-F1213-G0080',
    'name': 'PLAN DE PENSIONES MI PROYECTO SANTANDER SMART',
    'fundDescription': 'MI PROYECTO SANTANDER 2045 PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5283-F1213-G0080',
    'name': 'PLAN DE PENSIONES EMPLEADOS SANTANDER 2045',
    'fundDescription': 'MI PROYECTO SANTANDER 2045 PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4546-F1214-G0080',
    'name': 'PP MI PROYECTO SANTANDER 2030',
    'fundDescription': 'MI PROYECTO SANTANDER 2030, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5280-F1214-G0080',
    'name': 'PLAN DE PENSIONES EMPLEADOS SANTANDER 2030',
    'fundDescription': 'MI PROYECTO SANTANDER 2030, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4887-F1215-G0080',
    'name': 'PP SANTANDER CUMBRE 2023',
    'fundDescription': 'SANTANDER CUMBRE 2023 PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3892-F1217-G0080',
    'name': 'SANTANDER BOLSA INFLACION 1, PP',
    'fundDescription': 'SANTANDER G BOLSA INFLACION 1 FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1231-G0080',
    'name': '---',
    'fundDescription': 'BANESTO AHORRO 6 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1272-G0080',
    'name': '---',
    'fundDescription': 'BTO AHORRO 7 FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': '----F1275-G0080',
    'name': '---',
    'fundDescription': 'BANESTO AHORRO 10,.FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3950-F1278-G0080',
    'name': 'PP SANTANDER EMPLEADOS',
    'fundDescription': 'SANTANDER EMPLEADOS F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1598-F1282-G0080',
    'name': 'EMP. DE GRUPO ASTRAZENECA FARMA',
    'fundDescription': 'GRUPO ASTRAZENECA DECIDIDO PENSIONES, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N2651-F1283-G0080',
    'name': 'PP DE EMPLEO UNIVERDIAD DE LA RIOJA',
    'fundDescription': 'SANTANDER UNIVERSIDADES PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3845-F1283-G0080',
    'name': 'UNIVERSIDAD DE VALLADOLID',
    'fundDescription': 'SANTANDER UNIVERSIDADES PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3958-F1283-G0080',
    'name': 'PP.UNIVERSIDAD DE PABLO OLAVIDE',
    'fundDescription': 'SANTANDER UNIVERSIDADES PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3988-F1283-G0080',
    'name': 'PLAN DE PENSIONES UNIVERSIDAD DE EXTREMADURA',
    'fundDescription': 'SANTANDER UNIVERSIDADES PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4027-F1283-G0080',
    'name': 'PLAN DE PENSIONES UNIVERSIDAD DE CASTILLA LA MANCHA',
    'fundDescription': 'SANTANDER UNIVERSIDADES PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4248-F1283-G0080',
    'name': 'PLAN DE PENSIONES UNIVERSIDAD DE JAEN',
    'fundDescription': 'SANTANDER UNIVERSIDADES PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1032-F1421-G0080',
    'name': 'OPENBANK RENTA MIXTA',
    'fundDescription': 'SANTANDER PERFIL MODERADO F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1845-F1421-G0080',
    'name': 'SANTANDER AGRARIO CASTILLA Y LEON',
    'fundDescription': 'SANTANDER PERFIL MODERADO F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4574-F1421-G0080',
    'name': 'PP MI PLAN SANTANDER MODERADO',
    'fundDescription': 'SANTANDER PERFIL MODERADO F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5076-F1422-G0080',
    'name': 'PP. MI PLAN SANTANDER  ACUMULACION 2029',
    'fundDescription': 'SANTANDER G APORTACIONES 2026 PENSIONES, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5469-F1422-G0080',
    'name': 'PP SANTANDER OBJETIVO 2029',
    'fundDescription': 'SANTANDER G APORTACIONES 2026 PENSIONES, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5482-F1423-G0080',
    'name': 'SANTANDER RENTA FIJA 2029, PP',
    'fundDescription': 'SANTANDER RENTA FIJA 2029 PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3891-F1440-G0080',
    'name': 'P.P. DE PROMOCION CONJUNTA DEL GRUPO PHILIP MORRIS',
    'fundDescription': 'SANTANDER AHORRO 61 F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5125-F1478-G0080',
    'name': 'EUROPOPULAR AHORRO I',
    'fundDescription': 'EUROPOPULAR CONSOLIDADO VII',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5165-F1489-G0080',
    'name': 'EUROPOPULAR AHORRO II',
    'fundDescription': 'EUROPOPULAR CONSOLIDADO VIII',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4306-F1516-G0080',
    'name': 'EP CONSOLIDADO ACCIONES',
    'fundDescription': 'EUROPOPULAR CONSOLIDADO X',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4382-F1550-G0080',
    'name': 'EUROPOPULAR AHORRO IV',
    'fundDescription': 'EUROPOPULAR CONSOLIDADO XIII',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4462-F1573-G0080',
    'name': 'EUROPOPULAR AHORRO III',
    'fundDescription': 'EUROPOPULAR CONSOLIDADO XV',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4527-F1629-G0080',
    'name': 'EP. CONSOLIDADO ACCIONES II',
    'fundDescription': 'EUROPOPULAR CONSOLIDADO XVII',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4678-F1697-G0080',
    'name': 'PP SANTANDER ASG RENTA VARIABLE NORTEAMERICA',
    'fundDescription': 'SANTANDER ASG RENTA VARIABLE NORTEAMERICA PENSIONES, FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4690-F1758-G0080',
    'name': 'PLAN DE PENSIONES ASOCIADO 2034',
    'fundDescription': 'AHORROPENSION OCHENTA, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4846-F1758-G0080',
    'name': 'PP ASOCIADO AJAESOL',
    'fundDescription': 'AHORROPENSION OCHENTA, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4800-F1765-G0080',
    'name': 'PP SANTANDER 100 POR 100 10',
    'fundDescription': 'SANTANDER G AHORRO 10, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N5501-F1785-G0080',
    'name': 'PPES, PLANFINE',
    'fundDescription': 'EUROPOPULAR EMPLEO RENTA FIJA FLEXIBLE FP',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N4975-F1869-G0080',
    'name': 'PP SANTANDER EUROPA 2025',
    'fundDescription': 'SANTANDER EUROPA 2025 PENSIONES, F.P.',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3202-F1873-G0080',
    'name': 'SANTANDER UNIVERSIDADES RENTA VARIABLE MIXTA',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA VARIABLE MIXTA, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N3678-F1873-G0080',
    'name': 'COL. MEDICOS VALENCIA II',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA VARIABLE MIXTA, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N1549-F1873-G0080',
    'name': 'BANCO PASTOR III',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA VARIABLE MIXTA, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'n5437-F1873-G0080',
    'name': 'PLAN DE PENSIONES ASOCIADO 2034 RV DOS',
    'fundDescription': 'SANTANDER ASOCIADOS RENTA VARIABLE MIXTA, F.P',
    'managerId': 'G0080',
    'managerName': 'SANTANDER PENSIONES'
  },
  {
    'id': 'N0057-F0016-G0082',
    'name': 'BBVA PLAN INDIVIDUAL',
    'fundDescription': 'BBVA INDIVIDUAL F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0708-F0028-G0082',
    'name': 'TECNICOS COMERCIALES Y ECONOMISTAS DEL ESTADO',
    'fundDescription': 'BBVA CIEN F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0761-F0028-G0082',
    'name': 'ASOCIADO GESTORPLAN',
    'fundDescription': 'BBVA CIEN F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1852-F0028-G0082',
    'name': 'ASAJA',
    'fundDescription': 'BBVA CIEN F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2334-F0028-G0082',
    'name': 'HORIZONTE',
    'fundDescription': 'BBVA CIEN F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0186-F0131-G0082',
    'name': 'PLA DE PENSIONS PINTURAS HEMPEL',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0344-F0131-G0082',
    'name': 'FAMOSA',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0447-F0131-G0082',
    'name': 'MERCALICANTE',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0755-F0131-G0082',
    'name': 'CAMP D´ALCARRAS',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0908-F0131-G0082',
    'name': 'S.I.T.A.',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0999-F0131-G0082',
    'name': 'SADECO',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1602-F0131-G0082',
    'name': 'AYTO. CAMARGO',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1883-F0131-G0082',
    'name': 'PPC ELECTRICA CONQUENSE',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2339-F0131-G0082',
    'name': 'PPC DE GRUPO FELIX SOLIS',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2988-F0131-G0082',
    'name': 'PRENATAL, S.A.',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3037-F0131-G0082',
    'name': 'FLEXLINK SISTEMS ESPAÑA, S.L.',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3495-F0131-G0082',
    'name': 'P.P. TECHTRONIC INDUSTRIES IBERIA',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1977-F0131-G0082',
    'name': 'P.P MENZOLIT VITROPLAST,S.L.',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5251-F0131-G0082',
    'name': 'P.P EPIROC',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3041-F0131-G0082',
    'name': 'PIMES 1, PLAN DE PENSIONES',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3786-F0131-G0082',
    'name': 'AAPP 2, PLAN DE PENSIONES',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5354-F0131-G0082',
    'name': 'GRUPO ELECTROGENOS EUROPA, S.A.U',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2445-F0131-G0082',
    'name': 'REAL FEDERACION ESPAÑOLA DE ATLETISMO',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2754-F0131-G0082',
    'name': 'BBVA PYME PENSION CONSERVADOR 1',
    'fundDescription': 'BBVA ADHESION F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3669-F0133-G0082',
    'name': 'CAIXA CATALUNYA PLA DE PENSIONS CREIXEMENT 10/75',
    'fundDescription': 'PREVIFON, FONDO DE PENSIONES',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1054-F0208-G0082',
    'name': 'BBVA INDUSTRIAL',
    'fundDescription': 'BBVA PROGRESO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1557-F0255-G0082',
    'name': 'ALCOA INESPAL',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1560-F0255-G0082',
    'name': 'ALCOA TRANSFORMACION DE PRODUCTOS S.L.',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1853-F0255-G0082',
    'name': 'HYDRO EXTRUSION SPAIN',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1854-F0255-G0082',
    'name': 'ALCOA ARQUITECTURA S.L.',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2990-F0255-G0082',
    'name': 'ALUMINIO ESPAÑOL',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3059-F0255-G0082',
    'name': 'ALUMINA ESPAÑOLA',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3345-F0255-G0082',
    'name': 'ARCONIC INVERSIONES ESPAÑA',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4014-F0255-G0082',
    'name': 'HYDRO HOLDING SPAIN',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4349-F0255-G0082',
    'name': 'SABIÑANIGO',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4931-F0255-G0082',
    'name': 'ALCOA INESPAL CORUÑA',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4932-F0255-G0082',
    'name': 'ALU IBERICA AVL,P.P',
    'fundDescription': 'BBVA UNO FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1120-F0257-G0082',
    'name': 'ALFA LAVAL IBERIA, S.A.',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1134-F0257-G0082',
    'name': 'STL',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1141-F0257-G0082',
    'name': 'EMPRESAS AON EN ESPAÑA',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1704-F0257-G0082',
    'name': 'COLEGIO PROCURADORES VALENCIA',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2402-F0257-G0082',
    'name': 'RED UNIV MARKETING Y BOOKING',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3272-F0257-G0082',
    'name': 'INVISTA FIBERS&INTERM. IB.',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4768-F0257-G0082',
    'name': 'CRUCELL SPAIN, S.A.',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5364-F0257-G0082',
    'name': 'LIFESCAN',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2432-F0257-G0082',
    'name': 'LA FARGA',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2423-F0257-G0082',
    'name': 'PLAN DE PENSIONES UNIVERSITAT RAMON LLULL FUDANCIÓ',
    'fundDescription': 'BBVA TRES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0392-F0258-G0082',
    'name': 'PETRONOR',
    'fundDescription': 'PETRONOR PENSIONES F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0692-F0264-G0082',
    'name': 'MULTIPLAN',
    'fundDescription': 'BBVA DIEZ F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0080-F0264-G0082',
    'name': 'B.H.E.',
    'fundDescription': 'BBVA DIEZ F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0081-F0264-G0082',
    'name': 'B.C.L.',
    'fundDescription': 'BBVA DIEZ F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0288-F0264-G0082',
    'name': 'RENTAPLAN',
    'fundDescription': 'BBVA DIEZ F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1047-F0397-G0082',
    'name': 'BBVA PLAN RENTA FIJA',
    'fundDescription': 'BBVA RENTA FIJA, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3052-F0397-G0082',
    'name': 'BBVA EMPLEADOS Y COLECTIVOS RF',
    'fundDescription': 'BBVA RENTA FIJA, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1066-F0438-G0082',
    'name': 'BBVA PLAN 30',
    'fundDescription': 'BBVA 30, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1067-F0439-G0082',
    'name': 'BBVA PLAN 40',
    'fundDescription': 'BBVA 40, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1071-F0440-G0082',
    'name': 'BBVA PLAN 50',
    'fundDescription': 'BBVA 50, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1227-F0468-G0082',
    'name': 'BBVA PLAN GLOBAL DESARROLLO SOSTENIBLE ISR, PPI',
    'fundDescription': 'BBVA RENTA VARIABLE, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1714-F0509-G0082',
    'name': 'PLA PENSIONS SISTEMA ASSOCIAT COSITAL',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2422-F0509-G0082',
    'name': 'PLA PENSIONS SISTEMA OCUPACIO AJUNTAMENT MONTBLANC',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2433-F0509-G0082',
    'name': 'PLA PENSIONS SISTEMA OCUPACIO AJUNTAMENT VACARISSES',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2471-F0509-G0082',
    'name': 'PLA PENSIONS SISTEMA OCUPACION CONSELL COMARCAL DE SOLSONES',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3098-F0509-G0082',
    'name': 'PLA PENSIONS SISTEMA OCUPACIO AJNTAMENT SANT ANDREU DE LA BARCA',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3620-F0509-G0082',
    'name': 'P.P. CAIXA CATALUNYA PROMOCIO CONJUNTA SECTOR PUBLIC',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2723-F0509-G0082',
    'name': 'AJUNTAMENT DE SANT BOI LLOBREGAT',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3921-F0509-G0082',
    'name': 'AJUNTAMENT DE SANTA COLOMA DE GRAMANET',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3952-F0509-G0082',
    'name': 'AJUNTAMENT DE BADALONA',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4177-F0509-G0082',
    'name': 'AJUNTAMENT DE IGUALADA',
    'fundDescription': 'CAIXA CATALUNYA VIII',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3120-F0510-G0082',
    'name': 'PLAN PENSIONES PYMES II CAIXA CATALUNYA',
    'fundDescription': 'CAIXA CATALUNYA VI, FONDO DE PENSIONES',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': '5361-F0536-G0082',
    'name': 'IBERDROLA (2)',
    'fundDescription': 'EMPLEADOS DE IBERDROLA 2, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4411-F0591-G0082',
    'name': 'UNNIM PENSIONS G13',
    'fundDescription': 'UNNIM C5 FONDO DE PENSIONES',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2256-F0612-G0082',
    'name': 'GRUPO LLADRO',
    'fundDescription': 'LINCE FUND,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1796-F0620-G0082',
    'name': 'BBVA PLAN RENTA VARIABLE EUROPA, PPI',
    'fundDescription': 'BBVA RENTA VARIABLE EUROPA, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1997-F0655-G0082',
    'name': 'GRUPO LILLY',
    'fundDescription': 'BBV DIECISIETE F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2269-F0655-G0082',
    'name': 'EMPLEADOS DE BOSTON SCIENTIFIC',
    'fundDescription': 'BBV DIECISIETE F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5410-F0655-G0082',
    'name': 'PP ELANCO SPAIN',
    'fundDescription': 'BBV DIECISIETE F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2590-F0657-G0082',
    'name': 'BBVA PLAN MERCADO MONETARIO',
    'fundDescription': 'BBVA MERCADO MONETARIO F P',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1793-F0665-G0082',
    'name': 'BBVA TELECOMUNICACIONES',
    'fundDescription': 'BBVA TELECOMUNICACIONES, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N2022-F0730-G0082',
    'name': 'ATLAS COPCO, S.A.E.',
    'fundDescription': 'ATLAS COPCO PENSIONES, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5106-F0784-G0082',
    'name': 'PP CX EUROPA 2026',
    'fundDescription': 'CAIXA CATALUNYA XVI, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3210-F0954-G0082',
    'name': 'BBVA PLAN REVALORIZACION EUROPA 25, PPI',
    'fundDescription': 'BBVA CIENTO TRECE, F.P',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3236-F0989-G0082',
    'name': 'BBVA PROTECCION 2025',
    'fundDescription': 'BBVA VEINTIOCHO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': '----F1106-G0082',
    'name': '---',
    'fundDescription': 'BBVA TREINTA Y DOS, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3405-F1123-G0082',
    'name': 'BBVA PLAN RENTA VARIABLE IBEX',
    'fundDescription': 'BBVA TREINTA Y CINCO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3624-F1125-G0082',
    'name': 'BBVA PROTECCION 2030',
    'fundDescription': 'BBVA TREINTA Y SIETE, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3357-F1164-G0082',
    'name': 'BBVA PLAN MULTIACTIVO CONSERVADOR',
    'fundDescription': 'BBVA MULTIACTIVO CONSERVADOR, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4149-F1352-G0082',
    'name': 'PLAN  PROTECCION 2035',
    'fundDescription': 'BBVA CUARENTA Y UNO, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4336-F1544-G0082',
    'name': 'BBVA PLAN HORIZONTE 2025',
    'fundDescription': 'BBVA CUARENTA Y OCHO, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4476-F1614-G0082',
    'name': 'CX PLUS 25 T PLAN DE PENSIONES',
    'fundDescription': 'Caixa Tarragona Fondo de Pensiones 23 FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5107-F1727-G0082',
    'name': 'PP CX TRANQUIL·LITAT 10',
    'fundDescription': 'CATALUNYACAIXA XXX, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4729-F1822-G0082',
    'name': 'BBVA PLAN MULTIACTIVO MODERADO',
    'fundDescription': 'BBVA MULTIACTIVO MODERADO, F.P',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4845-F1892-G0082',
    'name': 'BBVA PLAN MULTIACTIVO DECIDIDO',
    'fundDescription': 'BBVA MULTIACTIVO DECIDIDO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4855-F1893-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 19 C',
    'fundDescription': 'BBVA SETENTA Y TRES, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4877-F1943-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 24',
    'fundDescription': 'BBVA SETENTA Y SIETE, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4951-F1946-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 24 B',
    'fundDescription': 'BBVA OCHENTA Y DOS, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4952-F1968-G0082',
    'name': 'BBVA RENTAS 2024, PPI',
    'fundDescription': 'BBVA OCHENTA Y CUATRO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4961-F1973-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 24 C',
    'fundDescription': 'BBVA OCHENTA Y SIETE, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4962-F1974-G0082',
    'name': 'BBVA PLAN REVALORIZACION EUROPA IV',
    'fundDescription': 'BBVA OCHENTA Y OCHO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4994-F1982-G0082',
    'name': 'BBVA RENTA FIJA INTERNACIONAL FLEXIBLE',
    'fundDescription': 'BBVA NOVENTA Y CINCO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5033-F1986-G0082',
    'name': 'BBVA PROTECCION FUTURO 5/10, PPI',
    'fundDescription': 'BBVA NOVENTA Y DOS, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N3946-F1987-G0082',
    'name': 'BBVA EMPLEADOS Y COLECTIVOS RV',
    'fundDescription': 'BBVA BOLSA GLOBAL, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N4995-F1988-G0082',
    'name': 'BBVA PLAN REVALORIZACION EUROPA 25 B',
    'fundDescription': 'BBVA NOVENTA Y CUATRO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5038-F1989-G0082',
    'name': 'BBVA PLAN JUBILACION SOSTENIBLE 2025',
    'fundDescription': 'BBVA NOVENTA Y SEIS, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5039-F2000-G0082',
    'name': 'BBVA PLAN JUBILACION SOSTENIBLE 2030',
    'fundDescription': 'BBVA NOVENTA Y SIETE, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5037-F2001-G0082',
    'name': 'BBVA PLAN JUBILACION SOSTENIBLE 2040',
    'fundDescription': 'BBVA NOVENTA Y OCHO, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5045-F2002-G0082',
    'name': 'BBVA PROTECCION FUTURO 2/10, PPI',
    'fundDescription': 'BBVA NOVENTA Y NUEVE, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5056-F2003-G0082',
    'name': 'BBVA PROTECCION FUTURO 5/10 B, PPI',
    'fundDescription': 'BBVA CIENTO UNO, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5063-F2004-G0082',
    'name': 'BBVA PROTECCION FUTURO 2/10 B, PPI',
    'fundDescription': 'BBVA CIENTO DOS, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5081-F2018-G0082',
    'name': 'BBVA PLAN RENTA FIJA INTERNACIONAL FLEXIBLE 0-3 PPI',
    'fundDescription': 'BBVA CIENTO TRES. F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5073-F2019-G0082',
    'name': 'BBVA PLAN OPORTUNIDAD EUROPA 24, PPI',
    'fundDescription': 'BBVA CIENTO CUATRO, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5099-F2020-G0082',
    'name': 'BBVA PROTECCION FUTURO 2/10 C,PPI',
    'fundDescription': 'BBVA CIENTO CINCO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5100-F2021-G0082',
    'name': 'BBVA PROTECCION FUTURO 5/10 C,PPI',
    'fundDescription': 'BBVA CIENTO SEIS, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5104-F2022-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 26',
    'fundDescription': 'BBVA CIENTO SIETE, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5105-F2023-G0082',
    'name': 'BBVA PLAN REVALORIZACION EUROPA POSITIVO',
    'fundDescription': 'BBVA CIENTO OCHO, FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5118-F2024-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 26 B',
    'fundDescription': 'BBVA CIENTO NUEVE,FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5119-F2025-G0082',
    'name': 'BBVA PLAN REVALORIZACION EUROPA POSITIVO B',
    'fundDescription': 'BBVA CIENTO DIEZ,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5129-F2026-G0082',
    'name': 'BBVA PLAN REVALORIZACIÓN ESPAÑA POSITIVO,PPI',
    'fundDescription': 'BBVA CIENTO ONCE,F,P',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5149-F2027-G0082',
    'name': 'BBVA PLAN REVALORIZACIÓN ESPAÑA POSITIVO B,PPI',
    'fundDescription': 'BBVA CIENTO DOCE,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N1487-F2030-G0082',
    'name': 'ABBOTT MEDICAL ESPAÑA, S.A.',
    'fundDescription': 'BBVA TRESCIENTOS F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5166-F2043-G0082',
    'name': 'BBVA PLAN TRANQUILIDAD 26 C,PPI',
    'fundDescription': 'BBVA CIENTO CATORCE  ,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5184-F2044-G0082',
    'name': 'BBVA PLAN CONSOLIDACION 85,PPI',
    'fundDescription': 'BBVA CIENTO QUINCE,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5190-F2045-G0082',
    'name': 'BBVA PLAN REVALORIZACIÓN ESPAÑA POSITIVO C,PPI',
    'fundDescription': 'BBVA CIENTO DIECISEIS,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5233-F2079-G0082',
    'name': 'BBVA PLAN DESTINO JUBILACION,PPI',
    'fundDescription': 'BBVA CIENTO VEINTE,F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5344-F2095-G0082',
    'name': 'BBVA PLAN SOSTENIBLE MODERADO ISR, PPI',
    'fundDescription': 'BBVA CIENTO TREINTA,F.P',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5380-F2136-G0082',
    'name': 'BBVA PLAN MEJORES IDEAS, PPI',
    'fundDescription': 'BBVA CIENTO TREINTA Y CUATRO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N5379-F2137-G0082',
    'name': 'BBVA PLAN USA DESARROLLO SOSTENIBLE ISR, PPI',
    'fundDescription': 'BBVA CIENTO TREINTA Y DOS FP',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': '21921-F2192-G0082',
    'name': 'IBERDROLA (1 )',
    'fundDescription': 'EMPLEADOS DE IBERDROLA 1, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': '21931-F2193-G0082',
    'name': 'IBERDROLA (3)',
    'fundDescription': 'EMPLEADOS DE IBERDROLA 3, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': '21991-F2199-G0082',
    'name': 'BBVA PLAN BONOS 2025, PPI',
    'fundDescription': 'BBVA CIENTO TREINTA Y CINCO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': '22001-F2200-G0082',
    'name': 'BBVA PLAN BONOS 2027, PPI',
    'fundDescription': 'BBVA CIENTO TREINTA Y OCHO, F.P.',
    'managerId': 'G0082',
    'managerName': 'BBVA PENSIONES'
  },
  {
    'id': 'N0058-F0018-G0085',
    'name': 'BS PLAN 15, P.P.',
    'fundDescription': 'SABADELL MIXTO-FIJO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0686-F0018-G0085',
    'name': 'BS PLAN 15 PLUS 1, P.P.',
    'fundDescription': 'SABADELL MIXTO-FIJO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0730-F0018-G0085',
    'name': 'BS PLAN 15 PLUS 2, P.P.',
    'fundDescription': 'SABADELL MIXTO-FIJO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3264-F0021-G0085',
    'name': 'BS PLAN ÉTICO Y SOLIDARIO',
    'fundDescription': 'HERRERO, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1541-F0024-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE ORACLE EN ESPAÑA',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4854-F0024-G0085',
    'name': 'PLAN  DE PENSIONES AXALTA COATING SYSTEMS SPAIN, S.L.',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5050-F0024-G0085',
    'name': 'PLAN DE PENSIONES DE CHEMOURS SPAIN',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0263-F0024-G0085',
    'name': 'PLAN DE PENSIONES ITW ESPAÑA',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1029-F0024-G0085',
    'name': 'PLA DE PENSIONS DELS EMPLEATS DEL FUTBOL CLUB BARCELONA',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1346-F0024-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE LA EMPRESA GENERAL VALENCIANA DEL AGUA, S.A.',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1972-F0024-G0085',
    'name': 'PLA DE PENSIONS D OCUPACIO DIPUTACIO DE TARRAGONA',
    'fundDescription': 'FONSOLBANK, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5428-F0035-G0085',
    'name': 'PLAN EMPRESA CICLO DE VIDA, P.P.',
    'fundDescription': 'SABADELL EMPRESA ACUMULACIÓN, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0734-F0153-G0085',
    'name': 'AJUNTAMENT DE SANT QUIRZE DEL VALLES, PLA DE PENSIONS',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1918-F0153-G0085',
    'name': 'PLAN DE PENSIONES GALLINA BLANCA, S.A.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2002-F0153-G0085',
    'name': 'PLAN DE PENSIONES THE GB FOODS, S.A.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2378-F0153-G0085',
    'name': 'PLAN DE PENSIONES HAPAG LLOYD SPAIN, S.L.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3004-F0153-G0085',
    'name': 'PLAND E PENSIONES DEL GRUPO DE LOS EMPLEADOS DE FACSA-FOBESA',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3306-F0153-G0085',
    'name': 'PLAN EMPRESA 10, P.P.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4224-F0153-G0085',
    'name': 'MULTIGRUPO 2000, PLAN DE PENSIONES',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4481-F0153-G0085',
    'name': 'PLAN DE PENSIONES INPRALSA',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4854-F0153-G0085',
    'name': 'PLAN  DE PENSIONES AXALTA COATING SYSTEMS SPAIN, S.L.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5072-F0153-G0085',
    'name': 'PLAN DE PENSIONES GALLINA BLANCA FABRICACION, S.L.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5070-F0153-G0085',
    'name': 'PLAN DE PENSIONES THE GB FOODS AFRICA HOLDING COMPANY, S.L.U.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5071-F0153-G0085',
    'name': 'PLAN DE PENSIONES PASA FABRICACION, S.L.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5069-F0153-G0085',
    'name': 'PLAN DE PENSIONES FOODIVERSA, S.L.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5369-F0153-G0085',
    'name': 'PLAN DE PENSIONES THE GB FOODS EUROPA, S.L.',
    'fundDescription': 'BANSABADELL 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0335-F0216-G0085',
    'name': 'HEIDELBERG SPAIN, PLAN DE PENSIONES',
    'fundDescription': 'HEIDELBERG SPAIN PENSIONES, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0383-F0217-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL BANCO DE SABADELL',
    'fundDescription': 'MULTIFONDO 2000, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0383-F0236-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL BANCO DE SABADELL',
    'fundDescription': 'G.M. PENSIONES, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5428-F0325-G0085',
    'name': 'PLAN EMPRESA CICLO DE VIDA, P.P.',
    'fundDescription': 'SABADELL EMPRESA CONSOLIDACIÓN, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0824-F0356-G0085',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO PARA XALLAS ELECTRICIDAD Y ALEACIONES, S.A.',
    'fundDescription': 'XEAL 2021, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0819-F0357-G0085',
    'name': 'UGT DE CATALUNYA, PLA DE PENSIONS',
    'fundDescription': 'BANSABADELL 5, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1269-F0357-G0085',
    'name': 'BS PENTAPENSION, P.P.',
    'fundDescription': 'BANSABADELL 5, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3319-F0357-G0085',
    'name': 'IBERSECURITIES PENTAPENSION, P.P.',
    'fundDescription': 'BANSABADELL 5, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4774-F0357-G0085',
    'name': 'BS PENTAPENSION EMPLEADOS, P.P.',
    'fundDescription': 'BANSABADELL 5, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0311-F0375-G0085',
    'name': 'PLAN DE PENSIONES DE CESCE',
    'fundDescription': 'CASTELLANA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0330-F0375-G0085',
    'name': 'PLAN DE PENSIONES ALRESA',
    'fundDescription': 'CASTELLANA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0984-F0375-G0085',
    'name': 'TIASA, PLAN DE PENSIONES',
    'fundDescription': 'CASTELLANA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1021-F0375-G0085',
    'name': 'PLAN DE PENSIONES DE VEOLIA WATER SYSTEMS IBERICA, S.L.',
    'fundDescription': 'CASTELLANA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1829-F0375-G0085',
    'name': 'PLAN EMPRESA 45, PP',
    'fundDescription': 'CASTELLANA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3168-F0375-G0085',
    'name': 'P.P. DEL SIST. EMP. PARA LOS TRABAJADORES DE SCOR GLOBAL LIFE SE IBERICA SUCURSAL',
    'fundDescription': 'CASTELLANA PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0389-F0395-G0085',
    'name': 'ASPEG PENSIONS',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0770-F0395-G0085',
    'name': 'AJUNTAMENT DE CERDANYOLA DEL VALLES, PLA DE PENSIONS',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0993-F0395-G0085',
    'name': 'S.A. CHUPA CHUPS, S.A. UNIPERSONAL PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1029-F0395-G0085',
    'name': 'PLA DE PENSIONS DELS EMPLEATS DEL FUTBOL CLUB BARCELONA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1594-F0395-G0085',
    'name': 'PLA DE PENSIONS FUNDACIO COLLSEROLA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1611-F0395-G0085',
    'name': 'PLA DE PENSIONS DEL PATRONAT MUNICIPAL D´ESPORTS DE TARRAGONA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1644-F0395-G0085',
    'name': 'PLA DE PENSIONS DEL PARLAMENT DE CATALUNYA, LA SINDICATURA DE COMPTES I EL SÍNDIC DE GREUGES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1856-F0395-G0085',
    'name': 'EVONIK, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1875-F0395-G0085',
    'name': 'ITEM INTERNATIONAL, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1972-F0395-G0085',
    'name': 'PLA DE PENSIONS D´OCUPACIO DIPUTACIO DE TARRAGONA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1990-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE INGENICO BARCELONA, S.A.',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2004-F0395-G0085',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO PARA LOS EMPLEADOS DE DIETRICH EQUIPOS QUÍMICOS, S.L.',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2464-F0395-G0085',
    'name': 'AJUNTAMENT DE ROSES, PLA DE PENSIONS',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2844-F0395-G0085',
    'name': 'DYNACAST, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2923-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE SUMINISTROS FARMACÉUTICOS Y HOSPITALARIOS EGARA, S.A.',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2950-F0395-G0085',
    'name': 'INGENICO IBERIA, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3021-F0395-G0085',
    'name': 'AMMERAAL BELTECH, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3069-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE LEICA GEOSYSTEMS',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3353-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE  LOS EMPLEADOS DE GRUPO DAMM',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3366-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE WESTLAKE COMPOUNDS SPAIN S.L.U',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3386-F0395-G0085',
    'name': 'PENTAPENSION EMPRESA, PP',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3580-F0395-G0085',
    'name': 'LEICA MICROSISTEMAS, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3840-F0395-G0085',
    'name': 'OXIRIS, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3931-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE LOS TARBAJADORES DE FEDERAL-MOGUL FRICTION PRODUCTS, S.A',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4125-F0395-G0085',
    'name': 'PLA DE PENSIONS DE PROMOCIO  CONJUNTA PER L´ADMINISTRACIO LOCAL DE CATALUNYA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4127-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE LABORATORIOS GALDERMA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4140-F0395-G0085',
    'name': 'P.P. DE LOS EMPLEADOS DEL CONSELL INSULAR DE MENORCA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4954-F0395-G0085',
    'name': 'SIGNODE PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1652-F0395-G0085',
    'name': 'THOR ESPECIALIDADES, PLAN DE PENSIONES',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0263-F0395-G0085',
    'name': 'PLAN DE PENSIONES ITW ESPAÑA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5403-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS DE SABADELL ASSET MANAGEMENT',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5406-F0395-G0085',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO PARA FERROGLOBE EN ESPAÑA',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5436-F0395-G0085',
    'name': 'PLAN DE PENSIONES DE DANONE NUTRICIA, S.R.L.',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0719-F0395-G0085',
    'name': 'PLAN DE PENSIONES STAEDTLER IBERIA, S.A.',
    'fundDescription': 'BS PENTAPENSION EMPRESA, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0226-F0426-G0085',
    'name': 'ECONOMISTES PRUDENT, PLA DE PENSIONS',
    'fundDescription': 'FONECONOMISTES, FONS DE PENSIONS',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5164-F0426-G0085',
    'name': 'PP ENFERM.COM.VALENCIANA',
    'fundDescription': 'FONECONOMISTES, FONS DE PENSIONS',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1231-F0429-G0085',
    'name': 'BS PLAN RENTA VARIABLE, P. P.',
    'fundDescription': 'SABADELL RENTA VARIABLE MUNDIAL, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1663-F0429-G0085',
    'name': 'BS PLAN RENTA VARIABLE PLUS 1, P.P.',
    'fundDescription': 'SABADELL RENTA VARIABLE MUNDIAL, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1801-F0429-G0085',
    'name': 'BS PLAN RENTA VARIABLE PLUS 2, P.P.',
    'fundDescription': 'SABADELL RENTA VARIABLE MUNDIAL, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1077-F0441-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE COMPAÑÍA DE SERVICIOS DE BEBIDAS REFRESCANTES, SL',
    'fundDescription': 'COMPAÑÍA DE SERVICIOS DE BEBIDAS REFRESCANTES PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1590-F0501-G0085',
    'name': 'BS PLAN MONETARIO, P. P.',
    'fundDescription': 'SABADELL MONETARIO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1803-F0501-G0085',
    'name': 'BS PLAN MONETARIO PLUS 1, P. P.',
    'fundDescription': 'SABADELL MONETARIO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1804-F0501-G0085',
    'name': 'BS PLAN MONETARIO PLUS 2, P. P.',
    'fundDescription': 'SABADELL MONETARIO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0223-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE FIRMENICH',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1346-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE LA EMPRESA GENERAL VALENCIANA DEL AGUA, S.A.',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1651-F0533-G0085',
    'name': 'WETRON, PLAN DE PENSIONES',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1840-F0533-G0085',
    'name': 'CAMBRA OFICIAL DE COMERÇ I INDUSTRIA DE SABADELL, PLA DE PENSIONS',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1919-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DE SFyM, CASSA y VENTORE',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2379-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE HUNTSMAN ADVANCED MATERIALS',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2555-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE VANDEMOORTELE',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2807-F0533-G0085',
    'name': 'GRIFOLS, PLAN DE PENSIONES',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3852-F0533-G0085',
    'name': 'PLAN EMPRESA 20, PP',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4507-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE ANV',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5050-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE CHEMOURS SPAIN',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4016-F0533-G0085',
    'name': 'P.P. DE EMPLEADOS DEL GRUPO DNV GL EN ESPAÑA',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5466-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA SFyMSUR y CGC',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3310-F0533-G0085',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE NYLSTAR, S.A. ESPAÑA',
    'fundDescription': 'BANSABADELL 18, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1517-F0579-G0085',
    'name': 'BS PENSION 60, P.P.',
    'fundDescription': 'BG PENSIONES CRECIMIENTO F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2249-F0579-G0085',
    'name': 'BS PLAN 60 PLUS 1, P.P.',
    'fundDescription': 'BG PENSIONES CRECIMIENTO F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2250-F0579-G0085',
    'name': 'BS PLAN 60 PLUS 2, P.P.',
    'fundDescription': 'BG PENSIONES CRECIMIENTO F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2024-F0656-G0085',
    'name': 'PLAN  DE PENSIONES DE PROMOCION CONJUNTA GRUPO DU PONT',
    'fundDescription': 'BANSABADELL 46, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0656-F0656-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA IFF',
    'fundDescription': 'BANSABADELL 46, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2578-F0914-G0085',
    'name': 'PLAN DE PENSIONES DE UNILEVER ESPAÑA, S.A',
    'fundDescription': 'BANSABADELL 28, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4168-F0914-G0085',
    'name': 'PLAN DE PENSIONES DE UNILEVER FOODS INDUSTRIAL ESPAÑA, S.L.U.',
    'fundDescription': 'BANSABADELL 28, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4170-F0914-G0085',
    'name': 'PLAN DE PENSIONES DE UNILEVER SERVICES ESPAÑA, S.A.U.',
    'fundDescription': 'BANSABADELL 28, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2913-F0929-G0085',
    'name': 'BS PLAN RENTA FIJA, P.P.',
    'fundDescription': 'FONDOATLANTICO 15 F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3218-F0962-G0085',
    'name': 'PLAN DE PENSIONES MPS AMAS DE CASA C.V.',
    'fundDescription': 'CAM FONDO ASOCIADO, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0685-F1112-G0085',
    'name': 'ENRESA, PLAN DE PENSIONES',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1163-F1112-G0085',
    'name': 'PLAN DE PENSIONES DE BEIERSDORF, S.A.',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N1209-F1112-G0085',
    'name': 'PLAN DE PENSIONES DE LA SOCIEDAD GENERAL DE AUTORES Y EDITORES',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2744-F1112-G0085',
    'name': 'PLAN DE PENSIONES LEXMARK ESPAÑA, S. L.',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3540-F1112-G0085',
    'name': 'PLAN DE PENSIONES DE TESA TAPE, S.A.',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4798-F1112-G0085',
    'name': 'PLAN DE PENSIONES BEIERSDORF MANUFACTURING ARGENTONA',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4799-F1112-G0085',
    'name': 'PLAN DE PENSIONES BEIERSDORF MANUFACTURING TRES CANTOS',
    'fundDescription': 'GESTION METODOLOGICA DE INVERSIONES, FP',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2578-F1152-G0085',
    'name': 'PLAN DE PENSIONES UNILEVER ESPAÑA, S.A.',
    'fundDescription': 'BANSABADELL 34, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4168-F1152-G0085',
    'name': 'PLAN DE PENSIONES UNILEVER FOODS INDUSTRIAL ESPAÑA, S.L.U.',
    'fundDescription': 'BANSABADELL 34, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4170-F1152-G0085',
    'name': 'PLAN DE PENSIONES UNILEVER SERVICES ESPAÑA, S.A.U.',
    'fundDescription': 'BANSABADELL 34, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2956-F1154-G0085',
    'name': 'PP DE LA CÁMARA OFICIAL DE COMERCIO, INDUSTRIA, SERVICIOS Y NAVEGACIÓN DE ESPAÑA',
    'fundDescription': 'BANSABADELL 36, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3008-F1154-G0085',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE ARESBANK',
    'fundDescription': 'BANSABADELL 36, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3009-F1154-G0085',
    'name': 'PLAN DE PENSIONES EMPLEADOS BANCO NACION',
    'fundDescription': 'BANSABADELL 36, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3239-F1154-G0085',
    'name': 'PRACTIPLAN GIJON',
    'fundDescription': 'BANSABADELL 36, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N3604-F1154-G0085',
    'name': 'PLAN EMPRESA 30, PP',
    'fundDescription': 'BANSABADELL 36, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0331-F1244-G0085',
    'name': 'PLAN DE PENSIONES ALCON CUSI PRESTACION DEFINIDA',
    'fundDescription': 'BANSABADELL 39, FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N2024-F1248-G0085',
    'name': 'PLAN  DE PENSIONES DE PROMOCION CONJUNTA DE GRUPO DU PONT',
    'fundDescription': 'BANSABADELL 43, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0656-F1248-G0085',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA IFF',
    'fundDescription': 'BANSABADELL 43, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5214-F1250-G0085',
    'name': 'PLAN DE PENSIONES FRONERI IBERIA, S.L.',
    'fundDescription': 'BANSABADELL 45, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5060-F1955-G0085',
    'name': 'SABADELL PLAN FUTURO 2030 DINAMICO P.P',
    'fundDescription': 'BANSABADELL 1006, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5061-F1956-G0085',
    'name': 'SABADELL PLAN FUTURO 2035 DINAMICO P.P',
    'fundDescription': 'BANSABADELL 1007, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5058-F1957-G0085',
    'name': 'SABADELL PLAN FUTURO 2040 DINAMICO P.P',
    'fundDescription': 'BANSABADELL 1008, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5059-F1958-G0085',
    'name': 'SABADELL PLAN FUTURO 2045 DINAMICO P.P',
    'fundDescription': 'BANSABADELL 1009, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5168-F1975-G0085',
    'name': 'SABADELL PLAN FUTURO 2030 EQUILIBRADO P.P',
    'fundDescription': 'BANSABADELL 59, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5170-F1976-G0085',
    'name': 'SABADELL PLAN FUTURO 2035 EQUILIBRADO P.P',
    'fundDescription': 'BANSABADELL 60, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4747-F1977-G0085',
    'name': 'PLAN DE VIDA FUTURO 155',
    'fundDescription': 'BANSABADELL 61, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5172-F1978-G0085',
    'name': 'SABADELL PLAN FUTURO 2040 EQUILIBRADO P.P',
    'fundDescription': 'BANSABADELL 62, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N4997-F1979-G0085',
    'name': 'PLAN RENDIMIENTO EUROPA P.P.',
    'fundDescription': 'BANSABADELL 63, F.P.',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5167-F2035-G0085',
    'name': 'SABADELL PLAN FUTURO 2030 PRUDENTE P.P',
    'fundDescription': 'BANSABADELL 65 FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5169-F2036-G0085',
    'name': 'SABADELL PLAN FUTURO 2035 PRUDENTE P.P',
    'fundDescription': 'BANSABADELL 66 FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5171-F2037-G0085',
    'name': 'SABADELL PLAN FUTURO 2040 PRUDENTE P.P',
    'fundDescription': 'BANSABADELL 67 FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5173-F2038-G0085',
    'name': 'SABADELL PLAN FUTURO 2045 PRUDENTE P.P',
    'fundDescription': 'BANSABADELL 68 FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N5174-F2053-G0085',
    'name': 'SABADELL PLAN FUTURO 2045 EQUILIBRADO P.P',
    'fundDescription': 'BANSABADELL 64 FONDO DE PENSIONES',
    'managerId': 'G0085',
    'managerName': 'BANSABADELL PENSIONES, E.G.F.P., S.A.'
  },
  {
    'id': 'N0128-F0068-G0091',
    'name': 'PLAN DE PENSION PERSONAL FIBANC',
    'fundDescription': 'MEDIOLANUM PENSIONES II F.P.',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N0240-F0068-G0091',
    'name': 'PLAN DE PENSIONES CYCLOPS',
    'fundDescription': 'MEDIOLANUM PENSIONES II F.P.',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N2915-F0068-G0091',
    'name': 'MEDIOLANUM RENTA FIJA MIXTA, PLAN.PENS.',
    'fundDescription': 'MEDIOLANUM PENSIONES II F.P.',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N0129-F0069-G0091',
    'name': 'MEDIPLAN, PLAN DE PENSIONES',
    'fundDescription': 'MEDIFOND PENSIONS, FONS DE PENSIONS',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N0989-F0069-G0091',
    'name': 'PLAN DE PENSIONES MEDIPENSIONS',
    'fundDescription': 'MEDIFOND PENSIONS, FONS DE PENSIONS',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N2796-F0874-G0091',
    'name': 'MEDIOLANUM RENTA VARIABLE, PLAN.PENS.',
    'fundDescription': 'MEDIOLANUM PENSIONES I F.P.',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N2795-F0875-G0091',
    'name': 'MEDIOLANUM ACTIVOS MONETARIOS PLAN.PENS.',
    'fundDescription': 'MEDIOLANUM PENSIONES III F.P.',
    'managerId': 'G0091',
    'managerName': 'MEDIOLANUM PENSIONES, S.G.F.P.,S.A.'
  },
  {
    'id': 'N0736-F0128-G0105',
    'name': 'PLA DE PENSIONS ASSOCIATIU DELS ENGINYERS INDUSTRIALS DE CATALUNYA',
    'fundDescription': 'FONS DE PENSIONS DELS ENGINYERS INDUSTRIALS DE CATALUNYA',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N1617-F0606-G0105',
    'name': 'Enginyers Renda Fixa ODS, Pla de Pensions',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 2, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N1618-F0607-G0105',
    'name': 'ENGINYERS MIXT, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 3, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N1619-F0608-G0105',
    'name': 'ENGINYERS BORSA, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 4, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N4032-F0609-G0105',
    'name': 'ENGINYERS PRUDENT, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 5, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N5258-F0609-G0105',
    'name': 'LA PREVISIÓ PRUDENT, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 5, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N2758-F0870-G0105',
    'name': 'A&G Conservador P.P.',
    'fundDescription': 'Stela Maris Conservador F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N2877-F0870-G0105',
    'name': 'Asefarma Conservador P.P.',
    'fundDescription': 'Stela Maris Conservador F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N3096-F0967-G0105',
    'name': 'ENGINYERS INVERSIO SOSTENIBLE, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 6, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N2759-F1064-G0105',
    'name': 'A&G Variable P.P.',
    'fundDescription': 'FONDO DE PENSIONES DE LOS INGENIEROS 7, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N2878-F1064-G0105',
    'name': 'Asefarma Variable P.P.',
    'fundDescription': 'FONDO DE PENSIONES DE LOS INGENIEROS 7, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N2760-F1064-G0105',
    'name': 'A&G Equilibrado P.P.',
    'fundDescription': 'FONDO DE PENSIONES DE LOS INGENIEROS 7, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N2879-F1064-G0105',
    'name': 'Asefarma Equilibrado P.P.',
    'fundDescription': 'FONDO DE PENSIONES DE LOS INGENIEROS 7, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N3630-F1064-G0105',
    'name': 'ENGINYERS FONSGESTIO, PLA DE PENSIONS',
    'fundDescription': 'FONDO DE PENSIONES DE LOS INGENIEROS 7, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N5416-F1065-G0105',
    'name': 'PLA DE PENSIONS GINVEST RENTA VARIABLE GLOBAL',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 8, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N4895-F1535-G0105',
    'name': 'ENGINYERS GESTIÓ RF, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 10, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N5488-F1535-G0105',
    'name': 'GINVEST RENTA FIJA GLOBAL, PP',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 10, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N5148-F1536-G0105',
    'name': 'ENGINYERS GLOBAL VALUE, PLA DE PENSIONS',
    'fundDescription': 'FONS DE PENSIONS DELS E.I.C. 9, F.P.',
    'managerId': 'G0105',
    'managerName': 'MUTUALITAT PREVISIO SOCIAL COL.LEGI OFICIAL DELS ENGINYERS INDUSTRIALS DE CATALUNYA, A PRIMA FIXA'
  },
  {
    'id': 'N0171-F0127-G0111',
    'name': 'PLAN FAMILIAR',
    'fundDescription': 'GAESCO SENIOR FP',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N0666-F0238-G0111',
    'name': 'AGROPECUARIA DE GUISSONA PP',
    'fundDescription': 'AGROPECUARIA DE GUISSONA FP',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N1233-F0320-G0111',
    'name': 'PLAN CAPITALIZACION',
    'fundDescription': 'GAESCO JUNIOR FP',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N3017-F0803-G0111',
    'name': 'PLA PYMES',
    'fundDescription': 'GVC GAESCO EMPRESA 1 FP',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N4980-F1088-G0111',
    'name': '3 PiR DIRECTOR PP',
    'fundDescription': 'TRES 3,1416 R NFO FP',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N4860-F1089-G0111',
    'name': 'BSG PROMETEO',
    'fundDescription': 'PENSIONES CRECIMIENTO 1 F.P.',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N3806-F1241-G0111',
    'name': 'GUISSONA BOLSA PP',
    'fundDescription': 'GUISSONA BOLSA F.P.',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N4509-F1651-G0111',
    'name': 'PATRIMONIALISTA PP',
    'fundDescription': 'PATRIMONIALISTA FP',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N5349-F2131-G0111',
    'name': 'INBESTME RENTA VARIABLE PP',
    'fundDescription': 'PENSIONES CRECIMIENTO RV FONDO DE PENSIONES',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N5350-F2164-G0111',
    'name': 'INBESTME RENTA FIJA PP',
    'fundDescription': 'Pensiones Crecimiento Renta Fija  F.P.',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N5425-F2166-G0111',
    'name': 'COLLINS PATRIMONIO GLOBAL, PLAN DE PENSIONES',
    'fundDescription': 'COLLINS PATRIMONIO GLOBAL, FONDO DE PENSIONES',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N5392-F2168-G0111',
    'name': 'PENSIONES CRECIMIENTO ISR RENTA VARIABLE, FONDO DE PENSIONES',
    'fundDescription': 'PENSIONES CRECIMIENTO ISR RENTA VARIABLE, FONDO DE PENSIONES',
    'managerId': 'G0111',
    'managerName': 'GVC GAESCO PENSIONES,S.A. S.G.F.P.'
  },
  {
    'id': 'N0061-F0019-G0121',
    'name': 'MAPFRE MIXTO PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE MIXTO F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N2919-F0019-G0121',
    'name': 'PLAN DE PENSIONES MAPFRE RD 256/92 (EXPLOTACIONES AGRARIAS)',
    'fundDescription': 'MAPFRE MIXTO F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N0107-F0051-G0121',
    'name': 'MAPFRE JUBILACION ACTIVA PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE JUBILACION ACTIVA F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N0725-F0299-G0121',
    'name': 'PLAN PENSIONES EMPRESAS SISTEMA MAPFRE',
    'fundDescription': 'MAPFRE VIDA DOS F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N1028-F0299-G0121',
    'name': 'BANCO MAPFRE PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE VIDA DOS F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N1034-F0311-G0121',
    'name': 'PLAN PENSIONES CONSORCIO C.DE SEGUROS',
    'fundDescription': 'MAPFRE VIDA TRES F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N0414-F0322-G0121',
    'name': 'PLAN DE PENSIONES FIVES STEEL SPAIN',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N0662-F0322-G0121',
    'name': 'PLAN DE PENSIONES MARIE BRIZARD ESPAÑA',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N1430-F0322-G0121',
    'name': 'PLAN PENSIONES PAPELERA DE BRANDIA',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N1832-F0322-G0121',
    'name': 'PLAN DE PENSIONES DE EMPLEO CADENA COPE',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N2521-F0322-G0121',
    'name': 'PLAN DE PENSIONES EMPLEO BLACK BOX',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N2750-F0322-G0121',
    'name': 'AYUNTAMIENTO DE LA PUERTA DE SEGURA PLAN DE PENSIONES',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N3047-F0322-G0121',
    'name': 'PLAN DE PENSIONES DE EMPLEO AIRBUS SECURE LAND COMUNICATIONS',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N3293-F0322-G0121',
    'name': 'PLAN DE PENSIONES DE EMPLEO MECANIZADOS DELINTE',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N3810-F0322-G0121',
    'name': 'PLAN DE PENSIONES DE EMPLEO INDUSTRIAS AJUSCAL',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N3841-F0322-G0121',
    'name': 'P.P. EMPLEO DE PROMOCION CONJUNTA MAPFRE PYMES',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N3949-F0322-G0121',
    'name': 'PLAN DE PENSIONES DE EMPLEO AYUNTAMIENTO VILLANUEVA DEL PARDILLO',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N2701-F0322-G0121',
    'name': 'PLAN DE PENSIONES GRUPO FAIN',
    'fundDescription': 'MAPENS F1 F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N1485-F0556-G0121',
    'name': 'MAPFRE CRECIMIENTO PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE CRECIMIENTO F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N1712-F0559-G0121',
    'name': 'MAPFRE RENTA PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE RENTA F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N2819-F0840-G0121',
    'name': 'MAPFRE AMERICA PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE AMERICA PENSIONES F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N2820-F0841-G0121',
    'name': 'MAPFRE EUROPA PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE EUROPA F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N4457-F1627-G0121',
    'name': 'MAPFRE CAPITAL RESPONSABLE PLAN DE PENSIONES',
    'fundDescription': 'MAPFRE CAPITAL RESPONSABLE  F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5339-F2124-G0121',
    'name': 'PLAN DE PENSIONES MAPFRE PUENTE GARANTIZADO',
    'fundDescription': 'MAPFRE PUENTE GARANTIZADO F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5472-F2153-G0121',
    'name': 'PLAN DE PENSIONES MAPFRE PUENTE GARANTIZADO II',
    'fundDescription': 'MAPFRE PUENTE GARANTIZADO II, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5439-F2171-G0121',
    'name': 'P.P.EMPLEO PROMOCION CONJUNTA MAPFRE TU FUTURO',
    'fundDescription': 'MAPFRE PUENTE FUTURO 1, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5473-F2171-G0121',
    'name': 'P.P. EMPLEO PROMOCION CONJUNTA MAPFRE TU FUTURO II',
    'fundDescription': 'MAPFRE PUENTE FUTURO 1, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5474-F2171-G0121',
    'name': 'P.P. EMPLEO PROMOCION CONJUNTA MAPFRE TU FUTURO III',
    'fundDescription': 'MAPFRE PUENTE FUTURO 1, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5439-F2172-G0121',
    'name': 'P.P.EMPLEO DE PROMOCION CONJUNTA MAPFRE TU FUTURO',
    'fundDescription': 'MAPFRE PUENTE FUTURO 2, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5473-F2172-G0121',
    'name': 'P.P. EMPLEO PROMOCION CONJUNTA MAPFRE TU FUTURO II',
    'fundDescription': 'MAPFRE PUENTE FUTURO 2, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5474-F2172-G0121',
    'name': 'P.P. EMPLEO PROMOCION CONJUNTA MAPFRE TU FUTURO III',
    'fundDescription': 'MAPFRE PUENTE FUTURO 2, F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N5492-F2224-G0121',
    'name': 'PLAN DE PENSIONES MAPFRE PUENTE GARANTIZADO III',
    'fundDescription': 'MAPFRE PUENTE GARANTIZADO III F.P.',
    'managerId': 'G0121',
    'managerName': 'MAPFRE VIDA PENSIONES EGFP,S.A.'
  },
  {
    'id': 'N3201-F0563-G0124',
    'name': 'PLAN DE PENSIONES PLAN INDIVIDUAL LORETO OPTIMA',
    'fundDescription': 'FONDLORETO PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0124',
    'managerName': 'LORETO MUTUA'
  },
  {
    'id': 'N5020-F0793-G0124',
    'name': 'PLAN DE PENSIONES LORETO EMPRESAS',
    'fundDescription': 'FONDLORETO EMPLEO FONDO PENSIONES',
    'managerId': 'G0124',
    'managerName': 'LORETO MUTUA'
  },
  {
    'id': 'N0092-F0114-G0131',
    'name': 'RGARenta Fija',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N0745-F0114-G0131',
    'name': 'C.R.NAVARRA',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N0831-F0114-G0131',
    'name': 'C.R.GRANADA',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N0954-F0114-G0131',
    'name': 'C.R.ASTURIAS',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1219-F0114-G0131',
    'name': 'FARMACEUTICOS SORIA',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1220-F0114-G0131',
    'name': 'RURALCOOP TOLEDO',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1348-F0114-G0131',
    'name': 'CAJA RURAL DE ARAGON',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1377-F0114-G0131',
    'name': 'RURALSEGUR',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1692-F0114-G0131',
    'name': 'C.R. Nª Sª GUADALUPE',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1693-F0114-G0131',
    'name': 'C.R.SORIA',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2307-F0114-G0131',
    'name': 'CAJASIETE',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2308-F0114-G0131',
    'name': 'C.R.ALMENDRALEJO',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2707-F0114-G0131',
    'name': 'C.R.VINAROS',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3163-F0114-G0131',
    'name': 'C.R.ALBALAT',
    'fundDescription': 'RGA, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N0443-F0169-G0131',
    'name': 'P.P.COL.APARE.TEC.CASTELLON',
    'fundDescription': 'RURALFONDO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1954-F0434-G0131',
    'name': 'AYTO.CARBONERAS',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2006-F0434-G0131',
    'name': 'ASAJA JAEN-C.R. JAEN',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2375-F0434-G0131',
    'name': 'AYTO. HUERCAL OVERA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2564-F0434-G0131',
    'name': 'ESCOLA LA MASIA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2705-F0434-G0131',
    'name': 'EMPLEADOS DE ACC',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2945-F0434-G0131',
    'name': 'EMTUSA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2946-F0434-G0131',
    'name': 'C.R. TORRENT',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3157-F0434-G0131',
    'name': 'RURALPYME',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3483-F0434-G0131',
    'name': 'RURALPYME CAJA RURAL',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3513-F0434-G0131',
    'name': 'C.R. VILA-REAL',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3605-F0434-G0131',
    'name': 'FLIGHT TRAINING EUROPE',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3615-F0434-G0131',
    'name': 'COOPERATIVA OLIVARERA DE CABRA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3985-F0434-G0131',
    'name': 'BOMBEROS COMUNIDAD AUTONOMA DE MURCIA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3986-F0434-G0131',
    'name': 'BOMBEROS PROVINCIA DE CASTELLON',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4126-F0434-G0131',
    'name': 'CAJA RURAL GALEGA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4247-F0434-G0131',
    'name': 'CAJA RURAL DE SALAMANCA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4974-F0434-G0131',
    'name': 'GRUPO CAJA RURAL PROMOCION CONJUNTA, PLAN DE PENSIONES',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2306-F0434-G0131',
    'name': 'C.R.EXTREMADURA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3324-F0434-G0131',
    'name': 'PLAN DE PENSIONES DE EMPLEO DEL AYUNTAMIENTO DE SORIA',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2268-F0434-G0131',
    'name': 'PLAN DE PENSIONES DE MERCAZARAGOZA S.A.',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1535-F0434-G0131',
    'name': 'AYUNTAMIENTO SAN PEDRO',
    'fundDescription': 'RURALCAMPO F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1090-F0490-G0131',
    'name': 'SANITY',
    'fundDescription': 'RGA 3, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1418-F0490-G0131',
    'name': 'RGAMixto 75',
    'fundDescription': 'RGA 3, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1426-F0490-G0131',
    'name': 'COFERDROZA',
    'fundDescription': 'RGA 3, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1490-F0490-G0131',
    'name': 'RURALSEGUR TRES',
    'fundDescription': 'RGA 3, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1961-F0553-G0131',
    'name': 'RGADINERO',
    'fundDescription': 'RGA 5, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4143-F0553-G0131',
    'name': 'RGAMONETARIO',
    'fundDescription': 'RGA 5, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1962-F0554-G0131',
    'name': 'RGARenta Variable Europea',
    'fundDescription': 'RGA 4, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4637-F0554-G0131',
    'name': 'RURALSEGUR CUATRO',
    'fundDescription': 'RGA 4, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2838-F0557-G0131',
    'name': 'PREMUTEL',
    'fundDescription': 'RGA 6, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2839-F0557-G0131',
    'name': 'APTEA',
    'fundDescription': 'RGA 6, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3121-F0557-G0131',
    'name': 'PREJUNA',
    'fundDescription': 'RGA 6, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1052-F0558-G0131',
    'name': 'RGAMixto 40',
    'fundDescription': 'RGA 7, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1756-F0558-G0131',
    'name': 'UPA CASTILLA Y LEON',
    'fundDescription': 'RGA 7, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1887-F0558-G0131',
    'name': 'ASAJA AGROPLAN',
    'fundDescription': 'RGA 7, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2403-F0558-G0131',
    'name': 'MUJER XXI',
    'fundDescription': 'RGA 7, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4397-F0899-G0131',
    'name': 'RGA RENTA VARIABLE ESPAÑOLA',
    'fundDescription': 'RGA 8, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1218-F0900-G0131',
    'name': 'RURALCOOP HUESCA',
    'fundDescription': 'RGA 9, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1272-F0900-G0131',
    'name': 'RURALCOOP ZARAGOZA',
    'fundDescription': 'RGA 9, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1438-F0900-G0131',
    'name': 'RURALCOOP VALENCIA',
    'fundDescription': 'RGA 9, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N1940-F0900-G0131',
    'name': 'RURALCOOP ASTURIAS',
    'fundDescription': 'RGA 9, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N2582-F0900-G0131',
    'name': 'RURALCOOP CASTILLA Y LEON',
    'fundDescription': 'RGA 9, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5245-F1037-G0131',
    'name': 'RGASeguridad 2027',
    'fundDescription': 'RGA 12, F. P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': '----F1038-G0131',
    'name': '---',
    'fundDescription': 'AGROFONDO PENSIONES, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5267-F1039-G0131',
    'name': 'RGAVALOR, PLAN DE PENSIONES',
    'fundDescription': 'RGA 14, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N3858-F1327-G0131',
    'name': 'RGAGESTION ACTIVA',
    'fundDescription': 'RGA 17, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5068-F1329-G0131',
    'name': 'RGAPROTEGIDO 2025',
    'fundDescription': 'RGA 16, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5422-F1411-G0131',
    'name': 'RGAMIFUTURO 2045, Plan de Pensiones',
    'fundDescription': 'RGA 18, FONDO DE PENSIONES',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': '----F1412-G0131',
    'name': '---',
    'fundDescription': 'RGA 19, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5365-F1483-G0131',
    'name': 'RGARENTA VARIABLE GLOBAL P.P',
    'fundDescription': 'RGA 21, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4765-F1495-G0131',
    'name': 'CAJA RURAL DEL SUR',
    'fundDescription': 'RGA 22, FP',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5275-F1636-G0131',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DEL GRUPO RGA',
    'fundDescription': 'RGA 24, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5246-F1698-G0131',
    'name': 'RGAMifuturo 2024, Plan de Pensiones',
    'fundDescription': 'RGA 26 FP',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5248-F1778-G0131',
    'name': 'RGAMifuturo 2038, Plan de Pensiones',
    'fundDescription': 'RGA 28 FP',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5247-F1800-G0131',
    'name': 'RGAMifuturo 2031, Plan de Pensiones',
    'fundDescription': 'RGA 27 FP',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N0684-F1833-G0131',
    'name': 'P.P. CAIXA POPULAR',
    'fundDescription': 'RGA 31, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4734-F1833-G0131',
    'name': 'RGAMIXTO 20 II',
    'fundDescription': 'RGA 31, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5479-F1857-G0131',
    'name': 'RGASeguridad 2027 III',
    'fundDescription': 'RGA 34 F,P',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5464-F1858-G0131',
    'name': 'RGA Seguridad 2027 II',
    'fundDescription': 'RGA 33 F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N4989-F1896-G0131',
    'name': 'RGAPROTEGIDO 2024',
    'fundDescription': 'RGA 35, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N5499-F1897-G0131',
    'name': 'RGASeguridad 2027 IV',
    'fundDescription': 'RGA 36, F.P.',
    'managerId': 'G0131',
    'managerName': 'RGA RURAL PENSIONES S.A. EGFP'
  },
  {
    'id': 'N0211-F0160-G0133',
    'name': 'CCOO',
    'fundDescription': 'CONFEDERACION SINDICAL DE CCOO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0943-F0210-G0133',
    'name': 'BBVA SEGUROS,S.A.',
    'fundDescription': 'BBVA PENSIONES III F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0944-F0210-G0133',
    'name': 'EMPLEO BBVA ASSET MANAGEMENT.',
    'fundDescription': 'BBVA PENSIONES III F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0956-F0210-G0133',
    'name': 'GESTION  DE  PREVISION Y PENSIONES',
    'fundDescription': 'BBVA PENSIONES III F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0961-F0210-G0133',
    'name': 'ARG. GESTION PENSIONES',
    'fundDescription': 'BBVA PENSIONES III F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2860-F0210-G0133',
    'name': 'EMPRESAS GRUPO CAIXA TERRASSA',
    'fundDescription': 'BBVA PENSIONES III F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0748-F0218-G0133',
    'name': 'KAO CORPORATION',
    'fundDescription': 'MULTIPLAN 2000 F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0273-F0240-G0133',
    'name': 'REPSOL BUTANO',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0293-F0240-G0133',
    'name': 'REPSOL EXPLORACION, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0294-F0240-G0133',
    'name': 'REPSOL YPF LUBRICANTES Y ESPECIALIDADES, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0296-F0240-G0133',
    'name': 'REPSOL PETROLEO',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0313-F0240-G0133',
    'name': 'REPSOL QUIMICA, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0422-F0240-G0133',
    'name': 'REPSOL, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0798-F0240-G0133',
    'name': 'REPSOL COMERCIAL DE PRD. PETROL.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0947-F0240-G0133',
    'name': 'COLUMBIAN CARBON SPAIN',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1011-F0240-G0133',
    'name': 'REPSOL INVEST. PETROLÍFERAS',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1862-F0240-G0133',
    'name': 'DYNASOL ELASTOMEROS',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1885-F0240-G0133',
    'name': 'DYNASOL GESTION, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2338-F0240-G0133',
    'name': 'COGENERACION GEQUISA, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2340-F0240-G0133',
    'name': 'GENERAL QUIMICA, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2569-F0240-G0133',
    'name': 'REPSOL TRADING, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3627-F0240-G0133',
    'name': 'SESEMA',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4009-F0240-G0133',
    'name': 'PPC FUNDACIÓN REPSOL Y RIS',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5295-F0240-G0133',
    'name': 'PLAN DE PENSIONES SOLRED, S.A.',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5346-F0240-G0133',
    'name': 'PPC REPSOL ELECTRICIDAD Y GAS',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0295-F0240-G0133',
    'name': 'PP INDEPENDIENTE DE LOS BENEFICIARIOS DE REPSOL PETROLEO',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5435-F0240-G0133',
    'name': 'P.P. REPSOL SERVICIOS RENOVABLES',
    'fundDescription': 'REPSOL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0382-F0247-G0133',
    'name': 'SISTEMA DE EMPLEO BBVA',
    'fundDescription': 'BBVA FONDO DE EMPLEO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0722-F0285-G0133',
    'name': 'ALCAN PACKAGING ALZIRA ( SUÑER )',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0875-F0285-G0133',
    'name': 'POSTAL COLECTIVOS',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0891-F0285-G0133',
    'name': 'P.P. CHEMINOVA AGRO, S.A.U.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0904-F0285-G0133',
    'name': 'DIPUTACION DE JAEN',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0994-F0285-G0133',
    'name': 'AYTO SANLUCAR BARRAMEDA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1001-F0285-G0133',
    'name': 'AYTO ALCALA LA REAL',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1037-F0285-G0133',
    'name': 'PATR. DEPORTES AYTO. FUENLABRADA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1053-F0285-G0133',
    'name': 'PP MUT FERR P S VALENCIA ARAGON ( MUFEVA )',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1095-F0285-G0133',
    'name': 'AYTO ALCALA GUADAIRA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1144-F0285-G0133',
    'name': 'INST. MUNIC. LIMPIEZAS',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1145-F0285-G0133',
    'name': 'SERVICIOS DE MONTEJURRA, S.A.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1404-F0285-G0133',
    'name': 'BBVA RENDIMIENTO',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2316-F0285-G0133',
    'name': 'ARMACELL IBERIA, S.L.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2330-F0285-G0133',
    'name': 'AYTO. SAN BARTOLOME DE TIRAJANA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2702-F0285-G0133',
    'name': 'EMT FUENLABRADA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3045-F0285-G0133',
    'name': 'KENOGARD, S.A.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3300-F0285-G0133',
    'name': 'KERN PHARMA, S.L.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3443-F0285-G0133',
    'name': 'MAYR MELNHOF PACKAGING IBERICA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3614-F0285-G0133',
    'name': 'GRUPO SANITAS',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3757-F0285-G0133',
    'name': 'PPC DE OSP Y OSFI',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3761-F0285-G0133',
    'name': 'BBVA PYME PENSION DOS',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3768-F0285-G0133',
    'name': 'GRUPO ORTIZ',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3784-F0285-G0133',
    'name': 'DIP.PROV.DE SORIA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3866-F0285-G0133',
    'name': 'EMPLEADOS SKF ESPAÑOLA, S.A.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3904-F0285-G0133',
    'name': 'PURAC BIOQUÍMICA, S.A.',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3947-F0285-G0133',
    'name': 'AYTO LAS PALMAS GRAN CANARIA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4181-F0285-G0133',
    'name': 'AYTO DE TELDE',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4260-F0285-G0133',
    'name': 'EMPL. DE BEPSA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4552-F0285-G0133',
    'name': 'P.P NANO AUTOMOTIVE',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5024-F0285-G0133',
    'name': 'PLAN DE PENSIONES GRUPO INDURKEN, S.L',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2679-F0285-G0133',
    'name': 'PLAN DE PENSIONES DEL AYUNTAMIENTO DE VALDEMORO',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5204-F0285-G0133',
    'name': 'PPE CRISA',
    'fundDescription': 'BBVA PENSIONES V F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1324-F0289-G0133',
    'name': 'SONAE ARAUCO ESPAÑA SOLUCIONES DE MADERA',
    'fundDescription': 'BBVA EMPLEO DIECIOCHO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1325-F0289-G0133',
    'name': 'SONAE ARAUCO, S.A',
    'fundDescription': 'BBVA EMPLEO DIECIOCHO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0415-F0297-G0133',
    'name': 'CANAL DE ISABEL II',
    'fundDescription': 'BBVA CANAL DE ISABEL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2315-F0297-G0133',
    'name': 'HIDRAULICA SANTILLANA, SA',
    'fundDescription': 'BBVA CANAL DE ISABEL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2317-F0297-G0133',
    'name': 'HISPANAGUA, S.A.',
    'fundDescription': 'BBVA CANAL DE ISABEL II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0775-F0301-G0133',
    'name': 'LOGISTICA BERTOLA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1423-F0301-G0133',
    'name': 'HASENOSA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1467-F0301-G0133',
    'name': 'J. GARCIA CARRION, S.A.',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1563-F0301-G0133',
    'name': 'CIUDAD DE CEUTA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1605-F0301-G0133',
    'name': 'RESUR JAEN, S.A.',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1630-F0301-G0133',
    'name': 'BEFESA ALUMINIO VALLADOLID, S.A.',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1671-F0301-G0133',
    'name': 'BBVA PREVISION, PPI',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1687-F0301-G0133',
    'name': 'AYUNTAMIENTO DE CALASPARRA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1700-F0301-G0133',
    'name': 'DIPUTACION PROV. DE ALMERIA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1798-F0301-G0133',
    'name': 'COLEGIO AMERICANO DE MADRID',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1861-F0301-G0133',
    'name': 'INSTIT. MUNIC. SERV. SOC. TARRAGONA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1901-F0301-G0133',
    'name': 'DIPUTACION PROV. DE HUELVA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1958-F0301-G0133',
    'name': 'ZOETIS OLOT',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2688-F0301-G0133',
    'name': 'FUTURAUTOR I',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2864-F0301-G0133',
    'name': 'P.P. HEARST ESPAÑA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2912-F0301-G0133',
    'name': 'EXCMO. AYTO. DE ALMERIA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3031-F0301-G0133',
    'name': 'FMC FORET, S.A.',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3035-F0301-G0133',
    'name': 'ENDEKA CERAMICS, PLAN DE PENSIONES',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3088-F0301-G0133',
    'name': 'UNIVERSIDAD POLITÉCNICA DE MADRID',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3205-F0301-G0133',
    'name': 'PPE SIBELCO MINERALES, S.L',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3320-F0301-G0133',
    'name': 'SUPLEMENTOS SOLGAR',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3608-F0301-G0133',
    'name': 'WATERS CROMATOGRAFIA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3755-F0301-G0133',
    'name': 'SIND. DE C.COMUNITAT VALENCIA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3759-F0301-G0133',
    'name': 'C. PROV. C. INC. Y SALVT. HUELVA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3772-F0301-G0133',
    'name': 'PLAN DE PENSIONES AUTOLIV BKI',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3935-F0301-G0133',
    'name': 'LEO PHARMA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4100-F0301-G0133',
    'name': 'UNIVERSITAT POLITECNICA DE VALENCIA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4166-F0301-G0133',
    'name': 'VIVEROS SANCHEZ',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4195-F0301-G0133',
    'name': 'AYTO .POZUELO Y ORG.AUTONOMOS',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4255-F0301-G0133',
    'name': 'EMP. GRUPO ASEGURADOR REALE',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4365-F0301-G0133',
    'name': 'SOCELEC, S.A.',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4369-F0301-G0133',
    'name': 'SEKISUI SPECIALTY CHEMICALS EUROPE, S.L.U.',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4479-F0301-G0133',
    'name': 'AYUNTAMIENTO DE MARBELLA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4494-F0301-G0133',
    'name': 'DIPUTACION PROVINCIAL DE VALLADOLID',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4495-F0301-G0133',
    'name': 'BANDAI',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4515-F0301-G0133',
    'name': 'EMPLEADOS/AS DE MADR. RED DE GAS',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4959-F0301-G0133',
    'name': 'PLAN DE PENSIONES DE EMPLEO PEROXYCHEM',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1839-F0301-G0133',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE EMPEADOS DE SIEMENS HEALTHCARE,S.L.U',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5177-F0301-G0133',
    'name': 'PLAN DE PENSIONES SILICATOS DE MALPICA',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5340-F0301-G0133',
    'name': 'PLAN DE PENSIONES SEDECAL',
    'fundDescription': 'BBVA PENSIONES IV, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0312-F0303-G0133',
    'name': 'PPC GRUPO ENAGAS',
    'fundDescription': 'ENAGAS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0387-F0324-G0133',
    'name': 'CEMENTOS MOLINS, PLAN DE PENSIONES',
    'fundDescription': 'FONDMOLINS, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1161-F0324-G0133',
    'name': 'CEMENTOS MOLINS INDUSTRIAL, PLAN DE PENSIONES',
    'fundDescription': 'FONDMOLINS, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0822-F0354-G0133',
    'name': 'PPC GRUPO BRIDGESTONE HISPANIA',
    'fundDescription': 'BRIDGESTONE HISPANIA PENSION, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1603-F0355-G0133',
    'name': 'PLAN DE EMPLEO LIBERTY SEGUROS',
    'fundDescription': 'BBVA EMPLEO VEINTICINCO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0305-F0363-G0133',
    'name': 'PPC ALTADIS',
    'fundDescription': 'FONDPOSTAL PENSIONES VI, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1624-F0363-G0133',
    'name': 'CIA.DISTRIB.INTEG.LOGISTA, S.A.',
    'fundDescription': 'FONDPOSTAL PENSIONES VI, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0982-F0393-G0133',
    'name': 'DE LOS TRABAJADORES DE  RTVE',
    'fundDescription': 'RADIO TVE , F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1455-F0393-G0133',
    'name': 'BBVA FUTURO DE PENSIONES',
    'fundDescription': 'RADIO TVE , F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1132-F0398-G0133',
    'name': 'PPC GRUPO EDP ESPAÑA',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1330-F0398-G0133',
    'name': 'SALTOS DEL NAVIA C.B.',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1850-F0398-G0133',
    'name': 'PPC DEL GRUPO NORTEGAS',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3463-F0398-G0133',
    'name': 'ELECTRICA RIBERA DEL EBRO',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3464-F0398-G0133',
    'name': 'HIDROCANTABRICO ENERGIA',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3966-F0398-G0133',
    'name': 'PPC EDP RENOVABLES',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5399-F0398-G0133',
    'name': 'EDP COMERCIALIZADORA',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2973-F0398-G0133',
    'name': 'EMPLEADOS DE VIESGO',
    'fundDescription': 'EDP ESPAÑA PENSION, FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1041-F0400-G0133',
    'name': 'MUTUALIDAD GENERAL JUDICIAL',
    'fundDescription': 'FONDBEX II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1208-F0424-G0133',
    'name': 'GRUPO AJUNTAMENT DE BARCELONA',
    'fundDescription': 'AJUNTAMENT DE BARCELONA , F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0949-F0432-G0133',
    'name': 'BAT ESPAÑA, S.A.',
    'fundDescription': 'BAT FONDO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1079-F0499-G0133',
    'name': 'ENTE PÚBLICO PUERTOS ESTADO',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1286-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA BARCELONA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1452-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA DE TARRAGONA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1494-F0499-G0133',
    'name': 'GERENCIA URBANISTICA PORT 2000 A.P. BARCELON',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1615-F0499-G0133',
    'name': 'CENTRE D´ESTUDIS MARÍTIMOS TARRAGONA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1809-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA CARTAGENA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2689-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA VALENCIA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2865-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA DE BALEARES',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3142-F0499-G0133',
    'name': 'AUT.PORTUARIA BAHIA ALGECIRAS',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3305-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA CASTELLON',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3395-F0499-G0133',
    'name': 'AUT.PORTUARIA CEUTA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3437-F0499-G0133',
    'name': 'AUT.PORTUARIA SANTANDER',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3529-F0499-G0133',
    'name': 'AUT.PORTUARIA ALICANTE',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3553-F0499-G0133',
    'name': 'AUTORIDAD PORTUARIA DE ALMERIA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3562-F0499-G0133',
    'name': 'AUTO. PORT. DE FERROL-SAN CIBRAO',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3574-F0499-G0133',
    'name': 'AUTORID.PORTUARIA DE LAS PALMAS',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3609-F0499-G0133',
    'name': 'EMPLEADOS AUTORIDAD PORTUARIA DE A CORUÑA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3611-F0499-G0133',
    'name': 'AUTORID.PORT.STA.CRUZ DE TENERIFE',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3635-F0499-G0133',
    'name': 'AUTORID. PORTUARIA DE MÁLAGA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3636-F0499-G0133',
    'name': 'AUT.PORT.BAHIA DE CÁDIZ',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3644-F0499-G0133',
    'name': 'AUTOR. PORTUARIA DE MELILLA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3677-F0499-G0133',
    'name': 'AUTORID.PORT.GIJON',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3685-F0499-G0133',
    'name': 'AUTORID.PORT.SEVILLA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3855-F0499-G0133',
    'name': 'AUTORID.PORTUARIA DE HUELVA',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3877-F0499-G0133',
    'name': 'AUT. PORTUARIA DE AVILÉS',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3878-F0499-G0133',
    'name': 'AUTORID.PORTUARIA DE MOTRIL',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4109-F0499-G0133',
    'name': 'AUT.PORT.MARIN Y RIA DE PONTEV.',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4190-F0499-G0133',
    'name': 'PLAN FUNDACIO PORT DE CASTELLO',
    'fundDescription': 'PUERTO PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1425-F0519-G0133',
    'name': 'DIPUTACION PROV. SEVILLA',
    'fundDescription': 'DIPUTACION PROVINCIAL DE SEVILLA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1492-F0519-G0133',
    'name': 'OPAEF',
    'fundDescription': 'DIPUTACION PROVINCIAL DE SEVILLA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1614-F0519-G0133',
    'name': 'SOCIEDAD PROV INFORMAT SEVILLA',
    'fundDescription': 'DIPUTACION PROVINCIAL DE SEVILLA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2288-F0519-G0133',
    'name': 'SEVILLA ACTIVA, S.A.',
    'fundDescription': 'DIPUTACION PROVINCIAL DE SEVILLA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2366-F0519-G0133',
    'name': 'SISTEMA DE EMPLEO DE PRODETUR',
    'fundDescription': 'DIPUTACION PROVINCIAL DE SEVILLA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5405-F0519-G0133',
    'name': 'AGUAS DEL HUESNA',
    'fundDescription': 'DIPUTACION PROVINCIAL DE SEVILLA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1328-F0520-G0133',
    'name': 'ASOC. NUCLEAR ASCO-VANDELLOS II',
    'fundDescription': 'NUCLEAR ASCO-VANDELLOS II F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1735-F0637-G0133',
    'name': 'SERVICIOS COMARCA DE PAMPLONA',
    'fundDescription': 'DEPOSITO DE MENDILLORRI, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2507-F0651-G0133',
    'name': 'PLAN DE PENSIONES ARDO ESPAÑA',
    'fundDescription': 'FRUDEFONDO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1882-F0683-G0133',
    'name': 'VICRILA, S.A.',
    'fundDescription': 'VICRILA PENSIONES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1985-F0717-G0133',
    'name': 'BAYER HISPANIA, S.L.',
    'fundDescription': 'DELTA PENSIONES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0789-F0744-G0133',
    'name': 'EMACSA',
    'fundDescription': 'BBVA EMPLEO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1910-F0745-G0133',
    'name': 'P.P. EMPLEADOS DE SUBCOM',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2324-F0745-G0133',
    'name': 'ARMSTRONG ARCHITECTURAL PRODUCTS',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2365-F0745-G0133',
    'name': 'FERROCARRIL DE SOLLER',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2519-F0745-G0133',
    'name': 'JEVSA',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3056-F0745-G0133',
    'name': 'CPPS, S.A.',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3057-F0745-G0133',
    'name': 'FERAG IBERICA S.A.',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3104-F0745-G0133',
    'name': 'WL GORE Y ASOCIADOS',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3444-F0745-G0133',
    'name': 'UTE FERROVIAL AGROMAN-GRUPISA',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3965-F0745-G0133',
    'name': 'CLEAR CHANNEL S.A.',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5150-F0745-G0133',
    'name': 'PPC EMPLEADOS GRUPO TEVA',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5188-F0745-G0133',
    'name': 'PLAN DE PENSIONES DE GRAN CASINO MADRID',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5221-F0745-G0133',
    'name': 'PPE UPFIELD SPAIN, S.L.U.',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5269-F0745-G0133',
    'name': 'PLAN DE PENSIONES DE CHANEL',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5297-F0745-G0133',
    'name': 'PLAN DE PENSIONES DE RIOT GAMES SERVICES',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5376-F0745-G0133',
    'name': 'PP EMPLEO SERVICIOS CCOO',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5440-F0745-G0133',
    'name': 'PPC EMPRESAS DEL GRUPO BOEING EN ESPAÑA',
    'fundDescription': 'BBVA EMPLEO DOS F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2461-F0746-G0133',
    'name': 'PROMOCION CONJUNTA DE ABC',
    'fundDescription': 'BBVA EMPLEO TRES F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2435-F0767-G0133',
    'name': 'PPC NEXANS IBERIA',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2436-F0767-G0133',
    'name': 'THALES ESPAÑA, GRP, S.A.U.',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2437-F0767-G0133',
    'name': 'NTT Intelligent Technologies and Services S.L.',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2440-F0767-G0133',
    'name': 'THALES ALENIA SPACE ESPAÑA, S.A.',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2630-F0767-G0133',
    'name': 'A NOVO COMLINKS ESPAÑA S.L.',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3352-F0767-G0133',
    'name': 'DRAKA COMTEQ IBERICA',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3603-F0767-G0133',
    'name': 'EMP. DE TCT MOBILE EUROPE SAS',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3872-F0767-G0133',
    'name': 'DOMINION CENTRO DE CONTROL, PPC',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4514-F0767-G0133',
    'name': 'THALES SISTEMAS DE SEGURIDAD',
    'fundDescription': 'BBVA EMPLEO CUATRO F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0751-F0768-G0133',
    'name': 'VIDRIERIAS ROVIRA',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0837-F0768-G0133',
    'name': 'AYUNTAMIENTO DE PALENCIA',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0964-F0768-G0133',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS DEL AYUNT. MONFORTE DEL CID',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1338-F0768-G0133',
    'name': 'AYTO. COLLADO VILLALBA',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2669-F0768-G0133',
    'name': 'EMPLEADOS DE CIFE',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2701-F0768-G0133',
    'name': 'PPC GRUPO FAIN',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2885-F0768-G0133',
    'name': 'AGUAS DEL PUERTO EMPRESA MUNICIPAL',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2929-F0768-G0133',
    'name': 'GRUPO SHOWA DENKO CARBON',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2954-F0768-G0133',
    'name': 'E.M. SERVICIOS FUNERARIOS DE MADRID',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3026-F0768-G0133',
    'name': 'AECOM URS ESPAÑA, S.L.U.',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3102-F0768-G0133',
    'name': 'AYUNTAMIENTO DE LEPE',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3282-F0768-G0133',
    'name': 'IQVIA INFORMATION, S.A.',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3285-F0768-G0133',
    'name': 'BBVA PYME PENSION',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3484-F0768-G0133',
    'name': 'P.C. PUBLICOS CIUD. AUTON. DE MELILLA',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3510-F0768-G0133',
    'name': 'AYTO. DE MIJAS',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3569-F0768-G0133',
    'name': 'PPE SYNTHESIA',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3700-F0768-G0133',
    'name': 'AYTO. DE SS. DE LOS REYES',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3800-F0768-G0133',
    'name': 'DIPUTACION DE VALENCIA , PLAN DE PENSIONES',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3911-F0768-G0133',
    'name': 'BBVA SECTOR PÚBLICO',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4117-F0768-G0133',
    'name': 'PERSONAL DEL AYUNT DE BURJASSOT',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4119-F0768-G0133',
    'name': 'AYUNTAMIENTO DE BENICARLO',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4553-F0768-G0133',
    'name': 'AMBROSIO VELASCO',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4624-F0768-G0133',
    'name': 'AIRE COMPRIMIDO INDUSTRIAL IBERIA',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5446-F0768-G0133',
    'name': 'CIMSA CEMENTOS DE ESPAÑA SAU',
    'fundDescription': 'BBVA EMPLEO CINCO,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2635-F0836-G0133',
    'name': 'P.P. EMPL. MAHOU FABR.CERVEZA',
    'fundDescription': 'EMPLEADOS Y EMPLEADAS DE MAHOU FABRICA DE CERVEZAS FONDO DE PENSIONES',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2951-F0849-G0133',
    'name': 'GRUPO CEMEX',
    'fundDescription': 'BBVA EMPLEO OCHO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3018-F0850-G0133',
    'name': 'EMPLEADOS DE SIDMED',
    'fundDescription': 'BBVA EMPLEO NUEVE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0766-F0877-G0133',
    'name': 'AYTO. DE FUENLABRADA',
    'fundDescription': 'BBVA EMPLEO DIEZ, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1004-F0877-G0133',
    'name': 'PATR. CULTURAL AYTO. FUENLABRADA',
    'fundDescription': 'BBVA EMPLEO DIEZ, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1355-F0877-G0133',
    'name': 'OTAF',
    'fundDescription': 'BBVA EMPLEO DIEZ, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4095-F0877-G0133',
    'name': 'EMPLEADOS DE FUMECO',
    'fundDescription': 'BBVA EMPLEO DIEZ, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3167-F0879-G0133',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE LAS ENTIDADES DEL GRUPO AENA',
    'fundDescription': 'EMPLEADOS DE AENA, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0418-F0979-G0133',
    'name': 'U.G.T.',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0765-F0979-G0133',
    'name': 'ASOC. EST. EMPLEADOS DE NOTARIAS (FEAPEN)',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0767-F0979-G0133',
    'name': 'COPYME',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1036-F0979-G0133',
    'name': 'HUÉRFANOS FERROVIARIOS',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3370-F0979-G0133',
    'name': 'F.E.L.O.',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5030-F0979-G0133',
    'name': 'P.P. DE LA ASSOCIACIO PROFESIONAL DE VENEDORS DE PREMSA DE TARRAGON',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0270-F0979-G0133',
    'name': 'P.P CUNAL',
    'fundDescription': 'BBVA COLECTIVO UNO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0161-F0980-G0133',
    'name': 'JOHNSON DIVERSEY ESPAÑA, S.L.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0849-F0980-G0133',
    'name': 'CONSORCIO AGUAS DE TARRAGONA',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1419-F0980-G0133',
    'name': 'CELANESE CHEMICALS IBERICA, S.L.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1715-F0980-G0133',
    'name': 'PLA PENSIONS SISTEMA OCUPACION AJUNTAMENT DE LA ROCA DEL VALES',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1647-F0980-G0133',
    'name': 'PPC INMA, S.L.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2771-F0980-G0133',
    'name': 'P.P. INEOS COMPOSITES HISPANIA',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2775-F0980-G0133',
    'name': 'PPE MAXION WHEELS ESPAÑA,S.L.U.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2886-F0980-G0133',
    'name': 'AGFA GRAPHICS N.V. SUCURSAL EN ESPAÑA',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3152-F0980-G0133',
    'name': 'MARPOSS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3481-F0980-G0133',
    'name': 'AJUNTAMENT DE GRANOLLERS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3621-F0980-G0133',
    'name': 'MUTUA DE PROPIETARIOS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3812-F0980-G0133',
    'name': 'P.P. DE PROMOCION CONJUNTA DE HENKEL',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3853-F0980-G0133',
    'name': 'TÚNELS DE BARCELONA I CADÍ CONCESSIONARIA DE LA GENERALITAT DE CATALUNYA, S.A.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3953-F0980-G0133',
    'name': 'PERSONAL DEL GRUPO SEAT',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3955-F0980-G0133',
    'name': 'BARCELONA ACTIVA, S.A.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4048-F0980-G0133',
    'name': 'AGFA HEALTHCARH SPAIN',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4328-F0980-G0133',
    'name': 'BBVA INSTITUCIONES',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4410-F0980-G0133',
    'name': 'INEOS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4540-F0980-G0133',
    'name': 'NEXEO SOLUTIONS SPAIN',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4705-F0980-G0133',
    'name': 'ELIX POLYMERS, S.L.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4910-F0980-G0133',
    'name': 'LA ANTIGUA LAVANDERA',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4958-F0980-G0133',
    'name': 'MARINA TEXTIL, S.L.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4971-F0980-G0133',
    'name': 'SOLENIS HISPANIA, S.A.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4972-F0980-G0133',
    'name': 'LUBRIZOL',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4996-F0980-G0133',
    'name': 'P.P ASK CHEMICALS ESPAñA,S.A.U',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5098-F0980-G0133',
    'name': 'PLAN DE PENSIONES DE NLS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3400-F0980-G0133',
    'name': 'PPC EMPRESAS GRUPO LANXESS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2643-F0980-G0133',
    'name': 'P.P. SEALED AIR PACKAGING,S.L.',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1556-F0980-G0133',
    'name': 'P.P BARCELONA DE SERVEIS MUNICIPALS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5313-F0980-G0133',
    'name': 'P.P. ESSENTRA COMPONENTS',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5351-F0980-G0133',
    'name': 'PPE PLIXXENT',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5358-F0980-G0133',
    'name': 'ASHLAND SPECIALITIES HISPANIA',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5454-F0980-G0133',
    'name': 'AGFA OFFSET BV',
    'fundDescription': 'BBVA COLECTIVOS DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1564-F1009-G0133',
    'name': 'AUTOBUSES DE CORDOBA, SAM',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2325-F1009-G0133',
    'name': 'UNIVERSIDAD DE CADIZ',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3116-F1009-G0133',
    'name': 'PLAN DE PENSIONES DE CEMENTOS COSMOS S.A.',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3854-F1009-G0133',
    'name': 'AGENCIA DE GEST. AGRARIA Y PESQ. DE ANDALUCIA',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4118-F1009-G0133',
    'name': 'INTURJOVEN',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4246-F1009-G0133',
    'name': 'DIPUTACIÓN PROVINCIAL DE CADIZ',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5257-F1009-G0133',
    'name': 'PPC DEL SECTOR PUBLICO ANDALUZ',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4019-F1009-G0133',
    'name': 'DIPUTACION PROVINCIAL DE CORDOBA',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2982-F1009-G0133',
    'name': 'PLAN DE PENSIONES EMPRESA MUNICIPAL DE AGUAS DE HUELVA, S.A.',
    'fundDescription': 'BBVA EMPLEO TREINTA Y TRES, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3402-F1031-G0133',
    'name': 'ADMON. GENERAL DEL ESTADO',
    'fundDescription': 'F.P.ADMON.GENERAL DEL ESTADO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3610-F1032-G0133',
    'name': 'EMPLEADOS DE SAN MIGUEL',
    'fundDescription': 'EMPLEADOS Y EMPLEADAS DE SAN MIGUEL FABRICA DE CERVEZAS',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0838-F1033-G0133',
    'name': 'E. DE LAS CN DE ALAMARAZ -TRILLO',
    'fundDescription': 'BBVA EMPLEO QUINCE,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3492-F1120-G0133',
    'name': 'P.P. PFIZER, S.L.',
    'fundDescription': 'BBVA EMPLEO TREINTA, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1920-F1120-G0133',
    'name': 'PLAN DE PENSIONES WYETH FARMA',
    'fundDescription': 'BBVA EMPLEO TREINTA, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5378-F1120-G0133',
    'name': 'P.P. PFIZER GEP, S.L.',
    'fundDescription': 'BBVA EMPLEO TREINTA, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3778-F1189-G0133',
    'name': 'PPC A.M.A GRUPO',
    'fundDescription': 'A.M.A. Pensiones F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2385-F1196-G0133',
    'name': 'GRUPO ERCROS',
    'fundDescription': 'GRUPO ERCROS, FP',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2470-F1197-G0133',
    'name': 'PLAN DE PENSIONES SISTEMA EMPLEO DE LOS EMPLEADOS DE AGUAS DE JEREZ EMSA',
    'fundDescription': 'BBVA EMPLEO DIECISIETE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2724-F1197-G0133',
    'name': 'EMP. METR. ABA.SANEA.AGUAS SEVILLA',
    'fundDescription': 'BBVA EMPLEO DIECISIETE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4530-F1197-G0133',
    'name': 'EMPRESA PROV. AGUAS CORDOBA',
    'fundDescription': 'BBVA EMPLEO DIECISIETE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4188-F1347-G0133',
    'name': 'AGENCIA DE MEDIO AMBIENTE Y AGUA DE ANDALUCIA',
    'fundDescription': 'EGMASA, FP',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2321-F1348-G0133',
    'name': 'AFFINITY PETCARE,S.A',
    'fundDescription': 'BBVA EMPLEO VEINTE ,F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3976-F1511-G0133',
    'name': 'PLAN DE PENSIONES AGUAS DE VALENCIA',
    'fundDescription': 'BBVA EMPLEO TREINTA Y DOS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3754-F1574-G0133',
    'name': 'Empleados de Goldman Sachs',
    'fundDescription': 'BBVA EMPLEO VEINTICUATRO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1745-F1575-G0133',
    'name': 'RTVA, plan de empleo',
    'fundDescription': 'BBVA EMPLEO VEINTIDOS',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0937-F1578-G0133',
    'name': 'GRUPO DE EMPRESAS AIG',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1449-F1578-G0133',
    'name': 'WALT DISNEY COMPANY IBERIA, P.P.',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N1633-F1578-G0133',
    'name': 'CCP COMPOSITES RESINS ESPAÑA, S.L.U.',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2991-F1578-G0133',
    'name': 'P.P. ELECTRONIC ARTS',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3346-F1578-G0133',
    'name': 'PLAN DE PENSIONES DE ATOS',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4740-F1578-G0133',
    'name': 'P.P ENGIE ESPAÑA',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4769-F1578-G0133',
    'name': 'EMPLEADOS DE ZOETIS SPAIN, S.L.U.',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4796-F1578-G0133',
    'name': 'SAICA',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5001-F1578-G0133',
    'name': 'PPC EMPLEADOS DE REDEXIS GAS',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5157-F1578-G0133',
    'name': 'PLAN DE EMPLEADOS DE HILTI',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5179-F1578-G0133',
    'name': 'P.P BONDUELE IBERICA,S.A.U.',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N4271-F1578-G0133',
    'name': 'P.P. EMPLEADOS DE CHEP ESPAÑA',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3283-F1578-G0133',
    'name': 'FUJITSU TECHNOLOGY SOLUTIONS, S.A.',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5062-F1578-G0133',
    'name': 'P.P.HISCOX ESPAÑA',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2939-F1578-G0133',
    'name': 'HONEYWELL, S.L.',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2940-F1578-G0133',
    'name': 'HONEYWELL TECNOLOGIA Y SEGURIDAD',
    'fundDescription': 'BBVA EMPLEO VEINTITRÉS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N2456-F1760-G0133',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE LAS EMPRESAS LOEWE',
    'fundDescription': 'BBVA EMPLEO VEINTISIETE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3307-F1783-G0133',
    'name': 'AMEC FOSTER WHEELER ENERGIA',
    'fundDescription': 'BBVA EMPLEO VEINTISEIS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0238-F1783-G0133',
    'name': 'PLAN DE PENSIONES WOOD IBERIA',
    'fundDescription': 'BBVA EMPLEO VEINTISEIS, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5014-F1970-G0133',
    'name': 'P.P.DE LOS EMPLEADOS/AS DE MAHOU',
    'fundDescription': 'BBVA EMPLEO TREINTA Y CINCO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N5250-F1983-G0133',
    'name': 'PPE HEINEKEN ESPAÑA,S.A.',
    'fundDescription': 'FONDO DE PENSIONES DEL PERSONAL DE HEINEKEN, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3683-F2138-G0133',
    'name': 'P.P.EMPLEADOS GRUPO AIRBUS GROUP',
    'fundDescription': 'BBVA EMPLEO TREINTA Y OCHO, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N3850-F2188-G0133',
    'name': 'P.P. DE EMPLEO DE RICHEMONT IBERIA',
    'fundDescription': 'BBVA EMPLEO TREINTA Y NUEVE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': '21881-F2188-G0133',
    'name': 'P.P WATCHFINDER, ESPAÑA',
    'fundDescription': 'BBVA EMPLEO TREINTA Y NUEVE, F.P.',
    'managerId': 'G0133',
    'managerName': 'GPP'
  },
  {
    'id': 'N0310-F0232-G0135',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE MUTUA MADRILEÑA',
    'fundDescription': 'FONDAUTO CONSERVADOR, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N1754-F0984-G0135',
    'name': 'Plan de Pensiones Unión de Campesinos de Castilla y León (UCCL)',
    'fundDescription': 'FONDOMUTUA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3077-F0984-G0135',
    'name': 'FONDOMUTUA, P.P.',
    'fundDescription': 'FONDOMUTUA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4430-F0984-G0135',
    'name': 'MIRABAUD PATRIMONIO, P.P.',
    'fundDescription': 'FONDOMUTUA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3716-F0984-G0135',
    'name': 'UBS SELECCION CONSERVADOR, P.P.',
    'fundDescription': 'FONDOMUTUA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N2799-F0984-G0135',
    'name': 'PLAN DE PENSIONES COLEGIO DE HUÉRFANOS DE HACIENDA',
    'fundDescription': 'FONDOMUTUA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3523-F1235-G0135',
    'name': 'FONDOMUTUA MODERADO, P.P.',
    'fundDescription': 'FONDOMUTUA MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3524-F1236-G0135',
    'name': 'FONDOMUTUA RENTA VARIABLE INTERNACIONAL, P.P.',
    'fundDescription': 'FONDOMUTUA RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3525-F1237-G0135',
    'name': 'FONDOMUTUA CONSERVADOR, P.P.',
    'fundDescription': 'FONDOMUTUA CONSERVADOR, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N2738-F1370-G0135',
    'name': 'P.P. SCHRODER INVESTMENT MANAGEMENT LIMITED, Sucursal en España',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3100-F1370-G0135',
    'name': 'P.P. Promocion Conjunta Grupo CREDIT SUISSE 1 Y 3',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3848-F1370-G0135',
    'name': 'P.P. Empleados GESROVIRALTA',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4147-F1370-G0135',
    'name': 'P.P. EMPLEADOS GRUPO MUTUA MADRILEÑA',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4894-F1370-G0135',
    'name': 'P.P. Promocion Conjunta Grupo CETELEM',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N2003-F1370-G0135',
    'name': 'P.P. EMPLEADOS THERMO FISHER 1 Y 3',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5291-F1370-G0135',
    'name': 'PLAN DE PENSIONES EMPLEO MIRABAUD ESPAÑA',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3188-F1370-G0135',
    'name': 'PLAN EMPLEADOS AVANADE SPAIN 1 Y 3',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5455-F1370-G0135',
    'name': 'FONDOMUTUA EMPRESAS PLAN DE PROMOCION CONJUNTA 1 Y 3',
    'fundDescription': 'FONDOMUTUA EMPLEO MODERADO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3715-F1372-G0135',
    'name': 'UBS SELECCIÓN DINÁMCIO PPI',
    'fundDescription': 'FONDOMUTUA FLEXIBILIDAD RVM, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5327-F1372-G0135',
    'name': 'FONDOMUTUA FLEXIBILIDAD RVM, P.P.',
    'fundDescription': 'FONDOMUTUA FLEXIBILIDAD RVM, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3273-F1373-G0135',
    'name': 'Plan de los Empleados de UBS España',
    'fundDescription': 'FONDOMUTUA EMPLEO DINAMICO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4429-F1722-G0135',
    'name': 'MIRABAUD CRECIMIENTO, P.P.',
    'fundDescription': 'FONDOMUTUA BOLSA EUROPEA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4619-F1722-G0135',
    'name': 'FONDOMUTUA BOLSA EUROPEA, P.P.',
    'fundDescription': 'FONDOMUTUA BOLSA EUROPEA, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N0847-F1936-G0135',
    'name': 'P.P. GRUPO A.F.I.',
    'fundDescription': 'A.F.I. EMPLEO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5450-F1936-G0135',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS HISDESAT',
    'fundDescription': 'A.F.I. EMPLEO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5478-F1936-G0135',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE PROMOCIÓN CONJUNTA DE FARMACIAS COFARES',
    'fundDescription': 'A.F.I. EMPLEO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N2003-F2065-G0135',
    'name': 'P.P. EMPLEADOS THERMO FISHER 2',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N2738-F2065-G0135',
    'name': 'P.P. SCHRODER INVESTMENT MANAGEMENT LIMITED, Sucursal en España',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5455-F2065-G0135',
    'name': 'FONDOMUTUA EMPRESAS PLAN DE PROMOCION CONJUNTA 2',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3100-F2065-G0135',
    'name': 'P.P. Promocion Conjunta Grupo CREDIT SUISSE 2',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4147-F2065-G0135',
    'name': 'P.P. EMPLEADOS GRUPO MUTUA MADRILEÑA 2',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3188-F2065-G0135',
    'name': 'PLAN EMPLEADOS AVANADE SPAIN 2',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N3381-F2065-G0135',
    'name': 'PP EMPLEADOS CBRE INVESTMENT MANAGEMENT SOUTHERN EUROPE SL',
    'fundDescription': 'FONDOMUTUA EMPLEO RENTA VARIABLE INTERNACIONAL, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4488-F2154-G0135',
    'name': 'EDM PENSIONES RENTA FIJA PP',
    'fundDescription': 'FONDOMUTUA PENSIONES UNO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N4886-F2155-G0135',
    'name': 'EDM PENSIONES RENTA VARIABLE PP',
    'fundDescription': 'FONDOMUTUA PENSIONES DOS, F.P',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N0310-F2175-G0135',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE MUTUA MADRILEÑA AUTOMOVILISTA 2',
    'fundDescription': 'FONDAUTO DINAMICO, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5447-F2177-G0135',
    'name': 'PLAN GENERACION 60S PP',
    'fundDescription': 'FONDOMUTA CICLO DE VIDA 1, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5449-F2178-G0135',
    'name': 'PLAN GENERACION 80S PP',
    'fundDescription': 'FONDOMUTA CICLO DE VIDA 3, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N5448-F2180-G0135',
    'name': 'PLAN GENERACION 70S PP',
    'fundDescription': 'FONDOMUTA CICLO DE VIDA 2, F.P.',
    'managerId': 'G0135',
    'managerName': 'MUTUACTIVOS PENSIONES SGFP S.A.'
  },
  {
    'id': 'N0184-F0129-G0137',
    'name': 'ARQUIA BANCA PLAN RENTA GLOBAL, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N0950-F0129-G0137',
    'name': 'PLAN II DE LA MUTUALIDAD',
    'fundDescription': 'ARQUIDOS, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N2254-F0738-G0137',
    'name': 'ARQUIA BANCA PLAN LIDERES GLOBALES, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS CRECIMIENTO, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N2253-F0739-G0137',
    'name': 'ARQUIA BANCA PLAN INVERSION, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS INVERSION, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N2252-F0740-G0137',
    'name': 'ARQUIA BANCA PLAN LIDERES DEL FUTURO, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS BOLSA, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N2639-F0848-G0137',
    'name': 'ARQUIA BANCA PLAN MONETARIO, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS MONETARIO, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N4655-F1742-G0137',
    'name': 'SOLVENTIS EOS, PLAN DE PENSIONES',
    'fundDescription': 'SOLVENTIS EOS RENTA VARIABLE, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N4654-F1743-G0137',
    'name': 'SOLVENTIS CRONOS, PLAN DE PENSIONES',
    'fundDescription': 'SOLVENTIS CRONOS, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N4755-F1807-G0137',
    'name': 'ARQUIA BANCA PLAN OBJETIVO 2024, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS ESTABILIDAD 3, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N4706-F1808-G0137',
    'name': 'ARQUIA BANCA PLAN OBJETIVO 2027, PLAN DE PENSIONES',
    'fundDescription': 'ARQUIDOS CONSERVADOR, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N5152-F1910-G0137',
    'name': 'ARQUIA BANCA PLAN PRUDENTE, PP',
    'fundDescription': 'ARQUIDOS PRUDENTE, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N5226-F1911-G0137',
    'name': 'ARQUIA BANCA PLAN PROFIM DISCRECIONAL 50',
    'fundDescription': 'ARQUIDOS PROFIM DISCRECIONAL 50, FP',
    'managerId': 'G0137',
    'managerName': 'ARQUIPENSIONES SA'
  },
  {
    'id': 'N0454-F0295-G0148',
    'name': 'PSN PLAN ASOCIADO PENSIONES',
    'fundDescription': 'PREVISION SANITARIA NACIONAL FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5182-F0295-G0148',
    'name': 'PSN Valor Ahorro, Plan de Pensiones',
    'fundDescription': 'PREVISION SANITARIA NACIONAL FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N1923-F0703-G0148',
    'name': 'PLAN INDIVIDUAL PENSIONES',
    'fundDescription': 'PREVISION II FONDO PENSIONES',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N0855-F1016-G0148',
    'name': 'PSN MUTUA DE SEGUROS',
    'fundDescription': 'PSN EMPLEO FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5434-F1016-G0148',
    'name': 'PSN PLAN EMPLEO DE PLEXUS SERVICIOS GENERALES SL',
    'fundDescription': 'PSN EMPLEO FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5462-F1016-G0148',
    'name': 'PSN PLAN EMPRESA 100 PP',
    'fundDescription': 'PSN EMPLEO FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N4804-F1879-G0148',
    'name': 'PLAN DE PENSIONES PSN PENSIONES RENTA FIJA CONFIANZA',
    'fundDescription': 'PSN PERSONAL RENTA FIJA FONDO DE PENSIONES',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5271-F1880-G0148',
    'name': 'FINANBEST EFICIENTE RENTA FIJA MIXTA PP',
    'fundDescription': 'PSN RENTA FIJA MIXTO GLOBAL FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5224-F2087-G0148',
    'name': 'PSN AUTORRESPONSABILIDAD PP',
    'fundDescription': 'PSN PERSONAL RENTA VARIABLE, FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5254-F2102-G0148',
    'name': 'FEELCAPITAL 50, PLAN DE PENSIONES',
    'fundDescription': 'PSN FLEXIBLE FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5270-F2111-G0148',
    'name': 'FINANBEST EFICIENTE BOLSA GLOBAL PP',
    'fundDescription': 'PSN RENTA VARIABLE GLOBAL FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N5353-F2135-G0148',
    'name': 'GDP WORLD EQUITY PP',
    'fundDescription': 'PSN GLOBAL BOLSA UNIVERSAL FP',
    'managerId': 'G0148',
    'managerName': 'PREVISION SANITARIA NACIONAL'
  },
  {
    'id': 'N0123-F0063-G0153',
    'name': 'MERCHBANC',
    'fundDescription': 'MERCHPENSION, F.P.',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N1175-F0451-G0153',
    'name': 'MERCHBANC GLOBAL',
    'fundDescription': 'MERCHPENSION GLOBAL, F.P.',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N3020-F0970-G0153',
    'name': 'MERCHBANC MIXTO',
    'fundDescription': 'MERCHPENSION MIXTO, F.P.',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5290-F2108-G0153',
    'name': 'MERCHPENSION RENTA FIJA FLEXIBLE P.P.',
    'fundDescription': 'MERCHPENSION RENTA FIJA FLEXIBLE F.P.',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5393-F2158-G0153',
    'name': 'MAVERICK RETIREMENT, PP',
    'fundDescription': 'MAVERICK RETIREMENT FUND, FP',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5394-F2159-G0153',
    'name': 'MYINVESTOR INDEXADO S&P 500, PP',
    'fundDescription': 'MYINVESTOR INDEXADO S&P 500, FP',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5396-F2160-G0153',
    'name': 'MYINVESTOR INDEXADO GLOBAL, PP',
    'fundDescription': 'MYINVESTOR INDEXADO GLOBAL, FP',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5431-F2167-G0153',
    'name': 'R3 GLOBAL BALANCED, P.P.',
    'fundDescription': 'R3 GLOBAL BALANCED, F.P.',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5459-F2187-G0153',
    'name': 'MYINVESTOR CARTERA PERMANENTE',
    'fundDescription': 'MYINVESTOR CARTERA PERMANENTE FP',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5241-F2196-G0153',
    'name': 'PLAN DE PENSIONES DE PROMOCIÓN CONJUNTA DEL GRUPO ANDBANK',
    'fundDescription': 'MYINVESTOR EMPLEO, FP',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N5360-F2196-G0153',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE MEDIAPATRIMONIA INVEST, SL',
    'fundDescription': 'MYINVESTOR EMPLEO, FP',
    'managerId': 'G0153',
    'managerName': 'MERCHBANC, E.G.F.P., S.A.'
  },
  {
    'id': 'N0396-F0286-G0154',
    'name': 'SECI PENSIONES',
    'fundDescription': 'SECI I FONDO DE PENSIONES',
    'managerId': 'G0154',
    'managerName': 'Seguros El Corte Inglés Vida, Pensiones y Reaseguros, S.A.'
  },
  {
    'id': 'N1521-F0581-G0154',
    'name': 'SECI BOLSA ACTIVO',
    'fundDescription': 'SECI FUTURO, FONDO DE PENSIONES',
    'managerId': 'G0154',
    'managerName': 'Seguros El Corte Inglés Vida, Pensiones y Reaseguros, S.A.'
  },
  {
    'id': 'N1678-F0634-G0154',
    'name': 'SECI RENTA FIJA',
    'fundDescription': 'SECI PATRIMONIO, FONDO DE PENSIONES',
    'managerId': 'G0154',
    'managerName': 'Seguros El Corte Inglés Vida, Pensiones y Reaseguros, S.A.'
  },
  {
    'id': 'N4551-F1687-G0154',
    'name': 'SECI SELECCION GESTORAS',
    'fundDescription': 'SECI SELECCION',
    'managerId': 'G0154',
    'managerName': 'Seguros El Corte Inglés Vida, Pensiones y Reaseguros, S.A.'
  },
  {
    'id': 'N0334-F0284-G0162',
    'name': 'Empleados de Telefónica',
    'fundDescription': 'Empleados de Telefonica de España',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1140-F0437-G0162',
    'name': 'Empleados de Telefónica Moviles España',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1702-F0437-G0162',
    'name': 'Empleados de Telefonica Holding',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1703-F0437-G0162',
    'name': 'Empleados de Telefonica Internacional',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N2275-F0437-G0162',
    'name': 'Empleados de Telefonica I+D',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N2765-F0437-G0162',
    'name': 'Empleados de Telfisa',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N2814-F0437-G0162',
    'name': 'PPE TELEFÓNICA GLOBAL SOLUTIONS',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N2816-F0437-G0162',
    'name': 'Empleados de Fonditel',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3063-F0437-G0162',
    'name': 'P.P.E.P.C. TELEFÓNICA CORREDURÍA DE SEGUROS Y REASEGUROS Y TELEFONICA SEGUROS Y REASEGUROS',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3119-F0437-G0162',
    'name': 'P.P.E. TELEF. EDUCACION DIGITAL',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3263-F0437-G0162',
    'name': 'Empleados de Telefonica Soluciones',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3732-F0437-G0162',
    'name': 'Empleados de Telefonica Ingenieria de Seguridad',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3910-F0437-G0162',
    'name': 'P.P.E.TELEFÓNICA IOT&BIG DATA',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4020-F0437-G0162',
    'name': 'Telefónica Servicios Audiovisuales y Telef. Contenidos',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4045-F0437-G0162',
    'name': 'Empleados de Fundacion Telefonica',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4047-F0437-G0162',
    'name': 'Empleados de Telyco',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4443-F0437-G0162',
    'name': 'Plan de Pensiones de Empleo Telefonica Global Technology',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4554-F0437-G0162',
    'name': 'Plan de Pensiones de Empleados de Telefónica Soluciones de Outsourcing',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4645-F0437-G0162',
    'name': 'Plan de pensiones empleados Telefónica Digital España',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4746-F0437-G0162',
    'name': 'Plan de pensiones empleados de Telefónica GIES',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4767-F0437-G0162',
    'name': 'Empleados de Telefónica Compras Electrónicas',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5126-F0437-G0162',
    'name': 'PROMOCION CONJUNTA TELXIUS',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5229-F0437-G0162',
    'name': 'PPE FUNDACION PROFUTURO',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5322-F0437-G0162',
    'name': 'P.P.E. T. SOLUCIONES CRIPTOGRAFIA',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5361-F0437-G0162',
    'name': 'P.P. P.C. MOVISTAR+',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5402-F0437-G0162',
    'name': 'PPE Telefónica Cybersecurity & Cloud Tech S.L.',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5423-F0437-G0162',
    'name': 'TELEFÓNICA HISPANOAMERICA, SA',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5487-F0437-G0162',
    'name': 'P.P.E. BLUEVIA FIBRA SL',
    'fundDescription': 'Fonditel B',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1167-F0462-G0162',
    'name': 'Individual Fonditel',
    'fundDescription': 'Fonditel C',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N0695-F0635-G0162',
    'name': 'Empleados Cofresco Iberica',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1129-F0635-G0162',
    'name': 'PP PC OCU Y OCU EDICIONES',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N2020-F0635-G0162',
    'name': 'PPE HP PRINTING AND COMPUTING SOLUTIONS, SLU',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3731-F0635-G0162',
    'name': 'Fonditel Alfa Promoción Conjunta',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4295-F0635-G0162',
    'name': 'Promoción Conjunta Morgan Stanley',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4421-F0635-G0162',
    'name': 'Plan de Pensiones AB SCIEX SPAIN',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4902-F0635-G0162',
    'name': 'PLAN DE PENSIONES DE COMPUTERSHARE',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5051-F0635-G0162',
    'name': 'P.P. Empleados HEWLETT PACKARD SERVICIOS ESPAÑA, S.L.',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5096-F0635-G0162',
    'name': 'PPE OATH BRANDS (Iberia) SLU',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5128-F0635-G0162',
    'name': 'Plan de Pensiones de los empleados de COTY BEAUTY SPAIN',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5192-F0635-G0162',
    'name': 'P.P.E. DXC TECHNOLOGY SERVICIOS ESPAÑA, SL.',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5408-F0635-G0162',
    'name': 'P.P.E. WELLA PRESTIGE',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5115-F0635-G0162',
    'name': 'EMPLEADOS KOA HEALTH BV',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5460-F0635-G0162',
    'name': 'American Tower España y ATC Scala Spain Holding',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5495-F0635-G0162',
    'name': 'PPE SIMPLIFICADO CEAJE',
    'fundDescription': 'Fonditel Alfa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1742-F0639-G0162',
    'name': 'Individual Fonditel Red Básica.',
    'fundDescription': 'Fonditel Red Básica',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1743-F0640-G0162',
    'name': 'Individual Fonditel Red Activa',
    'fundDescription': 'Fonditel Red Activa',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1855-F1056-G0162',
    'name': 'Trabajadores Fraternidad- Muprespa',
    'fundDescription': 'Fonditel Delta',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N5189-F1056-G0162',
    'name': 'PPE EXTRABAJADORES FRATER PREVENCION',
    'fundDescription': 'Fonditel Delta',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1834-F1058-G0162',
    'name': 'P.P.INDUSTRIAS QUIMICAS DEL EBRO',
    'fundDescription': 'Fonditel Beta',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N3262-F1058-G0162',
    'name': 'Empleados de ALK-ABELLO',
    'fundDescription': 'Fonditel Beta',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4254-F1493-G0162',
    'name': 'Fonditel Monetario',
    'fundDescription': 'Fonditel Monetario FP',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1960-F2109-G0162',
    'name': 'P.P.PC CICLO KAPPA EMP.P&G',
    'fundDescription': 'FONDITEL CICLO KAPPA,FP',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1959-F2109-G0162',
    'name': 'P.P.CICLO KAPPA P&G MATARO',
    'fundDescription': 'FONDITEL CICLO KAPPA,FP',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1960-F2110-G0162',
    'name': 'P.P.PC CICLO LAMBDA EMP.P&G',
    'fundDescription': 'FONDITEL CICLO LAMBDA,FP',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N1959-F2110-G0162',
    'name': 'P.P.CICLO LAMBDA P&G MATARO',
    'fundDescription': 'FONDITEL CICLO LAMBDA,FP',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N4368-F2165-G0162',
    'name': 'PROM.CONJ.GRUPO MICROSOFT',
    'fundDescription': 'FONDITEL EON, FONDO DE PENSIONES',
    'managerId': 'G0162',
    'managerName': 'FONDITEL PENSIONES E.G.F.P.,S.A.'
  },
  {
    'id': 'N0795-F0084-G0172',
    'name': 'PP TARGOBANK Sostenible Mixto',
    'fundDescription': 'AGRUPACION MIXTO, F.P.',
    'managerId': 'G0172',
    'managerName': 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    'id': 'N4927-F0084-G0172',
    'name': 'PP TARGOBANK Sostenible Mixto Horizon',
    'fundDescription': 'AGRUPACION MIXTO, F.P.',
    'managerId': 'G0172',
    'managerName': 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    'id': 'N0836-F0374-G0172',
    'name': 'PP TARGOBANK Sostenible Renta Fija',
    'fundDescription': 'AGRUPACION RENTA FIJA, F.P.',
    'managerId': 'G0172',
    'managerName': 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    'id': 'N4929-F0374-G0172',
    'name': 'PP TARGOBANK Sostenible Renta Fija Horizon',
    'fundDescription': 'AGRUPACION RENTA FIJA, F.P.',
    'managerId': 'G0172',
    'managerName': 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    'id': 'N1510-F0540-G0172',
    'name': 'PP TARGOBANK Sostenible Renta Variable',
    'fundDescription': 'AGRUPACION RENTA VARIABLE, F.P.',
    'managerId': 'G0172',
    'managerName': 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    'id': 'N4928-F0540-G0172',
    'name': 'PP TARGOBANK Sostenible Renta Variable Horizon',
    'fundDescription': 'AGRUPACION RENTA VARIABLE, F.P.',
    'managerId': 'G0172',
    'managerName': 'TARGOPENSIONES ENTIDAD GESTORA DE FONDOS DE PENSIONES, S.A.U. E.G.F.P.'
  },
  {
    'id': 'N0055-F0077-G0177',
    'name': 'G.V.C. P.P.',
    'fundDescription': 'WINTERTHUR II F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N0136-F0077-G0177',
    'name': 'MPP MODERADO',
    'fundDescription': 'WINTERTHUR II F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N1151-F0077-G0177',
    'name': 'S.C.I.A.S. P.P',
    'fundDescription': 'WINTERTHUR II F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N4325-F0077-G0177',
    'name': 'PLAN DE PENSIONES ASOCIADO CLUB DIÁLOGO',
    'fundDescription': 'WINTERTHUR II F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N0154-F0091-G0177',
    'name': 'P.P. FONDECA',
    'fundDescription': 'EMPLEADOS PUBLICOS F.P. - REF.VAL. 2046797',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N0194-F0287-G0177',
    'name': 'MPP EQUILIBRADO',
    'fundDescription': 'AUROFONDO EQUILIBRADO',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N1980-F0721-G0177',
    'name': 'MPP RENTA FIJA',
    'fundDescription': 'WINTERTHUR V F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N1981-F0722-G0177',
    'name': 'MPP AUDAZ GLOBAL',
    'fundDescription': 'WINTERTHUR VI, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N2725-F0859-G0177',
    'name': 'MUNDIPLAN PLAN DE PENSIONES DE EMPLEO PYME',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N0259-F0859-G0177',
    'name': 'P.P. ARCELORMITTAL COMMERCIAL SPAIN',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N2388-F0859-G0177',
    'name': 'P.P.QUIMICA BASICA',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N2553-F0859-G0177',
    'name': 'P.P.CALDIC IBERICA',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N3124-F0859-G0177',
    'name': 'P.P. ATENCIO PRIMARIA ALT CAMP',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N3387-F0859-G0177',
    'name': 'P.P. TEMPRASA',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N2389-F0859-G0177',
    'name': 'P.P. IBERCASA',
    'fundDescription': 'WINTERTHUR PYME, F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N4965-F1309-G0177',
    'name': 'MPP FUTURO SEGURO III',
    'fundDescription': 'WINTERTHUR VIII F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N3708-F1312-G0177',
    'name': 'MPP AUDAZ EUROPA',
    'fundDescription': 'WINTERTHUR XI F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N4091-F1312-G0177',
    'name': 'PP RV AS. INSP. H. ESTA.',
    'fundDescription': 'WINTERTHUR XI F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N3859-F1368-G0177',
    'name': 'EMPLEADOS GRUPO AXA',
    'fundDescription': 'SEGURPENSION EMPLEADOS AXA FP',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N3900-F1393-G0177',
    'name': 'MPP MONETARIO',
    'fundDescription': 'WINTERTHUR XII F.P.',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N5265-F2096-G0177',
    'name': 'MPP RUMBO 2026',
    'fundDescription': 'AUROFONDO FUTURO II',
    'managerId': 'G0177',
    'managerName': 'AXA PENSIONES'
  },
  {
    'id': 'N1177-F0442-G0179',
    'name': 'PLAN DE PENSIONES BESTINVER PLAN MIXTO',
    'fundDescription': 'BESTINVER PLAN MIXTO, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N5278-F1219-G0179',
    'name': 'PLAN DE PENSIONES BESTINVER PLAN PATRIMONIO',
    'fundDescription': 'BESTINVER PLAN PATRIMONIO, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N3472-F1220-G0179',
    'name': 'PLAN DE PENSIONES BESTINVER GLOBAL',
    'fundDescription': 'BESTINVER GLOBAL, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N4138-F1451-G0179',
    'name': 'LOMBARD ODIER DARIER HENTSCH & CIE (ESPAÑA) S.V., S.A., P.P.',
    'fundDescription': 'BESTINVER EMPLEO II, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N4542-F1451-G0179',
    'name': 'P.P. PROM. CONJUNTA BESTINVER EMPRESAS',
    'fundDescription': 'BESTINVER EMPLEO II, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N4124-F1451-G0179',
    'name': 'Empleados Ove Arup&Partners',
    'fundDescription': 'BESTINVER EMPLEO II, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N4541-F1451-G0179',
    'name': 'Plan de Pensiones Empleados Seamos Marketing, SL',
    'fundDescription': 'BESTINVER EMPLEO II, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N1591-F1451-G0179',
    'name': 'P.P.E. FITCH RATINGS IRELAND SPANISH BRANCH SUC. ESPAÑA',
    'fundDescription': 'BESTINVER EMPLEO II, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N1607-F1599-G0179',
    'name': 'EMPLEADOS RUSSEL REYNOLDS ASSOC',
    'fundDescription': 'BESTINVER EMPLEO III, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N3471-F2107-G0179',
    'name': 'PLAN DE PENSIONES BESTINVER PLAN RENTA',
    'fundDescription': 'BESTINVER PLAN RENTA, FONDO DE PENSIONES',
    'managerId': 'G0179',
    'managerName': 'BESTINVER PENSIONES E.G.F.P.'
  },
  {
    'id': 'N0377-F0253-G0180',
    'name': 'EMPLEADOS CAJA DE AHORROS DE ASTURIAS',
    'fundDescription': 'PCAJATUR EMPLEADOS CAJA AHORROS ASTURIAS',
    'managerId': 'G0180',
    'managerName': 'LIBERBANK PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N3762-F0545-G0180',
    'name': 'GRUPO EL COMERCIO',
    'fundDescription': 'CAJASTUR EMPRESAS I',
    'managerId': 'G0180',
    'managerName': 'LIBERBANK PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N4132-F0545-G0180',
    'name': 'CAJA DE ASTURIAS PYMES I PP',
    'fundDescription': 'CAJASTUR EMPRESAS I',
    'managerId': 'G0180',
    'managerName': 'LIBERBANK PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N4160-F0545-G0180',
    'name': 'PMG ASTURIAS POWDER me<x>taL, S.A.U. PP',
    'fundDescription': 'CAJASTUR EMPRESAS I',
    'managerId': 'G0180',
    'managerName': 'LIBERBANK PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N1111-F0470-G0185',
    'name': 'RENTA 4 EUROPA ACCIONES',
    'fundDescription': 'RENTPENSION F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3548-F0470-G0185',
    'name': 'BANCA PUEYO BOLSA',
    'fundDescription': 'RENTPENSION F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N1849-F0676-G0185',
    'name': 'RENTA 4 RENTA FIJA',
    'fundDescription': 'RENTPENSION II, F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3129-F0676-G0185',
    'name': 'BANCA PUEYO I',
    'fundDescription': 'RENTPENSION II, F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N2247-F0749-G0185',
    'name': 'NARANJA STANDARD & POORS 500, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 1',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N1238-F0901-G0185',
    'name': 'PLAN DE PENSIONES BANCA PUEYO',
    'fundDescription': 'FONDPUEYO PENSIONES F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N2898-F0932-G0185',
    'name': 'NARANJA IBEX 35, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 3, FONDO DE PENSIONES',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N2840-F0943-G0185',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE BANCA PUEYO',
    'fundDescription': 'RENTPENSION III F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3981-F0943-G0185',
    'name': 'PLAN DE PENSIONES EMPLEADOS RENTA 4 SV',
    'fundDescription': 'RENTPENSION III F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3686-F1292-G0185',
    'name': 'NARANJA EURO STOXX 50, P.P.',
    'fundDescription': 'ING DIRECT 4 FONDO DE PENSIONES',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3899-F1395-G0185',
    'name': 'NARANJA RENTA FIJA CORTO PLAZO, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 5 FONDO PENSIONES',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5205-F1396-G0185',
    'name': 'NARANJA 2025 PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 6, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3896-F1398-G0185',
    'name': 'NARANJA 2020, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 8, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3901-F1399-G0185',
    'name': 'NARANJA 2030, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 9, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N3897-F1400-G0185',
    'name': 'NARANJA 2040, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 10, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4033-F1425-G0185',
    'name': 'PLAN DE PENSIONES RENTA 4 EMPRESAS 1',
    'fundDescription': 'RENTPENSION IV F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4034-F1430-G0185',
    'name': 'PLAN DE PENSIONES RENTA 4 EMPRESAS 2',
    'fundDescription': 'RENTPENSION V F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5441-F1430-G0185',
    'name': 'PLAN DE PENSIONES EMPLEADOS DEASTERRA PARTNERS',
    'fundDescription': 'RENTPENSION V F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4203-F1466-G0185',
    'name': 'PLAN DE PENSIONES RENTA 4 DEUDA PUBLICA',
    'fundDescription': 'RENTPENSION VI F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4215-F1466-G0185',
    'name': 'PLAN DE PENSIONES BANCA PUEYO DEUDA PUBLICA',
    'fundDescription': 'RENTPENSION VI F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4194-F1467-G0185',
    'name': 'PLAN DE PENSIONES RENTA 4 DEDALO',
    'fundDescription': 'RENTPENSION VII F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4363-F1467-G0185',
    'name': 'PLAN DE PENSIONES DE LA CONFEDERACION DE VENDEDORES DE PRENSA DE ESPAÑA (COVEPRES)',
    'fundDescription': 'RENTPENSION VII F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4881-F1467-G0185',
    'name': 'PLAN DE PENSIONES APYME DON BENITO',
    'fundDescription': 'RENTPENSION VII F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5480-F1497-G0185',
    'name': 'NARANJA RENTABILIDAD OBJETIVO 2023, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 12, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4390-F1498-G0185',
    'name': 'PLAN DE PENSIONES RENTA 4 GLOBAL ACCIONES',
    'fundDescription': 'RENTPENSION IX F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4882-F1605-G0185',
    'name': 'PLAN DE PENSIONES RENTA 4 NEXUS',
    'fundDescription': 'RENTPENSION X F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5036-F1606-G0185',
    'name': 'R4 Activa Planes de Pensiones Gestión Conservadora',
    'fundDescription': 'RENTPENSION XII F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N4691-F1609-G0185',
    'name': 'NARANJA 2050, PLAN DE PENSIONES',
    'fundDescription': 'ING DIRECT 11, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5034-F2013-G0185',
    'name': 'R4 Activa Planes de Pensiones Gestión Tolerante',
    'fundDescription': 'RENTPENSION XIV F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5035-F2015-G0185',
    'name': 'R4 Activa Planes de Pensiones Gestión Moderada',
    'fundDescription': 'RENTPENSION XIII F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5078-F2031-G0185',
    'name': 'R4 Activa Planes de Pensiones Gestión Dinámica',
    'fundDescription': 'RENTPENSION XV F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5052-F2032-G0185',
    'name': 'GLOBAL VALUE PLAN DE PENSIONES',
    'fundDescription': 'RENTPENSION XVI F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5079-F2033-G0185',
    'name': 'PLAN DE PENSIONES  ALTAIR  CRECIMIENTO PENSIONES  II',
    'fundDescription': 'RENTPENSION XVII FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5430-F2098-G0185',
    'name': 'NUMANTIA PENSIONES, Plan de Pensiones',
    'fundDescription': 'RENTPENSION XVIII F.P.',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5465-F2099-G0185',
    'name': 'OLEA NEUTRAL, Plan de Pensiones',
    'fundDescription': 'RENTPENSION XX FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5080-F2123-G0185',
    'name': 'me<x>taVALOR PENSIONES, P.P.',
    'fundDescription': 'RENTPENSION XIX FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N5504-F2230-G0185',
    'name': 'NARANJA RENTABILIDAD OBJETIVO 2025',
    'fundDescription': 'ING DIRECT 13, FP',
    'managerId': 'G0185',
    'managerName': 'RENTA 4 PENSIONES, E.G.F. P., S.A.'
  },
  {
    'id': 'N1176-F0099-G0187',
    'name': 'HALIFAX PLAN DE PENSIONES',
    'fundDescription': 'FONDO DE PENSIONES ZURICH SUIZA, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0257-F0152-G0187',
    'name': 'CHUBB, PLAN DE PENSIONES',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0276-F0152-G0187',
    'name': 'AVEBE, PLAN DE PENSIONES',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1082-F0152-G0187',
    'name': 'VALOR PLAN DE PENSIONES DE EMPLEO DE PROMOCIÓN CONJUNTA',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1252-F0152-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE LA COMISIÓN NACIONAL DE ENERGÍA',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1351-F0152-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE MEDA PHARMA, S.L.',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1352-F0152-G0187',
    'name': 'EUROMED, PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1867-F0152-G0187',
    'name': 'EVONIK SILQUÍMICA, S.A., PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1939-F0152-G0187',
    'name': 'ATOTECH ESPAÑA, PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N2302-F0152-G0187',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO PARA LOS EMPLEADOS DE CARBURO DEL CINCA, S.A.',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3062-F0152-G0187',
    'name': 'PLAN DE PENSIONES DE EMPLEO DZ PYME 1. Subplan 1',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3753-F0152-G0187',
    'name': 'PLAN DE PENSIONES PARA LOS EMPLEADOS DE SMITH & NEPHEW, S.A.',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3954-F0152-G0187',
    'name': 'EVONIK , PLAN DE PENSIONES DE EMPLEO DE PROMOCION CONJUNTA',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4493-F0152-G0187',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE ACTELION PHARMACEUTICALS ESPAÑA',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4116-F0152-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE DEUTSCHE ZURICH PENSIONES SUPLAN 2 ( S0012 )',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4957-F0152-G0187',
    'name': 'PLAN DE PENSIONES PARA LOS EMPLEADOS DE BSN MEDICAL SL',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5111-F0152-G0187',
    'name': 'EUROMED BOTANICALS, PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5391-F0152-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE MYLAN  PHARMACEUTICALS',
    'fundDescription': 'DEUTSCHE BANK PENSIONES, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1225-F0467-G0187',
    'name': 'ARISTOTELES PENSIONES UNO, PLAN DE PENSIONES ASOCIADO',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1342-F0467-G0187',
    'name': 'RONA CONSULTORES, S.L., PLAN DE PENSIONES',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1344-F0467-G0187',
    'name': 'GESTORES TÉCNICOS ASEGURADORES, PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1732-F0467-G0187',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO PARA LOS TRABAJADORES DE ATRADIUS CREDIT INSURANCE, N.V. SUCURSAL EN ESPAÑA',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1772-F0467-G0187',
    'name': 'PLAN DE PENSIONES NIKE RETAIL',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1907-F0467-G0187',
    'name': 'PLAN DE PENSIONES DE THE AMERICAN SCHOOL OF BARCELONA',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N2417-F0467-G0187',
    'name': 'PLAN DE PENSIONES DE SICK OPTIC ELECTRONIC, S.A.',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3461-F0467-G0187',
    'name': 'HURLEY 999, PLAN DE PENSIONES DE EMPLEO',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4083-F0467-G0187',
    'name': 'COLT TELECOM BARCELONA, PLAN DE PENSIONES',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4116-F0467-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE DEUTSCHE ZURICH PENSIONES SUPLAN 1 ( S0011 )',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3062-F0467-G0187',
    'name': 'PLAN DE PENSIONES DE EMPLEO DZ PYME 1.subplan 2',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1352-F0467-G0187',
    'name': 'EUROMED PLAN DE PENSIONES DE EMPLEO -SUBPLAN DINAMICO (S0038)',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5111-F0467-G0187',
    'name': 'EUROMED BOTANICALS , PLAN DE PENSIONES DE EMPLEO -SUBPLAN DINAMICO (S0039)',
    'fundDescription': 'DB PREVISION 2, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1412-F0528-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE DEUTSCHE BANK, S.A.E.',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DEL GRUPO DEUTSCHE BANK, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1413-F0528-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE GFT IT CONSULTING, S.L., SOCIEDAD UNIPERSONAL',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DEL GRUPO DEUTSCHE BANK, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N2657-F0528-G0187',
    'name': 'PLAN DE LOS EMPLEADOS DE DEUTSCHE  ASSET MANAGEMENT',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DEL GRUPO DEUTSCHE BANK, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3991-F0528-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE DEUTSCHE BANK ÄKTIENGESELLSCHAFT, SUCURSAL EN ESPAÑA',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DEL GRUPO DEUTSCHE BANK, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5307-F0528-G0187',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE DWS GRUNDBESITZ GMBH, SUCURSAL EN ESPAÑA',
    'fundDescription': 'FONDO DE PENSIONES DE LOS EMPLEADOS DEL GRUPO DEUTSCHE BANK, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1297-F0529-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DEUTSCHE BANK RV EUROPA',
    'fundDescription': 'DB PREVISION 3, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1471-F0529-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DZP RV EUROPA',
    'fundDescription': 'DB PREVISION 3, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1737-F0544-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DEUTSCHE BANK RV GLOBAL',
    'fundDescription': 'DZ RENTA VARIABLE GLOBAL, F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0135-F0544-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL ZURICH STAR',
    'fundDescription': 'DZ RENTA VARIABLE GLOBAL, F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1999-F0698-G0187',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE CORREOS Y TELÉGRAFOS',
    'fundDescription': 'DB PREVISION 9, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N2419-F0718-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DEUTSCHE BANK  MODERADO',
    'fundDescription': 'DZ MODERADO F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5016-F0718-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DZP MODERADO',
    'fundDescription': 'DZ MODERADO F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0087-F0718-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL GRUPO ZURICH',
    'fundDescription': 'DZ MODERADO F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1312-F0718-G0187',
    'name': 'PLAN DE PENSIONES DE AGENTES COMERCIALES',
    'fundDescription': 'DZ MODERADO F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N2418-F0719-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DEUTSCHE BANK MONEY MARKET',
    'fundDescription': 'DZ MONETARIO F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0060-F0719-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL ZURICH SUIZA',
    'fundDescription': 'DZ MONETARIO F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N2867-F0905-G0187',
    'name': 'PPI MIXTO RESPONSABLE DB',
    'fundDescription': 'DB PREVISION 15, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4080-F0905-G0187',
    'name': 'PPI BANCORREOS',
    'fundDescription': 'DB PREVISION 15, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3153-F0906-G0187',
    'name': 'PPI RENTA FIJA PREMIUM 22',
    'fundDescription': 'DB PREVISION 16, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1102-F0985-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DEUTSCHE BANK DINAMICO',
    'fundDescription': 'DB PREVISION 18, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1470-F0985-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DZP  DINAMICO',
    'fundDescription': 'DB PREVISION 18, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4827-F0985-G0187',
    'name': 'Plan de Pensiones Individual Zurich Dinámico',
    'fundDescription': 'DB PREVISION 18, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0185-F0997-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DEUTSCHE BANK CONSERVADOR',
    'fundDescription': 'DB PREVISION 17, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N0683-F0997-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DB EMPLEADO',
    'fundDescription': 'DB PREVISION 17, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1085-F0997-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DZP CONSERVADOR',
    'fundDescription': 'DB PREVISION 17, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1679-F0997-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL CORREOS DEUTSCHE BANK I',
    'fundDescription': 'DB PREVISION 17, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4828-F0997-G0187',
    'name': 'Plan de Pensiones Individual Zurich Moderado',
    'fundDescription': 'DB PREVISION 17, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N3932-F1410-G0187',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE PROMOCIÓN CONJUNTA ZURICH',
    'fundDescription': 'FONDO DE PENSIONES DE EMPLEO ZURICH',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4444-F1525-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL CRECIMIENTO CONSERVADOR DB',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 22 FP',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N4992-F1951-G0187',
    'name': 'PLAN DE PENSIONES INFIVIDUAL PROTECCION FLEXIBLE 85 DB',
    'fundDescription': 'DEUTSCHE BANK PREVISION 25',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5133-F2034-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DESTINO FUTURO 2037 DB',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 26, F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5134-F2034-G0187',
    'name': 'PLAN DE PENSIONES ZURICH DESTINO 2037',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 26, F.P',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5130-F2059-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL DESTINO FUTURO 2030 DB',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 27, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5131-F2059-G0187',
    'name': 'PLAN DE PENSIONES INDIVIDUAL ZURICH DESTINO 2030',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 27, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5132-F2060-G0187',
    'name': 'PLAN DE PENSIONES INDVIDUAL DESTINO FUTURO 2023 DB',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 28, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N5135-F2060-G0187',
    'name': 'PLAN DE PENSIONES ZURICH DESTINO 2023',
    'fundDescription': 'DEUTSCHE ZURICH PREVISION 28, F.P.',
    'managerId': 'G0187',
    'managerName': 'DEUTSCHE ZURICH PENSIONES, ENTIDAD GESTORA DE FONDO DE PENSIONES, S.A.'
  },
  {
    'id': 'N1308-F0500-G0190',
    'name': 'NATIONALE-NEDERLANDEN EUROPA',
    'fundDescription': 'NATIONALE-NEDERLANDEN EUROPA',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N2248-F0750-G0190',
    'name': 'PLAN NARANJA RENTA FIJA EUROPEA',
    'fundDescription': 'ING DIRECT 2',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N2309-F0751-G0190',
    'name': 'NATIONALE-NEDERLANDEN CRECIMIENTO GLOBAL',
    'fundDescription': 'NATIONALE-NEDERLANDEN RENTA VARIABLE',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N1936-F0752-G0190',
    'name': 'PPG IBERICA SALES & SERVICES, S.L.',
    'fundDescription': 'NATIONALE-NEDERLANDEN EMPLEADOS',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N4258-F0752-G0190',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE PROMOCION CONJUNTA DEL GRUPO AEGON',
    'fundDescription': 'NATIONALE-NEDERLANDEN EMPLEADOS',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N4625-F0752-G0190',
    'name': 'PLAN DE PENSIONES DEL SISTEMA DE EMPLEO GOLDMAN SACHS ASSET MANAGEMENT, SUCURSAL EN ESPAÑA',
    'fundDescription': 'NATIONALE-NEDERLANDEN EMPLEADOS',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N4966-F0752-G0190',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE ING BANK N.V.',
    'fundDescription': 'NATIONALE-NEDERLANDEN EMPLEADOS',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N5262-F0752-G0190',
    'name': 'PPC NATIONALE-NEDERLANDEN ESPAÑA',
    'fundDescription': 'NATIONALE-NEDERLANDEN EMPLEADOS',
    'managerId': 'G0190',
    'managerName': 'NATIONALE-NEDERLANDEN VIDA,CIA.SEG.Y REA'
  },
  {
    'id': 'N0882-F1012-G0195',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DE LA CAMARA OFICIAL DE COMERCIO E INDUSTRIA DE ZARAGOZA',
    'fundDescription': 'CAI PENSIONES EMPRESAS',
    'managerId': 'G0195',
    'managerName': 'CAI VIDA Y PENSIONES SEGUROS Y REASEGUROS S.A.'
  },
  {
    'id': 'N0071-F0022-G0197',
    'name': 'MARCH PENSIONES 80/20, PLAN DE PENSIONES',
    'fundDescription': 'MARCH PENSIONES 80/20, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N1731-F0636-G0197',
    'name': 'MARCH PENSIONES 50/50, PLAN DE PENSIONES',
    'fundDescription': 'MARCH PENSIONES 50/50, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N2342-F0755-G0197',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS BANCA MARCH',
    'fundDescription': 'MARCH AHORRO, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N5468-F0755-G0197',
    'name': 'PLAN DE PENSIONES  DE PROMOCIÓN CONJUNTA DE LOS EMPLEADOS DEL GRUPO DE EMPRESAS BANCA MARCH',
    'fundDescription': 'MARCH AHORRO, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N2642-F0844-G0197',
    'name': 'MARCH ACCIONES PLAN DE PENSIONES',
    'fundDescription': 'MARCH ACCIONES, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N3173-F1046-G0197',
    'name': 'PLAN PENSION CRECIENTE',
    'fundDescription': 'PLAN PENSION CRECIENTE, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N1339-F1319-G0197',
    'name': 'PLAN DE LOS EMPLEADOS DE WESTINGHOUSE, PLAN DE PENSIONES',
    'fundDescription': 'PLAN OPTIMO, FONDO DE PENSIONES',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N4150-F1469-G0197',
    'name': 'MARCH BONOS P.P.',
    'fundDescription': 'MARCH BONOS F.P.',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N4292-F1512-G0197',
    'name': 'MARCH BABYBOOMER',
    'fundDescription': 'MARCH BABYBOOMER',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N4653-F1721-G0197',
    'name': 'MARCH GENERACION X',
    'fundDescription': 'MARCH GENERACION X',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N4752-F1856-G0197',
    'name': 'MARCH NEXT GENERATION, PP',
    'fundDescription': 'MARCH NEXT GENERATION, FP',
    'managerId': 'G0197',
    'managerName': 'MARCH GESTION DE PENSIONES, S.G.F.P., S.A.'
  },
  {
    'id': 'N0231-F0159-G0202',
    'name': 'TREA CONSERVADOR, P.P.',
    'fundDescription': 'TREA RFM, FONDO DE PENSIONES',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N1051-F0430-G0202',
    'name': 'TREA HORIZONTE, P.P.',
    'fundDescription': 'TREA RF LP, FONDO DE PENSIONES',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N1224-F0430-G0202',
    'name': 'ARGI 2 PENSIONES, P.P.',
    'fundDescription': 'TREA RF LP, FONDO DE PENSIONES',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N1634-F0592-G0202',
    'name': 'TREA IMPULSO, P.P.',
    'fundDescription': 'TREA RVM, FONDO DE PENSIONES',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N2541-F0813-G0202',
    'name': 'TREA CRECIMIENTO, P.P.',
    'fundDescription': 'TREA RV, FONDO DE PENSIONES',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N2670-F0861-G0202',
    'name': 'EMPLEADOS NOVO BANCO, P.P.',
    'fundDescription': 'EMPLEADOS NOVO BANCO, F.P.',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N2823-F0902-G0202',
    'name': 'PP PROMOCIÓN 1, PP',
    'fundDescription': 'TREA EMPLEO, FP',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N3942-F0902-G0202',
    'name': 'PP EMPLEADOS HAITONG BANK SA SE, PP',
    'fundDescription': 'TREA EMPLEO, FP',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N4107-F0902-G0202',
    'name': 'PP EMPLEADOS NOVO ACTIVOS FINANCIEROS ESPAÑA, PP',
    'fundDescription': 'TREA EMPLEO, FP',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N4634-F0902-G0202',
    'name': 'PP EMPLEADOS NOVO VANGUARDA, PP',
    'fundDescription': 'TREA EMPLEO, FP',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N2872-F0926-G0202',
    'name': 'TREA TRANQUILIDAD, P.P.',
    'fundDescription': 'TREA RF CP, FONDO DE PENSIONES.',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N4636-F1099-G0202',
    'name': 'VALUE TREE DEFENSIVO PP',
    'fundDescription': 'TREA AHORRO 1, FONDO DE PENSIONES',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N5217-F1862-G0202',
    'name': 'VALUE TREE DINAMICO PP',
    'fundDescription': 'TREA AHORRO 3, FP',
    'managerId': 'G0202',
    'managerName': 'TREA PENSIONES, E.G.F.P., S. A.'
  },
  {
    'id': 'N0220-F0734-G0207',
    'name': 'LIBERTY EQULIBRADO 3',
    'fundDescription': 'LIBERTY HIGH 30',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N1842-F0734-G0207',
    'name': 'LIBERTY EQUILIBRADO',
    'fundDescription': 'LIBERTY HIGH 30',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N2037-F0734-G0207',
    'name': 'LIBERTY EQUILIBRADO LIG',
    'fundDescription': 'LIBERTY HIGH 30',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N1843-F0735-G0207',
    'name': 'LIBERTY DINAMICO',
    'fundDescription': 'LIBERTY HIGH 70',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N2038-F0735-G0207',
    'name': 'LIBERTY DINAMICO LIG',
    'fundDescription': 'LIBERTY HIGH 70',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N1306-F0736-G0207',
    'name': 'P.P. GENESIS',
    'fundDescription': 'LIBERTY PLUS 1',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N1844-F0736-G0207',
    'name': 'LIBERTY CONSERVADOR',
    'fundDescription': 'LIBERTY PLUS 1',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N2039-F0736-G0207',
    'name': 'LIBERTY CONSERVADOR LIG',
    'fundDescription': 'LIBERTY PLUS 1',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N2640-F0851-G0207',
    'name': 'FINECO 5',
    'fundDescription': 'FINECO FUTURO',
    'managerId': 'G0207',
    'managerName': 'FINECO PREVISION'
  },
  {
    'id': 'N3242-F1047-G0211',
    'name': 'PLAN DE PENSIONES SVRNE JUBILACION',
    'fundDescription': 'SVRNE JUBILACION, FONDO DE PENSIONES',
    'managerId': 'G0211',
    'managerName': 'SVRNE, MUTUA DE SEGUROS Y REASEGUROS A PRIMA FIJA'
  },
  {
    'id': 'N5021-F2017-G0211',
    'name': 'SVRNE JUBILACION II,PLAN DE PENSIONES',
    'fundDescription': 'SURNEJUBILACION II FONDO DE PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0211',
    'managerName': 'SVRNE, MUTUA DE SEGUROS Y REASEGUROS A PRIMA FIJA'
  },
  {
    'id': 'N3417-F1198-G0214',
    'name': 'CAJAMAR DINERO, PLAN DE PENSIONES',
    'fundDescription': 'FONDOCAJAMAR I FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4339-F1198-G0214',
    'name': 'GCC DINERO',
    'fundDescription': 'FONDOCAJAMAR I FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N0290-F1199-G0214',
    'name': 'PLAN DEL CAMPO',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N0423-F1199-G0214',
    'name': 'CAJAMAR RENTA FIJA 2, P.P.',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N1025-F1199-G0214',
    'name': 'UNIVERSITAS',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3418-F1199-G0214',
    'name': 'CAJAMAR RENTA FIJA, PLAN DE PENSIONES',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3639-F1199-G0214',
    'name': 'CAJAMAR E RENTA FIJA, PLAN DE PENSIONES',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4340-F1199-G0214',
    'name': 'GCC RENTA FIJA',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4666-F1199-G0214',
    'name': 'PLAN DE PENSIONES AGROPLAN UPA CyL',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4667-F1199-G0214',
    'name': 'PLAN DE PENSIONES AGROPLAN ASAJA CyL',
    'fundDescription': 'FONDOCAJAMAR II FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3419-F1200-G0214',
    'name': 'CAJAMAR MIXTO I, PLAN DE PENSIONES',
    'fundDescription': 'FONDOCAJAMAR III FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3641-F1200-G0214',
    'name': 'CAJAMAR E RENTA MIXTO I',
    'fundDescription': 'FONDOCAJAMAR III FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4341-F1200-G0214',
    'name': 'GCC MIXTO I',
    'fundDescription': 'FONDOCAJAMAR III FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3420-F1201-G0214',
    'name': 'CAJAMAR MIXTO II, PLAN DE PENSIONES',
    'fundDescription': 'FONDOCAJAMAR IV FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4342-F1201-G0214',
    'name': 'GCC MIXTO II',
    'fundDescription': 'FONDOCAJAMAR IV FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3421-F1202-G0214',
    'name': 'CAJAMAR RENTA VARIABLE, PLAN DE PENSIONES',
    'fundDescription': 'FONDOCAJAMAR V FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N3640-F1202-G0214',
    'name': 'CAJAMAR E RENTA VARIABLE',
    'fundDescription': 'FONDOCAJAMAR V FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N4343-F1202-G0214',
    'name': 'GCC RENTA VARIABLE',
    'fundDescription': 'FONDOCAJAMAR V FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N5272-F2104-G0214',
    'name': 'CAJAMAR GESTION FUTURO 2030, P. PENSIONES',
    'fundDescription': 'FONDOCAJAMAR IX FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N5273-F2105-G0214',
    'name': 'CAJAMAR GESTION FUTURO 2040, P. PENSIONES',
    'fundDescription': 'FONDOCAJAMAR X FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N5274-F2106-G0214',
    'name': 'CAJAMAR GESTION FUTURO 2050, P. PENSIONES',
    'fundDescription': 'FONDOCAJAMAR XI FONDO DE PENSIONES',
    'managerId': 'G0214',
    'managerName': 'CAJAMAR VIDA S.A. de Seguros y Reaseguros'
  },
  {
    'id': 'N0116-F0057-G0217',
    'name': 'PLAN DE PENSIONES CAJA LABORAL CLASICO',
    'fundDescription': 'CAJA LABORAL FONDO DE PENSIONES',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N1790-F0653-G0217',
    'name': 'PLAN DE PENSIONES CAJA LABORAL MIXTO',
    'fundDescription': 'CAJA LABORAL MIXTO FONDO DE PENSIONES',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N1791-F0654-G0217',
    'name': 'PLAN DE PENSIONES CAJA LABORAL VARIABLE',
    'fundDescription': 'CAJA LABORAL VARIABLE FONDO DE PENSIONES',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N4296-F1513-G0217',
    'name': 'CAJA LABORAL MONETARIO',
    'fundDescription': 'CAJA LABORAL MONETARIO F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N5153-F1799-G0217',
    'name': 'LABORAL KUTXA RENDIMIENTO FIJO IX',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO I F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N4801-F1859-G0217',
    'name': 'CAJA LABORAL RENDIMIENTO FIJO III',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO III F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N5236-F1860-G0217',
    'name': 'P.PENS. LABORAL KUTXA 2045',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO IV F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N4861-F1861-G0217',
    'name': 'CAJA LABORAL RENDIMIENTO FIJO V',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO V F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N4948-F1965-G0217',
    'name': 'CAJA LABORAL RENDIMIENTO FIJO VI',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO VI F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N4977-F1966-G0217',
    'name': 'CAJA LABORAL RENDIMIENTO FIJO VII',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO VII F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N4981-F1967-G0217',
    'name': 'CAJA LABORAL RENDIMIENTO FIJO VIII',
    'fundDescription': 'CAJA LABORAL RENDIMIENTO FIJO VIII F.P.',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N5064-F2028-G0217',
    'name': 'CAJA LABORAL 2030 PP',
    'fundDescription': 'CAJA LABORAL 2030 FP',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N5065-F2029-G0217',
    'name': 'CAJA LABORAL 2040 PP',
    'fundDescription': 'CAJA LABORAL 2040 FP',
    'managerId': 'G0217',
    'managerName': 'CAJA LABORAL PENSIONES'
  },
  {
    'id': 'N0753-F0004-G0219',
    'name': 'EMPLEADOS GESINCA CONSULTORA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1462-F0004-G0219',
    'name': 'EMPLEO AYTO. SAN MARTIN DE LA VEGA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1555-F0004-G0219',
    'name': 'EMPL.C. AHORRO MONT. PIED. ONTIYENT',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1865-F0004-G0219',
    'name': 'CASERPYME AHORRO',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2522-F0004-G0219',
    'name': 'GERENCIA DE URBANISMO DE SEVILLA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2667-F0004-G0219',
    'name': 'FUNDAC CENTRO TRANSF GALICIA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2790-F0004-G0219',
    'name': 'DIPUTACION DE GRANADA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2791-F0004-G0219',
    'name': 'AGROSEGUROS',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2884-F0004-G0219',
    'name': 'ATLANTIS',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3332-F0004-G0219',
    'name': 'CAJASOL INSTITUCCIONES UNO, P.P.',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3440-F0004-G0219',
    'name': 'ROCKWOOL PENINSULAR,S.A',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3441-F0004-G0219',
    'name': 'COOPERATIVAS DE TRABAJO ASOCIADO Y COLECTIVOS AFINES (COTESP)',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3798-F0004-G0219',
    'name': 'FLINT GROUP IBERIA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4445-F0004-G0219',
    'name': 'AYUNTAMIENTO DE AVILA, PP',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4513-F0004-G0219',
    'name': 'P.P.EMPLEO DE AUVASA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0375-F0004-G0219',
    'name': 'EMPLEADOS CAJA DE CANARIAS',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3275-F0004-G0219',
    'name': 'PLAN DE PENSIONES TELECABLE',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4010-F0004-G0219',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE LA EXCMA.DIPUTACION PROVINCIAL DE BADAJOZ',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4697-F0004-G0219',
    'name': 'FUND.REAL ESCUELA ANDALUZA ARTE ECUESTRE',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4756-F0004-G0219',
    'name': 'PLAN DE PENSIONES DEL AYUNTAMIENTO DE SAN ROQUE',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0858-F0004-G0219',
    'name': 'DECCO IBERICA, PP',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4213-F0004-G0219',
    'name': 'PLAN DE PENSIONES DE PROMOCION CONJUNTA DE LOS TRABAJADORES DE OFICINAS Y DESPACHOS DE SEGOVIA',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3577-F0004-G0219',
    'name': 'PLAN DE PENSIONES AYUNTAMIENTO DE BURGOS',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0874-F0004-G0219',
    'name': 'AYUNTAMIENTO DE MARTOS',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5120-F0004-G0219',
    'name': 'P.P. DE PROMOCION CONJUNTA CASER EMPRENDEDORES',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0349-F0004-G0219',
    'name': 'PLAN DE PENSIONES DE EMPLEADOS DE LA CAJA MUNICIPAL DE BURGOS',
    'fundDescription': 'AHORROPENSION DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0019-F0005-G0219',
    'name': 'AHORRO 6000',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0032-F0005-G0219',
    'name': 'LA PREVISORA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0033-F0005-G0219',
    'name': 'FONDO-AHORRO COLONIA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1668-F0005-G0219',
    'name': 'CASER PREMIER',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1784-F0005-G0219',
    'name': 'ACA-UPA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1835-F0005-G0219',
    'name': 'AC AHORROPENSION PATRIMONIO',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1847-F0005-G0219',
    'name': 'ASOCIADO ASAJA-CAJA SEGOVIA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2888-F0005-G0219',
    'name': 'ASOC. EMPL. DURO FELGUERA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4926-F0005-G0219',
    'name': 'EVO RENTA FIJA MIXTA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4943-F0005-G0219',
    'name': 'CASER TRANQUILIDAD PLUS',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4944-F0005-G0219',
    'name': 'CASER TRANQUILIDAD',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0018-F0005-G0219',
    'name': 'MIRALTA PATRIMONIO',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3912-F0005-G0219',
    'name': 'PROT.DISCAPACIDA PP',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4357-F0005-G0219',
    'name': 'PLAN VIDA RF MIX PP',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4821-F0005-G0219',
    'name': 'BURGOS AFIANZA',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4897-F0005-G0219',
    'name': 'CASER MASXNADA RFM',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0140-F0005-G0219',
    'name': 'CASER R.F. MIXTA 30',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3427-F0005-G0219',
    'name': 'CD 2014, P.P.',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3428-F0005-G0219',
    'name': 'CASER BIENESTAR 30, PP',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3317-F0005-G0219',
    'name': 'CASER ENERO 2015, P.P.',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3648-F0005-G0219',
    'name': 'CASER ENERO 2018',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5373-F0005-G0219',
    'name': 'CASER COMPROMISO, P.P.',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0133-F0005-G0219',
    'name': 'HELVETIA, P.P',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5451-F0005-G0219',
    'name': 'CASER MODERADO 30, PP',
    'fundDescription': 'AHORROPENSION UNO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0117-F0050-G0219',
    'name': 'PLAN CR MIXTO FIJO',
    'fundDescription': 'AHORRO CR III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1194-F0058-G0219',
    'name': 'PLAN CR MIXTO RV',
    'fundDescription': 'AHORRO CR IV',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0119-F0060-G0219',
    'name': 'EXTREMADURA 2000',
    'fundDescription': 'CAJA BADAJOZ PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0021-F0080-G0219',
    'name': 'MUTUAL',
    'fundDescription': 'AHORROVIDA I',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0410-F0081-G0219',
    'name': 'EMVISESA',
    'fundDescription': 'CAJASOL INSTITUCIONES UNO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2747-F0081-G0219',
    'name': 'CAJASOL INSTITUCCIONES, P.P.',
    'fundDescription': 'CAJASOL INSTITUCIONES UNO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3330-F0081-G0219',
    'name': 'ACOSOL, S.A.',
    'fundDescription': 'CAJASOL INSTITUCIONES UNO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4326-F0081-G0219',
    'name': 'ORGANISMOS AUTONOMOS Y EMPRESAS DE LA DIPUTACION DE CÁDIZ, P.P.',
    'fundDescription': 'CAJASOL INSTITUCIONES UNO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4490-F0081-G0219',
    'name': 'EMP.CONS.ZONA FRANCA CADIZ,PP',
    'fundDescription': 'CAJASOL INSTITUCIONES UNO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4373-F0081-G0219',
    'name': 'CAJASOL PYMES, P.P.',
    'fundDescription': 'CAJASOL INSTITUCIONES UNO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2449-F0086-G0219',
    'name': 'PLAN CR CORTO PLAZO',
    'fundDescription': 'AHORRO CR II',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0347-F0243-G0219',
    'name': 'EMPLEADOS DE C.E.C.A.',
    'fundDescription': 'CECA (EMPLEADOS)',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0842-F0269-G0219',
    'name': 'EMP.GRUPO AHORROCORPORACION,P.P.',
    'fundDescription': 'GRUPO AHORRO CORPORACION (EMPLEA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1785-F0269-G0219',
    'name': 'P.P. EX-EMPLEADOS DE GESINCA PENSIONES',
    'fundDescription': 'GRUPO AHORRO CORPORACION (EMPLEA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2326-F0269-G0219',
    'name': 'EMPLEO AHORRO Y TITULACION',
    'fundDescription': 'GRUPO AHORRO CORPORACION (EMPLEA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0362-F0273-G0219',
    'name': 'EMPLEADOS CAJA CANTABRIA',
    'fundDescription': 'FONDEM CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0370-F0277-G0219',
    'name': 'EMPLEADOS CAJA CANARIAS',
    'fundDescription': 'F.P.DE EMPLEADOS DE LA CAJA GENERAL DE AHORROS DE CANARIAS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4990-F0278-G0219',
    'name': 'PLAN DE PENSIONES DEL PERSONAL DE ABANCA',
    'fundDescription': 'F.P. B DEL PERSONAL DE ABANCA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1319-F0300-G0219',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE CREDIT AGRICOLE CIB, SUCURSAL EN ESPAÑA',
    'fundDescription': 'AHORROVIDA 2, FONDO DE PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5409-F0300-G0219',
    'name': 'P.P.EMPLEADOS DE CA INDOSUEZ WEALTH (EUROPE) SUCURSAL EN ESPAÑA',
    'fundDescription': 'AHORROVIDA 2, FONDO DE PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1164-F0388-G0219',
    'name': 'CASER RV MIXTA 70',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1193-F0388-G0219',
    'name': 'ONTINYENT RENTA VARIABLE',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1195-F0388-G0219',
    'name': 'RENTA VARIABLE CIRCULO',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1262-F0388-G0219',
    'name': 'EUROFUTURO',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1378-F0388-G0219',
    'name': 'CAIXA CARLET RENTA VARIABLE',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1422-F0388-G0219',
    'name': 'ANDALUCIA R.V.',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1667-F0388-G0219',
    'name': 'CASER PREMIER RVM',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1837-F0388-G0219',
    'name': 'MIRALTA CRECIMIENTO',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3199-F0388-G0219',
    'name': 'P.P ATLAS CAPITAL DINAMICO',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4825-F0388-G0219',
    'name': 'BURGOS EXPLENDOR',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4841-F0388-G0219',
    'name': 'Caser masXnada RVM 75,PP',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4937-F0388-G0219',
    'name': 'CASER DINAMISMO PLUS',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4940-F0388-G0219',
    'name': 'CASER DINAMISMO',
    'fundDescription': 'AHORROPENSION TRES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1075-F0389-G0219',
    'name': 'CASER MIXTO 50',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1295-F0389-G0219',
    'name': 'LEALTAD',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1390-F0389-G0219',
    'name': 'RIOJA 2',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1480-F0389-G0219',
    'name': 'AVILA RENTA MIXTA',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1512-F0389-G0219',
    'name': 'CIRCULO MIXTO',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1669-F0389-G0219',
    'name': 'CANARIAS RENTA MIXTA',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2916-F0389-G0219',
    'name': 'CASER PREMIER MIXTO',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3195-F0389-G0219',
    'name': 'P.P ATLAS CAPITAL MIXTO',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4843-F0389-G0219',
    'name': 'CASER MASXNADA RVM 50',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4941-F0389-G0219',
    'name': 'CASER EQUILIBRIO PLUS',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4942-F0389-G0219',
    'name': 'CASER EQUILIBRIO',
    'fundDescription': 'AHORROVIDA III',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0936-F0390-G0219',
    'name': 'EMPLEO DEL PRINCIPADO DE ASTURIAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1133-F0390-G0219',
    'name': 'EMPLEO MUSEO BELLAS ARTES DE ASTURIAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1143-F0390-G0219',
    'name': 'EMPLEO SERV. SALUD PRINCIPADO ASTURIAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1311-F0390-G0219',
    'name': 'JUNTA GENERAL DEL PRINCIPADO DE ASTURIAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1313-F0390-G0219',
    'name': 'INSTITUTO DE FOMENTO REGIONAL',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1552-F0390-G0219',
    'name': 'ORGANISMO AUTONOMO E.R.A.',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3804-F0390-G0219',
    'name': 'C.CONSULT.PRINC.ASTURIAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2697-F0390-G0219',
    'name': 'PLAN DE PENSIONES 112-BOMBEROS DE ASTURIAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3174-F0390-G0219',
    'name': 'SERIDA',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3697-F0390-G0219',
    'name': 'EMPL. DE LA SINDICATURA DE CUENTAS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3805-F0390-G0219',
    'name': 'SERVICIO PUBLICO DE EMPLEO',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3830-F0390-G0219',
    'name': 'INSTITUTO ASTURIANO DE PREVENCION DE RIESGOS LABORALES',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3829-F0390-G0219',
    'name': 'ENTE PUBLICO SERVICIOS TRIBUTARIOS',
    'fundDescription': 'ADMINISTRACION PRINCIPADO ASTURI',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0828-F0391-G0219',
    'name': 'MONTEPIO Y MUTUALIDAD MINERIA ASTURIANA',
    'fundDescription': 'MUTUALIDAD Y MONTEPIO MINERIA AS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2300-F0469-G0219',
    'name': 'EXCMO. AYUNTAMIENTO DE CASTRO URDIALES',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3797-F0469-G0219',
    'name': 'I.M. DEPORTES SANTANDER',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3622-F0469-G0219',
    'name': 'CAJA CANTABRIA AYUNTAMIENTOS',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3779-F0469-G0219',
    'name': 'EXCMO. AYUNTAMIENTO DE SANTANDER',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3846-F0469-G0219',
    'name': 'EXCMO. AYUNTAMIENTO DE ASTILLERO',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3945-F0469-G0219',
    'name': 'EXCMO. AYUNTAMIENTO DE TORRELAVEGA',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4015-F0469-G0219',
    'name': 'EXCMO. AYUNTAMIENTO DE SANTOÑA',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4029-F0469-G0219',
    'name': 'PATRONATO MUNIC. EDUCACION CENTRO ESPECIAL DE EMPLEO SERCA',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4066-F0469-G0219',
    'name': 'AGUAS DE TORRELAVEGA',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4102-F0469-G0219',
    'name': 'EXCMO. AYUNTAMIENTO DE SANTA CRUZ DE BEZANA',
    'fundDescription': 'FONDO CAJA CANTABRIA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1544-F0478-G0219',
    'name': 'PATRONATO M. DEPORTES AYTO. CARTAGENA',
    'fundDescription': 'CAJA MURCIA II',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1545-F0478-G0219',
    'name': 'AGUAS DE LORCA',
    'fundDescription': 'CAJA MURCIA II',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1989-F0478-G0219',
    'name': 'AYTO. DE SAN JAVIER',
    'fundDescription': 'CAJA MURCIA II',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3499-F0478-G0219',
    'name': 'AYUNTAMIENTO DE HELLIN',
    'fundDescription': 'CAJA MURCIA II',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3979-F0478-G0219',
    'name': 'P.P.EXCELENTISIMO AYUNT. DE ABARAN',
    'fundDescription': 'CAJA MURCIA II',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3871-F0493-G0219',
    'name': 'CASER VALOR ALZA, P.P.',
    'fundDescription': 'AHORRO FUTURO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4822-F0493-G0219',
    'name': 'BURGOS BONIFICADO',
    'fundDescription': 'AHORRO FUTURO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4838-F0493-G0219',
    'name': 'Caser masXnada Valar en Alza,P.P.',
    'fundDescription': 'AHORRO FUTURO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0029-F0494-G0219',
    'name': 'CIRCULO',
    'fundDescription': 'CIRCULO DE BURGOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1719-F0494-G0219',
    'name': 'AGROCIRCULO',
    'fundDescription': 'CIRCULO DE BURGOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1858-F0494-G0219',
    'name': 'ASAJA CAJACIRCULO',
    'fundDescription': 'CIRCULO DE BURGOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1463-F0515-G0219',
    'name': 'EUROPA R.V.',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1475-F0515-G0219',
    'name': 'PLAN CR RENTA VARIABLE',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1513-F0515-G0219',
    'name': 'CIRCULO ACCIONES',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1661-F0515-G0219',
    'name': 'CASER VARIABLE',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1812-F0515-G0219',
    'name': 'MIRALTA GLOBAL PP',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2903-F0515-G0219',
    'name': 'CASER PREMIER RV',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3197-F0515-G0219',
    'name': 'P.P ATLAS CAPITAL RENTA VARIABLE',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4824-F0515-G0219',
    'name': 'BURGOS MERCADO',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4900-F0515-G0219',
    'name': 'CASER MASXNADA RV',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4924-F0515-G0219',
    'name': 'EVO RENTA VARIABLE',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4935-F0515-G0219',
    'name': 'CASER OPORTUNIDAD PLUS',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4936-F0515-G0219',
    'name': 'CASER OPORTUNIDAD',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5180-F0515-G0219',
    'name': '360 CorA Renta Variable',
    'fundDescription': 'AHORROPENSION CUATRO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1237-F0531-G0219',
    'name': 'CAJA DE JAEN',
    'fundDescription': 'JAENPENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2031-F0582-G0219',
    'name': 'CASER RF',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2594-F0582-G0219',
    'name': 'ATLANTIS I',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2595-F0582-G0219',
    'name': 'CIRCULO RF',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2904-F0582-G0219',
    'name': 'CASER PREMIER RF',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3150-F0582-G0219',
    'name': 'MPS AMAS DE CASA',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3198-F0582-G0219',
    'name': 'P.P ATLAS CAPITAL RENTA FIJA',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4235-F0582-G0219',
    'name': 'ENEAS MONETARIO',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4925-F0582-G0219',
    'name': 'EVO RENTA FIJA A CORTO PLAZO',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4938-F0582-G0219',
    'name': 'CASER SEGURIDAD PLUS',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4939-F0582-G0219',
    'name': 'CASER SEGURIDAD',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3885-F0582-G0219',
    'name': 'CASER 2011 BETA, P.P.',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3149-F0582-G0219',
    'name': 'ACF PLAN RF EURO',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3131-F0582-G0219',
    'name': 'CASER 129 RF, P.P.',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3206-F0582-G0219',
    'name': 'CASER ENERO 2009',
    'fundDescription': 'AHORROPENSION CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2032-F0583-G0219',
    'name': 'CASER CAPITAL PROTEGIDO',
    'fundDescription': 'AHORROPENSION SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2821-F0583-G0219',
    'name': 'ACUEDUCTO CAPITAL PROTEGIDO',
    'fundDescription': 'AHORROPENSION SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2938-F0583-G0219',
    'name': 'CASER PREMIER PROTEGIDO',
    'fundDescription': 'AHORROPENSION SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3029-F0583-G0219',
    'name': 'CASER FUTURO PROTEGIDO',
    'fundDescription': 'AHORROPENSION SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3030-F0583-G0219',
    'name': 'AHORRO PERSONAL',
    'fundDescription': 'AHORROPENSION SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4839-F0583-G0219',
    'name': 'Caser masXnada Protegido,PP',
    'fundDescription': 'AHORROPENSION SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2428-F0759-G0219',
    'name': 'BMN EMP. 2051 SBP 103 PD',
    'fundDescription': 'BMN EMPLEADOS, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3497-F1042-G0219',
    'name': 'EMPL.CONSEJO INSULAR DE AGUAS DE TENERIFE',
    'fundDescription': 'FONDCANARIAS EMPLEO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4491-F1042-G0219',
    'name': 'CONSORCIO TRIBUTOS ISLA TENERIFE',
    'fundDescription': 'FONDCANARIAS EMPLEO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4510-F1042-G0219',
    'name': 'O.A.INST.INSULAR ATENC.SOC.Y SOCIOSANITA',
    'fundDescription': 'FONDCANARIAS EMPLEO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3287-F1042-G0219',
    'name': 'CABILDO INSULAR TENERIFE',
    'fundDescription': 'FONDCANARIAS EMPLEO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3722-F1042-G0219',
    'name': 'O.A.P. INS.MUSICA, PP',
    'fundDescription': 'FONDCANARIAS EMPLEO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3266-F1081-G0219',
    'name': 'AYUNTAMIENTO DE MURCIA Y ORG. AUTONOMOS',
    'fundDescription': 'CAJAMURCIA III, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3665-F1084-G0219',
    'name': 'CASER GESTIÓN DE VALOR, PP',
    'fundDescription': 'AHORROPENSION ONCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3666-F1084-G0219',
    'name': 'CASER MAGALLANES, PP',
    'fundDescription': 'AHORROPENSION ONCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5210-F1084-G0219',
    'name': 'ONTINYENT MAGALLANES',
    'fundDescription': 'AHORROPENSION ONCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3377-F1085-G0219',
    'name': 'CASER NUEVAS OPORTUNIDADES,P.P.',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4840-F1085-G0219',
    'name': 'Caser masXnada Nuevas Oportunidades,PP',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4933-F1085-G0219',
    'name': 'CASER OPORTUNIDAD ÚNICA',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4934-F1085-G0219',
    'name': 'CASER OPORTUNIDAD ÚNICA PLUS',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5049-F1085-G0219',
    'name': 'CASER GESTIÓN DIRECTA',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5075-F1085-G0219',
    'name': 'ONTINYENT NUEVAS OPORTUNIDADES, PP',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5019-F1085-G0219',
    'name': 'ACF PLAN RV GLOBAL, PP',
    'fundDescription': 'AHORROPENSION DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3480-F1168-G0219',
    'name': 'PP DE LAS ENTIDADES LOCALES DEL PRINCIPADO DE ASTURIAS',
    'fundDescription': 'F.P.ENT.LOCALES PRINC.ASTURIAS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3424-F1190-G0219',
    'name': 'CASER RF LARGO',
    'fundDescription': 'AHORROPENSION CATORCE,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4222-F1190-G0219',
    'name': 'PLAN DE PENSIONES CASER DEUDA PUBLICA',
    'fundDescription': 'AHORROPENSION CATORCE,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4237-F1190-G0219',
    'name': 'ENEAS GOBIERNO',
    'fundDescription': 'AHORROPENSION CATORCE,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5161-F1190-G0219',
    'name': '360 CorA Renta Fija',
    'fundDescription': 'AHORROPENSION CATORCE,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3826-F1281-G0219',
    'name': 'CASER RV ESPAÑA',
    'fundDescription': 'AHORROPENSION VEINTE, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3823-F1284-G0219',
    'name': 'CASER RV ASIA',
    'fundDescription': 'AHORROPENSION VEINTIUNO,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4099-F1284-G0219',
    'name': 'ABANCA ASIA-PACIFICO',
    'fundDescription': 'AHORROPENSION VEINTIUNO,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5018-F1284-G0219',
    'name': 'ACF PLAN RV JAPON',
    'fundDescription': 'AHORROPENSION VEINTIUNO,F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0932-F1285-G0219',
    'name': 'PLAN DE PENSIONES ADISSEO ESPAÑA S.A',
    'fundDescription': 'AHORROPENSION VEINTIDOS, FONDO DE PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3766-F1285-G0219',
    'name': 'PLAN DE PENSIONES CAJA CIRCULO EMPLEO',
    'fundDescription': 'AHORROPENSION VEINTIDOS, FONDO DE PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3951-F1285-G0219',
    'name': 'PLAN DE PENSIONES EMPLEADOS DE NICOLAS CORREA, PLAN DE PENSIONES',
    'fundDescription': 'AHORROPENSION VEINTIDOS, FONDO DE PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3856-F1322-G0219',
    'name': 'CASER RESPONSABILISAD PLUS',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4826-F1322-G0219',
    'name': 'BURGOS RESPONSABLE',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4842-F1322-G0219',
    'name': 'Caser masXnada Responsable,PP',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5074-F1322-G0219',
    'name': 'ONTINYENT SOCIALMENTE RESPONSABLE, PP',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3919-F1322-G0219',
    'name': 'COLONYA PLAÈTIC I SOLIDARI, PLAN DE PENSIONES',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4069-F1322-G0219',
    'name': 'AVILA RESPONSABILIDAD PLUS, PLAN DE PENSIONES',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4984-F1322-G0219',
    'name': 'COLONYA SR, P.P.',
    'fundDescription': 'AHORROPENSION TREINTA Y TRES, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3822-F1335-G0219',
    'name': 'CASER RV NORTEAM',
    'fundDescription': 'AHORROPENSION VEINTISIETE,FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4299-F1335-G0219',
    'name': 'ACUEDUCTO RV NORTEAMERICA, P.P.',
    'fundDescription': 'AHORROPENSION VEINTISIETE,FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5017-F1335-G0219',
    'name': 'ACF PLAN RV USA',
    'fundDescription': 'AHORROPENSION VEINTISIETE,FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4098-F1335-G0219',
    'name': 'ABANCA USA',
    'fundDescription': 'AHORROPENSION VEINTISIETE,FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4253-F1336-G0219',
    'name': 'PLAN DE ADMINISTRACION PÚBLICA DE LA COMUNIDAD AUTONOMA DE CANARIAS, P.P',
    'fundDescription': 'FONDO DE EMPLEADOS PÚBLICOS DE LA COMUNIDAD AUTONOMA DE CANARIAS,F .P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3825-F1338-G0219',
    'name': 'CASER RV G.EMERG',
    'fundDescription': 'AHORROPENSION VEINTICINCO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3438-F1338-G0219',
    'name': 'CASER PREMIER 2021, P.P.',
    'fundDescription': 'AHORROPENSION VEINTICINCO, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4145-F1407-G0219',
    'name': 'PLAN DE PENSIONES CASER ALLIGATOR',
    'fundDescription': 'AHORROPENSION CUARENTA Y CUATRO, FONDO DE PENSIONES',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4167-F1408-G0219',
    'name': 'CAJA SEGOVIA 2013 A',
    'fundDescription': 'AHORROPENSION CUARENTA Y CINCO',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3316-F1409-G0219',
    'name': 'CASER ENERO 2010',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3425-F1409-G0219',
    'name': 'CASER 5 - IBEX',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3429-F1409-G0219',
    'name': 'CASER 2009 D PLUS',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3582-F1409-G0219',
    'name': 'CASER 2010 ALFA',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4179-F1409-G0219',
    'name': 'CASER ARD',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4212-F1409-G0219',
    'name': 'ACUEDUCTO ALTA RENTABILIDAD',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4236-F1409-G0219',
    'name': 'ENEAS ARD',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4278-F1409-G0219',
    'name': 'CASER DEPOSITO 35',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4279-F1409-G0219',
    'name': 'CANARIAS RENTA PROTEGIDA',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4300-F1409-G0219',
    'name': 'PLAN DE PENSIONES ACUEDUCTO RENTABILIDAD PLUS',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4330-F1409-G0219',
    'name': 'RIOJA PLANDEPOSITO, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4439-F1409-G0219',
    'name': 'CIRCULO TRANQUILIDAD, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4518-F1409-G0219',
    'name': 'CASER ARD PREMIER, PP',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4699-F1409-G0219',
    'name': 'CASER ARD ALFA, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4823-F1409-G0219',
    'name': 'BURGOS FUERZA',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4844-F1409-G0219',
    'name': 'Caser masXnada ARD,PP',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N2901-F1409-G0219',
    'name': 'CASER 2012, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3646-F1409-G0219',
    'name': 'CASER ENERO 2013, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3682-F1409-G0219',
    'name': 'C. SEGOVIA ENERO 2013, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4094-F1409-G0219',
    'name': 'CASER HORIZONTE 2013, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3132-F1409-G0219',
    'name': 'CASER 330 RF, P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3207-F1409-G0219',
    'name': 'CASER JULIO 2013,  P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4310-F1409-G0219',
    'name': 'CASER 3X3 P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y SEIS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5310-F1502-G0219',
    'name': 'CASER GENERACION 60-65,P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y NUEVE, F.P',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5414-F1502-G0219',
    'name': 'HELVETIA GENERACION 60-65,P.P.',
    'fundDescription': 'AHORROPENSION CUARENTA Y NUEVE, F.P',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': '----F1638-G0219',
    'name': '---',
    'fundDescription': 'AHORROPENSION SESENTA Y OCHO,F.P',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5309-F1641-G0219',
    'name': 'CASER GENERACION 65-70, P.P.',
    'fundDescription': 'AHORROPENSION SETENTA Y UNO,F.P',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5415-F1641-G0219',
    'name': 'HELVETIA GENERACION 65-70, P.P.',
    'fundDescription': 'AHORROPENSION SETENTA Y UNO,F.P',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4614-F1644-G0219',
    'name': 'PROTEGIDO RENTA CR 2017',
    'fundDescription': 'AHORROPENSION SETENTA Y CINCO,F.P',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5136-F1749-G0219',
    'name': 'TRIODOS RENTA FIJA MIXTA, P.P.',
    'fundDescription': 'AHORROPENSION OCHENTA Y CINCO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5140-F1753-G0219',
    'name': 'FINIZENS CONSERVADOR, P.P.',
    'fundDescription': 'FINIZENS 1, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5383-F1753-G0219',
    'name': 'EVO CONSERVADOR, P.P.',
    'fundDescription': 'FINIZENS 1, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N4973-F1754-G0219',
    'name': 'CASER GLOBAL, PP',
    'fundDescription': 'AHORROPENSION NOVENTA',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5143-F1817-G0219',
    'name': 'FINIZENS DECIDIDO, P.P.',
    'fundDescription': 'FINIZENS 4, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5144-F1819-G0219',
    'name': 'FINIZENS ATREVIDO, P.P.',
    'fundDescription': 'FINIZENS 5, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5381-F1819-G0219',
    'name': 'EVO AGRESIVO, P.P.',
    'fundDescription': 'FINIZENS 5, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5308-F1825-G0219',
    'name': 'CASER GENERACION 70 y MAS, PP',
    'fundDescription': 'AHORROPENSION NOVENTA Y DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5413-F1825-G0219',
    'name': 'HELVETIA GENERACION 70 y MAS, PP',
    'fundDescription': 'AHORROPENSION NOVENTA Y DOS',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5141-F1826-G0219',
    'name': 'FINIZENS CAUTO, P.P.',
    'fundDescription': 'FINIZENS 2, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5142-F1827-G0219',
    'name': 'FINIZENS EQUILIBRADO, P.P.',
    'fundDescription': 'FINIZENS 3, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5382-F1827-G0219',
    'name': 'EVO DINAMICO, P.P.',
    'fundDescription': 'FINIZENS 3, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5095-F1828-G0219',
    'name': 'LEGACY, P.P.',
    'fundDescription': 'AHORROPENSION NOVENTA Y CINCO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5048-F1829-G0219',
    'name': 'MAGALLANES ACCIONES EUROPEAS',
    'fundDescription': 'AHORROPENSION NOVENTA Y SEIS, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5255-F1830-G0219',
    'name': 'SMART BOLSA MUNDIAL, PP',
    'fundDescription': 'AHORROPENSION NOVENTA Y SIETE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5452-F1830-G0219',
    'name': 'CASER DINAMICO BOLSA, PP',
    'fundDescription': 'AHORROPENSION NOVENTA Y SIETE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5138-F1831-G0219',
    'name': 'INDEXA MAS RENT ACC',
    'fundDescription': 'INDEXA MÁS RENTABILIDAD ACCIONES, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5137-F1832-G0219',
    'name': 'INDEXA MAS RENT BON',
    'fundDescription': 'INDEXA MÁS RENTABILIDAD BONOS, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5263-F2070-G0219',
    'name': 'HOROS INTERNACIONAL, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO CUATRO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5285-F2071-G0219',
    'name': 'IM PENSION MODERADO, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO SEIS, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5286-F2072-G0219',
    'name': 'IM PENSION DINAMICO, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO SIETE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5289-F2073-G0219',
    'name': 'SÁSSOLA CARTERA EQUILIBRADA, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO OCHO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5213-F2074-G0219',
    'name': 'GESEM CYGNUS PENSIONES, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO NUEVE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5276-F2075-G0219',
    'name': 'PLAN PENSIONES ASOCIADO DE OCU, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO DIEZ, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5359-F2116-G0219',
    'name': 'BELGRAVIA EPSILON, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO DIECISEIS, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5357-F2117-G0219',
    'name': 'INTERNATIONAL EQUITY MARKETS, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO DOCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5375-F2118-G0219',
    'name': 'INDEXA EMPLEO MAS RENTABILIDAD BONOS, F.P.',
    'fundDescription': 'INDEXA EMPLEO BONOS, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5489-F2118-G0219',
    'name': 'INDEXA EMPLEO AUTONOMO BONOS, FP',
    'fundDescription': 'INDEXA EMPLEO BONOS, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5509-F2119-G0219',
    'name': 'INDEXA EMPLEO 100% ACCIONES, PP',
    'fundDescription': 'INDEXA EMPLEO ACCIONES, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5356-F2120-G0219',
    'name': 'INTERNATIONAL BOND MARKETS, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO TRECE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5325-F2121-G0219',
    'name': 'CRIANZA DE VALOR, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO ONCE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3765-F2156-G0219',
    'name': 'GALILEO ESPAÑA P.P.',
    'fundDescription': 'AHORROPENSION CIENTO VEINTE, P.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5485-F2156-G0219',
    'name': 'CASER EVOLUCION, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO VEINTE, P.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5503-F2156-G0219',
    'name': 'CASER COACVALENCIA',
    'fundDescription': 'AHORROPENSION CIENTO VEINTE, P.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N1389-F2157-G0219',
    'name': 'ALCALA FUTURO UNO, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO DIECISIETE, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5401-F2161-G0219',
    'name': 'TRIODOS RENTA VARIABLE MIXTA DINAMICO, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO VEINTITRES, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5400-F2162-G0219',
    'name': 'TRIODOS RENTA VARIABLE MIXTA NEUTRAL, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO VEINTIDOS, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N3765-F2163-G0219',
    'name': 'GALILEO ESPAÑA P.P.',
    'fundDescription': 'AHORROPENSION CIENTO DIECIOCHO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5485-F2163-G0219',
    'name': 'CASER EVOLUCION, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO DIECIOCHO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5503-F2163-G0219',
    'name': 'CASER COACVALENCIA',
    'fundDescription': 'AHORROPENSION CIENTO DIECIOCHO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5240-F2195-G0219',
    'name': 'B&H JUBILACION, PP',
    'fundDescription': 'AHORROPENSION CIENTO DIECINUEVE, FP',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N5475-F2197-G0219',
    'name': 'PANZA PENSIONES, P.P.',
    'fundDescription': 'AHORROPENSION CIENTO VEINTICUATRO, F.P.',
    'managerId': 'G0219',
    'managerName': 'CASER PENSIONES'
  },
  {
    'id': 'N0769-F0211-G0224',
    'name': 'PPPC DE EMPLEADOS DE METLIFE (MET-1 E)',
    'fundDescription': 'METLIFE 1, FONDO DE PENSIONES',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N1125-F0212-G0224',
    'name': 'PLAN DE PENSIONES DE LOS EMPLEADOS DEL GRUPO AMADEUS',
    'fundDescription': 'AZVALOR EMPLEO, FP',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N1376-F0517-G0224',
    'name': 'Profit Prevision, Plan de Pensiones',
    'fundDescription': 'PROFIT PENSION, FONDO DE PENSIONES',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5117-F1603-G0224',
    'name': 'AZVALOR CONSOLIDACION, PP',
    'fundDescription': 'AZVALOR CONSOLIDACIÓN, FONDO DE PENSIONES',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5116-F1905-G0224',
    'name': 'AZVALOR GLOBAL VALUE, PP',
    'fundDescription': 'AZVALOR GLOBAL VALUE, FONDO DE PENSIONES',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5231-F1906-G0224',
    'name': 'DUNAS VALOR FLEXIBLE I, PP',
    'fundDescription': 'DUNAS VALOR FLEXIBLE, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5232-F1906-G0224',
    'name': 'DUNAS VALOR FLEXIBLE R, PP',
    'fundDescription': 'DUNAS VALOR FLEXIBLE, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5261-F2052-G0224',
    'name': 'DUNAS SELECCION USA ESG CUBIERTO I, PP',
    'fundDescription': 'DUNAS SELECCION USA ESG CUBIERTO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5260-F2052-G0224',
    'name': 'DUNAS SELECCION USA ESG CUBIERTO R, PP',
    'fundDescription': 'DUNAS SELECCION USA ESG CUBIERTO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5242-F2100-G0224',
    'name': 'DUNAS VALOR EQUILIBRADO I, PP',
    'fundDescription': 'DUNAS VALOR EQUILIBRADO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N5243-F2100-G0224',
    'name': 'DUNAS VALOR EQUILIBRADO R, PP',
    'fundDescription': 'DUNAS VALOR EQUILIBRADO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N4529-F2100-G0224',
    'name': 'ATL CAPITAL CONSERVADOR, PP',
    'fundDescription': 'DUNAS VALOR EQUILIBRADO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N0022-F2100-G0224',
    'name': 'GESNORTE PLAN DE PENSIONERS',
    'fundDescription': 'DUNAS VALOR EQUILIBRADO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N0266-F2100-G0224',
    'name': 'ACIPROL, PP',
    'fundDescription': 'DUNAS VALOR EQUILIBRADO, FONDO DE PENSIONES ABIERTO',
    'managerId': 'G0224',
    'managerName': 'DUNAS CAPITAL PENSIONES, S.G.F.P., S.A.U.'
  },
  {
    'id': 'N0997-F0402-G0225',
    'name': 'CAJA INGENIEROS  GLOBAL SUSTAINABILITY ISR, PP',
    'fundDescription': 'CAJA DE INGENIEROS , FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N1779-F0645-G0225',
    'name': 'CAJA INGENIEROS  EUROMARKET RV 75, P.P.',
    'fundDescription': 'CAJA DE INGENIEROS 2, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2263-F0753-G0225',
    'name': 'CAJA INGENIEROS  CLIMATE SUSTAINABILITY ISR, PP',
    'fundDescription': 'CAJA DE INGENIEROS 3, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2599-F0839-G0225',
    'name': 'CAJA INGENIEROS  EUROBOND RF 100, P.P.',
    'fundDescription': 'CAJA DE INGENIEROS 4, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2977-F0933-G0225',
    'name': 'PLAN DE PENSIONES DE EMPLEO DEL GRUPO CAJA DE INGENIEROS',
    'fundDescription': 'CAJA DE INGENIEROS EMPLEO, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5025-F1087-G0225',
    'name': 'PLAN DE PENSIONES DE LOS INGENIEROS',
    'fundDescription': 'CAJA DE INGENIEROS 15, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3539-F1234-G0225',
    'name': 'CAJA INGENIEROS MULTIGESTION ISR, P.P.',
    'fundDescription': 'CAJA DE INGENIEROS 7, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5244-F1313-G0225',
    'name': 'ESCOLA COOP. PLA DE PENSIONS',
    'fundDescription': 'CAJA DE INGENIEROS 8, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3790-F1314-G0225',
    'name': 'CAJA INGENIEROS SKYLINE, P.P.',
    'fundDescription': 'CAJA DE INGENIEROS 9, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5493-F1379-G0225',
    'name': 'AUCAT FUTURO  AUTONOMOS, PPES',
    'fundDescription': 'CAJA DE INGENIEROS 10, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2267-F1380-G0225',
    'name': 'GIRNET, PLAN DE PENSIONES',
    'fundDescription': 'CAJA DE INGENIEROS 11, FP',
    'managerId': 'G0225',
    'managerName': 'CAJA DE INGENIEROS VIDA, CIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0079-F0027-G0230',
    'name': 'PLAN DE PENSIONES INDIVIDUAL AEGON I',
    'fundDescription': 'AEGON I FONDO DE PENSIONES',
    'managerId': 'G0230',
    'managerName': 'AEGON ESPAÑA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4110-F1456-G0230',
    'name': 'PLAN DE PENSIONES INDIVIDUAL AEGON II',
    'fundDescription': 'AEGON II FONDO DE PENSIONES',
    'managerId': 'G0230',
    'managerName': 'AEGON ESPAÑA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0208-F0775-G0232',
    'name': 'HNA PLAN 1',
    'fundDescription': 'ESTRATEGIA,F.P.',
    'managerId': 'G0232',
    'managerName': 'HERMANDAD NACIONAL DE ARQUITECTOS, ARQUITECTOS TÉCNICOS Y QUÍMICOS, MPS'
  },
  {
    'id': 'N5010-F2011-G0232',
    'name': 'PROYECTA EQUILIBRIO, PLAN DE PENSIONES',
    'fundDescription': 'FONDO PROYECTA EQUILIBRIO, FO',
    'managerId': 'G0232',
    'managerName': 'HERMANDAD NACIONAL DE ARQUITECTOS, ARQUITECTOS TÉCNICOS Y QUÍMICOS, MPS'
  },
  {
    'id': 'N5011-F2012-G0232',
    'name': 'PROYECTA TRANQUILIDAD, PLAN DE PENSIONES',
    'fundDescription': 'FONDO  PROYECTA TRANQUILIDAD , FP',
    'managerId': 'G0232',
    'managerName': 'HERMANDAD NACIONAL DE ARQUITECTOS, ARQUITECTOS TÉCNICOS Y QUÍMICOS, MPS'
  },
  {
    'id': 'N2861-F1404-G0233',
    'name': 'ABANTE VARIABLE, PP',
    'fundDescription': 'ABANTE VARIABLE PENSIONES, FP',
    'managerId': 'G0233',
    'managerName': 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    'id': 'N2896-F1405-G0233',
    'name': 'ABANTE BOLSA, PP',
    'fundDescription': 'ABANTE BOLSA PENSIONES, FP',
    'managerId': 'G0233',
    'managerName': 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    'id': 'N2862-F1824-G0233',
    'name': 'ABANTE RENTA, PP',
    'fundDescription': 'ABANTE RENTA PENSIONES, FP',
    'managerId': 'G0233',
    'managerName': 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    'id': 'N5108-F2049-G0233',
    'name': 'EUROPEAN QUALITY PP',
    'fundDescription': 'EUROPEAN QUALITY PENSIONES , FONDO DE PENSIONES',
    'managerId': 'G0233',
    'managerName': 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    'id': 'N5109-F2050-G0233',
    'name': 'OKAVANGO PP',
    'fundDescription': 'OKAVANGO PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0233',
    'managerName': 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    'id': 'N5110-F2051-G0233',
    'name': 'TEMPUS 30-75 PP',
    'fundDescription': 'TEMPUS 30-75 PENSIONES, FONDO DE PENSIONES',
    'managerId': 'G0233',
    'managerName': 'ABANTE PENSIONES E.G.F.P, S. A.'
  },
  {
    'id': 'N0077-F0025-G0234',
    'name': 'KUTXABANK RENTA FIJA, PLAN DE PENSIONES',
    'fundDescription': 'KUTXABANK RENTA FIJA, FONDO DE PENSIONES',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N0147-F0085-G0234',
    'name': 'KUTXABANK RENTA FIJA MIXTO 15, PLAN DE PENSIONES',
    'fundDescription': 'KUTXABANK RENTA FIJA MIXTO 15, FONDO DE PENSIONES',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N0265-F0204-G0234',
    'name': 'PLAN DE PENSIONES MUGA',
    'fundDescription': 'KUTXA FONDO DE PENSIONES ASOCIADO',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N1900-F0411-G0234',
    'name': 'KUTXABANK BOLSA EUROPA, PLAN DE PENSIONES',
    'fundDescription': 'KUTXABANK BOLSA EUROPA, FONDO DE PENSIONES',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N2621-F0682-G0234',
    'name': 'KUTXABANK BOLSA GLOBAL, PLAN DE PENSIONES',
    'fundDescription': 'KUTXABANK BOLSA GLOBAL, FONDO DE PENSIONES',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N2323-F0761-G0234',
    'name': 'EMPLEADOS DE CAJASUR',
    'fundDescription': 'EMPLEADOS DE CAJASUR',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N4037-F1445-G0234',
    'name': 'KUTXABANK RENTA FIJA MIXTO 30, PLAN DE PENSIONES',
    'fundDescription': 'KUTXABANK RENTA FIJA MIXTO 30, FONDO DE PENSIONES',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N4038-F1446-G0234',
    'name': 'KUTXABANK RENTA VARIABLE MIXTO 60, PLAN DE PENSIONES',
    'fundDescription': 'KUTXABANK RENTA VARIABLE MIXTO 60, FONDO DE PENSIONES',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N4969-F1964-G0234',
    'name': 'KUTXABANK PLUS 7, PP',
    'fundDescription': 'KUTXABANK PLUS 7, FP',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N5163-F2061-G0234',
    'name': 'KUTXABANK PLUS 9, PP',
    'fundDescription': 'KUTXABANK PLUS 9, FP',
    'managerId': 'G0234',
    'managerName': 'KUTXABANK PENSIONES, S.A.U., ENTIDAD GESTORA DE FONDOS DE PENSIONES'
  },
  {
    'id': 'N0139-F0079-G0236',
    'name': 'GCO Pensiones Mixto Fijo, Plan de Pensiones',
    'fundDescription': 'GCO Pensiones Mixto Fijo, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N1860-F0674-G0236',
    'name': 'GCO Pensiones Renta Variable, Plan de Pensiones',
    'fundDescription': 'GCO Pensiones Renta Variable, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N2408-F0780-G0236',
    'name': 'Plan de Pensiones de Promoción Conjunta Empleados GCO, Plan de Pensiones',
    'fundDescription': 'GCO Pensiones Empleados, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N3113-F1022-G0236',
    'name': 'GCO Pensiones Renta Fija, Plan de Pensiones',
    'fundDescription': 'GCO Pensiones Renta Fija, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N3380-F1179-G0236',
    'name': 'Cat Previsió, Plan de Pensiones',
    'fundDescription': 'Cat Previsió, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N3936-F1383-G0236',
    'name': 'GCO Pensiones Colectivo, Plan de Pensiones',
    'fundDescription': 'GCO Pensiones Colectivo, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N5368-F2150-G0236',
    'name': 'GCO Pensiones Mixto Variable, Plan de Pensiones',
    'fundDescription': 'GCO Pensiones Mixto Variable, Fondo de Pensiones',
    'managerId': 'G0236',
    'managerName': 'GCO Gestora de Pensiones, EGFP, S.A.'
  },
  {
    'id': 'N0098-F0039-G0237',
    'name': 'DUERO, P.P.',
    'fundDescription': 'FONDUERO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1481-F0039-G0237',
    'name': 'AGRARIO DUERO, P.P.',
    'fundDescription': 'FONDUERO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1369-F0039-G0237',
    'name': 'MUTUA FASA RENAULT',
    'fundDescription': 'FONDUERO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N2335-F0039-G0237',
    'name': 'COLEG.PSIC.EXTREMADURA',
    'fundDescription': 'FONDUERO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3281-F0039-G0237',
    'name': 'COLEGIO OFICIAL APAREJADORES Y ARQUITECTOS TECNICOS DE CACERES',
    'fundDescription': 'FONDUERO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3714-F0039-G0237',
    'name': 'COLEGIO FARMACEUTICO DE SORIA',
    'fundDescription': 'FONDUERO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N0007-F0353-G0237',
    'name': 'DUERO EQUILIBRIO, P.P.',
    'fundDescription': 'FONDUERO EQUILIBRIO, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1007-F0404-G0237',
    'name': 'DUERO ESTABILIDAD, P.P.',
    'fundDescription': 'FONDUERO ESTABILIDAD, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N0013-F0405-G0237',
    'name': 'DUERO INVERSION, P.P.',
    'fundDescription': 'FONDUERO INVERSION, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1786-F0661-G0237',
    'name': 'UPACYL',
    'fundDescription': 'AGRARIO DUERO CASTILLA Y LEON, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1787-F0661-G0237',
    'name': 'AGRARIO DUERO CASTILLA Y LEON, P.P.',
    'fundDescription': 'AGRARIO DUERO CASTILLA Y LEON, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1788-F0661-G0237',
    'name': 'ASAJA CAJA DUERO',
    'fundDescription': 'AGRARIO DUERO CASTILLA Y LEON, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3557-F0661-G0237',
    'name': 'COAG Castilla y León, P.P.',
    'fundDescription': 'AGRARIO DUERO CASTILLA Y LEON, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1851-F0681-G0237',
    'name': 'DUERO ACCION GLOBAL, P.P.',
    'fundDescription': 'FONDUERO ACCION GLOBAL, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N1909-F0684-G0237',
    'name': 'DUERO ACCION EUROPA, P.P.',
    'fundDescription': 'FONDUERO ACCION EUROPA, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N0747-F0808-G0237',
    'name': 'EMPL.AYTO.CIUDAD RODRIGO',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N0868-F0808-G0237',
    'name': 'PATRONATO MUNICIPAL DE DEPORTES DE PALENCIA',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N2255-F0808-G0237',
    'name': 'DUERO PYMES, P.P.',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N2612-F0808-G0237',
    'name': 'ASAJA-EXTREMADURA',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N2644-F0808-G0237',
    'name': 'EMPL. AYTO. BEJAR',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3369-F0808-G0237',
    'name': 'DUERO INSTITUCIONES',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3674-F0808-G0237',
    'name': 'FERVASA',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4302-F0808-G0237',
    'name': 'PLAN DE PROM.CONJ.DUERO EMPLEO',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3324-F0808-G0237',
    'name': 'EMPL.AYTO.SORIA',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N5294-F0808-G0237',
    'name': 'ROYAL & SUN ALLIANCE INSURENCE SLC',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4392-F0808-G0237',
    'name': 'DIPUTACIÓN DE SALAMANCA',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3948-F0808-G0237',
    'name': 'AYTO. SALAMANCA, P.P',
    'fundDescription': 'FONDUERO ENTIDADES, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N0365-F0860-G0237',
    'name': 'EMPLEADOS CAJA DE AHORROS DE SALAMANCA Y SORIA, P.P.',
    'fundDescription': 'FONDUERO PERSONAL, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N3028-F0973-G0237',
    'name': 'DUERO TRANQUILIDAD, P.P.',
    'fundDescription': 'FONDUERO I, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4523-F1257-G0237',
    'name': 'DUERO PROTECCION TOTAL  P.P.',
    'fundDescription': 'FONDUERO II, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4622-F1723-G0237',
    'name': 'Duero II, PP',
    'fundDescription': 'FONDUERO III, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4640-F1724-G0237',
    'name': 'Duero Protección Total, III P.P.',
    'fundDescription': 'FONDUERO IV, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4710-F1780-G0237',
    'name': 'Duero Protección Total V,  P.P.',
    'fundDescription': 'FONDUERO VI, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4682-F1781-G0237',
    'name': 'Duero Objetivo 2023, P.P.',
    'fundDescription': 'FONDUERO V, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4709-F1782-G0237',
    'name': 'Duero Estabilidad II,  P.P.',
    'fundDescription': 'FONDUERO VII, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4795-F1866-G0237',
    'name': 'Duero Estabilidad III, P.P.',
    'fundDescription': 'FONDUERO VIII, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4850-F1867-G0237',
    'name': 'Duero Protección Total VIII, P.P.',
    'fundDescription': 'Fonduero IX, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4851-F1868-G0237',
    'name': 'Duero Protección Total IX, P.P.',
    'fundDescription': 'Fonduero X, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N4906-F1952-G0237',
    'name': 'Duero Protección Total X, P.P.',
    'fundDescription': 'Fonduero XI, F.P.',
    'managerId': 'G0237',
    'managerName': 'UNION DEL DUERO, COMPAÑÍA DE SEGUROS DE VIDA, SAU'
  },
  {
    'id': 'N5219-F1633-G0238',
    'name': 'Cobas Global, PP',
    'fundDescription': 'Cobas Global, FP',
    'managerId': 'G0238',
    'managerName': 'COBAS PENSIONES, SOCIEDAD GESTORA DE FONDOS DE PENSIONES, S. A.'
  },
  {
    'id': 'N5218-F2076-G0238',
    'name': 'Cobas Mixto Global, PP',
    'fundDescription': 'Cobas Mixto Global, FP',
    'managerId': 'G0238',
    'managerName': 'COBAS PENSIONES, SOCIEDAD GESTORA DE FONDOS DE PENSIONES, S. A.'
  },
  {
    'id': 'N5424-F2130-G0238',
    'name': 'Cobas Empleo, PP',
    'fundDescription': 'Cobas Empleo, FP',
    'managerId': 'G0238',
    'managerName': 'COBAS PENSIONES, SOCIEDAD GESTORA DE FONDOS DE PENSIONES, S. A.'
  },
  {
    'id': 'N5367-F2139-G0239',
    'name': 'Allianz Pensiones Global',
    'fundDescription': 'Allianz Pensiones Global',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N1274-F2139-G0239',
    'name': 'Allianz Futuro, PPI',
    'fundDescription': 'Allianz Pensiones Global',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N1499-F2139-G0239',
    'name': 'Allianz Horizonte 75, PPI',
    'fundDescription': 'Allianz Pensiones Global',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N3048-F2139-G0239',
    'name': 'Allianz Gestión Total, PPI',
    'fundDescription': 'Allianz Pensiones Global',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N4185-F2139-G0239',
    'name': 'Allianz Optima Decidido, PPI',
    'fundDescription': 'Allianz Pensiones Global',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5366-F2140-G0239',
    'name': 'Allianz Pensiones Moderado',
    'fundDescription': 'Allianz Pensiones Moderado',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N0046-F2140-G0239',
    'name': 'Allianz Vida, PPI',
    'fundDescription': 'Allianz Pensiones Moderado',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N0163-F2140-G0239',
    'name': 'Fenixpensión, PPI',
    'fundDescription': 'Allianz Pensiones Moderado',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N2530-F2140-G0239',
    'name': 'Allianz Optima Prudente, PPI',
    'fundDescription': 'Allianz Pensiones Moderado',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N4187-F2140-G0239',
    'name': 'Allianz Optima Moderado, PPI',
    'fundDescription': 'Allianz Pensiones Moderado',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N2516-F2141-G0239',
    'name': 'Plan de Pensiones empleados de Allianz',
    'fundDescription': 'Allianz Pensiones Empleo',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5200-F2141-G0239',
    'name': 'Plan de pensiones de empleo de Allianz Technology',
    'fundDescription': 'Allianz Pensiones Empleo',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5407-F2141-G0239',
    'name': 'Plan de Pensiones de empleo de BBVA Allianz Seguros y Reaseguros',
    'fundDescription': 'Allianz Pensiones Empleo',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5481 -F2141-G0239',
    'name': 'Plan de Pensiones de los Empleados de Allianz Soluciones de Inversión',
    'fundDescription': 'Allianz Pensiones Empleo',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N4561-F2141-G0239',
    'name': 'Plan de Pensiones de Empleo de AGCS, Sucursal en España',
    'fundDescription': 'Allianz Pensiones Empleo',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5370-F2148-G0239',
    'name': 'Allianz Pensiones Conservador',
    'fundDescription': 'Allianz Pensiones Conservador',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N1239-F2148-G0239',
    'name': 'Allianz Renta, PPI',
    'fundDescription': 'Allianz Pensiones Conservador',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N4242-F2148-G0239',
    'name': 'Allianz Capital, PPI',
    'fundDescription': 'Allianz Pensiones Conservador',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N4679-F2202-G0239',
    'name': 'Allianz USA',
    'fundDescription': 'Allianz Pensiones RV USA',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5491-F2202-G0239',
    'name': 'Allianz Pensiones RV USA',
    'fundDescription': 'Allianz Pensiones RV USA',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N1496-F2203-G0239',
    'name': 'Allianz Crecimiento 100',
    'fundDescription': 'Allianz Pensiones RV Europa',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N3710-F2203-G0239',
    'name': 'Allianz España',
    'fundDescription': 'Allianz Pensiones RV Europa',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N5490-F2203-G0239',
    'name': 'Allianz Pensiones RV Europa',
    'fundDescription': 'Allianz Pensiones RV Europa',
    'managerId': 'G0239',
    'managerName': 'ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.'
  },
  {
    'id': 'N0103-F0046-G0240',
    'name': 'santalucía VP Gestión Decidido P.P.',
    'fundDescription': 'SANTALUCIA FONDOGESTION',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4749-F0046-G0240',
    'name': 'PLV PLAN EVOLUCION',
    'fundDescription': 'SANTALUCIA FONDOGESTION',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4683-F0046-G0240',
    'name': 'Santalucia Gestión2',
    'fundDescription': 'SANTALUCIA FONDOGESTION',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0250-F0141-G0240',
    'name': 'santalucía VP Mixto Prudente P.P.',
    'fundDescription': 'SANTALUCIA FONDOMIXTO',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4750-F0141-G0240',
    'name': 'PLV PLAN ESTABLE',
    'fundDescription': 'SANTALUCIA FONDOMIXTO',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4544-F0358-G0240',
    'name': 'SANTALUCIA VIDA EMPLEADOS  RENTA VARIABLE, P.P.',
    'fundDescription': 'SANTALUCIA FONDO RENTAVARIABLE',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0784-F0358-G0240',
    'name': 'santalucía VP Renta Variable Europa P.P.',
    'fundDescription': 'SANTALUCIA FONDO RENTAVARIABLE',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4751-F0358-G0240',
    'name': 'PLV PLAN ACTIVO',
    'fundDescription': 'SANTALUCIA FONDO RENTAVARIABLE',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0786-F0360-G0240',
    'name': 'santalucía VP Renta Fija P.P.',
    'fundDescription': 'SANTALUCIA FONDO RENTAFIJA',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4545-F0360-G0240',
    'name': 'SANTALUCIA VIDA EMPLEADOS  RENTA FIJA, P.P.',
    'fundDescription': 'SANTALUCIA FONDO RENTAFIJA',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4870-F0360-G0240',
    'name': 'PELAYO VIDA RENTA FIJA, PP',
    'fundDescription': 'SANTALUCIA FONDO RENTAFIJA',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N0073-F0385-G0240',
    'name': 'LLOYDS PLAN DE PENSIONES',
    'fundDescription': 'PLUSFONDO 2, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3176-F0923-G0240',
    'name': 'PYME I PLAN DE PENSIONES',
    'fundDescription': 'Santalucia Pyme Fondoempleo',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2503-F0924-G0240',
    'name': 'COOPERATIVA FARMACEUTICA ASTURIANA (COFAS)',
    'fundDescription': 'Santalucia Fondoempleo',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N2504-F0924-G0240',
    'name': 'PLANEMPLEO CONJUNTO 1, Plan de Pensiones de Empleo',
    'fundDescription': 'Santalucia Fondoempleo',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N3883-F0924-G0240',
    'name': 'PLAN DE PENSIONES DE EMPLEO DE PLUS ULTRA SEGUROS',
    'fundDescription': 'Santalucia Fondoempleo',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4121-F1461-G0240',
    'name': 'santalucía VP Retorno Absoluto P.P.',
    'fundDescription': 'SANTALUCIA FONDO RETORNO ABSOLUTO',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4597-F1706-G0240',
    'name': 'santalucía VP Espabolsa P.P.',
    'fundDescription': 'SANTALUCIA FONDO ESPABOLSA',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4991-F1706-G0240',
    'name': 'PELAYO VIDA PLAN ESPABOLSA',
    'fundDescription': 'SANTALUCIA FONDO ESPABOLSA',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4719-F1708-G0240',
    'name': 'FUTURO, P.P.',
    'fundDescription': 'SANTALUCÍA FONDO I, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5345-F1708-G0240',
    'name': 'SANTALUCIA VP MUNDIGLOBAL EURO',
    'fundDescription': 'SANTALUCÍA FONDO I, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4988-F1991-G0240',
    'name': 'SANTALUCIA POLAR EQUILIBRADO PP',
    'fundDescription': 'SANTALUCIA POLAR EQUILIBRADO, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5032-F2014-G0240',
    'name': 'SANTALUCIA PARDO DECIDIDO PP',
    'fundDescription': 'SANTALUCIA PARDO DECIDIDO, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N4503-F2016-G0240',
    'name': 'SANTALUCIA PANDA PRUDENTE, PLAN DE PENSIONES',
    'fundDescription': 'SANTALUCIA PANDA PRUDENTE, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5386-F2142-G0240',
    'name': 'Santalucía VP Objetivo Jubilación 2035',
    'fundDescription': 'SANTALUCIA FONDO V, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5419-F2142-G0240',
    'name': 'Santalucía Tu Plan Más Personal 2035',
    'fundDescription': 'SANTALUCIA FONDO V, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5388-F2143-G0240',
    'name': 'Santalucía VP Objetivo Jubilación 2055',
    'fundDescription': 'SANTALUCIA FONDO VII, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5421-F2143-G0240',
    'name': 'Santalucía Tu Plan Más Personal 2055',
    'fundDescription': 'SANTALUCIA FONDO VII, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5389-F2144-G0240',
    'name': 'Santalucia VP RV USA Élite',
    'fundDescription': 'SANTALUCIA FONDO IX, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5426-F2145-G0240',
    'name': 'Santalucia VP Gestión Sostenible RVM, PP',
    'fundDescription': 'SANTA LUCIA FONDO X, F.P',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5384-F2146-G0240',
    'name': 'Santalucía VP Gestión Estable',
    'fundDescription': 'SANTALUCIA FONDO III, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5417-F2146-G0240',
    'name': 'Santalucía Gestión Estable',
    'fundDescription': 'SANTALUCIA FONDO III, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5390-F2147-G0240',
    'name': 'Santalucia VP RV Europa Élite',
    'fundDescription': 'SANTALUCIA FONDO VIII, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5385-F2151-G0240',
    'name': 'Santalucía VP Objetivo Jubilación 2025',
    'fundDescription': 'SANTALUCIA FONDO IV, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5418-F2151-G0240',
    'name': 'Santalucía Tu Plan Más Personal 2025',
    'fundDescription': 'SANTALUCIA FONDO IV, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5387-F2152-G0240',
    'name': 'Santalucía VP Objetivo Jubilación 2045',
    'fundDescription': 'SANTALUCIA FONDO VI, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  },
  {
    'id': 'N5420-F2152-G0240',
    'name': 'Santalucía Tu Plan Más Personal 2045',
    'fundDescription': 'SANTALUCIA FONDO VI, FONDO DE PENSIONES',
    'managerId': 'G0240',
    'managerName': 'SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS'
  }
];