import React, { Component } from 'react';
import Tab from '@material-ui/core/Tab';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Utils } from '../../src-core/resources/index';
import moment from 'moment';
import MyTabsWithState from '../../components/MyTabsWithState';

/**
 * Tabs de los tipos de Objetivos
 */
class TargetsTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    this.setState({
      current: this.props.currentTab,
    });
  }

  handleClick(route, current, event) {
    event.preventDefault();
    this.setState({
      current: this.props.tabs.map(tab => tab.filter).indexOf(current),
    });
    console.log(
      'something: ',
      this.props.tabs.map(tab => tab.filter).indexOf(current),
    );
    this.props.history.replace(route);
    if (this.props.updateParent !== undefined) {
      this.props.updateParent(
        this.props.tabs.map(tab => tab.filter).indexOf(current),
      );
    }
  }

  renderTabs() {
    const { config, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const tabs = this.props.tabs.map(tab => {
      return (
        <Tab
          {...config.tab}
          label={tab.label}
          key={tab.filter}
          icon={tab.icon}
          onClick={e => {
            const todayDate = moment().unix();
            const dateStart = moment('2022-01-01', 'YYYY-MM-DD').unix();
            const dateEnd = moment('2022-01-08', 'YYYY-MM-DD').unix();
            console.log({ dateEnd });
            if (
              tab.filter === 'reembolso' &&
              (this.props.type === 'familyTarget' ||
                this.props.type === 'nextWish') &&
              todayDate > dateStart &&
              todayDate < dateEnd
            ) {
              this.modalOpen();
              return;
            }
            console.log({ tab: tab.filter, props: this.props });
            this.handleClick(this.props.base + tab.filter, tab.filter, e);
          }}
          classes={transformClasses('targetsTabs_tab1', classes)}
          // style={{ minWidth: 'none !important', maxWidth: '20%' }}
        />
      );
    });
    return tabs;
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  modalOpen(modalMessage) {
    this.setState({
      modalOpen: true,
    });
  }

  render() {
    const { config, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const configTabs = {
      ...config.tabs,
      children: this.renderTabs(),
    };
    return (
      <>
        <MyTabsWithState
          id='tabs'
          {...configTabs}
          // value={this.props.currentTab}
          current={this.props.currentTab}
          classes={transformClasses('targetsTabs_tabs1', classes)}
        />
        <Modal
          open={this.state.modalOpen}
          style={{
            //height: 'min-content',
            backgroundColor: '#ffffffb8',
            borderRadius: 5,
            padding: 15,
            //top: '30%',
            //margin: 15,
          }}
          onClose={this.modalClose}
        >
          <div
            style={{
              height: 'auto',
              backgroundColor: 'white',
              borderRadius: 5,
              padding: 15,
              //top: '30%',
              display: 'flex',
              flexFlow: 'column',
              margin: 15,
              marginTop: '50%',
            }}
          >
            <Typography style={{ color: '#353535', textAlign: 'center' }}>
              CASER va a realizar una migración tecnológica que durará desde el
              1 de enero hasta el 7 de enero.
            </Typography>
            <div style={{ height: 10 }} />
            <Typography style={{ color: '#353535', textAlign: 'center' }}>
              Por ello, durante ese tiempo, los reembolsos (al ser un producto
              ofrecido por CASER) se abonarán en tu cuenta a partir del 10 de
              enero. El resto de la operativa seguirá disponible como hasta
              ahora.
            </Typography>
            <Button
              style={{
                backgroundColor: '#f4137b',
                color: 'white',

                borderColor: '#9E9E9E',
                borderStyle: 'solid',
                borderWidth: 1,
                float: 'right',
                margin: 15,
              }}
              onClick={() => {
                if (this.state.modalOnCloseBack) {
                  this.props.history.goBack();
                }
                this.setState({ modalOpen: false });
              }}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default TargetsTabs;
