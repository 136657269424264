import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Input from '@material-ui/core/Input';
import { targesRefundInfo_strings } from './text';
import Grid from '@material-ui/core/Grid';
import { Utils } from '../../../resources/index';
import { TargetsAPI } from '../../../sdk/index';
import Modal from '@material-ui/core/Modal';
import SvgIcon from '@material-ui/core/SvgIcon';

const types = ['partial', 'total'];
const status = [
  'AVAILABLE',
  'AMOUNT_NOT_ENOUGHT',
  'BLOCKED',
  'INITIAL_CARENCY',
  'REFUND_CARENCY',
  'ERROR',
  'NOT_SUPPORTED',
];

class TargetRefundInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      open: false,
      open2: false,
      type: 'partial',
      message: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  handleRefund(type) {
    if (type === types[0]) {
      if (
        this.state.value < this.props.info.partial.minAmount ||
        this.state.value > this.props.info.partial.maxAmount
      ) {
        this.handleOpen(
          'valor incorrecto, debe estar comprendido entre ' +
            Utils.formatCurrency(this.props.info.partial.minAmount) +
            ' y ' +
            Utils.formatCurrency(this.props.info.partial.maxAmount) +
            '',
        );
      } else {
        this.refund(type);
      }
    } else {
      this.refund(type);
    }
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleOpen(message) {
    this.setState({
      open: true,
      message: message,
    });
  }

  refund(type) {
    const { baseUrl, id, token } = this.props;
    const data = {
      payload: {
        ammount: type === types[0] ? this.state.value : 0,
        type: type.toUpperCase(),
      },
    };
    TargetsAPI.refund(baseUrl, id, data, token)
      .then(response => {
        if (response.status === 200) {
          this.handleOpen(
            targesRefundInfo_strings['es'].targesRefundInfo_info_text1,
          );
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error durante el refund ', error);
      });
  }

  renderAvailableFields() {
    const { classes, config, info, moneyAmounts } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <Grid
        {...config.gridContainer1}
        classes={transformClasses('targetRefundInfo_gridContainer1', classes)}
      >
        <div className={classes.targetRefundInfo_infoMoney_container}>
          <Grid
            {...config.gridItemContainer1}
            classes={transformClasses(
              'targetRefundInfo_gridItemContainer1',
              classes,
            )}
          >
            <Grid
              {...config.gridItem1}
              classes={transformClasses('targetRefundInfo_gridItem1', classes)}
            >
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'targetRefundInfo_typography1',
                  classes,
                )}
              >
                {targesRefundInfo_strings['es'].targesRefundInfo_info_text2}
              </Typography>
            </Grid>
            <Grid
              {...config.gridItem2}
              classes={transformClasses('targetRefundInfo_gridItem2', classes)}
            >
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'targetRefundInfo_typography2',
                  classes,
                )}
              >
                {Utils.formatCurrency(moneyAmounts.saved)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            {...config.gridItemContainer2}
            classes={transformClasses(
              'targetRefundInfo_gridItemContainer2',
              classes,
            )}
          >
            <Grid
              {...config.gridItem3}
              classes={transformClasses('targetRefundInfo_gridItem3', classes)}
            >
              <Typography
                {...config.typography3}
                classes={transformClasses(
                  'targetRefundInfo_typography3',
                  classes,
                )}
              >
                {targesRefundInfo_strings['es'].targesRefundInfo_info_text3}
              </Typography>
            </Grid>
            <Grid
              {...config.gridItem4}
              classes={transformClasses('targetRefundInfo_gridItem4', classes)}
            >
              <Typography
                {...config.typography4}
                classes={transformClasses(
                  'targetRefundInfo_typography4',
                  classes,
                )}
              >
                {this.props.info.partial.status === status[0]
                  ? Utils.formatCurrency(info.partial.maxAmount)
                  : Utils.formatCurrency(0)}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid
          {...config.gridItem5}
          classes={transformClasses('targetRefundInfo_gridItem5', classes)}
        >
          <Typography
            {...config.typography5}
            classes={transformClasses('targetRefundInfo_typography5', classes)}
          >
            {targesRefundInfo_strings['es'].targesRefundInfo_info_text4}
          </Typography>
        </Grid>
        <div className={classes.targetInfo_body_targetSelector_container}>
          <div
            className={classes.targetInfo_body_targetSelector_leftIco_container}
          >
            <SvgIcon
              color='primary'
              classes={transformClasses(
                'targetInfo_body_targetSelector_leftIco_svgIcon',
                classes,
              )}
              viewBox='0 0 28 28'
            >
              <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
            </SvgIcon>
          </div>
          <div
            className={classes.targetInfo_body_targetSelector_info_container}
          >
            <div
              className={classes.targetInfo_body_targetSelector_info_firstRow}
            >
              <Typography
                variant='body1'
                color='secondary'
                classes={transformClasses(
                  'targetInfo_body_targetSelector_info_firstRow_typography',
                  classes,
                )}
              >
                IMPORTE
              </Typography>
            </div>
            <div
              className={classes.targetInfo_body_targetSelector_info_secondRow}
            >
              <Input
                disableUnderline
                value={this.state.value}
                placeholder='0,00 €'
                onChange={this.handleChange}
                classes={transformClasses(
                  'targetInfo_body_targetSelector_info_secondRow_input',
                  classes,
                )}
              />
            </div>
          </div>
        </div>
        <Grid
          {...config.gridItemContainer3}
          classes={transformClasses(
            'targetRefundInfo_gridItemContainer3',
            classes,
          )}
        >
          <Grid
            {...config.gridItem7}
            classes={transformClasses('targetRefundInfo_gridItem7', classes)}
          >
            <SvgIcon color='secondary'>
              <path d='M 12 24 C 5.373 24 0 18.627 0 12 C 0 5.373 5.373 0 12 0 C 18.627 0 24 5.373 24 12 C 24 18.627 18.627 24 12 24 Z M 12 22.286 C 17.681 22.286 22.286 17.681 22.286 12 C 22.286 6.319 17.681 1.714 12 1.714 C 6.319 1.714 1.714 6.319 1.714 12 C 1.714 17.681 6.319 22.286 12 22.286 Z M 11.143 9.429 L 12.857 9.429 L 12.857 18.857 L 11.143 18.857 L 11.143 9.429 Z M 12 5.143 C 12.71 5.143 13.286 5.718 13.286 6.429 C 13.286 7.139 12.71 7.714 12 7.714 C 11.29 7.714 10.714 7.139 10.714 6.429 C 10.714 5.718 11.29 5.143 12 5.143 Z' />
            </SvgIcon>
          </Grid>
          <Grid
            {...config.gridItem8}
            classes={transformClasses('targetRefundInfo_gridItem8', classes)}
          >
            <Typography
              {...config.typography7}
              classes={transformClasses(
                'targetRefundInfo_typography7',
                classes,
              )}
            >
              {targesRefundInfo_strings['es'].targesRefundInfo_info_text5}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, config, info } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.mainContainer}>
        {this.renderAvailableFields()}
        {this.props.index === 0 ? (
          <Button
            {...config.button2}
            classes={transformClasses('targetRefundInfo_button2', classes)}
            onClick={
              info.partial.status === status[0]
                ? () => this.handleRefund('partial')
                : () => this.handleOpen(info.partial.message)
            }
          >
            {info.partial.status === status[0]
              ? targesRefundInfo_strings['es'].targesRefundInfo_button_text3
              : targesRefundInfo_strings['es'].targesRefundInfo_button_text4}
          </Button>
        ) : (
          <Button
            {...config.button1}
            classes={transformClasses('targetRefundInfo_button1', classes)}
            onClick={
              info.total.status === status[0]
                ? () => this.handleRefund('total')
                : () => this.handleOpen(info.total.message)
            }
          >
            {info.total.status === status[0]
              ? targesRefundInfo_strings['es'].targesRefundInfo_button_text1
              : targesRefundInfo_strings['es'].targesRefundInfo_button_text2}
          </Button>
        )}

        <Modal
          {...config.modal1}
          open={this.state.open}
          classes={transformClasses('targetRefundInfo_modal1', classes)}
          onClose={this.handleClose}
        >
          <div className={classes.container2}>
            <Typography
              {...config.typographyModal1}
              classes={transformClasses(
                'targetRefunInfo_typographyModal1',
                classes,
              )}
            >
              {this.state.message}
            </Typography>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default TargetRefundInfo;
