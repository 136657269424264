import React, { useState } from 'react';
import colors from '../../config/colors';
import Icon from '../../components/MyIcon';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Modal from '@material-ui/core/Modal';

import * as S from './followupStyles';
import { ModalInfoStatus } from '../../src-core/targets/react/listTargetMovements/listTargetMovements.component';
import Flow from '../../components/Flow';
import { getCurrency } from '../../libs/Currency';
export const FollowupTotalDeposit = () => {
  const { totalDeposits } = Flow.getParams();

  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <S.PensionTransferWrapper>
        <S.Container>
          <LiveHelpIcon style={{ color: colors.pink }} />
          <S.TextContainer>
            <S.Text>
              Aportado este año: {getCurrency(totalDeposits || '0', 2)}
            </S.Text>
            <S.Text>
              Puedes aportar aún{' '}
              <S.Span>
                {getCurrency(
                  1500 - +(totalDeposits || '0') < 0
                    ? 0
                    : 1500 - +(totalDeposits || '0'),
                  2,
                )}
              </S.Span>
            </S.Text>
          </S.TextContainer>
        </S.Container>
        <div onClick={() => setOpenModal(true)}>
          <Icon
            name='info'
            style={{
              width: 15,
            }}
          />
        </div>
      </S.PensionTransferWrapper>

      <ModalInfoStatus open={openModal} onClose={() => setOpenModal(false)}>
        <p>
          (i) basado en los cálculos realizados en base al límite legal de
          aportaciones en tus planes de pensiones
        </p>
      </ModalInfoStatus>
    </>
  );
};
