import React, { Component, Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

//import { TargetsAPI } from '../src-core/sdk/index';
import { TargetsAPI } from '../../src-core/sdk/index';
//import { TargetsAPI } from '../../src-core/sdk/index';

// import {
// 	ListDocuments,
// 	ListDocumentsStyles,
// 	ListDocumentsConfig,
// } from '../../core/componets/targets';
import ListDocuments from '../listDocuments/listDocuments.component';
import ListDocumentsStyles from '../listDocuments/listDocuments.style';
import ListDocumentsConfig from '../listDocuments/listDocuments.config';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
// import DocView from '../../components/MyDocView';
// import Icons from '../../components/MyIcon.config';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location.pathname,
      documents: null,
      targets: null,
      showIframe: false,
      iframeUrl: null,
      types: null,
    };
    console.log('Documentsprops', this.state);
  }

  handleFrameTasks(event) {
    if (event.data === 'getToDaChoppa!') {
      this.setState({
        showIframe: false,
        iframeUrl: null,
      });
    }
  }

  goChat() {
    this.props.history.push({
      pathname: '/chatSupport',
      state: {
        back: this.props.location.pathname,
      },
    });
  }

  async componentDidMount() {
    window.addEventListener('message', this.handleFrameTasks.bind(this), false);
    let targetsDocumentsMap = {};

    const targetsResponse = await TargetsAPI.userTargets(
      this.props.baseUrl,
      'me',
      this.props.token,
      true,
    );

    if (targetsResponse.status !== 200) {
      throw targetsResponse.status;
    }

    const targets = await targetsResponse.json();

    this.setState({
      targets,
    });

    const documentsArrays = await Promise.all(
      targets.map(async target => {
        const documentsResponse = await TargetsAPI.getDocuments(
          this.props.baseUrl,
          target._id,
          this.props.token,
        );

        if (documentsResponse.status !== 200) {
          throw documentsResponse.status;
        }

        return documentsResponse.json();
      }),
    );

    targets.forEach((target, i) => {
      targetsDocumentsMap[target._id] = documentsArrays[i];
    });

    const typesResponse = await TargetsAPI.getTypes(
      this.props.baseUrl,
      'me',
      this.props.token,
    );

    if (typesResponse.status !== 200) {
      throw typesResponse.status;
    }

    const typesData = await typesResponse.json();
    let types = {};
    typesData.map(type => {
      types[type.identifier] = type.name;
      return null;
    });

    this.setState({
      documents: targetsDocumentsMap,
      types,
    });
  }

  sign(url) {
    this.setState({
      showIframe: true,
      iframeUrl: url,
    });
  }

  goBack() {
    if (this.state.showIframe) {
      this.setState({
        showIframe: false,
        iframeUrl: '',
      });
    } else {
      this.props.history.goBack();
    }
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.documents_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Header
          title={'Mis contratos'}
          modal={true}
          history={this.props.history}
        />

        {this.state.targets !== null &&
          this.state.documents !== null &&
          this.state.types !== null ? (
          !this.state.showIframe ? (
            <Body vAlign='top'>
              <ListDocuments
                config={ListDocumentsConfig}
                targets={this.state.targets}
                documents={this.state.documents}
                classes={classes}
                types={this.state.types}
                sign={this.sign.bind(this)}
                baseUrl={this.props.baseUrl}
                location={this.state.location}
              />
            </Body>
          ) : (
            /* 
              Esto es para el DocuSign tiene que ir con el iframe, 
              no cambiarlo por DocView sino no funciona 
            */
            <iframe
              title='doc'
              src={this.state.iframeUrl}
              className={classes.iframe}
            />
          )
        ) : (
          this.renderLoading()
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...ListDocumentsStyles(theme),
  documents_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
  },
  contentContainer: {
    height: '90vh',
    minHeight: 511,
    paddingLeft: 15,
    paddingRight: 15,
    overflow: 'auto',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  header_centerIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cargos_headerTypography1_root: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cargos_headerIconButton2_root: {
    padding: 'unset',
  },
  iframe: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
    frameBorder: 0,
  },
  listDocuments_typography2_root: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  listDocuments_typography3_root: {
    fontSize: 24,
    fontWeight: 100,
    color: theme.palette.primary.main,
  },
  listDocuments_typography4_root: {
    fontSize: 11,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    flexBasis: '100%',
    textTransform: 'uppercase',
  },
  listDocuments_typography5_root: {
    fontSize: '16px!important',
    textDecoration: 'none!important',
    color: theme.palette.primary.main + '!important',
  },
  listDocuments_typography7_root: {
    fontSize: '12px!important',
    textDecoration: 'none!important',
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    color: theme.palette.primary.main + '!important',
  },
  listDocuments_link: {
    fontSize: '12px!important',
    textDecoration: 'none!important',
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    color: theme.palette.primary.main + '!important',
    fontFamily: ['Calibre'],
  },
  listDocuments_typography6_root: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  docIconContainer: {
    paddingRight: 15,
  },
  listDocuments_iconButton2_root: {
    color: theme.palette.primary.main,
    paddingRight: 0,
    paddingLeft: 0,
  },
  listDocuments_listItem2_root: {
    ...ListDocumentsStyles(theme).listDocuments_listItem2_root,
    width: '90%',
    paddingLeft: 0,
    paddingRight: 2,
    paddingTop: 5,
    paddingBottom: 5,
  },
  listDocuments_list2_root: {
    ...ListDocumentsStyles(theme).listDocuments_list2_root,
    paddingTop: 0,
  },
  docTextContainer: {
    ...ListDocumentsStyles(theme).docTextContainer,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default withStyles(styles, { withTheme: true })(Documents);
