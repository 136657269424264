export const initialState = {
  simulation: {
    started: false,
    age: null,
    contribution: null,
    typeEmployment: null,
    grossIncome: null,
    savings: null,
    profileInvestor: null,
    total: null,
    year: '',
    yearlyContribution: null,
    monthlyContribution: null,
    lifeLevelPercentage: 100,
  },
  manual: {
    started: false,
    age: null,
    contribution: null,
    typeEmployment: null,
    grossIncome: null,
    savings: null,
    profileInvestor: null,
    total: null,
    yearlyContribution: null,
    monthlyContribution: null,
    numberOfYears: null,
  },
  pensionPlan: null,
  retirementAge: 67,
};
