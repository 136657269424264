import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { withStyles } from '@material-ui/core/styles';

// import {
//   ListRuleMovements,
//   ListRuleMovementsConfig,
//   ListRuleMovementsStyles
// } from '../../core/
import { ListRuleMovements } from '../../src-core/rules/index';
import { ListRuleMovementsConfig } from '../../src-core/rules/index';
import { ListRuleMovementsStyles } from '../../src-core/rules/index';

import { followupTarget_strings } from './text';
//import { RulesAPI } from '../../core/
import { RulesAPI } from '../../src-core/sdk/index';
//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

const styles = theme => ({
  ...ListRuleMovementsStyles(theme),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
  followupMovementsRules_button_root: {
    padding: 'unset',
    color: theme.palette.primary.main,
  },
  followupMovementsRules_headerContainer: {
    display: 'flex',
    height: '10vh',
    minHeight: 57,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  followup_headerTitle_root: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: -6,
  },
  listRuleMovements_iconButton1_root: {
    padding: 'unset',
  },
  followupMovements_headerContainer: {
    display: 'flex',
    height: '10vh',
    minHeight: 57,
    padding: 12,
    alignItems: 'center',
  },
  listRuleMovements_typography4_root: {
    padding: 12,
    fontSize: 12,
    fontWeight: 400,
    marginBottom: -6,
  },
  listRuleMovements_typography1_root: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: -6,
  },
  listRuleMovements_typography2_root: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: -6,
    textTransform: 'lowercase',
  },
  listRuleMovements_typography3_root: {
    fontSize: 24,
    fontWeight: 200,
    marginBottom: -6,
  },
  listRuleMovements_listItem1_root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
  movements_confe_container: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  movements_concepto_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movements_fecha_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movements_ammount_container: {
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  movements_icon_container: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class FollowupRulesMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: null,
    };
  }

  componentDidMount() {
    this.getMovements(
      this.props.baseUrl,
      this.props.match.params.idRule,
      this.props.token,
    );
  }

  getMovements(baseUrl, id, token) {
    RulesAPI.getRuleMovements(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('FollowupRulesMovements.getRuleMov', data);
        this.setState({
          movements: data,
        });
      })
      .catch(error => {
        console.log('error recuperando los movimientos de regla ', error);
      });
  }

  goBack() {
    this.props.history.push(
      '/followup/' + this.props.match.params.id + '/Reglas',
    );
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div>
        <div className={classes.followupMovementsRules_headerContainer}>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('followup_headerTitle', classes)}
          >
            {followupTarget_strings['es'].followupRulesMovements_header_text}
          </Typography>
          <IconButton
            classes={transformClasses('followupMovementsRules_button', classes)}
            onClick={this.goBack.bind(this)}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </div>
        {this.state.movements !== null ? (
          <ListRuleMovements
            classes={classes}
            config={ListRuleMovementsConfig}
            movements={this.state.movements}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FollowupRulesMovements);
