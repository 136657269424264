import React, { useState, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import Footer from '../../../components/MyFooter';
import Flow from '../../../components/Flow';
import SwipeableViews from 'react-swipeable-views';
import { chartColors } from '../../../components/MyChartJS';
import MyChartJS from '../../../components/MyChartJS';
import MobileStepper from '@material-ui/core/MobileStepper';

// const dataMockUp = {};

function SimulatorPensionPlanResult(props) {
  const [tabIndex, setTabIndex] = useState(0);

  const myDivStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '0px solid #fff',
    borderRadius: 7,
    width: '95%',
    height: '100%',
    color: '#fff',
    fontSize: '1rem',
    textAlign: 'left',
    padding: '1rem',
  };

  //[data] = transformDataFromService({ dataMockUp });
  //const data =

  return (
    <Fragment>
      <Header
        title='Perfil de inversor'
        rigthAction={() => {
          props.history.goBack();
        }}
      />
      <Body>
        <SwipeableViews
          index={tabIndex}
          onChangeIndex={index => {
            setTabIndex(index);
          }}
          style={{
            width: '100%',
            height: '100%',
            minHeight: '350px',
            padding: '0px 15px',
          }}
        >
          {/* Panel 1 ******************************************/}
          <div id='ds4' style={myDivStyle}>
            <div
              style={{
                color: '#fff',
                fontSize: 14,
                margin: '4rem 0 0 0',
                border: '2px solid #fff',
                padding: '0.5rem',
                lineHeight: 1.5,
                textAlign: 'justify',
              }}
            >
              Si quieres tener el 100% en tu nivel de vida debes aportar{' '}
              <span style={{ color: '#f4137b' }}>1234€</span> al año con lo que
              dispondrás de un capital de{' '}
              <span style={{ color: '#f4137b' }}>123.456€</span> que te
              permitirán que tus ingresos sean de{' '}
              <span style={{ color: '#f4137b' }}>35.475 €/año</span>
              <Button
                id='recalcularPension'
                variant='contained'
                color='secondary'
                style={{
                  display: 'block',
                  marginTop: 15,
                  width: '100%',
                }}
                //classes={transformClasses('targetProducts_button1', classes)}
                onClick={() => {
                  Flow.navigate(
                    '/product/simulatorPensionPlanInput',
                    props,
                    {},
                  );
                }}
              >
                Recalcular Pension
              </Button>
            </div>
          </div>

          {/* Panel 2 ******************************************/}
          <div id='ds5' style={myDivStyle}>
            <MyChartJS
              type='bar'
              data={{
                labels: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
                datasets: [
                  {
                    label: 'Ingresos netos',
                    backgroundColor: chartColors.trasparentWhite,
                    data: [0, 22, 0, 0, 0, 0, 0],
                  },
                  {
                    label: 'Gastos',
                    backgroundColor: chartColors.pink,
                    data: [0, 0, 18, 0, 0, 35, 0],
                  },
                  {
                    label: 'Capacidad de ahorro',
                    backgroundColor: chartColors.clearPink,
                    data: [0, 0, 4, 0, 0, 0, 0],
                  },
                  {
                    label: 'Seguridad social',
                    backgroundColor: chartColors.grey,
                    data: [0, 0, 0, 0, 25, 0, 0],
                  },
                  {
                    label: 'Capital ahorrado (EVO)',
                    backgroundColor: chartColors.green,
                    data: [0, 0, 0, 0, 10, 0, 0],
                  },
                ],
              }}
              options={{
                legend: {
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    fontColor: '#fafafa',
                    boxWidth: 5,
                    fontSize: 9,
                  },
                },
                title: {
                  display: false,
                  // text: 'Chart.js Bar Chart - Stacked',
                },
                tooltips: {
                  mode: 'index',
                  intersect: false,
                },
                responsive: true,
                scales: {
                  pointLabels: {},
                  point: { radius: 1 },
                  xAxes: [
                    {
                      stacked: true,
                      scaleLabel: {
                        display: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      scaleLabel: {
                        display: false,
                      },
                      ticks: {
                        display: true,
                      },
                    },
                  ],
                },
              }}
              height={300}
            />

            <Button
              id='recalcularPension'
              variant='contained'
              color='secondary'
              style={{
                display: 'block',
                marginTop: 15,
                width: '100%',
              }}
              //classes={transformClasses('targetProducts_button1', classes)}
              onClick={() => {
                Flow.navigate('/product/simulatorPensionPlanInput', props, {});
              }}
            >
              Recalcular Pensión
            </Button>
          </div>
        </SwipeableViews>
        <MobileStepper
          variant='dots'
          steps={2}
          position='static'
          style={{
            background: 'transparent',
          }}
          activeStep={tabIndex}
        />
      </Body>

      <Footer>
        <Button
          variant='contained'
          color='secondary'
          style={{
            margin: 10,
          }}
          onClick={() => {
            if (tabIndex === 0) {
              setTabIndex(1);
            } else {
              Flow.navigate('/create/target/retirement/products', props, {});
            }
          }}
        >
          Siguiente
        </Button>
      </Footer>
    </Fragment>
  );
}

const styles = theme => ({
  modal: {
    width: '312px',
    backgroundColor: 'rgba(65,65,65,1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px',
    borderRadius: '8px',
    '&:focus': {
      outline: 'unset',
    },
  },

  inputNumberStyle: {
    color: 'white',
    padding: '6px 0 1px',
    borderBottomColor: 'white',
    borderBottomWidth: 1,
    borderBottomStyle: 'double',
    textAlign: 'right',
  },
  //...ProductsStyles(theme),
  // ...SwipeableViewsConfigurableStyles(theme),
});

export default withStyles(styles, { withTheme: true })(
  SimulatorPensionPlanResult,
);

/*
var barChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Dataset 1',
      backgroundColor: window.chartColors.red,
      data: [
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
      ],
    },
    {
      label: 'Dataset 2',
      backgroundColor: window.chartColors.blue,
      data: [
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
      ],
    },
    {
      label: 'Dataset 3',
      backgroundColor: window.chartColors.green,
      data: [
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
        randomScalingFactor(),
      ],
    },
  ],
};
window.onload = function() {
  var ctx = document.getElementById('canvas').getContext('2d');
  window.myBar = new Chart(ctx, {
    type: 'bar',
    data: barChartData,
    options: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    },
  });
};
*/
