const config = {
    typography1:{
      variant:'body1',
      //color:'primary'
      color: 'secondary',
      fontSize: 45
    },
    typography2:{
      variant: 'body1',
      color:'primary'
    },
    collapse1:{
      unmountOnExit: true
    },
    textField1:{
      color: 'primary'
    },
    button1:{
      variant: 'contained',
      color: 'secondary'
    }
  }
  
  export { config as RuleInputConfig }
  