function generateStyles(theme) {
  const styles = {
    targetRulesList_img1: {
      maxWidth: '100%'
    },
    targetRulesList_listContainer: {
      border: '3px solid #fafafa',
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 10
    },
    targetRulesList_listItem1_root: {
      padding: 0
    },
    targetRulesList_typography1_root: {
      fontWeight: 600,
      display: 'inline-block'
    },
    
    targetRulesList_typography2_root: {
      color: 'white'
    },
    targetRulesList_iconButton1_root: {
      fontSize: '3em',
      fontWeight: 600,
      display: 'inline-block'
    },
    targetRulesList_imgDiv: {
      position: 'relative',
      width: '100vw',
      height: '25vh',
      minHeight: 142,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
    targetRulesList_divText: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: 15,
      paddingTop: 10,
      paddingRight: 10
    },
    targetRulesList_card1_root: {
        borderRadius: 'unset'
    },
    targetRulesList_cardContent1_root: {
      textAlign: 'justify'
    },
    targetRulesList_button1_root: {
      width: '100%',
      borderRadius: 'unset',
      margin: 'unset',
      fontWeight: 600
    },
    targetRulesList_cardActions1_root:{
        padding: 'unset'
    }
  }
  return styles
}

export { generateStyles as TargetRulesListStyles }
