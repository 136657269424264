const TargetInitialContributionConfig = {
  typography1: {
    variant: 'body1',
    color: 'default',
  },
  textField1: {
    label: 'name',
  },
  button1: {
    variant: 'contained',
    color: 'secondary',
  },
  button2: {
    variant: 'contained',
    color: 'primary',
  },
};

export default TargetInitialContributionConfig;
