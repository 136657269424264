import React, {
  Component,
  //  , Fragment
} from 'react';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { Typography, SvgIcon } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MyText from '../../components/MyText';
import TargetInfo from '../targetInfo/targetInfo.component';
import TargetInfoConfig from '../targetInfo/targetInfo.config';
import TargetInfoStyles from '../targetInfo/targetInfo.style';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';

import { Utils } from '../../src-core/resources/index';

import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import MyIcon from '../../components/MyIcon';
import CajaFuerte from '../common/svgs/CajaFuerte';
import { createTarget_strings } from './text';

import routes from '../../config/routes';
import Flow from '../../components/Flow';

import { Button as CoreButton, View } from 'coinscrap-webapp-core';

import { StoreContext } from '../../pensionStore/Store';
import * as Types from '../../pensionStore/types';

/*
 ATENCION, Carga cosas del Core>TargetInfo y cosas de aqui
 */
class CreateTargetBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // porps.temporary son los datos del target q tenia guardado
      // q el usuario a decidido recuperar
      // Para que no cargue el nombre por defecto q trae el servicio.
      name:
        this.props.temporary.name !== 'Meta familiar' &&
        this.props.temporary.name !== 'Un capricho' &&
        this.props.temporary.name !== 'Jubilación'
          ? this.props.temporary.name
          : '',
      image: props.temporary.image,
      showImages: false,
      queryImages: '',
      images: [],
      openBuscarFotos: false,
      infoSet: false,
      configSet: false,
      openError: false,
      modalErrorMessage: null,
      formIsOk: false,
    };
    Object.keys(this.props.temporaryConfiguration).map(key => {
      this.state[key] =
        this.props.temporaryConfiguration[key] > 0
          ? this.props.temporaryConfiguration[key]
          : '';
      return null;
    });

    console.log('CreateTargetBasic constructor', {
      props: this.props,
      state: this.state,
    });
  }
  static contextType = StoreContext;

  setFormIsOk(value) {
    console.log('setFormIsOk', value);
    this.setState({
      formIsOk: value,
    });
  }

  closeModal() {
    this.setState({ openBuscarFotos: false });
  }

  openBuscarFotosModal() {
    console.log('openBuscarFotosModal', this.refs);
    this.setState({
      openBuscarFotos: true,
      queryImages: this.refs.targetInfo.getName(),
    });
    this.getListImages(this.refs.targetInfo.getName(), 0);
  }

  closeError(action) {
    this.setState({ openError: false });
  }

  openError(message) {
    this.setState({ openError: true, modalErrorMessage: message });
  }

  getImagePage(index) {
    fetch(
      'https://pixabay.com/api/?key=10465050-661faffb220a43b9f64d560a2&q=' +
        this.state.queryImages +
        '&orientation=horizontal',
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          images: data.hits,
        });
      });
  }

  renderImages() {
    const { classes } = this.props;
    const images = this.state.images.map(image => {
      return (
        <View key={image.id}>
          <img
            src={image.webformatURL}
            alt={image.tags}
            className={classes.targetsConfig_img}
            onClick={e => this.changeImage(image.webformatURL, e)}
            style={{
              objectFit: 'cover',
              borderRadius: '3px',
            }}
          />
        </View>
      );
    });
    return images;
  }

  onChangeImport(event) {
    console.log('onChangeImport', this.props, this.state);

    this.setState({
      queryImages: event.target.value,
    });
    this.getListImages(event.target.value, this.state.index_page);
  }

  setImage(img) {
    const data = {
      image: img,
    };
    fetch(this.props.baseUrl + 'targets/' + this.props.temporary._id, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.token,
      },
    }).then(response => {
      if (response.status !== 200) {
        console.log('error guardando la imagen', response.status);
      }
    });
  }

  getListImages(query = '', index) {
    if (query === '') {
      this.setState({
        showImages: false,
        queryImages: '',
        images: [],
      });
    } else {
      fetch(
        'https://pixabay.com/api/?key=10465050-661faffb220a43b9f64d560a2&q=' +
          query +
          '&orientation=horizontal',
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.status;
          }
        })
        .then(data => {
          let pages = 0;
          if (data.totalHits > data.hits) {
            pages = 500 / this.state.per_page + 1;
          } else {
            pages = data.totalHits / this.state.per_page + 1;
          }
          this.setState({
            images: data.hits,
            max_page: pages,
            showImages: true,
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  changeImage(img) {
    this.setImage(img);
    this.props.refreshConf(this.props.temporary._id);
    this.setState({
      image: img,
      showImages: false,
      queryImages: '',
      images: [],
      openBuscarFotos: false,
    });
  }

  checkValid(data, check, required) {
    const valid = Object.keys(check).map(value => {
      if (required.indexOf(value) >= 0) {
        if (
          data[value] >= check[value].minimum &&
          data[value] <= check[value].maximum
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          data[value] >= check[value].minimum &&
          data[value] <= check[value].maximum &&
          data[value] != null
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
    if (valid.indexOf(false) >= 0) {
      return false;
    } else {
      return true;
    }
  }

  saveInfo(onlyTry) {
    // Si es true solo devuelve si esta todo ok o no
    onlyTry = onlyTry | false;

    console.log('saveInfo', this.refs);
    const name = { name: this.refs.targetInfo.getName() };
    const values = this.refs.targetInfo.getValues();
    const data = {};
    const dataCheck = {};
    const dataRequired = this.props.schema.required;
    Object.keys(values).map(value => {
      data[value] = values[value];
      return null;
    });
    Object.keys(this.props.schema.properties).map(value => {
      dataCheck[value] = this.props.schema.properties[value];
      return null;
    });
    delete data.images;
    delete data.showImages;
    delete data.queryImages;
    delete data.open;
    delete dataCheck.initialContribution;
    console.log(data);
    if (this.checkValid(data, dataCheck, dataRequired)) {
      if (!onlyTry) {
        TargetsAPI.setTargetConfiguration(
          this.props.baseUrl,
          this.props.temporary._id,
          this.props.token,
          data,
        )
          .then(response => {
            if (response.status === 200) {
              return null;
            } else {
              throw response.status;
            }
          })
          .catch(error => {
            this.openError('Error guardando el nombre, error: ' + error);
          });

        console.log('CreateTargetBasic.saveInfo', name);

        TargetsAPI.setTargetInfo(
          this.props.baseUrl,
          this.props.temporary._id,
          this.props.token,
          name,
        )
          .then(response => {
            if (response.status === 200) {
              this.props.refreshConf(this.props.temporary._id);
              return null;
            } else {
              throw response.status;
            }
          })
          .catch(error => {
            this.openError('Error guardando la configuración, error: ' + error);
          });
        this.props.getTarget(this.props.temporary._id);

        this.props.history.push(this.props.next);
      } else {
        return true;
      }
    } else {
      if (!onlyTry) {
        this.openError(
          'Error: hay un valor incorrecto en alguno de los campos',
        );
      } else {
        return false;
      }
    }
  }

  async retirementContinue(flujo) {
    this.props.saveNameAndImageRetirement({
      name: this.state.name,
      image: this.state.image,
    });
    const name = {
      name: this.state.name,
      image: this.state.image,
    };
    try {
      await TargetsAPI.setTargetInfo(
        this.props.baseUrl,
        this.props.temporary._id,
        this.props.token,
        name,
      );

      // Flow.navigate(
      //   flujo === 'transfer'
      //     ? routes.retirementTransferSelectPlan
      //     : routes.retirementObjective,
      //   this.props,
      //   {
      //     flujo: flujo,
      //     objectiveType: this.props.type,
      //   },
      // );
      Flow.navigate(routes.retirementBeneficiario, this.props, {
        flujo: flujo,
        objectiveType: this.props.type,
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    Flow.setParams({
      objectiveType: this.props.type,
      impInicial: null,
      impMensual: null,
      años: null,
      temporaryObjectiveId: this.props.temporary._id,
    });
    // La primera vez chequea si vienen precargado los datos si puede continuar
    if (this.saveInfo(true)) {
      this.setState({ formIsOk: true });
    }

    const { dispatch } = this.context;
    dispatch({
      type: Types.INITIAL_PENSION_STATE,
    });
    console.log(this.context, 'ContextType');
  }

  render() {
    const { classes } = this.props;
    console.log(this.props, 'PROPS');
    const transformClasses = Utils.transformClasses;
    const customTargetInfoConfig = {
      ...TargetInfoConfig,
      textField1: {
        label: createTarget_strings['es'].targetBasic_label_text1,
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub11_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub11_inputProps',
            classes,
          ),
        },
      },
      typography1: {
        variant: 'body1',
        color: 'default',
      },
      textField2: {
        label:
          this.props.schema.properties.price !== undefined
            ? this.props.schema.properties.price.title
            : '',
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub21_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              {this.props.schema.properties.price !== undefined
                ? this.props.schema.properties.price.unit
                : ''}
            </InputAdornment>
          ),
          classes: transformClasses(
            'targetInfo_textFieldsub21_inputProps',
            classes,
          ),
        },
      },
      textField3: {
        label:
          this.props.schema.properties.months !== undefined
            ? this.props.schema.properties.months.title
            : '',
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub31_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              {this.props.schema.properties.months !== undefined
                ? this.props.schema.properties.months.unit
                : ''}
            </InputAdornment>
          ),
          classes: transformClasses(
            'targetInfo_textFieldsub31_inputProps',
            classes,
          ),
        },
      },
      textField4: {
        label:
          this.props.schema.properties.years !== undefined
            ? this.props.schema.properties.years.title
            : '',
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub41_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              {this.props.schema.properties.years !== undefined
                ? this.props.schema.properties.years.unit
                : ''}
            </InputAdornment>
          ),
          classes: transformClasses(
            'targetInfo_textFieldsub41_inputProps',
            classes,
          ),
        },
      },
      textField5: {
        label:
          this.props.schema.properties.retirementAge !== undefined
            ? this.props.schema.properties.retirementAge.title
            : '',
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub51_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              {this.props.schema.properties.retirementAge !== undefined
                ? this.props.schema.properties.retirementAge.unit
                : ''}
            </InputAdornment>
          ),
          classes: transformClasses(
            'targetInfo_textFieldsub51_inputProps',
            classes,
          ),
        },
      },
      textField6: {
        label:
          this.props.schema.properties.salaryPercent !== undefined
            ? this.props.schema.properties.salaryPercent.title
            : '',
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInfo_textFieldsub61_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              {this.props.schema.properties.salaryPercent !== undefined
                ? this.props.schema.properties.salaryPercent.unit
                : ''}
            </InputAdornment>
          ),
          classes: transformClasses(
            'targetInfo_textFieldsub61_inputProps',
            classes,
          ),
        },
      },
    };

    console.log(this.props, 'PROPS');
    console.log(this.state, 'STATE');
    return (
      <div className='container'>
        <Header
          title='Añadir Objetivo'
          rigthAction={() => this.props.history.push('/home/objetivos')}
          leftAction={() =>
            this.props.type === 'retirement'
              ? this.props.history.push('/home/objetivos/retirement')
              : null
          }
        />
        <Body vAlign='top'>
          {this.props.type === 'nextWish' && (
            <MyIcon
              name='monedero'
              style={{ height: 45, width: 45, marginTop: 10 }}
            />
          )}
          {this.props.type === 'familyTarget' && (
            <MyIcon
              name='bolsaDinero'
              style={{ height: 45, width: 45, marginTop: 10 }}
            />
          )}

          {this.props.type === 'retirement' && (
            <div style={{ marginTop: 10 }}>
              <CajaFuerte style={{ color: 'white' }} />
            </div>
          )}

          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetBasic_slideTitle_typography1',
              classes,
            )}
          >
            Define tu objetivo
          </Typography>

          {/* Datos */}
          <TargetInfo
            ref='targetInfo'
            classes={classes}
            image={null}
            data={this.state}
            imgClick={null}
            config={customTargetInfoConfig}
            schema={this.props.schema}
            setFormIsOk={value => this.setFormIsOk(value)}
            targetType={this.props.type}
            retirementOnChange={value => this.setState(value)}
          />

          {/* Foto */}
          <div className={classes.wrapper_externo}>
            <div
              className={classes.targetInfo_body_targetSelector_container}
              onClick={() => this.openBuscarFotosModal()}
            >
              <div
                className={
                  classes.targetInfo_body_targetSelector_leftIco_container
                }
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses(
                    'targetInfo_body_targetSelector_leftIco_svgIcon',
                    classes,
                  )}
                >
                  <path d='M 11.953 13.198 C 10.987 13.198 10.201 13.984 10.201 14.949 C 10.201 15.914 10.987 16.699 11.953 16.699 C 12.918 16.699 13.704 15.914 13.704 14.949 C 13.704 13.984 12.918 13.198 11.953 13.198 Z M 11.978 17.873 C 10.38 17.873 9.081 16.567 9.081 14.964 C 9.081 13.359 10.38 12.053 11.978 12.053 C 13.575 12.053 14.874 13.359 14.874 14.964 C 14.874 16.567 13.575 17.873 11.978 17.873 Z M 9.729 4.684 L 14.088 4.684 L 13.461 3.418 L 10.356 3.418 L 9.729 4.684 Z M 12 8.075 C 13.663 8.075 15.268 8.682 16.52 9.784 L 22.583 9.807 L 22.583 6.922 C 22.583 6.469 22.215 6.1 21.762 6.1 L 2.238 6.1 C 1.785 6.1 1.417 6.469 1.417 6.922 L 1.417 9.807 L 7.416 9.807 C 8.732 8.682 10.337 8.075 12 8.075 Z M 12.028 9.454 C 9.022 9.454 6.577 11.908 6.577 14.924 C 6.577 17.941 9.022 20.395 12.028 20.395 C 15.033 20.395 17.478 17.941 17.478 14.924 C 17.478 11.908 15.033 9.454 12.028 9.454 Z M 1.417 19.731 C 1.417 20.184 1.785 20.553 2.238 20.553 L 7.874 20.553 L 7.655 20.38 C 5.984 19.062 5.025 17.088 5.025 14.963 C 5.025 13.694 5.374 12.452 6.035 11.371 L 6.125 11.225 L 1.417 11.225 L 1.417 19.731 Z M 17.716 11.371 C 18.377 12.452 18.727 13.694 18.727 14.963 C 18.727 17.088 17.768 19.062 16.096 20.38 L 15.877 20.553 L 21.762 20.553 C 22.215 20.553 22.583 20.184 22.583 19.731 L 22.583 11.225 L 17.626 11.225 L 17.716 11.371 Z M 2.238 21.969 C 1.005 21.969 0 20.965 0 19.731 L 0 6.922 C 0 5.688 1.005 4.684 2.238 4.684 L 8.158 4.684 L 9.291 2.394 C 9.411 2.151 9.654 2 9.926 2 L 13.911 2 C 14.182 2 14.426 2.151 14.546 2.394 L 15.679 4.684 L 21.762 4.684 C 22.996 4.684 24 5.688 24 6.922 L 24 19.731 C 24 20.965 22.996 21.969 21.762 21.969 L 2.238 21.969 Z' />
                </SvgIcon>
              </div>
              <div
                className={
                  classes.targetInfo_body_targetSelector_info_container
                }
              >
                <div
                  className={
                    classes.targetInfo_body_targetSelector_info_firstRow
                  }
                >
                  <Typography
                    variant='body1'
                    color='secondary'
                    classes={transformClasses(
                      'targetInfo_body_targetSelector_info_firstRow_typography',
                      classes,
                    )}
                  >
                    ¡SUBE UNA FOTO!
                  </Typography>
                </div>
                <div
                  className={
                    classes.targetInfo_body_targetSelector_info_secondRow
                  }
                >
                  <Typography
                    variant='body1'
                    color='primary'
                    classes={transformClasses(
                      this.state.valueTarget === ''
                        ? 'targetInfo_body_targetSelector_info_secondRow_typography1'
                        : 'targetInfo_body_targetSelector_info_secondRow_typography2',
                      classes,
                    )}
                  >
                    Selecciona
                  </Typography>
                  <SvgIcon
                    color='primary'
                    style={{
                      fontSize: 44,
                    }}
                    //classes={transformClasses('targetInfo_body_targetSelector_info_secondRow_icon',classes)
                  >
                    <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                    <path d='M0 0h24v24H0z' fill='none' />
                  </SvgIcon>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classes.targetBasic_targetImg_container}
            style={{
              backgroundImage: 'url(' + this.state.image + ')',
            }}
          />

          {this.props.type === 'retirement' && (
            <div style={{ width: 312 }}>
              <MyText style={{ textAlign: 'left' }}>
                Selecciona si quieres crear un nuevo plan de pensiones o por el
                contrario quieres traer a EVO Banco un plan que ya tienes
                contratado.
              </MyText>
            </div>
          )}
        </Body>
        {this.props.type === 'retirement' && (
          <View>
            <View style={{ padding: 15 }}>
              <CoreButton
                style={{
                  opacity: (!this.state.name || !this.state.image) && 0.2,
                }}
                onClick={
                  !this.state.name || !this.state.image
                    ? () => null
                    : this.retirementContinue.bind(this, 'nuevoPlan')
                }
              >
                ABRIR NUEVO PLAN DE PENSIONES
                {/* ¡VAMOS AllÁ! */}
              </CoreButton>
              <CoreButton
                greenButtonSt
                style={{
                  opacity: (!this.state.name || !this.state.image) && 0.2,
                  marginTop: 10,
                }}
                onClick={
                  !this.state.name || !this.state.image
                    ? () => null
                    : this.retirementContinue.bind(this, 'transfer')
                }
              >
                TRASPASAR PLAN
                {/* ¡VAMOS AllÁ! */}
              </CoreButton>
            </View>
          </View>
        )}

        {this.props.type !== 'retirement' && (
          <Footer>
            <Button
              variant='contained'
              color='secondary'
              classes={transformClasses('targetBasic_button1', classes)}
              onClick={() => this.saveInfo()}
              disabled={!this.state.formIsOk}
            >
              Siguiente {this.state.formIsOk}
            </Button>
          </Footer>
        )}

        {/* Buscador de Fotos */}
        <Modal
          disablePortal
          open={this.state.openBuscarFotos}
          onClose={this.closeModal.bind(this)}
        >
          <div
            className={classes.targetsConfig_container}
            style={{ height: '100%', maxHeight: 'none' }}
          >
            <Header title='Imagen' rigthAction={() => this.closeModal()}>
              <div
                style={{
                  float: 'left',
                  width: '100%',
                  padding: 10,
                  maxWidth: 350,
                }}
              >
                {/*className={classes.targetInfo_body_targetSelector_info_firstRow*/}
                <Typography
                  variant='body1'
                  color='secondary'
                  classes={transformClasses(
                    'targetInfo_body_targetSelector_info_firstRow_typography',
                    classes,
                  )}
                >
                  Busca la imagen
                </Typography>
                {/*<div
                className={classes.targetInfo_body_targetSelector_info_secondRow}
              >*/}
                <Input
                  disableUnderline
                  value={this.state.queryImages}
                  placeholder='Buscar'
                  onChange={e => this.onChangeImport(e)}
                  classes={transformClasses(
                    'targetInfo_body_targetSelector_info_secondRow_input',
                    classes,
                  )}
                />
              </div>
            </Header>

            {/*<div className={classes.targetsConfig_container}>*/}
            <Body vAlign='top'>
              {/*<div
                className={classes.targetInfo_body_targetSelector_container}
                style={{ marginTop: '24px' }}
              >
                <div
                  className={classes.targetInfo_body_targetSelector_info_container}
                >
                </div>
              </div>
              */}
              <Collapse in={this.state.showImages} unmountOnExit>
                <View gridSt>{this.renderImages()}</View>
                {/* <List>{this.renderImages()}</List> */}
              </Collapse>
            </Body>
          </div>
        </Modal>
        {/* Mensaje Error */}
        <Modal
          disablePortal
          open={this.state.openError}
          onClose={() => {
            return null;
          }}
          classes={transformClasses('targetBasic_error', classes)}
        >
          <div className={classes.ErrorContainer}>
            <Typography variant='body1' color='primary'>
              {this.state.modalErrorMessage}
            </Typography>
            <Button color='primary' onClick={this.closeError.bind(this)}>
              aceptar
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

const styles = theme => ({
  ...TargetInfoStyles(theme),
  targetsConfig_imgDiv: {
    position: 'relative',
    maxWidth: '100%',
    height: '25vh',
    minHeight: 142,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  targetsConfig_imgDiv_overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  targetsConfig_targetName: {
    paddingTop: 1,
    display: 'inline',
    fontSize: '0.85em',
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
  targetInfo_textField1_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField2_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField3_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField4_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField5_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textField6_root: {
    margin: 'unset',
    marginTop: 20,
  },
  targetInfo_textFieldsub11_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub21_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub31_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub41_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub51_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub61_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInfo_textFieldsub11_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub21_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub31_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub41_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub51_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub61_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInfo_textFieldsub11_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub21_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub31_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub41_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub51_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInfo_textFieldsub61_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetBasic_container: {
    minHeight: '640px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  targetInfo_container: {
    padding: '0 24px',
    width: '100%',
  },
  targetBasic_typography1: {
    padding: '10px 20px',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '16px',
  },
  targetBasic_textContainer: {
    height: '10vh',
    minHeight: 57,
    alignItems: 'center',
    display: 'flex',
  },
  targetBasic_buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  targetBasic_button1_root: {
    width: '312px',
    borderRadius: '4px',
    marginBottom: '24px',
    marginTop: 'auto',
  },
  targetBasic_button1_label: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  targetInfo_helpTextContainer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  targetInfo_helpTextIcon: {
    display: 'inline-flex',
    color: theme.palette.secondary.main,
  },
  targetInfo_helpTextTypography1_root: {
    fontSize: '0.65rem',
  },

  targetsConfig_container: {
    height: '100%',
    width: '100%',
    //padding: '0 24px',
    backgroundImage: 'url(/images/bg_body.png) !important',
    //display: 'flex',
    //flexDirection: 'column',
    //overflow: 'auto'
  },
  targetsConfig_img: {
    width: '312px',
    height: '129px',
  },
  ErrorContainer: {
    height: '100vh',
    minHeight: '640px',
    width: '100%',
    //backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    '&:focus': {
      outline: 'unset',
    },
  },
  targetBasic_error_root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/bg_body.png) !important',
  },
  targetBasic_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  targetBasic_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  targetBasic_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '18px',
  },
  targetBasic_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
  },
  targetBasic_slideTitle_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetBasic_slideTitle_icon_root: {
    width: '100%',
    height: 50,
    marginBottom: '5px',
  },
  targetBasic_slideTitle_typography1_root: {
    fontSize: '45px',
    height: '49px',
    fontWeight: 100,
    letterSpacing: '-1px',
    textAlign: 'center',
    marginBottom: '36.3px',
  },
  targetBasic_targetImg_container: {
    width: '312px',
    height: '129px',
    border: '1px dashed rgba(255,255,255,.3)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    alignSelf: 'center',
    marginBottom: 60,
  },
  wrapper_externo: {
    width: '100%',
    padding: '0 24px',
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetBasic);
