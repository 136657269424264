const SavedTotalConfig = {
  typography1: {
    variant: 'body1',
    color: 'primary',
    align: 'center',
  },
  typography2: {
    variant: 'body1',
    color: 'primary',
    align: 'center',
  },
};

export default SavedTotalConfig;
