function generateStyles(theme) {
  return {
    targetInfo_textField1_root: {
      margin: '0 20px',
    },
    targetInfo_textFieldsub11_inputLabelProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textFieldsub11_inputProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textField2_root: {
      margin: '0 20px',
    },
    targetInfo_textFieldsub21_inputLabelProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textFieldsub21_inputProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textField3_root: {
      margin: '0 20px',
    },
    targetInfo_textFieldsub31_inputLabelProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textFieldsub31_inputProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textField4_root: {
      margin: '0 20px',
    },
    targetInfo_textFieldsub41_inputLabelProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textFieldsub41_inputProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textField5_root: {
      margin: '0 20px',
    },
    targetInfo_textFieldsub51_inputLabelProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textFieldsub51_inputProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textField6_root: {
      margin: '0 20px',
    },
    targetInfo_textFieldsub61_inputLabelProps_root: {
      color: theme.palette.primary.main,
    },
    targetInfo_textFieldsub61_inputProps_root: {
      color: theme.palette.primary.main,
    },
    imgDiv: {
      maxWidth: '100%',
      height: 134,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    targetInfo_body_targetSelector_container: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      borderBottom: 'solid 1px rgba(255,255,255,.3)',
      marginBottom: '18px',
      textAlign: 'left',
    },
    targetInfo_body_targetSelector_leftIco_container: {
      width: '28px',
      height: '28px',
      display: 'flex',
      padding: 0,
      margin: 0,
    },
    targetInfo_body_targetSelector_leftIco_svgIcon_root: {
      width: '100%',
      height: '100%',
    },
    targetInfo_body_targetSelector_info_container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    targetInfo_body_targetSelector_info_firstRow_typography_root: {
      fontSize: '11px',
      lineHeight: '11.3px',
      letterSpacing: '0px',
      fontWeight: 600,
      marginLeft: '11.5px',
      textAlign: 'left',
    },
    targetInfo_body_targetSelector_info_alert_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
    targetInfo_body_targetSelector_info_secondRow: {
      width: '95%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    targetInfo_body_targetSelector_info_secondRow_typography1_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
    targetInfo_body_targetSelector_info_secondRow_input_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: theme.palette.primary.main,
      marginLeft: '11.5px',
      '&::placeholder': {
        color: 'rgba(255,255,255,.5)',
      },
    },
    targetInfo_body_targetSelector_info_secondRow_typography2_root: {
      fontSize: '16px',
      marginTop: '8px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
  };
}

export default generateStyles;
