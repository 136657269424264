import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
//import { TargetsAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

class CreateTargetCreateContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generated: false,
      created: false,
      waiting: false,
      interval: null,
      interval2: null,
      open: false,
      url: null,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleFrameTasks.bind(this), false);
  }

  async handleFrameTasks(event) {
    console.log('handleFrameTasks', event.data);
    if (event.data === 'getToDaChoppa!') {
      const data2 = await this.checkStatus2();
      console.log('handleFrameTasks2', data2, data2 === 'CANCELED');
      if (data2 === 'CANCELED') {
        //''NOT_CREATED')
        this.props.history.push('/createTargetDoneKo');
      } else {
        this.props.history.push('/createTargetDoneOk');
      }
    }
  }

  onContractFailed() {
    this.props.history.push('/home/resumen');
  }

  createContract() {
    TargetsAPI.createContract(
      this.props.baseUrl,
      this.props.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          this.setState({
            waiting: true,
            generated: true,
            interval: setInterval(() => this.checkStatus(), 2000),
          });
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error creando contrato ', error);
      });
  }

  checkStatus2() {
    return new Promise((resolve, reject) => {
      TargetsAPI.getTargets(this.props.baseUrl, this.props.id, this.props.token)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            reject(response.status);
            throw response.status;
          }
        })
        .then(data => {
          console.log('checkStatus2', data, data.status);
          resolve(data.status);
        })
        .catch(error => {
          console.log('error consiguiendo el target', error);
          reject(error);
        });
    });
    // TargetsAPI.getContractStatus(
    //   this.props.baseUrl,
    //   this.props.id,
    //   this.props.token,
    // )
    //   .then(response => {
    //     if (response.status === 200) {

    //       return response.json();
    //     } else {
    //       console.log('checkStatus2 status', response, response.status);
    //     }
    //   })
    //   .then(data => {
    //     console.log('checkStatus2', data);
    //     return data;
    //   });
  }

  checkStatus() {
    TargetsAPI.getContractStatus(
      this.props.baseUrl,
      this.props.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          clearInterval(this.state.interval);
        }
      })
      .then(data => {
        console.log('checkStatus', data);
        if (data === 'CREATED') {
          clearInterval(this.state.interval);
          this.setState({
            created: true,
            interval2: setInterval(() => this.getContractSigningInfo(), 1000),
          });
        } else if (data === 'FAILED') {
          clearInterval(this.state.interval);
          this.setState({
            created: false,
            waiting: false,
          });
        }
      });
  }

  getContractSigningInfo() {
    TargetsAPI.getContractSigningInfo(
      this.props.baseUrl,
      this.props.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getContractSigningInfo', data);
        if (data.creationStatus === 'CREATED') {
          clearInterval(this.state.interval2);
          this.setState({
            url: data.url,
            waiting: false,
            created: true,
          });
        } else if (data.creationStatus === 'FAILED') {
          this.setState({
            waiting: false,
            created: false,
          });
          clearInterval(this.state.interval2);
        }
      });
  }

  checkSigningInfo() {
    this.setState({
      open: true,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <iframe
        src={this.props.location.state.url}
        title='iframe'
        className={classes.iframeFirmaContainer}
      />
    );
  }
}

const styles = theme => ({
  CreateTargetCreateContract_container: {
    minHeight: '640px',
    overflow: 'auto',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  targetsCreateContract_loadingContainer: {
    minHeight: '640px',
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  CreateTargetCreateContract_textContainer: {
    minHeight: '640px',
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    flexDirection: 'column',
  },
  CreateTargetCreateContract_textContainer_typography1: {
    textAlign: 'center',
    marginBottom: 8,
  },
  CreateTargetCreateContract_textContainer_button1: {
    width: '100%',
    fontWeight: 600,
  },
  iframeFirmaContainer: {
    height: '100%',
    //minHeight: 568,
    width: '100%',
    overflow: 'auto',
    frameBorder: 0,
  },
});

export default withStyles(styles, { withTheme: true })(
  CreateTargetCreateContract,
);
