import {
  TOKEN,
  OBJECTIVE_ID,
  NAME_IMAGE_RETIREMENT,
  USER,
  PRODS_FOR_USER,
} from './containers/constants';

import Flow from './components/Flow';
import config from './config/app.js';

let prod = window.__CLIENT_ENV.CLIENT_ENV_NODE_ENV === 'staging' ? false : true;
console.log('CLIENT_ENV_NODE_ENV', window.__CLIENT_ENV.CLIENT_ENV_NODE_ENV);

const initialState = {
  // Mockup esta a pelo el token para pasar el emulador
  token: config.token ? config.token : null,
  baseUrl: prod ? config.apiUrl : config['--apiUrl'],
  // baseUrl: config.apiUrl,
  objectiveId: null,
  name: null,
  image: null,
  objectiveType: null,
  user: null,
  prodsForUser: null,
};

//console.log('InitialState modo Desarrollo:', initialState);

//ºº TODO: QUITAR EL REDUX y pasar token y baseUrl a un Wrapper
export default function reducer(state = initialState, action = {}) {
  //console.log('reducer', state, action);
  switch (action.type) {
    case TOKEN:
      // Comparte a nivel global el token y el baseURl
      Flow.setParams({
        token: action.payload,
        baseUrl: initialState.baseUrl,
      });

      return {
        ...state,
        token: action.payload,
      };
    case OBJECTIVE_ID:
      // Comparte a nivel global el token y el baseURl

      return {
        ...state,
        objectiveId: action.payload,
      };
    case NAME_IMAGE_RETIREMENT:
      return {
        ...state,
        name: action.payload.name,
        image: action.payload.image,
        objectiveType: 'retirement',
      };
    case USER:
      return {
        ...state,
        user: action.payload,
      };
    case PRODS_FOR_USER:
      return {
        ...state,
        prodsForUser: action.payload,
      };

    case 'persist/REHYDRATE':
      if (action.payload != null) {
        action.payload.main.token = config.token
          ? config.token
          : action.payload.main.token;

        // Comparte a nivel global el token y el baseURl
        Flow.setParams({
          token: action.payload.main.token,
          baseUrl: state.baseUrl,
        });

        return {
          ...state,
          token: action.payload.main.token,
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return {
        ...state,
      };
  }
}
