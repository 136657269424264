import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('image', {
  styles: {
    default: {},

    fullWidthSt: {
      width: '100%',
    },

    BannerSt: {
      borderRadius: 10,
    },

    BannerCerrarSt: {
      position: 'absolute',
      top: 2,
      right: 19,
    },

    BannerIcoSt: {
      position: 'absolute',
      bottom: 25,
      left: 25,
    },

    topRadiusSt: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
  },
});
