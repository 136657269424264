function generateStyles(theme) {
  return {
    targetInitialContribution_container: {
      height: '100vh',
      minHeight: 568,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
    },
    targetInitialContribution_textField1_root: {
      margin: 'unset',
      marginTop: 20,
    },
    targetInitialContribution_buttonContainer1: {
      width: '312px',
      marginBottom: '24px',
    },
    targetInitialContribution_button1_root: {
      width: '312px',
      height: '40px',
      borderRadius: '4px',
      // fontWeight: 600
    },
    targetInitialContribution_button1_label: {
      fontWeight: 600,
      fontSize: '14px',
    },
    targetInitialContribution_body_targetSelector_container: {
      display: 'flex',
      alignItems: 'flex-start',
      //width: '100%',
      borderBottom: 'solid 1px rgba(255,255,255,.3)',
      marginBottom: '18px',
    },
    targetInitialContribution_body_targetSelector_leftIco_container: {
      width: '24.9px',
      height: '27.5px',
      display: 'flex',
      padding: 0,
      margin: 0,
    },
    targetInitialContribution_body_targetSelector_leftIco_svgIcon_root: {
      width: '100%',
      height: '100%',
    },
    targetInitialContribution_body_targetSelector_info_container: {
      width: 60, //100%
      display: 'flex',
      flexDirection: 'column',
    },
    targetInitialContribution_body_targetSelector_info_firstRow_typography_root: {
      fontSize: '11px',
      lineHeight: '11.3px',
      letterSpacing: '0px',
      fontWeight: 600,
      marginLeft: '11.5px',
      textAlign: 'left',
    },
    targetInitialContribution_body_targetSelector_info_secondRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    targetInitialContribution_body_targetSelector_info_secondRow_typography1_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
    targetInitialContribution_body_targetSelector_info_secondRow_input_root: {
      fontSize: '16px',
      lineHeight: '16.9px',
      color: theme.palette.primary.main,
      marginLeft: '11.5px',
      '&::placeholder': {
        color: 'rgba(255,255,255,.5)',
      },
    },
    targetInitialContribution_body_targetSelector_info_secondRow_typography2_root: {
      fontSize: '16px',
      marginTop: '8px',
      lineHeight: '16.9px',
      color: 'rgba(255,255,255,.5)',
      marginLeft: '11.5px',
      marginBottom: '7.9px',
    },
    targetInitialContribution_disclaimer_typography_root: {
      fontSize: '16px',
      textAlign: 'center',
      marginTop: 'auto',
    },
  };
}

export default generateStyles;
