import { connect } from 'react-redux'
import CargosInitial from '../views/cargos/cargosInitial.component'

function mapStateToProps(state) {
  const { token, baseUrl } = state.main
  return {
    token,
    baseUrl
  }
}

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CargosInitial)
