import React, { Component, Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdorment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Check from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';

import { BanksAPI } from '../../src-core/sdk/index';
// import {
//   ActivateAccounts,
//   ActivateAccountsConfig,
//   ActivateAccountsStyles,
// } from '../../core//banks';
import ActivateAccounts from '../activateAccounts/activateAccounts.component';
import ActivateAccountsConfig from '../activateAccounts/activateAccounts.config';
import ActivateAccountsStyles from '../activateAccounts/activateAccounts.style';

import { cargos_strings } from './text';
import { Utils } from '../../src-core/resources/index';

import CargosInitialSelect from './cargosInitialSelect.component';
import CargosInitialConnect from './cargosInitialConnect.component';
import CargosInitialAccounts from './cargosInitialAccounts.component';
import CargosInitialOthers from './cargosInitialOthers.component';
import Flow from '../../components/Flow';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class CargosInitial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banksUser: null,
      open: false,
      listBanks: null,
      filteredBanks: null,
      query: '',
      showAutoComplete: false,
      selectedBank: null,
      showInputCredentials: false,
      bankFields: null,
      login: false,
      errorLogin: false,
      errorMessage: '',
      idSelected: '',
      passVisibility: false,
    };
    console.log('CargosInitial.const', props);
  }

  /*
  goBack() {
    console.log("goBack", this.props.location.pathname);
    switch (this.props.location.pathname) {
      case '/cuentasInitial/selector':
        this.props.history.push('/cuentasInitial/accounts/first')
        break

      case '/cuentasInitial/connect':
        this.setState({
          errorMessage: '',
          query: '',
          selectedBank: null
        })
        this.props.history.push('/cuentasInitial/selector')
        break

      case '/cuentasInitial/accounts':
        this.props.history.push('/cuentasInitial/selector')
        break
  
      case '/cuentasInitial/others':
        this.props.history.push('/cuentasInitial/selector')
        break
  
      default:
        this.props.history.push('/tour')
    }
  }
*/

  goChat() {
    this.props.history.push({
      pathname: '/chatSupport',
      state: {
        back: this.props.location.pathname,
      },
    });
  }

  componentDidMount() {
    //console.log('CargosInitial.getUserBanks1');
    this.getUserBanks();

    //console.log('CargosInitial.getUserBanks2');
    this.getBanksEntities();
    //this.renderAccountsEvo()
  }

  async getUserBanks() {
    //console.log('CargosInitial.getUserBanks3');
    const data = await BanksAPI.getUserBanks(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    //console.log('CargosInitial.getUserBanks4', data);
    this.setState({
      banksUser: data,
    });
  }

  getBanksEntities() {
    // Aqui es donde quitamos abanca
    BanksAPI.getListBanksEntities(this.props.baseUrl, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('CargosInitial.getBanksEntities', data);
        this.setState({
          listBanks: data,
        });
      })
      .catch(error => {
        console.log('error consiguiendo la lista de bancos ', error);
      });
  }

  activateAccount(idAccount, data) {
    BanksAPI.activateAccount(
      this.props.baseUrl,
      idAccount,
      data,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error en la activación de una cuenta ', error);
      });
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetAccounts_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      filteredBanks: null,
      query: '',
      showAutoComplete: false,
      selectedBank: null,
      bankFields: null,
      showInputCredentials: false,
      errorLogin: false,
    });
  }

  handleNext() {
    switch (this.props.location.pathname) {
      case '/cuentasInitial/selector':
        if (this.state.selectedBank) {
          this.props.history.push('/cuentasInitial/connect');
        } else {
          this.props.history.push('/cuentasInitial/accounts/first');
        }
        break;

      case '/cuentasInitial/connect':
        this.loginBank();
        break;

      default:
        this.props.history.push('/home/resumen');
    }
  }

  renderIconsContainer() {
    let containers = [];
    let listBanksShowed = [];

    // Carga los bancos si no los cargó
    if (!this.state.listBanksShowed) {
      let i = 0;
      let j = 0;
      let logos = [];
      while (i < 8) {
        let bank = this.state.listBanks[j];
        // Para no mostrar siempre los mismos
        j = j + parseInt(Math.random() * 5);
        //console.log("renderIconsContainer bank", j, bank);
        // No mostramos los bancos de tipo empresa, ni abanca en la lista inicial, ni repetir logos
        if (
          !bank.business &&
          (bank.id !== 'abanca' || bank.id !== 'abanca') &&
          !logos.includes(bank.image)
        ) {
          //console.log("dentro", i, bank);
          logos.push(bank.image);
          listBanksShowed.push(bank);
          i++;
        }
      }

      this.setState({
        listBanksShowed: listBanksShowed,
      });
    } else {
      listBanksShowed = this.state.listBanksShowed;
    }

    //console.log('listBanksShowed', listBanksShowed);
    // Muestra los bancos
    const { classes } = this.props;
    //let container = [];
    for (let i = 0; i < 8; i++) {
      let bank = listBanksShowed[i];
      containers.push(
        <div
          key={i}
          className={
            this.state.idSelected === bank.name.full
              ? classes.cargos_bankSelector_icons_container_selected
              : classes.cargos_bankSelector_icons_container
          }
          onClick={() => this.onSelectIconBank(bank)}
        >
          <img
            alt='bank_img'
            src={
              /*this.state.listBanks[i].image*/

              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.others/public/banks/icons/' +
              bank.id +
              '.png'
            }
            className={classes.cargos_bankSelector_icons}
          />
        </div>,
      );
    }

    return containers;
  }

  renderNewBanksSelector() {
    const { classes } = this.props;
    //const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.cargos_bankSelector_container}>
        {this.renderIconsContainer()}
        <div className={classes.cargos_bankSelector_new_container}>
          <SvgIcon
            color='primary'
            classes={Utils.transformClasses(
              'cargos_bankSelector_svgIcon',
              classes,
            )}
          >
            <path d='M 19 13 h -6 v 6 h -2 v -6 H 5 v -2 h 6 V 5 h 2 v 6 h 6 v 2 Z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={Utils.transformClasses(
              'cargos_bankSelector_typography1',
              classes,
            )}
            onClick={() => {
              this.setState({ noButton: true });
              Flow.navigate('/cuentasInitial/others', this.props);
            }}
          >
            Añadir otros
          </Typography>
        </div>
      </div>
    );
  }

  renderOthersSelector() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.cargos_others_selector_container}>
        <Input
          value={this.state.query}
          onChange={this.onChangeQuery.bind(this)}
          disableUnderline
          classes={transformClasses('cargos_othersInput', classes)}
          color='primary'
          placeholder='Escriba el nombre de la entidad'
          endAdornment={
            <InputAdorment position='end' color='primary'>
              <SvgIcon color='primary' viewBox='0 0 128 128'>
                <path d='M 39.779 62.209 C 38.585 62.209 37.592 61.307 37.469 60.11 C 37.378 59.249 37.334 58.439 37.334 57.635 C 37.334 50.624 40.434 44.057 45.838 39.62 C 46.252 39.28 46.774 39.092 47.308 39.092 C 48.012 39.092 48.67 39.407 49.111 39.956 C 49.923 40.959 49.773 42.436 48.779 43.252 C 44.462 46.798 41.988 52.041 41.988 57.635 C 41.988 58.264 42.023 58.914 42.095 59.62 C 42.23 60.903 41.302 62.058 40.026 62.195 C 39.943 62.204 39.86 62.209 39.779 62.209 Z M 60.392 30.687 C 45.648 30.687 33.654 42.776 33.654 57.635 C 33.654 72.496 45.648 84.585 60.392 84.585 C 75.136 84.585 87.131 72.496 87.131 57.635 C 87.131 42.776 75.136 30.687 60.392 30.687 Z M 86.248 96.414 C 86.978 97.317 88.066 97.834 89.233 97.834 C 89.353 97.834 89.475 97.828 89.597 97.816 C 90.361 97.746 91.076 97.451 91.666 96.965 L 92.148 96.568 C 92.943 95.912 93.437 94.982 93.54 93.945 C 93.643 92.91 93.343 91.899 92.698 91.1 L 83.182 79.36 C 81.26 81.409 79.085 83.185 76.712 84.647 L 86.248 96.414 Z M 89.232 102.665 C 86.618 102.665 84.175 101.5 82.528 99.469 L 72.337 86.889 C 68.539 88.471 64.522 89.273 60.392 89.273 C 43.083 89.273 29 75.08 29 57.635 C 29 40.191 43.083 26 60.392 26 C 77.701 26 91.785 40.191 91.785 57.635 C 91.785 64.052 89.876 70.233 86.262 75.524 L 96.409 88.045 C 97.868 89.841 98.544 92.109 98.313 94.43 C 98.079 96.754 96.97 98.842 95.187 100.307 L 94.704 100.704 C 93.367 101.804 91.752 102.469 90.038 102.628 C 89.77 102.653 89.5 102.665 89.232 102.665 Z' />
              </SvgIcon>
            </InputAdorment>
          }
        />
        {this.state.filteredBanks !== null ? this.renderOthersList() : <div />}
      </div>
    );
  }

  renderOthersList() {
    const { classes } = this.props;
    //const transformClasses = Utils.transformClasses;
    const elements = this.state.filteredBanks.map(element => (
      <div
        className={classes.cargosOther_list}
        key={element.id}
        onClick={() => {
          this.onSelectIconBank(element);
          this.props.history.push('/cuentasInitial/connect');
        }}
      >
        <img
          alt='bank logo'
          src={
            /*this.state.listBanks[i].image*/ 'https://s3.eu-central-1.amazonaws.com/com.coinscrap.others/public/banks/icons/' +
            element.id +
            '.png'
          }
          className={classes.cargosOther_list_img}
        />
        <Typography variant='body1' color='primary'>
          {element.name.full}
        </Typography>
      </div>
    ));
    return elements;
  }

  renderBankSelector() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.modalDiv}>
        <div className={classes.selectorDiv}>
          <div className={classes.banksShowcaseDiv}>
            <div className={classes.banksShowcaseFirstRow}>
              <div
                className={classes.banksShowcaseFirstCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[0])}
              >
                <img
                  alt='0'
                  src={this.state.listBanks[0].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
              <div
                className={classes.banksShowcaseSecondCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[1])}
              >
                <img
                  alt='1'
                  src={this.state.listBanks[1].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
            </div>
            <div className={classes.banksShowcaseSecondRow}>
              <div
                className={classes.banksShowcaseFirstCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[2])}
              >
                <img
                  alt='2'
                  src={this.state.listBanks[2].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
              <div
                className={classes.banksShowcaseSecondCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[3])}
              >
                <img
                  alt='3'
                  src={this.state.listBanks[3].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
            </div>
          </div>
          <div className={classes.inputContainer}>
            <Typography classes={transformClasses('typography7', classes)}>
              {cargos_strings['es'].cargosInitial_informativeText1}
            </Typography>
            <Typography classes={transformClasses('typography8', classes)}>
              {cargos_strings['es'].cargosInitial_informativeText2}
            </Typography>

            <FormControl classes={transformClasses('formControl', classes)}>
              <Input
                disableUnderline={true}
                classes={transformClasses('input1', classes)}
                startAdornment={
                  <InputAdorment position='start'>
                    <Search classes={transformClasses('searchIcon', classes)} />
                  </InputAdorment>
                }
                endAdornment={
                  <InputAdorment
                    position='end'
                    className={
                      this.state.selectedBank !== null
                        ? classes.endAdornmentActive1
                        : classes.endAdornment1
                    }
                    color=''
                  >
                    <Check />
                  </InputAdorment>
                }
                value={this.state.query}
                onChange={this.onChangeQuery.bind(this)}
              />
            </FormControl>

            <div style={{ overflow: 'auto', flex: 1 }}>
              {this.state.showAutoComplete &&
              this.state.filteredBanks !== null ? (
                this.renderAutoComplete()
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCredentialsInput() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.bankInputContainer}>
        {!this.state.login ? this.renderBankFields() : this.renderLoading()}
        {this.state.errorLogin ? (
          <Typography
            variant='body1'
            color='secondary'
            classes={transformClasses('typography9', classes)}
          >
            {this.state.errorMessage}
          </Typography>
        ) : (
          <div />
        )}
      </div>
    );
  }

  renderBankFields() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.bankFields.map((field, index) => {
      console.log('field: ', field);
      return (
        <Input
          key={index}
          classes={transformClasses('input2', classes)}
          value={this.state.bankFields[index].value}
          onChange={e => this.handleFieldChange(e, index)}
          type={
            this.state.bankFields[index].code.indexOf('pass') >= 0
              ? !this.state.passVisibility
                ? 'password'
                : this.state.bankFields[index].type
              : this.state.bankFields[index].type
          }
          placeholder={field.label.es}
          startAdornment={
            <InputAdornment position='start'>
              {this.state.bankFields[index].code.indexOf('pass') >= 0 ? (
                <SvgIcon
                  color='primary'
                  classes={transformClasses(
                    'cargos_inputAdornment_start_icon',
                    classes,
                  )}
                >
                  <path d='M22.732 21.289a.653.653 0 0 1-.598.593l-4.18.336a.651.651 0 0 1-.701-.563l-.308-2.245-1.93-.057a.653.653 0 0 1-.634-.614l-.118-1.965-1.942-.1a.654.654 0 0 1-.43-.19l-1.108-1.11c-2.624.714-5.548.049-7.605-2.008-3.055-3.054-3.055-8.023 0-11.073 3.054-3.055 8.025-3.055 11.079 0 2.056 2.052 2.722 4.976 2.009 7.6l6.655 6.654a.653.653 0 0 1 .19.52l-.379 4.222zm-4.268-.424l3.015-.242.302-3.368-6.02-6.018-.986-.986c.188-.473.314-.965.387-1.462a6.504 6.504 0 0 0-1.83-5.573 6.537 6.537 0 0 0-9.23 0c-2.544 2.542-2.544 6.682 0 9.225a6.515 6.515 0 0 0 5.577 1.83c.498-.073.99-.199 1.462-.387l.987.986.51.51 2.276.118c.333.018.6.282.62.614l.117 1.952 1.885.056c.32.01.585.248.629.564l.3 2.181zm1.824-3.54a.56.56 0 1 1-.793.793l-5.823-5.823a.56.56 0 1 1 .792-.793l5.824 5.824zM9.464 4.8a2.67 2.67 0 0 1 0 3.773 2.672 2.672 0 0 1-3.773 0 2.671 2.671 0 0 1 0-3.773 2.672 2.672 0 0 1 3.773 0zM6.616 7.648a1.36 1.36 0 1 0 0-1.923c-.53.53-.53 1.393 0 1.923z' />
                </SvgIcon>
              ) : (
                <SvgIcon
                  color='primary'
                  classes={transformClasses(
                    'cargos_inputAdornment_start_icon',
                    classes,
                  )}
                >
                  <path d='M2.128 15.072v5.372H.556v-5.372c0-1.848 1.433-3.36 3.186-3.36h7.564c1.754 0 3.189 1.512 3.189 3.36v5.372H12.92v-5.372c0-.938-.724-1.701-1.614-1.701H3.742c-.89 0-1.614.763-1.614 1.7zM7.434 0c2.587 0 4.682 2.209 4.682 4.934S10.02 9.868 7.434 9.868c-2.585 0-4.68-2.209-4.68-4.934S4.849 0 7.434 0zm0 8.208c1.713 0 3.107-1.47 3.107-3.274 0-1.806-1.394-3.274-3.107-3.274-1.712 0-3.106 1.468-3.106 3.274 0 1.805 1.394 3.274 3.106 3.274z' />
                </SvgIcon>
              )}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end'>
              {this.state.bankFields[index].code.indexOf('pass') >= 0 ? (
                <SvgIcon
                  color='primary'
                  classes={transformClasses(
                    'cargos_inputAdornment_start_icon',
                    classes,
                  )}
                  onClick={() =>
                    this.setState({
                      passVisibility: !this.state.passVisibility,
                    })
                  }
                >
                  <path
                    style={{
                      fill: 'none',
                      stroke: '#fff',
                      strokeMiterlimit: 10,
                    }}
                    d='m 3.3,11.9 c 2.3,-2.8 4.8,-4.2 7.6,-4.2 2.8,0 5.4,1.4 7.9,4.3 v 0 c 0.2,0.2 0.2,0.6 0,0.8 -2.3,2.7 -4.9,4.1 -7.9,4.1 -3,0 -5.5,-1.4 -7.6,-4.2 v 0 c -0.2,-0.3 -0.2,-0.6 0,-0.8 z'
                  />
                  <circle
                    style={{
                      fill: 'none',
                      stroke: '#fff',
                      strokeMiterlimit: 10,
                    }}
                    cx='11'
                    cy='12.3'
                    r='2.9'
                  />
                </SvgIcon>
              ) : (
                <div />
              )}
            </InputAdornment>
          }
        />
      );
    });
    return elements;
  }

  handleFieldChange(event, index) {
    const arr = this.state.bankFields;
    arr[index].value = event.target.value;
    this.setState({
      bankFields: arr,
    });
  }

  onSelectIconBank(bank) {
    console.log('onSelectIconBank', bank);
    const arr = bank.authenticationForm.fields.slice();
    arr.map((field, index) => {
      arr[index].value = '';
      return null;
    });
    this.setState({
      showInputCredentials: true,
      query: bank.name.short,
      selectedBank: bank,
      bankFields: arr,
      filteredBanks: null,
      showAutoComplete: false,
      idSelected: bank.name.full,
    });
  }

  renderAutoComplete() {
    const elements = this.state.filteredBanks.map(bank => {
      return (
        <div
          onClick={() => this.onSelectBank(bank.name.full, bank)}
          key={bank.id}
        >
          <Typography variant='body1' color='primary'>
            {bank.name.full}
          </Typography>
        </div>
      );
    });
    return elements;
  }

  onSelectBank(name, bank) {
    const arr = bank.authenticationForm.fields.slice();
    arr.map((field, index) => {
      arr[index].value = '';
      return null;
    });
    this.setState({
      query: name,
      selectedBank: bank,
      bankFields: arr,
      filteredBanks: null,
      showAutoComplete: false,
    });
  }

  loginBank() {
    this.setState({
      login: true,
      errorLogin: false,
      errorMessage: '',
    });
    const data = {
      bankName: this.state.selectedBank.name.full,
      form: {},
    };
    this.state.bankFields.map(field => {
      data.form[field.code] = field.value;
      return null;
    });
    BanksAPI.loginBank(
      this.props.baseUrl,
      this.state.selectedBank.id,
      data,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        if (data.success) {
          this.getBankData();
          this.props.history.push('/cuentasInitial/accounts/second');
        } else {
          this.setState({
            login: false,
            errorLogin: true,
            errorMessage: data.error.error_message,
          });
          console.log('error: ', data.error.error_message);
        }
      })
      .catch(error => {
        this.setState({
          login: false,
        });
        console.log('error en login banco ', error);
      });
  }

  getBankData() {
    BanksAPI.bankDataAvailable(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          this.handleClose();
          this.setState({
            login: false,
            banksUser: null,
          });
          this.getUserBanks();
        } else {
          this.handleClose();
          this.setState({
            login: false,
          });
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error importando el banco ', error);
      });
  }

  filterBanks(filter) {
    const arr = [];
    this.state.listBanks.map(bank => {
      if (bank.name.full.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
        arr.push(bank);
      }
      return null;
    });
    this.setState({
      filteredBanks: arr,
    });
  }

  onChangeQuery(event) {
    if (event.target.value === '') {
      this.setState({
        showAutoComplete: false,
        query: '',
        selectedBank: null,
      });
    } else {
      this.filterBanks(event.target.value);
      this.setState({
        query: event.target.value,
        showAutoComplete: true,
      });
    }
  }

  selectBankForCredentials() {
    if (this.state.selectedBank !== null) {
      this.setState({
        showInputCredentials: true,
        filteredBanks: null,
        showAutoComplete: false,
      });
    }
  }

  renderText1Header() {
    switch (this.props.location.pathname) {
      case '/cuentasInitial/selector':
        return cargos_strings['es'].cargosInitial_header_text1_option1;
      case '/cuentasInitial/connect':
        return cargos_strings['es'].cargosInitial_header_text1_option2;
      case '/cuentasInitial/accounts/first':
        return cargos_strings['es'].cargosInitial_header_text1_option3;
      default:
        return cargos_strings['es'].cargosInitial_header_text1_option1;
    }
  }

  renderText2Header() {
    switch (this.props.location.pathname) {
      case '/cuentasInitial/selector':
        return cargos_strings['es'].cargosInitial_header_text2_option1;
      case '/cuentasInitial/connect':
        return cargos_strings['es'].cargosInitial_header_text2_option2;
      case '/cuentasInitial/accounts':
        return cargos_strings['es'].cargosInitial_header_text2_option3;
      default:
        return cargos_strings['es'].cargosInitial_header_text2_option1;
    }
  }

  renderButtonText() {
    switch (this.props.location.pathname) {
      case '/cuentasInitial/selector':
        if (this.state.selectedBank) {
          return cargos_strings['es'].cargosInitial_button_text_option1;
        } else {
          return cargos_strings['es'].cargosInitial_button_text_option2;
        }
      case '/cuentasInitial/connect':
        return cargos_strings['es'].cargosInitial_button_text_option3;
      case '/cuentasInitial/accounts':
        return cargos_strings['es'].cargosInitial_button_text_option4;
      default:
        return cargos_strings['es'].cargosInitial_button_text_option1;
    }
  }

  mockUpAñadeBancos() {
    // Hace un clone del array sino es una referencia de puntero
    console.log('mockup 1', this.state.banksUser);
    let newBankUser = JSON.parse(JSON.stringify(this.state.banksUser));
    newBankUser[0].idBank = 'zaragozano';
    this.state.banksUser.push(newBankUser[0]);

    newBankUser = JSON.parse(JSON.stringify(this.state.banksUser));
    newBankUser[0].idBank = 'zangano';
    this.state.banksUser.push(newBankUser[0]);

    newBankUser = JSON.parse(JSON.stringify(this.state.banksUser));
    newBankUser[0].idBank = 'zomozano';
    this.state.banksUser.push(newBankUser[0]);
  }

  renderAccountsEvo() {
    //ºº Mockup
    if (false) {
      this.mockUpAñadeBancos();
    }

    const { classes } = this.props;

    console.log('CargosInitial.renderAccountsEvo', this.state.banksUser);
    return (
      <ActivateAccounts
        baseUrl={this.props.baseUrl}
        token={this.props.token}
        banks={this.state.banksUser.filter(
          bank => bank.idBank === 'evobanco_direct',
        )}
        renderLoading={this.renderLoading.bind(this)}
        activateAccounts={this.activateAccount.bind(this)}
        classes={classes}
        config={ActivateAccountsConfig}
        history={this.props.history}
        onOpen={this.handleOpen.bind(this)}
        useAccountName={true}
      />
    );
  }

  renderOtherAccounts() {
    const { classes } = this.props;
    return (
      <ActivateAccounts
        baseUrl={this.props.baseUrl}
        token={this.props.token}
        banks={this.state.banksUser.filter(
          bank => bank.idBank !== 'evobanco_direct',
        )}
        renderLoading={this.renderLoading.bind(this)}
        activateAccounts={this.activateAccount.bind(this)}
        classes={classes}
        config={ActivateAccountsConfig}
        history={this.props.history}
        onOpen={this.handleOpen.bind(this)}
        useAccountName={false}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    let renderHeader = '';
    //console.log("CargosInitial.render pathname", this.props.location);

    if (this.props.location.pathname !== '/cuentasInitial/accounts/first') {
      renderHeader = (
        <Header
          title={this.renderText1Header()}
          modal={true}
          history={this.props.history}
        />
      );
    } else {
      renderHeader = <Header title={this.renderText1Header()} />;
    }

    return (
      <Fragment>
        {' '}
        {/*classes.movilScreen*/}
        {renderHeader}
        <Body vAlign='top'>
          <Switch>
            <Route
              path='/cuentasInitial/connect'
              render={props => (
                <div className={classes.selectContainer}>
                  <div className={classes.bankIconContainer}>
                    <img
                      alt={this.state.selectedBank.name.short}
                      src={
                        /*this.state.listBanks[i].image*/

                        'https://s3.eu-central-1.amazonaws.com/com.coinscrap.others/public/banks/icons/' +
                        this.state.selectedBank.id +
                        '.png'
                      }
                      className={classes.bankIcon}
                    />
                  </div>
                  <Typography
                    variant='body1'
                    color='primary'
                    classes={transformClasses(
                      'cargos_login_title_typography1',
                      classes,
                    )}
                  >
                    Conéctate
                  </Typography>
                  <CargosInitialConnect
                    {...props}
                    renderInputs={this.renderCredentialsInput.bind(this)}
                  />
                  <Typography
                    variant='body1'
                    color='primary'
                    classes={transformClasses(
                      'cargos_login_disclaimer_typography1',
                      classes,
                    )}
                  >
                    Consulta la{' '}
                    <a
                      href='https://www.evobanco.com/politica-de-privacidad/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.cargos_login_disclaimer_link}
                    >
                      Política de privacidad
                    </a>
                  </Typography>
                </div>
              )}
            />

            <Route
              path='/cuentasInitial/selector'
              render={props =>
                this.state.banksUser !== null &&
                this.state.listBanks !== null ? (
                  <div className={classes.selectContainer}>
                    <SvgIcon
                      color='primary'
                      classes={transformClasses('cargos_svgIcon1', classes)}
                    >
                      <path
                        fillRule='evenodd'
                        d='M20.979 9.434A2.024 2.024 0 0 0 23 7.412V6.058a.676.676 0 0 0-.373-.596L11.847.07a.674.674 0 0 0-.601 0L.374 5.46l-.01.005A.673.673 0 0 0 0 6.058v1.354c0 1.115.907 2.022 2.021 2.022h.674v6.944a2.025 2.025 0 0 0-1.347 1.905v.79A2.025 2.025 0 0 0 0 20.979v1.347c0 .372.302.674.674.674h21.652a.674.674 0 0 0 .674-.674V20.98c0-.879-.564-1.628-1.348-1.906v-.79c0-.878-.563-1.627-1.347-1.905V9.434h.674zm-9.435-8.007l7.928 3.964H3.549l7.995-3.964zM2.695 18.283c0-.371.303-.674.674-.674h16.262c.371 0 .674.303.674.674v.674H2.695v-.674zm1.348-2.021H5.39V9.434H4.043v6.828zm2.695 0h2.786V9.434H6.738v6.828zm4.133 0h1.348V9.434H10.87v6.828zm2.695 0h2.696V9.434h-2.696v6.828zm4.043 0h1.348V9.434H17.61v6.828zm3.37 4.043c.37 0 .673.302.673.674v.673H1.348v-.673c0-.372.302-.674.673-.674H20.98zM2.02 8.085a.675.675 0 0 1-.673-.673v-.674h20.304v.674a.675.675 0 0 1-.673.674H2.02z'
                      />
                    </SvgIcon>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_title_typography2',
                        classes,
                      )}
                    >
                      Escoge un banco
                    </Typography>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_body_typography2',
                        classes,
                      )}
                    >
                      Elige la entidad de la que quieres añadir una cuenta.
                    </Typography>
                    <CargosInitialSelect
                      {...props}
                      classes={classes}
                      renderSelect={this.renderNewBanksSelector.bind(this)}
                    />
                  </div>
                ) : (
                  <div />
                )
              }
            />

            <Route
              path='/cuentasInitial/others'
              render={props =>
                this.state.banksUser !== null &&
                this.state.listBanks !== null ? (
                  <div className={classes.selectContainer}>
                    <CargosInitialOthers
                      {...props}
                      classes={classes}
                      renderOthers={this.renderOthersSelector.bind(this)}
                    />
                  </div>
                ) : (
                  <div />
                )
              }
            />

            <Route
              path='/cuentasInitial/accounts/first'
              render={props =>
                this.state.banksUser !== null ? (
                  <Fragment>
                    {' '}
                    {/*classes.selectContainer*/}
                    <SvgIcon
                      viewBox='0 0 28 28'
                      color='primary'
                      classes={transformClasses('cargos_svgIcon1', classes)}
                    >
                      <path d='M 27.153778,12.520278 C 27.620833,12.520278 28,12.9045 28,13.377778 v 8.005278 c 0,0.473666 -0.379167,0.857888 -0.846222,0.857888 h -1.189222 v 2.450778 C 25.964556,26.516 24.5,28 22.699833,28 H 3.264333 C 1.463778,28 0,26.516 0,24.691722 V 10.069889 C 0,8.245222 1.463778,6.761611 3.264333,6.761611 h 19.4355 c 1.800167,0 3.264723,1.483611 3.264723,3.308278 v 2.450389 z m -2.882056,12.171444 v -2.450778 h -7.788278 c -1.800166,0 -3.264333,-1.483611 -3.264333,-3.308277 v -3.104111 c 0,-1.824667 1.464167,-3.308278 3.264333,-3.308278 h 7.788278 V 10.069889 C 24.271722,9.191778 23.566667,8.477 22.699833,8.477 h -19.4355 c -0.866444,0 -1.5715,0.714778 -1.5715,1.592889 v 14.621833 c 0,0.878111 0.705056,1.592889 1.5715,1.592889 h 19.4355 c 0.866834,0 1.571889,-0.714778 1.571889,-1.592889 z m 2.035445,-4.166166 v -6.289889 h -9.823723 c -0.866055,0 -1.571888,0.714389 -1.571888,1.592889 v 3.104111 c 0,0.878111 0.705833,1.592889 1.571888,1.592889 z M 17.572722,2.275389 11.091111,5.290833 H 7.033444 L 18.404944,0 20.801278,5.290833 h -1.862389 z m 0.390834,14.991667 c 0,0.608611 -0.487278,1.1025 -1.088112,1.1025 -0.600444,0 -1.088111,-0.493889 -1.088111,-1.1025 0,-0.609 0.487667,-1.102889 1.088111,-1.102889 0.600834,0 1.088112,0.493889 1.088112,1.102889 z' />
                    </SvgIcon>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_title_typography1',
                        classes,
                      )}
                    >
                      Escoge tus cuentas
                    </Typography>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_body_typography1',
                        classes,
                      )}
                    >
                      Escoge tus cuentas EVO en las que quieres aplicar tus
                      redondeos de ahorro. Y si quieres ahorrar aún más, añade
                      tus cuentas de otros bancos. ¡Es muy sencillo!
                    </Typography>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_evo_title_typography1',
                        classes,
                      )}
                    >
                      Cuentas y Tarjetas EVO
                    </Typography>
                    <CargosInitialAccounts
                      {...props}
                      renderAccounts={this.renderAccountsEvo.bind(this)}
                    />
                    <div className={classes.cargos_separator}>
                      <hr className={classes.cargos_hr} />
                    </div>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_addAccount_typography1',
                        classes,
                      )}
                      onClick={() =>
                        Flow.navigate('/cuentasInitial/selector', this.props)
                      }
                    >
                      Añadir cuentas de otros bancos{' '}
                      <SvgIcon
                        color='primary'
                        classes={transformClasses(
                          'cargos_addAccount_svgIcon',
                          classes,
                        )}
                      >
                        <path d='M 19 13 h -6 v 6 h -2 v -6 H 5 v -2 h 6 V 5 h 2 v 6 h 6 v 2 Z' />
                      </SvgIcon>
                    </Typography>
                  </Fragment>
                ) : (
                  <div />
                )
              }
            />

            <Route
              path='/cuentasInitial/accounts/second'
              render={props =>
                this.state.banksUser !== null ? (
                  <div className={classes.selectContainer}>
                    <SvgIcon
                      viewBox='0 0 28 28'
                      color='primary'
                      classes={transformClasses('cargos_svgIcon1', classes)}
                    >
                      <path d='M 27.153778,12.520278 C 27.620833,12.520278 28,12.9045 28,13.377778 v 8.005278 c 0,0.473666 -0.379167,0.857888 -0.846222,0.857888 h -1.189222 v 2.450778 C 25.964556,26.516 24.5,28 22.699833,28 H 3.264333 C 1.463778,28 0,26.516 0,24.691722 V 10.069889 C 0,8.245222 1.463778,6.761611 3.264333,6.761611 h 19.4355 c 1.800167,0 3.264723,1.483611 3.264723,3.308278 v 2.450389 z m -2.882056,12.171444 v -2.450778 h -7.788278 c -1.800166,0 -3.264333,-1.483611 -3.264333,-3.308277 v -3.104111 c 0,-1.824667 1.464167,-3.308278 3.264333,-3.308278 h 7.788278 V 10.069889 C 24.271722,9.191778 23.566667,8.477 22.699833,8.477 h -19.4355 c -0.866444,0 -1.5715,0.714778 -1.5715,1.592889 v 14.621833 c 0,0.878111 0.705056,1.592889 1.5715,1.592889 h 19.4355 c 0.866834,0 1.571889,-0.714778 1.571889,-1.592889 z m 2.035445,-4.166166 v -6.289889 h -9.823723 c -0.866055,0 -1.571888,0.714389 -1.571888,1.592889 v 3.104111 c 0,0.878111 0.705833,1.592889 1.571888,1.592889 z M 17.572722,2.275389 11.091111,5.290833 H 7.033444 L 18.404944,0 20.801278,5.290833 h -1.862389 z m 0.390834,14.991667 c 0,0.608611 -0.487278,1.1025 -1.088112,1.1025 -0.600444,0 -1.088111,-0.493889 -1.088111,-1.1025 0,-0.609 0.487667,-1.102889 1.088111,-1.102889 0.600834,0 1.088112,0.493889 1.088112,1.102889 z' />
                    </SvgIcon>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_title_typography1',
                        classes,
                      )}
                    >
                      Escoge tus cuentas
                    </Typography>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_body_typography1',
                        classes,
                      )}
                    >
                      Escoge tus cuentas EVO en las que quieres aplicar tus
                      redondeos de ahorro. Y si quieres ahorrar aún más, añade
                      tus cuentas de otros bancos. ¡Es muy sencillo!
                    </Typography>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_evo_title_typography1',
                        classes,
                      )}
                    >
                      Cuentas EVO
                    </Typography>
                    <CargosInitialAccounts
                      {...props}
                      renderAccounts={this.renderAccountsEvo.bind(this)}
                    />
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_accounts_evo_title_typography2',
                        classes,
                      )}
                    >
                      Otros bancos
                    </Typography>
                    {/*
                    <CargosInitialAccounts
                      {...props}
                      renderAccounts={this.renderOtherAccounts.bind(this)}
                    />
                    */}
                    <div className={classes.cargos_separator}>
                      <hr className={classes.cargos_hr} />
                    </div>
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        'cargos_addAccount_typography1',
                        classes,
                      )}
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/cuentasInitial/selector',
                          state: {
                            back: this.props.location.pathname,
                          },
                        })
                      }
                    >
                      Añadir cuentas de otros bancos{' '}
                      <SvgIcon
                        color='primary'
                        classes={transformClasses(
                          'cargos_addAccount_svgIcon',
                          classes,
                        )}
                      >
                        <path d='M 19 13 h -6 v 6 h -2 v -6 H 5 v -2 h 6 V 5 h 2 v 6 h 6 v 2 Z' />
                      </SvgIcon>
                    </Typography>
                  </div>
                ) : (
                  <div />
                )
              }
            />

            <Route
              render={props =>
                this.state.banksUser !== null &&
                this.state.listBanks !== null ? (
                  <CargosInitialSelect
                    {...props}
                    classes={classes}
                    renderSelect={this.renderNewBanksSelector.bind(this)}
                  />
                ) : (
                  <div />
                )
              }
            />
          </Switch>
        </Body>
        {this.props.location.pathname !== '/cuentasInitial/others' ? (
          <Footer>
            <Button
              variant='contained'
              color='secondary'
              //classes={transformClasses('cargos_button2', classes)}
              onClick={this.handleNext.bind(this)}
            >
              {this.renderButtonText()}
            </Button>
          </Footer>
        ) : (
          <div></div>
        )}
        {/*}      
        <div
          className={
            this.props.location.pathname !== '/cuentasInitial/others'
              ? classes.cargos_enButton_container
              : classes.hidden
          }
        >
          <Button
            variant='contained'
            color='secondary'
            classes={transformClasses('cargos_button2', classes)}
            onClick={this.handleNext.bind(this)}
          >
            {this.renderButtonText()}
          </Button>
        </div>
      */}
      </Fragment>
    );
  }
}

export const styles = theme => ({
  ...ActivateAccountsStyles(theme),
  movilScreen: {
    minHeight: 640,
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  targetAccounts_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  header_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12px',
  },
  cargos_headerIconButton1_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  cargosInitial_cancel_svgIcon1_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.32px',
  },
  cargos_container: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
    overflow: 'auto',
  },
  header_centerIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cargos_headerTypography1_root: {
    fontSize: '20px',
    lineHeight: '22.5px',
    textAlign: 'center',
  },
  cargos_headerTypography2_root: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cargos_headerIconButton2_root: {
    padding: 'unset',
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  container: {
    height: '90vh',
    backgroundColor: theme.palette.primary.main,
    paddingTop: 12,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalDiv: {
    height: '80vh',
    minHeight: 454,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
    textAlign: 'center',
    '&:focus': {
      outline: 'unset',
    },
  },
  selectorDiv: {
    height: '80vh',
    minHeight: 454,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  banksShowcaseDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    padding: 24,
  },
  banksShowcaseFirstRow: {
    height: '20vh',
    minHeight: 114,
    width: '100%',
    display: 'flex',
    marginBottom: 6,
  },
  banksShowcaseSecondRow: {
    height: '20vh',
    minHeight: 114,
    width: '100%',
    display: 'flex',
    marginTop: 6,
  },
  banksShowcaseFirstCell: {
    width: '50%',
    height: '20vh',
    minHeight: 114,
    padding: '0 12px',
    display: 'flex',
    backgroundColor: '#A9A9A9',
    marginRight: 6,
  },
  banksShowcaseSecondCell: {
    width: '50%',
    height: '20vh',
    minHeight: 114,
    padding: '0 12px',
    display: 'flex',
    backgroundColor: '#A9A9A9',
    marginLeft: 6,
  },
  banksShowcaseImg: {
    maxWidth: '100%',
    height: 'auto',
    opacity: 0.7,
  },
  bankIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '4px',
  },
  headerBack: {
    height: '10vh',
    minHeight: 57,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  bankIcon: {
    width: '52px',
    height: 'auto',
  },
  bankInputContainer: {
    width: '100%',
    padding: '0 24px',
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '10vh',
    minHeight: 57,
    width: '100%',
    padding: '0px 12px 12px',
    justifyContent: 'space-between',
  },
  inputContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 36px',
  },
  input1_root: {
    marginTop: 24,
    border: 'solid 3px ' + theme.palette.primary.main,
  },
  input1_input: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  input2_root: {
    backgroundColor: 'transparent',
    width: '100%',
    marginBottom: '22px',
  },
  input2_input: {
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  input2_underline: {
    borderBottom: 'solid 1px rgba(255,255,255,0.098)',
  },
  input3_root: {
    marginTop: 12,
    border: 'solid 3px black',
  },
  cargos_enButton_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
  cargos_button2_root: {
    width: '312px',
    height: '40px',
    borderRadius: 'unset',
    boxShadow: 'unset',
    marginBottom: '24px',
  },
  cargos_button2_label: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  typography7_root: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  typography8_root: {
    color: theme.palette.primary.main,
  },
  typography9_root: {
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: 24,
  },
  endAdornment1: {
    color: theme.palette.primary.main,
  },
  searchIcon_root: {
    color: theme.palette.primary.main,
  },
  selectContainer: {
    marginTop: '24px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  activateAccounts_typography1_root: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  activateAccounts_typography5_root: {
    fontSize: 12,
    color: theme.palette.primary.dark,
  },
  notificationsList_iconButton2_root: {
    color: theme.palette.primary.main,
  },
  notificationsList_iconButton2_colorSecondary: {
    color: theme.palette.secondary.main,
  },
  activateAccounts_button_container: {
    display: 'none',
  },
  cargos_svgIcon1_root: {
    height: '23.6px',
    width: '23.9px',
    marginBottom: '23px',
  },
  cargos_accounts_title_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    lineHeight: '39.4px',
    letterSpacing: '-1px',
    textAlign: 'center',
    width: '309px',
    height: '49px',
    marginBottom: '9px',
  },
  cargos_accounts_title_typography2_root: {
    fontSize: '45px',
    fontWeight: 100,
    lineHeight: '39.4px',
    letterSpacing: '-1px',
    textAlign: 'center',
    width: '274px',
    marginBottom: '7px',
  },
  cargos_accounts_body_typography1_root: {
    fontSize: '16px',
    textAlign: 'center',
    width: '300px',
    height: '96px',
    marginBottom: '16px',
  },
  cargos_accounts_body_typography2_root: {
    fontSize: '16px',
    textAlign: 'center',
    width: '248px',
    marginBottom: '18px',
  },
  cargos_accounts_evo_title_typography1_root: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
    //width: '85px',
    height: '21px',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    marginLeft: '24px',
    fontWeight: 600,
    marginBottom: '7px',
  },
  cargos_accounts_evo_title_typography2_root: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
    width: '95px',
    height: '21px',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    marginLeft: '24px',
    fontWeight: 600,
    marginBottom: '7px',
    marginTop: '11px',
  },
  cargos_separator: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
    marginBottom: '16px',
  },
  cargos_hr: {
    width: '312px',
    marginBlockEnd: 'unset',
    marginBlockStart: 'unset',
    borderColor: 'rgba(255,255,255,0.2)',
    borderWidth: '0.5px',
  },
  cargos_addAccount_typography1_root: {
    fontSize: '14px',
    lineHeight: '15px',
    fontWeight: 600,
    width: '312px',
    textTransform: 'uppercase',
    marginBottom: '39.5px',
  },
  cargos_addAccount_svgIcon_root: {
    height: '10px',
    width: '10px',
    marginLeft: '2px',
  },
  cargos_bankSelector_container: {
    width: '100%',
    //height: '284px',
    //padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    //marginBottom: '80px'
  },
  cargos_bankSelector_icons_container: {
    height: '88px',
    width: '100px',
    backgroundColor: 'rgba(255,255,255,0.098)',
    borderRadius: '4px',
    margin: 5,
  },
  cargos_bankSelector_icons_container_selected: {
    height: '88px',
    width: '100px',
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: '4px',
  },
  cargos_bankSelector_new_container: {
    height: '88px',
    width: '100px',
    backgroundColor: 'rgba(255,255,255,0.098)',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cargos_bankSelector_icons: {
    width: '100%',
    height: '100%',
  },
  cargos_bankSelector_svgIcon_root: {
    height: '10px',
    width: '10px',
    marginBottom: '6px',
  },
  cargos_bankSelector_typography1_root: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '15px',
    textAlign: 'center',
    width: '52px',
    textTransform: 'uppercase',
  },
  cargos_login_title_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    width: '164px',
    lineHeight: '39.4px',
    letterSpacing: '-1px',
    textAlign: 'center',
    marginBottom: '61px',
  },
  cargos_inputAdornment_start_icon_root: {
    height: '28px',
    width: '28px',
    marginRight: '17px',
    marginBottom: '12px',
  },
  cargos_login_disclaimer_typography1_root: {
    fontSize: '14.2px',
    fontWeight: 300,
    lineHeight: '15px',
    alignSelf: 'flex-start',
    marginLeft: '24px',
  },
  cargos_login_disclaimer_link: {
    textDecoration: 'underline',
    color: 'white',
  },
  cargos_others_selector_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 24px',
    flex: 1,
    overflow: 'auto',
  },
  cargos_othersInput_root: {
    width: '100%',
    borderBottom: '1px solid rgba(255,255,255,0.098)',
  },
  cargos_othersInput_input: {
    color: 'rgba(255,255,255,1)',
    '&::placeholder': {
      color: 'rgba(255,255,255,.5)',
    },
  },
  cargosOther_list: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.098)',
    padding: '12px 24px',
    marginBottom: '12px',
  },
  cargosOther_list_img: {
    height: '40px',
    width: 'auto',
    marginRight: '8px',
  },
  hidden: {
    display: 'none',
  },
});

export default withStyles(styles, { withTheme: true })(CargosInitial);
