import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './clientEnv';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store';
import Root from './root';
import ApiContext from './ApiContext';

console.log('env', window.__CLIENT_ENV);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#f4137b',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Calibre'].join(','),
  },
});

function App() {
  const [tokenApiContext, setTokenApiContext2] = useState(null);
  const [baseUrlApiContext, setBaseUrlApiContext2] = useState(null);
  //ºº
  return (
    <CssBaseline>
      {/* <MuiThemeProvider theme={theme}> */}
      <ApiContext.Provider
        value={{
          token: tokenApiContext,
          baseUrl: baseUrlApiContext,
          setToken: value => setTokenApiContext2(value),
          setBaseUrl: value => setBaseUrlApiContext2(value),
        }}
      >
        <Root store={store} persistor={persistor} />
      </ApiContext.Provider>
      {/* </MuiThemeProvider> */}
    </CssBaseline>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);

serviceWorker.unregister();
