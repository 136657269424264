import React, { Component } from 'react';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SvgIcon from '@material-ui/core/SvgIcon';

//import { Utils } from '../../core/ces';
import { Utils } from '../../src-core/resources/index';

import Flow from '../../components/Flow';
import ProgressBar from '../../components/MyProgressBar';
import Currency from '../../components/Currency';

class ListTargetsUser extends Component {
  constructor(props) {
    super(props);

    // ºº Q coño hace esto!!
    const array = Array.apply(false, Array(this.props.targets.lenght)).map(
      function () {
        return true;
      },
    );

    this.state = {
      toggle: array,
    };
  }

  getPercentage(total, type) {
    let value = 0;
    this.props.summary.targetsInfo.forEach(info => {
      if (info.type === type) {
        value += info.target.progress.completed;
      }
    });
    if (total > 0) {
      return (value / total) * 100;
    } else {
      return 0;
    }
  }

  toggle(index, event) {
    event.preventDefault();
    const array = this.state.toggle;
    array[index] = !array[index];
    this.setState({ toggle: array });
  }

  targetCardClick(id) {
    console.log('targetCardClick', this.props);

    Flow.navigate('/followup/' + id + '/Gráficos', this.props, {
      objectiveType: this.props.type,
    });
  }

  renderListItems() {
    const { classes } = this.props;
    console.log('renderListItems', this.props.targets);
    const transformClasses = Utils.transformClasses;
    // Lista de Objetivos
    const items = this.props.targets.map((element, index) => {

      const lastTransferStatus = (element.type === 'retirement' && element.status === 'ACTIVE' && element?.transfers) ? element.transfers[(element.transfers.length - 1)]?.pensionTransferStatus : false;
      // Objetivo
      return (
        <div
          id='targetCard'
          className={classes.listTargetsUser_divContainer}
          key={element._id}
          style={{ backgroundImage: 'url(' + element.image + ')' }}
        >
          <div
            style={{
              backgroundColor: 'rgba(0,0,0,.6)',
              width: '312px',
              height: '128px',
              padding: '16px',
              position: 'relative',
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('listTargetsUser_typography1', classes)}
              style={{ textAlign: 'left' }}
            >
              {element.name.toLowerCase()}
            </Typography>
            {element.status === 'SENT' && (
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography1',
                  classes,
                )}
                style={{
                  position: 'absolute',
                  right: 39,
                  top: 10,
                  background: '#f4137b',
                  paddingLeft: 5,
                  paddingTop: 5,
                  borderRadius: 5,
                  paddingRight: 5,
                }}
                onClick={() => Flow.navigate('/documents/' + element._id, this.props)}
              >
                Pendiente de Firmar
              </Typography>
            )}
            {element.status === 'POLICY_CREATED' && (
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography1',
                  classes,
                )}
                style={{
                  position: 'absolute',
                  right: 39,
                  top: 10,
                  background: '#f4137b',
                  paddingLeft: 5,
                  paddingTop: 5,
                  borderRadius: 5,
                  paddingRight: 5,
                }}
              >
                Activación en curso...
              </Typography>
            )}
            {/* {element.status === 'PENDING' && (
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography1',
                  classes,
                )}
                style={{
                  position: 'absolute',
                  right: 39,
                  top: 10,
                  background: '#f4137b',
                  paddingLeft: 5,
                  paddingTop: 5,
                  borderRadius: 5,
                  paddingRight: 5,
                }}
                onClick={() => Flow.navigate('/documents', this.props)}
              >
                Sin finalizar
              </Typography>
            )} */}
            {lastTransferStatus && lastTransferStatus?.status !== '' && (
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listTargetsUser_typography1',
                  classes,
                )}
                style={{
                  position: 'absolute',
                  right: 39,
                  top: 10,
                  background: lastTransferStatus?.status === 'transferred' ? '#0DC8B3' : '#f4137b',
                  paddingLeft: 5,
                  paddingTop: 5,
                  borderRadius: 5,
                  paddingRight: 5,
                }}
              >
                {lastTransferStatus.label}
              </Typography>
            )
            }
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('listTargetsUser_typography2', classes)}
              onClick={() => this.targetCardClick(element._id)}
            >
              {Currency(element.progress.completed, 2)}
            </Typography>
            <div
              className={classes.listTargetsUser_progress_container}
              onClick={() => this.targetCardClick(element._id)}
            >
              <div
                className={classes.listTargetsUser_progress_container_firstRow}
              >
                <Typography
                  variant='body1'
                  color='primary'
                  classes={transformClasses(
                    'listTargetsUser_typography3',
                    classes,
                  )}
                >
                  {(
                    ((element.progress.completed > element.progress.total
                      ? element.progress.total
                      : element.progress.completed) /
                      element.progress.total) *
                    100
                  ).toFixed(0) + '%'}
                </Typography>
                <Typography
                  variant='body1'
                  color='primary'
                  classes={transformClasses(
                    'listTargetsUser_typography4',
                    classes,
                  )}
                >
                  {Currency(element.progress.total)}
                </Typography>
              </div>
              <div
                className={classes.listTargetsUser_progress_container_secondRow}
              >
                <div
                  className={
                    this.props.type === 'nextWish'
                      ? classes.listTargetsUser_progress_container_secondRow_nextWish
                      : this.props.type === 'familyTarget'
                        ? classes.listTargetsUser_progress_container_secondRow_familyTarget
                        : classes.listTargetsUser_progress_container_secondRow_retirement
                  }
                  style={{
                    width:
                      (280 *
                        (((element.progress.completed > element.progress.total
                          ? element.progress.total
                          : element.progress.completed) /
                          element.progress.total) *
                          100)) /
                      100,
                  }}
                />
              </div>
              <div
                className={classes.listTargetsUser_progress_container_thirdRow}
              >
                <Typography
                  variant='body1'
                  color='primary'
                  classes={transformClasses(
                    'listTargetsUser_typography5',
                    classes,
                  )}
                >
                  Total acumulado
                </Typography>
                <Typography
                  variant='body1'
                  color='primary'
                  classes={transformClasses(
                    'listTargetsUser_typography6',
                    classes,
                  )}
                >
                  Total objetivo
                </Typography>
              </div>
            </div>
            <SvgIcon
              color='primary'
              classes={transformClasses(
                'listTargetUser_progress_svgIcon',
                classes,
              )}
              onClick={() => this.targetCardClick(element._id)}
            >
              <path d='M0 0h24v24H0z' fill='none' />
              <path d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
            </SvgIcon>
          </div>
        </div>
      );
    });
    return items;
  }

  old_renderListItems() {
    const { classes, config, progressBarConfig } = this.props;
    const transformClasses = Utils.transformClasses;
    const items = this.props.targets.map((element, index) => {
      return (
        <div className={classes.listTargetsUser_divContainer} key={element._id}>
          <ListItem
            {...config.listItem}
            onClick={e => this.toggle(index, e)}
            classes={transformClasses('listTargetsUser_listItem1', classes)}
          >
            <div
              className={classes.listTargetsUser_divImage}
              style={{
                backgroundImage: 'url(' + element.image + ')',
              }}
            >
              <div className={classes.listTargetsUser_divImage_overlay} />
              <div className={classes.listTargetsUser_textDiv}>
                <Typography
                  {...config.typography1}
                  classes={transformClasses(
                    'listTargetsUser_typography1',
                    classes,
                  )}
                >
                  {element.name.toLowerCase()}
                </Typography>
                <Typography
                  {...config.typography2}
                  classes={transformClasses(
                    'listTargetsUser_typography2',
                    classes,
                  )}
                >
                  {(
                    (element.progress.completed / element.progress.total) *
                    100
                  ).toFixed(2) + '%'}
                </Typography>
              </div>
              <div className={classes.listTargetsUser_textCompleteDiv}>
                <Typography
                  {...config.typography4}
                  classes={transformClasses(
                    'listTargetsUser_typography4',
                    classes,
                  )}
                >
                  {Currency(element.progress.completed) +
                    ' de ' +
                    Currency(element.progress.total) +
                    ''}
                </Typography>
              </div>
              <ProgressBar
                config={progressBarConfig}
                classes={classes}
                completed={element.progress.completed}
                total={element.progress.total}
              />
            </div>
          </ListItem>
          <Collapse in={this.state.toggle[index]}>
            <Card
              {...config.card}
              classes={transformClasses('listTargetsUser_card1', classes)}
            >
              <CardActions
                {...config.cardActions}
                classes={transformClasses(
                  'listTargetsUser_cardActions1',
                  classes,
                )}
              >
                <Button
                  {...config.button}
                  classes={transformClasses('listTargetsUser_button1', classes)}
                  onClick={e => this.props.goToConfig(element._id, e)}
                >
                  Ir
                </Button>
              </CardActions>
            </Card>
          </Collapse>
        </div>
      );
    });
    return items;
  }

  render() {
    //const { listConfig } = this.props;
    // const listConfigCustom = {
    //   ...listConfig,
    //   children: this.renderListItems(),
    // };
    return this.renderListItems();
  }
}

export default ListTargetsUser;
