function generateStyles(theme) {
  return {
    accounts_container: {
      display: 'flex',
      paddingTop: 10,
      flexDirection: 'column',
      height: '100vh',
      minHeight: 568,
      overflow: 'auto',
      background: theme.palette.primary.main,
    },
    accounts_gridContainer1_container: {
      overflow: 'auto',
      marginBottom: '10vh',
      minHeight: 57,
    },
    accounts_gridContainer2_container: {
      border: '3px solid #000',
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
    },
    accounts_gridContainer3_container: {
      paddingTop: 5,
    },
    accounts_gridItem1_item: {
      maxHeight: 64,
    },
    accounts_iconBank: {
      maxHeight: 48,
      marginRight: 12,
    },
    accounts_typography1_root: {
      fontWeight: 600,
    },
    accounts_typography2_root: {
      marginLeft: 8,
      fontSize: 12,
      color: '#808080',
    },
    accounts_typography4_root: {
      //wordBreak: 'break-all',
      fontSize: '2vw',
      fontWeight: 600,
    },
    accounts_typography5_root: {
      fontSize: 12,
      color: '#808080',
    },
    accounts_typography6_root: {
      marginLeft: 12,
    },
    accounts_button_container: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      padding: '0 10px 10px',
    },
    accounts_button1_root: {
      width: '100%',
      borderRadius: 'unset',
      fontWeight: 600,
    },
    accounts_tabsDiv: {
      width: '100%',
      marginBottom: 16,
      paddingLeft: 10,
      paddingRight: 10,
    },
    accounts_tab1_root: {
      fontWeight: 600,
    },
    accounts_tab2_root: {
      fontWeight: 600,
    },
    accounts_tab1_textColorSecondary: {
      color: '#000',
    },
    accounts_tab2_textColorSecondary: {
      color: '#000',
    },
    accounts_modal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    accounts_modalDiv: {
      height: '50vh',
      minHeight: 284,
      width: '90%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
      textAlign: 'center',
      border: 'solid black 3px',
    },
    accounts_buttonDiv: {
      display: 'flex',
      width: '100%',
      padding: '0px 16px',
      justifyContent: 'space-between',
      marginTop: 20,
    },
    accounts_button2_root: {
      width: '100%',
      borderRadius: 'unset',
    },
    collapse_container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    collapse_textContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bankContainer: {
      marginBottom: 12,
      width: '100%',
    },
    bankContainerTitle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
    },
    accounts_collapse1_container: {
      width: '100%',
      backgroundColor: theme.palette.primary.dark,
    },
    accounts_collapse1_wrapperInner: {
      width: '100%',
      paddingLeft: 12,
      paddingRight: 12,
    },
    accounts_iconButton1_root: {
      color: '#808080',
      padding: 'unset',
      marginRight: 12,
    },
    bankContainerTitle_textContainer: {
      width: '100%',
    },
    accounts_accounts_container: {
      minWidth: '300px',
      minHeight: '88px',
      padding: '10px',
      borderRadius: '4px',
      display: 'flex',
      backgroundColor: 'rgba(255,255,255,0.098)',
      marginBottom: '8px',
      position: 'relative',
    },
    accounts_accounts_imageBank: {
      width: 40, // '18px',
      //height: '24px',
    },
    accounts_accounts_leftPanel_container: {
      width: 'calc(100%/6)',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    accounts_accounts_centerPanel_container: {
      width: 'calc((((100%/6)*5)/10)*7)',
      alignItems: 'flex-start',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    accounts_accounts_rightPanel_container: {
      width: 'calc((((100%/6)*5)/10)*3)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    accounts_accounts_title_typography1_root: {
      fontSize: '11px',
      lineHeight: '18px',
      letterSpacing: '0px',
      width: '40px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    accounts_accounts_account_typography1_root: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '16.9px',
    },
    accounts_accounts_currency_typography1_root: {
      fontSize: '24px',
      fontWeight: 100,
      lineHeight: '16.9px',
    },
    targetBasic_button1_container: {
      width: '100%',
      marginBottom: '10px',
    },
    targetBasic_button1: {
      width: '100%',
      borderRadius: 'unset',
      fontWeight: 600,
      boxShadow: 'unset',
    },
  };
}

export default generateStyles;
