import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Button } from 'coinscrap-webapp-core';

import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import Flow from '../../components/Flow';
import Icon from '../../components/MyIcon';
import Icons from '../../components/MyIcon.config';
import Container from '../../components/MyContainer';

import DocView from '../../components/MyDocView';
import { useSelector } from 'react-redux';
//import Header from '../../components/MyHeader';

import { retirementGeneralGraphData } from './RetirementData/carteraData';

/*
  Tarjeta de tipos de carteras por nivel de riesgo
*/
const ProductCard = props => {
  const [openRentInfo, setOpenRentInfo] = useState(false);
  const [openCopyFiniz, setOpenCopyFiniz] = useState(false);
  const [openCodigosTraspaso, setOpenCodigosTraspaso] = useState(false);

  const [openMorePdf, setOpenMorePdf] = useState(false);
  const objectiveType = useSelector(state => state.main.objectiveType);
  const flowParams = Flow.getParams();

  const CommonRetirement = () => {
    return (
      <>
        <Text font14St blackSt>
          Los fondos de pensiones EVO ofrecen acceso a la inversión pasiva más
          eficiente y diversificada del mercado mediante una estrategia de
          gestión indexada reconocida a nivel global.
        </Text>
        <Text font14St blackSt>
          Logran obtener la mejor rentabilidad ajustada al nivel de riesgo de
          cada partícipe, gestionando carteras compuestas por los mejores fondos
          indexados de las gestoras más prestigiosas y exclusivas del mundo como
          Vanguard, Blackrock, Amundi..., bajo una filosofía de gestión pasiva
          automatizada mediante la aplicación de algoritmos de éxito globalmente
          reconocido.
        </Text>
        <Text font14St blackSt>
          Los planes de pensiones de EVO tienen una elevada diversificación
          global con más de 14.700 posiciones distintas, tanto por mercados como
          por tipología de activos, incluyendo:{' '}
        </Text>
        <ul>
          <li style={{ color: 'black', fontSize: 14, lineHeight: 1.2 }}>
            - Renta fija: bonos de gobiernos Europa, bonos corporativos Europa y
            bonos internacionales.
          </li>
          <li style={{ color: 'black', fontSize: 14, lineHeight: 1.2 }}>
            - Renta variable: acciones en Europa, EE.UU., Japón y Países
            Emergentes.
          </li>
          <li style={{ color: 'black', fontSize: 14, lineHeight: 1.2 }}>
            - Bienes reales: oro físico y sector inmobiliario global (REITs).
          </li>
        </ul>
        <Text font14St blackSt>
          Nuestros planes de pensiones cuentan con 3 niveles de riesgo. El
          perfil de riesgo asignado a cada partícipe se identifica en función de
          sus respuestas al test de idoneidad digital.
        </Text>
      </>
    );
  };

  const copiesPerfilInversor = {
    conservador:
      objectiveType === 'retirement' ? (
        <View>
          <CommonRetirement />
          <Text font14St blackSt>
            Este plan está dirigido a inversores con tolerancia al riesgo
            medio-baja. El fondo está clasificado dentro de la categoría Renta
            Fija Mixta.
          </Text>
          <Text font14St blackSt>
            La inversión se realiza esencialmente en activos de renta fija
            (83,0%), y en menor medida en activos de renta variable (12,5%),
            bienes reales como oro o propiedad (2,5%), y efectivo (2,0%). Los
            instrumentos de inversión utilizados son vehículos de inversión
            indexados, principalmente ETFs y fondos de inversión.
          </Text>
        </View>
      ) : (
        'Los  productos  recomendados  son  idóneos  para  Usted  al  tratarse  de productos dirigidos a clientes que tienen como principal objetivo la estabilidad  patrimonial  pero  también  buscan  protección  frente  a  la inflación, por lo que admite una exposición baja al riesgo (exposición máxima a activos en renta variable del 35%). Para intentar superar a la inflación, admiten la posibilidad de que la rentabilidad de la cartera pueda ser negativa temporalmente, aunque con una frecuencia, duración y dimensión relativamente bajas. Tienen necesidad de alta liquidez, y suelen invertir a corto y a largo plazo. Por lo regular no poseen un gran conocimiento y experiencia en productos similares.'
      ),
    moderado:
      'Los  productos  recomendados  son  idóneos  para  Usted  al  tratarse  de productos dirigidos a clientes que tienen como principal objetivo el equilibrio entre la estabilidad y el crecimiento patrimonial, por lo que admiten una exposición intermedia al riesgo (exposición máxima a activos en renta variable del 55%). Para optar al crecimiento patrimonial, admiten la posibilidad de que la rentabilidad  de  la  cartera  pueda  ser  negativa  temporalmente  con  una frecuencia, duración y dimensión media. Tienen necesidad de alta liquidez, y suelen invertir a medio o a largo plazo. Por lo regular poseen un conocimiento y experiencia medios en productos similares.',
    dinámico:
      objectiveType === 'retirement' ? (
        <View>
          <CommonRetirement />
          <Text font14St blackSt>
            Este plan está dirigido a inversores con tolerancia media al riesgo.
            El fondo está clasificado dentro de la categoría Renta Variable
            Mixta.
          </Text>
          <Text font14St blackSt>
            La inversión se realiza equitativamente entre activos de renta fija
            (48,0%) y activos de renta variable(42,5%), con una menor exposición
            a bienes reales como oro o propiedad (7,5%), y efectivo (2,0%). Los
            instrumentos de inversión utilizados son vehículos de inversión
            indexados, principalmente ETFs y fondos de inversión.
          </Text>
        </View>
      ) : (
        'Los  productos  recomendados  son  idóneos  para  Usted  al  tratarse  de productos dirigidos a clientes que tienen como principal objetivo el crecimiento patrimonial, por lo que admiten una exposición significativa al riesgo (exposición máxima a activos en renta variable del 70%). Para optar al crecimiento patrimonial, admiten la posibilidad de obtener rentabilidad negativa, que puede ser frecuente a corto plazo y que en determinados periodos puede ser significativa y duradera. Tienen necesidad de liquidez baja, y suelen invertir a corto plazo, pero la mayor parte de su dinero lo suelen invertir a largo plazo. Por lo regular poseen un alto conocimiento y experiencia en productos similares.'
      ),
    animado:
      'Los  productos  recomendados  son  idóneos  para  Usted  al  tratarse  de productos dirigidos a clientes que tienen como principal objetivo el crecimiento patrimonial, por lo que admiten una exposición significativa al riesgo (exposición máxima a activos en renta variable del 85%). Para optar al crecimiento patrimonial, admiten la posibilidad de obtener rentabilidad negativa, que pueden ser frecuente a corto plazo y que en determinados periodos puede ser significativa y duradera. Tienen necesidad de liquidez baja, y suelen invertir a corto plazo, pero la mayor parte de su dinero lo suelen invertir a largo plazo. Por lo regular poseen un alto conocimiento y experiencia en productos similares.',
    agresivo:
      objectiveType === 'retirement' ? (
        <View>
          <CommonRetirement />
          <Text font14St blackSt>
            Este plan está dirigido a inversores con tolerancia al riesgo alta .
            El fondo está clasificado dentro de la categoría Renta Variable.
          </Text>
          <Text font14St blackSt>
            La inversión se realiza esencialmente en activos de renta variable
            (68,0%) y en menor medida en activos de renta fija (18,0%), bienes
            reales como oro o propiedad (12,0%), y efectivo (2,0%). Los
            instrumentos de inversión utilizados son vehículos de inversión
            indexados, principalmente ETFs y fondos de inversión.
          </Text>
        </View>
      ) : (
        'Los  productos  recomendados  son  idóneos  para  Usted  al  tratarse  de productos dirigidos a clientes que tienen como principal objetivo el crecimiento patrimonial, por lo que admiten una exposición significativa al riesgo (exposición máxima a activos en renta variable del 100%). Para optar al crecimiento patrimonial, admiten la posibilidad de obtener rentabilidad negativa, que puede ser frecuente a corto plazo y que en determinados periodos puede ser significativa y duradera. Tienen necesidad de liquidez baja, y suelen invertir a corto plazo, pero la mayor parte de su dinero lo suelen invertir a largo plazo. Por lo regular poseen un alto conocimiento y experiencia en productos similares.'
      ),
  };
  // console.log('propis ', props)
  const jsonCodigosParaTraspaso = (plan = '', cart = '', gest = '') => {
    return {
      plan: plan || '',
      cartera: cart || '',
      gestora: gest || '',
    };
  };
  const codigosParaTraspaso = {
    conservador: jsonCodigosParaTraspaso('N5383', 'F1753', 'G0219'),
    agresivo: jsonCodigosParaTraspaso('N5381', 'F1819', 'G0219'),
    dinámico: jsonCodigosParaTraspaso('N5382', 'F1827', 'G0219'),
  };

  console.log({ codigosParaTraspaso });
  console.log(
    'propis->',
    props.name.toLowerCase(),
    codigosParaTraspaso[props.name.toLowerCase()],
  );

  return (
    <Container
      id={props.name + 'Card'}
      key={props.keyAux}
      style={ProductCardStyle.divImgContainer}
    >
      <div style={ProductCardStyle.titleContainer}>
        <ModalInfo2
          open={openCopyFiniz}
          texto={copiesPerfilInversor[props.name.toLowerCase()]}
          onClose={() => setOpenCopyFiniz(false)}
        />
        {/* <Typography
          variant='body1'
          color='primary'
          style={ProductCardStyle.typography1}
        >
          {props.title}
        </Typography> */}
        <Typography
          variant='body1'
          color='primary'
          style={ProductCardStyle.typography2}
          onClick={() => setOpenCopyFiniz(true)}
        >
          {props.name}
          <Icon
            name='info'
            style={{
              width: 15,
              marginTop: -8,
              marginLeft: 7,
            }}
          />
        </Typography>
      </div>
      {props?.type === 'retirement' && (
        <div style={ProductCardStyle.infoContainer}>
          <ModalInfoCodigosTraspaso
            open={openCodigosTraspaso}
            data={codigosParaTraspaso[props.name.toLowerCase()]}
            onClose={() => setOpenCodigosTraspaso(false)}
          />
          <div
            style={ProductCardStyle.dataContainer}
            style={{
              width: '84%',
              background: 'rgba(255, 255, 255, 0.098)',
              padding: '5px 20px',
              color: '#fff',
              margin: '10px auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={() => setOpenCodigosTraspaso(true)}
          >
            CÓDIGOS PARA TRASPASO
            <Icon
              name='info'
              style={{
                width: 15,
              }}
            />
          </div>
        </div>
      )}
      <div style={ProductCardStyle.infoContainer}>
        <div style={ProductCardStyle.dataContainer}>
          <Typography
            variant='body1'
            color='primary'
            style={ProductCardStyle.typography3}
          >
            Perfil
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            style={{
              ...ProductCardStyle.typography2,
              flexFlow: 'row',
              display: 'flex',
            }}
          >
            {flowParams.objectiveType === 'retirement'
              ? props.name === 'Conservador'
                ? 4
                : props.name === 'Dinámico'
                ? 4
                : props.name === 'Agresivo'
                ? 5
                : 0
              : props.riskFactor}
            /
            <span
              variant='body1'
              color='primary'
              style={{
                fontSize: 23,
                marginTop: 20,
                fontWeight: 200,
              }}
            >
              {flowParams.objectiveType === 'retirement' ? 7 : 5}
            </span>
          </Typography>
        </div>

        <div style={ProductCardStyle.dataContainer}>
          <ModalInfo
            open={openRentInfo}
            onClose={() => setOpenRentInfo(false)}
          />
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
            }}
            onClick={() => setOpenRentInfo(true)}
          >
            <Typography
              variant='body1'
              color='primary'
              style={ProductCardStyle.typography3}
            >
              Rentabilidad
            </Typography>
            <Icon
              name='info'
              style={{
                width: 15,
                marginTop: -8,
                marginLeft: 7,
              }}
            />
          </div>

          <Typography
            variant='body1'
            color='primary'
            style={ProductCardStyle.typography2}
          >
            {(props.profitability * 100).toFixed(2)} %
          </Typography>
          {flowParams.objectiveType === 'retirement' && (
            <Text style={{ fontSize: 10 }}>* media 15 últimos años</Text>
          )}
        </div>
      </div>
      <div style={ProductCardStyle.footerContainer}>
        <div
          id='historicoButton'
          style={{
            ...ProductCardStyle.iconContainer,
          }}
          onClick={() =>
            Flow.navigate('/product/fundHistorical', props, {
              profitability: props.profitability,
              productName: props.name,
            })
          }
        >
          <Icon
            name='historial'
            style={{ width: 30, height: 30, marginBottom: 10 }}
          />
          <Typography
            variant='body1'
            color='primary'
            style={ProductCardStyle.typography5}
          >
            Histórico
          </Typography>
        </div>

        <div
          id='fondosButton'
          style={{
            ...ProductCardStyle.iconContainer,
            borderLeft: 'solid 1px rgba(255,255,255,.3)',
          }}
          onClick={() =>
            Flow.navigate('/product/funds/types', props, {
              recomendedId: props.recomendedId,
              productName: props.name,
            })
          }
        >
          <Icon
            name='graficasLinea'
            style={{ width: 30, height: 30, marginBottom: 10 }}
          />
          <Typography
            variant='body1'
            color='primary'
            style={ProductCardStyle.typography5}
          >
            Fondos
          </Typography>
        </div>

        {props.pdf_more && (
          <div
            style={{
              ...ProductCardStyle.iconContainer,
              borderLeft: 'solid 1px rgba(255,255,255,.3)',
            }}
            //onClick={() => setOpenMorePdf(true)}
          >
            <Icon
              name='atencion'
              style={{ width: 30, height: 30, marginBottom: 10 }}
              onClick={() => setOpenMorePdf(true)}
            />
            <Typography
              variant='body1'
              color='primary'
              style={ProductCardStyle.typography5}
              onClick={() => setOpenMorePdf(true)}
            >
              Más info
            </Typography>

            <DocView
              open={openMorePdf}
              onClose={() => {
                setOpenMorePdf(false);
                console.log('Cierra', openMorePdf);
              }}
              url={props.pdf_more}
              styleType='black'
              title='Mas información'
              leftIcon={Icons.disquete}
              disk
            />
          </div>
        )}
      </div>
    </Container>
  );
};

/*
 PropTypes ------------------------------------------
*/

ProductCard.propTypes = {
  keyAux: PropTypes.number,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  historicalPdf: PropTypes.string.isRequired,
  //fmiPdf: PropTypes.string.isRequired,
  riskFactor: PropTypes.number.isRequired,
  //maxRiskFactor: PropTypes.number.isRequired,
  profitability: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

/*
 Estilos ------------------------------------------
*/

const ProductCardStyle = {
  divImgContainer: {
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.098)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
  },

  dataContainer: {
    width: 125,
    margin: 4,
    backgroundColor: 'rgba(255,255,255,0.098)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    height: 120,
    //paddingBottom: 15,
  },
  currentProfileContainer2: {
    width: '128px',
    backgroundColor: 'rgba(255,255,255,0.098)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    paddingTop: '29px',
    paddingBottom: '18px',
  },
  footerContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 20,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '20px',
    paddingLeft: '20px',
    textDecoration: 'none',
    width: 100,
  },
  titleContainer: {
    textAlign: 'center',
    //marginBottom: 20,
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  typography1: {
    fontSize: '11px',
    fontWeight: 600,
    marginTop: '20px',
  },
  typography2: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    lineHeight: 1.3,
  },

  typography3: {
    fontSize: '11px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
};

/*
 Ventana Modal ------------------------------------------
*/

function ModalInfo(props) {
  const params = Flow.getParams();
  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => props.onClose(false)}
    >
      <div
        style={{
          width: '312px',
          backgroundColor: '#e9e9e9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '14px',
          borderRadius: '8px',
          '&:focus': {
            outline: 'unset',
          },
          fontFamily: 'Calibre',
        }}
      >
        {params.objectiveType !== 'retirement'
          ? 'Fuente: Morningstar, retorno histórico anualizado de tu cartera basado en datos reales de mercado relativos a los últimos 15 años.'
          : `Fuente: Morningstar, retorno histórico anualizado de tu cartera basado en datos reales de mercado relativos a los últimos 15 años.`}
      </div>
    </Modal>
  );
} /*
 Ventana Modal 2 ---------------------------------------
*/

function ModalInfo2(props) {
  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => props.onClose(false)}
    >
      <div
        style={{
          width: '312px',
          backgroundColor: '#e9e9e9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '14px',
          borderRadius: '8px',
          '&:focus': {
            outline: 'unset',
          },
          fontFamily: 'Calibre',
          height: 300,
          overflowY: 'scroll',
        }}
      >
        {props.texto}
      </div>
    </Modal>
  );
}

function ModalInfoCodigosTraspaso(props) {
  console.log(props);
  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '10px solid #f60',
      }}
      onClick={() => props.onClose(false)}
    >
      <div
        style={{
          // width: '312px',
          backgroundColor: '#e9e9e9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '20px',
          borderRadius: '8px',
          '&:focus': {
            outline: 'unset',
          },
          fontFamily: 'Calibre',
          // height: 300,
          overflowY: 'scroll',
        }}
      >
        <div>
          <Text
            style={{
              color: '#000',
              textAlign: 'left',
              padding: '5px 0',
              borderBottom: '1px solid #999',
            }}
          >
            CÓDIGOS PARA TRASPASO
          </Text>
          <View spaceBetweenSt flexRowSt style={{ marginTop: 20, padding: 5 }}>
            <Text font14St blackSt font16St>
              PLAN
            </Text>
            <Text font14St blackSt font16St>
              {props.data.plan}
            </Text>
          </View>
          <View spaceBetweenSt flexRowSt style={{ padding: 5 }}>
            <Text font14St blackSt font16St>
              CARTERA
            </Text>
            <Text font14St blackSt font16St>
              {props.data.cartera}
            </Text>
          </View>
          <View spaceBetweenSt flexRowSt style={{ padding: 5 }}>
            <Text font14St blackSt font16St>
              GESTORA
            </Text>
            <Text font14St blackSt font16St>
              {props.data.gestora}
            </Text>
          </View>
        </div>
        {/* <div>{props.data.plan}</div> */}
      </div>
    </Modal>
  );
}

// function ModalMorePdf(props) {
//   const url =
//     'https://docs.google.com/viewer?url=' +
//     encodeURIComponent(props.url) +
//     '&embedded=true';
//   console.log('ModalMorePdf', props, url);

//   return (
//     <Modal
//       open={props.open}
//       style={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}
//     >
//       <Header
//         rigthAction={() => {
//           props.onClose();
//         }}
//         title={'Más información'}
//       />
//       {url && (
//         <iframe
//           title='myFrame'
//           src={url}
//           frameBorder='0'
//           height='100%'
//           width='100%'
//         />
//       )}{' '}
//       */}
//     </Modal>
//   );
// }

export default ProductCard;
