// Agresivo / Atrevido - 5
export const retirementAgressive = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Oro',
      'Liquidez'
    ],
    data: [
      15.50,
      74.77,
      3.73,
      1.00,
      5.00,
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Pacífico',
    ],
    data: [
      39.47,
      28.12,
      13.16,
      12.19,
      7.06,
    ],
  },

  fondos: {
    labels: [
      'Xtrackers II US Treasuries ETF 2D - EURH',
      'Vanguard Eurozone Government Bond ETF',
      'Vanguard USD Corp Bd ETF EUR H Acc',
      'iShares € Inflation Linked Govt Bond UCITS ETF',
      'Vanguard Euro Corporate Bond ETF',
      'iShares $ Treasury Bd 1-3yr ETF EURH Acc',
      'SPDR Blmbrg Bcly 1-3 Yr EUR Govt BdETF',
      'iShares € Ultrashort Bond ETF EUR Acc',
      'iShares $ Floating Rate Bd ETF EURH Dist',
      'iShares Emerging Markets Government Bond Index H',
      'Lyxor Core STOXX Europe 600 ETF',
      'Vanguard S&P 500 ETF',
      'Schwab US Broad Market ETF',
      'SPDR Portfolio S&P 500 ETF',
      'Invesco PureBeta MSCI USA ETF',
      'SPDR Portfolio Emerging Markets ETF',
      'iShares Core MSCI Pacific ETF',
      'Schwab Emerging Markets Equity ETF™',
      'Schwab US Large-Cap ETF',
      'iShares Core S&P Total US Stock Mkt ETF',
      'Schwab US Small-Cap ETF™',
      'Vanguard FTSE All-Wld ex-US SmCp ETF',
      'Vanguard FTSE Pacific ETF',
      'iShares Core MSCI Emerging Markets ETF',
      'iShares Global REIT ETF',
      'Liquidez',
      'Invesco Physical Gold ETC',
    ],
    data: [
      2.57,
      2.56,
      2.15,
      2.08,
      1.56,
      1.25,
      1.24,
      0.84,
      0.84,
      0.41,
      18.84,
      8.70,
      4.80,
      4.80,
      4.80,
      4.80,
      4.80,
      4.80,
      4.80,
      3.50,
      3.41,
      2.28,
      2.26,
      2.18,
      3.73,
      1.00,
      5.00,
    ],
  },
};

// Dinamico / Equilibrado - 3
export const retirementDynamic = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Oro',
      'Liquidez'
    ],
    data: [
      44.00,
      47.62,
      2.38,
      1.00,
      5.00,
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Pacífico',
    ],
    data: [
      36.54,
      35.39,
      14.95,
      8.62,
      4.50,
    ],
  },

  fondos: {
    labels: [
      'Xtrackers II US Treasuries ETF 2D - EURH',
      'Vanguard Eurozone Government Bond ETF',
      'Vanguard USD Corp Bd ETF EUR H Acc',
      'Lyxor Cr € Govt Infl-Lnkd Bd (DR) ETFAcc',
      'Vanguard Euro Corporate Bond ETF',
      'iShares $ Treasury Bd 1-3yr ETF EURH Acc',
      'SPDR Blmbrg Bcly 1-3 Yr EUR Govt BdETF',
      'iShares € Ultrashort Bond ETF EUR Acc',
      'iShares $ Floating Rate Bd ETF EURH Dist',
      'iShares Emerging Markets Government Bond Index H',
      'Lyxor Core STOXX Europe 600 ETF',
      'Schwab US Broad Market ETF',
      'SPDR Portfolio S&P 500 ETF',
      'Schwab Emerging Markets Equity ETF™',
      'Schwab US Large-Cap ETF',
      'iShares Core MSCI Pacific ETF',
      'Invesco PureBeta MSCI USA ETF',
      'iShares Core S&P Total US Stock Mkt ETF',
      'SPDR Portfolio Emerging Markets ETF',
      'Schwab US Small-Cap ETF™',
      'Vanguard FTSE All-Wld ex-US SmCp ETF',
      'iShares Global REIT ETF',
      'Liquidez',
      'Invesco Physical Gold ETC',
    ],
    data: [
      7.29,
      7.27,
      6.12,
      5.91,
      4.43,
      3.54,
      3.54,
      2.39,
      2.39,
      1.12,
      12.00,
      4.80,
      4.80,
      4.80,
      4.80,
      4.50,
      2.80,
      2.80,
      2.70,
      2.17,
      1.45,
      2.38,
      1.00,
      5.00,
    ],
  },
};

// Conservador - 1
export const retirementConservative = {
  tipo: {
    labels: [
      'Renta fija',
      'Renta variable',
      'Inmuebles',
      'Oro',
      'Liquidez'
    ],
    data: [
      72.50,
      20.48,
      1.02,
      5.00,
      1.00,
    ],
  },

  regiones: {
    labels: [
      'Estados Unidos',
      'Europa',
      'Global',
      'Mercados emergentes',
      'Pacífico',
    ],
    data: [
      44.94,
      31.33,
      16.72,
      5.07,
      1.94,
    ],
  },

  fondos: {
    labels: [
      'Xtrackers II US Treasuries ETF 2D - EURH',
      'Vanguard USD Corp Bd ETF EUR H Acc',
      'Lyxor Cr € Govt Infl-Lnkd Bd (DR) ETFAcc',
      'Vanguard Eurozone Government Bond ETF',
      'Amundi Prime Euro Corporate ETF DR (C)',
      'iShares $ Treasury Bd 1-3yr ETF EURH Acc',
      'SPDR Blmbrg Bcly 1-3 Yr EUR Govt BdETF',
      'iShares € Ultrashort Bond ETF EUR Acc',
      'iShares $ Floating Rate Bd ETF EURH Dist',
      'Amundi Prime Euro Govies ETF DR (C)',
      'iShares Emerging Markets Government Bond Index H',
      'Lyxor Core STOXX Europe 600 ETF',
      'Schwab US Broad Market ETF',
      'Schwab US Large-Cap ETF',
      'Schwab Emerging Markets Equity ETF™',
      'iShares Core MSCI Pacific ETF',
      'Schwab US Small-Cap ETF™',
      'Vanguard FTSE All-Wld ex-US SmCp ETF',
      'iShares Global REIT ETF',
      'Liquidez',
      'Invesco Physical Gold ETC',
    ],
    data: [
      12.02,
      10.08,
      9.74,
      8.50,
      7.30,
      5.83,
      5.83,
      3.94,
      3.94,
      3.48,
      1.84,
      5.15,
      4.30,
      4.30,
      3.23,
      1.94,
      0.94,
      0.62,
      1.02,
      1.00,
      5.00,
    ],
  },
};

export const retirementFunds = [
  {
    name: 'Amundi Prime Euro Govies ETF DR',
    longName: '',
    objetivos: 'Replicar la evolución del índice "100% Solactive Eurozone Government Bond Index". Invierte en bonos gubernamentales de alta calificación (grado de inversión o “investment grade” en inglés) crediticia en la Zona Euro.',
    gestora: 'Amundi',
    codigoISIN: 'LU2089238898',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '05/02/2019',
    categoriaInversion: 'Renta Fija Gobiernos Euro',
    tamañoFondo: '581 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,05% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Vanguard Eurozone Government Bond ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice "Bloomberg Barclays EuroAggregate Corporate Index". Invierte en bonos de gobiernos europeos con una alta calificación crediticia (grado de inversión o “investment grade” en inglés).',
    gestora: 'Vanguard',
    codigoISIN: 'IE00BH04GL39',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '19/02/2019',
    categoriaInversion: 'Renta Fija Gobiernos Euro',
    tamañoFondo: '889 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,07% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Xtrackers II Euro Government Bond',
    longName: '',
    objetivos: 'Replicar la evolución del índice "iBoxx Euro Sovereigns Eurozone TR Index". Invierte en bonos gubernamentales de alta calificación crediticia (grado de inversión o “investment grade” en inglés) en la Zona Euro.',
    gestora: 'DWS',
    codigoISIN: 'LU0643975591',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'No',
    fechaLanzamiento: '24/08/2011',
    categoriaInversion: 'Renta Fija Gobiernos Euro',
    tamañoFondo: '3.699 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,09% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'iShares Core € Govt Bond UCITS ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice "Bloomberg Barclays Euro Treasury Bond Index". Invierte en bonos de gobiernos europeos con una alta calificación crediticia (grado de inversión o “investment grade” en inglés).',
    gestora: 'BlackRock',
    codigoISIN: 'IE00B4WXJJ64',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'No',
    fechaLanzamiento: '17/04/2009',
    categoriaInversion: 'Renta Fija Gobiernos Euro',
    tamañoFondo: '3.297 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,09% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Amundi Prime Euro Corporate ETF DR (C)',
    longName: '',
    objetivos: 'Replicar la evolución del índice "100% solactive Euro IG Corporate Index". Invierte en bonos de interés fijo emitidos en euros por empresas con una alta calificación crediticia (grado de inversión o “investment grade” en inglés).',
    gestora: 'Amundi',
    codigoISIN: 'LU2089238625',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '05/02/2019',
    categoriaInversion: 'Renta Fija Euro Corporativa',
    tamañoFondo: '773 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,05% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Vanguard Euro Corporate Bond ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice "Bloomberg Barclays EuroAggregate Corporate Index". Invierte en bonos de interés fijo emitidos en euros por empresas con una alta calificación crediticia (grado de inversión o “investment grade” en inglés).',
    gestora: 'Vanguard',
    codigoISIN: 'IE00BGYWT403',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '19/02/2019',
    categoriaInversion: 'Renta Fija Euro Corporativa',
    tamañoFondo: '627 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,09% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Vanguard Global Aggregate Bond ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice "Bloomberg Barclays Global Aggregate Float Adjusted and Scaled Index". Invierte en bonos de alta calificación crediticia (grado de inversión o “investment grade” en inglés) globales emitidos por gobiernos, agencias y empresas.',
    gestora: 'Vanguard',
    codigoISIN: 'IE00BG47KH54',
    divisa: '€ euro',
    claseCubierta: 'Sí',
    reinversion: 'Sí',
    fechaLanzamiento: '18/06/2019',
    categoriaInversion: 'Renta Fija Agregada Global',
    tamañoFondo: '736 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,10% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'iShares Core Global Aggregate Bond ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice "Bloomberg Barclays Global Aggregate Bond Index Hedged". Invierte en bonos de alta calificación crediticia (grado de inversión o “investment grade” en inglés) globales emitidos por gobiernos, agencias y empresas.',
    gestora: 'BlackRock',
    codigoISIN: 'IE00BDBRDM35',
    divisa: '€ euro',
    claseCubierta: 'Sí',
    reinversion: 'Sí',
    fechaLanzamiento: '27/11/2017',
    categoriaInversion: 'Renta Fija Agregada Global',
    tamañoFondo: '3.729 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,10% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'SPDR Bloomberg Barclays Global Aggregate Bond ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice "Bloomberg Barclays Global Aggregate Bond Index". Invierte en bonos de alta calificación crediticia (grado de inversión o “investment grade” en inglés)  globales emitidos por gobiernos, agencias y empresas.',
    gestora: 'State Street',
    codigoISIN: 'IE00BF1QPL78',
    divisa: '€ euro',
    claseCubierta: 'Sí',
    reinversion: 'No',
    fechaLanzamiento: '14/02/2018',
    categoriaInversion: 'Renta Fija Agregada Global',
    tamañoFondo: '822 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,10% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Lyxor Core STOXX Europe 600 ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice “STOXX Europe 600 NR EUR”. Invierte en acciones de empresas europeas de gran y mediana capitalización bursátil.',
    gestora: 'Lyxor',
    codigoISIN: 'LU0908500753',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '03/04/2013',
    categoriaInversion: 'Renta Variable Europea',
    tamañoFondo: '1.926 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,07% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Vanguard FTSE Developed Europe ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice “FTSE Developed Europe Index”. Invierte en acciones de empresas europeas de gran y mediana capitalización bursátil.',
    gestora: 'Vanguard',
    codigoISIN: 'IE00B945VV12',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'No',
    fechaLanzamiento: '21/05/2013',
    categoriaInversion: 'Renta Variable Europea',
    tamañoFondo: '2.045 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,10% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Schwab US Large-Cap ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice “Dow Jones US Large Cap Total Stock Market Index TR”. Invierte en acciones de empresas estadounidenses de gran capitalización bursátil.',
    gestora: 'Schwab',
    codigoISIN: 'US8085242019',
    divisa: '$ usd',
    claseCubierta: 'No',
    reinversion: 'No',
    fechaLanzamiento: '03/11/2009',
    categoriaInversion: 'Renta Variable USA',
    tamañoFondo: '18.455 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,03% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Vanguard S&P 500 ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice “S&P 500“. Invierte en acciones de empresas estadounidenses de gran capitalización bursátil.',
    gestora: 'Vanguard',
    codigoISIN: 'IE00BFMXXD54',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '14/05/2019',
    categoriaInversion: 'Renta Variable USA',
    tamañoFondo: '22.066 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,07% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Nomura TOPIX ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice “S&P 500“. Invierte en acciones de empresas estadounidenses de gran capitalización bursátil.',
    gestora: 'Vanguard',
    codigoISIN: 'IE00BFMXXD54',
    divisa: '¥ yen',
    claseCubierta: 'No',
    reinversion: 'No',
    fechaLanzamiento: '11/07/2001',
    categoriaInversion: 'Renta Variable Japonesa',
    tamañoFondo: '99.457 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,11% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'iShares Core MSCI EM IMI ETF',
    longName: '',
    objetivos: 'Replicar la evolución del índice “S&P 500“. Invierte en acciones de empresas estadounidenses de gran capitalización bursátil.',
    gestora: 'Vanguard',
    codigoISIN: 'IE00BKM4GZ66',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '30/05/2014',
    categoriaInversion: 'Renta Variable Emergente',
    tamañoFondo: '12.580 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,18% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'iShares Developed Real Estate Index Fund',
    longName: '',
    objetivos: 'Replicar la evolución del índice "FTSE EPRA/Nareit Developed Index”. Invierte en el sector inmobiliario global principalmente en Estados Unidos.',
    gestora: 'BlackRock',
    codigoISIN: 'IE00BDRK7P73',
    divisa: '€ euro',
    claseCubierta: 'No',
    reinversion: 'Sí',
    fechaLanzamiento: '06/06/2017',
    categoriaInversion: 'Bienes Raíces Globales',
    tamañoFondo: '1.731.965 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,20% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Invesco Physical Gold ETC',
    longName: '',
    objetivos: 'Replicar la evolución del índice "LBMA Gold Price". Invierte en certificados de oro físico respaldados por lingotes de oro depositados en las cámaras acorazadas del banco JP Morgan en Londres.',
    gestora: 'Invesco',
    codigoISIN: 'IE00B579F325',
    divisa: '$ usd',
    claseCubierta: 'No',
    reinversion: 'N/A',
    fechaLanzamiento: '26/06/2009',
    categoriaInversion: 'Oro Físico / Commodities',
    tamañoFondo: '10.279 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,20% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
  {
    name: 'Amundi Physical Gold ETC',
    longName: '',
    objetivos: 'Replicar la evolución del índice "100% London Gold Market Fixing LTD LBMA PM Fixing Price/USD". Invierte en certificados de oro físico respaldados por lingotes de oro depositados en las cámaras acorazadas del banco HSBC.',
    gestora: 'Amundi',
    codigoISIN: 'FR0013416716',
    divisa: '$ usd',
    claseCubierta: 'No',
    reinversion: 'N/A',
    fechaLanzamiento: '21/05/2019',
    categoriaInversion: 'Oro Físico / Commodities',
    tamañoFondo: '3.148 millones de Euros*',
    claseFondo: 'Institucional',
    gastosCorrientes: '0,15% anual',
    notas: '*Tamaño del instrumento financiero calculado a 01/07/2020',
  },
];
