import './buttonConfig';
import './textFieldConfig';
import './textConfig';
import './viewConfig';
import './enfatizeConfig';
import './layoutConfig';
import './textCurrencyConfig';
import './imageSelectorConfig';
import './inputSliderConfig';
import './inputSelectConfig';
import './inputSwipeConfig';
import './inputDateConfig';
import './inputSwitchConfig';
import './inputCheckConfig';
import './imageConfig';
import './radioOptionConfig';

export default {};
