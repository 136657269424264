export const rulesList_strings = {
    es: {
        rulesList_target_selector_text: 'Elige el objetivo de ahorro',
        rulesList_target_selector_button_text: 'Aceptar',
        rulesList_target_ammount_text: 'Elige la cantidad a aportar',
        rulesList_target_ammount_button_text: 'Aceptar',
        rulesList_target_success_text: '¡Enhorabuena aportación realizada con éxito!',
        rulesList_target_success_button_text: 'Ok',
        rulesList_popover_text: 'Ahorro realizado',
        rulesList_button_text1: 'Ahorrar',
        rulesList_button_text2: 'Editar',
        rulesList_button_text3: 'Aplicar',
        rulesList_button_text4: 'Prémiate'
    }
}