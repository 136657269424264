import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

//import { UsersAPI } from '../../core/
import { UsersAPI } from '../../src-core/sdk/index';

//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

import SwipeableViewsConfigurable from '../swipeableViews/swipeableViewsConfigurable.component';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: null,
      index: 0,
      total: null,
      answers: null,
      answersSet: false,
      query: '',
      showAutocomplete: false,
      autoComplete: null,
      arrayIDs: [],
      continueSurvey: true,
    };
    //console.log('Survey', this.props, this.state);
  }

  componentDidMount() {
    console.log(this.props.baseUrl);

    // UsersAPI.getSurvey(this.props.baseUrl, 'idd-evo', this.props.token)
    // UsersAPI.getSurvey(this.props.baseUrl, 'idd-evo', this.props.token)
    UsersAPI.getSurvey(this.props.baseUrl, 'idd-evo', this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        const answers = {};
        const arrayIDs = this.state.arrayIDs.slice();
        data.questions.map(question => {
          // Cambios para Evo
          //console.log('SurveyEVO', question);
          if (question.title === 'Rentabilidad del patrimonio') {
            question.title = 'Rentabilidad de tu patrimonio';
          }
          arrayIDs.push(question._id);
          if (question.responseType === 'number') {
            answers[question._id] = {
              value: '',
              type: question.responseType,
            };
          } else {
            if (question.deferredItems) {
              answers[question._id] = {
                value: '',
                type: question.responseType,
                deferredItems: true,
              };
            } else {
              answers[question._id] = {
                value: question.items[question.defaultValue]._id,
                type: question.responseType,
                deferredItems: false,
              };
            }
          }
          return null;
        });
        this.setState({
          survey: data,
          total: data.questions.length,
          answers,
          arrayIDs,
        });
        UsersAPI.getSurveyAnswers(
          this.props.baseUrl,
          this.state.survey._id,
          this.props.token,
        )
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw response.status;
            }
          })
          .then(data => {
            const answers = this.state.answers;
            data.map(answer => {
              if (answer.questionType === 'number') {
                answers[answer.question].value = answer.value;
              } else {
                if (answers[answer.question].deferredItems) {
                  answers[answer.question].value = answer.itemData._id;
                  this.setState({
                    query: answer.itemData.title,
                  });
                } else {
                  answers[answer.question].value = answer.itemData._id;
                }
              }
              return null;
            });
            this.setState({
              answers,
              answersSet: true,
            });
          });
      })
      .catch(error => {
        console.log('error recuperando la survey ', error);
      });
  }

  renderSurveySlides() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const total = this.state.survey.questions.length;
    const elements = this.state.survey.questions.map((question, index) => {
      //console.log('renderSurveySlides', question, index);
      if (question.responseType === 'number') {
        return (
          <div className={classes.numberContainer} key={question._id}>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('survey_typography3', classes)}
            >
              {question.title + ' (' + (index + 1) + '/' + total + ')'}
            </Typography>
            <Input
              classes={transformClasses('survey_input1', classes)}
              placeholder={question.placeholder}
              value={this.state.answers[question._id].value}
              type={question.responseType}
              disableUnderline
              onChange={e => this.onChangeNumber(e, question._id)}
            />
          </div>
        );
      } else {
        return (
          <div
            className={classes.optionsContainer}
            key={question._id}
            id={'question' + index}
          >
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('survey_typography3', classes)}
            >
              {index + 1 + '/' + total + '  ' + question.title}
            </Typography>
            {!question.deferredItems ? (
              <RadioGroup
                value={this.state.answers[question._id].value}
                classes={transformClasses('survey_radioGroup', classes)}
              >
                {this.renderOptions(question)}
              </RadioGroup>
            ) : (
              <div className={classes.autoCompleteContainer}>
                <Input
                  classes={transformClasses('survey_input1', classes)}
                  placeholder={question.placeholder}
                  value={this.state.query}
                  disableUnderline
                  onChange={e => this.onChangeQuery(e, question._id)}
                />
                {this.state.showAutocomplete ? (
                  <List classes={transformClasses('survey_list1', classes)}>
                    {this.renderAutoComplete(question._id)}
                  </List>
                ) : (
                  <div />
                )}
              </div>
            )}
          </div>
        );
      }
    });
    return elements;
  }

  renderOptions(question) {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = question.items.map((option, index) => {
      return (
        <FormControlLabel
          key={option._id}
          value={option._id}
          label={option.title}
          labelPlacement='start'
          control={
            <Radio
              color='secondary'
              classes={transformClasses('survey_radio1', classes)}
            />
          }
          onChange={() => this.onChangeOption(option._id, question._id)}
          classes={
            index === question.items.length - 1
              ? transformClasses('survey_formControlLabel2', classes)
              : transformClasses('survey_formControlLabel1', classes)
          }
          id={'option' + index}
        />
      );
    });
    return elements;
  }

  renderAutoComplete(questionID) {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.autoComplete.map(element => {
      return (
        <ListItem
          key={element._id}
          classes={transformClasses('survey_listItem1', classes)}
          onClick={() => this.onCLickListItem(element, questionID)}
        >
          <Typography variant='body1' color='primary'>
            {element.title}
          </Typography>
        </ListItem>
      );
    });
    return elements;
  }

  onChangeOption(optionID, questionID) {
    const answers = { ...this.state.answers };
    answers[questionID].value = optionID;
    this.setState({
      answers,
    });
  }

  onChangeNumber(event, questionID) {
    const answers = { ...this.state.answers };
    answers[questionID].value = event.target.value;
    this.setState({
      answers,
    });
  }

  onChangeQuery(event, questionID) {
    const value = event.target.value;
    this.setState({
      query: value,
    });
    if (value !== '') {
      UsersAPI.getQuestionItems(
        this.props.baseUrl,
        questionID,
        value,
        this.props.token,
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.status;
          }
        })
        .then(data => {
          this.setState({
            autoComplete: data,
            showAutocomplete: true,
          });
        })
        .catch(error => {
          console.log('error recuperando al autocomplete ', error);
        });
    } else {
      this.setState({
        autoComplete: null,
        query: '',
        showAutocomplete: false,
      });
    }
  }

  onCLickListItem(element, questionID) {
    const answers = { ...this.state.answers };
    answers[questionID].value = element._id;
    this.setState({
      query: element.title,
      answers,
      showAutocomplete: false,
      autoComplete: null,
    });
  }

  handleChangeIndex(index, lastIndex) {
    this.setState({
      index,
    });
  }

  goBack() {
    if (
      this.state.answers[this.state.arrayIDs[this.state.index]].value === ''
    ) {
      return null;
    } else {
      if (this.state.index <= 0) {
        return null;
      } else {
        this.setState({
          index: this.state.index - 1,
        });
      }
    }
  }

  goNext() {
    if (
      this.state.answers[this.state.arrayIDs[this.state.index]].value === ''
    ) {
      return null;
    } else {
      if (this.state.index >= this.state.total - 1) {
        if (this.props.location.state === undefined) {
          this.props.history.push('/survey/completed');
        } else {
          console.log('state survey: p s', this.props, this.state);
          this.props.history.push({
            pathname: '/survey/completed',
            state: {
              type: this.props.location.state.type,
              founds: this.props.location.state.founds,
            },
          });
        }
      } else {
        this.setState({
          index: this.state.index + 1,
        });
      }
    }
  }

  setQuestion(way) {
    const data = {};
    if (
      this.state.answers[this.state.arrayIDs[this.state.index]].value === ''
    ) {
    } else {
      if (
        this.state.answers[this.state.arrayIDs[this.state.index]].type ===
        'number'
      ) {
        data['value'] = this.state.answers[
          this.state.arrayIDs[this.state.index]
        ].value;
      } else {
        data['questionItem'] = this.state.answers[
          this.state.arrayIDs[this.state.index]
        ].value;
      }
      UsersAPI.setQuestion(
        this.props.baseUrl,
        this.state.survey._id,
        this.state.arrayIDs[this.state.index],
        data,
        this.props.token,
      )
        .then(response => {
          if (response.status === 200) {
            return null;
          } else {
            throw response.status;
          }
        })
        .catch(error => {
          console.log('error guardando valor de la question ', error);
        });
      if (way === 'next') {
        this.goNext();
      } else {
        this.goBack();
      }
    }
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const swipeConfig = {
      axis: 'x',
      enableMouseEvents: true,
      hysteresis: 0.5,
      resistance: true,
      disabled: true,
    };

    return this.state.answersSet ? (
      this.state.continueSurvey ? (
        <Fragment>
          <Body>
            <SwipeableViewsConfigurable
              index={this.state.index}
              handleChangeIndex={this.handleChangeIndex.bind(this)}
              config={swipeConfig}
              child={this.renderSurveySlides()}
              classes={classes}
            />
          </Body>
          <Footer>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => this.setQuestion('next')}
              classes={transformClasses('survey_button1', classes)}
            >
              Siguiente
            </Button>
          </Footer>
        </Fragment>
      ) : (
        <div className={classes.container}>
          <div className={classes.surveyLandingContainer}>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('survey_typography4', classes)}
            >
              ¡Genial!
            </Typography>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('survey_typography5', classes)}
            >
              Ahora que ya nos conoces mejor,{' '}
              <span className={classes.enfasis}>
                ¡Necesitamos saber un poco más de ti!
              </span>{' '}
              'Responde a unas preguntas para poder asesorarte con mayor
              seguridad en tu objetivo de ahorro'
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => this.setState({ continueSurvey: true })}
              classes={transformClasses('survey_button1', classes)}
            >
              Ok
            </Button>
          </div>
        </div>
      )
    ) : (
      <div />
    );
  }
}

const styles = theme => ({
  container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    height: '20vh',
    minHeight: 114,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navContainer: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  questionsContainer: {
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberContainer: {
    marginTop: 34,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionsContainer: {
    marginTop: 34,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  autoCompleteContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  surveyLandingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    minHeight: 511,
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '50%',
    height: 'auto',
  },
  survey_typography1_root: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  survey_typography2_root: {
    fontSize: 10,
    fontWeight: 600,
  },
  survey_typography3_root: {
    fontSize: '33px',
    lineHeight: '33px',
    fontWeight: 100,
    textAlign: 'left',
    padding: '0 24px',
    width: '100%',
    //marginTop: '166px',
    //marginBottom: '28px'
  },
  survey_typography4_root: {
    fontSize: 64,
  },
  survey_typography5_root: {
    marginTop: 24,
  },
  survey_input1_root: {
    marginTop: 32,
    border: 'solid 3px ' + theme.palette.primary.main,
    padding: 12,
    width: '80%',
  },
  survey_input1_input: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontWeight: 600,
  },
  survey_button1_root: {
    width: '312px',
    borderRadius: '4px',
    //boxShadow: 'unset',
    fontWeight: 600,
  },
  survey_formControlLabel1_label: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 10,
  },
  survey_formControlLabel1_labelPlacementStart: {
    justifyContent: 'space-between',
    borderTop: 'solid 1px rgba(255,255,255,0.3)',
    //borderBottom: 'solid 1px rgba(255,255,255,0.098)',
    margin: 'unset',
  },
  survey_formControlLabel2_label: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 10,
  },
  survey_formControlLabel2_labelPlacementStart: {
    justifyContent: 'space-between',
    borderTop: 'solid 1px rgba(255,255,255,0.3)',
    borderBottom: 'solid 1px rgba(255,255,255,0.3)',
    margin: 'unset',
  },
  survey_radioGroup_root: {
    width: '100%',
    padding: '0 24px',
  },
  survey_radio1_root: {
    color: theme.palette.secondary.main,
  },
  survey_list1_root: {
    width: '80%',
    overflow: 'auto',
    height: '30vh',
    minHeight: 170,
  },
  survey_listItem1_root: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
    borderBottom: 'solid 1px ' + theme.palette.primary.main,
  },
  enfasis: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
});

export default withStyles(styles, { withTheme: true })(Survey);
