import React, { useState, useContext } from 'react';
import { View, Text, Button } from 'coinscrap-webapp-core';
import Header from '../../../components/MyHeader';
import Footer from '../../../components/MyFooter';
import Icon from '../../../components/MyIcon';

import routes from '../../../config/routes';
import colors from '../../../config/colors';

import { StoreContext } from '../../../pensionStore/Store';
import * as Types from '../../../pensionStore/types';
import Traspaso from '../../common/svgs/Traspaso';
import navConfig from '../config/navigation';

import { ModalInfo } from '../../ReglaRetirementTraspaso/ReglaRetirementTraspaso.component';

import PlanPicker from './PlanPicker';
require('intersection-observer');

export default props => {
  const context = useContext(StoreContext);
  const { state, dispatch } = context;
  console.log({ state });
  const [selectedPlan, setSelectedPlan] = useState(state?.pensionPlan || null);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  // const [pageNumber, setPageNumber] = useState(10);

  console.log({ selectedPlan });

  return (
    <>
      <Header
        title='Añadir objetivo'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() =>
          props.history.push('/create/target/retirement/Editar')
        }
      />
      <View bodySt style={{ marginTop: 20 }}>
        <View>
          <View width85St>
            <View style={{ marginBottom: 10 }}>
              <Traspaso style={{ color: colors.iconPurple }} />
            </View>
            <View autoWidthSt flexRowSt>
              <ModalInfo
                open={openInfoModal}
                text={
                  'El traspaso tendrá un plazo de ejecución de unos 10 días hábiles si es un plan individual y 1 mes si es un plan de empleo. En este último caso indicar que es necesario no tener relación laboral con el promotor y no ser beneficiario del plan de origen.'
                }
                onClose={() => setOpenInfoModal(false)}
              />
              <Text centerSt style={{ marginTop: 20, fontSize: 32 }}>
                Traspasar plan de pensiones
                <Icon
                  onClick={() => setOpenInfoModal(true)}
                  name='info'
                  style={{
                    width: 15,
                    marginLeft: 5,
                  }}
                />{' '}
              </Text>
            </View>
            <Text centerSt font16St>
              Trae tu plan a EVO y comenzará un nuevo futuro para ti. Escribe
              aquí el nombre de tu plan.
            </Text>
          </View>

          <PlanPicker onChange={setSelectedPlan} value={selectedPlan} />
          {/* <SearchPlanComp onClick={plan => setSelectedPlan(plan)} /> */}
        </View>
        <View borderSt style={{ flexGrow: 1 }}>
          <Text
            style={{
              border: '1px solid #fff',
              padding: 10,
              margin: 25,
              textAlign: 'center',
            }}
          >
            Este traspaso no será solicitado hasta que se firme el contrato de
            nuevo plan de pensiones
          </Text>
        </View>

        {/* {loading && (
          <div className={classes['lds-roller']}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )} */}
      </View>

      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            style={{ opacity: !selectedPlan && !state.pensionPlan && 0.2 }}
            onClick={() => {
              if (!selectedPlan && !state.pensionPlan) return;
              dispatch({
                type: Types.PENSION_PLAN,
                payload: selectedPlan,
              });
              props.history.push(routes.retirementObjective);
            }}
          >
            CONTINUAR
          </Button>
        </View>
      </Footer>
    </>
  );
};
