import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';

import {
  View,
  Text,
  Button,
  Layout,
  InputSelect,
  SelectItem,
} from 'coinscrap-webapp-core';

import { useSelector } from 'react-redux';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TargetsAPI } from '../../src-core/sdk/index';

import Header from '../../components/MyHeader';
import Footer from '../../components/MyFooter';
import routes from '../../config/routes';
import colors from '../../config/colors';
import Icon from '../../components/MyIcon';

import TraspasoIcon from '../common/svgs/Traspaso';

import SearchPlanComp from '../createRetirement/traspaso/SearchPlanComp';
import { ModalInfo2 } from '../Product/ProductCard';
import PlanPicker from '../createRetirement/traspaso/PlanPicker';

export default props => {
  const store = useSelector(state => state.main);

  const location = useLocation();

  const [targets, setTargets] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [destinationPlan, setDestinationPlan] = useState(0);
  const [openModal, setOpenModal] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  useEffect(() => {
    targetsUser(props.baseUrl, 'me', props.token);
  }, []);

  const targetsUser = async (url, user, token) => {
    try {
      const responseTargets = await TargetsAPI.userTargets(url, user, token);
      const dataTargets = await responseTargets.json();

      const filterTargets = dataTargets.filter(
        target => target.type === 'retirement' && target.status === 'ACTIVE',
      );

      if (location.state?.pensionPlan) {
        setDestinationPlan(location.state.pensionPlan._id);
      }
      setTargets(filterTargets);
    } catch (error) {
      console.log('error recuperando datos de usuario: ', error.message);
    }
  };

  const saveObjective = async () => {
    //TODO: PHILIP REVISA SI ESTO NO SE LLAMA EN EL FLUJO DE CREACION
    //  EN ESE CASO EL ENDPOINT DEBE SER OTRO
    try {
      const response = await TargetsAPI.targetTransfer(
        store.baseUrl,
        destinationPlan,
        selectedPlan.id,
        store.token,
      );

      if (response.status != 200) {
        throw new Error('BACK FAIL');
      }
      setOpenModal('success');
    } catch (error) {
      setOpenModal('error');

      //TODO: MOSTRAR MODAL DE QUE NO SE PUEDE HACER TRANSAPO
      //   Ahora mismo no podemos procesar tu traspaso, porfavor inténtelo de nuevo mas tarde.
      console.log(error);
    }
  };

  return (
    <Layout.Container>
      <Header title='Traspaso' rigthAction={() => props.history.goBack()} />
      <View bodySt style={{ marginTop: 20 }}>
        <View width85St>
          <View>
            <View style={{ marginBottom: 10 }}>
              <TraspasoIcon style={{ color: colors.iconPurple }} />
            </View>
            <View autoWidthSt flexRowSt>
              <ModalInfo
                open={openInfoModal}
                text={
                  'El traspaso tendrá un plazo de ejecución de unos 10 días hábiles si es un plan individual y 1 mes si es un plan de empleo. En este último caso indicar que es necesario no tener relación laboral con el promotor y no ser beneficiario del plan de origen.'
                }
                onClose={() => setOpenInfoModal(false)}
              />
              <Text centerSt style={{ marginTop: 20, fontSize: 32 }}>
                Traspasar plan de pensiones{' '}
                <Icon
                  onClick={() => setOpenInfoModal(true)}
                  name='info'
                  style={{
                    width: 15,
                  }}
                />{' '}
              </Text>
            </View>
            <View style={{ marginTop: 15 }}>
              <Text boldSt>Plan origen</Text>
            </View>
            <PlanPicker
              onChange={setSelectedPlan}
              value={selectedPlan}
              style={{ textAlign: 'center' }}
            />
            {/* <SearchPlanComp
              onClick={plan => setSelectedPlan(plan)}
              style={{ height: 150 }}
            /> */}

            <View
              style={{ marginTop: 10, width: 250, alignItems: 'flex-start' }}
            >
              <Text boldSt style={{ fontSize: 16 }}>
                Plan destino de Evo
              </Text>
              <View wideWidthPeriodicSt style={{ height: 50 }}>
                <InputSelect
                  disabled={!!location.state?.pensionPlan}
                  value={destinationPlan}
                  onChange={e => setDestinationPlan(e.target.value)}
                  selectIcon={() => (
                    <ExpandMoreIcon
                      style={{ fill: 'white', marginBottom: -15 }}
                    />
                  )}
                  reglaTraspasoSt
                >
                  <SelectItem
                    style={{
                      textComponent: { color: 'black', fontWeight: 'bold' },
                    }}
                    value={0}
                    disabled
                  >
                    Seleccionar plan
                  </SelectItem>
                  {targets.map(t => (
                    <SelectItem
                      style={{ textComponent: { color: 'black' } }}
                      value={t._id}
                    >
                      {t.name}
                    </SelectItem>
                  ))}
                </InputSelect>
              </View>
            </View>
          </View>
        </View>
        <View borderSt style={{ flexGrow: 1 }}>
          <Text
            style={{
              border: '1px solid #fff',
              padding: 10,
              margin: 25,
              textAlign: 'center',
            }}
          >
            Este traspaso no será solicitado hasta que se firme el contrato de
            nuevo plan de pensiones
          </Text>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            disabled={!destinationPlan || !selectedPlan}
            pinkButtonSt
            onClick={() => saveObjective()}
          >
            TRASPASAR
          </Button>
        </View>
      </Footer>
      <Modal open={!!openModal} onClose={() => null}>
        <View modalActivarSwitchBodySt>
          <View style={{ marginBottom: 10 }}>
            <>
              <Text centerSt boldSt font20St style={{ marginBottom: 15 }}>
                {openModal === 'error' ? 'Error' : 'Correcto'}
              </Text>
              <Text centerSt>
                {openModal === 'error'
                  ? 'Disculpe, ahora mismo no es posible procesar el traspaso. Si ha realizado un intento anteriormente, póngase en contacto con atención al cliente para conocer el estado.'
                  : 'Su Plan de Pensiones se ha solicitado con éxito.'}
              </Text>
            </>
          </View>
          {/* Ahora mismo no podemos procesar tu traspaso, por favor inténtelo de nuevo mas tarde. */}
          <View flexRowSt>
            <Button
              style={{ backgroundColor: 'black' }}
              onClick={() => props.history.goBack()}
            >
              {openModal === 'error' ? 'Volver' : 'OK'}
            </Button>
          </View>
        </View>
      </Modal>
    </Layout.Container>
  );
};

export function ModalInfo(props) {
  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => props.onClose(false)}
    >
      <div
        style={{
          width: '312px',
          backgroundColor: '#e9e9e9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '14px',
          borderRadius: '8px',
          '&:focus': {
            outline: 'unset',
          },
          fontFamily: 'Calibre',
        }}
      >
        {props.text}
      </div>
    </Modal>
  );
}
