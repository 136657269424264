import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import TargetInitialContribution from '../targetInitialContribution/targetInitialContribution.component';
import TargetInitialContributionConfig from '../targetInitialContribution/targetInitialContribution.config';
import TargetInitialContributionStyles from '../targetInitialContribution/targetInitialContribution.style';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';
//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class CreateTargetInitContribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      open: false,
      errorMessage: '',
    };
  }

  setContribution(value) {
    this.setState({
      value: value,
    });
    console.log('targetContrib.setContribution', value);
  }

  onButtonClick() {
    const value = this.state.value;
    console.log(this.props.schema.properties.initialContribution.maximum);

    const retirementMin = 30;
    const retirementMax = 1500;

    if (
      (this.props.type === 'retirement' && value < retirementMin) ||
      value > retirementMax
    ) {
      this.setState({
        open: true,
        errorMessage: `Introduzca un valor entre 30€ y 1.500€.`,
      });
    } else if (
      value >= this.props.schema.properties.initialContribution.minimum &&
      value <= this.props.schema.properties.initialContribution.maximum
    ) {
      const data = {
        initialContribution: Number(value),
      };
      TargetsAPI.setTargetConfiguration(
        this.props.baseUrl,
        this.props.targetID,
        this.props.token,
        data,
      )
        .then(response => {
          if (response.status === 200) {
            this.props.refreshConf(this.props.targetID);

            if (this.props.type === 'retirement') {
              this.props.history.push(this.props.nextRetirement);
            } else {
              this.props.history.push(this.props.next);
            }
          } else {
            throw response.status;
          }
        })
        .catch(error => {
          console.log('error configurando la aportación inicial', error);
        });
    } else {
      console.log('incorrecto: ', value);
    }
  }
  onButtonClickNoApplicar() {
    const data = {
      initialContribution: Number(0),
    };
    TargetsAPI.setTargetConfiguration(
      this.props.baseUrl,
      this.props.targetID,
      this.props.token,
      data,
    )
      .then(response => {
        if (response.status === 200) {
          this.props.refreshConf(this.props.targetID);

          if (this.props.type === 'retirement') {
            this.props.history.push(this.props.nextRetirement);
          } else {
            this.props.history.push(this.props.next);
          }
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error configurando la aportación inicial', error);
      });
  }

  handleClose = () => {
    console.log('hello close modal');
    this.setState({
      open: false,
      errorMessage: '',
    });
  };

  render() {
    console.log(this.props);
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const customConfig = {
      ...TargetInitialContributionConfig,
      textField1: {
        label: this.props.schema.properties.initialContribution.name,
        fullWidth: true,
        InputLabelProps: {
          classes: transformClasses(
            'targetInitialContribution_textFieldsub11_inputLabelProps',
            classes,
          ),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position='end'>
              {this.props.schema.properties.initialContribution.unit}
            </InputAdornment>
          ),
          classes: transformClasses(
            'targetInitialContribution_textFieldsub11_inputProps',
            classes,
          ),
        },
      },
    };
    /*
    const headerConfig = {
      icon: {
        color: 'inherit'
      }
    }
    */
    return (
      /*<div className='container'> {classes.targetContribution_container*/
      <Fragment>
        <Header
          title='Añadir Objetivo'
          rigthAction={() => this.props.history.push('/home/objetivos')}
        />

        <Body>
          {/*<div className={classes.targetContribution_slideTitle_container}>*/}

          <SvgIcon
            color='primary'
            classes={transformClasses(
              'targetContribution_slideTitle_icon',
              classes,
            )}
            viewBox='0 0 28 28'
          >
            <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetContribution_slideTitle_typography1',
              classes,
            )}
          >
            Aporte inicial
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetContribution_slideTitle_typography2',
              classes,
            )}
          >
            ¡Pistoletazo de salida!
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetContribution_slideTitle_typography3',
              classes,
            )}
          >
            Realiza un ingreso incial para comenzar el juego. Como mínimo tienes
            que empezar con{' '}
            {this.props.schema.properties.initialContribution.minimum}
            €.
          </Typography>

          <TargetInitialContribution
            schema={this.props.schema.properties.initialContribution}
            setContribution={this.setContribution.bind(this)}
            config={customConfig}
            configuration={this.props.configuration}
            classes={classes}
          />
        </Body>
        <Modal
          disablePortal
          open={this.state.open}
          classes={transformClasses('swipeRules_modal1', classes)}
        >
          <div className={classes.swipeRules_modalContainer}>
            <div style={{ padding: 10 }}>
              <Typography variant='body1' color='default'>
                {this.state.errorMessage}
              </Typography>
            </div>
            <Button
              variant='contained'
              color='secondary'
              onClick={this.handleClose}
            >
              Volver
            </Button>
          </div>
        </Modal>
        <Footer>
          {' '}
          {/*classes.targetInitialContribution_buttonContainer1*/}
          {this.props.type === 'retirement' && (
            <Button
              {...customConfig.button2}
              //classes={transformClasses('targetInitialContribution_button1',classes)}
              onClick={() => {
                this.onButtonClickNoApplicar();
                // this.props.history.push(this.props.nextRetirement);
              }}
              id={'aplicarContribution2'}
            >
              No Aplicar
            </Button>
          )}
          <Button
            {...customConfig.button1}
            //classes={transformClasses('targetInitialContribution_button1',classes)}
            onClick={() => this.onButtonClick()}
            id={'aplicarContribution2'}
          >
            Aplicar
          </Button>
        </Footer>
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...TargetInitialContributionStyles(theme),
  targetContribution_container: {
    minHeight: '640px',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  targetInitialContribution_container: {
    display: 'flex',
    padding: '0 48px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    marginBottom: 60,
  },
  targetInitialContribution_textFieldsub11_inputLabelProps_root: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    position: 'unset',
  },
  targetInitialContribution_textFieldsub11_inputLabelProps_focused: {
    color: theme.palette.secondary.main + '!important',
  },
  targetInitialContribution_textFieldsub11_inputProps_root: {
    color: '#000',
    fontWeight: 600,
    margin: 'unset!important',
  },
  targetInitialContribution_buttonContainer: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    padding: '0 12px 12px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'flex-end',
  },
  targetInitialContribution_button1_root: {
    width: '312px',
    height: '40px',
    //fontWeight: 600,
    borderRadius: '4px',
  },
  targetContribution_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  targetContribution_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  targetContribution_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '18px',
  },
  targetContribution_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
  },
  targetContribution_slideTitle_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetContribution_slideTitle_icon_root: {
    width: '50px', //28
    height: '40px', //28
    marginBottom: '5px',
    marginTop: 50,
  },
  targetContribution_slideTitle_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    letterSpacing: '-1px',
    textAlign: 'center',
  },
  targetContribution_slideTitle_typography2_root: {
    fontSize: '16px',
    fontWeight: 800,
    letterSpacing: 0,
    marginBottom: '8px',
  },
  targetContribution_slideTitle_typography3_root: {
    fontSize: '16px',
    letterSpacing: 0,
    width: '232px',
    textAlign: 'center',
    marginBottom: '32.3px',
  },
  swipeRules_modalContainer: {
    // height: '50vh',
    minHeight: 100,
    // width: '90%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15,
    '&:focus': {
      outline: 'unset',
    },
    margin: 15,
    marginTop: '50%',
    borderRadius: 14,
  },
});

export default withStyles(styles, { withTheme: true })(
  CreateTargetInitContribution,
);
