import React, { useEffect, useContext } from 'react';
import { View, Text, Button, Enfatize } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import routes from '../../../../config/routes';
import colors from '../../../../config/colors';
import CerdoHucha from '../../../common/svgs/CerdoHucha';
import { StoreContext } from '../../../../pensionStore/Store';
import navConfig from '../../config/navigation';

export default props => {
  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  useEffect(() => {
    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
  }, []);
  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt>
        <View width85St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <CerdoHucha
                style={{ color: colors.iconYellow, filter: 'brightness(1.6)' }}
              />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              Tu ahorro
            </Text>
            <Text centerSt font16St>
              Por último, vamos a calcular{' '}
              <Enfatize font16St>
                cuánto tendrías que ahorrar para mantener tu nivel de ingresos
                actual
              </Enfatize>{' '}
              en el momento de tu jubilación.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() =>
              props.history.push(routes.retirementSimulationSavingsAmount)
            }
          >
            ¡VAMOS ALLÁ!
          </Button>
        </View>
      </Footer>
    </>
  );
};
