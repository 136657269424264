function generateStyles(theme) {
	return {
		activateAccounts_container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		activateAccounts_gridContainer1_container: {
			overflow: 'auto',
			marginBottom: '10vh',
			minHeight: 57,
		},
		activateAccounts_gridContainer2_container: {
			border: '3px solid #000',
			marginLeft: 10,
			marginRight: 10,
			marginBottom: 10,
		},
		activateAccounts_gridContainer3_container: {
			paddingTop: 5,
		},
		activateAccounts_gridItem1_item: {
			maxHeight: 64,
		},
		activateAccounts_iconBank: {
			maxHeight: 48,
			marginRight: 12,
		},
		activateAccounts_typography1_root: {
			fontWeight: 600,
		},
		activateAccounts_typography2_root: {
			marginLeft: 8,
			fontSize: 12,
			color: '#808080',
		},
		activateAccounts_typography4_root: {
			//wordBreak: 'break-all',
			fontSize: '2vw',
			fontWeight: 600,
		},
		activateAccounts_typography5_root: {
			fontSize: 12,
			color: '#808080',
		},
		activateAccounts_typography6_root: {
			marginLeft: 12,
		},
		activateAccounts_button_container: {
			width: '100%',
			position: 'absolute',
			bottom: 0,
			padding: '0 10px 10px',
		},
		activateAccounts_button1_root: {
			width: '100%',
			borderRadius: 'unset',
			boxShadow: 'unset',
			fontWeight: 600,
		},
		activateAccounts_tabsDiv: {
			width: '100%',
			marginBottom: 16,
			paddingLeft: 10,
			paddingRight: 10,
		},
		activateAccounts_tab1_root: {
			fontWeight: 600,
		},
		activateAccounts_tab2_root: {
			fontWeight: 600,
		},
		activateAccounts_tab1_textColorSecondary: {
			color: '#000',
		},
		activateAccounts_tab2_textColorSecondary: {
			color: '#000',
		},
		collapse_container: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		collapse_textContainer: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		bankContainer: {
			marginBottom: 12,
			width: '100%',
		},
		bankContainerTitle: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			paddingLeft: 12,
			paddingRight: 12,
		},
		activateAccounts_collapse1_container: {
			width: '100%',
			backgroundColor: theme.palette.primary.dark,
		},

		activateAccounts_collapse1_wrapperInner: {
			width: '100%',
			paddingLeft: 12,
			paddingRight: 12,
		},
		activateAccounts_iconButton1_root: {
			color: '#808080',
			padding: 'unset',
			marginRight: 12,
		},
		bankContainerTitle_textContainer: {
			width: '100%',
		},
		endAdornmentActive1: {
			color: theme.palette.secondary.main,
		},
		activateAccounst_accounts_container: {
			width: '312px',
			height: '88px',
			borderRadius: '4px',
			display: 'flex',
			backgroundColor: 'rgba(255,255,255,0.098)',
			marginBottom: '8px',
			position: 'relative',
		},
		activateAccounst_accounts_imageBank: {
			width: 40,
			//height: '24px',
		},
		activateAccounst_accounts_leftPanel_container: {
			width: 'calc(100%/6)',
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
		},
		activateAccounst_accounts_centerPanel_container: {
			width: 'calc((((100%/6)*5)/10)*7)',
			alignItems: 'flex-start',
			justifyContent: 'center',
			display: 'flex',
			flexDirection: 'column',
		},
		activateAccounst_accounts_rightPanel_container: {
			width: 'calc((((100%/6)*5)/10)*3)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		activayeAccounts_accounts_title_typography1_root: {
			fontSize: '11px',
			lineHeight: '18px',
			letterSpacing: '0px',
			fontWeight: 600,
			textTransform: 'uppercase',
		},
		activayeAccounts_accounts_account_typography1_root: {
			fontSize: '16px',
			fontWeight: 600,
			lineHeight: '16.9px',
		},
		activayeAccounts_accounts_currency_typography1_root: {
			fontSize: '24px',
			fontWeight: 100,
			lineHeight: '16.9px',
		},
	};
}

export default generateStyles; //as ActivateAccountsStyles }
