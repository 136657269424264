import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

//import { RulesAPI, TargetsAPI } from '../components-core/sdk';
import { RulesAPI } from '../../src-core/sdk/index';

import { TargetsAPI } from '../../src-core/sdk/index';

//import { ListConfig, ListStyles } from '../components-core/ui';
import { ListConfig } from '../../src-core/ui/index';
import { ListStyles } from '../../src-core/ui/index';

import ListTargetsUser from '../Home/ListTargetsUser';
import ListTargetsUserConfig from '../Home/ListTargetsUser.config';
import ListTargetsUserStyles from '../Home/ListTargetsUser.style';

import ProgressBarConfig from '../../components/progressBar.config';
import ProgressBarStyles from '../../components/progressBar.style';
import Header from './header.component';

const styles = theme => ({
  ...ListTargetsUserStyles(theme),
  ...ListStyles(theme),
  ...ProgressBarStyles(theme),
  homeTargets_shortList: {
    maxHeight: '90vh',
    minHeight: 511,
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
});

class TargetsByRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: null,
      targets: null,
      filteredTargets: null,
    };
  }
  goToConfig(id) {
    this.props.history.push('/followup/' + id + '/Gráficos');
  }
  componentDidMount() {
    //ºº RulesAPI.getRulesByDefinition Esta a medio cambiar al estilo nuevo
    RulesAPI.getRulesByDefinition(
      this.props.baseUrl,
      'me',
      this.props.match.params.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          rules: data,
        });
      })
      .catch(error => {
        console.log('error recuperando las reglas por definicion ', error);
      });
    TargetsAPI.userTargets(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          targets: data,
        });
      })
      .catch(error => {
        console.log('error recuperando los targets de usuario ', error);
      });
  }
  componentDidUpdate() {
    if (
      this.state.rules !== null &&
      this.state.targets !== null &&
      !this.state.filteredTargets
    ) {
      const filtered = this.state.targets.filter(target => {
        if (
          this.state.rules.find(rule => rule.target === target._id) !==
          undefined
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        targets: filtered,
        filteredTargets: true,
      });
    }
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <div>
        <Header
          classes={classes}
          history={this.props.history}
          prev='/home/reglas'
        />
        {this.state.filteredTargets ? (
          <div className={classes.homeTargets_shortList}>
            <ListTargetsUser
              config={ListTargetsUserConfig}
              classes={classes}
              targets={this.state.targets}
              theme={theme}
              listConfig={ListConfig}
              progressBarConfig={ProgressBarConfig}
              goToConfig={this.goToConfig.bind(this)}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TargetsByRule);
