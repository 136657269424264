import { connect } from 'react-redux'
import History from '../views/history/history.component';


function mapStateToProps(state) {
  const { token, baseUrl } = state.main
  return {
    token,
    baseUrl
  }
}

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History)
