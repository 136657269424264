import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import { withStyles } from '@material-ui/core/styles';

import { ListConfig } from '../../src-core/ui/react/list/list.config';
import { ListStyles } from '../../src-core/ui/react/list/list.style';

import { ListTargetsForRulesStyles } from '../../src-core/targets/react/listTargetForRules/listTargetsForRules.style';

import RulesList from '../rulesList/rulesList.component';
import RulesListConfig from '../rulesList/rulesList.config';
import RulesListStyles from '../rulesList/rulesList.style';
import SwipeableViewsConfigurableConfig from '../swipeableViews/swipeableViewsConfigurable.config';
import SwipeableViewsConfigurableStyles from '../swipeableViews/swipeableViewsConfigurable.style';

class HomeRules extends Component {
  listTargets(id) {
    this.props.history.push('/home/reglas/listConfRule/' + id);
  }

  goToRules() {
    this.props.history.push('/home/reglas/listadoReglas');
  }

  render() {
    const { classes, rules, theme, summaryIds } = this.props;
    console.log('HomeRules.render', summaryIds);
    return (
      //<div className={classes.homeRules_divContainer}>
      <Switch>
        <Route
          path='/home/reglas/listadoReglas'
          render={props => (
            <RulesList
              {...props}
              rules={rules}
              classes={classes}
              theme={theme}
              config={RulesListConfig}
              listConfig={ListConfig}
              summaryIds={summaryIds}
              targets={this.props.targets}
              swipeConfig={SwipeableViewsConfigurableConfig}
              applyAction={this.listTargets.bind(this)}
              baseUrl={this.props.baseUrl}
              token={this.props.token}
              // renderAnimations={this.returnAnimations.bind(this)}
              history={this.props.history}
            />
          )}
        />
        <Route
          render={props => (
            <RulesList
              rules={rules}
              classes={classes}
              theme={theme}
              config={RulesListConfig}
              listConfig={ListConfig}
              summaryIds={summaryIds}
              targets={this.props.targets}
              swipeConfig={SwipeableViewsConfigurableConfig}
              applyAction={this.listTargets.bind(this)}
              baseUrl={this.props.baseUrl}
              token={this.props.token}
              location={this.props.location}
              // renderAnimations={this.returnAnimations.bind(this)}
              history={this.props.history}
            />
          )}
        />
      </Switch>
    );
  }
}

const styles = theme => ({
  ...RulesListStyles(theme),
  ...ListTargetsForRulesStyles(theme),
  ...SwipeableViewsConfigurableStyles(theme),
  ...ListStyles(theme),
  listWithState_list_root: {
    padding: 'unset',
  },
  homeRules_divContainer: {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  rulesList_cardActions1_root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 'unset',
    marginBottom: '24px',
  },
  rulesList_button2_root: {
    width: '128px',
    height: '40px',
    backgroundColor: 'rgba(255,255,255,0.098)',
  },
  rulesList_button2_label: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWieght: 600,
  },
  listTargetForRulesHeader: {
    display: 'flex',
    padding: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10vh',
    minHeight: 57,
  },
  listTargetForRulesHeader_iconButton: {
    padding: 'unset',
  },
  listTargetForRulesHeader_typography: {
    textTransform: 'uppercase',
    fontWieght: 600,
  },
  listTargetForRules_optionsContainer: {
    width: '100%',
    height: '10vh',
    minHeight: 57,
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    padding: '0 8px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  listTargetForRules_optionTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  listTargetForRules_optionText: {
    width: '32%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listTargetForRules_optionControlContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  listTargetForRules_optionControl: {
    width: '32%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// const animations = {
//   COINSCRAP_ROUNDS: {
//     subText: 'Ahorra mientras gastas',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: cerditoAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 100,
//     },
//   },
//   PERIODIC_SAVING: {
//     subText: 'Ahorra mes a mes',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: calendarioAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 100,
//     },
//   },
//   PUNTUAL_SAVING: {
//     subText: 'Ahorro directo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: ahorroHotDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 80,
//     },
//   },
//   NOMINA_SAVING: {
//     subText: 'Que no solo sume tu cuenta corriente haz crecer tu objetivo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: maletinAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 90,
//     },
//   },
//   CANCER_DONATION: {
//     subText: 'Dona contra el cancer',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: lazoAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 65,
//     },
//   },
//   COFFEE_SAVING: {
//     subText: 'Ahorrate el café',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: cafeDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 130,
//       y: 10,
//     },
//   },
//   COVID_SAVING: {
//     subText: '#QuedateEnCasa',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: quedateEnCasa.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//         viewBoxSize: '-450 -450 1500 1500',
//       },
//       with: 130,
//       y: 10,
//     },
//   },

//   SMOKE_SAVING: {
//     subText: 'Recupera tu salud',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: tabacoDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 120,
//     },
//   },
//   TEAM: {
//     subText: 'Ahorra con tu equipo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: camisetaAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       with: 140,
//     },
//   },
// };

export default withStyles(styles, { withTheme: true })(HomeRules);
