import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Button, Enfatize, TextField } from 'coinscrap-webapp-core';
import {
  calculateComplementaryPeriodicContribution,
  calculateTotalAmountUsingPeriodicContributions,
} from '@coinscrap/retirement-calculations';
import Header from '../../../components/MyHeader';
import Footer from '../../../components/MyFooter';
import routes from '../../../config/routes';

import {
  onChangeAmountHandler,
  amountStringToNumber,
} from '../../../libs/currencyInput';
import { formatNumber } from '../../../libs/formatToCurrency';
import * as Types from '../../../pensionStore/types';

import { StoreContext } from '../../../pensionStore/Store';
import CajaFuerte from '../../common/svgs/CajaFuerte';
import Lapiz from '../../common/svgs/Lapiz';
import InfoModal from '../components/InfoModal';

import { TargetsAPI } from '../../../src-core/sdk/index';
import { useSelector } from 'react-redux';
import navConfig from '../config/navigation';
import Flow from '../../../components/Flow';

export default props => {
  const [nivelIngresos, setNivelIngresos] = useState(100);
  const [aportacionAnual, setAportacionAnual] = useState('');
  const [aportacionMensual, setAportacionMensual] = useState('');
  const [aportacionInicial, setAportacionInicial] = useState('');
  const flowParams = Flow.getParams();
  const context = useContext(StoreContext);
  const { state, dispatch } = context;
  const { simulation, pensionPlan } = state;
  const store = useSelector(state => state.main);
  const { prodsForUser } = store;

  useEffect(() => {
    dispatch({
      type: Types.UPDATE_LIFE_LEVEL_PERCENTAGE,
      payload: parseFloat(nivelIngresos),
    });
    dispatch({
      type: Types.SAVE_SIMULATION,
      payload: {
        yearlyContribution: amountStringToNumber(aportacionAnual),
        monthlyContribution: amountStringToNumber(aportacionMensual),
        savings: amountStringToNumber(aportacionInicial),
      },
    });
  }, [nivelIngresos, aportacionInicial, aportacionMensual, aportacionAnual]);

  const calculateComplementaryPeriodicContributionHandler = (
    lifeLevelPercentage = simulation.lifeLevelPercentage,
  ) => {
    let findProduct;
    for (const property in prodsForUser) {
      if (prodsForUser[property].name === simulation.profileInvestor) {
        findProduct = prodsForUser[property];
      }
    }
    const PeriodicContribution = calculateComplementaryPeriodicContribution(
      lifeLevelPercentage,
      simulation.typeEmployment,
      simulation.grossIncome,
      simulation.savings,
      simulation.age,
      simulation.contribution,
      0.03,
      0.02,
      83,
    );

    setAportacionAnual(
      formatNumber(PeriodicContribution.neededYearlyContribution.toFixed(2)),
    );
    setAportacionMensual(
      formatNumber(PeriodicContribution.neededMonthlyContribution.toFixed(2)),
    );
  };

  useEffect(() => {
    if (!simulation.started) {
      props.history.push(navConfig.notStarted);
    }

    // if (simulation.monthlyContribution) {
    //   aportacionMensualHandler(simulation.monthlyContribution);
    //   setAportacionInicial(formatNumber(simulation.savings));
    //   return;
    // }

    calculateComplementaryPeriodicContributionHandler();
    setAportacionInicial(formatNumber(simulation.savings));

    setNivelIngresos(simulation.lifeLevelPercentage);
  }, []);

  const saveObjective = async () => {
    let data;

    flowParams.flujo === 'transfer'
      ? (data = {
          // initialContribution: 50,
          retirementAge: 67,
          transferPensionPlan: pensionPlan.id,
        })
      : (data = {
          // initialContribution: simulation.savings,
          retirementAge: 67,
        });
    console.log(data);
    try {
      await TargetsAPI.setTargetConfiguration(
        store.baseUrl,
        flowParams.temporaryObjectiveId,
        store.token,
        data,
      );

      props.history.push(routes.retirementSimulationGenial);
    } catch (error) {
      console.log(error);
    }
  };

  const nivelIngresosHandler = e => {
    setNivelIngresos(e.target.value);

    calculateComplementaryPeriodicContributionHandler(e.target.value);
  };

  const aportacionMensualHandler = value => {
    let result = onChangeAmountHandler(value, setAportacionMensual);
    const valueNumber = amountStringToNumber(result);
    if (isNaN(valueNumber)) {
      return;
    }

    let yearValue = +valueNumber * 12;
    if (yearValue === 0) {
      yearValue = '0';
    }
    setAportacionAnual(formatNumber(yearValue || 0));

    let findProduct;
    for (const property in prodsForUser) {
      if (prodsForUser[property].name === simulation.profileInvestor) {
        findProduct = prodsForUser[property];
      }
    }

    const newLifeLevelPercentage = calculateTotalAmountUsingPeriodicContributions(
      simulation.typeEmployment,
      simulation.grossIncome,
      simulation.savings,
      simulation.age,
      simulation.contribution,
      0.03,
      valueNumber,
      0.02,
      83,
    );

    setNivelIngresos(newLifeLevelPercentage.lifeLevelPercentage.toFixed(0));
  };
  const aportacionAnualHandler = e => {
    const value = e.target.value;
    let result = onChangeAmountHandler(value, setAportacionAnual);
    const valueNumber = amountStringToNumber(result);
    if (isNaN(valueNumber)) {
      return;
    }

    const monthlyValue = +valueNumber / 12;
    const monthlyValueString = formatNumber(monthlyValue.toFixed(2));
    setAportacionMensual(monthlyValueString || 0);

    let findProduct;
    for (const property in prodsForUser) {
      if (prodsForUser[property].name === simulation.profileInvestor) {
        findProduct = prodsForUser[property];
      }
    }

    const newLifeLevelPercentage = calculateTotalAmountUsingPeriodicContributions(
      simulation.typeEmployment,
      simulation.grossIncome,
      simulation.savings,
      simulation.age,
      simulation.contribution,
      0.03,
      monthlyValue,
      0.02,
      83,
    );

    setNivelIngresos(newLifeLevelPercentage.lifeLevelPercentage.toFixed(0));
  };

  const aportacionInicialHandler = e => {
    const value = e.target.value;
    const initial = onChangeAmountHandler(value, setAportacionInicial);
    const initialConvertToNum = amountStringToNumber(initial);
    let findProduct;
    for (const property in prodsForUser) {
      if (prodsForUser[property].name === simulation.profileInvestor) {
        findProduct = prodsForUser[property];
      }
    }
    const PeriodicContribution = calculateComplementaryPeriodicContribution(
      simulation.lifeLevelPercentage,
      simulation.typeEmployment,
      simulation.grossIncome,
      initialConvertToNum,
      simulation.age,
      simulation.contribution,
      0.03,
      0.02,
      83,
    );

    setAportacionAnual(
      formatNumber(PeriodicContribution.neededYearlyContribution.toFixed(2)),
    );
    setAportacionMensual(
      formatNumber(PeriodicContribution.neededMonthlyContribution.toFixed(2)),
    );
  };

  return (
    <>
      <Header
        title='Añadir objetivo'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodyTwoButtonsSt style={{ marginBottom: 135, marginTop: 10 }}>
        <View width85St>
          <View>
            <Text centerSt font30St>
              Resultado de tu simulación
            </Text>
            <Text font16St centerSt>
              A continuacion puedes ver el Plan de aportaciones periódicas que
              hemos preparado para ti. Puedes modificar los datos para ver como
              afecta a tu ahorro.
            </Text>
          </View>

          <View summaryCardSt style={{ padding: '20px 20px', height: 'auto' }}>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                marginBottom: 15,
              }}
            >
              <View autoWidthSt startSt flexRowSt style={{ width: 200 }}>
                <Text font16St boldSt>
                  PLAN DE APORTACIONES PERIÓDICAS{' '}
                </Text>
              </View>

              <CajaFuerte style={{ color: 'white', width: 24, height: 24 }} />
            </View>

            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: '2px solid rgba(255,255,255,0.1)',
              }}
            >
              <Text font16St weight500St>
                Nivel de ingresos
              </Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  type='number'
                  value={nivelIngresos}
                  onChange={e => nivelIngresosHandler(e)}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                  }}
                />
                <Text style={{ marginLeft: 1, marginRight: 1 }}>%</Text>
              </View>
            </View>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: '2px solid rgba(255,255,255,0.1)',
              }}
            >
              <Text font16St>Aportación anual</Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={aportacionAnual}
                  onChange={e => aportacionAnualHandler(e)}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                  }}
                />
                <Text style={{ marginLeft: 1, marginRight: 1 }}>€</Text>
              </View>
            </View>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
                borderBottom: '2px solid rgba(255,255,255,0.1)',
              }}
            >
              <Text font16St>Aportación mensual</Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={aportacionMensual}
                  onChange={e => aportacionMensualHandler(e.target.value)}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                  }}
                />
                <Text style={{ marginLeft: 1, marginRight: 1 }}>€</Text>
              </View>
            </View>
            <View
              flexRowSt
              spaceBetweenSt
              style={{
                paddingBottom: 15,
                marginBottom: 15,
              }}
            >
              <Text font16St>Aportado</Text>
              <View
                flexRowSt
                spaceBetweenSt
                style={{
                  width: '50%',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: 2,
                  padding: 5,
                  height: 40,
                }}
              >
                <Lapiz style={{ color: 'white' }} />
                <TextField
                  value={aportacionInicial}
                  onChange={e => aportacionInicialHandler(e)}
                  smallInputSt
                  style={{
                    containerComponent: {
                      paddingTop: 5,
                    },
                  }}
                />
                <Text style={{ marginLeft: 1, marginRight: 1 }}>€</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            onClick={() => {
              saveObjective();
            }}
          >
            CONTINUAR
            {/* ¡VAMOS AllÁ! */}
          </Button>
          <Button
            greenButtonSt
            onClick={() =>
              props.history.push(routes.retirementSimulationCalculations)
            }
          >
            VOLVER A REALIZAR SIMULACIÓN
            {/* ¡VAMOS AllÁ! */}
          </Button>
        </View>
      </Footer>
    </>
  );
};
