import { initialState } from './state';
import * as Types from './types';

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.NEW_PLAN_SIMULATION:
      const yearsToRetirement =
        state.retirementAge - parseInt(state.simulation.age);
      const year = new Date().getFullYear() + yearsToRetirement;

      return {
        ...state,
        simulation: {
          ...state.simulation,
          ...payload,
          year,
        },
      };
    case Types.NEW_PLAN_MANUAL:
      return {
        ...state,
        manual: {
          ...state.manual,
          ...payload,
        },
      };
    case Types.MODE:
      return {
        ...state,
        mode: payload,
      };
    case Types.PENSION_PLAN:
      return {
        ...state,
        pensionPlan: payload,
      };
    case Types.UPDATE_LIFE_LEVEL_PERCENTAGE:
      return {
        ...state,
        simulation: {
          ...state.simulation,
          lifeLevelPercentage: payload,
        },
      };
    case Types.SAVE_SIMULATION:
      return {
        ...state,
        simulation: {
          ...state.simulation,
          ...payload,
        },
      };
    case Types.INITIAL_PENSION_STATE:
      return {
        simulation: {
          started: false,
          age: null,
          contribution: null,
          typeEmployment: null,
          grossIncome: null,
          savings: null,
          profileInvestor: null,
          total: null,
          year: '',
          yearlyContribution: null,
          monthlyContribution: null,
          lifeLevelPercentage: 100,
        },
        manual: {
          started: false,
          age: null,
          contribution: null,
          typeEmployment: null,
          grossIncome: null,
          savings: null,
          profileInvestor: null,
          total: null,
          yearlyContribution: null,
          monthlyContribution: null,
          numberOfYears: null,
        },
        pensionPlan: null,
        retirementAge: 67,
      };
    default:
      return state;
  }
};
