import styled from 'styled-components/macro';
import colors from '../../../../config/colors';

export const PensionTransferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  color: white;
  padding: 0px 0px;
`;
export const PensionTransferStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
`;
export const PensionTransferStatusItem = styled.div`
  display: flex;
  flex-direction: row;

  color: white;
  align-items: center;
`;

export const PensionTransferStatusSituation = styled.p`
  font-weight: bold;
  margin: 0px;
`;
export const PensionTransferStatusExplication = styled.p`
  margin: 0px;
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  /* grid-template-rows: repeat(3, 30px); */
  gap: 10px;
  width: 100%;
`;
export const GridItem = styled.div`
  width: 'auto';
`;
export const TableContainer = styled.table`
  width: 100%;
  table-layout: fixed;
`;
export const TableRow = styled.tr`
  margin: 5px 0px;
  padding: 5px;
  width: 100%;
`;
export const TableItem = styled.td`
  text-align: left;
  padding: 5px;
  word-break: break-all;
  font-size: 10px;
`;
export const TableHeading = styled.th`
  text-align: left;
  padding: 5px;
  width: ${({ label }) => {
    if (label === '') return '75px';
    if (label === 'Codigos') return '85px';
    if (label === 'Nombre') return 'auto';
  }};
  font-size: 10px;
`;
export const TableTitle = styled.p`
  text-decoration: underline;
  font-size: 16px;
  margin: 10px 0px;
  text-align: center;
  font-size: 12px;
`;
export const ModalWrapper = styled.div`
  width: 312px;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 8px;

  font-family: Calibre;

  &:focus: {
    outline: unset;
  }
`;
