const config = {
  textField1: {
    label: 'name',
  },
  textField2: {
    label: 'price',
  },
  textField3: {
    label: 'months',
  },
  textField4: {
    label: 'years',
  },
  textField5: {
    label: 'retirementAge',
  },
  textField6: {
    label: 'salaryPercent',
  },
};

export default config;
