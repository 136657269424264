import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Enfatize } from 'coinscrap-webapp-core';
import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import colors from '../../../../config/colors';

import routes from '../../../../config/routes';

import Picker from 'react-mobile-picker';

import { StoreContext } from '../../../../pensionStore/Store';
import * as Types from '../../../../pensionStore/types';
import Camisa from '../../../common/svgs/Camisa';
import navConfig from '../../config/navigation';

export default props => {
  const [listYears, setListYears] = useState([]);
  const [years, setYears] = useState(10);

  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  useEffect(() => {
    setListYears([...Array(60).keys()]);

    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
    state.simulation.contribution && setYears(state.simulation.contribution);
  }, []);

  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt bodyAddTopPaddingSt>
        <View width85St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Camisa style={{ color: colors.iconPurple }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt style={{ fontSize: 30 }}>
              ¿Cuántos años has cotizado a la Seguridad Social?
            </Text>
            <View>
              <Picker
                valueGroups={{
                  age: years,
                }}
                optionGroups={{
                  age: listYears,
                }}
                onChange={(name, value) => {
                  console.log(value, 'VALUE');
                  if (!value) {
                    return;
                  }
                  setYears(prevState => value);
                }}
              />
            </View>
          </View>
        </View>

        <View style={{ paddingBottom: 10, justifyContent: 'flex-end' }}>
          <View width85St>
            <Text centerSt font16St>
              A parte de para calcular tu edad de jubilación, podremos estimar
              el porcentaje de pensión que te podría corresponder.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View flexRowSt spaceBetweenSt footerSmallRoundButtonWrapperSt>
          <View autoWidthSt flexRowSt>
            <View autoWidthSt smallDotSt />
            <Text style={{ marginTop: 10 }}>
              <Enfatize style={{ fontSize: '35px', fontWeight: 100 }}>
                2
              </Enfatize>
              /6
            </Text>
          </View>
          <View autoWidthSt flexRowSt>
            <View
              autoWidthSt
              footerRoundOkButtonSt
              style={{ paddingTop: 5, fontWeight: 'bold' }}
              onClick={() => {
                dispatch({
                  type: Types.NEW_PLAN_SIMULATION,
                  payload: {
                    contribution: years,
                  },
                });

                props.history.push(routes.retirementSimulationCongratulations);
              }}
            >
              OK
            </View>
          </View>
        </View>
      </Footer>
    </>
  );
};
