import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Enfatize } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import colors from '../../../../config/colors';

import Picker from 'react-mobile-picker';
import routes from '../../../../config/routes';

import { StoreContext } from '../../../../pensionStore/Store';
import * as Types from '../../../../pensionStore/types';
import Tarta from '../../../common/svgs/Tarta';
import { useSelector } from 'react-redux';
import moment from 'moment';
import navConfig from '../../config/navigation';

export default props => {
  const [listAge, setListAge] = useState([]);
  const [age, setAge] = useState(35);

  const context = useContext(StoreContext);
  const { state, dispatch } = context;
  const store = useSelector(state => state.main);

  useEffect(() => {
    setListAge([...Array(100).keys()].filter(age => age > 15));

    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
    if (state.simulation.age) {
      setAge(state.simulation.age);
    } else if (store.user && store.user.nif.dateOfBirth) {
      const time = moment().diff(
        moment(store.user.nif.dateOfBirth, 'YYYYMMDD'),
        'years',
      );

      setAge(time);
    }
  }, []);

  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt>
        <View width85St flexSt>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Tarta style={{ color: colors.iconPurple }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt style={{ fontSize: 36 }}>
              ¿Cuál es tu edad?
            </Text>
            <View>
              <Picker
                valueGroups={{
                  age: age,
                }}
                optionGroups={{
                  age: listAge,
                }}
                onChange={(name, value) => {
                  console.log(value, 'VALUE');
                  if (!value) {
                    return;
                  }
                  setAge(prevState => value);
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <Footer>
        <View flexRowSt spaceBetweenSt footerSmallRoundButtonWrapperSt>
          <View autoWidthSt flexRowSt>
            <View autoWidthSt smallDotSt />
            <Text style={{ marginTop: 10 }}>
              <Enfatize style={{ fontSize: '35px', fontWeight: 100 }}>
                1
              </Enfatize>
              /6
            </Text>
          </View>
          <View autoWidthSt flexRowSt>
            {/* <View autoWidthSt footerRoundIconButtonSt>
              <ListIcon />
            </View> */}
            <View
              autoWidthSt
              footerRoundOkButtonSt
              style={{ paddingTop: 5, fontWeight: 'bold' }}
              onClick={() => {
                dispatch({
                  type: Types.NEW_PLAN_SIMULATION,
                  payload: {
                    age: age,
                  },
                });
                props.history.push(routes.retirementSimulationContribution);
              }}
            >
              OK
            </View>
          </View>
        </View>
      </Footer>
    </>
  );
};
