import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// import SvgIcon from '@material-ui/core/SvgIcon';
// import Typography from '@material-ui/core/Typography';
//import { withStyles } from '@material-ui/core/styles'

//import { Utils } from '../../src-core/resources/index'
// import { Utils } from '../../../resources/index';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className='footerComp'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          bottom: 0,
          flexFlow: 'column',
          ...this.props.style,
        }}
      >
        {this.props.areButtons ? (
          <div className='footerButtons'>{this.props.children}</div>
        ) : (
          <Fragment>{this.props.children}</Fragment>
        )}
      </div>
    );
  }
}

Footer.propTypes = {
  areButtons: PropTypes.bool,
};

Footer.defaultProps = {
  areButtons: true,
};

/*
const styles = theme => ({
  container: {
  },
  title: {
    fontWeight: 100,
    fontSize: '35px',
    lineHeight: '45px',
    textAlign: 'center',
    color: color
  },
})
*/

//export default withStyles(styles, { withTheme: true })(Header)
export default Footer;
