import React, { Component } from 'react';

//import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Dialog from '@material-ui/core/Dialog';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import Slide from '@material-ui/core/Slide';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

import MyIcon from '../../components/MyIcon';

import { Utils } from '../../src-core/resources/index';
// import { productInfo_strings } from './text';
// import ProductInfoGraph from '../Product/ProductInfoGraphs';
// import ModalView from '../../components/MyModalView';
// import MyIcon from '../../components/MyIcon';

class FollowupInfoWish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalContent: null,
      modalTitle: '',
    };
  }

  onCloseModal() {
    console.log('onCloseModal');
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const { product, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    console.log('productInfo.render product: ', product);
    console.log('productInfo.render type: ', this.props, this.state);
    return (
      <React.Fragment>
        <MyIcon name='check' style={{ height: 80 }} />

        <Typography
          variant='body1'
          color='primary'
          style={{ width: '100%', marginTop: 30 }}
        >
          {/* {this.props.product.founds[0].label} */}
          {this.props.product.label}
        </Typography>
        <div
          className={classes.targetSummary_renta_container}
          style={{ paddingBottom: 10, width: '100%' }}
        >
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetSummary_renta_typography1',
              classes,
            )}
          >
            Tipo de Interes Técnico
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'targetSummary_renta_typography2',
              classes,
            )}
          >
            {(this.props.product.profitability * 100).toFixed(2)} %
          </Typography>
          {/*ºº
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses('targetSummary_renta_typography3',classes)}
            >
              *Rentabilidad acumulada del fondo según ficha de la cartera.
            </Typography>
            */}
        </div>
      </React.Fragment>
    );
  }
}

export default FollowupInfoWish;
