import React, { useState, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MyText from '../../../components/MyText';
import Flow from '../../../components/Flow';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import Currency from '../../../components/Currency';

function ProductSimulatorInsurance(props) {
  console.log('ProductSimulatorInsurance', props);
  const params = props.location.state;

  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);

  function ExpandItem(props) {
    return (
      <Fragment>
        <CardActions
          disableSpacing
          onClick={() => props.onClick()}
          style={{
            width: '100%',
            borderTopWidth: 1,
            borderTopStyle: 'dotted',
            borderTopColor: 'black',
            color: 'black',
          }}
        >
          <MyText
            id={props.id}
            style={{
              fontSize: 18,
              fontWeight: 600,
              width: '90%',
              color: 'black',
            }}
          >
            {props.title}
          </MyText>
          <IconButton
            style={{
              width: '10%',
              color: 'black',
            }}
            //aria-expanded={openCollapse1}
            aria-label='show more'
          >
            <ExpandMoreIcon
              style={
                props.show
                  ? {
                      transform: 'rotate(180deg)',
                      transition: 'transform 2s',
                    }
                  : {
                      transform: 'rotate(0deg)',
                      transition: 'transform 2s',
                    }
              }
            />
          </IconButton>
        </CardActions>
        <Collapse
          in={props.show}
          style={{
            width: '100%',
            color: 'black',
            textAlign: 'left',
            fontFamily: 'Calibre',
          }}
        >
          {props.children}
        </Collapse>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header modal={true} title='Seguro de Vida' history={props.history} />
      <Body vAlign='top'>
        <MyText
          style={{
            marginBottom: 20,
          }}
        >
          El producto Plan de Inversión Inteligente es, en términos técnicos, un
          seguro de vida-ahorro.
        </MyText>
        <div
          style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'white',
            padding: 15,
            backgroundColor: '#e9e9e9',
            color: 'black',
            borderRadius: 5,
          }}
        >
          <div>
            <MyText
              variant='h1'
              style={{
                color: 'black',
              }}
            >
              Costes del Plan de Inversión Inteligente
            </MyText>

            <ExpandItem
              id='porqueExpand'
              show={collapse1}
              onClick={() => setCollapse1(!collapse1)}
              title='¿Por qué tengo que contratar un seguro?'
            >
              <p>
                Por sus múltiples ventajas hemos decidido adoptar para el Plan
                de Inversión Inteligente un{' '}
                <strong>formato de seguro de vida-ahorro</strong> conocido como
                "Unit Linked". Este seguro se abre formalmente a través de Caser
                Seguros y es necesario para poder aprovechar todas estas
                ventajas:
              </p>
            </ExpandItem>

            <ExpandItem
              id='beneficiosExpand'
              show={collapse2}
              onClick={() => setCollapse2(!collapse2)}
              title='¿Qué beneficios tiene para mi?'
            >
              <Fragment>
                <p>
                  Adoptar este formato nos permite asegurarte una serie de
                  ventajas muy provechosas comparado con otras alternativas en
                  el mercado, como:
                </p>
                <ul>
                  <li>Sin barreras de entrada</li>
                  <li>Puedes aportar cuando quieras</li>
                  <li>Y retirar tu dinero cuando quieras</li>
                  <li>Consigues un plan más diversificado</li>
                  <li>Tienes un seguro de vida incluido</li>
                </ul>
                <div>
                  <MyText
                    id='infoBeneficios'
                    style={{
                      color: 'black',
                    }}
                    variant='link'
                    onClick={() =>
                      Flow.navigate('/product/simulator/unitlinked', props)
                    }
                  >
                    Más información sobre los beneficios
                  </MyText>
                </div>
              </Fragment>
            </ExpandItem>

            <ExpandItem
              id='queEsExpand'
              show={collapse3}
              onClick={() => setCollapse3(!collapse3)}
              title='¿Qué es un Unit Linked?'
            >
              <p>
                Unit Linked es un seguro de vida-ahorro en el que el tomador del
                seguro, es decir, la persona que lo contrata, asume los riesgos
                y beneficios de la inversión.
              </p>
            </ExpandItem>

            <ExpandItem
              id='costeExpand'
              show={collapse4}
              onClick={() => setCollapse4(!collapse4)}
              title='¿Qué coste tiene el seguro?'
            >
              <Fragment>
                <p>
                  Al estar contratando un seguro, el importe a pagar, denominado
                  "prima" en el lenguaje asegurador, estará relacionado solo con
                  tu edad actuarial. Cuanto más mayor seas, más alta será la
                  cuantía de tu prima anual.
                </p>
                <p>
                  El pago del seguro es anual, siendo en tu caso concreto{' '}
                  {Currency(params.datosEscenario.costeSeguroPrimerAño, 2)} el
                  primer año, pagando un total de{' '}
                  {Currency(params.datosEscenario.costeSeguroTotal, 2)} en total
                  si mantienes activo tu Plan de Inversión Inteligente durante
                  los 10 años previstos.
                </p>
                <p>
                  Puedes ver el desglose del coste anual del seguro desde
                  <span
                    style={{
                      textDecoration: 'underline',
                    }}
                    onClick={() =>
                      Flow.navigate(
                        '/product/simulator/unitlinkedAnnual',
                        props,
                      )
                    }
                  >
                    {' '}
                    aquí
                  </span>
                  .
                </p>
              </Fragment>
            </ExpandItem>

            <ExpandItem
              id='quienExpand'
              show={collapse5}
              onClick={() => setCollapse5(!collapse5)}
              title='¿Quién cobra ese importe?'
            >
              <Fragment>
                <p>
                  El contrato de seguro se abrirá formalmente a través de Caser
                  Seguros, aseguradora nacional de reconocido prestigio y
                  elevada solvencia, Caser recibirá las primas por darte acceso
                  a los beneficios descritos arriba.
                </p>
              </Fragment>
            </ExpandItem>
          </div>
        </div>
      </Body>
    </Fragment>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 32,
    width: '100%',
    height: '100%',
  },
  expandDown: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandUp: {
    transform: 'rotate(180deg)',
  },
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(
  ProductSimulatorInsurance,
);
