import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

function MyTextForInput(props) {
  let moreStyle = null;
  //console.log('MyTextForInput', props);
  if (props.inLine) {
    moreStyle = {
      display: 'flex',
      justifyContent: 'space-between',
    };
  }

  return (
    <div
      style={{
        margin: 10,
        ...moreStyle,
        ...props.style,
      }}
    >
      <div>
        <Typography
          variant='body1'
          color='secondary'
          style={{
            fontSize: 12,
            textAlign: 'left',
            //lineHeight: 11.3,
            fontWeight: 600,
            //marginLeft: 11.5,
            letterSpacing: 0,
            textTransform: 'uppercase',
            padding: '6px 0 7px',
          }}
        >
          {props.label}
        </Typography>
      </div>
      <div //className={classes.ahorroHot_body_targetSelector_info_secondRow}
        style={{
          display: 'flex',
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

const styles = theme => ({
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(
  MyTextForInput,
);
