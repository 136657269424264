function generateStyles(theme) {
  const styles = {
    rulesList_container: {
      minHeight: 511,
    },
    rulesList_img1: {
      maxWidth: '100%',
    },
    rulesList_listContainer: {
      //marginLeft: '24px',
      //marginRight: '24px',
      marginBottom: '6px',
      marginTop: '6px',
    },
    rulesList_listItem1_root: {
      padding: 0,
      width: '312px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
    },
    rulesList_typography1_root: {
      fontWeight: 100,
      fontSize: '33px',
      lineHeight: '33px',
      display: 'inline-block',
      textTransform: 'Capitalize',
      fontFamily: 'Calibre',
    },
    rulesList_typography2_root: {
      fontSize: '14px',
      lineHeight: '14px',
      textAlign: 'center',
    },
    rulesList_iconButton1_root: {
      //fontSize: 12,
      fontWeight: 600,
      //display: 'inline-block'
    },
    rulesList_imgDiv: {
      position: 'relative',
      minWidth: 300,
      maxWidth: 350,
      height: 150,
      backgroundColor: 'rgba(255,255,255,0.098)',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    rulesList_divText: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      paddingLeft: 16,
      paddingTop: 12,
      paddingRight: 12,
    },
    rulesList_card1_root: {
      borderRadius: 'unset',
      backgroundColor: 'rgba(255,255,255,0.098)',
      maxWidth: 300,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    rulesList_cardContent1_root: {
      textAlign: 'justify',
    },
    rulesList_button1_root: {
      width: '152px',
      borderRadius: '4px',
      margin: 'unset',
      backgroundColor: 'rgba(255,255,255,0.098)',
    },
    rulesList_button1_label: {
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: 600,
    },
    rulesList_cardActions1_root: {
      padding: 'unset',
    },
    ruleList_modal1_root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ruleList_modalContainer: {
      height: '80vh',
      minHeight: 454,
      width: '80%',
      backgroundColor: theme.palette.primary.main,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    newRule_container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '-20px',
    },
    newRule_container_icon_root: {
      height: '46px',
      width: '46px',
    },
    rulesList_typography3_root: {
      fontSize: '11px',
      textTransform: 'uppercase',
      lineHeight: '11px',
      textAlign: 'center',
      fontWeight: 600,
      maxWidth: 300,
    },
  };
  return styles;
}

export default generateStyles;
