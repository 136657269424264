import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, Text, Enfatize, TextField } from 'coinscrap-webapp-core';
import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import colors from '../../../../config/colors';
import CircularSlider from '@fseehawer/react-circular-slider';
import { getCurrency } from '../../../../libs/Currency';
import routes from '../../../../config/routes';
import { StoreContext } from '../../../../pensionStore/Store';
import * as Types from '../../../../pensionStore/types';
import Billetes from '../../../common/svgs/Billetes';
import navConfig from '../../config/navigation';
import {
  onChangeAmountHandler,
  amountStringToNumber,
} from '../../../../libs/currencyInput';
export default props => {
  const [salary, setSalary] = useState(25000);
  const [salaryInput, setSalaryInput] = useState(25000);
  const [showInput, setShowInput] = useState(false);
  const salaryInputRef = useRef(null);
  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  useEffect(() => {
    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
    state.simulation.grossIncome && setSalary(state.simulation.grossIncome);
    state.simulation.grossIncome &&
      setSalaryInput(state.simulation.grossIncome);
  }, []);

  useEffect(() => {
    if (showInput) {
      salaryInputRef.current.focus();
    }
  }, [showInput]);

  const handleBlur = () => {
    setShowInput(false);
  };

  // useEffect(() => {
  //   const number = amountStringToNumber(salaryInput);

  //   setSalary(number);
  // }, [salaryInput]);

  useEffect(() => {
    const { current } = salaryInputRef;

    if (!current) {
      return;
    }

    current.addEventListener('blur', handleBlur);

    return () => {
      current.removeEventListener('blur', handleBlur);
    };
  }, [handleBlur]);

  const onChangeSalary = value => {
    const number = onChangeAmountHandler(value, setSalaryInput);

    const number2 = amountStringToNumber(number);

    setSalary(number2);
  };
  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt bodyAddTopPaddingSt>
        <View width93St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Billetes style={{ color: colors.iconGreen }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              ¿Cúal es tu salario bruto?
            </Text>
            <View
              style={{
                marginTop: 10,
                marginBottom: 15,
                position: 'relative',
              }}
            >
              <>
                <CircularSlider
                  dataIndex={salary}
                  width={200}
                  trackSize={14}
                  progressSize={14}
                  trackColor='#6d5e57'
                  progressColorTo='#54e0c0'
                  progressColorFrom='#54e0c0'
                  knobColor='#ffffff'
                  renderLabelValue={<View></View>}
                  label='IMPORTE'
                  min={0}
                  max={100000}
                  onChange={value => {
                    // var result = 100 * Math.ceil(value / 100);
                    var result = Math.round(value / 100) * 100;
                    setSalary(result);
                  }}
                />
                <View
                  autoWidthSt
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: 3,
                  }}
                >
                  <Text font12St boldSt style={{ marginBottom: 7 }}>
                    IMPORTE
                  </Text>
                  {showInput ? (
                    <TextField
                      value={salaryInput}
                      inputRef={salaryInputRef}
                      salaryInputSt
                      onChange={e => onChangeSalary(e.target.value)}
                    />
                  ) : (
                    <Text
                      onClick={() => {
                        setSalaryInput(salary);
                        setShowInput(true);
                      }}
                      style={{ fontSize: 32, zIndex: 5 }}
                    >
                      {getCurrency(salary)}
                    </Text>
                  )}
                  <View
                    style={{
                      borderTop: '1px solid rgba(255, 255, 255, 0.5)',
                      width: 130,
                      marginTop: 7,
                      paddingTop: 4,
                    }}
                  >
                    <Text font12St boldSt>
                      BRUTO / AÑO
                    </Text>
                  </View>
                </View>
              </>
            </View>
          </View>
        </View>
        <View style={{ paddingBottom: 10, justifyContent: 'flex-end' }}>
          <View width85St>
            <Text centerSt font16St>
              Con tu salario bruto anual estimaremos tus cotizaciones a la
              Seguridad Social durante los 25 años previos a la jubilación.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View flexRowSt spaceBetweenSt footerSmallRoundButtonWrapperSt>
          <View autoWidthSt flexRowSt>
            <View autoWidthSt smallDotSt />
            <Text style={{ marginTop: 10 }}>
              <Enfatize style={{ fontSize: '35px', fontWeight: 100 }}>
                4
              </Enfatize>
              /6
            </Text>
          </View>
          <View autoWidthSt flexRowSt>
            <View
              autoWidthSt
              footerRoundOkButtonSt
              // disabledButtonSt={!salary || salary === 0}
              style={{ paddingTop: 5, fontWeight: 'bold' }}
              onClick={() => {
                dispatch({
                  type: Types.NEW_PLAN_SIMULATION,
                  payload: {
                    grossIncome: salary,
                  },
                });

                props.history.push(
                  routes.retirementSimulationPensionCongratulations,
                );
              }}
            >
              OK
            </View>
          </View>
        </View>
      </Footer>
    </>
  );
};
