import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline'
//import CreditCard from '@material-ui/icons/CreditCard'
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdorment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Check from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { BanksAPI } from '../../src-core/sdk/index';
//import { BanksAPI } from '../../src-core/sdk/index';
import { Utils } from '../../src-core/resources/index';

import Flow from '../../components/Flow';
import Header from '../../components/MyHeader';
// import {
//   ActivateAccounts,
//   ActivateAccountsConfig,
//   ActivateAccountsStyles,
// } from '../../core/componets/banks';

import ActivateAccounts from '../activateAccounts/activateAccounts.component';
import ActivateAccountsConfig from '../activateAccounts/activateAccounts.config';
import ActivateAccountsStyles from '../activateAccounts/activateAccounts.style';

import { cargos_strings } from './text';
//import Modal from '@material-ui/core/Modal'

import CargosInitialAccounts from './cargosInitialAccounts.component';
import { styles as AccountsStyles } from './cargosInitial.component';
import SvgIcon from '@material-ui/core/SvgIcon';

class Cargos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banksUser: null,
      open: false,
      listBanks: null,
      filteredBanks: null,
      query: '',
      showAutoComplete: false,
      selectedBank: null,
      showInputCredentials: false,
      bankFields: null,
      login: false,
      errorLogin: false,
      errorMessage: '',
    };
  }

  goChat() {
    this.props.history.push({
      pathname: '/chatSupport',
      state: {
        back: this.props.location.pathname,
      },
    });
  }

  componentDidMount() {
    this.getUserBanks();
    this.getBanksEntities();
  }

  /*
  getUserBanks() {
    BanksAPI.getUserBanks(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw response.status
        }
      })      
      .then(data => {
        console.log("Cargos.getUserBanks",data);
        this.setState({
          banksUser: data
        })
      })
      .catch(error => {
        console.log('error reuperando los datos bancarios de usuario ', error)
      })
  }
  */

  async getUserBanks() {
    const data = await BanksAPI.getUserBanks(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    //console.log("Cargos.getUserBanks",data);
    this.setState({
      banksUser: data,
    });
  }

  getBanksEntities() {
    BanksAPI.getListBanksEntities(this.props.baseUrl, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          listBanks: data.filter(
            b => b.id !== 'abanca' && b.id !== 'abanca_emp',
          ),
        });
      })
      .catch(error => {
        console.log('error consiguiendo la lista de bancos ', error);
      });
  }

  activateAccount(idAccount, data) {
    BanksAPI.activateAccount(
      this.props.baseUrl,
      idAccount,
      data,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
        } else {
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error en la activación de una cuenta ', error);
      });
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetAccounts_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      filteredBanks: null,
      query: '',
      showAutoComplete: false,
      selectedBank: null,
      bankFields: null,
      showInputCredentials: false,
      errorLogin: false,
    });
  }

  renderBankSelector() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.modalDiv}>
        <div className={classes.selectorDiv}>
          <div className={classes.banksShowcaseDiv}>
            <div className={classes.banksShowcaseFirstRow}>
              <div
                className={classes.banksShowcaseFirstCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[0])}
              >
                <img
                  alt='0'
                  src={this.state.listBanks[0].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
              <div
                className={classes.banksShowcaseSecondCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[1])}
              >
                <img
                  alt='1'
                  src={this.state.listBanks[1].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
            </div>
            <div className={classes.banksShowcaseSecondRow}>
              <div
                className={classes.banksShowcaseFirstCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[2])}
              >
                <img
                  alt='2'
                  src={this.state.listBanks[2].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
              <div
                className={classes.banksShowcaseSecondCell}
                onClick={() => this.onSelectIconBank(this.state.listBanks[3])}
              >
                <img
                  alt='3'
                  src={this.state.listBanks[3].image}
                  className={classes.banksShowcaseImg}
                />
              </div>
            </div>
          </div>
          <div className={classes.inputContainer}>
            <Typography classes={transformClasses('typography7', classes)}>
              {cargos_strings['es'].cargos_informativeText1}
            </Typography>
            <Typography classes={transformClasses('typography8', classes)}>
              {cargos_strings['es'].cargos_informativeText2}
            </Typography>
            <FormControl classes={transformClasses('formControl', classes)}>
              <Input
                disableUnderline={true}
                classes={transformClasses('input1', classes)}
                startAdornment={
                  <InputAdorment position='start'>
                    <Search />
                  </InputAdorment>
                }
                endAdornment={
                  <InputAdorment
                    position='end'
                    onClick={() => this.selectBankForCredentials()}
                    className={
                      this.state.selectedBank !== null
                        ? classes.endAdornmentActive1
                        : classes.endAdornment1
                    }
                    color=''
                  >
                    <Check />
                  </InputAdorment>
                }
                value={this.state.query}
                onChange={this.onChangeQuery.bind(this)}
              />
            </FormControl>
            <div style={{ overflow: 'auto', flex: 1 }}>
              {this.state.showAutoComplete &&
              this.state.filteredBanks !== null ? (
                this.renderAutoComplete()
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            variant='contained'
            color='secondary'
            classes={transformClasses('button2', classes)}
            onClick={this.handleClose.bind(this)}
          >
            {cargos_strings['es'].cargos_closeText}
          </Button>
        </div>
      </div>
    );
  }

  renderCredentialsInput() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.modalDiv}>
        <div className={classes.headerBack}>
          <IconButton
            onClick={() => {
              this.setState({
                showInputCredentials: false,
                errorLogin: false,
                selectedBank: null,
                query: '',
              });
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </div>
        <div className={classes.bankIconContainer}>
          <img
            alt={this.state.selectedBank.name}
            src={
              /*this.state.listBanks[i].image*/

              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.others/public/banks/icons/' +
              this.state.selectedBank.id +
              '.png'
            }
            className={classes.bankIcon}
          />
        </div>
        <div className={classes.bankInputContainer}>
          {!this.state.login ? this.renderBankFields() : this.renderLoading()}
          {this.state.errorLogin ? (
            <Typography
              variant='body1'
              color='secondary'
              classes={transformClasses('typography9', classes)}
            >
              {this.state.errorMessage}
            </Typography>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.buttonDiv}>
          <Button
            variant='contained'
            color='secondary'
            classes={transformClasses('button2', classes)}
            onClick={() => this.loginBank()}
            className={this.state.login ? 'hide' : ''}
          >
            {cargos_strings['es'].cargos_loginText}
          </Button>
        </div>
      </div>
    );
  }

  renderBankFields() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.bankFields.map((field, index) => {
      return (
        <FormControl
          classes={transformClasses('formContro2', classes)}
          key={field.code}
        >
          <Input
            disableUnderline={true}
            classes={transformClasses('input2', classes)}
            value={this.state.bankFields[index].value}
            onChange={e => this.handleFieldChange(e, index)}
            type={this.state.bankFields[index].type}
            placeholder={field.label.es}
          />
        </FormControl>
      );
    });
    return elements;
  }

  handleFieldChange(event, index) {
    const arr = this.state.bankFields;
    arr[index].value = event.target.value;
    this.setState({
      bankFields: arr,
    });
  }

  onSelectIconBank(bank) {
    const arr = bank.authenticationForm.fields.slice();
    arr.map((field, index) => {
      arr[index].value = '';
      return null;
    });
    this.setState({
      showInputCredentials: true,
      query: '',
      selectedBank: bank,
      bankFields: arr,
      filteredBanks: null,
      showAutoComplete: false,
    });
  }

  renderAutoComplete() {
    const elements = this.state.filteredBanks.map(bank => {
      return (
        <div
          onClick={() => this.onSelectBank(bank.name.full, bank)}
          key={bank.id}
        >
          <Typography variant='body1' color='default'>
            {bank.name.full}
          </Typography>
        </div>
      );
    });
    return elements;
  }

  onSelectBank(name, bank) {
    const arr = bank.authenticationForm.fields.slice();
    arr.map((field, index) => {
      arr[index].value = '';
      return null;
    });
    this.setState({
      query: name,
      selectedBank: bank,
      bankFields: arr,
      filteredBanks: null,
      showAutoComplete: false,
    });
  }

  loginBank() {
    this.setState({
      login: true,
      errorLogin: false,
      errorMessage: '',
    });
    const data = {
      bankName: this.state.selectedBank.name.full,
      form: {},
    };
    this.state.bankFields.map(field => {
      data.form[field.code] = field.value;
      return null;
    });
    BanksAPI.loginBank(
      this.props.baseUrl,
      this.state.selectedBank.id,
      data,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        if (data.success) {
          this.getBankData();
        } else {
          this.setState({
            login: false,
            errorLogin: true,
            errorMessage: data.error.error_message,
          });
          console.log('error: ', data.error.error_message);
        }
      })
      .catch(error => {
        this.setState({
          login: false,
        });
        console.log('error en login banco ', error);
      });
  }

  getBankData() {
    BanksAPI.bankDataAvailable(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          this.handleClose();
          this.setState({
            login: false,
            banksUser: null,
          });
          this.getUserBanks();
        } else {
          this.handleClose();
          this.setState({
            login: false,
          });
          throw response.status;
        }
      })
      .catch(error => {
        console.log('error importando el banco ', error);
      });
  }

  filterBanks(filter) {
    const arr = [];
    this.state.listBanks.map(bank => {
      if (bank.name.full.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
        arr.push(bank);
      }
      return null;
    });
    this.setState({
      filteredBanks: arr,
    });
  }

  onChangeQuery(event) {
    if (event.target.value === '') {
      this.setState({
        showAutoComplete: false,
        query: '',
      });
    } else {
      this.filterBanks(event.target.value);
      this.setState({
        query: event.target.value,
        showAutoComplete: true,
      });
    }
  }

  selectBankForCredentials() {
    if (this.state.selectedBank !== null) {
      this.setState({
        showInputCredentials: true,
        filteredBanks: null,
        showAutoComplete: false,
      });
    }
  }

  renderAccountsEvo() {
    console.log(
      'Cargos.renderAccountsEvo',
      this.state.banksUser.filter(bank => bank.name === 'EVO Banco'),
    );

    const { classes } = this.props;
    return (
      <ActivateAccounts
        baseUrl={this.props.baseUrl}
        token={this.props.token}
        //ºº Ojo esta filtrando por name no por idBank
        banks={this.state.banksUser.filter(bank => bank.name === 'EVO Banco')}
        renderLoading={this.renderLoading.bind(this)}
        activateAccounts={this.activateAccount.bind(this)}
        classes={classes}
        config={ActivateAccountsConfig}
        history={this.props.history}
        onOpen={this.handleOpen.bind(this)}
        useAccountName={true}
      />
    );
  }

  renderOtherAccounts() {
    const { classes } = this.props;
    return (
      <ActivateAccounts
        baseUrl={this.props.baseUrl}
        token={this.props.token}
        //ºº Ojo esta filtrando por name no por idBank
        banks={this.state.banksUser.filter(bank => bank.name !== 'EVO Banco')}
        renderLoading={this.renderLoading.bind(this)}
        activateAccounts={this.activateAccount.bind(this)}
        classes={classes}
        config={ActivateAccountsConfig}
        history={this.props.history}
        onOpen={this.handleOpen.bind(this)}
        useAccountName={false}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    console.log('Cargos.render location', this.props.location);
    return (
      <div className={classes.container}>
        <Header
          title={'Mis cuentas'}
          modal={true}
          history={this.props.history}
        />
        {this.state.banksUser !== null ? (
          <div className={classes.cargos_container}>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'cargos_accounts_evo_title_typography1',
                classes,
              )}
            >
              Cuentas EVO
            </Typography>
            <CargosInitialAccounts
              {...this.props}
              renderAccounts={this.renderAccountsEvo.bind(this)}
            />
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'cargos_accounts_evo_title_typography2',
                classes,
              )}
            >
              Otros bancos
            </Typography>
            <CargosInitialAccounts
              {...this.props}
              renderAccounts={this.renderOtherAccounts.bind(this)}
            />
            <div className={classes.cargos_separator}>
              <hr className={classes.cargos_hr} />
            </div>
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'cargos_addAccount_typography1',
                classes,
              )}
              onClick={() =>
                Flow.navigate(
                  '/cuentasInitial/selector?fromCargo=true',
                  this.props,
                )
              }
            >
              Añadir cuentas de otros bancos{' '}
              <SvgIcon
                color='primary'
                classes={transformClasses('cargos_addAccount_svgIcon', classes)}
              >
                <path d='M 19 13 h -6 v 6 h -2 v -6 H 5 v -2 h 6 V 5 h 2 v 6 h 6 v 2 Z' />
              </SvgIcon>
            </Typography>
          </div>
        ) : (
          this.renderLoading()
        )}
      </div>
    );
  }
}

const styles = theme => ({
  ...ActivateAccountsStyles(theme),
  ...AccountsStyles(theme),
  targetAccounts_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  container: {
    height: '100vh',
    minHeight: 568,
    width: '100%',
    overflow: 'auto',
  },
  header_container: {
    height: '10vh',
    minHeight: 57,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  cargos_container: {
    height: '90vh',
    minHeight: 511,
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    //margin: 20,
    overflow: 'auto',
  },
  header_centerIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cargos_headerTypography1_root: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cargos_headerIconButton2_root: {
    padding: 'unset',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalDiv: {
    height: '90vh',
    minHeight: 511,
    width: '90%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: 'solid black 3px',
    '&:focus': {
      outline: 'unset',
    },
  },
  selectorDiv: {
    height: '80vh',
    minHeight: 454,
    width: '100%',
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  banksShowcaseDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  banksShowcaseFirstRow: {
    height: '20vh',
    minHeight: 114,
    width: '100%',
    display: 'flex',
    marginBottom: 6,
  },
  banksShowcaseSecondRow: {
    height: '20vh',
    minHeight: 114,
    width: '100%',
    display: 'flex',
    marginTop: 6,
  },
  banksShowcaseFirstCell: {
    width: '50%',
    height: '20vh',
    minHeight: 114,
    padding: '0 12px',
    display: 'flex',
    backgroundColor: '#A9A9A9',
    marginRight: 6,
  },
  banksShowcaseSecondCell: {
    width: '50%',
    height: '20vh',
    minHeight: 114,
    padding: '0 12px',
    display: 'flex',
    backgroundColor: '#A9A9A9',
    marginLeft: 6,
  },
  banksShowcaseImg: {
    maxWidth: '100%',
    height: 'auto',
    opacity: 0.7,
  },
  bankIconContainer: {
    height: '20vh',
    minHeight: 114,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerBack: {
    height: '10vh',
    minHeight: 57,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  bankIcon: {
    maxHeight: '100%',
  },
  bankInputContainer: {
    height: '50vh',
    minHeight: 284,
    paddingTop: 24,
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '10vh',
    minHeight: 57,
    width: '100%',
    padding: '0px 12px 12px',
    justifyContent: 'space-between',
  },
  inputContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 36px',
  },
  input1_root: {
    marginTop: 24,
    border: 'solid 3px black',
  },
  input2_root: {
    marginTop: 24,
    border: 'solid 3px black',
  },
  input3_root: {
    marginTop: 12,
    border: 'solid 3px black',
  },
  button2_root: {
    width: '100%',
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  typography7_root: {
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: 24,
  },
  typography9_root: {
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: 24,
  },
  cargos_accounts_evo_title_typography1_root: {
    ...AccountsStyles(theme).cargos_accounts_evo_title_typography1_root,
    marginLeft: 10,
    marginTop: 20,
  },
  cargos_accounts_evo_title_typography2_root: {
    ...AccountsStyles(theme).cargos_accounts_evo_title_typography2_root,
    marginLeft: 10,
  },
});

export default withStyles(styles, { withTheme: true })(Cargos);
