import React, { Component } from 'react';
import { View } from 'coinscrap-webapp-core';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
// import SvgIcon from '@material-ui/core/SvgIcon';

//import { RulesAPI } from '../src-core/sdk/index';
import { RulesAPI } from '../../src-core/sdk/index';

import { Utils } from '../../src-core/resources/index';

import Picker from '../../components/picker/picker.component';
import SwipeableViewsConfigurable from '../swipeableViews/swipeableViewsConfigurable.component';
import Body from '../../components/MyBody';
import Flow from '../../components/Flow';
import MyAnimation from '../../components/MyAnimation';
import MyIcon from '../../components/MyIcon';
import { StoreContext } from '../../pensionStore/Store';

class SwipeRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesDefinitions: null, //props.rulesDefinitions,
      rules: null, //props.rules,
      autocomplete: null,
      configuredRules: [],
      showAutocomplete: false,
      fieldToAutocomplete: null,
      activeRules: null,
      index: 0,
      indexLatest: null,
      open: false,
      valueGroups1: {
        title: { name: '1', id: '1' },
      },
      valueGroups2: {
        title: { name: '1', id: '1' },
      },
      valueGroups3: {
        title: '',
      },
      optionGroups1: {
        title: null,
      },
      optionGroups2: {
        title: null,
      },
      optionGroups3: {
        title: null,
      },
      // Tuve q hacer una ñapa, para el caso de Equipo,
      // no se porque no guarda el valor en array normal al hacer el setState!?!?
      valueEquipo: null,
    };
    this.renderData = this.renderData.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.goToNext = this.goToNext.bind(this);
  }

  static contextType = StoreContext;

  componentDidMount() {
    const obj = {};

    Object.keys(this.props.rules).map(key => {
      obj[key] = { active: false, ruleID: null };
      return true;
    });
    this.props.rulesTarget.map(rule => {
      if (
        !rule.ruleDefinition.hideInCreation &&
        rule.isActive &&
        !rule.ruleDefinition.required
      ) {
        obj[rule.ruleDefinition._id] = {
          active: true,
          ruleID: rule._id,
        };
      }
      return true;
    });

    // let filteredRulesDefinitions= this.props.rulesDefinitions.filtered((item)=> {

    // })
    console.log({ rulesDefinitions: this.props.rulesDefinitions });
    this.setState({
      rulesDefinitions: this.props.rulesDefinitions,
      rules: this.props.rules,
      activeRules: obj,
    });
  }

  ruleAlreadySet(id) {
    if (
      this.state.configuredRules.indexOf(id) >= 0 ||
      this.props.rulesTarget
        .map(e => {
          return e.ruleDefinition._id;
        })
        .indexOf(id) >= 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getAutocomplete(query, id, name) {
    RulesAPI.getAutocomplete(
      this.props.baseUrl,
      id,
      name,
      query,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          autocomplete: data,
        });
      })
      .catch(error => {
        console.log('error recuperando autocomplete', error);
      });
  }

  isValidData(id, pos) {
    if (this.state.rules[id].value.transactionsAmountReductor !== undefined) {
      const value = this.state.rules[id].value.transactionsAmountReductor[
        this.state.rules[id].value.transactionsAmountReductor.type.toLowerCase()
      ];
      if (
        value >=
          this.props.rulesDefinitions[pos].transactionsAmountReductor.min &&
        value <= this.props.rulesDefinitions[pos].transactionsAmountReductor.max
      ) {
        return { valid: true };
      } else {
        console.log(
          'isValidData min-max',
          this.props.rulesDefinitions[pos].transactionsAmountReductor.min,
          this.props.rulesDefinitions[pos].transactionsAmountReductor.max,
        );
        return {
          valid: false,
          min: this.props.rulesDefinitions[pos].transactionsAmountReductor.min,
          max: this.props.rulesDefinitions[pos].transactionsAmountReductor.max,
        };
      }
    } else {
      const values = this.state.rules[id].value.fieldsValues.map(e => {
        return e.value;
      });
      const valid = values.map((e, index) => {
        console.log('isValidData check e, index', e, index);
        if (this.props.rulesDefinitions[pos].fields[index].min !== undefined) {
          if (e >= this.props.rulesDefinitions[pos].fields[index].min) {
            return true;
          } else {
            console.log(
              'isValidData valor min1',
              this.props.rulesDefinitions[pos].fields[index].min,
            );

            return false;
          }
        } else {
          return true;
        }
      });
      if (valid.indexOf(false) >= 0) {
        console.log(
          'isValidData valor min2',
          this.props.rulesDefinitions[pos].fields[valid.indexOf(false)].min,
        );
        return {
          valid: false,
          min: this.props.rulesDefinitions[pos].fields[valid.indexOf(false)]
            .min,
        };
      } else {
        return { valid: true };
      }
    }
  }

  setRuleMonthlyTransferNotApplicable(id, index) {
    const rule = JSON.parse(JSON.stringify(this.state.rules[id]));
    console.log('SwipeRule.setRule 1', rule);
    // Eliminamos lo q sobra
    if (rule.value.fieldsValues) {
      rule.value.fieldsValues[0].value = 0;
      delete rule.value.fieldsValues[0].default;
      delete rule.value.fieldsValues[0].modificado;
      delete rule.value.fieldsValues[0].identifier;
    }
    console.log('SwipeRule.setRule 2', rule);

    RulesAPI.setRule(
      this.props.baseUrl,
      this.props.temporaryID,
      rule,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        const obj = this.state.configuredRules;
        if (obj.indexOf(this.props.rulesDefinitions[index]._id) < 0) {
          obj.push(this.props.rulesDefinitions[index]._id);
          this.setState({
            configuredRules: obj,
          });
        }
        if (!this.props.rulesDefinitions[index].required) {
          const obj = this.state.activeRules;
          obj[this.props.rulesDefinitions[index]._id] = {
            active: true,
            ruleID: data._id,
          };
          this.setState({
            activeRules: obj,
          });
          this.activateRule(data._id);
        }
        if (this.state.index < this.state.rulesDefinitions.length - 1) {
          this.setState({
            index: this.state.index + 1,
          });
        } else {
          this.props.goNext();
        }
      })
      .catch(error => {
        console.log('error actualizando regla', error);
      });
  }

  setRule(id, index) {
    let valid = true;
    const rule = this.state.rulesDefinitions[index];
    if (
      rule.name === 'Aportación mensual' &&
      this.props.type === 'retirement'
    ) {
      const retirementMin = 30;
      const value = +this.state.rules[id].value.fieldsValues[0].value;
      if (value < retirementMin) {
        this.setState({
          errorMessage: 'Introduzca un valor superior o igual a 30 €.',
          indexLatest: this.state.index,
          open: true,
        });
        valid = false;
      }
    } else {
      valid = this.isValidData(id, index).valid;
    }
    if (valid) {
      //console.log('setRule', this.state.rules[id])
      // Se eliminan los campos a mayores q se metieron de comodin
      // para q no falle el servicio

      // Lo clonamos
      const rule = JSON.parse(JSON.stringify(this.state.rules[id]));
      console.log('SwipeRule.setRule 1', rule);
      // Eliminamos lo q sobra
      if (rule.value.fieldsValues) {
        delete rule.value.fieldsValues[0].default;
        delete rule.value.fieldsValues[0].modificado;
        delete rule.value.fieldsValues[0].identifier;

        if (rule.value.fieldsValues[0].name === 'team') {
          rule.value.fieldsValues[0].value = this.state.valueEquipo;
        }
      }
      console.log('SwipeRule.setRule 2', rule);

      RulesAPI.setRule(
        this.props.baseUrl,
        this.props.temporaryID,
        rule,
        this.props.token,
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.status;
          }
        })
        .then(data => {
          const obj = this.state.configuredRules;
          if (obj.indexOf(this.props.rulesDefinitions[index]._id) < 0) {
            obj.push(this.props.rulesDefinitions[index]._id);
            this.setState({
              configuredRules: obj,
            });
          }
          if (!this.props.rulesDefinitions[index].required) {
            const obj = this.state.activeRules;
            obj[this.props.rulesDefinitions[index]._id] = {
              active: true,
              ruleID: data._id,
            };
            this.setState({
              activeRules: obj,
            });
            this.activateRule(data._id);
          }
          if (this.state.index < this.state.rulesDefinitions.length - 1) {
            this.setState({
              index: this.state.index + 1,
            });
          } else {
            this.props.goNext();
          }
        })
        .catch(error => {
          console.log('error actualizando regla', error);
        });
    } else {
      if (
        rule.name === 'Aportación mensual' &&
        this.props.type === 'retirement'
      ) {
        return;
      } else {
        this.setState({
          errorMessage:
            'Los datos introducidos no son los correctos, vuelva a intentarlo.',
          indexLatest: this.state.index,
          open: true,
        });
      }
    }
  }

  activateRule(id) {
    const { baseUrl, token } = this.props;
    RulesAPI.getRule(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        if (!data.isActive) {
          const data = {
            status: true,
          };
          RulesAPI.setActive(baseUrl, id, data, token);
        }
      })
      .catch(error => {
        console.log('error activando la relga: ', error);
      });
  }

  handleChangeIndex(index, indexLatest) {
    if (
      this.state.rulesDefinitions[indexLatest].required &&
      !this.ruleAlreadySet(this.state.rulesDefinitions[indexLatest]._id) &&
      index > indexLatest
    ) {
      this.setState({
        errorMessage:
          'La regla anterior era obligatoria, por favor vuelva y configurela',
        open: true,
      });
    }
    this.setState({
      index: index,
      indexLatest: indexLatest,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      index: this.state.indexLatest,
    });
  }

  deactivateRule(id, index) {
    console.log('swipeRules.deactivateRule ');
    const { baseUrl, token } = this.props;
    if (this.state.activeRules[this.props.rulesDefinitions[index]._id].active) {
      const data = {
        status: false,
      };
      RulesAPI.setActive(
        baseUrl,
        this.state.activeRules[this.props.rulesDefinitions[index]._id].ruleID,
        data,
        token,
      )
        .then(response => {
          if (response.status === 200) {
            const obj = this.state.activeRules;
            obj[this.props.rulesDefinitions[index]._id] = {
              active: false,
              ruleID: data._id,
            };
            this.setState({
              activeRules: obj,
            });
          } else {
            throw response.status;
          }
        })
        .catch(error => {
          console.log('error desactivando una regla: ', error);
        });
    }
    if (this.state.index < this.state.rulesDefinitions.length - 1) {
      this.setState({
        index: this.state.index + 1,
      });
    } else {
      this.props.goNext();
    }
  }

  renderFields(ruleDefinition) {
    const params = Flow.getParams(this.props);
    console.log('SwipeRules.renderFields', params);
    const { state } = this.context;

    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = ruleDefinition.fields.map((field, index) => {
      const regla = this.state.rules[ruleDefinition._id].value.fieldsValues[
        index
      ];
      let valor = 0;

      //ººº A ver si no afecta a los otros casos

      if (regla.modificado) {
        valor = regla.value;
      } else {
        if (regla.identifier === 'PERIODIC_SAVING') {
          valor =
            this.props.type === 'retirement'
              ? params.mode === 'retirementManual'
                ? state.manual.monthlyContribution
                : state.simulation.monthlyContribution
              : params.impMensual
              ? params.impMensual
              : regla.default;
        } else {
          valor = regla.default;
        }
      }
      regla.value = valor;

      //console.log('renderFields', regla, valor);

      if (field.options === undefined) {
        if (ruleDefinition.movements.disableExtraordinaryContributions) {
          return (
            <div
              style={{
                width: '100%',
                padding: '0 48px',
                marginTop: '34px',
              }}
              key={field._id}
            >
              <div className={classes.swipeRules_body_targetSelector_container}>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_leftIco_container
                  }
                >
                  <MyIcon name='euro' />
                  {/* <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'swipeRules_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                    viewBox='0 0 28 28'
                  >
                    <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
                  </SvgIcon> */}
                </div>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      {field.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_secondRow
                    }
                  >
                    <Input
                      id='impInicial'
                      disableUnderline
                      value={valor}
                      placeholder='Ejemplo 200 €'
                      onChange={e =>
                        this.handleChangeFields(e, ruleDefinition, index)
                      }
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              style={{
                width: '100%',
                padding: '0 48px',
                marginTop: '34px',
              }}
              key={field._id}
            >
              <div className={classes.swipeRules_body_targetSelector_container}>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_leftIco_container
                  }
                >
                  <MyIcon name='euro' />

                  {/* <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'swipeRules_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                    viewBox='0 0 28 28'
                  >
                    <path d='M 13.997278,0 C 21.733056,0 28,6.267722 28,14.000389 28,21.732278 21.733056,28 13.997278,28 6.266944,28 0,21.732278 0,14.000389 0,6.267722 6.266944,0 13.997278,0 Z m 0,26.334389 c 6.802833,0 12.3375,-5.533111 12.3375,-12.334 0,-6.801278 -5.534667,-12.334778 -12.3375,-12.334778 -6.8005,0 -12.332056,5.5335 -12.332056,12.334778 0,6.800889 5.531556,12.334 12.332056,12.334 z M 15.118833,7.801111 c -2.575222,0 -4.788389,1.581222 -5.721333,3.823556 h 5.980722 c 0.460056,0 0.832611,0.372555 0.832611,0.833 0,0.459666 -0.372555,0.832611 -0.832611,0.832611 h -6.412 c -0.02644,0.232555 -0.04356,0.469389 -0.04356,0.708944 0,0.240334 0.01711,0.477167 0.04356,0.710111 h 5.009667 c 0.459667,0 0.832611,0.373334 0.832611,0.833 0,0.459667 -0.372944,0.832611 -0.832611,0.832611 H 9.3975 c 0.933722,2.242334 3.146111,3.823945 5.721333,3.823945 1.247556,0 2.451945,-0.371 3.4825,-1.071778 0.3815,-0.259 0.898723,-0.159444 1.157334,0.220111 0.258222,0.380334 0.159833,0.898722 -0.2205,1.156945 -1.308223,0.889389 -2.836556,1.359944 -4.419334,1.359944 -3.507389,0 -6.483944,-2.310389 -7.493111,-5.489167 H 5.562278 c -0.460056,0 -0.832222,-0.372944 -0.832222,-0.832611 0,-0.459666 0.372166,-0.833 0.832222,-0.833 h 1.730944 c -0.021,-0.234111 -0.03578,-0.470166 -0.03578,-0.710111 0,-0.239166 0.01478,-0.475222 0.03578,-0.708944 H 5.562278 c -0.460056,0 -0.832222,-0.372945 -0.832222,-0.832611 0,-0.460445 0.372166,-0.833 0.832222,-0.833 H 7.625722 C 8.634889,8.445889 11.611444,6.1355 15.118833,6.1355 c 1.584334,0 3.112278,0.470167 4.419334,1.359556 0.380333,0.258222 0.478722,0.776611 0.2205,1.156944 -0.259,0.379556 -0.776611,0.478722 -1.157334,0.219722 -1.029389,-0.700389 -2.233777,-1.070611 -3.4825,-1.070611 z' />
                  </SvgIcon> */}
                </div>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      {field.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_secondRow
                    }
                  >
                    {/* Para QuedateEnCasa */}
                    <Input
                      disableUnderline
                      value={
                        this.state.rules[ruleDefinition._id].value.fieldsValues[
                          index
                        ].value
                      }
                      onChange={e =>
                        this.handleChangeFields(e, ruleDefinition, index)
                      }
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      } else {
        if (field.hasAutocomplete) {
          return (
            <div
              style={{
                width: '100%',
                padding: '0 48px',
                marginTop: '34px',
              }}
              key={field._id}
            >
              <div className={classes.swipeRules_body_targetSelector_container}>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_leftIco_container
                  }
                >
                  <MyIcon name='camisetaEquipo' />
                  {/* <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'swipeRules_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                    viewBox='0 0 28 28'
                    style={{ width: '36px', height: '36px' }}
                  >
                    <path d='M15.2,10.1V9.6h-0.9v0.5c0,0.3,0.2,0.5,0.5,0.5C14.9,10.6,15.2,10.4,15.2,10.1z M13.3,8.7h2.8v1.4      c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V8.7z M19.8,10.6v-3c0-0.5-0.3-1-0.8-1.2l-2.1-1.1c0,0.6,0.4,1.3,1,1.6l0.9,0.5v3.3      H19.8z M18,10.6V7.8l-0.4-0.2C16.5,7.1,15.9,6,16,5h-0.8v0.5c0,1.3-1,2.3-2.3,2.3s-2.3-1-2.3-2.3V5H9.7c0.1,1-0.5,2.2-1.5,2.6      L7.7,7.8v2.7h0.9V19H17v-8.4H18z M6.8,7.2l0.9-0.5c0.6-0.3,1-0.9,1-1.6L6.6,6.3C6.1,6.5,5.8,7,5.8,7.5v3h1V7.2z M11.4,5v0.5      c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4V5H11.4z M19.5,5.5c0.8,0.4,1.3,1.2,1.3,2.1v4H18v8.4H7.7v-8.4H4.9v-4      c0-0.9,0.5-1.7,1.3-2.1L9,4.1h1.5h4.6h1.5L19.5,5.5z' />
                  </SvgIcon> */}
                </div>
                <div
                  className={
                    classes.swipeRules_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      {field.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.swipeRules_body_targetSelector_info_secondRow
                    }
                  >
                    <Input
                      id='inputTeam'
                      disableUnderline
                      value={
                        this.state.valueEquipo ? this.state.valueEquipo : ''
                      }
                      placeholder='Ejemplo Barcelona'
                      onChange={e =>
                        this.handleChangeAutocomplete(
                          e,
                          ruleDefinition._id,
                          index,
                          field.name,
                        )
                      }
                      classes={transformClasses(
                        'swipeRules_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          if (this.state.optionGroups1.title !== null) {
            return (
              <div
                className={classes.swipeRules_picker_container}
                key={field._id}
              >
                <Picker
                  optionGroups={this.state.optionGroups3}
                  valueGroups={this.state.valueGroups3}
                  onChange={(name, value) =>
                    this.handleChangePickerFields(
                      value,
                      name,
                      ruleDefinition,
                      index,
                    )
                  }
                  itemHeight={34}
                  height={100}
                  classes={classes}
                />
              </div>
            );
          } else {
            this.setState(({ optionGroups3 }) => ({
              optionGroups3: {
                ...optionGroups3,
                title: this.returnPickerFields(field),
              },
            }));
          }
        }
      }
      return true;
    });
    return elements;
  }

  returnPickerFields(field) {
    const elements = field.options.map(value => {
      return {
        name: value,
        id: value,
        unit: field.unit,
      };
    });
    return elements;
  }

  renderTransactionsAmountReductor(ruleDefinition) {
    const { classes } = this.props;

    if (ruleDefinition.transactionsAmountReductor.type === 'PERCENTAGE') {
      if (this.state.optionGroups1.title !== null) {
        return (
          <div
            className={classes.swipeRules_picker_container}
            key={ruleDefinition._id}
          >
            <Picker
              optionGroups={this.state.optionGroups1}
              valueGroups={this.state.valueGroups1}
              onChange={(name, value) =>
                this.handleChangeTransactionsAmountReductor1(
                  value.name,
                  ruleDefinition,
                  name,
                  value,
                )
              }
              itemHeight={34}
              height={125}
              classes={classes}
            />
          </div>
        );
      } else {
        this.setState(({ optionGroups1 }) => ({
          optionGroups1: {
            ...optionGroups1,
            title: this.renderOptionsTransactionsAmountReductor(
              ruleDefinition,
              '%',
            ),
          },
        }));
      }
    } else if (ruleDefinition.transactionsAmountReductor.type === 'ROUND') {
      if (this.state.optionGroups2.title !== null) {
        return (
          <div
            className={classes.swipeRules_picker_container}
            key={ruleDefinition._id}
          >
            <Picker
              optionGroups={this.state.optionGroups2}
              valueGroups={this.state.valueGroups2}
              onChange={(name, value) =>
                this.handleChangeTransactionsAmountReductor2(
                  value.name,
                  ruleDefinition,
                  name,
                  value,
                )
              }
              itemHeight={34}
              height={125}
              classes={classes}
            />
          </div>
        );
      } else {
        this.setState(({ optionGroups2 }) => ({
          optionGroups2: {
            ...optionGroups2,
            title: this.renderOptionsTransactionsAmountReductor(
              ruleDefinition,
              '€',
            ),
          },
        }));
      }
    } else {
      return (
        <TextField
          key={ruleDefinition._id}
          label={ruleDefinition.name}
          value={
            this.state.rules[ruleDefinition._id].value
              .transactionsAmountReductor[
              ruleDefinition.transactionsAmountReductor.type.toLowerCase()
            ]
          }
          onChange={e =>
            this.handleChangeTransactionsAmountReductor(e, ruleDefinition)
          }
        />
      );
    }
  }
  renderOptionsTransactionsAmountReductor(ruleDefinition, unit) {
    const arrAux = Array.from(
      {
        length:
          ruleDefinition.transactionsAmountReductor.max -
          ruleDefinition.transactionsAmountReductor.min +
          1,
      },
      (e, i) => ({
        name: ruleDefinition.transactionsAmountReductor.min + i,
        id: ruleDefinition.transactionsAmountReductor.min + i,
        unit: unit,
      }),
    );
    return arrAux;
  }

  renderData() {
    console.log(this.props.rulesDefinitions);

    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.rulesDefinitions.map(
      (ruleDefinition, index) => (
        <div
          key={ruleDefinition.id}
          className={classes.swipeRules_divContainer + ruleDefinition}
          style={{ width: '100%' }}
        >
          <Body>
            <MyAnimation name={ruleDefinition.identifier} color='white' />
            {/* <MyAnimation name={'COINSCRAP_ROUNDS'} color='white' />
            <MyAnimation name={'PERIODIC_SAVING'} color='white' />
            <MyAnimation name={'NOMINA_SAVING'} color='white' />
            <MyAnimation name={'CANCER_DONATION'} color='white' />
            <MyAnimation name={'COFFEE_SAVING'} color='white' />
            <MyAnimation name={'COVID_SAVING'} color='white' />
            <MyAnimation name={'SMOKE_SAVING'} color='white' />
            <MyAnimation name={'TEAM'} color='white' />
            <MyAnimation name={'MOVIL'} color='white' />
            <MyAnimation name={'MOUNTAIN'} color='white' />
            <MyAnimation name={'MANO'} color='white' />
            <MyAnimation name={'PACMAN'} color='white' />
            <MyAnimation name={'AHORRO_HOT'} color='white' /> */}
            <Typography
              {...config.summaryCard.typography1}
              classes={transformClasses('cardSummary_typography1', classes)}
            >
              {ruleDefinition.name}
            </Typography>
            <div className={classes.swipeRules_divContainer2}>
              <Typography
                {...config.summaryCard.typography3}
                classes={transformClasses('cardSummary_typography3', classes)}
              >
                {ruleDefinition.title}
              </Typography>
              <Typography
                {...config.summaryCard.typography3}
                classes={transformClasses('cardSummary_typography4', classes)}
              >
                {ruleDefinition.description}
              </Typography>
              {ruleDefinition.transactionsAmountReductor === undefined
                ? this.renderFields(ruleDefinition)
                : this.renderTransactionsAmountReductor(ruleDefinition)}
              {this.state.showAutocomplete &&
              this.state.autocomplete !== null ? (
                <div style={{ overflow: 'auto', maxHeight: 200 }}>
                  <Collapse in={this.state.showAutocomplete} unmountOnExit>
                    <List>{this.renderAutocomplete()}</List>
                  </Collapse>
                </div>
              ) : (
                <div />
              )}
            </div>
          </Body>

          {(this.props.type === 'retirement' &&
            (!this.props.rulesDefinitions[index].required ||
              this.props.rulesDefinitions[index].identifier ===
                'PERIODIC_SAVING')) ||
          (this.props.type !== 'retirement' &&
            this.props.rulesDefinitions[index].identifier !==
              'PERIODIC_SAVING' &&
            !this.props.rulesDefinitions[index].required) ? (
            <div
              className='footerButtons'
              id={
                'footer_' +
                ruleDefinition.name.replace(/%/gi, '_').replace(/ /gi, '_')
              }
            >
              {' '}
              {/*classes.swipeRules_buttonContainer*/}
              <Button
                variant='contained'
                color='primary'
                classes={transformClasses('swipeRules_button1', classes)}
                onClick={() => {
                  if (
                    this.props.rulesDefinitions[index].identifier ===
                    'PERIODIC_SAVING'
                  ) {
                    this.setRuleMonthlyTransferNotApplicable(
                      ruleDefinition._id,
                      index,
                    );
                    // if (this.props.type === 'retirement') {
                    //   this.setRuleMonthlyTransferNotApplicable(
                    //     ruleDefinition._id,
                    //     index,
                    //   );
                    // } else {
                    //   this.deactivateRule(ruleDefinition._id, index);
                    // }
                  } else {
                    this.deactivateRule(ruleDefinition._id, index);
                  }
                }}
                id='noAplicar'
              >
                No Aplicar
              </Button>
              <Button
                variant='contained'
                color='secondary'
                classes={transformClasses('swipeRules_button2', classes)}
                onClick={e => this.setRule(ruleDefinition._id, index, e)}
                id='aplicar1'
              >
                Aplicar
              </Button>
            </div>
          ) : (
            <div className='footerButtons'>
              <Button
                variant='contained'
                color='secondary'
                classes={transformClasses('swipeRules_button3', classes)}
                onClick={e => this.setRule(ruleDefinition._id, index, e)}
                id='aplicar2'
              >
                Aplicar
              </Button>
            </div>
          )}
        </div>
      ),
    );
    return elements;
  }

  goToNext() {
    this.props.history.push(this.props.next);
  }

  renderAutocomplete() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.state.autocomplete.options.map(element => {
      return (
        <ListItem key={element}>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses(
              'swipeRules_autocomplete_typography1',
              classes,
            )}
            onClick={e => this.handleClikcAutomcomplete(element, e)}
          >
            {element}
          </Typography>
        </ListItem>
      );
    });
    return elements;
  }

  handleChangeTransactionsAmountReductor1(event, ruleDefinition, name, value) {
    const obj = { ...this.state.rules };
    obj[ruleDefinition._id].value.transactionsAmountReductor[
      ruleDefinition.transactionsAmountReductor.type.toLowerCase()
    ] = event;
    this.setState(({ valueGroups1 }) => ({
      valueGroups1: { ...valueGroups1, [name]: value },
      rules: obj,
    }));
  }

  handleChangeTransactionsAmountReductor2(event, ruleDefinition, name, value) {
    const obj = { ...this.state.rules };
    obj[ruleDefinition._id].value.transactionsAmountReductor[
      ruleDefinition.transactionsAmountReductor.type.toLowerCase()
    ] = event;
    this.setState(({ valueGroups2 }) => ({
      valueGroups2: { ...valueGroups2, [name]: value },
      rules: obj,
    }));
  }

  handleChangePickerFields(value, name, ruleDefinition, index) {
    const obj = { ...this.state.rules };
    obj[ruleDefinition._id].value.fieldsValues[index].value = value.name;
    //obj[ruleDefinition._id].value.fieldsValues[index].value2 = value.name;
    this.setState(({ valueGroups3 }) => ({
      valueGroups3: { ...valueGroups3, [name]: value },
      rules: obj,
    }));
  }

  handleChangeFields(event, ruleDefinition, index) {
    const obj = { ...this.state.rules };
    obj[ruleDefinition._id].value.fieldsValues[index].value =
      event.target.value;
    obj[ruleDefinition._id].value.fieldsValues[index].modificado = true;
    console.log(obj[ruleDefinition._id].value.fieldsValues[index].value);
    this.setState({
      rules: obj,
    });
  }

  handleChangeAutocomplete(event, id, index, name) {
    this.getAutocomplete(event.target.value, id, name);

    let rulesCopy = JSON.parse(JSON.stringify(this.state.rules));
    rulesCopy[id].value.fieldsValues[index].value = event.target.value;
    //rulesCopy[id].value.fieldsValues[index].value2 = event.target.value;
    console.log('handleChangeAutocomplete', {
      rules: rulesCopy[id].value.fieldsValues[index],
      //v: rulesCopy[id].value.fieldsValues[index].value2,
    });

    if (event.target.value !== '') {
      this.setState({
        rules: JSON.parse(JSON.stringify(rulesCopy)),
        fieldToAutocomplete: { idField: id, indexField: index },
        showAutocomplete: true,
        valueEquipo: event.target.value,
      });
    } else {
      this.setState({
        rules: JSON.parse(JSON.stringify(rulesCopy)),
        fieldToAutocomplete: null,
        showAutocomplete: false,
        valueEquipo: null,
      });
    }
  }

  handleClikcAutomcomplete(value) {
    const obj = { ...this.state.rules };
    obj[this.state.fieldToAutocomplete.idField].value.fieldsValues[
      this.state.fieldToAutocomplete.indexField
    ].value = value;
    this.setState({
      rules: obj,
      showAutocomplete: false,
      valueEquipo: value,
    });
  }

  render() {
    console.log(this.state);
    const { classes, swipeConfig } = this.props;
    const transformClasses = Utils.transformClasses;

    return this.state.activeRules !== null &&
      this.state.rules !== null &&
      this.state.rulesDefinitions !== null ? (
      <div className='container'>
        <SwipeableViewsConfigurable
          index={this.state.index}
          handleChangeIndex={this.handleChangeIndex}
          config={swipeConfig}
          child={this.renderData()}
          classes={classes}
          slideStyle={{ display: 'flex' }}
        />

        <Modal
          disablePortal
          open={this.state.open}
          classes={transformClasses('swipeRules_modal1', classes)}
        >
          <div className={classes.swipeRules_modalContainer}>
            <Typography variant='body1' color='default'>
              {this.state.errorMessage}
            </Typography>
            <Button
              variant='contained'
              color='secondary'
              onClick={this.handleClose}
            >
              Volver
            </Button>
          </div>
        </Modal>
      </div>
    ) : (
      <div />
    );
  }
}

const config = {
  summaryCard: {
    iconButton: {},
    paper: {
      square: true,
      elevation: 0,
    },
    typography1: {
      variant: 'body1',
      color: 'primary',
    },
    typography2: {
      variant: 'body1',
      align: 'center',
    },
    typography3: {
      variant: 'body1',
      align: 'center',
      color: 'primary',
    },
  },
  lastSummary: {
    typography1: {
      variant: 'body1',
      align: 'center',
    },
    typography2: {
      variant: 'body1',
      align: 'center',
      color: 'secondary',
    },
    typography3: {
      variant: 'body1',
      align: 'center',
    },
    typography4: {
      variant: 'body1',
      align: 'center',
      color: 'secondary',
    },
  },
};

export default SwipeRules;
