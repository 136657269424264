import React, { Component } from 'react'
import SwipeableViews from 'react-swipeable-views'

class SwipeableViewsConfigurable extends Component {
  render() {
    const { config, child } = this.props
    return (
      <SwipeableViews
        {...config}
        index={this.props.index}
        onChangeIndex={(index, indexLatest) => this.props.handleChangeIndex(index, indexLatest)}
        children={child}
        className='container'
      />
    )
  }
}

export default SwipeableViewsConfigurable
