import React, { useEffect, useContext, useState } from 'react';
import { View, Text, Button, Enfatize } from 'coinscrap-webapp-core';
import { StoreContext } from '../../../../pensionStore/Store';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import Flow from '../../../../components/Flow';
import colors from '../../../../config/colors';
import Check from '../../../common/svgs/Check';
import navConfig from '../../config/navigation';
import CurrencyDecimals from '../../../../libs/CurrencyDecimals';
import { calculateRetirementYearlyAverageAmounts } from '@coinscrap/retirement-calculations';

export default props => {
  const context = useContext(StoreContext);
  const [salaryAtRetirement, setSalaryAtRetirement] = useState(null);
  const { state } = context;
  const { simulation, pensionPlan } = state;

  useEffect(() => {
    if (!simulation.started) {
      props.history.push(navConfig.notStarted);
    }
    const salary = calculateRetirementYearlyAverageAmounts(
      simulation.lifeLevelPercentage,
      simulation.typeEmployment,
      simulation.grossIncome,
      simulation.savings,
      simulation.age,
      simulation.contribution,
      0,
      0.02,
      83,
    );

    setSalaryAtRetirement(salary.yearlyAverageSalaryAmount);
  }, []);
  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt>
        <View width85St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Check style={{ color: colors.iconYellow }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              ¡Enhorabuena!
            </Text>
            <Text centerSt>
              Según nuestra simulación necesitarás{' '}
              <Enfatize>
                {CurrencyDecimals(salaryAtRetirement, true, true)}
              </Enfatize>{' '}
              para mantener tu nivel de ingresos actual cuando te jubiles.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() => {
              Flow.navigate(
                '/create/target/retirement/products/selector',
                props,
                { mode: 'retirementSimulation' },
              );

              // props.history.push(routes.retirementSimulationSavingsPerfil)
            }}
          >
            CONTINUAR
          </Button>
        </View>
      </Footer>
    </>
  );
};
