import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MyText from '../../../components/MyText';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';

function TargetPerfilSimulacionInfoUnitLinked(props) {
  console.log('TargetPerfilSimulacionInfoUnitLinked', props);
  //const params = props.location.state
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);

  function ExpandItem(props) {
    return (
      <Fragment>
        <CardActions
          disableSpacing
          onClick={() => props.onClick()}
          style={{
            width: '100%',
            borderTopWidth: 1,
            borderTopStyle: 'dotted',
            borderTopColor: 'black',
            color: 'black',
          }}
        >
          <MyText
            id={props.id}
            style={{
              fontSize: 18,
              fontWeight: 600,
              width: '90%',
              color: 'black',
            }}
          >
            {props.title}
          </MyText>
          <IconButton
            style={{
              width: '10%',
              color: 'black',
            }}
            //aria-expanded={openCollapse1}
            aria-label='show more'
          >
            <ExpandMoreIcon
              style={
                props.show
                  ? {
                      transform: 'rotate(180deg)',
                      transition: 'transform 2s',
                    }
                  : {
                      transform: 'rotate(0deg)',
                      transition: 'transform 2s',
                    }
              }
            />
          </IconButton>
        </CardActions>
        <Collapse
          in={props.show}
          style={{
            width: '100%',
            color: 'black',
            textAlign: 'left',
            fontFamily: 'Calibre',
          }}
        >
          {props.children}
        </Collapse>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header modal={true} title='Unit Linked' history={props.history} />
      <Body vAlign='top'>
        <div
          style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'white',
            padding: 15,
            backgroundColor: '#e9e9e9',
            color: 'black',
            borderRadius: 5,
          }}
        >
          <MyText
            variant='h1'
            style={{
              color: 'black',
            }}
          >
            Beneficios de Unit Linked
          </MyText>

          <ExpandItem
            id='sinBarrerasExpand'
            show={collapse1}
            onClick={() => setCollapse1(!collapse1)}
            title='Sin barreras de entrada'
          >
            <p class='t-space-s'>
              Podrás acceder a un plan personalizado{' '}
              <span class='text-weight-bold ng-binding'>a partir de 50€</span>,
              mientras que con otros vehículos de inversión los mínimos suelen
              ser mucho más elevados.
            </p>
          </ExpandItem>

          <ExpandItem
            id='ventajasExpand'
            show={collapse2}
            onClick={() => setCollapse2(!collapse2)}
            title='Disfruta de ventajas fiscales'
          >
            <ul class='bulleted-list'>
              <li class='bulleted-list__item'>
                <strong>
                  No tienes que tributar hasta que no realices un rescate{' '}
                </strong>
                de efectivo, mientras que con otros vehículos de inversión
                normalmente estarías obligado a notificar a Hacienda (y, en caso
                de generar plusvalías, pagar impuestos) cada vez que se
                introduzca un cambio a la composición de tu cartera al utilizar
                productos financieros distintos de los fondos de inversión.
              </li>
              <li class='bulleted-list__item'>
                En caso de fallecimiento, el saldo acumulado en tu Plan de
                Ahorro{' '}
                <strong>
                  se podrá transferir directamente a tus beneficiarios
                </strong>
                , con el importe adicional que se indica más abajo.
              </li>
              <li class='bulleted-list__item'>
                Esta prestación por fallecimiento tributaría en el Impuesto
                sobre Sucesiones y Donaciones, accediendo así a las{' '}
                <strong>
                  importantes bonificaciones que se regulan en determinadas
                  Comunidades Autónomas
                </strong>
                .
              </li>
              <li class='bulleted-list__item b-space-m'>
                Puedes{' '}
                <strong>designar un beneficiario con total privacidad</strong>,
                ya que no es necesario que el beneficiario conozca o acepte su
                designación.
              </li>
            </ul>{' '}
          </ExpandItem>

          <ExpandItem
            id='costesExpand'
            show={collapse3}
            onClick={() => setCollapse3(!collapse3)}
            title='Reduces los costes de transacción'
          >
            <p class='t-space-s'>
              Nos permite agregar las operaciones de compraventa a un nivel más
              elevado y asimismo{' '}
              <span class='text-weight-bold'>
                mitigar el impacto de los gastos de corretaje sobre tu cartera
              </span>
              , con el resultado, en igualdad de condiciones, de mejorar la
              rentabilidad de tu plan de ahorro.
            </p>
          </ExpandItem>

          <ExpandItem
            id='carteraExpand'
            show={collapse4}
            onClick={() => setCollapse4(!collapse4)}
            title='Consigues una cartera más diversificada'
          >
            <p class='t-space-s'>
              Podemos invertir en productos de inversión como los ETFs sin
              incurrir en costes fiscales al reajustar tu cartera, ofreciéndote
              así una cartera más diversificada al incluir tipos de activos como
              el oro y los bienes raíces (activos inmobiliarios).
            </p>
          </ExpandItem>

          <ExpandItem
            id='seguroExpand'
            show={collapse5}
            onClick={() => setCollapse5(!collapse5)}
            title='Tienes un seguro de vida incluido'
          >
            <p class='t-space-s'>
              En caso de fallecimiento se le abonarán 500€ adicionales al
              beneficiario que hayas designado en concepto de seguro de vida.
            </p>
          </ExpandItem>
        </div>
      </Body>
    </Fragment>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 32,
    width: '100%',
    height: '100%',
  },
  expandDown: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandUp: {
    transform: 'rotate(180deg)',
  },
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(
  TargetPerfilSimulacionInfoUnitLinked,
);
