import React, { Component, Fragment } from 'react';

import Input from '@material-ui/core/Input';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

import { Utils } from '../../src-core/resources/index';
//import { TargetsAPI, EventsAPI, RulesAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';
import { EventsAPI } from '../../src-core/sdk/index';
import { RulesAPI } from '../../src-core/sdk/index';

import Picker from '../../components/picker/picker.component';
import PickerStyles from '../../components/picker/picker.style';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

class AhorroHot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueTarget: '',
      targets: null,
      open: false,
      valueGroups: {
        title: null,
      },
      optionGroups: {
        title: null,
      },
      valueEvent: '',
      ruleDefinitions: null,
      buttonDisabled: true,
      modalOpen: false,
      modalMessage: '',
      modalOnCloseBack: false,
    };
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  componentDidMount() {
    this.targetsUser(this.props.baseUrl, 'me', this.props.token);
  }

  async rulesDef() {
    const targetType = this.state.targets.filter(
      element => element._id === this.state.valueTarget.id,
    );
    const data = await RulesAPI.getRulesDefinitionsByType(
      this.props.baseUrl,
      targetType[0].type,
      this.props.token,
    );
    //Peroconsole.log("AhorroHot.rulesDef", data);
    return data.filter(element => element.identifier === 'PUNTUAL_SAVING')[0];
  }

  async targetsUser(url, user, token) {
    let title = [];
    const response = await TargetsAPI.userTargets(url, user, token);
    if (response.status === 200) {
      const data = await response.json();
      data.forEach(element => {
        title.push({ name: element.name, id: element._id, unit: '' });
      });
      this.setState(
        ({ valueGroups, optionGroups }) => (
          // eslint-disable-next-line
          { valueGroups: { ...valueGroups, title: '' } },
          {
            optionGroups: { ...optionGroups, title: title },
            targets: data,
          }
        ),
      );

      const targetIdParam = this?.props?.match?.params?.id;
      if (targetIdParam) {
        const findTarget = (this?.state?.optionGroups?.title || []).find(
          t => t.id === targetIdParam,
        );
        if (findTarget) {
          this.setState({
            valueTarget: findTarget,
            valueGroups: { ...this.state.valueGroups, title: findTarget },
          });
        }
      }
    } else {
      console.log('error recuperando datos de usuario: ', response.status);
    }
  }

  async doAhorroHot() {
    const rule = await this.rulesDef();
    if (rule !== null) {
      const dataPost = {
        fieldsValues: [
          {
            name: rule.eventDefinition.fields[0].name,
            value: this.state.valueEvent,
          },
        ],
      };
      // PRUEBAS
      //const response = { status: 200 };
      const response = await EventsAPI.createEventForTarget(
        this.props.baseUrl,
        this.state.valueGroups.title.id,
        rule._id,
        dataPost,
        this.props.token,
      );
      if (response.status === 200) {
        //this.props.history.push('/home/resumen/')
        // Mostrar mensaje
        this.setState({
          modalOpen: true,
          modalMessage: 'Perfecto, hemos registrado tu aportación!',
          modalOnCloseBack: true,
        });
      } else {
        this.setState({
          modalOpen: true,
          modalMessage:
            'Lo siento algo a pasado al realizar la aportación, intentalo mas tarde.',
          modalOnCloseBack: false,
        });
      }
    } else {
      this.setState({
        modalOpen: true,
        modalMessage:
          'Lo siento algo a pasado al realizar la aportación, no hay regla definida. Intentalo mas tarde.',
        modalOnCloseBack: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <Fragment>
        {/*classes.ahorroHot_movilScreen*/}
        <Header
          title='Prémiate'
          rigthAction={() => this.props.history.goBack()}
        />

        <Body>
          {/*classes.ahorroHot_header_container*/}
          {/*          
          <SvgIcon
            color='primary'
            classes={transformClasses('ahorroHot_header_leftIcon', classes)}
            onClick={()=>this.props.history.push('/home/resumen')}
          >
            <path d='M 15.41 7.41 L 14 6 l -6 6 l 6 6 l 1.41 -1.41 L 10.83 12 Z' />
            <path d='M0 0h24v24H0z' fill='none' />
          </SvgIcon>
          */}
          {/*
          <SvgIcon
            color='primary'
            classes={transformClasses('ahorroHot_header_rightIcon', classes)}
          >
            <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
          </SvgIcon>
          */}

          {this.state.optionGroups.title !== null ? (
            <div className={classes.ahorroHot_body_container}>
              <SvgIcon
                color='primary'
                classes={transformClasses('ahorroHot_body_icon', classes)}
              >
                <path d='M 6 18 c 0 0.55 0.45 1 1 1 h 1 v 3.5 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 V 19 h 2 v 3.5 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 V 19 h 1 c 0.55 0 1 -0.45 1 -1 V 8 H 6 v 10 Z M 3.5 8 C 2.67 8 2 8.67 2 9.5 v 7 c 0 0.83 0.67 1.5 1.5 1.5 S 5 17.33 5 16.5 v -7 C 5 8.67 4.33 8 3.5 8 Z m 17 0 c -0.83 0 -1.5 0.67 -1.5 1.5 v 7 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 v -7 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 Z m -4.97 -5.84 l 1.3 -1.3 c 0.2 -0.2 0.2 -0.51 0 -0.71 c -0.2 -0.2 -0.51 -0.2 -0.71 0 l -1.48 1.48 C 13.85 1.23 12.95 1 12 1 c -0.96 0 -1.86 0.23 -2.66 0.63 L 7.85 0.15 c -0.2 -0.2 -0.51 -0.2 -0.71 0 c -0.2 0.2 -0.2 0.51 0 0.71 l 1.31 1.31 C 6.97 3.26 6 5.01 6 7 h 12 c 0 -1.99 -0.97 -3.75 -2.47 -4.84 Z M 10 5 H 9 V 4 h 1 v 1 Z m 5 0 h -1 V 4 h 1 v 1 Z' />
              </SvgIcon>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'ahorroHot_body_title_typography',
                  classes,
                )}
              >
                Aportación inmediata
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'ahorroHot_body_subtitle_typography',
                  classes,
                )}
              >
                Ahorro directo
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'ahorroHot_body_info_typography',
                  classes,
                )}
              >
                Selecciona el objetivo y la cantidad que quieres aportar a dicho
                objetivo
              </Typography>
              <div
                className={classes.ahorroHot_body_targetSelector_container}
                onClick={() => this.toggle()}
              >
                <div
                  className={
                    classes.ahorroHot_body_targetSelector_leftIco_container
                  }
                >
                  <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'ahorroHot_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                  >
                    <path d='M 6 18 c 0 0.55 0.45 1 1 1 h 1 v 3.5 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 V 19 h 2 v 3.5 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 V 19 h 1 c 0.55 0 1 -0.45 1 -1 V 8 H 6 v 10 Z M 3.5 8 C 2.67 8 2 8.67 2 9.5 v 7 c 0 0.83 0.67 1.5 1.5 1.5 S 5 17.33 5 16.5 v -7 C 5 8.67 4.33 8 3.5 8 Z m 17 0 c -0.83 0 -1.5 0.67 -1.5 1.5 v 7 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 v -7 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 Z m -4.97 -5.84 l 1.3 -1.3 c 0.2 -0.2 0.2 -0.51 0 -0.71 c -0.2 -0.2 -0.51 -0.2 -0.71 0 l -1.48 1.48 C 13.85 1.23 12.95 1 12 1 c -0.96 0 -1.86 0.23 -2.66 0.63 L 7.85 0.15 c -0.2 -0.2 -0.51 -0.2 -0.71 0 c -0.2 0.2 -0.2 0.51 0 0.71 l 1.31 1.31 C 6.97 3.26 6 5.01 6 7 h 12 c 0 -1.99 -0.97 -3.75 -2.47 -4.84 Z M 10 5 H 9 V 4 h 1 v 1 Z m 5 0 h -1 V 4 h 1 v 1 Z' />
                  </SvgIcon>
                </div>
                <div
                  className={
                    classes.ahorroHot_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.ahorroHot_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'ahorroHot_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      Nombre del objetivo
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.ahorroHot_body_targetSelector_info_secondRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='primary'
                      classes={transformClasses(
                        this.state.valueTarget === ''
                          ? 'ahorroHot_body_targetSelector_info_secondRow_typography1'
                          : 'ahorroHot_body_targetSelector_info_secondRow_typography2',
                        classes,
                      )}
                    >
                      {this.state.valueTarget === ''
                        ? 'Selecciona'
                        : this.state.valueTarget.name}
                    </Typography>
                    <SvgIcon
                      color='primary'
                      classes={transformClasses(
                        'ahorroHot_body_targetSelector_info_secondRow_icon',
                        classes,
                      )}
                    >
                      <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
                      <path d='M0 0h24v24H0z' fill='none' />
                    </SvgIcon>
                  </div>
                </div>
              </div>
              <Collapse in={this.state.open}>
                <div className={classes.ahorroHot_body_collapse_container}>
                  <Picker
                    optionGroups={this.state.optionGroups}
                    valueGroups={this.state.valueGroups}
                    onChange={(name, value) =>
                      this.setState(({ valueGroups }) => ({
                        valueGroups: {
                          ...valueGroups,
                          [name]: value,
                        },
                        valueTarget: value,
                        unit: '',
                      }))
                    }
                    itemHeight={34}
                    height={131}
                    classes={classes}
                  />
                </div>
              </Collapse>
              <div className={classes.ahorroHot_body_targetSelector_container}>
                <div
                  className={
                    classes.ahorroHot_body_targetSelector_leftIco_container
                  }
                >
                  <SvgIcon
                    color='primary'
                    classes={transformClasses(
                      'ahorroHot_body_targetSelector_leftIco_svgIcon',
                      classes,
                    )}
                  >
                    <path d='M 6 18 c 0 0.55 0.45 1 1 1 h 1 v 3.5 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 V 19 h 2 v 3.5 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 V 19 h 1 c 0.55 0 1 -0.45 1 -1 V 8 H 6 v 10 Z M 3.5 8 C 2.67 8 2 8.67 2 9.5 v 7 c 0 0.83 0.67 1.5 1.5 1.5 S 5 17.33 5 16.5 v -7 C 5 8.67 4.33 8 3.5 8 Z m 17 0 c -0.83 0 -1.5 0.67 -1.5 1.5 v 7 c 0 0.83 0.67 1.5 1.5 1.5 s 1.5 -0.67 1.5 -1.5 v -7 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 Z m -4.97 -5.84 l 1.3 -1.3 c 0.2 -0.2 0.2 -0.51 0 -0.71 c -0.2 -0.2 -0.51 -0.2 -0.71 0 l -1.48 1.48 C 13.85 1.23 12.95 1 12 1 c -0.96 0 -1.86 0.23 -2.66 0.63 L 7.85 0.15 c -0.2 -0.2 -0.51 -0.2 -0.71 0 c -0.2 0.2 -0.2 0.51 0 0.71 l 1.31 1.31 C 6.97 3.26 6 5.01 6 7 h 12 c 0 -1.99 -0.97 -3.75 -2.47 -4.84 Z M 10 5 H 9 V 4 h 1 v 1 Z m 5 0 h -1 V 4 h 1 v 1 Z' />
                  </SvgIcon>
                </div>
                <div
                  className={
                    classes.ahorroHot_body_targetSelector_info_container
                  }
                >
                  <div
                    className={
                      classes.ahorroHot_body_targetSelector_info_firstRow
                    }
                  >
                    <Typography
                      variant='body1'
                      color='secondary'
                      classes={transformClasses(
                        'ahorroHot_body_targetSelector_info_firstRow_typography',
                        classes,
                      )}
                    >
                      Importe
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.ahorroHot_body_targetSelector_info_secondRow
                    }
                  >
                    <Input
                      disableUnderline
                      value={this.state.valueEvent}
                      placeholder='Ejemplo 200 €'
                      onChange={e =>
                        this.setState({ valueEvent: e.target.value })
                      }
                      classes={transformClasses(
                        'ahorroHot_body_targetSelector_info_secondRow_input',
                        classes,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          <Modal
            open={this.state.modalOpen}
            onClose={() => this.setState({ modalOpen: false })}
          >
            <div
              style={{
                height: 150,
                backgroundColor: 'white',
                borderRadius: 5,
                padding: 37,
                top: '30%',
                margin: 15,
                marginTop: '50%',
              }}
            >
              <Typography style={{ color: '#353535' }}>
                {this.state.modalMessage}
              </Typography>
              <Button
                style={{
                  borderColor: '#9E9E9E',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  float: 'right',
                  marginTop: 20,
                }}
                onClick={() => {
                  if (this.state.modalOnCloseBack) {
                    this.props.history.goBack();
                  }
                  this.setState({ modalOpen: false });
                }}
              >
                Cerrar
              </Button>
            </div>
          </Modal>
        </Body>

        <Footer>
          <Button
            variant='contained'
            color='secondary'
            classes={transformClasses('ahorroHot_footer_button', classes)}
            onClick={() => {
              this.buttonDisabled = true;
              this.state.valueEvent === '' || this.state.valueTarget === ''
                ? this.props.history.goBack() //push(this.props.urlBack)
                : this.doAhorroHot();
            }}
            disabled={
              this.state.valueEvent === '' ||
              this.state.valueTarget === '' ||
              this.buttonDisabled
                ? true
                : false
            }
          >
            Activar
          </Button>
        </Footer>
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...PickerStyles(theme),
  targetRefundInfo_modal1_root: {
    display: 'flex',
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  ahorroHot_movilScreen: {
    minHeight: '640px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ahorroHot_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
    marginTop: '12px',
  },
  ahorroHot_header_leftIcon_root: {
    height: '17px',
    width: '17px',
  },
  ahorroHot_header_rightIcon_root: {
    height: '19px',
    width: '19px',
  },
  ahorroHot_header_tipography_root: {
    fontSize: '20px',
    lineHeight: '22.5px',
    textAlign: 'center',
    width: '100%',
  },
  ahorroHot_body_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ahorroHot_body_icon_root: {
    height: '27px',
    width: '27px',
    //marginTop: '25px'
  },
  ahorroHot_body_title_typography_root: {
    fontSize: '45px',
    fontWeight: 100,
    lineHeight: '33.4px',
    letterSpacing: '0px',
    textAlign: 'center',
    width: '318px',
    marginTop: 30,
  },
  ahorroHot_body_subtitle_typography_root: {
    fontSize: '11px',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: '14.6px',
  },
  ahorroHot_body_info_typography_root: {
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '0px',
    width: '242px',
    marginTop: '22px',
  },
  ahorroHot_body_targetSelector_container: {
    display: 'flex',
    alignItems: 'center',
    width: '262px',
    borderBottom: 'solid 1px rgba(255,255,255,.3)',
    marginTop: '31.3px',
    textAlign: 'left',
  },
  ahorroHot_body_targetSelector_leftIco_container: {
    width: '24.9px',
    height: '27.5px',
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  ahorroHot_body_targetSelector_leftIco_svgIcon_root: {
    width: '100%',
    height: '100%',
  },
  ahorroHot_body_targetSelector_info_container: {
    width: '237px',
    display: 'flex',
    flexDirection: 'column',
  },
  ahorroHot_body_targetSelector_info_firstRow_typography_root: {
    fontSize: '11px',
    lineHeight: '11.3px',
    letterSpacing: '0px',
    fontWeight: 600,
    marginLeft: '11.5px',
    textAlign: 'left',
  },
  ahorroHot_body_targetSelector_info_secondRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ahorroHot_body_targetSelector_info_secondRow_typography1_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  ahorroHot_body_targetSelector_info_secondRow_typography2_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: theme.palette.primary.main,
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  ahorroHot_body_collapse_container: {
    height: '131px',
    width: '262px',
    backgroundColor: 'rgba(255,255,255,0.098)',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  ahorroHot_body_targetSelector_info_secondRow_input_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: theme.palette.primary.main,
    marginLeft: '11.5px',
    marginBottom: '7.9px',
    '&::placeholder': {
      color: 'rgba(255,255,255,.5)',
    },
  },
  ahorroHot_footer_button_root: {
    width: '312px',
    height: '40px',
    borderRadius: '4px',
    padding: 0,
    marginTop: 'auto',
    marginBottom: '24px',
  },
});

export default withStyles(styles, { withTheme: true })(AhorroHot);
