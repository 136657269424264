import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Flow from '../../components/Flow';
import MyText from '../../components/MyText';
import MyTabs from '../../components/MyTabs';
import ProductFundsDoughnutList from './ProductFundsDoughnutList';
import SwipeableViewsConfigurableStyles from '../swipeableViews/swipeableViewsConfigurable.style';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import { retirementAgressive, retirementConservative, retirementDynamic, retirementFunds } from './ProductFundRetirementData';
import { agresivo, animado, dinamico, moderado, conservador, funds } from './ProductFundData';

/*
  Desglose de la cartera en los distintos fondos
 */
function ProductFunds(props) {
  const params = Flow.getParams();
  let datos = null;

  if (params.productName === 'Agresivo') {
    datos = params.objectiveType === 'retirement' ? retirementAgressive : agresivo;
  } else if (params.productName === 'Animado') {
    datos = animado;
  } else if (params.productName === 'Dinámico') {
    datos = params.objectiveType === 'retirement' ? retirementDynamic : dinamico;
  } else if (params.productName === 'Moderado') {
    datos = moderado;
  } else if (params.productName === 'Conservador') {
    datos = params.objectiveType === 'retirement' ? retirementConservative : conservador;
  }

  const tabArray =
    params.objectiveType === 'retirement'
      ? [
        {
          label: 'Tipo',
          path: '/product/funds/types',
          render: props => (
            <ProductFundsDoughnutList data={datos['tipo']} funds={retirementFunds} tabType={'tipo'} />
          ),
        },
        {
          label: 'Fondos',
          path: '/product/funds/list',
          render: props => (
            <ProductFundsDoughnutList data={datos['fondos']} funds={retirementFunds} history={props.history} location={props.location} tabType={'fondos'} />
          ),
        }
      ] : [
        {
          label: 'Tipo',
          path: '/product/funds/types',
          render: props => (
            <ProductFundsDoughnutList data={datos['tipo']} funds={funds} tabType={'tipo'} />
          ),
        },
        {
          label: 'Región',
          path: '/product/funds/regions',
          render: props => (
            <ProductFundsDoughnutList data={datos['regiones']} funds={funds} tabType={'regiones'} />
          ),
        },
        {
          label: 'Fondos',
          path: '/product/funds/list',
          render: props => (
            <ProductFundsDoughnutList data={datos['fondos']} funds={funds} history={props.history} location={props.location} tabType={'fondos'} />
          ),
        },
      ];

  return (
    <Fragment>
      <Header modal={true} title='Fondos Cartera' history={props.history} />
      <Body vAlign='top'>
        <MyText id='productName' variant='h1'>
          {params.productName}
        </MyText>
        <MyText>
          Así es como hemos configurado la cartera de inversión óptima para tu
          {/* Plan de Inversión Inteligente. */} Hucha Inteligente. Puedes ver
          la composición por tipo, por región o cada fondo individualmente.
        </MyText>

        <MyTabs history={props.history} currentTab={0} tabs={tabArray} />
      </Body>
      <Footer />
    </Fragment>
  );
}

const styles = theme => ({
  //...ProductsStyles(theme),
  ...SwipeableViewsConfigurableStyles(theme),
});

export default withStyles(styles, { withTheme: true })(ProductFunds);
