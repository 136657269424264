function generateStyles(theme) {
  const styles = {
    progressBar_mobileStepper_root: {
      width: '100%',
      padding: 0,
    },
    progressBar_mobileStepper_progress: {
      position: 'absolute',
      bottom: 0,
      height: 35,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
  };

  return styles;
}

export default generateStyles;
