import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistCombineReducers } from 'redux-persist';
import mainReducer from './reducer';
import storage from 'redux-persist/lib/storage';
import { hardSet } from 'redux-persist/lib/stateReconciler/hardSet';

const reducers = {
  main: mainReducer,
};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  whitelist: ['main'],
};
const persistedReducer = persistCombineReducers(persistConfig, reducers);

function configureStore(initialState = {}) {
  const middlewares = [thunkMiddleware];

  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    if ('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }

    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
  }

  return createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
}

export const store = configureStore();
export const persistor = persistStore(store);
