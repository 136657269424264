import PortfolioAgresivo from './ScenariosPortfolioAgresivo';
import PortfolioAnimado from './ScenariosPortfolioAnimado';
import PortfolioDinamico from './ScenariosPortfolioDinamico';
import PortfolioModerado from './ScenariosPortfolioModerado';
import PortfolioConservador from './ScenariosPortfolioConservador';

// Config(e, t, n, i, a, r)
export default class Config {
  e = null;
  t = null;
  n = null;
  i = null;
  a = null;
  r = null;

  CASER_UNIT_LINKED_COSTS = {
    total: 0.87,
    management: {
      total: 0.69,
      finizens: 0.49,
      custodian: 0.2,
    },
    financialProducts: 0.17,
    intermediaries: 0.01,
  };

  CASER_PENSION_PLAN_COSTS = {
    total: 0.85,
    management: {
      total: 0.64,
      finizens: 0.34,
      custodian: 0.3,
    },
    depositary: 0.1,
    financialProducts: 0.1,
    intermediaries: 0.01,
  };

  // Resto planes
  EVO_CASER_UNIT_LINKED_COSTS = {
    total: 1,
    management: {
      total: 0.82,
      finizens: 0.165,
      custodian: 0.2,
      evo: 0.455,
    },
    financialProducts: 0.17,
    intermediaries: 0.01,
  };
  // Caso conservador
  EVO_CASER_UNIT_LINKED_COSTS_CONSERVADOR = {
    total: 0.855,
    management: {
      total: 0.565,
      finizens: 0.065,
      custodian: 0.2,
      evo: 0.3,
    },
    financialProducts: 0.28,
    intermediaries: 0.01,
  };

  INVERSIS_INVESTMENT_COSTS = {
    total: 0.8,
    management: {
      total: 0.42,
      min: 0.15,
    },
    depositary: 0.18,
    financialProducts: 0.2,
    tiers: [
      {
        management: 0.42,
        from: 0,
        to: 19999.99,
      },
      {
        management: 0.39,
        from: 2e4,
        to: 99999.99,
      },
      {
        management: 0.37,
        from: 1e5,
        to: 299999.99,
      },
      {
        management: 0.35,
        from: 3e5,
        to: 499999.99,
      },
      {
        management: 0.3,
        from: 5e5,
        to: 999999.99,
      },
      {
        management: 0.25,
        from: 1e6,
        to: 4999999.99,
      },
      {
        management: 0.15,
        from: 5e6,
        to: 1e8,
      },
    ],
  };

  AHORROBOT_CASER_UNIT_LINKED_COSTS = {
    total: 0.87,
    management: {
      total: 0.69,
      finizens: 0.345,
      caser: 0.345,
    },
    financialProducts: 0.17,
    intermediaries: 0.01,
  };

  ids = {
    ID_CASER_UNIT_LINKED: 'caser_unit_linked',
    ID_AHORROBOT_CASER_UNIT_LINKED: 'ahorrobot_caser_unit_linked',
    ID_CASER_PENSION_PLAN: 'caser_pension_plan',
    ID_EVO_CASER_UNIT_LINKED: 'evo_caser_unit_linked',
    ID_INVERSIS_INVESTMENT: 'inversis_investment',
  };

  static performance = null;

  constructor(productName) {
    let portfolio = null;
    if (productName === 'Agresivo') {
      portfolio = PortfolioAgresivo();
    } else if (productName === 'Animado') {
      portfolio = PortfolioAnimado();
    } else if (productName === 'Dinámico') {
      portfolio = PortfolioDinamico();
    } else if (productName === 'Moderado') {
      portfolio = PortfolioModerado();
    } else if (productName === 'Conservador') {
      portfolio = PortfolioConservador();
    } else if (productName === 'Standard') {
      portfolio = this.getStandardPortfolio();
    } else {
      console.error('ProductName desconocido', productName);
    }

    this.portfolio = portfolio;
    this.productId = portfolio.productId;
    this.performance = portfolio.performance;
    this.historicPerformance = portfolio.historicPerformance;
  }

  // calcula >> s
  //function calcula(e) {
  //  var n = this;
  ofunction(e) {
    return e
      .map(function(e) {
        return e.date.getFullYear();
      })
      .filter(function(e, t, n) {
        return n.indexOf(e) === t;
      });
  }

  init(e) {
    e.forEach(e, function(e, n) {
      (void 0 === e && void 0 !== this.t[n]) || (this.t[n] = e);
    });
  }

  getFullName() {
    return this.name + ' ' + this.title;
  }

  getNetPerformance() {
    var e =
      arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5;
    return this.t.sumAnnualized(
      this.performance,
      -1 * this.getEstimatedCosts(e),
    );
  }

  getManagementFee() {
    var e =
      arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5;
    return this.getCosts(e).management.total;
  }

  getEstimatedCosts() {
    var e =
      arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5;
    return this.getCosts(e).total;
  }

  getCosts() {
    var e =
        arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5,
      t = this.getBaseCosts(e);
    return (
      !0 === t.hasOwnProperty(this.tolerance) && (t = t[this.tolerance]), t
    );
  }

  getBaseCosts() {
    //var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5;

    return this.portfolio.name === 'Conservador'
      ? this.EVO_CASER_UNIT_LINKED_COSTS_CONSERVADOR
      : this.EVO_CASER_UNIT_LINKED_COSTS;

    // return this.productId === this.ids.ID_CASER_PENSION_PLAN
    //   ? this.CASER_PENSION_PLAN_COSTS
    //   : this.productId === this.ids.ID_EVO_CASER_UNIT_LINKED
    //   ? this.EVO_CASER_UNIT_LINKED_COSTS
    //   : this.productId === this.ids.ID_INVERSIS_INVESTMENT
    //   ? this.getInvestmentPlanCosts(e)
    //   : this.productId === this.ids.ID_AHORROBOT_CASER_UNIT_LINKED
    //   ? this.AHORROBOT_CASER_UNIT_LINKED_COSTS
    //   : this.CASER_UNIT_LINKED_COSTS;
  }

  getInvestmentPlanCosts() {
    var e =
        arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5,
      t = this.INVERSIS_INVESTMENT_COSTS.tiers.filter(function(t) {
        return e >= t.from && e < t.to;
      }),
      n = this.INVERSIS_INVESTMENT_COSTS.management.total;
    void 0 !== t[0] && (n = t[0].management);
    var i = this.INVERSIS_INVESTMENT_COSTS.depositary,
      a = this.INVERSIS_INVESTMENT_COSTS.financialProducts;
    return {
      total: n + i + a,
      management: {
        total: n,
        min: this.INVERSIS_INVESTMENT_COSTS.management.min,
      },
      depositary: i,
      financialProducts: a,
      tiers: this.INVERSIS_INVESTMENT_COSTS.tiers,
    };
  }

  getTypicalDeviationForYear(e) {
    // let porfolio = this.getStandardPortfolio();
    // return porfolio.typicalDeviation[e] || porfolio.typicalDeviation.default;
    return this.portfolio.typicalDeviation[e];
  }

  historicValues() {
    return (
      void 0 === this.historicData &&
        (this.historicData = this.i.resolveById(this.id)),
      this.historicData
    );
  }

  applyCosts(e) {
    var n =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1e5;
    var i,
      a,
      o = -1 * this.getEstimatedCosts(n);
    return (
      (i = e),
      (a = this.t.map(
        this.i.map(function(e) {
          return e.value;
        }),
        o,
      )),
      i.map(function(e, t) {
        // return (e.value = a[t]), e;
        e.value = a[t];
        return e;
      })
    );
  }

  getNumYearsInData(e) {
    return this.o(e).length - 1;
  }

  getFirstYearInData(e) {
    return this.o(e)[1];
  }

  getLastYearInData(e) {
    var t = this.o(e);
    return t[t.length - 1];
  }

  averageBankFee() {
    return 4.13;
  }

  toYearly(e) {
    var t = (function(e, t) {
        return e
          .map(function(e) {
            if (t === e.date.getMonth() + 1) return e;
          })
          .filter(Boolean);
      })(e, 12),
      n = t.splice(0, 1)[0];
    return t.map(function(e) {
      var t = 100 * (e.value / n.value - 1),
        i = {
          year: e.date.getFullYear(),
          performance: t,
        };
      // return (n = e), i;
      n = e;
      return i;
    });
  }
  getProductSettings() {
    return new this.a(this.productId);
  }

  mandatoryDepositMonthly() {
    return (
      this.n.getProductSettings().isFinizensSavingsPlan() &&
      (!this.n.contractedAt ||
        this.r(this.n.contractedAt).isSameOrAfter(
          this.a.FINIZENS_SAVINGS_PLAN_DATE_MANDATORY_MONTHLY_DEPOSIT,
          'day',
        ))
    );
  }

  fromKyc(e) {
    return this.n
      .get({
        kyc: e.id,
      })
      .$promise.then(function(e) {
        //return new calcula(e);
      });
  }

  averageInvestorPerformance = function() {
    return 2.5;
  };

  getStandardPortfolio() {
    //return new calcula(

    return {
      performance: 6.3467,
      tolerance: 3,
      typicalDeviation: {
        1: 10.3517,
        2: 7.3738,
        3: 6.0051,
        4: 5.4175,
        5: 4.9906,
        6: 4.5132,
        7: 4.2403,
        8: 4.1238,
        9: 4.0029,
        10: 3.7996,
        11: 3.6927,
        12: 3.5889,
        13: 3.4568,
        14: 3.3429,
        15: 3.2444,
        16: 3.1107,
        17: 2.926,
        18: 2.7568,
        19: 2.6068,
        20: 2.4579,
        21: 2.3349,
        22: 2.2505,
        23: 2.1352,
        24: 1.9808,
        25: 1.8377,
        26: 1.6711,
        27: 1.4698,
        28: 1.2771,
        29: 1.1344,
        30: 0.9367,
        31: 0.7128,
        32: 0.6043,
        33: 0.5081,
        34: 0.291,
        35: 0.2783,
        36: 0.3309,
        37: 0.2689,
        38: 0.2687,
        39: 0.2935,
        40: 0.2715,
        default: 0.2715,
      },
    };
  }
}
