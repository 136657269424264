import {connect} from 'react-redux'
import ConfigureRule from '../views/configureRule/configureRule.component'

function mapStateToProps(state){
    const { token, baseUrl } = state.main
    return {
        token,
        baseUrl
    }
}

const mapDispatchToProps = {
    
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureRule)