import React, { useContext, useEffect, useState } from 'react';
import {
  calculateRetirementYearlyAverageAmounts,
  calculateTotalAmounts,
} from '@coinscrap/retirement-calculations';

import { View, Text, Button } from 'coinscrap-webapp-core';

import Header from '../../../components/MyHeader';
import Footer from '../../../components/MyFooter';
import routes from '../../../config/routes';
import { useSelector } from 'react-redux';

import CajaFuerte from '../../common/svgs/CajaFuerte';
import InfoModal from '../components/InfoModal';
import CurrencyDecimals from '../../../libs/CurrencyDecimals';
import SimulationCalculatonsModal from '../components/SimulationCalculationModal';
import { StoreContext } from '../../../pensionStore/Store';
import * as Types from '../../../pensionStore/types';
import colors from '../../../config/colors';
import navConfig from '../config/navigation';

import MyChartJS from '../components/MyChartJS';

const optionsGraph = {
  rotation: 20,
  cutoutPercentage: 70,
  legend: {
    display: false,
    position: 'left',
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    animateRotate: true,
    animateScale: false,
  },
  plugins: {
    labels: {
      render: 'percentage',

      precision: 2,
      fontColor: ['white', 'white', 'white'],
      position: 'outside',

      textMargin: 4,
    },
  },
};

export default props => {
  const [yearlyAverageAmounts, setYearlyAverageAmounts] = useState(null);
  const [totalAmounts, setTotalAmounts] = useState(null);
  const [typeInfo, setTypeInfo] = useState('average');

  const context = useContext(StoreContext);
  const { state, dispatch } = context;
  const store = useSelector(state => state.main);

  useEffect(() => {
    // if (!state.simulation.started) {
    //   props.history.push(navConfig.notStarted);
    //   return;
    // }

    let findProduct;
    for (const property in store.prodsForUser) {
      if (
        store.prodsForUser[property].name === state.simulation.profileInvestor
      ) {
        findProduct = store.prodsForUser[property];
      }
    }

    const yearlyAmt = calculateRetirementYearlyAverageAmounts(
      state.simulation.lifeLevelPercentage,
      state.simulation.typeEmployment,
      state.simulation.grossIncome,
      state.simulation.savings,
      state.simulation.age,
      state.simulation.contribution,

      0.03,

      0.02,
      83,
    );
    const totalAmt = calculateTotalAmounts(
      state.simulation.lifeLevelPercentage,
      state.simulation.typeEmployment,
      state.simulation.grossIncome,
      state.simulation.savings,
      state.simulation.age,
      state.simulation.contribution,

      0.03,

      0.02,
      83,
    );

    setYearlyAverageAmounts(yearlyAmt);
    setTotalAmounts(totalAmt);
  }, []);

  const dataGraph = (total, saved) => {
    const remain = total - saved;
    console.log(
      yearlyAverageAmounts
        ? +yearlyAverageAmounts.yearlyAveragePublicPensionAmount.toFixed(0)
        : 0,
    );
    const datasets = [
      {
        data: [
          yearlyAverageAmounts
            ? +yearlyAverageAmounts.yearlyAveragePublicPensionAmount.toFixed(0)
            : 0,
          yearlyAverageAmounts
            ? +yearlyAverageAmounts.yearlyAverageInitialContribution.toFixed(0)
            : 0,
          yearlyAverageAmounts
            ? +yearlyAverageAmounts.yearlyAverageRemainingAmount.toFixed(0)
            : 0,
        ],
        backgroundColor: ['#f4137b', '#50e3c2', '#BCE34F'],
        // hoverBorderWidth: 0,
        // weight: 1,
      },
    ];
    return {
      datasets: datasets,
    };
  };

  console.log(totalAmounts);

  const getWidth = number => {
    const maxWidth =
      yearlyAverageAmounts &&
      (yearlyAverageAmounts.yearlyAverageSalaryAmount > 150000
        ? 200000
        : yearlyAverageAmounts.yearlyAverageSalaryAmount > 100000
        ? 150000
        : yearlyAverageAmounts.yearlyAverageSalaryAmount > 75000
        ? 100000
        : 75000);
    let width = (number / maxWidth) * 100;
    width = width.toString() + '%';

    return width;
  };

  const calculatePercentage = number => {
    const percentage = yearlyAverageAmounts
      ? number / yearlyAverageAmounts.yearlyAverageSalaryAmount
      : 0;

    let width = percentage * 100;
    width = width.toString() + '%';

    return width;
  };
  return (
    <>
      <Header
        title='Cálculos de la simulación'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt spaceBetweenSt>
        <View>
          <View width85St bottomSt style={{ marginTop: 20 }}>
            <SimulationCalculatonsModal />
          </View>
          <View componentWrapperSt style={{ marginTop: 20, marginBottom: 20 }}>
            <View width85St topSt startSt>
              <View
                style={{
                  marginBottom: 10,
                  position: 'relative',
                }}
              >
                <MyChartJS
                  type={'doughnut'}
                  data={dataGraph()}
                  options={optionsGraph}
                  style={{ margin: 0 }}
                />
                <View
                  autoWidthSt
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <CajaFuerte
                    style={{ color: 'white', width: '30', height: 'auto' }}
                  />
                </View>
              </View>

              {/* <View
                flexRowSt
                spaceBetweenSt
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <View autoWidthSt startSt flexRowSt>
                  <Text font14St weight400St style={{ marginRight: '10px' }}>
                    ESTIMACIONES A LA JUBILACIÓN
                  </Text>
                  <InfoModal
                    texto='Tenemos en cuenta el ahorro de que dispones actualmente en tu plan
              de pensiones y la rentabilidad que este va a tener durante los
              años que faltan hasta que te jubiles. Calculamos también cuál será
              tu pensión de la seguridad social en ese momento y así sabemos lo
              que deberías ahorrar cada mes para llegar a tu jubilación con el
              mismo nivel de vida que tienes ahora.'
                  />
                </View>
               
              </View> */}
              {/* <View
                style={{ flexDirection: 'row', justifyContent: 'space-around' }}
              >
                <Text
                  style={{
                    width: '100px',
                    textAlign: 'center',
                    borderBottom: `2px solid ${
                      typeInfo === 'total' ? colors.pink : 'transparent'
                    }`,
                    opacity: typeInfo === 'total' ? 1 : 0.5,
                  }}
                  onClick={() => setTypeInfo('total')}
                >
                  Total
                </Text>
                <Text
                  style={{
                    width: '100px',
                    textAlign: 'center',
                    borderBottom: `2px solid ${
                      typeInfo === 'average' ? colors.pink : 'transparent'
                    }`,
                    opacity: typeInfo === 'average' ? 1 : 0.5,
                  }}
                  onClick={() => setTypeInfo('average')}
                >
                  Medio anual
                </Text>
              </View> */}
              <SummaryCard
                Icon={
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: '#f4137b',
                      borderRadius: '50%',
                    }}
                  />
                }
                label='Seguridad Social'
                label2='Mi pensión'
                input={CurrencyDecimals(
                  yearlyAverageAmounts
                    ? yearlyAverageAmounts.yearlyAveragePublicPensionAmount.toFixed(
                        0,
                      )
                    : 0,
                  true,
                  true,
                )}
              />
              <SummaryCard
                Icon={
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: '#50e3c2',
                      borderRadius: '50%',
                    }}
                  />
                }
                label='Aportación que tengo para planes de pensiones'
                input={CurrencyDecimals(
                  yearlyAverageAmounts
                    ? yearlyAverageAmounts.yearlyAverageInitialContribution.toFixed(
                        0,
                      )
                    : 0,
                  true,
                  true,
                )}
              />
              <SummaryCard
                Icon={
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: '#BCE34F',
                      borderRadius: '50%',
                    }}
                  />
                }
                label='Aportación necesaria para mantener mi nivel de ingresos'
                input={CurrencyDecimals(
                  yearlyAverageAmounts
                    ? yearlyAverageAmounts.yearlyAverageRemainingAmount.toFixed(
                        0,
                      )
                    : 0,
                  true,
                  true,
                )}
              />
              {/* <SummaryCard
                Icon={
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: '#5E58FD',
                      borderRadius: '50%',
                    }}
                  />
                }
                label='Aportación inicial'
                input={CurrencyDecimals(
                  yearlyAverageAmounts
                    ? typeInfo === 'average'
                      ? yearlyAverageAmounts.yearlyAverageInitialContribution.toFixed(
                          0,
                        )
                      : totalAmounts.initialContributionAtRetirement.toFixed(0)
                    : 0,
                  true,
                  true,
                )}
              /> */}

              <View style={{ marginTop: 15 }}>
                <Text font14St boldSt textJustifySt>
                  ¿Cómo calculamos la cantidad mensual que necesitas ahorrar
                  para mantener tu nivel de ingresos cuando te jubiles?
                </Text>
                <Text font14St style={{ marginTop: 15 }} textJustifySt>
                  Obtenemos el ahorro en tu plan de pensiones y asumimos una
                  revalorización de un 3% anual, de la misma manera,
                  revalorizamos los ingresos que tienes un 2% anual hasta tu
                  jubilación para saber lo que te corresponderá de pensión de la
                  Seguridad Social ( los datos que utilizamos son hipotéticos y
                  aproximados a la realizad pero no tiene por qué suceder así ).
                  Teniendo estos dos datos, podremos aproximar la diferencia
                  hasta llegar a tu nivel de vida actual y por tanto, cuánto
                  tienes que ahorrar cada mes.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() =>
              props.history.push(routes.retirementSimulationResults)
            }
          >
            FINALIZAR SIMULACIÓN
          </Button>
        </View>
      </Footer>
    </>
  );
};

const SummaryCard = ({ Icon, label, label2, input }) => {
  return (
    <View summaryCardSt style={{ backgroundColor: '#424242', height: 60 }}>
      <View flexRowSt spaceBetweenSt>
        <View autoWidthSt flexRowSt>
          <View style={{ width: '30px' }}>{Icon}</View>
          <View autoWidthSt>
            <Text
              font16St
              style={{
                marginLeft: 8,
                width: 200,
              }}
            >
              {label}
            </Text>
            {label2 && (
              <Text
                font16St
                style={{
                  marginLeft: 8,
                  width: 200,
                }}
              >
                {label2}
              </Text>
            )}
          </View>
        </View>
        <Text font16St weight500St>
          {input}
        </Text>
      </View>
    </View>
  );
};
const CoverageCard = ({ Icon, label, input }) => {
  return (
    <View
      summaryCardSt
      spaceBetweenSt
      flexRowSt
      style={{ backgroundColor: '#424242', height: 64 }}
    >
      <View autoWidthSt flexRowSt>
        <Text
          font16St
          style={{
            marginLeft: 8,
            fontWeight: 600,
          }}
        >
          {label}
        </Text>
      </View>
      <Text font24St>{input}</Text>
    </View>
  );
};
