function generateStyles(theme){
    const styles = {
        listRuleMovements_divListContainer:{
            height: '55vh',
            minHeight: 312,
            overflow: 'auto',
            //background: theme.palette.primary.main
        },
        listRuleMovements_typography1_root: {
            fontSize: 12
        },
        listRuleMovements_typography2_root: {
            fontSize: 12
        },
        listRuleMovements_typography4_root: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8
        },
        listRuleMovements_iconButton1_root:{
            padding: 'unset'
        }
    }
    return styles
}

export {
    generateStyles as ListRuleMovementsStyles
}