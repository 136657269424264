//import { isDate } from 'util';
//import { Finance } from 'financejs';

const chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  blanco: '#ffffffba',
  blancoClaro: '#ffffff4a',
};

export default function getGraphData(caserData, dateFrom, graphType) {
  let debug = true;

  let contributed = null;
  let contributedArray = [];
  //let participations = 0;
  //let liquidValue = 0;
  let oper = '';
  let labels = [];

  /*
   Calcula los datos
   */

  let dataTmp = [];
  let started = false;
  let profitability = 0;
  let variationPending = 0;
  let pendingDay = 0;

  caserData.map(el => {
    //let r = null;
    let consolidedDate = new Date(el.consolidedDate);

    /*
      Calcula los datos
      
      Hay movimientos antes del primer registro de aportación de type='AL'
      pero tienen valores 0, por que aun no fueron efectivos.

      El primer mov tipo AL, no coincide con la aportInicial, porque le resta el coste
      El calculo rentabilidad total, incluyendo costes, así q 
      aportación inicia es initialContributionAmmount
      y no el movementAmmount de reg tipo = AL
     */
    if (contributed === null) {
      contributed = el.initialContributionAmmount;
      contributedArray.push(contributed);
    }

    // Aportacion inicial
    if (el.type === 'AL') {
      started = true;
    }
    oper = getOperacion(el.type);
    if (oper === 'consolidado') {
      /*
      Suma un dia a la fecha de la operación, por que en las imagenes del valor del
      la operación afecta al día siguiente.
      */

      if (variationPending !== 0 && pendingDay !== consolidedDate.getDate()) {
        console.log('variationPending', variationPending);
        contributed += variationPending;
        variationPending = 0;
        pendingDay = 0;
      }

      profitability =
        ((el.consolidedAmmount - contributed) * 100) / contributed;
      console.log({
        consDate: consolidedDate.getMonth() + '/' + consolidedDate.getDate(),
        totAportado: contributed,
        valorActual: el.consolidedAmmount,
        dif: el.consolidedAmmount - contributed,
        renta: profitability,
      });

      if (started) {
        dataTmp.push({
          date: consolidedDate,
          amount: el.consolidedAmmount - contributed,
          percent: profitability,
        });
      }
    } else if (oper === 'suma') {
      //participations = el.participations;
      //liquidValue = el.liquidValue;
      //contributed += el.movementAmmount;
      variationPending = el.movementAmmount;
      contributedArray.push(el.movementAmmount);
    } else if (oper === 'resta') {
      //participations = el.participations;
      //liquidValue = el.liquidValue;
      //contributed -= el.movementAmmount;
      variationPending = el.movementAmmount * -1;
      contributedArray.push(el.movementAmmount * -1);
    }

    if (oper !== 'consolidado') {
      let operationDate = new Date(el.operationDate);

      pendingDay = operationDate.getDate();

      debug &&
        console.log({
          operDay: operationDate.getMonth() + '/' + operationDate.getDate(),
          type: el.type,
          oper: oper,
          importe: el.movementAmmount,
          totAportado: contributed,
        });
    }
    return null;
  });
  console.log('calculado', dataTmp);

  /*
   Filtra los datos por fecha.
   No se puede filtran antes, porque necesita recorrer desde el principio por el acumulado
   */
  if (dateFrom && dateFrom instanceof Date) {
    dataTmp = dataTmp.filter(e => {
      return e.date.getTime() >= dateFrom.getTime();
    });
    debug && console.log('filtrado', dateFrom, dataTmp);
  }

  let data = [];
  dataTmp.map(el => {
    labels.push(
      el.date.getFullYear() +
        '/' +
        el.date.getMonth() +
        '/' +
        el.date.getDate(),
    );

    if (graphType === 'percent') {
      data.push(el.percent);
    } else if (graphType === 'amount') {
      data.push(el.amount);
    } else {
      console.error('getGraphData graphType desconocido', graphType);
    }
    return null;
  });

  /*
   Monta la estructura para la gráfica
  */
  let dataGraph = { datasets: [], labels: labels };
  dataGraph.datasets.push({
    data: data,
    type: 'line',
    borderColor: chartColors.blanco,

    pointRadius: 0,
    pointHoverRadius: 0,
    hiddenLegend: true,
    //showLine: true,
  });

  // contributedArray = [-500000, 200000, 300000, 200000];
  // console.log('getGraphData data', contributedArray);
  // let finance = new Finance();
  // let percent = finance.IRR(...contributedArray);

  debug &&
    console.log('getGraphData data', { dataGraph, profitability, contributed });

  return [dataGraph, profitability, contributed];
}

function getOperacion(type) {
  let r = null;
  switch (type) {
    case 'AE':
    case 'AR':
    case 'AP':
    case 'AT':
    case 'AU':
      r = 'suma';
      break;

    case 'EI':
    case 'EE':
    case 'ER':
    case 'RP':
    case 'RT':
    case 'EU':
    case 'JP':
      r = 'resta';
      break;

    case 'CP':
    case 'CT':
    case 'CI':
    case 'CE':
    case 'XP':
    case 'XT':
    case 'XI':
    case 'XE':
    case 'CU':
    case 'XU':
    case 'AL': // La aportación inicial ya la cogemos al principio, y no se suma de nuevo
      r = 'pasar';
      break;

    case undefined:
      r = 'consolidado';
      break;

    default:
      console.error('getOperacion Tipo no controlado: ', type);
  }

  return r;
}

/*
Código Denominación
AL Alta
AE Aportación extraordinaria
AR Aportación regular

EI Devolución Aportación inicial/única
ED Anulación aportación extraordinaria
ER Anulación Aportación regular
RT Rescate total
RP Rescate parcial
VC Vaciado
AT Anulación rescate total
AP Anulación rescate parcial
AN Anulación

VT Vencimiento

SD Suplemento de datos
SR Suplemento de redistribución
FA Renovación – Prima de riesgo

JP Ajuste de participaciones 
(No viene en la doc, pero lo saco Fran de info de los servicios)
*/
