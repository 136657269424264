import React, { Component, Fragment } from 'react';

// import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { withStyles } from '@material-ui/core/styles';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';

import Flow from '../../components/Flow';
import ProgressBarStyles from '../../components/progressBar.style';
import MyChartJS, {
  transformDataFromService,
  chartColors,
} from '../../components/MyChartJS';
//import ScenariosSimulator from '../Product/Simulator/ScenariosSimulator';

class FollowupGraphProgress extends Component {
  constructor(props) {
    super(props);

    console.log('FollowupGraphProgress.constr', this.props, this.state);
  }

  // componentDidMount() {
  //   this.getChartInfo();
  // }

  getChartInfo__() {
    const flowParams = Flow.getParams(this.props);

    TargetsAPI.getChartData(
      flowParams.baseUrl,
      this.props.targetID,
      flowParams.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //let options;
        [data] = transformDataFromService(data[0]);

        data.datasets[0].backgroundColor = chartColors.white;
        data.datasets[0].pointRadius = 0;
        data.datasets[0].pointStyle = 'circle';

        // No permite type del dataset tipo 'area' q viene del servicio
        data.datasets[1].type = 'line';
        data.datasets[1].backgroundColor = chartColors.white;
        data.datasets[1].pointRadius = 10;
        data.datasets[1].pointStyle = 'rectRot';

        data.datasets[2].type = 'line';
        data.datasets[2].backgroundColor = chartColors.trasparentWhite;
        data.datasets[2].pointRadius = 0;
        data.datasets[2].pointStyle = 'circle';

        this.setState({
          data: data,
        });
      })
      .catch(error => {
        console.log('error consiguiendo el target', error);
      });
  }

  render() {
    //const { classes /*, targetID*/ } = this.props;

    console.log('followGraphProgress.render', this.props, this.state);

    let data = {};
    //let options = {};
    if (this.props.data) {
      [data] = transformDataFromService(this.props.data);

      data.datasets[0].backgroundColor = chartColors.white;
      data.datasets[0].pointRadius = 0;
      data.datasets[0].pointStyle = 'circle';

      // No permite type del dataset tipo 'area' q viene del servicio
      data.datasets[1].type = 'line';
      data.datasets[1].backgroundColor = chartColors.trasparentPink;
      data.datasets[1].pointRadius = 10;
      data.datasets[1].pointStyle = 'rectRot';

      try {
        data.datasets[2].type = 'line';
        data.datasets[2].backgroundColor = chartColors.trasparentWhite;
        data.datasets[2].pointRadius = 0;
        data.datasets[2].pointStyle = 'circle';
      } catch (e) {}

      //const today = new Date();
      console.log('Progress 1', JSON.parse(JSON.stringify(data)));
      let newLabels = [];
      data.labels.map(el => {
        const date = new Date(el);
        //console.log(el, date);
        newLabels.push(
          date.getDate() +
            '/' +
            (date.getMonth() ? date.getMonth() : 1) +
            '/' +
            String(date.getFullYear()).substring(2, 4),
        );
        return null;
      });
      data.labels = newLabels;
    }

    return (
      <Fragment>
        <MyChartJS
          type='line'
          data={data}
          options={{
            legend: {
              labels: {
                usePointStyle: true,
                fontColor: 'white',
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: 'white',
                    //fontSize: 4,
                    autoSkip: true,
                    maxTicksLimit: 5,
                    stepSize: 0.2,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    callback: function(label, index, labels) {
                      return label + ' €';
                    },

                    fontColor: 'white',
                    //fontSize: 18,
                  },

                  scaleLabel: {
                    display: false,
                    //labelString: this.props.type == 'percent' ? '%' : '€',
                  },
                },
              ],
            },
          }}
          height={150}
        />
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...ProgressBarStyles(theme),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 12,
    transition: 0.2,
    zIndex: 99999,
    backgroundColor: 'transparent',
  },
  followup_container: {
    //height: '100%',
    height: '100vh',
    display: 'flex',
    //justifyContent: 'space-evenly',
    flexFlow: 'column',
  },
  followup_movilScreen: {
    maxWidth: 400,
    margin: 'auto',
    maxHeight: 'inherit',
  },
  followup_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  followup_imgDiv: {
    position: 'relative',
    maxWidth: '100%',
    height: '25vh',
    minHeight: 142,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  progressBar_mobileStepper_progress: {
    /*position: 'absolute',
    bottom: 0,*/
    height: 10,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  listTargetMovements_divListContainer: {
    backgroundColor: 'none',
    flex: 1,
  },
  listTargetMovementsDetails_divListContainer: {
    backgroundColor: 'none',
  },
  /*
  followup_imgDiv_overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
*/

  chart: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  /*
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //marginTop: '100px',
    height: '100%',
    width: '100%',
    padding: 20,
  },
  */
  headersContainer: {
    //height: '15vh',
    //minHeight: 85,
    width: '100%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  followup_header_iconButton1_root: {
    padding: 'unset',
  },
  followup_header_iconButton2_root: {
    padding: 'unset',
  },
  followup_targetInfoContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },
  followup_targetName_root: {
    fontSize: 45,
    fontWeight: 200,
    textTransform: 'lowercase',
    lineHeight: '30px',
    paddingTop: 15,
  },
  followup_percentage_root: {
    fontSize: 25,
    fontWeight: 300,
    textTransform: 'lowercase',
    lineHeight: '30px',
  },
  followup_progress_root: {
    fontSize: 20,
    fontWeight: 300,
    textTransform: 'lowercase',
    lineHeight: '12px',
  },
  targetNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
  },
});

export default withStyles(styles, { withTheme: true })(FollowupGraphProgress);
