import React, { useState, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import Currency from '../../../components/Currency';

import MyText from '../../../components/MyText';
import Flow from '../../../components/Flow';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import Footer from '../../../components/MyFooter';
import Icon from '../../../components/MyIcon';

function ProductSimulatorExplanation(props) {
  console.log('TargetPerfilSimulacionResumen', props);
  const params = Flow.getParams();

  const [openModalInfoRentabilidad, setOpenModalInfoRentabilidad] = useState(
    false,
  );

  // Modal Como -------------------------------------------------------------

  function ModalInfoRentabilidad(props) {
    return (
      <Modal
        open={openModalInfoRentabilidad}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setOpenModalInfoRentabilidad(false)}
      >
        <div
          style={{
            width: '312px',
            backgroundColor: '#e9e9e9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '14px',
            borderRadius: '8px',
            '&:focus': {
              outline: 'unset',
            },
            fontFamily: 'Calibre',
          }}
        >
          Cálculos para la cartera {params.productName}. El rango de las
          proyecciones está construido utilizando volatilidades de carteras
          formadas con índices históricos a largo plazo. Fuente: Morningstar
          Direct 01/01/2000 a 31/12/2018 y 01/01/1973 a 31/12/2018.
        </div>
      </Modal>
    );
  }

  return (
    <Fragment>
      <Header
        modal={true}
        title='Simulación Inversión'
        history={props.history}
      />
      <Body vAlign='top'>
        <MyText>
          Para que puedas visualizar con total transparencia el alcance de tu
          inversión, en el <strong>Plan {params.productName}</strong> aquí te
          presentamos el desglose para el escenario:
        </MyText>
        <MyText
          style={{
            fontSize: 22,
            fontWeight: 600,
            //color: 'green',
            marginTop: 5,
          }}
        >
          {params.datosEscenario.scenarioName}
        </MyText>
        <Item
          id='infoAportacion'
          label='Aportación'
          icon={<Icon name='manoBillete' />}
        >
          <MyText>Al final de los {params.años} años esperados</MyText>
          <MyText variant='bold'>{Currency(params.aportado)}</MyText>
        </Item>

        <Item
          id='infoRentabilidad'
          label='Rentabilidad'
          icon={<Icon name='sacoMonedasFlechaArriba' />}
          onClickInfo={() => setOpenModalInfoRentabilidad(true)}
        >
          <MyText>
            Retorno de entre{' '}
            {Currency(params.datosEscenario.porcDesde, 2, true)} % y el{' '}
            {Currency(params.datosEscenario.porcHasta, 2, true)} % bruto
          </MyText>
          <MyText variant='bold'>
            {Currency(
              params.datosEscenario.finalDesde -
                params.aportado +
                params.datosEscenario.costesTotalesDesde,
              -2,
            )}
            {' y '}
            {Currency(
              // params.datosEscenario.finalHasta / 100).toFixed(0) * 100 -
              params.datosEscenario.finalHasta -
                params.aportado +
                params.datosEscenario.costesTotalesHasta,
              -2,
            )}
          </MyText>
        </Item>
        <ModalInfoRentabilidad />

        <Item
          id='infoCostes'
          label='Costes'
          icon={<Icon name='euroFlechaAbajo' />}
          onClickInfo={() =>
            Flow.navigate('/product/simulator/costs', props, {
              ...params,
            })
          }
        >
          <MyText>Muy por debajo de la media del mercado.</MyText>
          <MyText variant='bold'>
            Entre - {Currency(params.datosEscenario.costesTotalesDesde, -2)} y -{' '}
            {Currency(params.datosEscenario.costesTotalesHasta, -2)}
          </MyText>
        </Item>

        <Item
          id='infoSeguro'
          label='Seguro'
          icon={<Icon name='cerraduraPuerta' />}
          onClickInfo={() =>
            Flow.navigate('/product/simulator/insurance', props, {
              ...params,
            })
          }
        >
          <MyText>
            Un promedio de {Currency(params.datosEscenario.costeSeguroAnual, 2)}{' '}
            al año.
          </MyText>
          <MyText variant='bold'>
            - {Currency(params.datosEscenario.costeSeguroTotal, 0)}
          </MyText>
        </Item>

        <div>
          <MyText variant='item'>PATRIMONIO TOTAL</MyText>
          <MyText>
            En conjunto, este sería el valor total aproximado de tu inversión
            entre
          </MyText>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <MyText style={{ fontSize: 35 }}>
              {Currency(params.datosEscenario.finalDesde, -2)}
            </MyText>
            <MyText style={{ padding: 10 }}>y</MyText>
            <MyText style={{ fontSize: 35 }}>
              {Currency(params.datosEscenario.finalHasta, -2)}
            </MyText>
          </div>
        </div>

        <MyText style={{ marginTop: 0, fontSize: 10 }}>
          Nota importante: rentabilidades pasadas no garantizan rentabilidades
          futuras.
          <br />
          El valor del rescate de la inversión podrá ser con rentabilidad nula e
          incluso negativa. Capital y rentabilidad no garantizados.
        </MyText>
      </Body>
      <Footer>
        <Button
          variant='contained'
          color='secondary'
          style={{
            marginTop: 15,
          }}
          onClick={() => props.history.goBack()}
        >
          Volver a la simulación
        </Button>
      </Footer>
    </Fragment>
  );
}

function Item(props) {
  //const classes = props.classes

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderBottom: 'solid 1px rgba(255,255,255,.3)',
        marginBottom: '18px',
        textAlign: 'left',
        paddingBottom: 10,
      }}
    >
      <div
        style={{
          width: 45,
          height: 45,
          display: 'flex',
          padding: 0,
          margin: 0,
        }}
      >
        {props.icon}
      </div>
      <div
        style={{
          width: 'calc(100% - 45px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='body1'
            color='secondary'
            style={{
              fontSize: 12,
              textAlign: 'left',
              //lineHeight: 11.3,
              fontWeight: 600,
              //marginLeft: 11.5,
              letterSpacing: 0,
              textTransform: 'uppercase',
            }}
          >
            {props.label}
          </Typography>
        </div>
        <div
          style={{
            //width: 'calc(100% - 60px)',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          {props.children}
          {props.onClickInfo && (
            <Icon
              id={props.id}
              name='infoCirculo'
              style={{
                float: 'right',
                marginTop: -26,
                alignSelf: 'flex-end',
                width: 25,
                height: 25,
              }}
              onClick={() => props.onClickInfo(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 32,
    width: '100%',
    height: '100%',
  },
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(
  ProductSimulatorExplanation,
);
