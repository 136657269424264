import React, { Component } from 'react';
import { List } from '../../../ui/index';
// import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { targetRulesList_strings } from './text';
import Typography from '@material-ui/core/Typography';
// import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import { Utils } from '../../../resources/index';

class TargetRulesList extends Component {
  constructor(props) {
    super(props);
    const array = Array.apply(false, Array(this.props.rules.lenght)).map(
      function() {
        return null;
      },
    );
    this.state = {
      toggle: array,
      areActiveRules: this.props.areActiveRules,
    };
    console.log('TargetRulesList');
  }

  toggle(position, event) {
    const array = this.state.toggle;
    array.map((element, index) => {
      if (position !== index) {
        array[index] = false;
      }
      return null;
    });
    array[position] = !array[position];
    this.setState({ toggle: array });
  }

  renderRules() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const rules = this.props.rules.map((rule, index) => {
      if (this.props.areActiveRules === rule.isActive) {
        return (
          <div key={rule._id} className={classes.targetRulesList_listContainer}>
            <ListItem
              {...config.listItem}
              onClick={e => this.toggle(index, e)}
              classes={transformClasses('targetRulesList_listItem1', classes)}
            >
              <div className={classes.targetRulesList_divText}>
                <Typography
                  {...config.typography1}
                  classes={transformClasses(
                    'targetRulesList_typography1',
                    classes,
                  )}
                >
                  {rule.ruleDefinition.name}
                </Typography>
                {/*
                <KeyboardArrowDown
                  {...config.iconButton1}
                  color={this.state.toggle[index] ? 'secondary' : 'primary'}
                  classes={transformClasses('targetRulesList_iconButton1', classes)}
                />
                */}
                <Typography
                  {...config.typography2}
                  classes={transformClasses(
                    'targetRulesList_typography2',
                    classes,
                  )}
                >
                  {Utils.formatCurrency(rule.ruleAmounts.total)}
                </Typography>
                {rule.isActive ? (
                  rule.ruleDefinition.eventDefinition === null ? (
                    <div className={classes.targetRulesList_cardActionsDiv}>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes,
                        )}
                        onClick={() => this.props.movements(rule._id)}
                      >
                        {
                          targetRulesList_strings['es']
                            .targetRulesList_button_text1
                        }
                      </Button>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes,
                        )}
                        onClick={() => this.props.configure(rule._id)}
                      >
                        {
                          targetRulesList_strings['es']
                            .targetRulesList_button_text2
                        }
                      </Button>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes,
                        )}
                        className={rule.ruleDefinition.required ? 'hide' : ''}
                        onClick={() => this.props.deactivate(rule._id, index)}
                      >
                        {
                          targetRulesList_strings['es']
                            .targetRulesList_button_text3
                        }
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.targetRulesList_cardActionsDiv}>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes,
                        )}
                        onClick={() => this.props.movements(rule._id)}
                      >
                        {
                          targetRulesList_strings['es']
                            .targetRulesList_button_text1
                        }
                      </Button>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes,
                        )}
                        className={rule.ruleDefinition.required ? 'hide' : ''}
                        onClick={() => this.props.deactivate(rule._id, index)}
                      >
                        {
                          targetRulesList_strings['es']
                            .targetRulesList_button_text3
                        }
                      </Button>
                    </div>
                  )
                ) : (
                  <div className={classes.targetRulesList_cardActionsDiv}>
                    <Button
                      {...config.button}
                      classes={transformClasses(
                        'targetRulesList_button2',
                        classes,
                      )}
                      onClick={() => this.props.movements(rule._id)}
                    >
                      {
                        targetRulesList_strings['es']
                          .targetRulesList_button_text1
                      }
                    </Button>
                    <Button
                      {...config.button}
                      classes={transformClasses(
                        'targetRulesList_button2',
                        classes,
                      )}
                      onClick={() => this.props.activate(rule._id, index)}
                    >
                      {
                        targetRulesList_strings['es']
                          .targetRulesList_button_text4
                      }
                    </Button>
                  </div>
                )}
              </div>
            </ListItem>
          </div>
        );
      }
      return null;
    });

    return rules;
  }

  /*
  renderRules() {
    const { classes, config } = this.props
    const transformClasses = Utils.transformClasses
    const rules = this.props.rules.map((rule, index) => {
      return (
        <div key={rule._id} className={classes.targetRulesList_listContainer}>
          <ListItem
            {...config.listItem}
            onClick={e => this.toggle(index, e)}
            classes={transformClasses('targetRulesList_listItem1', classes)}
          >
            <div
              className={classes.targetRulesList_imgDiv}
              style={
                rule.isActive
                  ? {
                      backgroundImage: 'url(' + rule.ruleDefinition.image + ')'
                    }
                  : {
                      backgroundImage:
                        'url(' + rule.ruleDefinition.disabledImage + ')'
                    }
              }
            >
              <div className={classes.targetRulesList_divText}>
                <Typography
                  {...config.typography1}
                  classes={transformClasses(
                    'targetRulesList_typography1',
                    classes
                  )}
                >
                  {rule.ruleDefinition.name}
                </Typography>
                <KeyboardArrowDown
                  {...config.iconButton1}
                  color={this.state.toggle[index] ? 'secondary' : 'primary'}
                  classes={transformClasses(
                    'targetRulesList_iconButton1',
                    classes
                  )}
                />
              </div>
            </div>
          </ListItem>
          <Collapse {...config.collapse} in={this.state.toggle[index]}>
            <Card
              {...config.card1}
              classes={transformClasses('targetRulesList_card1', classes)}
            >
              <CardContent
                {...config.cardContent1}
                classes={transformClasses(
                  'targetRulesList_cardContent1',
                  classes
                )}
              >
                <Typography
                  {...config.typography2}
                  classes={transformClasses(
                    'targetRulesList_typography2',
                    classes
                  )}
                >
                  {'Total generados: ' + Utils.formatCurrency(rule.ruleAmounts.total)}
                </Typography>
              </CardContent>
              <CardActions
                {...config.cardActions1}
                classes={transformClasses(
                  'targetRulesList_cardActions1',
                  classes
                )}
              >
                {rule.isActive ? (
                  rule.ruleDefinition.eventDefinition === null ? (
                    <div className={classes.targetRulesList_cardActionsDiv}>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes
                        )}
                        onClick={() => this.props.movements(rule._id)}
                      >
                        {targetRulesList_strings['es'].targetRulesList_button_text1}
                      </Button>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes
                        )}
                        onClick={() => this.props.configure(rule._id)}
                      >
                        {targetRulesList_strings['es'].targetRulesList_button_text2}
                      </Button>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes
                        )}
                        className={rule.ruleDefinition.required ? 'hide' : ''}
                        onClick={() => this.props.deactivate(rule._id, index)}
                      >
                        {targetRulesList_strings['es'].targetRulesList_button_text3}
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.targetRulesList_cardActionsDiv}>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes
                        )}
                        onClick={() => this.props.movements(rule._id)}
                      >
                        {targetRulesList_strings['es'].targetRulesList_button_text1}
                      </Button>
                      <Button
                        {...config.button}
                        classes={transformClasses(
                          'targetRulesList_button2',
                          classes
                        )}
                        className={rule.ruleDefinition.required ? 'hide' : ''}
                        onClick={() => this.props.deactivate(rule._id, index)}
                      >
                        {targetRulesList_strings['es'].targetRulesList_button_text3}
                      </Button>
                    </div>
                  )
                ) : (
                  <div className={classes.targetRulesList_cardActionsDiv}>
                    <Button
                      {...config.button}
                      classes={transformClasses(
                        'targetRulesList_button2',
                        classes
                      )}
                      onClick={() => this.props.movements(rule._id)}
                    >
                      {targetRulesList_strings['es'].targetRulesList_button_text1}
                    </Button>
                    <Button
                      {...config.button}
                      classes={transformClasses(
                        'targetRulesList_button2',
                        classes
                      )}
                      onClick={() => this.props.activate(rule._id, index)}
                    >
                      {targetRulesList_strings['es'].targetRulesList_button_text4}
                    </Button>
                  </div>
                )}
              </CardActions>
            </Card>
          </Collapse>
        </div>
      )
    })
    return rules
  }
*/

  render() {
    const { classes, listConfig } = this.props;
    const config = {
      ...listConfig,
      children: this.renderRules(),
    };
    return <List config={config} classes={classes} />;
  }
}

export default TargetRulesList;
