import React, { Component } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { Utils } from '../../src-core/resources/index';
//import { TargetsAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Accounts from '../accounts/accounts.component';
import AccountsConfig from '../accounts/accounts.config';
import AccountsStyles from '../accounts/accounts.style';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';
import Flow from '../../components/Flow';

class CreateTargetAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBanks: [],
      accounts: null,
      tab: 0,
      open: false,
      bankToggle: null,
      showBanksRedondeos: null,
      showBanksCargos: null,
    };
  }

  componentDidMount() {
    const obj = {};
    const arr = [];
    const objBanksRedondeos = {};
    const objBanksCargos = {};
    let tab = 0;
    //     this.props.banks.map(bank => {
    this.props.banks.forEach(bank => {
      arr.push(false);
      objBanksRedondeos[bank._id] = false;
      objBanksCargos[bank._id] = false;
      // bank.accounts.map(account => {
      bank.accounts.forEach(account => {
        obj[account._id] = {
          idBank: bank.idBank,
          bank: bank._id,
          type: account.type,
          name: account.name,
          ibanText: account.ibanText,
          panText: account.panText,
          currencyName: account.currencyName,
          currencyBalance: account.currencyBalance,
          isHidden: account.isHidden,
          isSelected: this.isSelected(
            account.targetsSelected,
            this.props.idTarget,
          ),
          isMain: this.isSelected(account.targetsMain, this.props.idTarget),
          witouthAcces: account.withoutAccessToTransactions,
          balance: account.currencyBalance,
        };
        if (!account.isHidden && account.type === 'account') {
          objBanksCargos[bank._id] = true;
        }
        if (!account.isHidden) {
          objBanksRedondeos[bank._id] = true;
        }
      });
    });
    if (this.props?.match?.params?.type === 'retirement') {
      tab = 1;
    }
    this.setState({
      accounts: obj,
      bankToggle: arr.slice(),
      showBanksRedondeos: objBanksRedondeos,
      showBanksCargos: objBanksCargos,
      tab,
    });
  }

  async setTargetAccounts(data) {
    const response = await TargetsAPI.setTargetAccounts(
      this.props.baseUrl,
      this.props.idTarget,
      data,
      this.props.token,
    );
    if (response.status === 200) {
      console.log('CreateTargetAccounts.setTargetAccounts: accounts setted');
    } else {
      console.log(
        'CreateTargetAccounts.setTargetAccounts: error configurando las cuentas del objetivo ',
        response.status,
      );
    }
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.targetBanks_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  renderAccountsEvo() {
    const { classes } = this.props;
    console.log('renderAccountsEvo', this.state.accounts);

    return (
      <React.Fragment>
        <Accounts
          banks={this.props.banks.filter(bank => bank.name === 'EVO Banco')}
          idTarget={this.props.idTarget}
          token={this.props.token}
          renderLoading={this.renderLoading.bind(this)}
          setAccounts={this.setTargetAccounts.bind(this)}
          classes={classes}
          config={AccountsConfig}
          history={this.props.history}
          next={this.props.next}
          handleSelected={this.handleSelected.bind(this)}
          handleMain={this.handleMain.bind(this)}
          accounts={this.state.accounts}
          tab={this.state.tab}
        />
      </React.Fragment>
    );
  }

  renderOtherAccounts() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Accounts
          banks={this.props.banks.filter(bank => bank.name !== 'EVO Banco')}
          idTarget={this.props.idTarget}
          token={this.props.token}
          renderLoading={this.renderLoading.bind(this)}
          setAccounts={this.setTargetAccounts.bind(this)}
          classes={classes}
          config={AccountsConfig}
          history={this.props.history}
          next={this.props.next}
          handleSelected={this.handleSelected.bind(this)}
          handleMain={this.handleMain.bind(this)}
          accounts={this.state.accounts}
          tab={this.state.tab}
        />
      </React.Fragment>
    );
  }

  renderCargos() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className='container'>
        <Header
          title='Cargos'
          rigthAction={() => this.props.history.push('/home/objetivos')}
        />
        <Body vAlign='top'>
          <SvgIcon
            color='primary'
            classes={transformClasses('targetBanks_title_icon', classes)}
            viewBox='0 0 28 28'
          >
            <path d='M 27.153778,12.520278 C 27.620833,12.520278 28,12.9045 28,13.377778 v 8.005278 c 0,0.473666 -0.379167,0.857888 -0.846222,0.857888 h -1.189222 v 2.450778 C 25.964556,26.516 24.5,28 22.699833,28 H 3.264333 C 1.463778,28 0,26.516 0,24.691722 V 10.069889 C 0,8.245222 1.463778,6.761611 3.264333,6.761611 h 19.4355 c 1.800167,0 3.264723,1.483611 3.264723,3.308278 v 2.450389 z m -2.882056,12.171444 v -2.450778 h -7.788278 c -1.800166,0 -3.264333,-1.483611 -3.264333,-3.308277 v -3.104111 c 0,-1.824667 1.464167,-3.308278 3.264333,-3.308278 h 7.788278 V 10.069889 C 24.271722,9.191778 23.566667,8.477 22.699833,8.477 h -19.4355 c -0.866444,0 -1.5715,0.714778 -1.5715,1.592889 v 14.621833 c 0,0.878111 0.705056,1.592889 1.5715,1.592889 h 19.4355 c 0.866834,0 1.571889,-0.714778 1.571889,-1.592889 z m 2.035445,-4.166166 v -6.289889 h -9.823723 c -0.866055,0 -1.571888,0.714389 -1.571888,1.592889 v 3.104111 c 0,0.878111 0.705833,1.592889 1.571888,1.592889 z M 17.572722,2.275389 11.091111,5.290833 H 7.033444 L 18.404944,0 20.801278,5.290833 h -1.862389 z m 0.390834,14.991667 c 0,0.608611 -0.487278,1.1025 -1.088112,1.1025 -0.600444,0 -1.088111,-0.493889 -1.088111,-1.1025 0,-0.609 0.487667,-1.102889 1.088111,-1.102889 0.600834,0 1.088112,0.493889 1.088112,1.102889 z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetBanks_title_typography1', classes)}
          >
            Cuentas de Cargo
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetBanks_title_typography2', classes)}
          >
            Activa las cuentas sobre las que se realiza el cargo.
          </Typography>

          <div
            style={{
              maxWidth: 350,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'targetBanks_title_bank_typography1',
                classes,
              )}
            >
              Cuentas EVO
            </Typography>
            {this.renderAccountsEvo()}
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'targetBanks_title_bank_typography2',
                classes,
              )}
            >
              Otros Bancos
            </Typography>
            {this.renderOtherAccounts()}
            <Typography
              variant='body1'
              color='primary'
              style={{ textDecoration: 'underline' }}
              onClick={() => Flow.navigate('/cargos', this.props)}
            >
              Ver todas tus cuentas.
            </Typography>
            <div style={{ height: 60 }} />
          </div>
        </Body>
        <Footer>
          <Button
            disabled={this.disableSave()}
            variant='contained'
            color='secondary'
            classes={transformClasses('targetBanks_body_button', classes)}
            onClick={() => this.handleSave()}
          >
            Siguiente
          </Button>
        </Footer>
      </div>
    );
  }

  renderRedondeos() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className='container'>
        {' '}
        {/*classes.targetBanks_container*/}
        <Header
          title='Redondeo'
          rigthAction={() => this.props.history.push('/home/objetivos')}
        />
        <Body vAlign='top'>
          {/*<div className={classes.targetBanks_body_container}>*/}
          <SvgIcon
            color='primary'
            classes={transformClasses('targetBanks_title_icon', classes)}
            viewBox='0 0 28 28'
          >
            <path d='M 27.153778,12.520278 C 27.620833,12.520278 28,12.9045 28,13.377778 v 8.005278 c 0,0.473666 -0.379167,0.857888 -0.846222,0.857888 h -1.189222 v 2.450778 C 25.964556,26.516 24.5,28 22.699833,28 H 3.264333 C 1.463778,28 0,26.516 0,24.691722 V 10.069889 C 0,8.245222 1.463778,6.761611 3.264333,6.761611 h 19.4355 c 1.800167,0 3.264723,1.483611 3.264723,3.308278 v 2.450389 z m -2.882056,12.171444 v -2.450778 h -7.788278 c -1.800166,0 -3.264333,-1.483611 -3.264333,-3.308277 v -3.104111 c 0,-1.824667 1.464167,-3.308278 3.264333,-3.308278 h 7.788278 V 10.069889 C 24.271722,9.191778 23.566667,8.477 22.699833,8.477 h -19.4355 c -0.866444,0 -1.5715,0.714778 -1.5715,1.592889 v 14.621833 c 0,0.878111 0.705056,1.592889 1.5715,1.592889 h 19.4355 c 0.866834,0 1.571889,-0.714778 1.571889,-1.592889 z m 2.035445,-4.166166 v -6.289889 h -9.823723 c -0.866055,0 -1.571888,0.714389 -1.571888,1.592889 v 3.104111 c 0,0.878111 0.705833,1.592889 1.571888,1.592889 z M 17.572722,2.275389 11.091111,5.290833 H 7.033444 L 18.404944,0 20.801278,5.290833 h -1.862389 z m 0.390834,14.991667 c 0,0.608611 -0.487278,1.1025 -1.088112,1.1025 -0.600444,0 -1.088111,-0.493889 -1.088111,-1.1025 0,-0.609 0.487667,-1.102889 1.088111,-1.102889 0.600834,0 1.088112,0.493889 1.088112,1.102889 z' />
          </SvgIcon>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetBanks_title_typography1', classes)}
          >
            Cuentas de redondeo
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('targetBanks_title_typography2', classes)}
          >
            Activa las cuentas sobre las que quieres aplicar los redondeos.
          </Typography>

          <div
            style={{
              maxWidth: 350,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'targetBanks_title_bank_typography1',
                classes,
              )}
            >
              Cuentas EVO
            </Typography>
            {this.renderAccountsEvo()}
            <Typography
              variant='body1'
              color='primary'
              classes={transformClasses(
                'targetBanks_title_bank_typography2',
                classes,
              )}
            >
              Otros Bancos
            </Typography>
            {this.renderOtherAccounts()}
            <Typography
              variant='body1'
              color='primary'
              style={{ textDecoration: 'underline' }}
              onClick={() => Flow.navigate('/cargos', this.props)}
            >
              Ver todas tus cuentas.
            </Typography>
            <div style={{ height: 60 }} />
          </div>
        </Body>
        <Footer>
          <Button
            disabled={this.disableNext()}
            variant='contained'
            color='secondary'
            classes={transformClasses('targetBanks_body_button', classes)}
            onClick={() => this.setState({ tab: 1 })}
          >
            Siguiente
          </Button>
        </Footer>
      </div>
    );
  }

  isSelected(array, id) {
    let booleano = false;
    //     array.map(element => {
    array.forEach(element => {
      if (element._id === id) {
        booleano = true;
      }
    });
    return booleano;
  }

  handleSelected(id) {
    const obj = this.state.accounts;
    obj[id].isSelected = !obj[id].isSelected;
    this.setState({
      accounts: obj,
    });
  }

  handleMain(id) {
    const obj = this.state.accounts;
    // Object.keys(obj).map(key => {
    Object.keys(obj).forEach(key => {
      if (key === id) {
        obj[id].isMain = !obj[id].isMain;
      } else {
        obj[key].isMain = false;
      }
    });
    this.setState({
      accounts: obj,
    });
  }

  disableNext() {
    let disabled = true;
    // Object.keys(this.state.accounts).map(key => {
    Object.keys(this.state.accounts).forEach(key => {
      if (this.state.accounts[key].isSelected) {
        disabled = false;
      }
    });
    return disabled;
  }

  disableSave() {
    let disabled = true;
    //Object.keys(this.state.accounts).map(key => {
    Object.keys(this.state.accounts).forEach(key => {
      if (this.state.accounts[key].isMain) {
        disabled = false;
      }
    });
    return disabled;
  }

  async handleSave() {
    const data = {
      accounts: [],
      mainAccount: null,
    };
    // Object.keys(this.state.accounts).map(key => {
    Object.keys(this.state.accounts).forEach(key => {
      if (
        this.state.accounts[key].isSelected &&
        this.props?.match?.params?.type !== 'retirement'
      ) {
        data.accounts.push(key);
      }
      if (this.state.accounts[key].isMain) {
        data.mainAccount = key;

        if (this.props?.match?.params?.type === 'retirement') {
          data.accounts.push(key);
        }
      }
    });
    await this.setTargetAccounts(data);
    console.log('CreateTargetAccounts.handleSave siguiente');
    this.props.history.push(this.props.next);
  }

  render() {
    //const {classes} = this.props
    //const transformClasses = Utils.transformClasses
    return this.state.accounts !== null ? (
      this.state.tab === 0 ? (
        this.renderRedondeos()
      ) : (
        this.renderCargos()
      )
    ) : (
      <div />
    );
  }
}

const styles = theme => ({
  ...AccountsStyles(theme),
  targetBanks_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  targetBanks_container: {
    width: '100%',
    minHeight: '640px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  header: {
    display: 'flex',
    height: '10vh',
    minHeight: 57,
    backgroundColor: theme.palette.primary.main,
  },
  accounts_container: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  accounts_tabsDiv: {
    width: '100%',
    padding: '0 12px',
    height: '10vh',
    minHeight: 57,
  },
  accounts_gridContainer1_container: {
    height: '70vh',
    minHeight: 397,
    display: 'flex',
    flexDirection: 'column',
  },
  accounts_button_container: {
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: '0 12px 12px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  accounts_button1_root: {
    width: '100%',
    fontWeight: 600,
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  targetBanks_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  targetBanks_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  targetBanks_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '18px',
  },
  targetBanks_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
  },
  targetBanks_body_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  targetBanks_title_icon_root: {
    height: '45px',
    width: '45px',
    marginTop: '24px',
    marginBottom: '10px',
  },
  targetBanks_title_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    lineHeight: '37.4px',
    letterSpacing: '-1px',
    width: '284px',
    paddingTop: 15,
  },
  targetBanks_title_typography2_root: {
    fontSize: '16px',
    textAlign: 'center',
    width: '300px',
    marginTop: '9px',
    marginBottom: '16px',
  },
  targetBanks_title_bank_typography1_root: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
    width: '85px',
    height: '21px',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    //marginLeft: '24px',
    fontWeight: 600,
    marginBottom: '7px',
  },
  targetBanks_title_bank_typography2_root: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
    width: '95px',
    height: '21px',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    //marginLeft: '24px',
    fontWeight: 600,
    marginBottom: '7px',
    marginTop: '11px',
  },
  targetBanks_body_button_root: {
    width: '312px',
    height: '40px',
    borderRadius: '4px',
    marginBottom: '24px',
    marginTop: 'auto',
  },
  targetBanks_body_button_label: {
    fontSize: '14px',
    fontWeight: 600,
  },
});

export default withStyles(styles, { withTheme: true })(CreateTargetAccounts);
