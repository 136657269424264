import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import Login2 from './containers/login2.container';
import { PersistGate } from 'redux-persist/integration/react';
import Home from './containers/home.container';
import Notifications from './containers/notifications.container';
import Cargos from './containers/cargos.container';
import Faqs from './containers/faqs.container';
import Documents from './containers/documents.container';
import History from './containers/history.container';
import TargetsTypes from './containers/targetsTypes.container';
import CreateTarget from './containers/targetsCreate.container';
import ConfigureTarget from './containers/targetsConfigure.container';
import FollowupMain from './containers/followupMain.container';
import ConfigureRule from './containers/configureRule.container';
import RulesList from './containers/rulesList.container';
import CreateRule from './containers/createRule.container';
import RuleMovements from './containers/ruleMovements.container';
import TargetsByRule from './containers/targetsByRule.container';
import LandingPage from './containers/landingPage.container';
import Tour from './views/tour/tour.component';
import CargosInitial from './containers/cargosInitial.container';
import Survey from './containers/survey.container';
import SurveyComplete from './containers/surveyComplete.container';
import SurveyLanding from './containers/surveyLanding.container';
import ChatSupport from './containers/chatSupport.container';
import NoTargets from './views/noTargets/noTargets.component';
import AhorroHot from './containers/ahorroHot.container';
import ReglaRetirementTraspaso from './containers/reglaRetirementTraspaso.container';
import CreateTargetDoneOk from './views/CreateTarget/CreateTargetDoneOk';
import CreateTargetDoneKo from './views/CreateTarget/CreateTargetDoneKo';
import ListConfRule from './containers/listConfRule.container';
import MenuHome from './containers/menuHome.container';
import Info from './views/Home/Info';
import ProductFunds from './views/Product/ProductFunds';
import ProductFund from './views/Product/ProductFund';
import ProductSimulator from './views/Product/Simulator/ProductSimulator';
import ProductSimulatorResume from './views/Product/Simulator/ProductSimulatorResume';
import ProductSimulatorExplanation from './views/Product/Simulator/ProductSimulatorExplanation';
import ProductSimulatorCosts from './views/Product/Simulator/ProductSimulatorCosts';
import ProductSimulatorInsurance from './views/Product/Simulator/ProductSimulatorInsurance';
import ProductSimulatorUnitLinked from './views/Product/Simulator/ProductSimulatorUnitLinked';
import ProductSimulatorUnitLinkedAnnual from './views/Product/Simulator/ProductSimulatorUnitLinkedAnnual';
import ProductHistorical from './views/Product/Historical/ProductHistorical';

import SimulatorPensionPlanInput from './views/Product/SimulatorPensionPlan/SimulatorPensionPlanInput';
import SimulatorPensionPlanResult from './views/Product/SimulatorPensionPlan/SimulatorPensionPlanResult';

import PromoChequeAmazon from './views/Promos/ChequeAmazon';

import { UiWrapper, ApplicationWrapper, Layout } from 'coinscrap-webapp-core';

import config from './config';
import { backgroundImages } from './config/Layout/backgrounds';

// routes
import routes from './config/routes';

// Retirement
import CreateRetirementObjective from './views/createRetirement/CreateRetirementObjective';
import SimulationIntro from './views/createRetirement/simulation/retirement/SimulationRetirementIntro';
import SimulationAge from './views/createRetirement/simulation/retirement/SimulationRetirementAge';
import SimulationContribution from './views/createRetirement/simulation/retirement/SimulationRetirementContribution';
import SimulationCongratulationsYear from './views/createRetirement/simulation/retirement/SimulationRetirementCongratulations';
import SimulationIntroPension from './views/createRetirement/simulation/pension/SimulationPensionIntro';
import SimulationEmploymentType from './views/createRetirement/simulation/pension/SimulationPensionEmploymentType';
import SimulationEmploymentSalary from './views/createRetirement/simulation/pension/SimulationPensionEmploymentSalary';
import SimulationCongratulationsPension from './views/createRetirement/simulation/pension/SimulationPensionCongratulations';
import SimulationSavingsAmount from './views/createRetirement/simulation/savings/SimulationSavingsAmount';
import SimulationSavingsInfo from './views/createRetirement/simulation/savings/SimulationSavingsInfo';
import SimulationSavingsCongratulations from './views/createRetirement/simulation/savings/SimulationSavingsCongratulations';
import SimulationSavingsPerfil from './views/createRetirement/simulation/savings/SimulationSavingsPerfil';
import SimulationSummary from './views/createRetirement/simulation/SimulationSummary';
import SimulationResults from './views/createRetirement/simulation/SimulationResults';
import Genial from './views/createRetirement/simulation/Genial';
import Calculator from './views/createRetirement/manualmente/Calculator';
import SelectPlan from './views/createRetirement/traspaso/SelectPlan';
//import ProductInfoGraphDet from './containers/ProductInfoGraphDet.container';

// Store
import Store from './pensionStore/Store';
import SimulationCalculations from './views/createRetirement/simulation/SimulationCalculations';
import Beneficiario from './views/createRetirement/Beneficiario';
import CajaFuertePromo2 from './views/Promos/CajaFuertePromo2';
import TestPdf from './TestPdf';

const Root = ({ store, persistor }) => {
  console.log({ store, persistor });
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Store>
          <UiWrapper config={config} backgroundImages={backgroundImages}>
            <Router>
              <Switch>
                <Route
                  path='/test/pdf'
                  render={props => <TestPdf {...props} />}
                />
                <Route
                  path='/createTargetDoneOk'
                  render={props => <CreateTargetDoneOk {...props} />}
                />
                <Route
                  path='/createTargetDoneKo'
                  render={props => <CreateTargetDoneKo {...props} />}
                />
                <Route
                  path='/home/reglas/listConfRule/:id'
                  render={props => <ListConfRule {...props} />}
                />
                <Route
                  path='/ahorroHot/:id?'
                  render={props => <AhorroHot {...props} />}
                />
                <Route
                  path='/reglaRetirementTraspaso'
                  render={props => <ReglaRetirementTraspaso {...props} />}
                />
                <Route
                  path='/noTargets'
                  render={props => <NoTargets {...props} />}
                />
                <Route
                  path='/chatSupport'
                  render={props => <ChatSupport {...props} />}
                />
                <Route
                  path='/survey/completed'
                  render={props => <SurveyComplete {...props} />}
                />
                <Route
                  path='/survey/answer'
                  render={props => <Survey {...props} />}
                />
                <Route
                  path='/survey'
                  render={props => <SurveyLanding {...props} />}
                />
                <Route
                  path='/promoChequeAmazon'
                  render={props => <PromoChequeAmazon {...props} />}
                />
                <Route
                  path='/promoCajaFuerte'
                  render={props => <CajaFuertePromo2 {...props} />}
                />
                <Route path='/tour' render={props => <Tour {...props} />} />
                <Route
                  path='/history'
                  render={props => <History {...props} />}
                />
                <Route path='/menu' render={props => <MenuHome {...props} />} />
                <Route path='/info' render={props => <Info {...props} />} />
                <Route
                  path='/documents'
                  render={props => <Documents {...props} />}
                />
                <Route
                  path='/documents/:idTarget/'
                  render={props => <Documents {...props} />}
                />
                <Route path='/faqs' render={props => <Faqs {...props} />} />
                <Route
                  path='/notifications'
                  render={props => <Notifications {...props} />}
                />
                <Route path='/cargos' render={props => <Cargos {...props} />} />
                <Route
                  path='/targetsByRule/:id/'
                  render={props => <TargetsByRule {...props} />}
                />
                <Route
                  path='/rule/:id/movements/:idTarget'
                  render={props => <RuleMovements {...props} />}
                />
                <Route
                  path='/followup/:id'
                  render={props => <FollowupMain {...props} />}
                />
                <Route
                  path='/configure/rule/:id/:defID?/:targetID?'
                  render={props => <ConfigureRule {...props} />}
                />
                <Route
                  path='/configure/target/:id'
                  render={props => <ConfigureTarget {...props} />}
                />
                <Route
                  path='/create/rule/:id/:idRule'
                  render={props => <CreateRule {...props} />}
                />
                <Route
                  path='/create/rule/:id'
                  render={props => <RulesList {...props} />}
                />
                <Route
                  path='/create/target/:type'
                  render={props => <CreateTarget {...props} />}
                />
                <Route
                  path='/create/types'
                  render={props => <TargetsTypes {...props} />}
                />
                <Route
                  path='/home:filter?'
                  render={props => <Home {...props} />}
                />
                <Route
                  path='/landing'
                  render={props => (
                    <LandingPage {...props} redirector='/landing' />
                  )}
                />
                <Route
                  path='/cuentasInitial'
                  render={props => <CargosInitial {...props} />}
                />
                <Route
                  path='/product/funds'
                  render={props => <ProductFunds {...props} />}
                />
                <Route
                  path='/product/fund'
                  render={props => <ProductFund {...props} />}
                />
                <Route
                  path='/product/fundHistorical'
                  render={props => <ProductHistorical {...props} />}
                />
                <Route
                  path='/product/simulatorTab'
                  render={props => <ProductSimulator {...props} />}
                />

                <Route
                  path='/product/simulatorPensionPlanInput'
                  render={props => <SimulatorPensionPlanInput {...props} />}
                />
                <Route
                  path='/product/simulatorPensionPlanResult'
                  render={props => <SimulatorPensionPlanResult {...props} />}
                />

                <Route
                  path='/product/simulator/resume'
                  render={props => <ProductSimulatorResume {...props} />}
                />
                <Route
                  path='/product/simulator/explanation'
                  render={props => <ProductSimulatorExplanation {...props} />}
                />
                <Route
                  path='/product/simulator/costs'
                  render={props => <ProductSimulatorCosts {...props} />}
                />
                <Route
                  path='/product/simulator/insurance'
                  render={props => <ProductSimulatorInsurance {...props} />}
                />
                <Route
                  path='/product/simulator/unitlinked'
                  render={props => <ProductSimulatorUnitLinked {...props} />}
                />
                <Route
                  path='/product/simulator/unitlinkedAnnual'
                  render={props => (
                    <ProductSimulatorUnitLinkedAnnual {...props} />
                  )}
                />
                <Route
                  path='/login'
                  render={props => <Login2 {...props} redirector='/landing' />}
                />
                <Route
                  path={routes.retirementObjective}
                  exact
                  render={props => <CreateRetirementObjective {...props} />}
                />
                <Route
                  path={routes.retirementBeneficiario}
                  exact
                  render={props => <Beneficiario {...props} />}
                />
                <Route
                  path={routes.retirementSimulation}
                  exact
                  render={props => <SimulationIntro {...props} />}
                />
                <Route
                  path={routes.retirementSimulationAge}
                  exact
                  render={props => <SimulationAge {...props} />}
                />
                <Route
                  path={routes.retirementSimulationContribution}
                  exact
                  render={props => <SimulationContribution {...props} />}
                />
                <Route
                  path={routes.retirementSimulationCongratulations}
                  exact
                  render={props => <SimulationCongratulationsYear {...props} />}
                />
                <Route
                  path={routes.retirementSimulationPensionInfo}
                  exact
                  render={props => <SimulationIntroPension {...props} />}
                />
                <Route
                  path={routes.retirementSimulationPensionEmployment}
                  exact
                  render={props => <SimulationEmploymentType {...props} />}
                />
                <Route
                  path={routes.retirementSimulationPensionEmploymentSalary}
                  exact
                  render={props => <SimulationEmploymentSalary {...props} />}
                />
                <Route
                  path={routes.retirementSimulationPensionCongratulations}
                  exact
                  render={props => (
                    <SimulationCongratulationsPension {...props} />
                  )}
                />
                <Route
                  path={routes.retirementSimulationSavingsInfo}
                  exact
                  render={props => <SimulationSavingsInfo {...props} />}
                />
                <Route
                  path={routes.retirementSimulationSavingsAmount}
                  exact
                  render={props => <SimulationSavingsAmount {...props} />}
                />
                <Route
                  path={routes.retirementSimulationSavingsCongratulations}
                  exact
                  render={props => (
                    <SimulationSavingsCongratulations {...props} />
                  )}
                />
                <Route
                  path={routes.retirementSimulationSavingsPerfil}
                  exact
                  render={props => <SimulationSavingsPerfil {...props} />}
                />
                <Route
                  path={routes.retirementSimulationSummary}
                  exact
                  render={props => <SimulationSummary {...props} />}
                />
                <Route
                  path={routes.retirementSimulationCalculations}
                  exact
                  render={props => <SimulationCalculations {...props} />}
                />
                <Route
                  path={routes.retirementSimulationResults}
                  exact
                  render={props => <SimulationResults {...props} />}
                />
                <Route
                  path={routes.retirementSimulationGenial}
                  exact
                  render={props => <Genial {...props} />}
                />
                <Route
                  path={routes.retirementManualCalculator}
                  exact
                  render={props => <Calculator {...props} />}
                />
                {/* Retirement transfer flow */}
                <Route
                  path={routes.retirementTransferSelectPlan}
                  exact
                  render={props => <SelectPlan {...props} />}
                />
                <Route
                  render={props => (
                    <LandingPage {...props} redirector='/landing' />
                  )}
                />
              </Switch>
            </Router>
          </UiWrapper>
        </Store>
      </PersistGate>
    </Provider>
  );
};

export default Root;
