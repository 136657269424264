import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { View, Text, TextField } from 'coinscrap-webapp-core';
import classes from './SelectPlan.module.css';

import { StoreContext } from '../../../pensionStore/Store';
import * as Types from '../../../pensionStore/types';
import { useSelector } from 'react-redux';
import useSearch from './hooks/usePlansSearch';

require('intersection-observer');

export default ({ onClick, style }) => {
  const [searchPlan, setSearchPlan] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [pageNumber, setPageNumber] = useState(10);

  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  const inputRef = useRef(null);

  const store = useSelector(state => state.main);

  const { loading, error, hasMore, listPlans, setLoading } = useSearch(
    store,
    inputRef,
    searchPlan,
    setSearchPlan,
  );

  useEffect(() => {
    if (state.pensionPlan && searchPlan) {
      dispatch({
        type: Types.PENSION_PLAN,
        payload: null,
      });
    }
  }, [searchPlan]);

  const observer = useRef();

  const fakeLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };
  const lastPlanElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          fakeLoading();
          setPageNumber(prevState => prevState + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  console.log('list plans', listPlans.slice(0, pageNumber));

  return (
    <>
      <View>
        <View
          style={{
            position: 'sticky',
            top: '0px',
          }}
        >
          <TextField
            placeholder='Buscar tu plan'
            inputRef={inputRef}
            selectPlanSt
            value={searchPlan}
            onChange={e => setSearchPlan(e.target.value)}
          />
        </View>
        {!searchPlan && state.pensionPlan && (
          <View>
            <Text
              style={{
                marginBottom: 5,

                textDecoration: 'underline',
              }}
            >
              Plan Seleccionado
            </Text>
            <Text
              style={{
                fontSize: 18,
                paddingTop: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {state.pensionPlan.name}
            </Text>
          </View>
        )}
        <View
          style={{
            transition: '0.4s all',
            height:
              !searchPlan && listPlans.length === 0
                ? 20
                : style?.height
                ? style.height
                : 250,
          }}
        >
          <View
            width85St
            style={{
              height: '100%',
              overflow: 'scroll',
              position: 'relative',
              justifyContent: 'flex-start',
            }}
          >
            {listPlans
              .map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{ width: '100%' }}
                    ref={pageNumber === index + 1 ? lastPlanElementRef : null}
                  >
                    <View
                      style={{
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        onClick(item);
                        setSelectedPlan(item);
                      }}
                    >
                      <Text
                        style={{
                          color:
                            selectedPlan === item
                              ? 'white'
                              : 'rgba(255,255,255,.5)',
                          fontSize: 18,
                          paddingTop: 0,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '90%',
                        }}
                      >
                        {item.name}
                      </Text>
                    </View>
                  </div>
                );
              })
              .slice(0, pageNumber)}
          </View>
        </View>
      </View>

      {loading && (
        <div
          className={classes['lds-roller']}
          style={{
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </>
  );
};
