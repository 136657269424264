/**
 * Tabs de los tipos de Objetivos
 *
 */
import React, { Component } from 'react';
import { Tabs } from '../../../ui/index';
import Tab from '@material-ui/core/Tab';

import { Utils } from '../../../resources/index';

class TargetsTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    this.setState({
      current: this.props.currentTab,
    });
  }

  handleClick(route, current, event) {
    event.preventDefault();
    this.setState({
      current: this.props.tabs.map(tab => tab.filter).indexOf(current),
    });
    console.log(
      'something: ',
      this.props.tabs.map(tab => tab.filter).indexOf(current),
    );
    this.props.history.push(route);
    if (this.props.updateParent !== undefined) {
      this.props.updateParent(
        this.props.tabs.map(tab => tab.filter).indexOf(current),
      );
    }
  }

  renderTabs() {
    const { config, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const tabs = this.props.tabs.map(tab => {
      return (
        <Tab
          {...config.tab}
          label={tab.label}
          key={tab.filter}
          icon={tab.icon}
          onClick={e =>
            this.handleClick(this.props.base + tab.filter, tab.filter, e)
          }
          classes={transformClasses('targetsTabs_tab1', classes)}
        />
      );
    });
    return tabs;
  }

  render() {
    const { config, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const configTabs = {
      ...config.tabs,
      children: this.renderTabs(),
    };
    return (
      <Tabs
        {...configTabs}
        // value={this.props.currentTab}
        current={this.props.currentTab}
        classes={transformClasses('targetsTabs_tabs1', classes)}
      />
    );
  }
}

export default TargetsTabs;
