import React, { useContext, useEffect } from 'react';
import { View, Text, Button, Enfatize } from 'coinscrap-webapp-core';

import Header from '../../../components/MyHeader';
import Footer from '../../../components/MyFooter';
import routes from '../../../config/routes';
import colors from '../../../config/colors';
import Flow from '../../../components/Flow';
import { StoreContext } from '../../../pensionStore/Store';
import CurrencyDecimals from '../../../libs/CurrencyDecimals';
import navConfig from '../config/navigation';
import { formatNumber } from '../../../libs/formatToCurrency';

import Check from '../../common/svgs/Check';

export default props => {
  const flowParams = Flow.getParams();

  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  useEffect(() => {
    // if (!state.simulation.started && !state.manual.started) {
    //   props.history.push(navConfig.notStarted);
    // }
  }, []);
  return (
    <>
      <Header
        title='Añadir objetivo'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt>
        <View flexSt width85St>
          <View style={{ marginBottom: 20, paddingTop: 90 }}>
            <Check style={{ color: colors.iconPurple }} />
          </View>
          <Text headlineSt centerSt headlineSpaceSt>
            ¡Genial!
          </Text>
          <Text centerSt weight500St>
            ¡Has fijado tu objetivo!
          </Text>
          <View>
            {flowParams.mode === 'retirementManual' ? (
              <Text font16St centerSt style={{ marginTop: 10 }}>
                Habrás aportado un total de{' '}
                <Enfatize>{formatNumber(state.manual.total)} € </Enfatize>
                lo que supone una aportación de{' '}
                <Enfatize>{formatNumber(state.manual.savings)} €</Enfatize> y
                una aportación anual de{' '}
                <Enfatize>
                  {formatNumber(state.manual.yearlyContribution)} €
                </Enfatize>{' '}
                y una aportación mensual de{' '}
                <Enfatize>
                  {formatNumber(state.manual.monthlyContribution)} €
                </Enfatize>
                .
              </Text>
            ) : (
              <Text font16St centerSt style={{ marginTop: 10 }}>
                Tendras que aportar:{' '}
                <Enfatize>
                  {state.simulation.yearlyContribution === 0
                    ? '0 €'
                    : CurrencyDecimals(
                        state.simulation.yearlyContribution,
                        true,
                        true,
                      )}
                </Enfatize>{' '}
                al año ; mes a mes{' '}
                <Enfatize>
                  {state.simulation.monthlyContribution === 0
                    ? '0 €'
                    : CurrencyDecimals(
                        state.simulation.monthlyContribution,
                        true,
                        true,
                      )}
                </Enfatize>{' '}
                para mantener tu nivel de ingresos.
              </Text>
            )}
          </View>
          <Text font16St centerSt style={{ marginTop: 10 }}>
            Para conseguir tu objetivo presentamos una serie de reglas que te
            ayudarán a conseguirlo de manera más fácil.{' '}
          </Text>
          <Text font16St centerSt style={{ marginTop: 10 }}>
            ¡Ánimo!
          </Text>
        </View>

        <View width85St>
          {flowParams.mode === 'retirementManual' ? (
            <Text font12St style={{ lineHeight: 1.1, textAlign: 'justify' }}>
              Caja Fuerte - Planes de Pensiones entidad Depositaria: CECA,
              Entidad Promotora: EVO Banco, Entidad Gestora: Caser Pensiones,
              Entidad Comercializadora: EVO Banco. La rentabilidad del producto
              podría ser nula e incluso negativa al estar sujetos a riesgos de
              mercado, de crédito, emisor y contrapartida, de liquidez y de
              cambio de moneda, fluctuaciones de valor y la posible pérdida de
              capital invertido. Los auditores de los fondos de pensiones son
              Mazars Auditores, S.L.P. También puedes consultar los documentos
              de los datos fundamentales en www.caser.es.
            </Text>
          ) : (
            <Text font16St centerSt style={{ marginTop: 30 }}>
              Producto con riesgo que puede variar los importes de la
              simulación.
            </Text>
          )}
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() => {
              if (flowParams.flujo === 'transfer') {
                props.history.push('/create/target/retirement/Reglas');
              } else {
                props.history.push('/create/target/retirement/Inicial');
              }
            }}
          >
            CONFIGURAR REGLAS
          </Button>
        </View>
      </Footer>
    </>
  );
};
