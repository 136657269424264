const config = {
    list: {
      component: 'div',
      disablePadding: true
    },
    listItem: {
      disableGutters: true
    },
    typographyTitle1: {
      variant: 'h5',
      color: 'secondary'
    },
    typographyAmount1: {
      variant: 'body1',
      color: 'primary'
    },
    typographyAmount2: {
      variant: 'h6',
      color: 'secondary'
    },
    typographyActive1:{
      variant: 'body1',
      color:'primary',
    },
    editTypography1:{
      variant: 'body1',
      color: 'primary'
    },
    editTypography2:{
      variant: 'h6',
      color: 'secondary'
    },
    editIconButton1:{
      color: 'secondary'
    }
  }
  
  export { config as ListTargetsForRulesConfig }
  