import React from 'react'
import KeyboardArrowLef from '@material-ui/icons/KeyboardArrowLeft'
import IconButton from '@material-ui/core/IconButton'

function backButton(history,prev) {
  history.push(prev)
}

export default props => {
  return (
    <div className={props.classes.header}>
      <IconButton onClick={e => backButton(props.history,props.prev, e)}>
        <KeyboardArrowLef color='primary' className={props.classes.icon} />
      </IconButton>
    </div>
  )
}