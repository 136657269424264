import React, { useReducer, createContext } from 'react';
import { reducer } from './cases';
import { initialState } from './state';
import { View } from 'coinscrap-webapp-core';

export const StoreContext = createContext();

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log(state, 'PENSION STATE');
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default Store;
