import React, { Component } from 'react';

//import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
// import {
//   ListTargetMovements,
//   ListTargetMovementsConfig,
//   ListTargetMovementsStyles,
// } from '../../core/s';
import { ListTargetMovements } from '../../src-core/targets/index';
import { ListTargetMovementsConfig } from '../../src-core/targets/index';
import { ListTargetMovementsStyles } from '../../src-core/targets/index';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';

//import {Utils} from '../../core/ces'
//import {followupTarget_strings} from './text'
import { StyleCss } from './styles';

class FollowupMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: null,
      next: null,
      transfers: null,
      pensionTransfers: null,
    };
  }

  componentDidMount() {
    const { baseUrl, targetID, token } = this.props;
    this.getMovements(baseUrl, targetID, token);
    this.getNextMovement(baseUrl, targetID, token);
    if (this.props.target.type === 'retirement') {
      this.getPensionTransfers(baseUrl, targetID, token);
    }
  }

  getMovements(baseUrl, id, token) {
    TargetsAPI.getMovements(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log("FollowupMovements.getMovemenet", data)
        this.props.updateMovements(data);
      })
      .catch(error => {
        console.log('error recuperando los movimientos de usuario', error);
      });
  }

  getNextMovement(baseUrl, id, token) {
    TargetsAPI.nextMovement(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.props.updateNextMovement(data);
      })
      .catch(error => {
        console.log('error recuperando los movimientos de usuario', error);
      });
  }
  async getPensionTransfers(baseUrl, id, token) {
    let dataTransfer = [];
    try {
      const responseTransfer = await TargetsAPI.getStatusTPP(
        baseUrl,
        this.props.target.user,
        token,
        id,
      );
      dataTransfer = await responseTransfer.json();

      const arrangeDataTransfer = dataTransfer.reduce((prev, cur) => {
        const findTransferIndex = prev.findIndex(
          item => item.dgs_plan_origen === cur.dgs_plan_origen,
        );

        if (findTransferIndex !== -1) {
          const findSuccess = prev.findIndex(item =>
            item.SITUACION_c.toLowerCase().includes('finalizado'),
          );

          if (findSuccess !== -1) return prev;
          if (cur.SITUACION_c.toLowerCase().includes('finalizado')) {
            prev[findTransferIndex] = cur;
            return prev;
          } else if (
            moment(prev[findTransferIndex].fecha_solicitud_cliente).valueOf() <
            moment(cur.fecha_solicitud_cliente).valueOf()
          ) {
            prev[findTransferIndex] = cur;
            return prev;
          }
        } else {
          prev.push(cur);
          return prev;
        }
      }, []);
      console.log({ arrangeDataTransfer });
      this.setState({
        pensionTransfers: arrangeDataTransfer,
      });
      // this.setState({
      //   pensionTransfers: dataTransfer
      //     .sort(function(a, b) {
      //       return (
      //         new Date(a?.fecha_solicitud_cliente) -
      //         new Date(b?.fecha_solicitud_cliente)
      //       );
      //     })
      //     .slice(0, 1),
      // });
    } catch (error) {
      dataTransfer = [];
    } finally {
      return dataTransfer;
    }
  }

  goToDetails(id) {
    this.props.history.replace(
      '/followup/' + this.props.targetID + '/Movimientos/' + id + '/detalles',
    );
  }

  goToNextDetails(lines) {
    this.props.history.replace({
      pathname:
        '/followup/' + this.props.targetID + '/Movimientos/next/detalles',
      state: {
        movementLines: lines,
      },
    });
  }

  render() {
    const { classes, targetID } = this.props;
    //const transformClasses = Utils.transformClasses
    //console.log("FollowupMovements.render", this.props.movements);
    return this.props.movements !== null && this.props.nextMovement !== null ? (
      //<div className={classes.followupMovements_Container}>
      <>
        <ListTargetMovements
          classes={classes}
          targetID={targetID}
          config={ListTargetMovementsConfig}
          movements={this.props.movements}
          nextMovement={this.props.nextMovement}
          history={this.props.history}
          goToDetails={this.goToDetails.bind(this)}
          goToNextDetails={this.goToNextDetails.bind(this)}
          pensionTransfers={this.state.pensionTransfers}
        />
      </>
    ) : (
      //</div>
      <div className={classes.followupMovements_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

const styles = theme => ({
  ...ListTargetMovementsStyles(theme),
  followupMovements_Container: {
    display: 'flex',
    flexDirection: 'column',
    //height: '100%'
  },
  listTargetMovements_divListContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    flex: 1,
  },
  followupMovements_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  followupMovements_headerContainer: {
    display: 'flex',
    padding: 12,
    alignItems: 'center',
  },
  followup_headerTitle_root: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: -6,
  },

  listTargetMovements_iconButton1_root: {
    padding: 'unset',
    color: StyleCss.color.primary,
  },
  listTargetMovements_typography4_root: {
    padding: 12,
    fontSize: '12px',
    marginBottom: -6,
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255,255,255,0.098)',
    textTransform: 'uppercase',
  },
  listTargetMovements_typography1_root: {
    fontSize: '16px',
    marginBottom: -6,
    color: theme.palette.primary.main,
  },
  listTargetMovements_typography2_root: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: -6,
    textTransform: 'lowercase',
    color: StyleCss.color.primary,
  },
  listTargetMovements_typography3_root: {
    fontSize: 24,
    fontWeight: 200,
    marginBottom: -6,
    color: StyleCss.color.primary,
  },
  listTargetMovements_listItem1_root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },

  movements_confe_container: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  movements_concepto_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movements_fecha_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movements_ammount_container: {
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  movements_icon_container: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pinkSquare: {
    height: '8px',
    width: '8px',
    backgroundColor: theme.palette.secondary.main,
    marginRight: '8px',
  },
});

export default withStyles(styles, { withTheme: true })(FollowupMovements);

/*
<div className={classes.followupMovements_headerContainer} >
          <Typography variant='body1' color='primary' classes={transformClasses('followup_headerTitle', classes)}>
            {followupTarget_strings['es'].followupMovements_header_text}
          </Typography>
        </div>
*/
