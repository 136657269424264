import colors from '../colors';
import { fonts } from '../fonts';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('view', {
  styles: {
    default: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      color: colors.white,
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 100,
      fontFamily: fonts,
    },

    fullHeightSt: {
      height: '100%',
    },

    fullWidthSt: {
      width: '100%',
    },

    flexSt: {
      flex: 1,
    },

    componentWrapperSt: {
      marginTop: 15,
      marginBottom: 50,
    },

    spaceBetweenSt: {
      justifyContent: 'space-between',
    },

    wideWidthPeriodicSt: {
      width: 343,
      maxWidth: '95%',
    },

    startSt: {
      justifyContent: 'flex-start',
    },
    endSt: {
      justifyContent: 'flex-end',
    },

    bottomSt: {
      alignItems: 'flex-end',
    },
    topSt: {
      alignItems: 'flex-start',
    },

    centerSt: {
      alignItems: 'center',
    },
    justifyCenterSt: {
      justifyContent: 'center',
    },
    alingStartSt: {
      alignItems: 'start',
    },

    displayFlexSt: {
      display: 'flex',
    },

    flexRowSt: {
      flexDirection: 'row',
    },

    width80St: {
      width: '80%',
    },
    width82St: {
      width: '82%',
    },
    width85St: {
      width: '85%',
    },
    width87St: {
      width: '87%',
    },
    width93St: {
      width: '93%',
    },
    width90St: {
      width: '90%',
    },
    width92St: {
      width: '92%',
    },

    autoWidthSt: {
      width: 'auto',
    },
    boldSt: {
      fontWeight: 600,
    },
    weight400St: {
      fontWeight: 400,
    },
    weight500St: {
      fontWeight: 500,
    },

    footerSmallRoundButtonWrapperSt: {
      padding: '15px 30px 15px 30px',
    },
    footerRoundIconButtonSt: {
      borderRadius: '50%',
      color: colors.white,
      backgroundColor: colors.green,
      height: 40,
      width: 40,
      fontSize: 13,
      marginRight: 10,
    },
    modalActivarSwitchBodySt: {
      width: '85%',
      height: 200,
      backgroundColor: '#5b5048',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '4px',
      padding: 15,
    },
    footerRoundOkButtonSt: {
      borderRadius: '50%',
      color: colors.white,
      backgroundColor: colors.pink,
      height: 50,
      width: 50,
      fontSize: 13,
    },
    pinkDotSt: {
      width: 8,
      height: 8,
      backgroundColor: '#f4137b',
      borderRadius: '50%',
      marginRight: 10,
    },
    smallDotSt: {
      borderRadius: '50%',

      backgroundColor: colors.pink,
      height: 7,
      width: 7,

      marginRight: 10,
    },
    gridSt: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '10px',
    },
    summaryCardSt: {
      backgroundColor: '#424242',
      borderRadius: 5,
      height: 40,
      marginTop: 10,
      padding: '0 15px 0 10px',
    },

    transitionSt: {
      transition: 'all .6s',
    },

    bodySt: {
      flex: '0 1 auto',
      overflow: 'auto',
      justifyContent: 'flex-start',
      marginBottom: 85,
      minHeight: 'calc(100% - 160px)',
    },
    bodyTwoButtonsSt: {
      flex: '0 1 auto',
      overflow: 'auto',
      justifyContent: 'flex-start',
      marginBottom: 135,
      paddingTop: 25,
      minHeight: 'calc(100% - 210px)',
    },

    bodyAddTopPaddingSt: {
      paddingTop: 75,
    },
    iconMarginBottomSt: {
      marginBottom: 18,
    },

    planesBoxSt: {
      height: 35,
      width: 35,
      backgroundColor: 'white',
      color: '#5f5e5e',
      marginRight: 5,
    },
    planesBoxActiveSt: {
      height: 35,
      width: 35,
      backgroundColor: '#5f5e5e',
      color: 'white',
      marginRight: 5,
    },
  },
});
