import React, { Component } from 'react';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Security from '@material-ui/icons/Security';
import LocalCafeOutlined from '@material-ui/icons/LocalCafeOutlined';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';

import { Utils } from '../../../resources/index';

import { List } from '../../../ui/index';

class ListSummary extends Component {
  renderIcons(type) {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    switch (type) {
      case 'nextWish':
        return (
          <PhoneIphone
            classes={transformClasses('listSummary_icon1', classes)}
          />
        );
      case 'safetyNet':
        return (
          <Security classes={transformClasses('listSummary_icon1', classes)} />
        );
      case 'familyTarget':
        return (
          <FavoriteBorder
            classes={transformClasses('listSummary_icon1', classes)}
          />
        );
      case 'retirement':
        return (
          <LocalCafeOutlined
            classes={transformClasses('listSummary_icon1', classes)}
          />
        );
      default:
        return (
          <PhoneIphone
            classes={transformClasses('listSummary_icon1', classes)}
          />
        );
    }
  }

  renderSummary() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const summary = this.props.data.map(element => {
      return (
        <ListItem
          key={element.target._id}
          {...config.listItem}
          classes={transformClasses('listSummary_listItem1', classes)}
        >
          <Card classes={transformClasses('listSummary_card1', classes)}>
            <CardContent
              {...config.cardContent1}
              classes={transformClasses('listSummary_cardContent1', classes)}
            >
              {this.renderIcons(element.target.type)}
              <Typography
                {...config.typography1}
                classes={transformClasses('listSummary_typography1', classes)}
              >
                {element.savingMessage.explanation}
              </Typography>
              <Typography
                {...config.typography2}
                classes={transformClasses('listSummary_typography2', classes)}
              >
                {element.savingMessage.explanationTitle}
              </Typography>
              <Typography
                {...config.typography3}
                classes={transformClasses('listSummary_typography3', classes)}
              >
                {Utils.formatCurrency(element.target.progress.completed)}
              </Typography>
            </CardContent>
          </Card>
        </ListItem>
      );
    });
    return summary;
  }

  render() {
    const { classes, listConfig } = this.props;
    const config = {
      ...listConfig,
      children: this.renderSummary(),
    };
    return <List config={config} classes={classes} />;
  }
}

export default ListSummary;
