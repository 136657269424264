import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { Utils } from '../../../resources/index';

class ListTargetsMovementsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: null,
    };
  }

  renderListItems() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.movementLines.map(element => {
      const startDate = new Date(element.ruleExecution.startDate);
      const endDate = new Date(element.ruleExecution.endDate);
      return (
        <ListItem
          {...config.listItem1}
          key={element._id}
          classes={transformClasses(
            'listTargetMovementsDetails_listItem1',
            classes,
          )}
        >
          <div className={classes.pinkSquare} />
          <div className={classes.movementsDetails_confe_container}>
            <div className={classes.movementsDetails_concepto_container}>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'listTargetMovementsDetails_typography1',
                  classes,
                )}
              >
                {element.concept}
              </Typography>
            </div>
            <div className={classes.movementsDetails_fecha_container}>
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'listTargetMovementsDetails_typography2',
                  classes,
                )}
              >
                {'desde el ' +
                  startDate.getUTCDate() +
                  ' del ' +
                  (startDate.getUTCMonth() + 1) +
                  ' al ' +
                  endDate.getUTCDate() +
                  ' del ' +
                  (endDate.getUTCMonth() + 1)}
              </Typography>
            </div>
          </div>
          <div className={classes.movementsDetails_ammount_container}>
            <Typography
              {...config.typography3}
              classes={transformClasses(
                'listTargetMovementsDetails_typography3',
                classes,
              )}
            >
              {Utils.formatCurrency(element.amount)}
            </Typography>
          </div>
          <div className={classes.movementsDetails_icon_container}>
            <IconButton
              {...config.iconButton1}
              classes={transformClasses(
                'listTargetMovementsDetails_iconButton1',
                classes,
              )}
              onClick={() =>
                this.props.goToLines(
                  element.ruleExecution.ruleValue._id,
                  element.ruleExecution,
                )
              }
            >
              <MoreVert color='primary' />
            </IconButton>
          </div>
        </ListItem>
      );
    });
    return elements;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.listTargetMovementsDetails_divListContainer}>
        <List children={this.renderListItems()} />
      </div>
    );
  }
}

export default ListTargetsMovementsDetails;
