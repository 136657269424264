import styled from 'styled-components/macro';

export const PensionTransferWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  padding: 5px;
  box-shadow: 0px 1px 2px black;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  color: white;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  color: white;
`;
export const Text = styled.p`
  margin: 0px;
  padding: 0px;
`;
export const Span = styled.span`
  font-weight: bold;
`;
