import React from 'react';

import image from '../../../common/img/funds/retirement/conservador-bars2024.png';
import { Image } from 'coinscrap-webapp-core';

export default function () {
  return (
    <div className='ct-chart-bar'>
      <Image src={image} style={{ width: '100%', height: 'auto' }} />
    </div>
  );
}
