import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLef from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';
// import {
//   ListTargetMovementsDetailsLines,
//   ListTargetMovementsDetailsLinesConfig,
//   ListTargetMovementsDetailsLinesStyles,
// } from '../../core/
import { ListTargetMovementsDetailsLines } from '../../src-core/targets/index';
import { ListTargetMovementsDetailsLinesConfig } from '../../src-core/targets/index';
import { ListTargetMovementsDetailsLinesStyles } from '../../src-core/targets/index';

//import { Utils } from '../../core/
import { Utils } from '../../src-core/resources/index';

import { followupTarget_strings } from './text';

const styles = theme => ({
  ...ListTargetMovementsDetailsLinesStyles(theme),
  followupMovementsDetails_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  followupMovementsDetailsLines_button: {
    padding: 'unset',
    color: theme.palette.primary.main,
  },
  followupMovementsDetailsLines_headerContainer: {
    display: 'flex',
    backgroundColor: 'rgba(255,255,255,0.098)',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  followup_headerTitle_root: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: -6,
  },
  listTargetMovementsDetailsLines_iconButton1_root: {
    padding: 'unset',
  },
  listTargetMovementsDetailsLines_typography4_root: {
    padding: 12,
    fontSize: 12,
    fontWeight: 400,
    marginBottom: -6,
  },
  listTargetMovementsDetailsLines_typography1_root: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: -6,
    color: theme.palette.primary.main,
  },
  listTargetMovementsDetailsLines_typography2_root: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: -6,
    textTransform: 'lowercase',
    color: theme.palette.primary.main,
  },
  listTargetMovementsDetailsLines_typography3_root: {
    fontSize: 24,
    fontWeight: 200,
    marginBottom: -6,
  },
  listTargetMovementsDetailsLines_listItem1_root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
  movementsDetailsLines_confe_container: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  movementsDetailsLines_concepto_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movementsDetailsLines_fecha_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movementsDetailsLines_ammount_container: {
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  movementsDetailsLines_icon_container: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pinkSquare: {
    height: '8px',
    width: '8px',
    backgroundColor: theme.palette.secondary.main,
    marginRight: '8px',
  },
});

class FollowupMovementsDetailsLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: null,
      type: null,
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  getDetail() {
    const movementID = this.props.match.params.idMovement;
    const { baseUrl, token } = this.props;
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.ruleExecution.transactions !== undefined) {
        this.setState({
          lines: this.props.location.state.ruleExecution.transactions,
          type: 'transactions',
        });
      } else {
        this.setState({
          lines: this.props.location.state.ruleExecution.lines,
          type: 'lines',
        });
      }
    } else {
      if (movementID === 'next') {
        TargetsAPI.nextMovement(baseUrl, this.props.match.params.id, token)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw response.status;
            }
          })
          .then(data => {
            let transactions = null;
            data.movementLines.map(element => {
              if (
                element.ruleExecution.ruleValue._id ===
                this.props.match.params.idLine
              ) {
                transactions = element.ruleExecution.transactions;
              }
              return null;
            });
            this.setState({
              lines: transactions,
            });
          })
          .catch(error => {
            console.log('error recuperando los movimientos de usuario', error);
          });
      } else {
        TargetsAPI.getMovementDetail(baseUrl, movementID, token)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw response.status;
            }
          })
          .then(data => {
            let transactions = null;
            data.movementLines.map(element => {
              if (element._id === this.props.match.params.idLine) {
                transactions = element.ruleExecution.transactions;
              }
              return null;
            });
            this.setState({
              transactions: transactions,
            });
          })
          .catch(error => {
            console.log(
              'error consiguiendo los detalles de movimiento: ',
              error,
            );
          });
      }
    }
  }

  goBack() {
    this.props.history.replace(
      '/followup/' +
        this.props.match.params.id +
        '/Movimientos/' +
        this.props.match.params.idMovement +
        '/detalles',
    );
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.lines !== null ? (
      <div>
        <div className={classes.followupMovementsDetailsLines_headerContainer}>
          <Typography
            variant='body1'
            color='primary'
            classes={transformClasses('followup_headerTitle', classes)}
          >
            {
              followupTarget_strings['es']
                .followupMovementsDetailsLines_header_text
            }
          </Typography>
          <IconButton
            className={classes.followupMovementsDetailsLines_button}
            onClick={this.goBack.bind(this)}
          >
            <KeyboardArrowLef />
          </IconButton>
        </div>
        <ListTargetMovementsDetailsLines
          classes={classes}
          targetID={this.props.match.params.id}
          config={ListTargetMovementsDetailsLinesConfig}
          lines={this.state.lines}
          type={this.state.type}
        />
      </div>
    ) : (
      <div className={classes.followupMovementsDetails_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  FollowupMovementsDetailsLines,
);
