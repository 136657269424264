function portfolio() {
  return {
    title: 'Dinámico',
    $$hashKey: 'object:116',
    benchmark: { id: 'imff', name: 'FI Renta Fija Mixta Internacional' },
    equity: 25,
    historicPerformance: 5.8881,
    id: 'a2108585-04e1-11e7-8555-080027628e9b',
    minAmount: 100,
    minTimeframe: 3,
    name: 'Dinámico',
    performance: 6.17,
    productId: 'evo_caser_unit_linked',
    recommended: false,
    selected: false,
    tolerance: 3,
    typicalDeviation: {
      1: 7.6925,
      2: 5.4359,
      3: 4.5049,
      4: 4.137,
      5: 3.8792,
      6: 3.5913,
      7: 3.4178,
      8: 3.3467,
      9: 3.2334,
      10: 3.0911,
      11: 3.0112,
      12: 2.92,
      13: 2.8142,
      14: 2.7129,
      15: 2.6264,
      16: 2.5262,
      17: 2.4026,
      18: 2.2779,
      19: 2.1541,
      20: 2.0281,
      21: 1.9107,
      22: 1.8208,
      23: 1.7183,
      24: 1.5825,
      25: 1.4583,
      26: 1.3318,
      27: 1.1884,
      28: 1.0497,
      29: 0.9308,
      30: 0.7743,
      31: 0.5828,
      32: 0.4724,
      33: 0.3919,
      34: 0.2198,
      35: 0.2138,
      36: 0.25,
      37: 0.2282,
      38: 0.2213,
      39: 0.2029,
      40: 0.1758,
      default: 0.1758,
    },
  };
}

export default portfolio;
