import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from '@material-ui/core/Modal';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

import { Utils } from '../../src-core/resources/index';

//import Body from '../../components/MyBody';
import Footer from '../../components/MyFooter';

import accounts_strings from './text.js';

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: null,
      tab: 0,
      open: false,
      bankToggle: null,
      showBanksRedondeos: null,
      showBanksCargos: null,
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  isSelected(array, id) {
    let booleano = false;
    array.map(element => {
      if (element._id === id) {
        booleano = true;
      }
      return true;
    });
    return booleano;
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  toggleBank(index) {
    const arr = this.state.bankToggle.slice();
    arr[index] = !arr[index];
    this.setState({
      bankToggle: arr.slice(),
    });
  }

  componentDidMount() {
    const obj = {};
    const arr = [];
    const objBanksRedondeos = {};
    const objBanksCargos = {};
    this.props.banks.map(bank => {
      arr.push(false);
      objBanksRedondeos[bank._id] = false;
      objBanksCargos[bank._id] = false;
      bank.accounts.map(account => {
        obj[account._id] = {
          bank: bank._id,
          idBank: bank.idBank,
          type: account.type,
          name: account.name,
          ibanText: account.ibanText,
          panText: account.panText,
          currencyName: account.currencyName,
          currencyBalance: account.currencyBalance,
          isHidden: account.isHidden,
          isSelected: this.isSelected(
            account.targetsSelected,
            this.props.idTarget,
          ),
          isMain: this.isSelected(account.targetsMain, this.props.idTarget),
          witouthAcces: account.withoutAccessToTransactions,
          balance: account.currencyBalance,
        };
        if (!account.isHidden && account.type === 'account') {
          objBanksCargos[bank._id] = true;
        }
        if (!account.isHidden) {
          objBanksRedondeos[bank._id] = true;
        }
        return true;
      });
      return true;
    });
    this.setState({
      accounts: obj,
      bankToggle: arr.slice(),
      showBanksRedondeos: objBanksRedondeos,
      showBanksCargos: objBanksCargos,
    });
  }

  getNumCuenta(account) {
    // ºº EVO envia los números de tarjeta
    // Ojo tambien se utiliza en ActivateAccounts
    let numCuenta = '';
    if (account.type === 'card' && account.idBank === 'evobanco_direct') {
      numCuenta = '****';
    } else {
      if (account.ibanText) {
        numCuenta = account.ibanText;
      } else {
        numCuenta = account.panText;
      }
    }

    //numCuenta = "[" + numCuenta + "] i: " + account.ibanText + " p:" + account.panText + " t:" + account.type + " b:" + account.idBank //+ JSON.stringify(account);
    return numCuenta;
  }

  renderAccountsCargos(id, imageBank) {
    const { classes, config } = this.props,
      transformClasses = Utils.transformClasses;

    const elements = Object.keys(this.state.accounts).map(key => {
      if (
        !this.state.accounts[key].witouthAcces &&
        !this.state.accounts[key].isHidden &&
        this.state.accounts[key].bank === id &&
        this.state.accounts[key].type === 'account'
      ) {
        return (
          <div className={classes.accounts_accounts_container} key={key}>
            <div className={classes.accounts_accounts_leftPanel_container}>
              <img
                alt='bank_img'
                src={imageBank}
                className={classes.accounts_accounts_imageBank}
              />
            </div>
            <div className={classes.accounts_accounts_centerPanel_container}>
              <Typography
                variant='body1'
                color='secondary'
                classes={transformClasses(
                  'activayeAccounts_accounts_title_typography1',
                  classes,
                )}
              >
                {this.state.accounts[key].name}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'activayeAccounts_accounts_account_typography1',
                  classes,
                )}
              >
                {this.getNumCuenta(this.state.accounts[key])}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'activayeAccounts_accounts_currency_typography1',
                  classes,
                )}
              >
                {this.state.accounts[key].currencyBalance +
                  this.state.accounts[key].currencyName}
              </Typography>
            </div>
            <div className={classes.accounts_accounts_rightPanel_container}>
              <Switch
                {...config.switch2}
                checked={
                  this.props.accounts !== undefined
                    ? this.props.accounts[key].isMain
                    : this.state.accounts[key].isMain
                }
                onChange={e =>
                  this.props.handleMain !== undefined
                    ? this.props.handleMain(key, e)
                    : this.handleMain(key, e)
                }
                classes={transformClasses('accounts_switch2', classes)}
              />
            </div>
          </div>
        );
      }
      return true;
    });

    return elements;
  }

  renderAccountsRedondeos(id, imageBank) {
    const { classes, config } = this.props,
      transformClasses = Utils.transformClasses;

    const elements = Object.keys(this.state.accounts).map(key => {
      if (
        !this.state.accounts[key].witouthAcces &&
        !this.state.accounts[key].isHidden &&
        this.state.accounts[key].bank === id
      ) {
        return (
          <div className={classes.accounts_accounts_container} key={key}>
            <div className={classes.accounts_accounts_leftPanel_container}>
              <img
                alt='bank_img'
                src={imageBank}
                className={classes.accounts_accounts_imageBank}
              />
            </div>
            <div className={classes.accounts_accounts_centerPanel_container}>
              <Typography
                variant='body1'
                color='secondary'
                classes={transformClasses(
                  'activayeAccounts_accounts_title_typography1',
                  classes,
                )}
              >
                {this.state.accounts[key].name}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'activayeAccounts_accounts_account_typography1',
                  classes,
                )}
              >
                {this.getNumCuenta(this.state.accounts[key])}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'activayeAccounts_accounts_currency_typography1',
                  classes,
                )}
              >
                {this.state.accounts[key].currencyBalance +
                  this.state.accounts[key].currencyName}
              </Typography>
            </div>
            <div className={classes.accounts_accounts_rightPanel_container}>
              <Switch
                {...config.switch2}
                checked={
                  this.props.accounts !== undefined
                    ? this.props.accounts[key].isSelected
                    : this.state.accounts[key].isSelected
                }
                onChange={e =>
                  this.props.handleSelected !== undefined
                    ? this.props.handleSelected(key, e)
                    : this.handleSelected(key, e)
                }
                classes={transformClasses('accounts_switch2', classes)}
              />
            </div>
          </div>
        );
      }
      return true;
    });
    return elements;
  }

  renderNewData() {
    //console.log('renderNewData banks: ', this.props.banks)
    const elements = this.props.banks.map(element => {
      return this.props.tab === 0 &&
        this.state.showBanksRedondeos[element._id] ? (
        this.renderAccountsRedondeos(element._id, element.image)
      ) : this.props.tab === 1 && this.state.showBanksCargos[element._id] ? (
        this.renderAccountsCargos(element._id, element.image)
      ) : (
        <div key={element._id} />
      );
    });
    return elements;
  }

  renderData() {
    //console.log('actually doing something')
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.banks.map((element, index) => {
      return this.state.tab === 0 &&
        this.state.showBanksRedondeos[element._id] ? (
        <div key={element._id} className={classes.bankContainer}>
          <div
            className={classes.bankContainerTitle}
            onClick={() => this.toggleBank(index)}
          >
            <img
              alt='logo banco'
              src={element.image}
              className={classes.accounts_iconBank}
            />
            <div className={classes.bankContainerTitle_textContainer}>
              <Typography
                {...config.typography1}
                classes={transformClasses('accounts_typography1', classes)}
              >
                {element.name}
              </Typography>
              <Typography
                {...config.typography5}
                classes={transformClasses('accounts_typography5', classes)}
              >
                {this.getTimeDifference(
                  new Date(element.bankAccess.lastUpdate),
                )}
              </Typography>
            </div>
            <IconButton
              {...config.iconButton2}
              color={!this.state.bankToggle[index] ? 'default' : 'secondary'}
              classes={transformClasses(
                'notificationsList_iconButton2',
                classes,
              )}
            >
              <ExpandMore />
            </IconButton>
          </div>
          <Collapse
            {...config.collapse1}
            in={this.state.bankToggle[index]}
            classes={transformClasses('accounts_collapse1', classes)}
          >
            {this.renderAccountsRedondeos(element._id)}
          </Collapse>
        </div>
      ) : this.state.tab === 1 && this.state.showBanksCargos[element._id] ? (
        <div key={element._id} className={classes.bankContainer}>
          <div
            className={classes.bankContainerTitle}
            onClick={() => this.toggleBank(index)}
          >
            <img
              alt='logo banco'
              src={element.image}
              className={classes.accounts_iconBank}
            />
            <div className={classes.bankContainerTitle_textContainer}>
              <Typography
                {...config.typography1}
                classes={transformClasses('accounts_typography1', classes)}
              >
                {element.name}
              </Typography>
              <Typography
                {...config.typography5}
                classes={transformClasses('accounts_typography5', classes)}
              >
                {this.getTimeDifference(
                  new Date(element.bankAccess.lastUpdate),
                )}
              </Typography>
            </div>
            <IconButton
              {...config.iconButton2}
              color={!this.state.bankToggle[index] ? 'default' : 'secondary'}
              classes={transformClasses(
                'notificationsList_iconButton2',
                classes,
              )}
            >
              <ExpandMore />
            </IconButton>
          </div>
          <Collapse
            {...config.collapse1}
            in={this.state.bankToggle[index]}
            classes={transformClasses('accounts_collapse1', classes)}
          >
            {this.renderAccountsCargos(element._id)}
          </Collapse>
        </div>
      ) : (
        <div key={element._id} />
      );
    });
    return elements;
  }

  handleSelected(id) {
    const obj = this.state.accounts;
    obj[id].isSelected = !obj[id].isSelected;
    this.setState({
      accounts: obj,
    });
  }

  handleMain(id) {
    const obj = this.state.accounts;
    Object.keys(obj).map(key => {
      if (key === id) {
        obj[id].isMain = !obj[id].isMain;
      } else {
        obj[key].isMain = false;
      }
      return true;
    });
    this.setState({
      accounts: obj,
    });
  }

  handleSave() {
    const data = {
      accounts: [],
      mainAccount: null,
    };
    Object.keys(this.state.accounts).map(key => {
      if (this.state.accounts[key].isSelected) {
        data.accounts.push(key);
      }
      if (this.state.accounts[key].isMain) {
        data.mainAccount = key;
      }
      return true;
    });
    if (data.mainAccount !== null && data.accounts.length >= 1) {
      this.props.setAccounts(data);
      this.props.history.push(this.props.next);
    } else {
      this.setState({
        open: true,
      });
    }
  }

  handleTabChange(value) {
    this.setState({
      tab: value,
    });
  }

  getTimeDifference(time) {
    const currentTime = new Date();
    let diff = (currentTime.getTime() - time.getTime()) / 1000 / 60;
    if (Math.abs(Math.round(diff)) < 60) {
      return (
        accounts_strings['es'].accounts_time_text1 +
        ' ' +
        Math.abs(Math.round(diff)) +
        ' ' +
        accounts_strings['es'].accounts_time_units1
      );
    } else {
      diff /= 60;
      if (Math.abs(Math.round(diff)) < 24) {
        return (
          accounts_strings['es'].accounts_time_text1 +
          ' ' +
          Math.abs(Math.round(diff)) +
          ' ' +
          accounts_strings['es'].accounts_time_units2
        );
      } else {
        diff /= 24;
        return (
          accounts_strings['es'].accounts_time_text1 +
          ' ' +
          Math.abs(Math.round(diff)) +
          ' ' +
          accounts_strings['es'].accounts_time_units3
        );
      }
    }
  }

  getCurrencyType(currency) {
    switch (currency) {
      case 'EUR':
        return '€';
      default:
        return '';
    }
  }

  renderTabs() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.accounts_tabsDiv}>
        <Tabs
          {...config.tabs1}
          value={this.state.tab}
          onChange={(e, value) => this.handleTabChange(value)}
          classes={transformClasses('accounts_tabs1', classes)}
        >
          <Tab
            {...config.tab1}
            label={accounts_strings['es'].accounts_label_text1}
            classes={transformClasses('accounts_tab1', classes)}
          />
          <Tab
            {...config.tab2}
            label={accounts_strings['es'].accounts_label_text2}
            classes={transformClasses('accounts_tab2', classes)}
          />
        </Tabs>
      </div>
    );
  }

  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.accounts !== null ? (
      <Fragment>
        <div align='top'>{this.renderNewData()}</div>

        {this.props.accounts === undefined ? (
          <Footer>
            <Button
              variant='contained'
              color='secondary'
              className={classes.targetBasic_button1}
              onClick={this.handleSave.bind(this)}
            >
              Guardar Cambios
            </Button>
          </Footer>
        ) : (
          <div />
        )}

        <Modal open={this.state.open} className={classes.accounts_modal}>
          <div className={classes.accounts_modalDiv}>
            <Typography {...config.typography3}>
              {accounts_strings['es'].accounts_modal_text1}
            </Typography>
            <div className={classes.accounts_buttonDiv}>
              <Button
                {...config.button2}
                classes={transformClasses('accounts_button2', classes)}
                onClick={this.handleClose}
              >
                {accounts_strings['es'].accounts_button_text2}
              </Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    ) : (
      this.props.renderLoading()
    );
  }
}

export default Accounts;

Accounts.propTypes = {
  idTarget: PropTypes.string,
  classes: PropTypes.object,
  renderLoading: PropTypes.func,
};

/*

<div className={classes.accounts_button_container}>
          <Button
            {...config.button1}
            onClick={this.handleSave}
            classes={transformClasses('accounts_button1', classes)}
          >
            {accounts_strings['es'].accounts_button_text1}
          </Button>
        </div>

        */
