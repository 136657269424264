import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';

import Icons from '../../components/MyIcon.config';

class HomeHeaderConfigInfo extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    //console.log("HomeHeaderConfigInfo", props);
  }

  onClickConfig() {
    //console.log("HomeHeaderConfigInfo2", this);
    //console.log("HomeHeaderConfigInfo3", this.props);
    this.props.history.push('/menu');
  }

  onClickInfo() {
    this.props.history.push('/info');
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: -38,
          marginBotton: -15,
          paddingLeft: 5,
          paddingBottom: 14,
          paddingRight: 5,
        }}
      >
        <SvgIcon
          color='primary'
          viewBox={Icons.config.viewBox}
          onClick={() => this.onClickConfig()}
          style={{
            marginLeft: 10,
            height: 20,
          }}
        >
          <path
            //fillRule='evenodd'
            d={Icons.config.data}
          />
        </SvgIcon>
        <SvgIcon
          color='primary'
          onClick={() => this.onClickInfo()}
          style={{
            marginRight: 10,
            height: 20,
          }}
        >
          <path d={Icons.info.data} />
        </SvgIcon>
      </div>
    );
  }
}

HomeHeaderConfigInfo.propTypes = {
  history: PropTypes.object,
};

export default HomeHeaderConfigInfo;
