import React, { Component } from 'react';
// import {
//   ListTargetsTypes,
//   ListTargetsTypesStyles,
//   ListTargetsTypesConfig,
// } from '../../core/
import { ListTargetsTypes } from '../../src-core/targets/index';
//core/src/targets/react/listTargetsTypes/listTargetsTypes.component';
import { ListTargetsTypesConfig } from '../../src-core/targets/index';
import { ListTargetsTypesStyles } from '../../src-core/targets/index';

import { withStyles } from '@material-ui/core/styles';
//import { ListConfig, ListStyles } from '../../core/
import { ListConfig } from '../../src-core/ui/index';
import { ListStyles } from '../../src-core/ui/index';

//import { TargetsAPI, UsersAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';
import { UsersAPI } from '../../src-core/sdk/index';

import CircularProgress from '@material-ui/core/CircularProgress';
import Header from './header.component';

const styles = theme => ({
  ...ListTargetsTypesStyles(theme),
  ...ListStyles(theme),
  targetsTypes_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  targetsTypes_movilScreen: {
    maxWidth: '100%',
    margin: 'auto',
    maxHeight: 'inherit',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
  targetsTypes_icon: {
    float: 'left',
  },
  targetsTypes_listTypes: {
    maxHeight: 'inherit',
    overflow: 'auto',
  },
  targetsTypes_listContainer: {
    maxHeight: '90vh',
    minHeight: 511,
    overflow: 'auto',
  },
});

class TargetsTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: null,
      user: null,
      doSurvey: false,
      surveyFetched: false,
    };
  }

  async getTypes() {
    const response = await TargetsAPI.getTypes(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      this.setState({
        types: data,
      });
    } else {
      console.log('error redcuperando los tipos de objetivo ', response.status);
    }
  }

  async getUserData() {
    const response = await UsersAPI.getUserData(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      this.setState({
        user: data,
      });
    } else {
      console.log('error recuperando datos de usuario: ', response.status);
    }
  }

  async getSurveys() {
    const response = await UsersAPI.getSurvey(
      this.props.baseUrl,
      'idd-evo',
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      const response2 = await UsersAPI.getUserSurveyResponse(
        this.props.baseUrl,
        'me',
        data._id,
        this.props.token,
      );
      if (response2.status === 200) {
        const data2 = await response2.json();
        if (!data2.finished) {
          this.setState({
            doSurvey: true,
            surveyFetched: true,
          });
        } else {
          this.setState({
            surveyFetched: true,
          });
        }
      } else if (response2.status === 404) {
        this.setState({
          doSurvey: true,
          surveyFetched: true,
        });
      } else {
        console.log(
          'error recuperando la respuesta de usuario a survey: ',
          response2.status,
        );
      }
    } else {
      console.log('error recuperando la survey: ', response.status);
    }
  }

  componentDidMount() {
    this.getTypes();
    this.getUserData();
    this.getSurveys();
  }

  render() {
    const { classes, history } = this.props;
    const headerConfig = {
      icon: {
        color: 'primary',
      },
    };
    return (
      <div className={classes.targetsTypes_movilScreen}>
        <Header
          classes={classes}
          config={headerConfig}
          history={history}
          prev='/home/summary'
        />
        {this.state.types !== null &&
        this.state.user !== null &&
        this.state.surveyFetched ? (
          <div className={classes.targetsTypes_listContainer}>
            <ListTargetsTypes
              types={this.state.types}
              config={ListTargetsTypesConfig}
              classes={classes}
              listConfig={ListConfig}
              base='/create/target/'
              history={this.props.history}
              user={this.state.user}
              doSurvey={this.state.doSurvey}
            />
          </div>
        ) : (
          <div className={classes.targetsTypes_loadingContainer}>
            <CircularProgress color='secondary' />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TargetsTypes);
