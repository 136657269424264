import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import Currency from '../../../components/Currency';

import MyText from '../../../components/MyText';
import Flow from '../../../components/Flow';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import Config from './ScenariosConfig';

function TargetPerfilSimulacionInfoCostes(props) {
  console.log('TargetPerfilSimulacionInfoCostes', props);
  const params = props.location.state;
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const textColor = 'black';
  // const rentabilidadAdicional =
  //   params.datosEscenario.finalHasta -
  //   params.aportado -
  //   (params.datosEscenarioStandard.final - params.aportado);

  // NO SE UTILIZA HASTA SABER CUAL EL RENDIMIENTO DEL MERCADO
  function ChartHorizontalBar(props) {
    const rentabilidadBruta =
      (
        (params.datosEscenario.finalHasta -
          params.aportado +
          params.datosEscenario.costesTotalesHasta) /
        100
      ).toFixed(0) * 100;
    const coste = params.datosEscenario.costesTotalesHasta;
    const heightBar = 10;

    let numMarcas = Math.round(rentabilidadBruta / 500) + 1;
    if (numMarcas < 1) numMarcas = 1;
    if (numMarcas > 5) numMarcas = 5;

    if (rentabilidadBruta % 100 > 1) ++numMarcas;

    const partes = (rentabilidadBruta / numMarcas).toFixed();

    // Hacer proporcionales las barrar con respecto al total
    const finizensPorcRenta = (rentabilidadBruta * 100) / (numMarcas * partes);
    const finizensPorcCoste = (coste * 100) / (numMarcas * partes);
    //ºº Meti lo q se me ocurrió como valores de la competencia!!
    const otrosPorcRenta = finizensPorcRenta / 3;
    const otrosPorcCoste = finizensPorcCoste * 3;
    console.log('ChartHorizontalBar', {
      rentabilidadBruta,
      coste,
      finizensPorcCoste,
      finizensPorcRenta,
      otrosPorcCoste,
      otrosPorcRenta,
      numMarcas,
      partes,
    });

    function getMarcas() {
      let marcas = [];
      let widthMarca = 100 / numMarcas;

      marcas.push(
        <div
          key={100}
          style={{
            width: 1,
            borderRightStyle: 'dotted',
            borderRightColor: 'grey',
            borderRightWidth: 1,
            marginBottom: 5,
            display: 'flex',
            alignItems: 'flex-end',
            //justifyContent: 'flex-end'
          }}
        >
          <div
            style={{
              top: 18,
              position: 'relative',
              fontSize: 11,
            }}
          >
            0 €
          </div>
        </div>,
      );

      for (let i = 0; i < numMarcas; i++) {
        marcas.push(
          <div
            key={i}
            style={{
              width: widthMarca + '%',
              borderRightStyle: 'dotted',
              borderRightColor: 'grey',
              borderRightWidth: 1,
              marginBottom: 5,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                top: 18,
                position: 'relative',
                fontSize: 11,
              }}
            >
              {(i + 1) * partes} €
            </div>
          </div>,
        );
      }

      return marcas;
    }

    return (
      <div
        style={{
          with: '100%',
          fontFamily: 'Calibre',
          fontSize: 11,
          color: 'gray',
          marginTop: 15,
        }}
      >
        <MyText
          style={{
            color: 'black',
            fontWeight: 600,
            marginBottom: 10,
          }}
        >
          Finizens
        </MyText>
        <div
          style={{
            width: finizensPorcCoste + '%',
            height: heightBar,
            backgroundColor: '#f52a7b',
            borderRadius: 9,
            marginBottom: 5,
          }}
        />
        <div
          style={{
            width: finizensPorcRenta + '%',
            height: heightBar,
            backgroundColor: '#50d2c2',
            borderRadius: 9,
            marginBottom: 20,
          }}
        />
        <div
          style={{
            width: otrosPorcCoste + '%',
            height: heightBar,
            backgroundColor: '#f52a7b',
            borderRadius: 9,
            marginBottom: 5,
          }}
        />
        <div
          style={{
            width: otrosPorcRenta + '%',
            height: heightBar,
            backgroundColor: '#50d2c2',
            borderRadius: 9,
            marginBottom: 5,
          }}
        />

        <div
          style={{
            marginTop: -79,
            display: 'flex',
            flexDirection: 'row',
            height: 84,
          }}
        >
          {getMarcas()}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontSize: 8,
            marginTop: -81,
            color: 'silver',
          }}
        >
          <div
            style={{
              width: 12,
              height: 12,
              float: 'left',
              marginRight: 10,
              borderRadius: 10,
              boxShadow: '1px 1px 1px 1px',
              backgroundColor: '#f52a7b',
            }}
          />
          <MyText
            style={{
              color: 'gray',
            }}
          >
            Coste
          </MyText>
          <div
            style={{
              width: 12,
              height: 12,
              float: 'left',
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 10,
              boxShadow: '1px 1px 1px 1px',
              backgroundColor: '#50d2c2',
            }}
          />
          <MyText
            style={{
              color: 'gray',
            }}
          >
            Rentabilidad
          </MyText>
        </div>
        <MyText
          style={{
            color: 'black',
            marginTop: 85,
            fontWeight: 600,
          }}
        >
          Media del mercado
        </MyText>
      </div>
    );
  }

  function ExpandItem(props) {
    return (
      <Fragment>
        <CardActions
          disableSpacing
          onClick={() => props.onClick()}
          style={{
            width: '100%',
            borderTopWidth: 1,
            borderTopStyle: 'dotted',
            borderTopColor: 'silver',
          }}
        >
          <MyText
            style={{
              fontSize: 27,
              width: '28%',
              color: textColor,
            }}
          >
            {props.porc} %
          </MyText>
          <MyText
            id={props.id}
            style={{
              fontSize: 20,
              width: '62%',
              lineHeight: 1,
              paddingLeft: 5,
              color: textColor,
            }}
          >
            {props.title}
          </MyText>
          <IconButton
            style={{
              width: '9%',
              color: textColor,
            }}
            //aria-expanded={openCollapse1}
            aria-label='show more'
          >
            <ExpandMoreIcon
              style={
                props.show
                  ? {
                      transform: 'rotate(180deg)',
                      transition: 'transform 2s',
                    }
                  : {
                      transform: 'rotate(0deg)',
                      transition: 'transform 2s',
                    }
              }
            />
          </IconButton>
        </CardActions>
        <Collapse
          in={props.show}
          style={{
            width: '100%',
            textAlign: 'left',
            fontFamily: 'Calibre',
            color: textColor,
          }}
        >
          {props.children}
        </Collapse>
      </Fragment>
    );
  }

  let costs = new Config(params.productName);
  costs = costs.getBaseCosts();
  console.log('costs', costs);
  return (
    <Fragment>
      <Header modal={true} title='Costes' history={props.history} />
      <Body vAlign='top'>
        {/*
        <MyText
          style={{
            marginBottom: 20,
          }}
        >
          Generando, en tu caso, una{' '}
          <strong>
            rentabilidad adicional estimada entre{' '}
            {Currency(
              (params.datosEscenario.finalDesde / 100).toFixed(0) * 100 -
                params.aportado,
            )}
            {' y '}
            {Currency(
              (params.datosEscenario.finalHasta / 100).toFixed(0) * 100 -
                params.aportado,
            )}
          </strong>{' '}
          para un periodo de {params.años} años.
        </MyText>
        */}

        <div
          style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'white',
            padding: 15,
            backgroundColor: '#e9e9e9',
            color: 'black',
            borderRadius: 5,
          }}
        >
          <MyText
            variant='h1'
            style={{
              color: 'black',
            }}
          >
            Costes del Plan de Inversión Inteligente
          </MyText>

          <MyText
            style={{
              color: 'black',
            }}
          >
            Se compone de una comisión promedio de{' '}
            <strong className='ng-binding'>{costs.total}%</strong> anual más la{' '}
            <strong
              id='primaSeguro'
              variant='link'
              style={{
                marginBottom: 20,
                color: 'black',
                textDecoration: 'underline',
              }}
              onClick={() => {
                Flow.navigate('/product/simulator/insurance', props, {
                  datosEscenario: params.datosEscenario,
                });
              }}
            >
              prima del seguro
            </strong>
          </MyText>

          <ExpandItem
            id='asesoramientoExpand'
            show={collapse1}
            onClick={() => setCollapse1(!collapse1)}
            porc={costs.management.total}
            title='Asesoramiento, gestión y comercialización'
          >
            <ul className='bulleted-list text-left b-space-l ng-scope'>
              <li className='bulleted-list__item'>
                <strong className='ng-binding'>
                  {costs.management.finizens}%
                </strong>{' '}
                sobre el patrimonio gestionado
                <ul className='bulleted-list'>
                  <li className='bulleted-list__item t-space-m'>
                    Comisión que cobra <strong>Finizens</strong>
                  </li>
                  <li className='bulleted-list__item t-space-m'>
                    <strong>Asesoramiento a Caser</strong> en la gestión de las
                    inversiones relacionadas con tu plan de inversión
                    inteligente y provisión de la plataforma tecnológica.
                  </li>
                  <li className='bulleted-list__item t-space-m'>
                    Se descuenta diariamente la parte proporcional:
                    <p className='ng-binding'>
                      365 / ({costs.management.finizens}% * capital gestionado).
                    </p>
                  </li>
                </ul>
              </li>
              <li className='bulleted-list__item t-space-l'>
                <strong className='ng-binding'>
                  {costs.management.custodian}%
                </strong>{' '}
                sobre el patrimonio gestionado
                <ul className='bulleted-list'>
                  <li className='bulleted-list__item t-space-m'>
                    Comisión que cobra <strong>Caser</strong>
                  </li>
                  <li className='bulleted-list__item t-space-m'>
                    Comisión de <strong>gestión de las carteras</strong>. Es una
                    comisión de administración necesaria para mantener la
                    actividad de la aseguradora y dar soporte al seguro de
                    vida-ahorro (“Unit-Linked”)
                  </li>
                  <li className='bulleted-list__item t-space-m'>
                    Se descuenta diariamente la parte proporcional:
                    <p className='ng-binding'>
                      365 / ({costs.management.custodian}% * capital
                      gestionado).
                    </p>
                  </li>
                </ul>
              </li>
              <li className='bulleted-list__item t-space-l'>
                <strong className='ng-binding'>{costs.management.evo}%</strong>{' '}
                sobre el patrimonio gestionado
                <ul className='bulleted-list'>
                  <li className='bulleted-list__item t-space-m'>
                    Comisión que cobra <strong>EVO</strong>
                  </li>
                  <li className='bulleted-list__item t-space-m'>
                    <strong>Comercialización</strong> del producto de seguro de
                    vida y ahorro (“unit-linked”) de Caser.
                  </li>
                  <li className='bulleted-list__item t-space-m'>
                    Se descuenta diariamente la parte proporcional:
                    <p className='ng-binding'>
                      365 / ({costs.management.evo}% * capital gestionado).
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </ExpandItem>

          <ExpandItem
            id='corrientesExpand'
            show={collapse2}
            onClick={() => setCollapse2(!collapse2)}
            porc={costs.financialProducts}
            title='Gastos corrientes de los productos financieros'
          >
            <ul className='bulleted-list text-left b-space-l ng-scope'>
              <li className='bulleted-list__item'>
                <strong className='ng-binding'>
                  {costs.financialProducts}%**
                </strong>{' '}
                sobre el patrimonio gestionado.
              </li>
              <li className='bulleted-list__item t-space-m'>
                Comisión que cobra{' '}
                <strong>
                  las gestoras de los fondos de inversión utilizados
                </strong>
                .
              </li>
              <li className='bulleted-list__item t-space-m'>
                Se compone de todos los{' '}
                <strong>
                  gastos soportados por los fondos de inversión y ETFs
                </strong>{' '}
                utilizados para construir las carteras.
              </li>
              <li className='bulleted-list__item t-space-m'>
                Se descuenta diariamente la fracción correspondiente.
              </li>
            </ul>
            <span>
              **Fuente: Morningstar Direct, Cecabank, Gastos estimados para una
              cartera de riesgo medio.
            </span>
          </ExpandItem>

          <ExpandItem
            id='intermediacionExpand'
            show={collapse3}
            onClick={() => setCollapse3(!collapse3)}
            porc={costs.intermediaries}
            title='Gastos de intermediarios'
          >
            <ul className='bulleted-list text-left b-space-l ng-scope'>
              <li className='bulleted-list__item'>
                <strong className='ng-binding'>
                  {costs.intermediaries}%***
                </strong>
              </li>
              <li className='bulleted-list__item t-space-m'>
                Comisión que cobran los{' '}
                <strong>intermediarios financieros</strong>.
              </li>
              <li className='bulleted-list__item t-space-m'>
                Son principalmente costes relacionados con las operaciones de
                compraventa de valores, es decir con las transacciones
                efectuadas para construir, optimizar (“rebalancing”) y liquidar
                tus carteras.
              </li>
              <li className='bulleted-list__item t-space-m'>
                Se descuenta según se realice una transacción.
              </li>
            </ul>
            <span id='finIntermediacionExpand'>
              ***Fuente: Ahorro Corporación Financiera. Gastos estimados para
              una cartera de riesgo medio, con un 20% de rebalanceo medio anual
              y una duración de la inversión de 10 años.
            </span>
          </ExpandItem>
        </div>

        <div
          style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'white',
            padding: 15,
            backgroundColor: '#e9e9e9',
            color: 'black',
            borderRadius: 5,
            marginTop: 20,
          }}
        >
          {/*

          <MyText
            style={{
              color: 'black',
            }}
          >
            Para tu aportación de {Currency(params.aportado)} a lo largo de{' '}
            {params.años} años previstos supondría aproximadamente
          </MyText>
          <MyText
            style={{
              fontSize: 25,
              color: 'black',
            }}
          >
            {Currency(rentabilidadAdicional, -2)} de rentabilidad adicional
          </MyText>
          <MyText
            id='grafica'
            style={{
              color: 'black',
            }}
          >
            El ahorro en costes hace que se dispare tu rentabilidad
          </MyText>
          <ChartHorizontalBar />
          */}
          <section
            style={{
              color: 'black',
              textAlign: 'left',
              fontFamily: 'Calibre',
            }}
          >
            <MyText
              variant='h1'
              style={{
                color: 'black',
                textAlign: 'center',
              }}
            >
              ¿Cómo podemos tener unas tarifas tan bajas?
            </MyText>
            <ol className='bulleted-list--numeric space-m text-left'>
              <li className='bulleted-list--numeric__item space-m'>
                Seleccionamos solo los{' '}
                <strong>
                  fondos de gestión pasiva{' '}
                  <span
                    ng-if='!ctrl.portfolio.getProductSettings().isInvestmentPlan()'
                    className='ng-scope'
                  >
                    y ETFs
                  </span>{' '}
                  más eficientes y económicos
                </strong>{' '}
                del mercado.
              </li>
              <li className='bulleted-list--numeric__item space-m'>
                <strong>Mitigamos los costes de transacción</strong> gracias a
                nuestros procesos de rebalanceo inteligentes.
              </li>
              <li className='bulleted-list--numeric__item space-m'>
                <strong>Reducimos nuestros costes de estructura</strong>{' '}
                mediante el uso de nuestra plataforma tecnológica.
              </li>
              <li className='bulleted-list--numeric__item space-m'>
                <strong>Minimizamos los costes de gestión</strong> al adoptar
                algoritmos de inversión sistemática.
              </li>
            </ol>
          </section>
        </div>
      </Body>
    </Fragment>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 32,
    width: '100%',
    height: '100%',
  },
  expandDown: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandUp: {
    transform: 'rotate(180deg)',
  },
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(
  TargetPerfilSimulacionInfoCostes,
);
