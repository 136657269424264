import React from 'react';
import Lottie from 'react-lottie';

// import { withStyles } from '@material-ui/core/styles';

import * as ahorroHotDiffLottie from '../components/Lottie/ahorroHotDiff.json';
import * as ahorroHotLottie from '../components/Lottie/ahorroHot.json';
import * as cafeDiffLottie from '../components/Lottie/cafeDiff.json';
import * as calendarioLottie from '../components/Lottie/calendario.json';
import * as camisetaLottie from '../components/Lottie/camiseta.json';
import * as cerditoLottie from '../components/Lottie/cerdito.json';
import * as lazoLottie from '../components/Lottie/lazo.json';
import * as maletinLottie from '../components/Lottie/maletin.json';
import * as tabacoDiffLottie from '../components/Lottie/tabacoDiff.json';
import * as quedateEnCasaLottie from '../components/Lottie/quedateEnCasa.json';
import * as movilLottie from '../components/Lottie/movil.json';
import * as manoLottie from '../components/Lottie/mano.json';
import * as mountainLottie from '../components/Lottie/mountain.json';
import * as pacmanLottie from '../components/Lottie/pacman.json';

export default function MyAnimation({
  name,
  color,
  isPaused = false,
  height = 50,
  width = 50,
  margin,
}) {
  let className = '';

  if (name !== 'COVID_SAVING' && color === 'white') {
    className = 'animation-white';
  }

  return (
    <div
      className={className}
      style={{
        // border: '1px solid',
        margin: margin,
      }}
    >
      <Lottie
        options={animations[name].options}
        height={height}
        width={width}
        // isStopped={isStopped}
        isPaused={isPaused}
      />
    </div>
  );
}

// const styles = theme => ({
//   listConfRules_header_container: {},
// });

const animations = {
  COINSCRAP_ROUNDS: {
    //subText: 'Ahorra mientras gastas',
    options: {
      loop: true,
      autoplay: true,
      animationData: cerditoLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '130 130 250 250',
      },
    },
  },
  PERIODIC_SAVING: {
    //subText: 'Ahorra mes a mes',
    options: {
      loop: true,
      autoplay: true,
      animationData: calendarioLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '130 130 250 250',
      },
    },
  },
  NOMINA_SAVING: {
    //subText: 'Que no solo sume tu cuenta corriente haz crecer tu objetivo',
    options: {
      loop: true,
      autoplay: true,
      animationData: maletinLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '121 50 250 250', //-40 -95 550 550', //'-100 -100 700 700',
      },
    },
  },
  CANCER_DONATION: {
    //subText: 'Dona contra el cancer',
    options: {
      loop: true,
      autoplay: true,
      animationData: lazoLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '30 50 400 400',
      },
    },
  },
  COFFEE_SAVING: {
    //subText: 'Ahorrate el café',
    options: {
      loop: true,
      autoplay: true,
      animationData: cafeDiffLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '160 160 200 200',
      },
    },
  },
  COVID_SAVING: {
    //subText: 'Ahorrate el café',
    options: {
      loop: true,
      autoplay: true,
      animationData: quedateEnCasaLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '-80 -100 700 700',
      },
    },
  },
  SMOKE_SAVING: {
    //subText: 'Recupera tu salud',
    options: {
      loop: true,
      autoplay: true,
      animationData: tabacoDiffLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '150 140 200 200',
      },
    },
  },
  TEAM: {
    //subText: 'Ahorra con tu equipo',
    options: {
      loop: true,
      autoplay: true,
      animationData: camisetaLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '180 160 150 250',
      },
    },
  },
  PUNTUAL_SAVING: {
    //subText: 'Ahorro directo',
    options: {
      loop: true,
      autoplay: true,
      animationData: ahorroHotDiffLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        viewBoxSize: '130 130 250 250',
      },
    },
  },
  MOVIL: {
    options: {
      loop: true,
      autoplay: true,
      animationData: movilLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  },
  MOUNTAIN: {
    options: {
      loop: true,
      autoplay: true,
      animationData: mountainLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  },
  MANO: {
    options: {
      loop: true,
      autoplay: true,
      animationData: manoLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  },
  PACMAN: {
    options: {
      loop: true,
      autoplay: true,
      animationData: pacmanLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  },
  AHORRO_HOT: {
    options: {
      loop: true,
      autoplay: true,
      animationData: ahorroHotLottie.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  },
};

// export default withStyles(styles, { withTheme: true })(Animation);
