export const createTarget_strings = {
  es: {
    targetBasic_label_text1: 'Nombre de objetivo',
    targetBasic_label_text2: 'buscar',
    targetBasic_header_text: 'Edita tu Objetivo',
    targetBasic_button_text: 'Guardar Cambios',
    targetResume_header_text: 'Producto',
    targetResume_informative_text1: 'Rentabilidad',
    targetResume_informative_text2:
      '*Rentabilidad acumulada del fondo según ficha de cartera',
    CreateTargetStart_modal_text:
      'Ya existe un objetivo ¿desea crear uno nuevo?',
    CreateTargetStart_button_text1: 'Si',
    CreateTargetStart_button_text2: 'No',
  },
};
