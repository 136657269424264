import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import SwipeRules from './SwipeRules';
import PickerStyles from '../../components/picker/picker.style';
import SwipeableViewsConfigurableConfig from '../swipeableViews/swipeableViewsConfigurable.config';
import SwipeableViewsConfigurableStyles from '../swipeableViews/swipeableViewsConfigurable.style';
import Header from '../../components/MyHeader';

class CreateTargetRules extends Component {
  goNext() {
    this.props.refreshConf(this.props.id);
    this.props.history.push(this.props.next);
  }

  render() {
    const { classes, theme } = this.props;
    const customSwipe = {
      ...SwipeableViewsConfigurableConfig,
      containerStyle: { flex: 1 },
      slideStyle: { display: 'flex' },
    };
    console.log('CreateTargetRules swipeConfig', customSwipe);
    console.log(this.props, 'PROPS');
    return (
      <div className='container'>
        <Header
          title='Añadir Objetivo'
          rigthAction={() => this.props.history.push('/home/objetivos')}
        />

        <SwipeRules
          type={this.props.type}
          theme={theme}
          classes={classes}
          rulesTarget={this.props.rulesTarget}
          rules={this.props.rulesAnswers}
          rulesDefinitions={this.props.rulesDefinitions}
          //config={SwipeRulesConfig}
          swipeConfig={customSwipe}
          token={this.props.token}
          temporaryID={this.props.id}
          baseUrl={this.props.baseUrl}
          next={this.props.next}
          history={this.props.history}
          goNext={this.goNext.bind(this)}
          // animation={this.returnAnimations.bind(this)}
          className='container'
        />
      </div>
    );
  }
}

const styles = theme => ({
  swipeRules_mainContainer: {
    height: '100vh',
    //minHeight: 568,
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main,
    flex: 1,
    display: 'flex',
  },

  swipeRules_divContainer: {
    display: 'flex',
    flexDirection: 'column',
    //minHeight: 568,
    overflow: 'auto',
    justifyContent: 'center',
    height: 'calc(100% - 60px)',
    width: '100%',
    alignItems: 'center',
  },

  swipeRules_divContainer2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 24px',
    //height: '100%'
  },
  swipeRules_buttonContainer: {
    padding: '0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  swipeRules_buttonContainer2: {
    padding: '0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: '24px',
  },
  cardSummary_iconButton_root: {
    fontSize: 64,
  },
  cardSummary_paper_root: {
    display: 'flex',
    alignItems: 'center',
    height: '10vh',
    minHeight: 57,
    backgroundColor: 'transparent',
  },
  swipeRules_button1_root: {
    width: '152px',
    borderRadius: '4px',
    height: '40px',
    backgroundColor: '#5D5D5D',
  },
  swipeRules_button2_root: {
    width: '152px',
    height: '40px',
    borderRadius: '4px',
  },
  swipeRules_button3_root: {
    width: '312px',
    height: '40px',
    borderRadius: '4px',
  },
  swipeRules_fabutton1_root: {
    marginRight: 8,
    boxShadow: 'unset',
  },

  swipeRules_fabutton2_root: {
    marginLeft: 8,
    boxShadow: 'unset',
  },

  cardSummary_typography1_root: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    textTransform: 'capitalize',
    lineHeight: '45px',
    marginBottom: '21px',
    marginTop: '4px',
  },
  cardSummary_typography2_root: {
    fontWeight: 600,
  },
  cardSummary_typography3_root: {
    fontSize: '16px',
    fontWeight: 700,
  },
  cardSummary_typography4_root: {
    fontSize: '16px',
    textAlign: 'center',
    //width: '290px',
    marginTop: '6px',
  },
  lastSummary_typography1_root: {
    fontWeight: 600,
  },
  lastSummary_typography2_root: {
    fontWeight: 600,
  },
  lastSummary_typography3_root: {
    fontWeight: 600,
  },
  lastSummary_typography4_root: {
    fontWeight: 600,
  },
  cardSummary_icon1_root: {
    height: '46px',
    width: '46px',
    marginTop: '122px',
  },
  swipeRules_picker_container: {
    overflow: 'hidden',
  },
  swipeRules_body_targetSelector_container: {
    display: 'flex',
    alignItems: 'flex-start',
    width: 160,
    borderBottom: 'solid 1px rgba(255,255,255,.3)',
    margin: 'auto',
    textAlign: 'left',
  },
  swipeRules_body_targetSelector_leftIco_container: {
    width: '24.9px',
    height: '27.5px',
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  swipeRules_body_targetSelector_leftIco_svgIcon_root: {
    width: '100%',
    height: '100%',
  },
  swipeRules_body_targetSelector_info_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  swipeRules_body_targetSelector_info_firstRow_typography_root: {
    fontSize: '11px',
    lineHeight: '11.3px',
    letterSpacing: '0px',
    fontWeight: 600,
    marginLeft: '11.5px',
  },
  swipeRules_body_targetSelector_info_secondRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  swipeRules_body_targetSelector_info_secondRow_typography1_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  swipeRules_body_targetSelector_info_secondRow_input_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: theme.palette.primary.main,
    marginLeft: '11.5px',
    '&::placeholder': {
      color: 'rgba(255,255,255,.5)',
    },
  },
  swipeRules_body_targetSelector_info_secondRow_typography2_root: {
    fontSize: '16px',
    marginTop: '8px',
    lineHeight: '16.9px',
    color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  swipeRules_autocomplete_typography1_root: {
    fontSize: '16px',
  },

  ...SwipeableViewsConfigurableStyles(theme),
  ...PickerStyles(theme),
  card_container: {
    maxWidth: '100%',
    height: '75vh',
    minHeight: 426,
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  card_card1_root: {
    borderRadius: 'unset',
    height: '100%',
    margin: 'unset',
    borderStyle: 'unset',
    borderWidth: 'unset',
  },
  card_stepper_root: {
    display: 'none',
  },
  card_cardcontent1_root: {
    height: '100%',
  },
  swipeRules_swipeableViews: {
    height: '100%',
  },
  header: {
    height: '10vh',
    minHeight: 57,
    backgroundColor: theme.palette.primary.main,
  },
  swipeRules_modalContainer: {
    // height: '50vh',
    minHeight: 100,
    // width: '90%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15,
    '&:focus': {
      outline: 'unset',
    },
    margin: 15,
    marginTop: '50%',
    borderRadius: 14,
  },
  swipeRules_modal1_root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  swipeRules_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  swipeRules_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  swipeRules_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '12px',
    marginBottom: '18px',
    paddingRight: 10,
    paddingLeft: 10,
  },
  swipeRules_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
  },
  targetRules_overContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

// const animations = {
//   COINSCRAP_ROUNDS: {
//     subText: 'Ahorra mientras gastas',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: cerditoAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 100,
//     },
//   },
//   PERIODIC_SAVING: {
//     subText: 'Ahorra mes a mes',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: calendarioAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 100,
//     },
//   },
//   NOMINA_SAVING: {
//     subText: 'Que no solo sume tu cuenta corriente haz crecer tu objetivo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: maletinAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 100,
//     },
//   },
//   CANCER_DONATION: {
//     subText: 'Dona contra el cancer',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: lazoAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 85,
//     },
//   },
//   COFFEE_SAVING: {
//     subText: 'Ahorrate el café',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: cafeDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 160,
//     },
//   },
//   COVID_SAVING: {
//     subText: '#QuedateEnCasa',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: quedateEnCasa.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//         viewBoxSize: '-1500 -1500 3500 3500',
//       },
//     },
//   },
//   SMOKE_SAVING: {
//     subText: 'Recupera tu salud',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: tabacoDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 160,
//     },
//   },
//   TEAM: {
//     subText: 'Ahorra con tu equipo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: camisetaAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 180,
//     },
//   },
//   PUNTUAL_SAVING: {
//     subText: 'Ahorro directo',
//     options: {
//       loop: true,
//       autoplay: true,
//       animationData: ahorroHotDiffAnimations.default,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice',
//       },
//       width: 100,
//     },
//   },
// };

export default withStyles(styles, { withTheme: true })(CreateTargetRules);
