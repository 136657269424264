import { Component } from 'react'


class CargosInitialSelect extends Component{
    render(){
        return(
            this.props.renderSelect()
        )
    }
}

export default CargosInitialSelect