import React from 'react';

const Container = ({
  style,
  horizontal,
  fullHeight,

  centerContent,
  spaceContent,
  expandContent,

  fullWidth,
  noPadding,
  onClick,
  ...props
}) => {
  const generatedStyle = {
    display: 'flex',
  };

  if (!horizontal) {
    generatedStyle.flexDirection = 'column';
  }

  if (fullHeight) {
    generatedStyle.flexBasis = '100%';
  }
  if (fullWidth) {
    generatedStyle.width = '100%';
  }

  if (
    generatedStyle.flexDirection &&
    generatedStyle.flexDirection.indexOf('column') >= 0
  ) {
    generatedStyle.alignItems = 'center';
  } else {
    generatedStyle.justifyContent = 'center';
  }

  if (centerContent) {
    if (
      generatedStyle.flexDirection &&
      generatedStyle.flexDirection.indexOf('column') >= 0
    ) {
      generatedStyle.justifyContent = 'center';
    } else {
      generatedStyle.alignItems = 'center';
      generatedStyle.alignContent = 'center';
    }
  }

  if (spaceContent) {
    generatedStyle.justifyContent = 'space-around';
  }

  if (expandContent) {
    /* if (
      generatedStyle.flexDirection &&
      generatedStyle.flexDirection.indexOf('column') >= 0
    ) { */
    generatedStyle.justifyContent = 'space-between';
    /* } */
  }

  if (!noPadding) {
    generatedStyle.padding = 20;
  }

  return (
    <div
      id={props.id}
      style={{ ...generatedStyle, ...style }}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
};

export default Container;
