
function generateStyles(theme) {
    const styles = {
      listTargetForRules_divContainer:{ //correct
        marginLeft: 12,
        marginRight: 12,
        marginBottom: 12,
      },
      listTargetForRules_listItem1_root:{ //correct
        padding: 0
      },
      listTargetForRules_divImage: { //correct
        position: 'relative',
        width: '312px',
        borderRadius: '4px',
        //height: '25vh',
        //minHeight: 142,
        height: '128px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      },
      listTargetForRules_divImage_overlay:{ //correct
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)'
      },
      listTargetForRules_titleContainer:{
          position: 'absolute',
          top:0,
          width: '100%',
          height: '10vh',
          minHeight: 57,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
      },
      listTargetForRules_typographyTitle1_root:{
        textTransform: 'uppercase',
        fontSize: 16,
        fontWeight: 600
      },
      listTargetForRules_optionsContainer:{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: '10vh',
          minHeight: 57,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 8,
          paddingRight: 8
      },
      listTargetForRules_amountContainer:{
          width: '30%',
          height: '10vh',
          minHeight: 57,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
      },
      listTargetForRules_typographyAmount1_root:{
        fontSize: 6,
        textAlign: 'center',
        textTransform: 'uppercase'
      },
      listTargetForRules_typographyAmount2_root:{
        fontWeight: 600,
        textAlign: 'center'
      },
      listTargetForRules_typographyActivet1_root:{
        fontSize: 6,
        textAlign: 'center'
      },
      listTargetForRules_editTypography1_root:{
        fontSize: 6,
        textAlign: 'center'
      },
      listTargetForRules_editTypography2_root:{
        fontWeight: 600,
        textAlign: 'center'
      },
      listTargetForRules_activeContainer:{
        width: '30%',
        display: 'flex',
        height: '10vh',
        minHeight: 57,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      listTargetForRules_editContainer2: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end'
      },
      listTargetForRules_editContainer1:{
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      listTargetForRules_leftPanel_container:{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      },
      listTargetForRules_rightPanel_container:{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        alignItems: 'flex-end',
        justifyContent: 'flex-start'
      },
      listTargetsUser_img: {
        maxWidth: '100%'
      },
      listTargetsUser_button1_root: {
        width: '100%',
        borderRadius: 'unset',
        margin: 0,
        fontWeight: 600
      },
      listTargetsUser_divContainer:{
        width: '312px',
        height: '128px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        margin: '5px auto 5px auto',
      },
      listTargetsUser_listItem1_root:{
        padding: 0
      },
      listTargetsUser_divImage: {
        position: 'relative',
        width: '100vw',
        height: '25vh',
        minHeight: 142,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      },
      listTargetsUser_divImage_overlay:{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)'
      },
      listTargetsUser_textDiv: {
        paddingLeft: 15,
        paddingTop: 10,
        position: 'absolute',
        top: 0,
        left: 0
      },
      listTargetsUser_typography1_root:{
        fontWeight: 200,
        fontSize: '30px',
        textTransform: 'Capitalize'
      },
      listTargetsUser_typography2_root: {
        fontSize: '33px',
        fontWeight: 100,
        lineHeight: '39.4px',
        letterSpacing: '0px'
      },
      listTargetsUser_typography3_root:{
        fontSize: '12px',
        fontWeight: 600,
        paddingTop: 6

      },
      listTargetsUser_typography4_root:{
        top: 30,
        fontSize: '15px',
        lineHeight: '16px',
        position: 'absolute',
        right: 67
      },
      listTargetsUser_progress_container:{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 'auto'
      },
      listTargetsUser_progress_container_firstRow:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      },
      listTargetsUser_card1_root: {
        borderRadius: 'unset'
      },
      listTargetsUser_cardContent1_root: {
        textAlign: 'center'
      },
      listTargetsUser_textCompleteDiv:{
        width: '100%',
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        zIndex: 9999
      },
      listTargetsUser_cardActions1_root:{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: 3
      },
      listTargetsUser_progress_container_secondRow_nextWish:{
        height: '8px',
        backgroundColor: theme.palette.secondary.main,
      },
      listTargetsUser_progress_container_secondRow_familyTarget:{
        height: '8px',
        backgroundColor: '#50e3c2'
      },
      listTargetsUser_progress_container_secondRow_retirement:{
        height: '8px',
        backgroundColor: '#ffc000'
      },
      listTargetsUser_progress_container_secondRow:{
        width: '100%',
        height: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(255,255,255,.2)',
        marginBottom: '4px'
      },
      listTargetsUser_progress_container_thirdRow:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      },
      listTargetsUser_typography5_root:{
        bottom: 24,
        fontSize: '15px',
        lineHeight: '16px',
        position: 'absolute',
        right: 67
      },
      listTargetsUser_typography6_root:{
        fontSize: '12px',
        letterSpacing: '0px',
        lineHeight: '12px'
      },
      listTargetsUser_switch_root:{
        position: 'absolute',
        right: '8px',
        top: 12
      },
      listTargetsUser_editIcon_root:{
        position: 'absolute',
        right: 13,
        bottom: 14,
        height: 45,
        width: 50    
      }
    }
    return styles
  }
  
  export { generateStyles as ListTargetsForRulesStyles }
  