function generateStyles(theme) {
  const styles = {
    targetsTypesList_container:{
      margin: '0 15px 5px 15px',
      border: '3px solid #fafafa'
    },
    listTargetsTypes_listItem1_root:{
      padding: 0
    },
    listTargetsTypes_img: {
      maxWidth: '100%'
    },
    listTargetsTypes_button1_root: {
      width: '100%',
      borderRadius: 'unset',
      margin: 'unset'
    },
    listTargetsTypes_card1_root: {
      borderRadius: 'unset'
    },
    listTargetsTypes_cardContent1_root: {
      textAlign: 'center'
    },
    listTargetsTypes_cardActions1_root: {
      padding: 0
    },
    listTargetsTypes_divImage:{
      position: 'relative',
      width: '100vw',
      height: '25vh',
      minHeight: 142,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
  }
  return styles
}

export { generateStyles as ListTargetsTypesStyles }
