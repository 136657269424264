import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('layout', {
  styles: {
    default: {
      headerComponent: {
        minHeight: 40,
        background: colors.greyLight,
        // boxShadow: 'silver 0px -2px 7px',
      },
      footerComponent: {
        backgroundColor: 'white',
        // boxShadow: 'silver 0px -2px 7px',
        // borderTopColor: colors.greyLightBorders,
        // borderTopWidth: 1,
        // borderTopStyle: 'solid',
        minHeight: 'auto',
      },
      bodyComponent: {
        backgroundColor: 'white',
      },
      modalComponent: {
        contentComponent: {
          overflow: 'initial',
        },
      },
    },
  },
});
