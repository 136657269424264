function portfolio() {
  return {
    title: 'Animado',
    $$hashKey: 'object:115',
    benchmark: { id: 'imef', name: 'FI Renta Variable Mixta Internacional' },
    equity: 42,
    historicPerformance: 6.9404,
    id: 'a210a76c-04e1-11e7-8555-080027628e9b',
    minAmount: 100,
    minTimeframe: 3,
    name: 'Animado',
    performance: 8.75,
    productId: 'evo_caser_unit_linked',
    recommended: false,
    selected: false,
    tolerance: 4,
    typicalDeviation: {
      1: 11.6717,
      2: 8.3547,
      3: 6.755,
      4: 5.997,
      5: 5.4077,
      6: 4.7705,
      7: 4.4281,
      8: 4.3194,
      9: 4.2321,
      10: 4.0367,
      11: 3.938,
      12: 3.8533,
      13: 3.7294,
      14: 3.6168,
      15: 3.5072,
      16: 3.348,
      17: 3.1266,
      18: 2.931,
      19: 2.7721,
      20: 2.6203,
      21: 2.4988,
      22: 2.4147,
      23: 2.2824,
      24: 2.1098,
      25: 1.9538,
      26: 1.7681,
      27: 1.5441,
      28: 1.3469,
      29: 1.2119,
      30: 1.0119,
      31: 0.7996,
      32: 0.6985,
      33: 0.5971,
      34: 0.3822,
      35: 0.3678,
      36: 0.4083,
      37: 0.3142,
      38: 0.2863,
      39: 0.3034,
      40: 0.2713,
      default: 0.2713,
    },
  };
}

export default portfolio;
