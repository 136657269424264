import colors from '../colors';
import fonts from '../fonts';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('button', {
  styles: {
    default: {
      backgroundColor: colors.pink,
      borderRadius: 4,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      maxWidth: '310px',
      textComponent: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 400,
        color: colors.white,
      },
      disabledMode: {
        opacity: 0.2,
      },
    },

    greenButtonSt: {
      backgroundColor: colors.green,
    },
    pinkButtonSt: {
      backgroundColor: colors.pink,
    },
    disabledButtonSt: {
      backgroundColor: '#664c42',
    },

    fullWidthSt: {
      width: '100%',
    },

    horizontalSt: {
      display: 'flex',
      flexDirection: 'row',
    },

    boldTextSt: {
      textComponent: {
        fontWeight: 700,
      },
    },

    transparentSt: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
});
