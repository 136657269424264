import React, { Component } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Utils } from '../../../resources/index';
import PropTypes from 'prop-types';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class SwipeWithState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      step: 0,
    };
  }

  handleStepChange = index => {
    if (index === 0) {
      index = this.props.last - 1;
    } else if (index === this.props.last) {
      index = 1;
    }
    this.setState({
      index,
      step: (index - 1) % 3,
    });
  };

  render() {
    const { classes, theme } = this.props;
    const { index, step } = this.state;
    const transformClasses = Utils.transformClasses;
    const maxSteps = 3;

    return (
      <div className={classes.card_container}>
        <Card classes={transformClasses('card_card', classes)}>
          <CardContent>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={index}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {this.props.renderData()}
            </AutoPlaySwipeableViews>
          </CardContent>
        </Card>
        <MobileStepper
          steps={maxSteps}
          position='static'
          activeStep={step}
          classes={transformClasses('card_stepper', classes)}
          nextButton={<div />}
          backButton={<div />}
        />
      </div>
    );
  }
}

export default SwipeWithState;

SwipeWithState.propTypes = {
  last: PropTypes.number,
  classes: PropTypes.object,
  theme: PropTypes.object,
  renderData: PropTypes.func,
};
