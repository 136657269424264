import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

// TODO: Este creo q no se UTILIZA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
class PickerColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      ...this.computeTranslate(props),
    };
    //console.log('state: ', this.state)
  }

  /*
  useEffect(()=>{

  })
  */

  /* DEPRECATED
  componentWillReceiveProps(nextProps) {
    if (this.state.isMoving) {
      return
    }
    this.setState(this.computeTranslate(nextProps))
  }
  */

  static getDerivedStateFromProps(nextProps) {
    return this.state.isMoving ? null : this.computeTranslate(nextProps);
  }

  computeTranslate = props => {
    const { options, value, itemHeight, columnHeight } = props;

    let selectedIndex = value
      ? options.findIndex(element => element.name === value.name)
      : -1;
    if (selectedIndex < 0) {
      // throw new ReferenceError();
      /*console.warn(
        'Warning: "' +
          this.props.name +
          '" doesn\'t contain an option of "' +
          value +
          '".'
      )*/
      this.onValueSelected(options[0]);
      selectedIndex = 0;
    }
    return {
      scrollerTranslate:
        columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
      minTranslate:
        columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
      maxTranslate: columnHeight / 2 - itemHeight / 2,
    };
  };

  onValueSelected = newValue => {
    this.props.onChange(this.props.name, newValue);
  };

  handleTouchStart = event => {
    const startTouchY = event.targetTouches[0].pageY;
    this.setState(({ scrollerTranslate }) => ({
      startTouchY,
      startScrollerTranslate: scrollerTranslate,
    }));
  };

  handleTouchMove = event => {
    const touchY = event.targetTouches[0].pageY;
    this.setState(
      ({
        isMoving,
        startTouchY,
        startScrollerTranslate,
        minTranslate,
        maxTranslate,
      }) => {
        if (!isMoving) {
          return {
            isMoving: true,
          };
        }

        let nextScrollerTranslate =
          startScrollerTranslate + touchY - startTouchY;
        if (nextScrollerTranslate < minTranslate) {
          nextScrollerTranslate =
            minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
        } else if (nextScrollerTranslate > maxTranslate) {
          nextScrollerTranslate =
            maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
        }
        return {
          scrollerTranslate: nextScrollerTranslate,
        };
      },
    );
  };

  handleTouchEnd = event => {
    if (!this.state.isMoving) {
      return;
    }
    this.setState({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
    });
    setTimeout(() => {
      const { options, itemHeight } = this.props;
      const { scrollerTranslate, minTranslate, maxTranslate } = this.state;
      let activeIndex;
      if (scrollerTranslate > maxTranslate) {
        activeIndex = 0;
      } else if (scrollerTranslate < minTranslate) {
        activeIndex = options.length - 1;
      } else {
        activeIndex = -Math.floor(
          (scrollerTranslate - maxTranslate) / itemHeight,
        );
      }
      this.onValueSelected(options[activeIndex]);
    }, 0);
  };

  handleTouchCancel = event => {
    if (!this.state.isMoving) {
      return;
    }
    this.setState(startScrollerTranslate => ({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      scrollerTranslate: startScrollerTranslate,
    }));
  };

  handleItemClick = option => {
    if (option !== this.props.value) {
      this.onValueSelected(option);
    } else {
      this.props.onClick(this.props.name, this.props.value);
    }
  };

  renderItems() {
    const { options, itemHeight, value, classes } = this.props;
    return options.map((option, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px',
      };
      return (
        <Typography
          variant='body1'
          color='primary'
          key={index}
          className={
            value && option.name === value.name
              ? classes.pickerItemSelected
              : classes.pickerItem
          }
          style={style}
          onClick={() => this.handleItemClick(option)}
        >
          {option.name + '' + option.unit}
        </Typography>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
    const style = {
      MsTransform: translateString,
      MozTransform: translateString,
      OTransform: translateString,
      WebkitTransform: translateString,
      transform: translateString,
    };
    if (this.state.isMoving) {
      style.transitionDuration = '0ms';
    }
    return (
      <div className={classes.PickerColumn}>
        <div
          className={classes.pickerScroller}
          style={style}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onTouchCancel={this.handleTouchCancel}
        >
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

class Picker extends Component {
  static defaultProps = {
    onClick: () => {},
    itemHeight: 36,
    height: 216,
  };

  renderInner() {
    const {
      optionGroups,
      valueGroups,
      itemHeight,
      height,
      onChange,
      onClick,
      classes,
    } = this.props;
    const highlightStyle = {
      height: itemHeight,
      // marginTop: -(itemHeight / 2)
    };
    const columnNodes = [];
    for (const name in optionGroups) {
      columnNodes.push(
        <PickerColumn
          key={name}
          name={name}
          options={optionGroups[name]}
          value={valueGroups[name]}
          itemHeight={itemHeight}
          columnHeight={height}
          onChange={onChange}
          onClick={onClick}
          classes={classes}
        />,
      );
    }
    return (
      <div className={classes.pickerInner}>
        {columnNodes}
        <div className={classes.pickerHighlight} style={highlightStyle} />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const style = {
      height: this.props.height,
    };

    return (
      <div className={classes.pickerContainer} style={style}>
        {this.renderInner()}
      </div>
    );
  }
}

export default Picker;
