import React, { Component } from 'react';

import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { Utils } from '../../src-core/resources/index';
//import { UsersAPI } from '../../core/;
import { UsersAPI } from '../../src-core/sdk/index';

import Flow from '../../components/Flow';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  async getUser() {
    //const currentDate = new Date()
    console.log('getUser', this.props.token);
    const response = await UsersAPI.getUserData(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    if (response.status === 200) {
      //const data = await response.json()
      //const userDate = new Date(data.create_date)
      //const diff = currentDate-userDate
      //const diffMin = Math.round(((diff / 1000) /60))

      Flow.initParams();

      const tourShowed = window.localStorage.getItem('tourShowed');
      console.log('tourShowed', tourShowed);
      if (tourShowed === 'true') {
        this.props.history.push('/home');
      } else {
        this.props.history.push('/tour');
      }
    } else {
      this.setState({
        open: true,
      });
      console.log('error consiguiendo datos de usuario ', response.status);
    }
  }

  async getTargetsUser() {
    const response = await UsersAPI.getTargetsSummary(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    console.log('getTargetsUser', this.props.token);

    if (response.status === 200) {
      const data = await response.json();
      if (data.targetsInfo.length <= 0) {
        this.props.history.push('/noTargets');
      } else {
        this.props.history.push('/home/resumen');
      }
    } else {
      console.log('error recuperando targets de usuario ', response.status);
    }
  }

  componentDidMount() {
    this.getUser();
  }

  render() {
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      <div className={classes.container}>
        <img
          src='https://www.evobanco.com/.galleries/home/logoEvo_w_.png'
          alt='Logo EVO'
        />
        <Modal
          open={this.state.open}
          classes={transformClasses('landing_modal1', classes)}
        >
          <div className={classes.modal_div}>
            <Typography
              variant='body1'
              color='default'
              classes={transformClasses('landing_typography1', classes)}
            >
              {this.props.token}
              Ups! algo ha salido mal :(
            </Typography>
          </div>
        </Modal>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  landing_modal1_root: {
    height: '100vh',
    minHeight: 568,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal_div: {
    height: '50vh',
    width: '80%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    '&:focus': {
      outline: 'unset',
    },
  },
});

export default withStyles(styles, { withTheme: true })(LandingPage);
