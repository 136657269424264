import React, { Fragment, useState, useEffect } from 'react';
import { TextField } from 'coinscrap-webapp-core';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Currency from '../../../components/Currency';

import Flow from '../../../components/Flow';
import MyText from '../../../components/MyText';
import MyIcon from '../../../components/MyIcon';
import Body from '../../../components/MyBody';
import Footer from '../../../components/MyFooter';
import Header from '../../../components/MyHeader';

import { TargetsAPI } from '../../../src-core/sdk/index';

function ProductSimulatorResume(props) {
  console.log('TargetPerfilSimulacionResumen', props, props.location.state);
  const params = props.location.state;
  const primerMensualidad = new Date();
  // +2 meses
  primerMensualidad.setMonth(primerMensualidad.getMonth() + 2);
  console.log(props);
  const flowParams = Flow.getParams();
  const [years, setYears] = useState(flowParams.años);
  const [schema, setSchema] = useState(null);
  const [target, setTarget] = useState(null);
  const [configurationData, setConfigurationData] = useState(null);
  const [yearInputError, setYearInputError] = useState(null);

  console.log(schema);
  console.log(target);
  console.log(configurationData);

  const getTargetConfigurationSchema = async type => {
    const data = await TargetsAPI.getTargetTypeSchema(
      flowParams.baseUrl,
      type,
      flowParams.token,
    );
    setSchema(data);
  };

  const getTarget = async () => {
    TargetsAPI.getTargets(
      flowParams.baseUrl,
      flowParams.temporaryObjectiveId,
      flowParams.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(async data => {
        await getTargetConfigurationSchema(data.type);

        setTarget(data);
      })
      .catch(error => {
        console.log('error consiguiendo el target', error);
      });
  };
  const getConfiguration = async () => {
    TargetsAPI.getTargetConfiguration(
      flowParams.baseUrl,
      flowParams.temporaryObjectiveId,
      flowParams.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(async data => {
        setConfigurationData(data);
      })
      .catch(error => {
        console.log('error consiguiendo el target', error);
      });
  };

  useEffect(() => {
    getTarget();
    getConfiguration();
  }, []);

  const continueHandler = async () => {
    try {
      await TargetsAPI.setTargetConfiguration(
        flowParams.baseUrl,
        flowParams.temporaryObjectiveId,
        flowParams.token,
        { years: +years },
      );
      Flow.navigate(
        `/create/target/${
          flowParams.objectiveType ? flowParams.objectiveType : 'familyTarget'
        }/Inicial`,
        props,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderLoading = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress color='secondary' />
      </div>
    );
  };

  return !configurationData || !target || !schema ? (
    renderLoading()
  ) : (
    <Fragment>
      <Header modal={true} title='Resumen Simulación' history={props.history} />
      <Body vAlign='top'>
        <MyIcon style={{ width: 50, height: 50 }} name='bolsaDinero'></MyIcon>
        <MyText variant='headline'>Hucha</MyText>
        <MyText style={{ marginBottom: 20 }}>
          Seguro de Vida Ahorro (Unit Linked) contratado a través de Caser
          Seguros y EVO
        </MyText>
        <Item
          label='¿En cuantos años lo quieres?'
          icon={<MyIcon name='calendario' />}
        >
          <TextField
            type='number'
            value={years}
            onChange={e => {
              const config = schema.properties;
              const value = e.target.value;
              setYears(value);
              if (value < config.years.minimum) {
                setYearInputError(
                  'No puedes crear un objetivo de menos de ' +
                    config.years.minimum +
                    ' años.',
                );
              } else if (value > config.years.maximum) {
                setYearInputError(
                  'No puedes crear un objetivo de más de ' +
                    config.years.maximum +
                    ' años.',
                );
              } else {
                setYearInputError(null);

                Flow.setParams({
                  años: +value,
                });
              }
            }}
            productSummaryYearInputSt
          />
          <MyText>
            Su entrada inicial antes de la simulación era de{' '}
            {configurationData.years} años.
          </MyText>
          {yearInputError && (
            <MyText style={{ color: 'rgba(255,255,255,.5)' }}>
              {yearInputError}
            </MyText>
          )}
        </Item>
        <Item
          label='Cartera del Plan'
          icon={<MyIcon name='documentoLapizFirma' />}
        >
          <MyText>{params.productName}</MyText>
        </Item>
        <Item
          label='Aportación inicial'
          icon={<MyIcon name='dineroFlechaArriba' />}
        >
          <MyText>{Currency(params.impInicial)}</MyText>
        </Item>
        <Item
          label='Aportación mensual'
          icon={<MyIcon name='dineroFlechaArriba' />}
        >
          <MyText>{Currency(params.impMensual)}</MyText>
          <MyText>
            Primera mensualidad:{' '}
            {primerMensualidad.getDate() +
              '/' +
              (primerMensualidad.getMonth() + 1) +
              '/' +
              primerMensualidad.getFullYear()}
          </MyText>
        </Item>
        <Item label='Coste del seguro' icon={<MyIcon name='cerraduraPuerta' />}>
          <MyText>
            {Currency(params.datosEscenario.costeSeguroAnual, 2)} anual
          </MyText>
          <MyText
            variant='link'
            onClick={() => {
              Flow.navigate('/product/simulator/insurance', props);
            }}
          >
            saber más
          </MyText>
        </Item>
        <Item
          label='Comisiones de gestión'
          icon={<MyIcon name='euroFlechaAbajo' />}
        >
          <MyText>{(params.comisionGestion * 100).toFixed(2)} %</MyText>
          <MyText
            variant='link'
            onClick={() => {
              Flow.navigate('/product/simulator/costs', props);
            }}
          >
            Ver detalle de las comisiones
          </MyText>
        </Item>
        <div style={{ height: 80 }} />
      </Body>
      <Footer>
        <Button
          variant='contained'
          color='secondary'
          disabled={!!yearInputError}
          onClick={continueHandler}
        >
          Continuar
        </Button>
      </Footer>
    </Fragment>
  );
}

function Item(props) {
  //const classes = props.classes

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderBottom: 'solid 1px rgba(255,255,255,.3)',
        marginBottom: '18px',
        textAlign: 'left',
      }}
    >
      <div
        style={{
          width: 45,
          height: 45,
          display: 'flex',
          padding: 0,
          margin: 0,
        }}
      >
        {props.icon}
      </div>
      <div
        style={{
          //width: '85%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='body1'
            color='secondary'
            style={{
              fontSize: 12,
              textAlign: 'left',
              //lineHeight: 11.3,
              fontWeight: 600,
              //marginLeft: 11.5,
              letterSpacing: 0,
              textTransform: 'uppercase',
            }}
          >
            {props.label}
          </Typography>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 32,
    width: '100%',
    height: '100%',
  },
  //...ProductsStyles(theme),
  //...SwipeableViewsConfigurableStyles(theme)
});

export default withStyles(styles, { withTheme: true })(ProductSimulatorResume);
