import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
//import { withStyles } from '@material-ui/core/styles'

import Icons from './MyIcon.config';
import Flow from './Flow';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //rules: null,
      //loadDone: false,
      //userTargets: null
    };
    //console.log("Header.const", props);
  }

  render() {
    //const { /*classes , theme,*/ title, leftAction, rigthAction } = this.props
    //const transformClasses = Utils.transformClasses

    let rigthAction = this.props.rigthAction;

    if (this.props.modal) {
      // Crea una pila de llamadas global
      //'Para el modo modal, debe lanzarse la url con Flow.Navigate'

      rigthAction = () => Flow.goBack(this.props.history);
    }

    const textColor = this.props.styleType === 'white' ? 'black' : 'white';
    const textFontSize = this.props.textFontSize
      ? this.props.textFontSize
      : '30px';
    let leftIcon = null;
    if (this.props.leftAction) {
      if (this.props.leftIcon) {
        leftIcon = this.props.leftIcon;
      } else {
        leftIcon = Icons.arrowLeft;
      }
    }

    //console.log("header.render", this.props, leftIcon );
    return (
      <div
        className='headerComp'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexFlow: 'column',
          ...this.props.style,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            //marginBottom: '12px',
            //paddingTop: '12px',
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <SvgIcon
            id='headerLeftButton'
            //classes={transformClasses('icon',classes)}
            onClick={this.props.leftAction}
            //color={(this.props.styleType == 'black') ? 'primary' : 'white'}
            viewBox={leftIcon ? leftIcon.viewBox : null}
            style={{
              height: 35,
              width: 35,
              color: textColor,
            }}
          >
            {leftIcon ? <path d={leftIcon.data} /> : <path d='' />}
          </SvgIcon>

          <Typography
            variant='body1'
            //color={(this.props.styleType == 'black') ? 'primary' : 'white'}
            //className={classes.title}
            style={{
              fontWeight: 100,
              fontSize: textFontSize,
              lineHeight: '45px',
              textAlign: 'center',
              color: textColor,
              paddingTop: 7,
              height: 50,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontFamily: 'Calibre',
            }}
          >
            {this.props.title}
          </Typography>

          <SvgIcon
            id='headerRightButton'
            //color={(this.props.styleType == 'black') ? 'primary' : 'white'}
            //classes={transformClasses('icon', classes)}
            onClick={rigthAction}
            style={{
              height: 35,
              width: 35,
              color: textColor,
            }}
          >
            {rigthAction ? (
              <path d='M 19 6.41 L 17.59 5 L 12 10.59 L 6.41 5 L 5 6.41 L 10.59 12 L 5 17.59 L 6.41 19 L 12 13.41 L 17.59 19 L 19 17.59 L 13.41 12 Z' />
            ) : (
              <path d='' />
            )}
          </SvgIcon>
        </div>
        {this.props.children}
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  leftAction: PropTypes.any,
  rightAction: PropTypes.any,
  styleType: PropTypes.oneOf(['black', 'white']), // Por defecto black
  // Si modal es = true aparece un X, gestiona una cola de navegación y el click va a la anterior ventana
  modal: PropTypes.bool,
  // Si es modal hay q enviar la ultima url, para q guarde el flujo
  lastUrl: PropTypes.string,
};

Header.defaultProps = {
  modal: false,
};

/*
const styles = theme => ({
  container: {
  },
  title: {
    fontWeight: 100,
    fontSize: '35px',
    lineHeight: '45px',
    textAlign: 'center',
    color: color
  },
})
*/

//export default withStyles(styles, { withTheme: true })(Header)
export default Header;
