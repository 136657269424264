// react-hooks/exhaustive-deps
import React, { useState, useEffect, Fragment, useCallback } from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';

//import { UsersAPI } from '../../core/
import { UsersAPI } from '../../../src-core/sdk/index';

import Currency from '../../../components/Currency';
import SwipeableViewsConfigurableStyles from '../../swipeableViews/swipeableViewsConfigurable.style';
import MyText from '../../../components/MyText';
import MyTextForInput from '../../../components/MyTextForInput';
import MyTabs from '../../../components/MyTabs';
import Header from '../../../components/MyHeader';
import Body from '../../../components/MyBody';
import Footer from '../../../components/MyFooter';
import Flow from '../../../components/Flow';
import Simulator from './ScenariosSimulator';
import Config from './ScenariosConfig';

function TargetPerfilSimulacion(props) {
  //console.log('TargetPerfilSimulacion', props);

  let params = Flow.getParams();
  const [activeTab, setActiveTab] = useState(1);
  //params = { ...params, ...props.location.state };
  const [edad, setEdad] = useState(0);
  const seguroPorAños = params.metadata.insuranceCostsPerYear.ages;
  const [openModalInfoComo, setOpenModalInfoComo] = useState(false);
  const [
    tieneAportMensual, //setTieneAportMensual
  ] = useState(
    true, //params.tieneAportMensual,
  );
  const [impInicial, setImpInicial] = useState(params.impInicial || 0);
  const [impMensual, setImpMensual] = useState(params.impMensual || 0);
  const [años, setAños] = useState(params.años || 5);
  const [aportado, setAportado] = useState(0);
  // const rentabilidad = {
  //   pesimista: params.pessimisticProfitability,
  //   prevista: params.profitability,
  //   optimista: params.optimisticProfitability,
  // };
  const [pesimista, setPesimista] = useState({
    finalDesde: 0,
    finalHasta: null,
    costeDesde: 0,
    costeHasta: 0,
    seguroDesde: 0,
    costeSeguroTotal: 0,
  });
  const [optimista, setOptimista] = useState({
    finalDesde: 0,
    finalHasta: null,
    costeDesde: 0,
    costeHasta: 0,
    seguroDesde: 0,
    costeSeguroTotal: 0,
  });
  const [esperado, setEsperado] = useState({
    finalDesde: 0,
    finalHasta: null,
    costeDesde: 0,
    costeHasta: 0,
    seguroDesde: 0,
    costeSeguroTotal: 0,
  });
  const [standard, setStandard] = useState({
    finalDesde: 0,
    finalHasta: null,
    costeDesde: 0,
    costeHasta: 0,
    seguroDesde: 0,
    costeSeguroTotal: 0,
  });

  const comisionGestion = params.metadata.managementCostsRate;
  //const costeAnual, setCosteAnual] = useState(null)

  //let inputImpInicialRef = React.createRef();
  //let inputImpMensualRef = React.createRef();

  // Caculos ----------------------------------------------------------
  useEffect(() => {
    if (!edad || edad === -1) {
      getUserData();
    }

    let impInicialInt = !isNaN(parseInt(impInicial)) ? parseInt(impInicial) : 0;

    let aportadoMensualmente = 0;
    let aportadoLocal = 0;
    if (tieneAportMensual === true) {
      aportadoMensualmente = impMensual * 12 * años;
    }

    aportadoLocal = aportadoMensualmente + impInicialInt;

    Flow.setParams({
      impInicial: impInicialInt,
      impMensual: parseInt(impMensual),
      años,
    });

    // Obtiene los datos de las funciones extraidas de finizens
    // de la web de Evo Banco
    if (edad > 0) {
      let simulator = new Simulator();
      let scenarios = simulator.getDeviations();
      let config = new Config(params.productName);

      for (const [name, scenario] of Object.entries(scenarios)) {
        scenarios[name] = simulator.projectScenario(
          config,
          scenario,
          edad,
          impInicialInt,
          impMensual,
          años,
        );
      }

      console.log('Escenarios', scenarios);

      setAportado(aportadoLocal); //ºº

      setPesimista({
        scenarioName: 'Pesimista',
        finalDesde: scenarios.pessimistic.from.balance.amount,
        finalHasta: scenarios.pessimistic.to.balance.amount,
        porcDesde: scenarios.pessimistic.from.performance.annual,
        porcHasta: scenarios.pessimistic.to.performance.annual,
        costeSeguroTotal: scenarios.pessimistic.from.insurance.total,
        costeSeguroAnual: scenarios.pessimistic.from.insurance.annual,
        costeSeguroPrimerAño: scenarios.pessimistic.from.insurance.firstYear,
        costesTotalesDesde: scenarios.pessimistic.from.fees.total,
        costesTotalesHasta: scenarios.pessimistic.to.fees.total,
      });
      setEsperado({
        scenarioName: 'Esperado',
        finalDesde: scenarios.expected.from.balance.amount,
        finalHasta: scenarios.expected.to.balance.amount,
        porcDesde: scenarios.expected.from.performance.annual,
        porcHasta: scenarios.expected.to.performance.annual,
        costeSeguroTotal: scenarios.expected.from.insurance.total,
        costeSeguroAnual: scenarios.expected.from.insurance.annual,
        costeSeguroPrimerAño: scenarios.expected.from.insurance.firstYear,
        costesTotalesDesde: scenarios.expected.from.fees.total,
        costesTotalesHasta: scenarios.expected.to.fees.total,
      });
      setOptimista({
        scenarioName: 'Optimista',
        finalDesde: scenarios.optimistic.from.balance.amount,
        finalHasta: scenarios.optimistic.to.balance.amount,
        porcDesde: scenarios.optimistic.from.performance.annual,
        porcHasta: scenarios.optimistic.to.performance.annual,
        costeSeguroTotal: scenarios.optimistic.from.insurance.total,
        costeSeguroAnual: scenarios.optimistic.from.insurance.annual,
        costeSeguroPrimerAño: scenarios.optimistic.from.insurance.firstYear,
        costesTotalesDesde: scenarios.optimistic.from.fees.total,
        costesTotalesHasta: scenarios.optimistic.to.fees.total,
      });
      setStandard({
        scenarioName: 'Standard',
        final: scenarios.standard.from.balance.amount,
        porc: scenarios.standard.from.performance.annual,
        //costeSeguroTotal: scenarios.standard.from.insurance.total,
        // costeSeguroAnual: scenarios.standard.from.insurance.annual,
        // costeSeguroPrimerAño: scenarios.standard.from.insurance.firstYear,
        costesTotales: scenarios.standard.from.fees.total,
      });

      // Importe == 0
    } else {
      console.log('TargetPerfilSimulacion edad < 1', edad);
      setAportado(aportadoLocal);

      setPesimista({});
      setEsperado({});
      setOptimista({});
      setStandard({});
    }
  }, [
    años,
    impInicial,
    impMensual,
    edad,
    getUserData,
    params.productName,
    tieneAportMensual,
  ]);

  /*
  function calculateCompoundInterest(
    initialPayment,
    yearlyPayment,
    rate,
    years,
    repetitionPerYear,
    type,
  ) {
    if (!type || type === 'start') {
      return Currency(
        basicInvestment(
          initialPayment,
          rate / repetitionPerYear,
          years * repetitionPerYear,
          yearlyPayment / repetitionPerYear,
        ),
        2,
      );
    } else if (type === 'end') {
      return Currency(
        bI2(
          initialPayment,
          rate / repetitionPerYear,
          years * repetitionPerYear,
          yearlyPayment / repetitionPerYear,
        ),
        2,
      );
    } else {
      throw new Error('Invalid type');
    }
  }
*/

  /*
  function calculateCosteSeguro(edad, años) {
    return Array.from({ length: años }, (e, i) => edad + i)
      .map(age => seguroPorAños[age] || 0)
      .reduce((accum, cost) => accum + cost);
  }

  function futureValue(p, r, y) {
    return p * Math.pow(1 + r, y);
  }

  function returnRate(pv, fv, y) {
    return Math.pow(fv / pv, 1.0 / y) - 1.0
  }

  function geomSeries(z, m, n) {
    var amt;
    if (z === 1.0) amt = n + 1;
    else amt = (Math.pow(z, n + 1) - 1) / (z - 1);
    if (m >= 1) amt -= geomSeries(z, 0, m - 1);
    return amt;
  }

  function basicInvestment(p, r, y, c) {
    if (c == null) c = 0;

    return futureValue(p, r, y) + c * geomSeries(1 + r, 1, y);
  }

  function bI2(initialPayment, rate, repetitions, periodicPayment) {
    if (periodicPayment === null) periodicPayment = 0;
    if (repetitions === 0) return initialPayment;
    return (
      futureValue(initialPayment, rate, repetitions) +
      periodicPayment * geomSeries(1 + rate, 0, repetitions - 1)
    );
  }

  function calculateCosts(
    initialPayment,
    yearlyPayment,
    costRate,
    years,
    repetitionPerYear,
  ) {
    const totalAmount = initialPayment + yearlyPayment * years;
    return (
      calculateCompoundInterest(
        initialPayment,
        yearlyPayment,
        costRate,
        years,
        repetitionPerYear,
      ) - totalAmount
    );
  }
*/

  //const fv = calculateCompoundInterest(50, 360, 0.1096, 10, 12, 'start')
  //const costs = calculateCosts(50, 360, 0.01, 10, 365)

  //costs
  //fv - costs

  const getUserData = useCallback(async () => {
    // Para evitar rellamadas mientras espera
    if (edad === -1) {
      return 0;
    }
    setEdad(-1);

    console.log('getUserData', params);
    UsersAPI.getUserData(params.baseUrl, 'me', params.token)
      .then(response => {
        if (response.status === 200) {
          console.log('getUserData 1', response);
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getUserData 3', data);
        setEdad(calcularEdad(data.nif.dateOfBirth));
        Flow.setParams({ edad: edad });
      })
      .catch(error => {
        console.error('getUserData error recuperando datos de usuario ', error);
      });
  }, [params, edad]);

  function calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    //console.log('calcularEdad', fecha, edad);
    return edad;
  }

  // Modal Como -------------------------------------------------------------

  function ModalInfoComo(props) {
    return (
      <Modal
        open={openModalInfoComo}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setOpenModalInfoComo(false)}
      >
        <div
          style={{
            width: '312px',
            backgroundColor: '#e9e9e9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '14px',
            borderRadius: '8px',
            '&:focus': {
              outline: 'unset',
            },
          }}
        >
          <p>
            El rango de retornos es de{' '}
            <strong>
              {Currency(props.datosEscenario.porcDesde, 2, true)} %
            </strong>{' '}
            y{' '}
            <strong>
              {Currency(props.datosEscenario.porcHasta, 2, true)} %
            </strong>{' '}
            netos, calculado a partir de{' '}
            <span>una desviación estándar de la media.</span> El efecto de las
            comisiones ya está reflejado en el resultado.
          </p>
          <p>
            Hay un <strong>95% de probabilidades</strong> de situarte en los
            escenarios planteados. 14% para el positivo (2,5% de hacerlo aún
            mejor), 67% para el esperado y 14% para el negativo (2,5% de hacerlo
            aún peor)
          </p>
          <p>
            Cálculos para la cartera Dinámico. El rango de las proyecciones está
            construido utilizando volatilidades de carteras formadas con índices
            históricos a largo plazo. Fuente: Morningstar Direct 01/01/2000 a
            31/12/2018 y 01/01/1973 a 31/12/2018.
          </p>
          <p>
            El valor del rescate de la inversión podrá ser con rentabilidad nula
            e incluso negativa.{' '}
            <strong>Capital y rentabilidad no garantizados.</strong>
          </p>
        </div>
      </Modal>
    );
  }

  // Tabs -------------------------------------------------------------
  function TabResultado(props) {
    console.log('TabResultado', props);
    //ºº Esto debería hacerlo el propio componente MyTab pero no se como
    if (props.escenario === 'Optimista') {
      setActiveTab(2);
    } else if (props.escenario === 'Esperado') {
      setActiveTab(1);
    } else if (props.escenario === 'Pesimista') {
      setActiveTab(0);
    } else {
      console.error('TabResultado: Escenario no contemplado', props);
    }

    return (
      <div>
        <div
          style={{
            borderColor: 'white',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 5,
            backgroundColor: '#e0e0e0',
            marginBottom: 8,
            marginTop: -10,
          }}
        >
          {props.datosEscenario.finalDesde - props.aportado > 0 ? (
            <MyText
              style={{
                color: '#6c6c6c',
                marginTop: 10,
              }}
            >
              Tras {props.años} años, en este escenario, tu inversión podría
              generar ganancias de:
            </MyText>
          ) : (
            <div style={{ padding: 10 }}>
              <div>
                <div>
                  <i></i>
                </div>
                <div>
                  <p>
                    <strong>Atención</strong>: en este escenario, con un
                    horizonte previsto de tan solo {props.años} años , es más
                    probable que tu Plan <strong>incurra en pérdidas</strong>.
                  </p>
                  <p>
                    <strong>
                      Aunque no existe permanencia, es conveniente un plan a más
                      largo plazo. Selecciona una duración mayor para verlo.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                style={{
                  fontSize: 35,
                  color: 'black',
                  marginBottom: -15,
                }}
                id='finalDesde'
              >
                {Currency(props.datosEscenario.finalDesde, -2)}
              </Typography>
              <Typography
                style={{
                  fontSize: 20,
                  color:
                    props.datosEscenario.finalDesde - props.aportado > 0
                      ? 'green'
                      : 'red',
                  textAlign: 'right',
                }}
              >
                {props.datosEscenario.finalDesde - props.aportado > 0
                  ? '+'
                  : ''}

                {Currency(props.datosEscenario.finalDesde - props.aportado, -2)}
              </Typography>
            </div>

            {props.datosEscenario.finalHasta && (
              <Fragment>
                <MyText
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    margin: 20,
                    color: 'black',
                  }}
                >
                  y
                </MyText>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 35,
                      color: 'black',
                      marginBottom: -15,
                    }}
                  >
                    {Currency(props.datosEscenario.finalHasta, -2)}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 20,
                      color:
                        props.datosEscenario.finalHasta - props.aportado > 0
                          ? 'green'
                          : 'red',
                      textAlign: 'left',
                    }}
                  >
                    {props.datosEscenario.finalHasta - props.aportado > 0
                      ? '+'
                      : ''}
                    {Currency(
                      props.datosEscenario.finalHasta - props.aportado,
                      -2,
                    )}
                  </Typography>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        <div
          onClick={() =>
            Flow.navigate('/product/simulator/explanation', props, {
              impInicial,
              impMensual,
              comisionGestion,
              //costeAnual,
              tieneAportMensual,
              datosEscenario: props.datosEscenario,
              datosEscenarioStandard: props.datosEscenarioStandard,
              aportado,
              años,
              edad,
              seguroPorAños,
              productName: params.productName,
              //...props,
            })
          }
        >
          <MyText id='comoCalculamos' variant='link'>
            ¿Cómo calculamos estos datos?
          </MyText>
        </div>
        <InfoIcon
          id='comoCalculamosInfo'
          color='primary'
          style={{
            float: 'right',
            marginTop: -24,
          }}
          onClick={() => setOpenModalInfoComo(true)}
        />
        <ModalInfoComo datosEscenario={props.datosEscenario} />
        <Button
          variant='contained'
          color='secondary'
          style={{
            marginTop: 15,
          }}
          //classes={transformClasses('targetProducts_button1', classes)}
          onClick={() =>
            Flow.navigate('/product/simulator/resume', props, {
              impInicial,
              impMensual,
              comisionGestion,
              //costeAnual,
              tieneAportMensual,
              años,
              productName: params.productName,
              edad,
              seguroPorAños,
              escenario: props.escenario,
              datosEscenario: props.datosEscenario,
            })
          }
        >
          Ver resumen simulación
        </Button>
      </div>
    );
  }

  // Return PRINCIPAL -------------------------------------------------
  return (
    <Fragment>
      <Header modal={true} title='Simulación' history={props.history} />
      <Body vAlign='top' hAlign='initial'>
        <MyText variant='h1'>
          Mira como quedaría tu Plan {params.productName}
        </MyText>
        <MyTextForInput inLine label='¿Cuanto aportaras inicialmente?'>
          <Input
            id='impInicial'
            //ref={inputImpInicialRef}
            classes={{
              input: props.classes.inputNumberStyle,
            }}
            style={{
              width: 70,
            }}
            type='number'
            value={impInicial}
            placeholder='Ej: 500'
            onChange={e => setImpInicial(parseInt(e.target.value))}
          />
          <MyText variant='body' style={{ marginTop: 7, marginLeft: 5 }}>
            €
          </MyText>
        </MyTextForInput>
        <div>
          <MyTextForInput inLine label='Aportación mensual al plan'>
            <Input
              id='impMensual'
              //ref={inputImpMensualRef}
              classes={{
                input: props.classes.inputNumberStyle,
              }}
              style={{
                width: 70,
              }}
              type='number'
              disableUnderline
              value={impMensual}
              placeholder='Ej: 30'
              onChange={e => setImpMensual(parseInt(e.target.value))}
            />
            <MyText variant='body' style={{ marginTop: 7, marginLeft: 5 }}>
              €
            </MyText>
          </MyTextForInput>
        </div>
        <MyTextForInput label='Duración del plan' style={{ marginTop: 0 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <MyText
              style={{
                marginBottom: 10,
              }}
            >
              Mantener el plan durante {años} años
            </MyText>
            <Slider
              id='sliderAños'
              value={años}
              onChange={value => setAños(value)}
              min={1}
              max={40}
              trackStyle={{ backgroundColor: '#f4137b', height: 10 }}
              handleStyle={{
                borderColor: 'silver',
                height: 28,
                width: 28,
                marginLeft: -14,
                marginTop: -9,
                backgroundColor: 'white',
              }}
              railStyle={{ backgroundColor: 'white', height: 10 }}
              style={{
                marginBottom: 15,
              }}
            />
            <MyText
              style={{
                marginTop: 5,
              }}
            >
              aportaría
              <strong id='totalAportado'>
                {' ' + Currency(aportado) + ' '}
              </strong>{' '}
              en total.
            </MyText>
          </div>
        </MyTextForInput>
        <MyText>
          Dependiendo de como evolucione la economía mundial, tu{' '}
          <strong>Plan {params.productName}</strong> podría situarse en los
          siguientes escenarios:
        </MyText>
        <Grow in={aportado > 0 && impMensual > 0} timeout={0}>
          <div>
            <MyTabs
              history={props.history}
              currentTab={activeTab}
              onChangeTab={indexTab => setActiveTab(indexTab)}
              tabs={[
                {
                  label: 'Pesimista',
                  path: '/product/simulatorTab/pesimista',
                  render: () => (
                    <TabResultado
                      datosEscenario={pesimista}
                      datosEscenarioStandard={standard}
                      aportado={aportado}
                      history={props.history}
                      escenario='Pesimista'
                      años={años}
                    />
                  ),
                },
                {
                  label: 'Esperado',
                  path: '/product/simulatorTab/esperado',
                  render: () => (
                    <TabResultado
                      datosEscenario={esperado}
                      datosEscenarioStandard={standard}
                      aportado={aportado}
                      history={props.history}
                      escenario='Esperado'
                      años={años}
                    />
                  ),
                },
                {
                  label: 'Optimista',
                  path: '/product/simulatorTab/optimista',
                  render: () => (
                    <TabResultado
                      datosEscenario={optimista}
                      datosEscenarioStandard={standard}
                      aportado={aportado}
                      history={props.history}
                      escenario='Optimista'
                      años={años}
                    />
                  ),
                },
              ]}
            />
          </div>
        </Grow>
      </Body>
      <Footer />
    </Fragment>
  );
}

const styles = theme => ({
  modal: {
    width: '312px',
    backgroundColor: 'rgba(65,65,65,1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px',
    borderRadius: '8px',
    '&:focus': {
      outline: 'unset',
    },
  },

  inputNumberStyle: {
    color: 'white',
    padding: '6px 0 1px',
    borderBottomColor: 'white',
    borderBottomWidth: 1,
    borderBottomStyle: 'double',
    textAlign: 'right',
  },
  //...ProductsStyles(theme),
  ...SwipeableViewsConfigurableStyles(theme),
});

export default withStyles(styles, { withTheme: true })(TargetPerfilSimulacion);
