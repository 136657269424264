export function createEventForTarget(baseUrl, idTarget, idRule, data, token){
    return fetch(baseUrl + 'targets/' + idTarget + '/ruleDefinitions/' + idRule + '/events', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
}

export function getEventForTarget(baseUrl, idTarget, idRule, token){
    return fetch(baseUrl + 'targets/' + idTarget + '/ruleDefinitions/' + idRule + '/events', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
}

export function createGlobalEvent(baseUrl, idUser, idRule, data, token){
    return fetch(baseUrl + 'users/' + idUser + '/ruleDefinitions/' + idRule + '/events', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
}

export function getGlobalEvent(baseUrl, idUser, idRule, token){
    return fetch(baseUrl + 'users/' + idUser + '/ruleDefinitions/' + idRule + '/events', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
}

