const ListColors = [
  '#20bc99',
  '#f4137b',
  '#8345b9',
  '#ffa400',
  '#51cb00',
  '#147efb',

  '#fecb2e',
  '#fc3d39',
  '#5fc9f8',
  '#d0e6da',
  'TEAL',
  '#c13584',
  '#669c34',
  'PURPLE',
  '#e91188',
  '#5a8429',
  '#b96ad1',
  '#888792',
];

export default ListColors;
