import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import { Utils } from '../../src-core/resources/index';
//import { TargetsAPI } from '../../core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

import Currency from '../../components/Currency';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Header from '../../components/MyHeader';
import Body from '../../components/MyBody';
import ProgressBar from '../../components/MyProgressBar';
import ProgressBarConfig from '../../components/progressBar.config';
import ProgressBarStyles from '../../components/progressBar.style';

import FollowupGraphs from './followupGraphs';
import FollowupMovements from './followupMovements';
import FollowupMovementsDetails from './followupMovementsDetails';
import FollowupMovementsDetailsLines from './followupMovementsDetailsLines';
import FollowupRules from './followupRules';
import FollowupRulesMovements from './followupRulesMovements';
import FollowupInfoFamily from './followupInfoFamily';
import FollowupInfoWish from './followupInfoWish';
import FollowupFooter from './footer.component';
import { updatedValues } from '../../libs/updatedValuesProduct';

import { FollowupTotalDeposit } from './FollowupTotalDeposit';

/*
  Detalle de Objetivos
  Ruta: /followup
  
  Aquí se encarga de gestionar los tabs del seguimiento.
 */

class FollowupMain extends Component {
  constructor(props) {
    super(props);
    console.log('constructor', this);
    this.state = {
      targetID: this.props.match.params.id,
      target: null,
      rules: null,
      movements: null,
      nextMovement: null,
      rulesDefinitions: null,
      product: null,
      chartInfo: null,
    };
  }

  componentDidMount() {
    this.getTarget();
    this.getProduct();
    //    this.getChartInfo()
  }

  getTarget() {
    console.log('getTarget1', this);

    const { baseUrl } = this.props;
    TargetsAPI.getTargets(baseUrl, this.state.targetID, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getTarget', data);
        this.setState({
          target: data,
        });
      })
      .catch(error => {
        console.log('error consiguiendo el target', error);
      });
  }

  getProduct() {
    TargetsAPI.getTargetProductInfo(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('getProduct', data);

        this.setState({ product: data });
      })
      .catch(error => {
        console.log(
          'error recuperando datos del producto asociado al target ',
          error,
        );
      });
  }

  updateRules(rules) {
    console.log('followupMain.updateRules', rules);
    this.setState({
      rules: rules,
    });
  }

  updateMovements(movements) {
    this.setState({
      movements: movements,
    });
  }

  updateNextMovement(movement) {
    this.setState({
      nextMovement: movement,
    });
  }

  updateRulesDefinitions(definitions) {
    console.log('followupMain.updateRulesDefinitions', definitions);
    this.setState({
      rulesDefinitions: definitions,
    });
  }

  updateProduct(product) {
    this.setState({
      product: product,
    });
  }

  filterFounds() {
    const foundCode = this.state.product.foundsConfiguration[0].foundCode;
    console.error(
      'filterFounds',
      this.props,
      foundCode,
      this.state.product.founds.filter(el => el.code === foundCode)[0],
    );
    return this.state.product.founds.filter(el => el.code === foundCode)[0];
  }

  render() {
    let product = null;
    if (this.state.product && !this.state.product.name) {
      product = this.filterFounds();
    }
    if (product?.code && this.state?.product?.identifier) {
      product = {
        ...product,
        ...updatedValues(product.code + this.state.product.identifier),
      };
    }

    console.log('followupMain render', {
      props: this.props,
      state: this.state,
      product,
    });
    const { classes } = this.props;
    const transformClasses = Utils.transformClasses;

    return this.state.target !== null ? (
      <Fragment>
        <Header title='Seguimiento' modal={true} history={this.props.history}>
          {/*<div className={classes.headersContainer}>*/}
          <div className={classes.targetNameContainer}>
            <SvgIcon
              /*color='primary'*/
              style={{ width: 45, height: 45 }}
            >
              <path d='M13.2,8.4c0,0,0,0,0.2,0c0.7,0,2-0.7,2.5-1.2c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.4-1.1-0.4-1.5,0 C13.7,6.4,13,8.1,13.2,8.4z M8.4,7.3c0.4,0.4,1.8,1.2,2.5,1.2c0.1,0,0.2,0,0.2,0c0.2-0.2-0.4-2-1.1-2.7C9.8,5.5,9.5,5.4,9.2,5.4 c-0.3,0-0.6,0.1-0.8,0.3C8,6.1,8,6.8,8.4,7.3z M5.1,11.5h6.6v-2H5.1V11.5z M6.1,18.6h5.6v-6.1H6.1V18.6z M12.7,18.6h5.6v-6.1 h-5.6V18.6z M12.7,11.5h6.6v-2h-6.6V11.5z M19.3,8.4c0.6,0,1,0.5,1,1V12c0,0.3-0.2,0.5-0.5,0.5h-0.5v6.1c0,0.6-0.5,1-1,1H6.1  c-0.6,0-1-0.5-1-1v-6.1H4.5C4.3,12.5,4,12.3,4,12V9.4c0-0.6,0.5-1,1-1h3.2C8,8.3,7.8,8.1,7.7,8c-0.8-0.8-0.8-2.2,0-3 c0.8-0.8,2.2-0.8,3,0c0.4,0.4,1.6,2.2,1.4,3.4h0.1c-0.2-1.2,1-3,1.4-3.4c0.8-0.8,2.2-0.8,3,0c0.8,0.8,0.8,2.2,0,3 c-0.1,0.1-0.3,0.3-0.6,0.5H19.3z' />
            </SvgIcon>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='body1'
                  color='primary'
                  classes={transformClasses('followup_targetName', classes)}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 180,
                    fontSize: 30,
                  }}
                >
                  {this.state.target.name}
                </Typography>
                {this.state.target.nonBrokenStatus === 'PAUSED' && (
                  <PauseCircleOutlineIcon
                    style={{ marginLeft: 5, fontSize: 30 }}
                  />
                )}
              </div>

              {this.state.target.status !== 'FINISHED' &&
                this.state.target.status !== 'CANCELED' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  id='editarButton'
                  onClick={() =>
                    this.props.history.push(
                      '/configure/target/' + this.state.targetID + '/info',
                    )
                  }
                >
                  <Typography
                    variant='body1'
                    color='primary'
                    classes={transformClasses('followup_targetName', classes)}
                    style={{
                      fontSize: 20,
                      textAlign: 'right',
                      paddingTop: 12,
                    }}
                  >
                    Editar
                  </Typography>
                  <SvgIcon
                    color='primary'
                    style={{
                      float: 'right',
                      display: 'block',
                      fontSize: 40,
                    }}
                  >
                    <path d='M18.8,9.3L15,5.6c0.5-0.4,1.1-0.6,1.7-0.6c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9      C19.4,8.3,19.2,8.9,18.8,9.3z M9.5,18.6L8,17.1l8.6-8.6l1.5,1.5L9.5,18.6z M14.3,6.2l1.6,1.6l-8.6,8.6l-1.6-1.6L14.3,6.2z       M19.3,5.1c-1.4-1.4-3.7-1.4-5.1,0l-9.4,9.4c-0.1,0.1-0.1,0.2-0.1,0.3l-0.7,5.2c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1      c0,0,0,0,0.1,0L7.5,20c0.3,0,0.4-0.3,0.4-0.5c0-0.3-0.3-0.4-0.5-0.4l-2.5,0.3l0.5-3.6l3.8,3.8c0.1,0.1,0.2,0.1,0.3,0.1      s0.2,0,0.3-0.1l9.4-9.4c0.7-0.7,1.1-1.6,1.1-2.6S20,5.8,19.3,5.1z' />
                  </SvgIcon>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
          {this.state.target?.status === 'SENT' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#00000050',
                alignItems: 'center',
                padding: '15px 5px 10px 5px',
                margin: '5px 10px',
                color: '#ffffff',
                borderRadius: 5,
              }}
            >
              <Typography style={{
                fontSize: 16,
                fontWeight: 'bold',
              }}>
                PENDIENTE DE FIRMA
              </Typography>
              <Typography style={{
                fontSize: 14,
                textAlign: 'center',
              }}>
                Si no se firma antes de 24 horas tras su creación, el objetivo se eliminará automáticamente.
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f4137b',
                  padding: '8px 8px 5px 8px',
                  margin: '5px 10px',
                  color: '#ffffff',
                  borderRadius: 5,
                  lineHeight: 1,
                }}
                onClick={() =>
                  this.props.history.push(
                    '/documents/' + this.state.targetID,
                  )
                }
              >
                Pulsa aquí para firmar.
              </Typography>
            </div>
          )}
          <div
            style={{
              padding: 10,
              width: '100%',
              maxWidth: 375,
              paddingBottom: 5,
            }}
          >
            <div className={classes.followup_targetInfoContainer}>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses('followup_percentage', classes)}
              >
                {(
                  ((this.state.target.progress.completed >
                    this.state.target.progress.total
                    ? this.state.target.progress.total
                    : this.state.target.progress.completed) /
                    this.state.target.progress.total) *
                  100
                ).toFixed(2) + ' %'}
              </Typography>

              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses('followup_progress', classes)}
              >
                {Currency(this.state.target.progress.completed, 2) +
                  ' de ' +
                  Currency(this.state.target.progress.total)}
              </Typography>
            </div>

            <ProgressBar
              config={ProgressBarConfig}
              classes={classes}
              completed={this.state.target.progress.completed}
              total={this.state.target.progress.total}
            />

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant='body1' color='primary'>
                Total acumulado
              </Typography>
              <Typography variant='body1' color='primary'>
                Total objetivo
              </Typography>
            </div>
          </div>
        </Header>

        <Switch>
          <Route
            path='/followup/:id/Gráficos'
            render={props => (
              <Body vAlign='top'>
                {this.state.target.type === 'retirement' && (
                  <FollowupTotalDeposit />
                )}
                <FollowupGraphs
                  {...props}
                  token={this.props.token}
                  targetID={this.state.targetID}
                  baseUrl={this.props.baseUrl}
                  config={{}}
                  target={this.state.target}
                  type={this.state.target.type}
                  product={product}
                />
              </Body>
            )}
          />
          <Route
            path='/followup/:id/Movimientos/:idMovement/detalles/:idLine/transacciones'
            render={props => (
              <Body vAlign='top'>
                <FollowupMovementsDetailsLines
                  {...props}
                  token={this.props.token}
                  targetID={this.state.targetID}
                  baseUrl={this.props.baseUrl}
                  config={{}}
                />
              </Body>
            )}
          />

          <Route
            path='/followup/:id/Movimientos/:idMovement/detalles'
            render={props => (
              <Body vAlign='top'>
                <FollowupMovementsDetails
                  {...props}
                  token={this.props.token}
                  targetID={this.state.targetID}
                  baseUrl={this.props.baseUrl}
                  config={{}}
                />
              </Body>
            )}
          />
          <Route
            path='/followup/:id/Movimientos'
            render={props => (
              <Body vAlign='top'>
                <FollowupMovements
                  {...props}
                  token={this.props.token}
                  targetID={this.state.targetID}
                  target={this.state.target}
                  baseUrl={this.props.baseUrl}
                  history={this.props.history}
                  movements={this.state.movements}
                  updateMovements={this.updateMovements.bind(this)}
                  nextMovement={this.state.nextMovement}
                  updateNextMovement={this.updateNextMovement.bind(this)}
                />
              </Body>
            )}
          />
          <Route
            path='/followup/:id/Reglas/movimientos/:idRule'
            render={props => (
              <Body vAlign='top'>
                <FollowupRulesMovements
                  {...props}
                  token={this.props.token}
                  baseUrl={this.props.baseUrl}
                />
              </Body>
            )}
          />
          <Route
            path='/followup/:id/Reglas'
            render={props => (
              <Body vAlign='top'>
                <FollowupRules
                  {...props}
                  token={this.props.token}
                  targetID={this.state.targetID}
                  baseUrl={this.props.baseUrl}
                  updateRules={this.updateRules.bind(this)}
                  updateRulesDefinitions={this.updateRulesDefinitions.bind(
                    this,
                  )}
                  rules={this.state.rules}
                  rulesDefinitions={this.state.rulesDefinitions}
                  location={this.props.location}
                  status={this.state.target.status}
                  type={this.state.target.type}
                />
              </Body>
            )}
          />
          <Route
            path='/followup/:id/Información'
            render={props => (
              <Body vAlign='top'>
                {this.state.target.type === 'nextWish' ? (
                  <FollowupInfoWish
                    {...props}
                    classes={this.props.classes}
                    baseUrl={this.props.baseUrl}
                    token={this.props.token}
                    updateProduct={this.updateProduct.bind(this)}
                    product={product}
                    type={this.state.target.type}
                  />
                ) : (
                  <FollowupInfoFamily
                    {...props}
                    baseUrl={this.props.baseUrl}
                    token={this.props.token}
                    updateProduct={this.updateProduct.bind(this)}
                    product={product}
                    type={this.state.target.type}
                  />
                )}
              </Body>
            )}
          />
        </Switch>

        <FollowupFooter
          location={this.props.location}
          match={this.props.match}
          history={this.props.history}
        />
      </Fragment>
    ) : (
      <div className={classes.followup_loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

const styles = theme => ({
  ...ProgressBarStyles(theme),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '10vh',
    minHeight: 57,
    padding: 12,
    transition: 0.2,
    zIndex: 99999,
    backgroundColor: 'transparent',
  },
  followup_container: {
    //height: '100%',
    height: '100vh',
    display: 'flex',
    //justifyContent: 'space-evenly',
    flexFlow: 'column',
  },
  followup_movilScreen: {
    maxWidth: 400,
    margin: 'auto',
    maxHeight: 'inherit',
  },
  followup_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  followup_imgDiv: {
    position: 'relative',
    maxWidth: '100%',
    height: '25vh',
    minHeight: 142,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  progressBar_mobileStepper_progress: {
    /*position: 'absolute',
    bottom: 0,*/
    height: 10,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  listTargetMovements_divListContainer: {
    backgroundColor: 'none',
    flex: 1,
  },
  listTargetMovementsDetails_divListContainer: {
    backgroundColor: 'none',
  },
  /*
  followup_imgDiv_overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
*/

  chart: {
    paddingLeft: 8,
    paddingRight: 8,
  },

  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
  headersContainer: {
    //height: '15vh',
    //minHeight: 85,
    marginTop: -20,
    width: '100%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  followup_header_iconButton1_root: {
    padding: 'unset',
  },
  followup_header_iconButton2_root: {
    padding: 'unset',
  },
  followup_targetInfoContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },
  followup_targetName_root: {
    fontSize: 45,
    fontWeight: 200,
    //textTransform: 'lowercase',
    lineHeight: '28px',
    paddingTop: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 43,
    width: '100%',
    whiteSpace: 'nowrap',
  },
  followup_percentage_root: {
    fontSize: 25,
    fontWeight: 300,
    textTransform: 'lowercase',
    lineHeight: '30px',
  },
  followup_progress_root: {
    fontSize: 20,
    fontWeight: 300,
    textTransform: 'lowercase',
    lineHeight: '12px',
  },
  targetNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 380,
  },

  // ------------------------ Wish
  movilScreen: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '640px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
  },
  targetSummary_header_left_icon_root: {
    height: '17px',
    width: '17px',
    marginLeft: '27.5px',
  },
  targetSummary_header_right_icon_root: {
    height: '19.8px',
    width: '19.8px',
    marginRight: '23.2px',
  },
  targetSummary_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '18px',
  },
  targetSummary_header_title_typography_root: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '22.5px',
  },
  targetSummary_body_container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: '0 24px',
    flex: 1,
  },
  targetSummary_title_icon_root: {
    //marginTop: '23px',
    width: '25px',
    height: '32px',
    marginBottom: '12px',
  },
  targetSummary_title_typography_root: {
    fontSize: '45px',
    fontWeight: 100,
    textAlign: 'center',
    letterSpacing: '-1px',
    lineHeight: '39.4px',
    marginBottom: '28px',
  },
  targetSummary_body_targetSelector_container: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    borderBottom: 'solid 1px rgba(255,255,255,.3)',
    marginBottom: '18px',
    textAlign: 'left',
  },
  targetSummary_body_targetSelector_leftIco_container: {
    width: '24.9px',
    height: '27.5px',
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  targetSummary_body_targetSelector_leftIco_svgIcon_root: {
    width: '100%',
    height: '100%',
  },
  targetSummary_body_targetSelector_info_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  targetSummary_body_targetSelector_info_firstRow_typography_root: {
    fontSize: '11px',
    lineHeight: '11.3px',
    letterSpacing: '0px',
    fontWeight: 600,
    marginLeft: '11.5px',
    marginBottom: '6px',
  },
  targetSummary_body_targetSelector_info_secondRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  targetSummary_body_targetSelector_info_secondRow_typography1_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    //color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  targetSummary_body_targetSelector_info_secondRow_input_root: {
    fontSize: '16px',
    lineHeight: '16.9px',
    color: theme.palette.primary.main,
    marginLeft: '11.5px',
    '&::placeholder': {
      color: 'rgba(255,255,255,.5)',
    },
  },
  targetSummary_body_targetSelector_info_secondRow_typography2_root: {
    fontSize: '16px',
    marginTop: '8px',
    lineHeight: '16.9px',
    color: 'rgba(255,255,255,.5)',
    marginLeft: '11.5px',
    marginBottom: '7.9px',
  },
  targetSummary_button1_root: {
    width: '312px',
    height: '40px',
    marginBottom: '24px',
    marginTop: 'auto',
    borderRadius: '4px',
  },
  targetSummary_button1_label: {
    fontSize: '14px',
    fontWeight: 600,
  },
  targetSummary_company_logo: {
    width: 100,
    height: 'auto',
    marginTop: '10px',
  },
  targetSummary_company_label_typography_root: {
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '22px',
    width: '260px',
  },
  targetSummary_renta_container: {
    width: '312px',
    backgroundColor: 'rgba(255,255,255,0.098)',
    marginTop: '17px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  targetSummary_renta_typography1_root: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'center',
    letterSpacing: '0px',
    marginTop: '17px',
    textTransform: 'uppercase',
    paddingBottom: 10,
  },
  targetSummary_renta_typography2_root: {
    fontSize: '33px',
    lineHeight: '30px',
    fontWeight: 100,
    letterSpacing: '0px',
    marginTop: '5px',
  },
  targetSummary_renta_typography3_root: {
    fontSize: '14px',
    textAlign: 'center',
    width: '248px',
    letterSpacing: '0px',
    marginBottom: '5px',
  },
  targetSummary_body_summary_container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  targetSummary_check: {
    color: 'white',
  },
});

export default withStyles(styles, { withTheme: true })(FollowupMain);
