import colors from '../colors';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputSwipe', {
  styles: {
    default: {
      textComponent: {
        paddingBottom: 0,
      },
    },
    fullWidthSt: {
      containerComponent: {
        width: '100%',
      },
      swipeContainerComponent: {
        width: '100%',
      },
    },
  },
});
