import React, { useContext } from 'react';
import { View, Text, Button } from 'coinscrap-webapp-core';
import Flow from '../../components/Flow';
import Header from '../../components/MyHeader';
import Footer from '../../components/MyFooter';
import routes from '../../config/routes';
import { StoreContext } from '../../pensionStore/Store';
import * as Types from '../../pensionStore/types';
import Tareas from '../common/svgs/Tareas';
import colors from '../../config/colors';

export default props => {
  const flowParams = Flow.getParams();

  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  return (
    <>
      <Header
        title='Añadir Objetivo'
        rigthAction={() => props.history.push('/home/objetivos')}
        leftAction={() =>
          flowParams.flujo && flowParams.flujo === 'transfer'
            ? props.history.push(routes.retirementTransferSelectPlan)
            : props.history.goBack()
        }
      />
      <View bodyTwoButtonsSt justifyCenterSt>
        <View width85St>
          <View>
            <View iconMarginBottomSt>
              <Tareas style={{ color: colors.iconPurple }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              Configura tu plan de pensiones
            </Text>
            <Text centerSt font16St>
              Ahora, decide si quieres que te ayudemos a fijar tu objetivo con
              una simulación o si prefieres hacerlo por tu cuenta.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          {flowParams.flujo && flowParams.flujo !== 'transfer' && (
            <Button
              onClick={() => {
                dispatch({
                  type: Types.NEW_PLAN_SIMULATION,
                  payload: {
                    started: true,
                  },
                });
                props.history.push(routes.retirementSimulation);
              }}
            >
              REALIZAR SIMULACIÓN
              {/* ¡VAMOS AllÁ! */}
            </Button>
          )}
          <Button
            greenButtonSt
            onClick={() => {
              dispatch({
                type: Types.NEW_PLAN_MANUAL,
                payload: {
                  started: true,
                },
              });
              Flow.navigate(
                '/create/target/retirement/products/selector',
                props,
                { mode: 'retirementManual' },
              );
            }}
            //   onClick={() => this.saveInfo()}
          >
            {flowParams.flujo && flowParams.flujo !== 'transfer'
              ? 'CONTINUAR MANUALMENTE'
              : 'CONTINUAR'}

            {/* ¡VAMOS AllÁ! */}
          </Button>
        </View>
      </Footer>
    </>
  );
};
