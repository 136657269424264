import styled from 'styled-components/macro';
import colors from '../../../../config/colors';

export const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const Label = styled.p`
  color: white;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 700;
`;
export const PasswordInputWrapper = styled.div`
  display: flex;
  position: relative;
`;
export const SelectWrapper = styled.div`
  .MuiSelect-select {
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
  }

  .MuiSelect-icon {
    top: calc(50% - 15px);
    width: 30px;
    height: auto;
    right: 10px;
  }

  .Mui-focused {
    border-color: #30353f;
  }
  .MuiInputBase-root {
    width: 100%;
    height: 16px;
    border: ${props => {
      if (props.value === 0) {
        return '1px solid #30353F';
      } else {
        return '1px solid #680079';
      }
    }};

    border-radius: 5px;
    padding: 16px 13px;
    &::before {
      border-bottom: none;
    }

    &:hover {
      &::before {
        border-bottom: none !important;
      }
    }
  }
`;
export const PasswordInputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-right: 20px;
`;
export const InputSuggestWrapper = styled.div`
  position: relative;
  width: 100%;
`;
export const InputSuggestCloseWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const SuggestionsUl = styled.ul`
  border: 1px solid #dadada;
  list-style: none;
  max-height: 500px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: absolute;
  top: 105%;
  z-index: 150;
  background: white;
  &::-webkit-scrollbar {
    width: 32px;
    height: 0;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(104, 0, 121, 1);
  }
`;

export const SuggestionsLi = styled.li`
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  &:hover {
    background: gray;
    color: blue;
    cursor: pointer;
    font-weight: 700;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
`;

export const InputWrapper = styled.input`
  border: none;
  border-bottom: ${props => {
    //if (!props.value) {
    return `2px solid ${colors.pink}`;
    //} else {
    //  return '1px solid var(--color-primary)';
    //}
  }};
  height: 14px;
  color: white;
  font-size: 18px;
  padding: 16px 0px;
  outline: none;
  font-family: Calibre;
  background: transparent;
  width: 250px;
  font-weight: 100;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #ffffff80;
  }
`;
