import colors from '../colors';
import fonts from '../fonts';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('textField', {
  styles: {
    default: {
      containerComponent: {
        marginTop: 15,
        marginBottom: 15,
      },
      inputContainerComponent: {
        backgroundColor: '#FAFBFC',
      },
      inputComponent: {
        fontSize: '25px',
        fontWeight: 400,
        textAlign: 'left',
      },
      currencyMode: {
        iconComponent: {
          color: colors.green,
        },
        inputComponent: {
          borderBottomColor: colors.green,
        },
      },
      speechMode: {
        iconComponent: {
          color: colors.green,
        },
      },
      listeningMode: {
        iconComponent: {
          color: colors.red,
        },
      },
    },

    fullWidthSt: {
      containerComponent: {
        width: '100%',
      },
    },
    mediumSt: {
      containerComponent: {
        borderBottom: '2px solid' + colors.blue,
        paddingBottom: 12,
      },
      inputComponent: {
        color: '#444444',
        fontSize: 30,
        fontWeight: 'light',
        textAlign: 'center',
        fontFamily: 'SantanderTextRegular',
        backgroundColor: colors.white,
        textDecorationColor: colors.green,
        padding: 0,
      },
    },
    inputImgModalBackgroundSt: {
      containerComponent: {
        width: '100%',
        height: 50,
        backgroundColor: '#EAEAEA',
        borderRadius: '4px 4px 0px 0px',
        paddingBottom: 0,
        justifyContent: 'center',
        padding: '0px 15px',
        borderBottom: '1px solid' + colors.pink,
        overflow: 'hidden',
      },
      inputContainerComponent: {
        backgroundColor: '#EAEAEA',
        height: '100%',
      },
      inputComponent: {
        color: colors.blackLight,
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        textAlign: 'left',
        backgroundColor: '#EAEAEA',
        fontFamily: fonts.SantanderTextRegular,
        height: '100%',
        paddingBottom: 20,
      },
      labelComponent: {
        display: 'block',
        fontSize: 16,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.grey3,
      },
    },
    bigSt: {
      containerComponent: {
        borderBottom: '2px solid' + colors.blue,
        paddingBottom: 12,
      },
      inputComponent: {
        color: colors.grey,
        fontSize: 35,
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'SantanderTextRegular',

        padding: 0,
        height: 40,
      },
    },
    lightBcSt: {
      inputComponent: {
        backgroundColor: '#ffffff',
      },
    },
    BorderBottomSt: {
      inputContainerComponent: {
        paddingBottom: 0,
      },
    },

    squareInputSt: {
      containerComponent: {
        border: '1px solid #DEEDF2',
        boxSizing: 'border-box',
        width: '100%',
        height: '64px',
        borderRadius: 0,
        borderBottom: '1px solid' + colors.blue2,
        // borderBottom: 'none',
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: colors.white,
        borderRadius: 0,
        height: '100%',
      },

      inputComponent: {
        height: '100%',
        paddingBottom: 20,
        fontSize: 28,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.blackLight,
        textAlign: 'center',
      },

      focusedMode: {
        inputContainerComponent: {
          backgroundColor: colors.white,
          borderRadius: 0,
          height: '100%',
        },
      },
    },

    inputMovementSt: {
      containerComponent: {
        height: '55px',
      },
    },

    squareWithBorderSt: {
      containerComponent: {
        border: '1px solid #DEEDF2',
        boxSizing: 'border-box',
        width: '100%',
        height: '64px',
        borderBottom: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        // borderBottom: 'none',
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: '#FAFBFC',
        borderBottom: '1px solid #9BC3D3',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: '100%',
      },

      inputComponent: {
        height: '100%',
        paddingBottom: 6,
        fontSize: 17,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.blackLight,
        textAlign: 'center',
      },
    },

    topRoundInputSt: {
      containerComponent: {
        border: '1px solid #EAEAEA',
        boxSizing: 'border-box',
        width: '100%',
        height: '56px',
        borderRadius: 0,
        borderBottom: '1px solid' + colors.pink,
        // borderBottom: 'none',
        marginBottom: 0,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        overflow: 'hidden',
      },
      inputContainerComponent: {
        backgroundColor: colors.greyBcInput,
        borderRadius: 0,
        height: '100%',
      },

      inputComponent: {
        height: '100%',
        fontSize: 18,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.blackLight,
        width: '100%',
        textAlign: 'center',
        paddingBottom: 20,
      },

      labelComponent: {
        display: 'block',
        width: '93%',
        textAlign: 'center',
        fontSize: 18,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.grey3,
      },

      // focusedMode: {
      //   labelComponent: {
      //     display: 'hidden',
      //   },
      // },
    },

    onBlurWithTextSt: {
      labelComponent: {
        display: 'none',
      },
      focusedMode: {
        labelComponent: {
          display: 'none',
        },
      },
    },
    onFocusWithoutTextSt: {
      labelComponent: {
        display: 'block',
      },
      focusedMode: {
        labelComponent: {
          display: 'block',
        },
      },
    },
    topRoundContactSt: {
      containerComponent: {
        border: '1px solid #EAEAEA',
        boxSizing: 'border-box',
        width: '100%',
        height: '48px',
        borderRadius: 0,
        borderBottom: '1px solid' + colors.pink,
        // borderBottom: 'none',
        marginBottom: 0,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        overflow: 'hidden',
      },
      inputContainerComponent: {
        backgroundColor: colors.greyBcInput,
        borderRadius: 0,
        height: '100%',
      },

      inputComponent: {
        height: '100%',
        fontSize: 18,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.blackLight,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 20,
      },

      labelComponent: {
        display: 'block',
        fontSize: 18,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.grey3,
        transform: 'translate(10px, 13px)',
      },
    },
    transparentInputSt: {
      containerComponent: {
        // border: '1px solid #EEF0F2',
        border: 'none',
        boxSizing: 'border-box',
        width: '100%',
        height: '48px',
        borderRadius: 0,
        // borderBottom: '2px solid' + colors.greyLightBorders2,
        borderBottom: 'none',
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        height: '100%',
      },

      inputComponent: {
        height: '100%',

        fontSize: 17,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.blackLight,
      },
    },
    GeneralInputSt: {
      containerComponent: {
        borderBottom: '1px solid #9bc3d3',
        boxSizing: 'border-box',
        width: '100%',
        height: '50px',
        borderRadius: 0,
        marginBottom: 10,
        marginTop: 10,
      },
      inputContainerComponent: {
        backgroundColor: '#f4f6f7',
        borderRadius: 0,
        height: '100%',
        alignItems: 'initial',
      },
      inputComponent: {
        height: '100%',
        fontSize: 17,
        lineHeight: '20px',
        color: '#444444',
        textAlign: 'left',
        padding: '5px 15px',
        fontFamily: 'SantanderTextRegular',
      },
      focusedMode: {
        labelComponent: {
          fontSize: 12,
          lineHeight: '16px',
          color: '#727272',
          fontFamily: 'SantanderTextRegular',
          transform: 'translate(15px, 5px) scale(1) !important',
        },
        inputContainerComponent: {
          alignItems: 'initial',
          backgroundColor: '#f4f6f7',
          border: '0',
        },
        containerComponent: {
          borderBottom: '2px solid #9bc3d3',
        },
      },
    },

    currencyTextFieldWithBorderSt: {
      labelComponent: {
        fontSize: 17,
        color: '#727272',
        lineHeight: '20px',
        fontFamily: 'SantanderTextRegular',
        marginTop: '-4px',
      },
      containerComponent: {
        marginTop: 0,
        marginBottom: 0,
        borderBottomWidth: 1,
        borderBottomColor: '#9BC3D3',
      },
      inputContainerComponent: {
        width: 'auto',
        paddingRight: 7,
      },

      currencyMode: {
        inputComponent: {
          textAlign: 'left',
        },
        iconComponent: {
          borderLeft: '1px solid #E5E5E5',
          paddingLeft: 9,
          paddingRight: 4,
          // paddingTop: 15,
          // paddingBottom: 5,
          height: '28px',
          boxSizing: 'content-box',
          marginTop: 0,
          color: colors.green,
        },
      },
    },
    paddingTopSt: {
      inputComponent: {
        paddingTop: 15,
        height: 25,
      },
    },

    transparentSt: {
      inputComponent: {
        height: 38,
        backgroundColor: 'transparent',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
      },
      focusedMode: {
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },

    textLeftSt: {
      inputComponent: {
        textAlign: 'left',
      },
    },
    squareInput2St: {
      containerComponent: {
        border: '1px solid #EEF0F2',
        boxSizing: 'border-box',
        width: '100%',
        height: '48px',
        borderRadius: 0,
        borderBottom: '2px solid' + colors.greyLightBorders2,
        // borderBottom: 'none',
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: '#FAFBFC',
        borderRadius: 0,
        height: '100%',
      },

      inputComponent: {
        height: '100%',

        fontSize: 17,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.blackLight,
      },

      // focusedMode: {
      //   inputContainerComponent: {
      //     border: 'none',
      //     transform: 'none',
      //     borderBottom: '10px solid #EEF0F2',
      //     after: {
      //       display: 'none',
      //     },
      //   },
      //   'inputContainerComponent::after': {
      //     border: 'none',
      //   },
      // },
    },
    noMarginSt: {
      containerComponent: {
        marginTop: 0,
      },
      inputComponent: {
        paddingBottom: 0,
        lineHeight: '20px',
      },
    },

    smallInputSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        width: '65%',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
      },
      inputComponent: {
        backgroundColor: 'transparent',
        fontSize: '18px',
        lineHeight: 1.1,
        fontWeight: 400,
        textAlign: 'right',
        padding: 0,
        margin: 0,
        color: colors.white,
        fontWeight: 100,
      },
      focusedMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
      disabledMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },
    productSummaryYearInputSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        padding: '0px !important',
        margin: '0px !important',
        width: '65%',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        padding: '0px !important',
        margin: '0px !important',
      },
      inputComponent: {
        backgroundColor: 'transparent',
        fontSize: '18px',
        lineHeight: 1.1,
        fontWeight: 400,
        textAlign: 'left',
        padding: '0px !important',
        margin: '0px !important',
        color: colors.white,
        fontWeight: 100,
      },
      focusedMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
      disabledMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },
    selectPlanSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        width: '65%',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        borderBottom: `2px solid ${colors.pink}`,
      },
      inputComponent: {
        backgroundColor: 'transparent',
        fontSize: '20px',
        lineHeight: 1.1,
        fontWeight: 600,
        textAlign: 'center',
        padding: 0,
        paddingTop: 5,
        margin: 0,
        color: colors.white,
        fontWeight: 100,
      },

      focusedMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },
    salaryInputSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: '0px !important',
        width: 115,
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
      },
      inputComponent: {
        backgroundColor: 'transparent',
        fontSize: 32,
        lineHeight: 1.1,
        fontWeight: 600,
        textAlign: 'center',
        padding: 0,
        paddingTop: 5,
        margin: 0,
        color: colors.white,
        fontWeight: 100,
      },

      focusedMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },

    reglaTraspasoSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: '0px !important',
        width: 250,
        borderBottom: '1px solid white',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
      },
      inputComponent: {
        backgroundColor: 'transparent',
        fontSize: 32,
        lineHeight: 1.1,
        fontWeight: 600,
        textAlign: 'center',
        padding: 0,
        paddingTop: 5,
        margin: 0,
        color: colors.white,
        fontWeight: 100,
      },

      focusedMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});
