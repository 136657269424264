import { connect } from 'react-redux';
import Home from '../views/Home/Home';

function mapStateToProps(state) {
  const { token, baseUrl } = state.main;
  return {
    token,
    baseUrl,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
