import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '../../../components/MyIcon';
import { View, Text, Button, Enfatize } from 'coinscrap-webapp-core';
import Header from '../../../components/MyHeader';
import SvgIcon from '@material-ui/core/SvgIcon';
import backgroundImage from './bg_new.png';

export default props => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Icon
        name='info'
        style={{
          width: 35,
          height: 35,
          ...props.style,
        }}
        onClick={() => setOpen(true)}
      />

      <Modal
        open={open}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setOpen(false)}
      >
        <View
          startSt
          style={{
            height: '100%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: 'center top',
            overflowY: 'scroll',
          }}
        >
          <Header
            title='Cálculos Planes de Pensiones'
            rigthAction={() => setOpen(false)}
          />

          <View
            width90St
            style={{
              marginTop: 30,
              flex: '0 1 auto',
              overflow: 'auto',
              justifyContent: 'flex-start',

              minHeight: 'calc(100% - 160px)',
            }}
          >
            <Text font14St style={{ marginBottom: 20 }}>
              El resultado de la simulación se ha realizado con las siguientes
              estimaciones:
            </Text>

            <View flexRowSt spaceBetweenSt topSt style={{ marginBottom: 15 }}>
              <View pinkDotSt />
              <Text font14St style={{ padding: 0 }}>
                <Enfatize font14St>
                  Salario proyectado a la jubilación:
                </Enfatize>{' '}
                Es la proyección del importe salarial que se ha incluido en la
                simulación aplicando un % de crecimiento salarial hasta el año
                de jubilación. Por defecto se asume que este porcentaje será del
                2%.
              </Text>
            </View>
            <View flexRowSt spaceBetweenSt topSt style={{ marginBottom: 15 }}>
              <View pinkDotSt />
              <Text font14St style={{ padding: 0 }}>
                <Enfatize font14St>
                  Nivel de ingresos durante la jubilación:
                </Enfatize>{' '}
                El nivel de ingresos durante la jubilación son los ingresos
                totales que el usuario quiere cobrar durante su jubilación. Se
                calcula sumando la pensión pública con el ahorro disponible para
                planes de pensiones. Se calcula como un % dividiendo entre el
                nivel de ingresos durante jubilación y los ingresos actuales.
                P.e. si una persona quiere recibir los mismo ingresos en la
                jubilación que en el momento actual
              </Text>
            </View>
            <View flexRowSt spaceBetweenSt topSt style={{ marginBottom: 15 }}>
              <View pinkDotSt />
              <Text font14St style={{ padding: 0 }}>
                <Enfatize font14St>
                  Necesidades de ahorro para jubilarse a un % de nivel de
                  ingresos.
                </Enfatize>{' '}
                Este cálculo se realiza calculando cuánto es el ahorro a
                acumular en el momento de jubilación para poder garantizar el
                nivel de ingresos seleccionado. Para ello se calcula la
                diferencia entre el nivel de ingresos seleccionado y la pensión
                pública durante los años que van desde el momento de jubilación
                hasta la esperanza de vida en España (Por defecto 83 años). Esta
                cantidad se descuenta al momento de jubilación y se compara con
                la proyección de los ahorros proyectados destinados a la
                jubilación. La diferencia es el importe a ahorrar desde el
                momento actual hasta la jubilación.
              </Text>
            </View>
            <View flexRowSt spaceBetweenSt topSt style={{ marginBottom: 15 }}>
              <View pinkDotSt />
              <Text font14St style={{ padding: 0 }}>
                <Enfatize font14St>Plan de ahorro:</Enfatize> El plan de ahorro
                es el cálculo del importe anual/mensual necesario para llegar a
                las necesidades de ahorro para jubilarse a un % del nivel de
                ingresos.
              </Text>
            </View>
            <View flexRowSt spaceBetweenSt topSt style={{ marginBottom: 15 }}>
              <View pinkDotSt />
              <Text font14St style={{ padding: 0 }}>
                <Enfatize font14St>Disclaimer </Enfatize>
                La primera edad de jubilación que se permite seleccionar se
                corresponde con la edad de jubilación anticipada voluntaria, es
                decir, aquella que como máximo permite adelantar hasta 2 años la
                fecha de jubilación ordinaria si se cumplen todos los requisitos
                establecidos en la legislación El salario que se utiliza para el
                cálculo del sistema complementario y el sistema público es el
                mismo.
              </Text>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};
