import React, { Component } from 'react';
import { Utils } from '../../src-core/resources/index';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { activateAccounts_strings } from './text';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

class ActivateAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activateAccounts: null,
      open: false,
      bankToggle: null,
      listBanks: null,
      filteredBanks: null,
      query: '',
      showAutoComplete: false,
      selectedBank: null,
      showInputCredentials: false,
      bankFields: null,
      login: false,
      errorLogin: false,
      errorMessage: '',
    };
  }

  toggleBank(index) {
    const arr = this.state.bankToggle.slice();
    arr[index] = !arr[index];
    this.setState({
      bankToggle: arr.slice(),
    });
  }

  componentDidMount() {
    const obj = {};
    const arr = [];
    this.props.banks.map(bank => {
      arr.push(false);
      bank.accounts.map(account => {
        obj[account._id] = {
          idBank: bank.idBank,
          bank: bank._id,
          type: account.type,
          name: account.name,
          ibanText: account.ibanText,
          panText: account.panText,
          currencyName: account.currencyName,
          currencyBalance: account.currencyBalance,
          canBeHidden: account.canBeHidden,
          isHidden: account.isHidden,
          witouthAcces: account.withoutAccessToTransactions,
          balance: account.currencyBalance,
        };
        return true;
      });
      return true;
    });
    this.setState({
      activateAccounts: obj,
      bankToggle: arr.slice(),
    });
  }

  renderAccounts(id, imageBank) {
    const { classes, config } = this.props,
      transformClasses = Utils.transformClasses;

    const elements = Object.keys(this.state.activateAccounts).map(key => {
      if (this.state.activateAccounts[key].bank === id) {
        /* ºº EVO envia los números de tarjeta
        // Ojo tambien se utiliza en Accounts
        */

        let numCuenta = '';

        if (
          this.state.activateAccounts[key].type === 'card' &&
          this.state.activateAccounts[key].idBank === 'evobanco_direct'
        ) {
          numCuenta = '****';
        } else {
          if (this.state.activateAccounts[key].ibanText) {
            numCuenta = this.state.activateAccounts[key].ibanText;
          } else {
            numCuenta = this.state.activateAccounts[key].panText;
          }
        }
        //let account = this.state.activateAccounts[key];
        //numCuenta = "[" + numCuenta + "] i: " + account.ibanText + " p:" + account.panText + " t:" + account.type + " b:" + account.idBank //+ JSON.stringify(account);

        return (
          <div
            className={classes.activateAccounst_accounts_container}
            key={key}
          >
            <div
              className={classes.activateAccounst_accounts_leftPanel_container}
            >
              <img
                alt='bank_img'
                src={imageBank}
                className={classes.activateAccounst_accounts_imageBank}
              />
            </div>
            <div
              className={
                classes.activateAccounst_accounts_centerPanel_container
              }
            >
              <Typography
                variant='body1'
                color='secondary'
                classes={transformClasses(
                  'activayeAccounts_accounts_title_typography1',
                  classes,
                )}
              >
                {this.props.useAccountName
                  ? this.state.activateAccounts[key].name
                  : 'Cuenta'}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'activayeAccounts_accounts_account_typography1',
                  classes,
                )}
              >
                {numCuenta}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'activayeAccounts_accounts_currency_typography1',
                  classes,
                )}
              >
                {this.state.activateAccounts[key].type !== 'card'
                  ? this.state.activateAccounts[key].currencyBalance +
                    this.state.activateAccounts[key].currencyName
                  : ''}
              </Typography>
            </div>
            <div
              className={classes.activateAccounst_accounts_rightPanel_container}
            >
              <Switch
                {...config.switch2}
                checked={!this.state.activateAccounts[key].isHidden}
                disabled={!this.state.activateAccounts[key].canBeHidden}
                onChange={e => this.handleSelected(key, e)}
                classes={transformClasses('activateAccounts_switch2', classes)}
              />
            </div>
          </div>
        );
      }
    });
    return elements;
  }

  goBack() {
    this.props.history.push('/home/summary');
  }

  renderNewData() {
    const elements = this.props.banks.map(element => {
      return this.renderAccounts(element._id, element.image);
    });
    return elements;
  }

  renderData() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.banks.map((element, index) => {
      return (
        <div key={element._id} className={classes.bankContainer}>
          <div
            className={classes.bankContainerTitle}
            onClick={() => this.toggleBank(index)}
          >
            <img
              alt=''
              src={element.image}
              className={classes.activateAccounts_iconBank}
            />
            <div className={classes.bankContainerTitle_textContainer}>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'activateAccounts_typography1',
                  classes,
                )}
              >
                {element.name}
              </Typography>
              <Typography
                {...config.typography5}
                classes={transformClasses(
                  'activateAccounts_typography5',
                  classes,
                )}
              >
                {this.getTimeDifference(
                  new Date(element.bankAccess.lastUpdate),
                )}
              </Typography>
            </div>
            <IconButton
              {...config.iconButton2}
              color={!this.state.bankToggle[index] ? 'default' : 'secondary'}
              classes={transformClasses(
                'notificationsList_iconButton2',
                classes,
              )}
            >
              <ExpandMore />
            </IconButton>
          </div>
          <Collapse
            {...config.collapse1}
            in={this.state.bankToggle[index]}
            classes={transformClasses('activateAccounts_collapse1', classes)}
          >
            {this.renderAccounts(element._id)}
          </Collapse>
        </div>
      );
    });
    return elements;
  }

  handleSelected(id) {
    const obj = this.state.activateAccounts;
    obj[id].isHidden = !obj[id].isHidden;
    this.props.activateAccounts(id, { isHidden: obj[id].isHidden });
    this.setState({
      activateAccounts: obj,
    });
  }

  handleMain(id) {
    const obj = this.state.activateAccounts;
    Object.keys(obj).map(key => {
      if (key === id) {
        obj[id].isMain = !obj[id].isMain;
      } else {
        obj[key].isMain = false;
      }
      return true;
    });
    this.setState({
      activateAccounts: obj,
    });
  }

  getTimeDifference(time) {
    const currentTime = new Date();
    let diff = (currentTime.getTime() - time.getTime()) / 1000 / 60;
    if (Math.abs(Math.round(diff)) < 60) {
      return (
        activateAccounts_strings['es'].activateAccounts_time_text1 +
        ' ' +
        Math.abs(Math.round(diff)) +
        ' ' +
        activateAccounts_strings['es'].activateAccounts_time_units1
      );
    } else {
      diff /= 60;
      if (Math.abs(Math.round(diff)) < 24) {
        return (
          activateAccounts_strings['es'].activateAccounts_time_text1 +
          ' ' +
          Math.abs(Math.round(diff)) +
          ' ' +
          activateAccounts_strings['es'].activateAccounts_time_units1
        );
      } else {
        diff /= 24;
        return (
          activateAccounts_strings['es'].activateAccounts_time_text1 +
          ' ' +
          Math.abs(Math.round(diff)) +
          ' ' +
          activateAccounts_strings['es'].activateAccounts_time_units1
        );
      }
    }
  }

  getCurrencyType(currency) {
    switch (currency) {
      case 'EUR':
        return '€';
      default:
        return '';
    }
  }

  render() {
    const { classes } = this.props;
    return this.state.activateAccounts !== null ? (
      <div className={classes.activateAccounts_container}>
        {this.renderNewData()}
      </div>
    ) : (
      this.props.renderLoading()
    );
  }
}

ActivateAccounts.propTypes = {
  idTarget: PropTypes.string,
  classes: PropTypes.object,
  renderLoading: PropTypes.func,
};

export default ActivateAccounts;
