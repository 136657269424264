import React, { useEffect, useState } from 'react';

import Switch from '@material-ui/core/Switch';

import Modal from '@material-ui/core/Modal';

import CircularProgressIcon from '@material-ui/core/CircularProgress';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TraspasoIcon from '../common/svgs/Traspaso';

import { TargetsAPI } from '../../src-core/sdk/index';

import { View, Text, InputSwitch, Button } from 'coinscrap-webapp-core';

import Footer from '../../components/MyFooter';
import routes from '../../config/routes';

const TargetOperativos = props => {
  const { baseUrl, token, targetID, history } = props;
  const [target, setTarget] = useState(null);
  const [activeRule, setActiveRule] = useState('ACTIVE');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await TargetsAPI.getTargets(baseUrl, targetID, token);
        const data = await response.json();
        console.log(data);
        setTarget(data);
        setActiveRule(data.nonBrokenStatus === 'NORMAL' ? 'ACTIVE' : 'PAUSED');
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return !target ? (
    <CircularProgressIcon />
  ) : target.type !== 'retirement' ? null : target.status !== 'ACTIVE' ? (
    <View fullHeightSt justifyCenterSt style={{ marginTop: 20 }}>
      <Text boldSt>Este objetivo no está activado</Text>
    </View>
  ) : (
    <View>
      {/* {target.type === 'retirement' &&
        (activeRule === 'PAUSED' || activeRule === 'ACTIVE') && ( */}
      <View
        style={{
          height: 88,
          backgroundColor: 'rgba(255,255,255,0.098)',
          borderRadius: 4,
          marginBottom: 8,
          padding: 10,
          width: '300px',
        }}
        flexRowSt
        spaceBetweenSt
      >
        <View topSt style={{ width: '60%' }}>
          {' '}
          <View flexRowSt>
            <View style={{ width: 30 }}>
              <TraspasoIcon style={{ color: '#f4137b', width: '100%' }} />
            </View>
            <Text leftSt font16St style={{ marginLeft: 10 }}>
              Traspasar plan de pensiones
            </Text>
          </View>
        </View>
        <Button
          onClick={() =>
            history.push({
              pathname: '/reglaRetirementTraspaso',

              state: { pensionPlan: target },
            })
          }
          style={{
            width: 100,
            backgroundColor: 'rgba(255,255,255,0.098)',
          }}
        >
          Traspasar
        </Button>
        {/* <TraspasoIcon style={{ color: '#f4137b' }} /> */}
      </View>
      {/* )}
      {activeRule !== 'PAUSED' || activeRule !== 'ACTIVE' ? null : ( */}
      <View
        style={{
          height: 88,
          backgroundColor: 'rgba(255,255,255,0.098)',
          borderRadius: 4,
          marginBottom: 8,
          padding: 10,
          width: '300px',
        }}
        flexRowSt
        spaceBetweenSt
      >
        <>
          <View topSt style={{ width: '60%' }} flexRowSt>
            <View style={{ width: 30 }}>
              {activeRule === 'ACTIVE' && (
                <PauseIcon style={{ fill: '#f4137b' }} />
              )}
              {activeRule === 'PAUSED' && (
                <PlayArrowIcon style={{ color: '#f4137b', width: '100%' }} />
              )}
            </View>
            <Text leftSt font16St style={{ marginLeft: 10 }}>
              {activeRule === 'ACTIVE' && 'Suspender las aportaciones'}
              {activeRule === 'PAUSED' && 'Reactivar las aportaciones'}
            </Text>
          </View>
          <InputSwitch
            pauseTargetSt
            onChange={() => {
              setOpenModal(true);
            }}
            value={activeRule === 'ACTIVE'}
          />
        </>
      </View>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <View spaceBetweenSt modalActivarSwitchBodySt>
          <View>
            {activeRule === 'PAUSED' && (
              <>
                <Text centerSt boldSt font20St style={{ marginBottom: 15 }}>
                  Reactivar objetivo
                </Text>
                <Text>
                  Esta acción reactivará las aportaciones de este objetivo.
                </Text>
              </>
            )}
            {activeRule === 'ACTIVE' && (
              <>
                <Text centerSt boldSt font20St style={{ marginBottom: 15 }}>
                  Suspender objetivo
                </Text>
                <Text>
                  Esta acción suspenderá las aportaciones de este objetivo.
                </Text>
              </>
            )}
          </View>
          <View flexRowSt spaceBetweenSt>
            <Button
              style={{ marginRight: 20, backgroundColor: 'black' }}
              onClick={() => setOpenModal(false)}
            >
              Volver
            </Button>
            <Button
              onClick={async () => {
                try {
                  const response = await TargetsAPI.changeContributionStatuses(
                    baseUrl,
                    targetID,
                    activeRule !== 'ACTIVE',
                    token,
                  );
                  if (response.status !== 200) {
                    throw new Error('ENDPOINT FALLIDO');
                  }
                  setActiveRule(prevState => {
                    setOpenModal(false);
                    return prevState === 'PAUSED' ? 'ACTIVE' : 'PAUSED';
                  });
                } catch (e) {
                  // TODO: PUT show modal error
                  //  'No se ha podido cambiar es estado de las aportaciones'
                }
              }}
            >
              {activeRule === 'PAUSED' ? 'Reactivar' : 'Suspender'}
            </Button>
          </View>
        </View>
      </Modal>
      {/* )} */}
      {/* <Footer>
        <View>
          <Button
            pinkButtonSt
            onClick={() => props.history.push(routes.retirementSimulationAge)}
            disabled={activeRule === target.status}
            style={{
              borderRadius: 0,
              width: 250,
              textComponent: {
                fontWeight: 600,
              },
            }}
          >
            GUARDAR CAMBIOS
          </Button>
        </View>
      </Footer> */}
    </View>
  );
};

export default TargetOperativos;
