const config = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  }
}

export { config as PopoverCustomConfig }
