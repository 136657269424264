import React, { useState, useEffect } from 'react';

import Chart from 'chart.js';
import ListColors from '../../components/MyListColors';
import Flow from '../../components/Flow';

function ProductFundsDoughnutList(props) {
  //console.log('ProductFundsDonughtList', props);
  const [chartNode, setChartNode] = useState(null);

  const chartConfig = {
    type: 'doughnut',
    data: {
      labels: props.data.labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: props.data.data,
          backgroundColor: ListColors,
        },
      ],
    },
    options: {
      animation: {
        animateScale: true,
      },
      legend: {
        //position: 'top',
        display: false,

        labels: {
          //usePointStyle: true,
          //fontColor: '#fafafa'
        },
      },
      responsive: true,
    },
  };

  useEffect(
    () => {
      if (chartNode) {
        //console.log('new Chart', chartNode, chartConfig)
        new Chart(chartNode, chartConfig);
      }
    } /*, chartNode*/,
  );

  return (
    <div
      style={{
        padding: 10,
        backgroundColor: '#fafafac2',
        boxShadow: '2px 2px 5px 0px',
        borderRadius: 3,
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          //height: 200,
          width: '100%',
        }}
      >
        <canvas ref={node => setChartNode(node)} />
      </div>
      <Lista
        styleType={props.styleType}
        data={props.data}
        funds={props.funds}
        history={props.history}
        location={props.location}
      />
      {props.tabType === 'fondos' && (
        <React.Fragment>
          <p class='text-disclaimer'>
            Los Folletos Explicativos de los Fondos de la cartera y los
            Documentos de Datos Fundamentales para el inversor están disponibles
            en <a href='http://www.cnmv.es'>www.cnmv.es</a>.
          </p>
          <p class='text-disclaimer'>
            En Fondos de Inversión, la inversión está sujeta a posibles
            fluctuaciones del precio del mercado, por lo que no hay garantía de
            devolución del capital invertido.
          </p>
        </React.Fragment>
      )}
    </div>
  );
}

function Lista(props) {
  //let l = null
  //console.log('Lista', props)

  const ReturnList = props.data.labels.map((fundName, index) => {
    //console.log('Lista labels', index, element, props.data)

    //Busca los datos de enlace
    let fund = null;
    if (props.funds) {
      fund = props.funds.find(obj => {
        return obj.name === fundName;
      });
    }
    console.log('ProductFundDonughtList ', fund);

    return props.data.data[index] === 0 ? null : (
      <li
        key={index}
        style={{
          listStyle: 'none',
          marginBottom: 20,
          marginTop: 15,
          color: 'rgba(0, 0, 0, 0.77)', //textColor,
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            float: 'left',
            marginRight: 10,
            borderRadius: 10,
            boxShadow: '1px 1px 1px 1px',
            backgroundColor: ListColors[index],
          }}
        />
        <span
          style={{
            maxWidth: '60%',
          }}
        >
          {fund ? (
            <span
              id='fondoLink'
              style={{ textDecoration: 'underline' }}
              onClick={() =>
                Flow.navigate('/product/fund', props, { fund: fund })
              }
            >
              {fundName}
            </span>
          ) : (
            fundName
          )}
        </span>
        <span>
          {new Intl.NumberFormat('es-ES', {
            minimumFractionDigits: 2,
          }).format(props.data.data[index]) + ' %'}
        </span>
      </li>
    );
  });

  //console.log("Lista", list, returnList);
  return (
    <ul
      style={{
        width: '90%',
        //textAlign: 'center'
      }}
    >
      {ReturnList}
    </ul>
  );
}

export default ProductFundsDoughnutList;
