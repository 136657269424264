export function getTypeProducts(baseUrl, user, type, token) {
  return fetch(
    baseUrl + 'users/' + user + '/targetTypes/identifier/' + type + '/products',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

export function getTargetProducts(baseUrl, id, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract/product', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getAccountTransactions(baseUrl, id, token) {
  return fetch(baseUrl + 'accounts/' + id + '/transactions', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function setProducts(baseUrl, id, data, token) {
  return fetch(baseUrl + 'targets/' + id + '/contract', {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getUserBanksFinal(baseUrl, id, token) {
  return fetch(baseUrl + 'users/' + id + '/banks', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getUserBanks(baseUrl, id, token) {
  //console.log("BanksAPI.getUserBanksFinal1");
  return new Promise((resolve, reject) => {
    getUserBanksFinal(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          //console.log("BanksAPI.getUserBanksFinal2");
          return response.json();
        } else {
          //console.log("BanksAPI.getUserBanksFinal3");
          throw response.status;
        }
      })
      .then(data => {
        console.log('BanksAPI.getUserBanks', data);
        let bank = '';
        data.forEach((element, id) => {
          //console.log(data[id], element, element.idBank + '.png');

          if (element.idBank === 'evobanco_direct') {
            bank = 'evobanco';
          } else {
            bank = element.idBank;
          }

          //bank = element.idBank;
          data[id].image =
            'https://s3.eu-central-1.amazonaws.com/com.coinscrap.others/public/banks/icons/' +
            bank +
            '.png';
        });
        //console.log("BanksAPI.getUserBanksFinal5", data);
        resolve(data);
      })
      .catch(error => {
        console.log('Error reuperando los datos bancarios de usuario ', error);
        reject(error);
      });
  });
}

export function activateAccount(baseUrl, id, data, token) {
  return fetch(baseUrl + 'accounts/' + id, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getListBanksEntities(baseUrl, token) {
  return fetch(baseUrl + 'bankEntities', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function loginBank(baseUrl, id, data, token) {
  return fetch(baseUrl + 'banks/identifier/' + id + '/login', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}

export function bankDataAvailable(baseUrl, id, token) {
  return fetch(baseUrl + 'users/' + id + '/banks/last/dataAvailable', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
}
