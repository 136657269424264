import React, { Component } from 'react';
// import PropTypes from 'prop-types';

//import SvgIcon from '@material-ui/core/SvgIcon'
//import Typography from '@material-ui/core/Typography'
//import { withStyles } from '@material-ui/core/styles'

//import { Utils } from '../../src-core/resources/index'
//import { Utils } from '../../../resources/index'

import Header from './header.component';
import Body from './body.component';
import Footer from './footer.component';

class Container_DESCARTADO extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflow: 'auto',
        }}
      >
        <Header
          title={this.props.title ? this.props.title : null}
          rightAction={this.props.rightAction ? this.props.rightAction : null}
          leftAction={this.props.leftAction ? this.props.leftAction : null}
        />
        {this.props.body && <Body content={this.props.body} />}
        {this.props.children}
        <Footer content={this.props.footer ? this.props.footer : null} />
      </div>
    );
  }
}

/*
const styles = theme => ({
  container: {
  },
  title: {
    fontWeight: 100,
    fontSize: '35px',
    lineHeight: '45px',
    textAlign: 'center',
    color: color
  },
})
*/

//export default withStyles(styles, { withTheme: true })(Header)
export default Container_DESCARTADO;
