import { pensionTransferStatus } from "./pensionTransferStatus";

export const sortTransfers = (transfers) => {

  /**
   * add pensionTransferStatus to each transfer
   */
  const transfersWithStatus = transfers.map(transfer => {
    const status = pensionTransferStatus(transfer);
    return {
      ...transfer,
      pensionTransferStatus: {
        label: status.label,
        status: status.status,
      }
    };
  });

  /**
   * if there is a transfer with status 'pending' then sort by that
   */
  const pendingTransfers = transfersWithStatus.filter(transfer => transfer.pensionTransferStatus.status === 'pending');

  if (pendingTransfers.length > 0) {
    return pendingTransfers.sort((a, b) => {
      return new Date(b.fecha_solicitud_cliente) - new Date(a.fecha_solicitud_cliente);
    });
  }

  /**
   * if there all transfers have the same status 'success', 'rejected' or 'transferred' then sort by that
   */
  const successTransfers = transfersWithStatus.filter(transfer => transfer.pensionTransferStatus.status === 'success');
  const rejectedTransfers = transfersWithStatus.filter(transfer => transfer.pensionTransferStatus.status === 'rejected');
  const transferredTransfers = transfersWithStatus.filter(transfer => transfer.pensionTransferStatus.status === 'transferred');

  if ((successTransfers.length === transfersWithStatus.length)
    || (rejectedTransfers.length === transfersWithStatus.length)
    || (transferredTransfers.length === transfersWithStatus.length)) {
    return transfersWithStatus.sort((a, b) => {
      return new Date(b.fecha_solicitud_cliente) - new Date(a.fecha_solicitud_cliente);
    });
  }

  /**
   * if not, check dates transfer.fecha_cobro_entrada, transfer.fecha_pago_entrada, transfer.fecha_recepcion_rechazo, transfer.fecha_solicitud_a_SNCE and transfer.fecha_solicitud_cliente
   * and sort by the most recent date
   */
  const transfersWithDates = transfersWithStatus.map(transfer => {
    const dates = [
      transfer.fecha_cobro_entrada,
      transfer.fecha_pago_entrada,
      transfer.fecha_recepcion_rechazo,
      transfer.fecha_solicitud_a_SNCE,
      transfer.fecha_solicitud_cliente,
    ];
    const mostRecentDate = dates.reduce((a, b) => {
      return (new Date(a) > new Date(b)) ? a : b;
    });
    return {
      ...transfer,
      mostRecentDate,
    };
  });

  return transfersWithDates.sort((a, b) => {
    return new Date(b.mostRecentDate) - new Date(a.mostRecentDate);
  });

}