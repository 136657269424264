import colors from '../colors';
import fonts from '../fonts';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('text', {
  styles: {
    default: {
      color: colors.white,
      fontSize: 18,
      lineHeight: 1.2,
      fontWeight: 100,
      paddingTop: '5px',
    },
    centerSt: {
      textAlign: 'center',
    },

    headlineSpaceSt: {
      marginBottom: 15,
    },
    ellipsisSt: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    startSt: {
      justifyContent: 'start',
    },
    textJustifySt: { textAlign: 'justify' },

    rightSt: {
      textAlign: 'right',
    },

    leftSt: {
      textAlign: 'left',
    },

    selfLeftSt: {
      alignSelf: 'flex-start',
    },

    boldSt: {
      fontWeight: 600,
    },
    weight400St: {
      fontWeight: 400,
    },
    weight500St: {
      fontWeight: 500,
    },

    italicSt: {
      fontStyle: 'italic',
    },

    normalSt: {
      fontStyle: 'initial',
    },

    headlineSt: {
      color: colors.white,
      fontSize: 45,
      lineHeight: '42px',
      fontWeight: 100,
    },
    mediumHeadlineSt: {
      color: colors.white,
      fontSize: 30,

      fontWeight: 100,
    },

    paddingNoneSt: {
      padding: 0,
    },

    font14St: {
      fontSize: 14,
    },
    font12St: {
      fontSize: 12,
    },
    font13St: {
      fontSize: 13,
    },

    font15St: {
      fontSize: 15,
    },
    font16St: {
      fontSize: 16,
    },
    font17St: {
      fontSize: 17,
    },
    font18St: {
      fontSize: 18,
    },
    font20St: {
      fontSize: 20,
    },

    font22St: {
      fontSize: 22,
    },
    font24St: {
      fontSize: 24,
    },
    font28St: {
      fontSize: 28,
    },
    font30St: {
      fontSize: 30,
    },
    font32St: {
      fontSize: 32,
    },

    whiteSt: {
      color: colors.white,
    },
    blackSt: {
      color: colors.black,
    },
    uppercaseSt: {
      textTransform: 'uppercase',
    },

    bottomTextSt: {
      fontSize: 12,
      lineHeight: 1.2,
      textAlign: 'justify',
    },
  },
});
