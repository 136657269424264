import { Component } from 'react'


class CargosInitialAccounts extends Component{
    render(){
        return(
            this.props.renderAccounts()
        )
    }
}

export default CargosInitialAccounts