import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { CircularProgress } from '@material-ui/core';

//import { UsersAPI } from '../src-core/sdk/index';
import { UsersAPI } from '../../src-core/sdk/index';
import { Utils } from '../../src-core/resources/index';
import Flow from '../../components/Flow';

import Header from '../../components/MyHeader';
import Icons from '../../components/MyIcon.config';

import { MenuStyles as styles } from './menuHome.style';

class MenuHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      notificationsCounter: 0,
    };
  }

  async getUser() {
    //const currentDate = new Date()
    const response = await UsersAPI.getUserData(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      console.log('Datos', data);
      this.setState((state, props) => {
        return { ...state, user: data };
      });
    } else {
    }
  }

  async getNotifications(url, token) {
    UsersAPI.getPendingNotifications(url, 'me', token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState((state, props) => {
          return { ...state, notificationsCounter: data.count };
        });
      })
      .catch(error => {
        console.log('error recuperando el contador de notificaciones ', error);
      });
  }

  componentDidMount() {
    this.getUser();
    this.getNotifications(this.props.baseUrl, this.props.token);
  }

  onClickClose() {
    this.props.history.push('/home');
  }

  render() {
    const { classes /*, modalConfig */ } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.user ? (
      <div className={classes.container}>
        <Header title={'Ajustes'} rigthAction={() => this.onClickClose()} />

        <div className={classes.menu_container}>
          <div className={classes.userName_container}>
            <Typography
              variant='caption'
              color='primary'
              classes={transformClasses('menu_typography7', classes)}
            >
              {(
                this.state.user.name.first +
                ' ' +
                this.state.user.name.last
              ).toLowerCase()}
            </Typography>
            <Typography
              variant='caption'
              color='primary'
              classes={transformClasses('menu_typography8', classes)}
            >
              {this.state.user.email}
            </Typography>
          </div>
          <div className={classes.menu_listContainer}>
            <List className={classes.menu_list}>
              <ListItem
                className={classes.menu_listItem}
                onClick={() => Flow.navigate('/notifications', this.props)}
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses('menu_iconButton', classes)}
                  viewBox={'30 27 62 72'}
                >
                  <path d='M 30.195 89.272 C 30.597 90.245 31.577 90.908 32.648 90.908 L 52.095 90.908 C 52.942 96.618 57.937 101 63.869 101 C 69.801 101 74.796 96.618 75.644 90.908 L 95.135 90.908 C 95.269 90.908 95.403 90.908 95.537 90.864 C 96.517 90.731 97.321 90.068 97.677 89.182 C 98.034 88.165 97.766 87.102 97.008 86.395 L 96.875 86.262 C 96.606 85.996 95.537 84.801 94.51 82.278 C 93.395 79.579 92.012 74.755 92.012 67.275 C 92.012 52.492 86.393 44.704 81.709 40.764 C 78.588 38.153 75.466 36.869 73.414 36.25 C 73.324 34.435 72.879 31.957 71.184 29.965 C 70.025 28.593 67.839 27 63.959 27 C 60.123 27 57.937 28.637 56.778 29.965 C 55.038 32.001 54.547 34.523 54.503 36.383 C 52.407 37.002 49.284 38.286 46.207 40.808 C 37.599 47.801 35.815 59.043 35.815 67.186 C 35.815 74.445 34.432 79.268 33.273 82.057 C 32.024 85.023 30.819 86.35 30.819 86.35 C 30.017 87.102 29.793 88.253 30.195 89.272 Z M 63.914 95.688 C 60.925 95.688 58.384 93.653 57.58 90.908 L 70.248 90.908 C 69.444 93.653 66.902 95.688 63.914 95.688 Z M 41.167 67.186 C 41.167 43.376 56.867 41.119 57.491 41.03 C 58.205 40.941 58.874 40.587 59.32 40.012 C 59.766 39.437 59.945 38.728 59.856 37.976 C 59.721 37.135 59.632 34.657 60.836 33.285 C 61.059 33.019 61.773 32.178 63.959 32.178 C 66.144 32.178 66.858 33.019 67.125 33.285 C 68.24 34.568 68.195 37.002 68.062 37.799 C 67.928 38.507 68.106 39.26 68.553 39.835 C 68.998 40.41 69.623 40.808 70.381 40.897 C 70.426 40.897 74.395 41.473 78.365 44.792 C 83.94 49.439 86.75 57.007 86.75 67.275 C 86.75 76.304 88.49 82.101 90.229 85.642 L 37.599 85.642 C 39.294 81.969 41.167 76.038 41.167 67.186 Z' />
                </SvgIcon>
                <Typography
                  variant='caption'
                  color='primary'
                  classes={transformClasses('menu_typography9', classes)}
                >
                  Notificaciones
                </Typography>
              </ListItem>

              <ListItem
                className={classes.menu_listItem}
                onClick={() => Flow.navigate('/cargos', this.props)}
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses('menu_iconButton', classes)}
                  viewBox={'30 28 62 75'}
                >
                  <path d='M 97.824 58.195 C 99.025 58.195 100 59.183 100 60.4 L 100 80.985 C 100 82.203 99.025 83.191 97.824 83.191 L 94.766 83.191 L 94.766 89.493 C 94.766 94.184 91 98 86.371 98 L 36.394 98 C 31.764 98 28 94.184 28 89.493 L 28 51.894 C 28 47.202 31.764 43.387 36.394 43.387 L 86.371 43.387 C 91 43.387 94.766 47.202 94.766 51.894 L 94.766 58.195 L 97.824 58.195 Z M 90.413 89.493 L 90.413 83.191 L 70.386 83.191 C 65.757 83.191 61.992 79.376 61.992 74.684 L 61.992 66.702 C 61.992 62.01 65.757 58.195 70.386 58.195 L 90.413 58.195 L 90.413 51.894 C 90.413 49.636 88.6 47.798 86.371 47.798 L 36.394 47.798 C 34.166 47.798 32.353 49.636 32.353 51.894 L 32.353 89.493 C 32.353 91.751 34.166 93.589 36.394 93.589 L 86.371 93.589 C 88.6 93.589 90.413 91.751 90.413 89.493 Z M 95.647 78.78 L 95.647 62.606 L 70.386 62.606 C 68.159 62.606 66.344 64.443 66.344 66.702 L 66.344 74.684 C 66.344 76.942 68.159 78.78 70.386 78.78 L 95.647 78.78 Z M 73.187 31.851 L 56.52 39.605 L 46.086 39.605 L 75.327 26 L 81.489 39.605 L 76.7 39.605 L 73.187 31.851 Z M 74.192 70.401 C 74.192 71.966 72.939 73.236 71.394 73.236 C 69.85 73.236 68.596 71.966 68.596 70.401 C 68.596 68.835 69.85 67.565 71.394 67.565 C 72.939 67.565 74.192 68.835 74.192 70.401 Z' />
                </SvgIcon>
                <Typography
                  variant='caption'
                  color='primary'
                  classes={transformClasses('menu_typography9', classes)}
                >
                  Cuentas
                </Typography>
              </ListItem>

              <ListItem
                className={classes.menu_listItem}
                onClick={() => Flow.navigate('/documents', this.props)}
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses('menu_iconButton', classes)}
                >
                  <path d='M 19.923 0 C 21.027 0 21.93 0.931 21.93 2.068 L 21.93 21.932 C 21.93 23.069 21.027 24 19.923 24 L 5.006 24 C 3.903 24 3 23.069 3 21.932 L 3 5.218 L 3.01 5.22 C 3.038 5.027 3.128 4.844 3.285 4.709 L 8.489 0.197 C 8.576 0.122 8.674 0.069 8.777 0.038 L 8.779 0.028 L 8.942 0 L 19.923 0 Z M 20.525 21.932 L 20.525 2.068 C 20.525 1.732 20.25 1.448 19.923 1.448 L 10.215 1.448 L 10.213 7.556 L 6.693 7.556 L 6.693 6.109 L 8.81 6.109 L 8.81 2.08 L 4.405 5.899 L 4.405 21.932 C 4.405 22.268 4.68 22.552 5.006 22.552 L 19.923 22.552 C 20.25 22.552 20.525 22.268 20.525 21.932 Z M 17.037 15.821 L 7.807 15.821 C 7.42 15.821 7.105 15.497 7.105 15.097 C 7.105 14.698 7.42 14.374 7.807 14.374 L 17.037 14.374 C 17.425 14.374 17.739 14.698 17.739 15.097 C 17.739 15.497 17.425 15.821 17.037 15.821 Z M 17.037 19.147 L 7.807 19.147 C 7.42 19.147 7.105 18.823 7.105 18.423 C 7.105 18.024 7.42 17.7 7.807 17.7 L 17.037 17.7 C 17.425 17.7 17.739 18.024 17.739 18.423 C 17.739 18.823 17.425 19.147 17.037 19.147 Z M 17.037 12.512 L 7.807 12.512 C 7.42 12.512 7.105 12.188 7.105 11.788 C 7.105 11.389 7.42 11.065 7.807 11.065 L 17.037 11.065 C 17.425 11.065 17.739 11.389 17.739 11.788 C 17.739 12.188 17.425 12.512 17.037 12.512 Z' />
                </SvgIcon>
                <Typography
                  variant='caption'
                  color='primary'
                  classes={transformClasses('menu_typography9', classes)}
                >
                  Contratos
                </Typography>
              </ListItem>

              <ListItem
                className={classes.menu_listItem}
                onClick={() => Flow.navigate('/faqs', this.props)}
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses('menu_iconButton', classes)}
                  viewBox={'28 27 70 76'}
                >
                  <path d='M 64 26 C 84.987 26 102 43.013 102 64 C 102 84.987 84.987 102 64 102 C 43.013 102 26 84.987 26 64 C 26 43.013 43.013 26 64 26 Z M 64 97.059 C 82.257 97.059 97.059 82.257 97.059 64 C 97.059 45.743 82.257 30.941 64 30.941 C 45.743 30.941 30.941 45.743 30.941 64 C 30.941 82.257 45.743 97.059 64 97.059 Z M 63.819 84.06 C 62.85 84.06 62.009 83.686 61.318 82.946 C 60.632 82.219 60.285 81.34 60.285 80.334 C 60.285 79.329 60.632 78.449 61.318 77.722 C 62.006 76.983 62.847 76.609 63.819 76.609 C 64.788 76.609 65.629 76.983 66.32 77.722 C 67.003 78.449 67.35 79.329 67.35 80.334 C 67.35 81.34 67.003 82.219 66.32 82.946 C 65.626 83.686 64.785 84.06 63.819 84.06 Z M 60.818 70.794 L 60.818 67.305 C 60.818 66.416 60.881 65.656 61.006 65.049 C 61.135 64.429 61.333 63.861 61.596 63.355 C 61.856 62.847 62.212 62.356 62.649 61.889 C 63.053 61.464 65.097 59.385 65.569 58.894 C 66.027 58.424 66.44 57.936 66.811 57.437 C 67.167 56.952 67.466 56.431 67.697 55.896 C 67.918 55.387 68.029 54.801 68.029 54.157 C 68.029 53.514 67.915 52.91 67.694 52.368 C 67.472 51.821 67.173 51.348 66.808 50.959 C 66.452 50.579 66.015 50.277 65.513 50.052 C 65.013 49.834 64.474 49.723 63.903 49.723 C 62.61 49.723 61.596 50.142 60.8 51.004 C 59.977 51.898 59.495 53.044 59.331 54.513 L 59.265 55.097 L 53.526 54.498 L 53.613 53.891 C 54.017 51.007 55.166 48.703 57.036 47.048 C 58.903 45.393 61.246 44.55 63.996 44.55 C 65.3 44.55 66.545 44.762 67.688 45.178 C 68.843 45.6 69.86 46.216 70.719 47.012 C 71.575 47.805 72.26 48.798 72.75 49.965 C 73.241 51.126 73.487 52.458 73.487 53.921 C 73.487 55.187 73.313 56.318 72.966 57.278 C 72.619 58.233 72.128 59.136 71.5 59.974 L 68.795 62.96 L 68.319 63.415 C 67.867 63.867 67.499 64.256 67.227 64.579 C 66.985 64.869 66.79 65.171 66.647 65.486 C 66.512 65.788 66.416 66.141 66.362 66.527 C 66.305 66.952 66.276 67.478 66.276 68.113 L 66.276 70.794 L 60.818 70.794 Z' />
                </SvgIcon>
                <Typography
                  variant='caption'
                  color='primary'
                  classes={transformClasses('menu_typography9', classes)}
                >
                  Preguntas Frecuentes
                </Typography>
              </ListItem>

              <ListItem
                className={classes.menu_listItem}
                onClick={() => Flow.navigate('/history', this.props)}
              >
                <SvgIcon
                  color='primary'
                  classes={transformClasses('menu_iconButton', classes)}
                  viewBox={Icons.historial.viewBox}
                >
                  <path d={Icons.historial.data} />
                </SvgIcon>
                <Typography
                  variant='caption'
                  color='primary'
                  classes={transformClasses('menu_typography9', classes)}
                >
                  Historial
                </Typography>
              </ListItem>
            </List>

            <div className={classes.menu_sponsors}>
              <div className={classes.menu_sponsors_item}>
                <div className={classes.menu_sponsor_logo_container}>
                  <img
                    alt='caser logo'
                    src='/images/caser.png'
                    className={classes.menu_sponsor_logo}
                  />
                </div>
                <div className={classes.menu_sponsors_text}>
                  <Typography
                    variant='caption'
                    color='primary'
                    classes={transformClasses('menu_typography10', classes)}
                  >
                    Ahorro gestionado y depositado en{' '}
                    <b style={{ textTransform: 'capitalize' }}>
                      {' '}
                      Caser Seguros{' '}
                    </b>
                  </Typography>
                </div>
              </div>
              <div />
              <div className={classes.menu_sponsors_item}>
                <div className={classes.menu_sponsor_logo_container}>
                  <img
                    alt='caser logo'
                    src='/images/coinscrap-logo.png'
                    className={classes.menu_sponsor_logo}
                  />
                </div>
                <div className={classes.menu_sponsors_text}>
                  <Typography
                    variant='caption'
                    color='primary'
                    classes={transformClasses('menu_typography10', classes)}
                  >
                    Desarrollado por{' '}
                    <b style={{ textTransform: 'capitalize' }}> Coinscrap </b>
                  </Typography>
                  <Typography
                    variant='caption'
                    color='primary'
                    style={{ fontSize: 10 }}
                  >
                    v 20.04.22
                  </Typography>
                </div>
              </div>
              <div />
            </div>
          </div>
          <Typography
            variant='caption'
            color='primary'
            classes={transformClasses('menu_typography10', classes)}
            style={{
              textAlign: 'center',
              marginTop: 20,
            }}
          >
            Contáctanos por teléfono en el 910 900 900. (atención de Lunes a
            Viernes de 8h a 22h, excepto festivos). También puedes escribir a
            soporte.laboratorio@evobanco.com
          </Typography>
        </div>
      </div>
    ) : (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      />
    );
  }
}

export default ({ ...otherProps }) => {
  const MenuHomeWithStyles = withStyles(styles)(MenuHome);

  return <MenuHomeWithStyles {...otherProps} />;
};
