import React, { Component } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Icons from '../../components/MyIcon.config';
import DocView from '../../components/MyDocView';
import Body from '../../components/MyBody';
//import Footer from '../../components/MyFooter';
import Header from '../../components/MyHeader';

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  onClickUrl(url, title) {
    //console.log(url, title);
    this.setState({
      modalOpen: true,
      modalContent: null,
      modalUrl: url,
      modalTitle: title,
    });
  }

  onCloseModal() {
    this.setState({
      modalOpen: false,
    });
  }

  onClickBackIcon() {
    this.props.history.push('/home/summary');
  }

  onClickEntendido() {
    this.props.history.push('/home/summary');
  }

  onClickDisquete(url) {
    window.open(url);
  }

  render() {
    const { classes /*, theme*/ } = this.props;
    //const transformClasses = Utils.transformClasses

    return (
      <div
        className='container'
        style={{
          background: 'white',
          //height: '100%',
          //with: '100%',
        }}
      >
        <Header
          leftAction={() => this.onClickBackIcon()}
          title='Mas información'
          styleType='white'
          align='top'
        />

        <Body vAlign='top'>
          <iframe
            style={{ marginBottom: 10 }}
            //width='560'
            //height='315'
            height='175'
            src='https://www.youtube.com/embed/uQRwFfo2H1w'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='YOUTUBE'
          />
          <Typography className='textInfoH1'>
            ¿Te imaginas conseguir tus metas sin comerte el coco?
          </Typography>
          <Typography className='textInfoNormal'>
            Crea tus{' '}
            <strong>
              propias reglas y supera retos para intentar alcanzarlas.
            </strong>
          </Typography>
          <Typography className='textInfoNormal'>
            La Hucha Inteligente EVO, como no podía ser de otro manera, aplica
            un sistema automatizado para que puedes crear tantos objetivos como
            tengas en mente y además en tan solo unos clics.
          </Typography>
          <Typography className='textInfoNormal'>
            Configúralo como prefieras y ella solita se encargará de todo
            dependiendo de tu perfil riesgo y de las reglas que establezcas.
          </Typography>

          <div
            style={{
              marginTop: 20,
              width: '100%',

              marginBottom: 30,
            }}
          >
            <Typography
              className='textInfoH1'
              style={{
                textDecoration: 'none',
                color: 'black',
                textAlign: 'left',
              }}
              onClick={() =>
                this.onClickUrl(
                  'https://s3.eu-central-1.amazonaws.com/com.coinscrap.evo/public/docs/EVO_+Te%CC%81rminos+y+condiciones+-+Disclaimer.docx.pdf',
                  'Términos y Condiciones',
                )
              }
            >
              TÉRMINOS Y CONDICIONES
              <SvgIcon
                style={{
                  float: 'right',
                  fontSize: 43,
                  marginRight: -25,
                }}
              >
                <path d={Icons.arrowRight.data} />
              </SvgIcon>
            </Typography>
            <Button
              variant='contained'
              color='secondary'
              className={classes.targetBasic_button1}
              onClick={() => this.onClickEntendido()}
              style={{
                width: '100%',
              }}
            >
              ENTENDIDO
            </Button>
          </div>
        </Body>

        {/* Ventana Modal */}
        {this.state.modalOpen && (
          <DocView
            title={this.state.modalTitle}
            open={this.state.modalOpen}
            onClose={() => this.onCloseModal()}
            url={this.state.modalUrl}
            leftAction={() => this.onClickDisquete(this.state.modalUrl)}
            leftIcon={Icons.disquete}
            disk
          >
            {this.state.modalContent}
          </DocView>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  listConfRules_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '12px',
    marginBottom: '12px',
    paddingRight: 10,
    paddingLeft: 10,
  },
});

export default withStyles(styles, { withTheme: true })(Info);
