import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

//import * as TargetsAPI from '../../api/targets';
import { TargetsAPI } from '../../src-core/sdk/index';

//import * as BanksAPI from '../../api/banks';
import { BanksAPI } from '../../src-core/sdk/index';

//import * as RulesAPI from '../../api/rules';
import { RulesAPI } from '../../src-core/sdk/index';

//import * as UsersAPI from '../../api/users';
import { UsersAPI } from '../../src-core/sdk/index';

import CreateTargetBasic from './CreateTargetBasic';
import CreateTargetStart from './CreateTargetStart';
import CreateTargetAccounts from './CreateTargetAccounts';
import CreateTargetProducts from './CreateTargetProducts';
import CreateTargetRules from './CreateTargetRules';
import CreateTargetInitContribution from './CreateTargetInitContribution';
import CreateTargetEnd from './CreateTargetEnd';
import CreateTargetCreateContract from './CreateTargetCreateContract';
import CreateTargetSummary from './CreateTargetSummary';
import CreateTargetInitSummary from './CreateTargetInitSummary';
import CreateTargetRetirement from './CreateTargetRetirement';

import Flow from '../../components/Flow';
import { StoreContext } from '../../pensionStore/Store';
import { updatedValues } from '../../libs/updatedValuesProduct';
/*
  Engloba toda la funcionalidad de selección de nivel de riesgo que quiere
  asumir el usuario.

  Muestra la ventana donde el usuario puede seleccionar el perfil 
  de riesgo que quiere, se´gun su test de idoneidad.
 */

//fine tuning to the refresh mechanic, could use improving (mostly just refresh what you need)

class CreateTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryID: null,
      temporary: null,
      temporaryConfiguration: null,
      temporarySchema: null,
      image: null,
      products: null,
      founds: null,
      userBanks: null,
      new: false,
      rulesDefinitions: null,
      rulesAnswers: null,
      rulesTargets: null,
      answersSet: false,
      userData: null,
      contractSchema: null,
      contractCurrentData: null,
      doSurvey: false,
      surveyFetched: false,
      isMounted: false,
      selectedFound: null,
      productIdentifier: null,
    };
  }

  static contextType = StoreContext;

  //ººTODO: Ahora la survey no se hace hasta despues de meter datos basicos
  // Hay que ver como quitar esto de aquí.
  async getSurveys() {
    const response = await UsersAPI.getSurvey(
      this.props.baseUrl,
      'idd-evo',
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      const response2 = await UsersAPI.getUserSurveyResponse(
        this.props.baseUrl,
        'me',
        data._id,
        this.props.token,
      );
      if (response2.status === 200) {
        const data2 = await response2.json();
        console.log('CreateTarget.getSurvey', data2);
        if (!data2.finished) {
          this.setState({
            doSurvey: true,
            surveyFetched: true,
          });
        } else {
          console.log('CreateTarget survey done');
          this.setState({
            doSurvey: false, //true,
            surveyFetched: true,
          });
          //this.getTemporaryID();
        }
      } else if (response2.status === 404) {
        console.error(
          'CreateTarget response2 404, error controlado >> Hay q hacer encuesta',
        );
        this.setState({
          doSurvey: true,
          surveyFetched: true,
        });
      } else {
        console.error(
          'CreateTarget error recuperando la respuesta de usuario a survey: ',
          response2.status,
        );
      }
    } else {
      console.error(
        'CreateTarget error recuperando la survey: ',
        response.status,
      );
    }
  }

  componentDidMount() {
    if (this.props.match.params.type === 'familyTarget') {
      this.getSurveys();
      this.getTemporaryID(); // Añadido nuevo al cambiar orden Survey
      this.getTypeProducts();
    } else {
      this.getTemporaryID();
      this.setState({
        surveyFetched: true,
      });
    }
  }

  setIdToNull() {
    this.setState({
      temporaryID: null,
    });
  }

  getUserData() {
    UsersAPI.getUserData(this.props.baseUrl, 'me', this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        //console.log('CreateTarget .getUserData: ', data)
        this.setState({
          userData: data,
        });

        this.props.saveUserObject(data);
      })
      .catch(error => {
        console.error(
          'CreateTarget error recuperando datos de usuario ',
          error,
        );
      });
  }

  getContractSchema() {
    TargetsAPI.getContractDataSchema(
      this.props.baseUrl,
      this.props.match.params.type,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          contractSchema: data,
        });
      })
      .catch(error => {
        console.error(
          'CreateTarget error recuperando el esquema de contratos ',
          error,
        );
      });
  }

  async getTargetData(id) {
    console.log({ id });
    const { baseUrl } = this.props;
    let data = null;
    try {
      const response = await TargetsAPI.getTargets(
        baseUrl,
        id,
        this.props.token,
      );

      if (response.status === 200) {
        data = await response.json();
      }
    } catch (error) {
      console.error(error);
    }

    return data;
  }
  async getConfiguration(id) {
    console.log({ id });
    const { baseUrl } = this.props;
    let data = null;
    try {
      const response = await TargetsAPI.getTargetConfiguration(
        baseUrl,
        id,
        this.props.token,
      );
      data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
    }

    return data;
  }

  async resolveTemporaryId(id, type) {
    if (type === 'retirement') {
      return await this.getTargetData(id);
    }

    if (type === 'familyTarget') {
      return await this.getConfiguration(id);
    }
  }

  getTemporaryID() {
    console.log(
      'CreateTarget.getTemporaryID inicio' + this.props.match.params.type,
    );
    const { baseUrl } = this.props;
    TargetsAPI.getTemporaryID(
      baseUrl,
      this.props.match.params.type,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          console.log('CreateTarget.getTemporaryID 200');
          return response.json();
        } else if (response.status === 404) {
          console.log('CreateTarget.getTemporaryID 404');
          this.setState({
            new: true,
          });

          this.createNewTemporary();
          throw response.status;
        }
      })
      .then(async data => {
        console.log('CreateTarget.getTemporaryID data', data);
        console.log('PROPS STATE INSIDE', this.props, this.state);

        let newTarget = false;

        if (this.props.match.params.type === 'familyTarget') {
          const res = await this.resolveTemporaryId(data._id, 'familyTarget');
          console.log({ res });
          if (res && res.price === 0) {
            newTarget = true;
          }
        }
        if (this.props.match.params.type === 'retirement') {
          const res = await this.resolveTemporaryId(data._id, 'retirement');
          console.log({ res });
          if (res && (!res?.name || res?.name === 'Jubilación')) {
            newTarget = true;
          }
        }

        if (newTarget) {
          this.setToZero();

          return;
        }

        // const response = await this.resolveTemporaryId(data._id);
        // console.log({ response });
        // if (!response?.name) {
        //   this.setState({
        //     new: true,
        //   });

        //   this.createNewTemporary();
        //   throw response.status;
        // }

        this.setState({
          temporaryID: data._id,
        });

        await Promise.all([
          this.getUserBanks(),
          this.getRulesDefinitions(),
          this.getTargetConfigurationSchema(),
          this.getTypeProducts(),
          this.getUserData(),
          this.getContractSchema(),
          this.getTarget(data._id),
          this.getTargetConfiguration(data._id),
          this.getTargetProducts(data._id),
          this.getContractCurrentData(data._id),
        ]);
      })
      .catch(error => {
        console.error('CreateTarget Error target no encontrado: ', error);
      });
  }

  createNewTemporary() {
    const { baseUrl, token } = this.props;
    TargetsAPI.createNewTemporary(baseUrl, this.props.match.params.type, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(async data => {
        this.setState({
          temporaryID: data._id,
        });

        this.props.saveObjectiveId(data._id);

        await Promise.all([
          this.getUserBanks(),
          this.getRulesDefinitions(),
          this.getTargetConfigurationSchema(),
          this.getTypeProducts(),
          this.getUserData(),
          this.getContractSchema(),
          this.getTarget(data._id),
          this.getTargetConfiguration(data._id),
          this.getTargetProducts(data._id),
          this.getContractCurrentData(data._id),
          //this.getSurveys()
        ]);
      });
  }

  getTarget(id) {
    const { baseUrl } = this.props;

    TargetsAPI.getTargets(baseUrl, id, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.getTargetRules(id);
        console.log('CreateTarget TargetsAPI.getTargets', data);

        this.setState({
          temporary: data,
          image: data.image,
        });
      })
      .catch(error => {
        console.error('CreateTarget error consiguiendo el target', error);
      });
  }

  setToZero() {
    this.setState({
      temporaryID: null,
      temporary: null,
      temporaryConfiguration: null,
      banks: null,
      banksUser: null,
      image: null,
      new: true,
      contractCurrentData: null,
      products: null,
      founds: null,
    });

    this.createNewTemporary();
  }

  getTargetConfiguration(id) {
    const { baseUrl } = this.props;
    TargetsAPI.getTargetConfiguration(baseUrl, id, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('CreateTarget.getTargetConfiguration', data);

        this.setState({
          temporaryConfiguration: data,
        });
      })
      .catch(error => {
        console.error('CreateTarget error consiguiendo confiuration', error);
      });
  }

  async getTargetConfigurationSchema() {
    let data = await TargetsAPI.getTargetTypeSchema(
      this.props.baseUrl,
      this.props.match.params.type,
      this.props.token,
    );
    console.log(data, 'DATA');
    if (this.props.match.params.type === 'retirement') {
      // schema.properties.initialContribution.minimum
      data = {
        ...data,

        properties: {
          ...data.properties,
          initialContribution: {
            ...data.properties.initialContribution,
            minimum: 30,
          },
        },
      };
    }
    this.setState({
      temporarySchema: data,
    });
  }

  getContractCurrentData(id) {
    TargetsAPI.getContractCurrentData(this.props.baseUrl, id, this.props.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          contractCurrentData: data,
        });
      })
      .catch(error => {
        console.error(
          'CreateTarget error recuperando la indormación actual del contrato ',
          error,
        );
      });
  }

  getTargetProducts(id) {
    const { baseUrl, token } = this.props;
    BanksAPI.getTargetProducts(baseUrl, id, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('CreateTarget getTargetProducts: ', data);
        this.setState({
          products: data.foundsConfiguration[0].foundCode,
          productIdentifier: data.identifier,
          metadata: data.metadata,
        });
      })
      .catch(error => {
        console.error(
          'CreateTarget error getting products for target: ',
          error,
        );
      });
  }

  getTypeProducts() {
    const { baseUrl, token } = this.props;
    BanksAPI.getTypeProducts(baseUrl, 'me', this.props.match.params.type, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        let founds = {};
        data.map(product => {
          product.founds.map(found => {
            founds[found.code + product.identifier] = {
              ...found,
              product: product.identifier,
              productId: found.code + product.identifier,
              ...updatedValues(found.code + product.identifier),
            };
            return null;
          });
          return null;
        });
        console.log('founds: ', founds);
        this.setState({
          founds: founds,
        });
      })
      .catch(error => {
        console.error(
          'CreateTarget: error recuperando la lista de productos',
          error,
        );
      });
  }

  async getUserBanks() {
    const data = await BanksAPI.getUserBanks(
      this.props.baseUrl,
      'me',
      this.props.token,
    );
    console.log('CreateTarget.getUserBanks', data);
    this.setState({
      userBanks: data,
    });
  }

  async getRulesDefinitions() {
    const { state } = this.context;
    const flowParams = Flow.getParams();
    console.log('CreateTarget.getRulesDefinitions 1', this.props);
    console.log('state', state);
    const { baseUrl, token } = this.props;
    let data = await RulesAPI.getRulesDefinitionsByType(
      baseUrl,
      this.props.match.params.type,
      token,

      state,
      flowParams,
    );

    console.log('CreateTarget.getRulesDefinitions 2', data);
    const obj = {};
    const array = [];
    data
      .filter(item => {
        if (
          item.identifier === 'COVID_SAVING' ||
          item.identifier === 'CANCER_DONATION' ||
          item.identifier === 'COFFEE_SAVING' ||
          item.identifier === 'SMOKE_SAVING' ||
          (item.identifier !== 'PERIODIC_SAVING' &&
            this.props.match.params.type === 'retirement')
        )
          return false;
        else return true;
      })
      .forEach(definition => {
        if (!definition.hideInCreation) {
          array.push(definition);
          if (definition.transactionsAmountReductor !== undefined) {
            obj[definition._id] = {
              ruleDefinition: definition._id,
              value: {
                transactionsAmountReductor: {
                  type: definition.transactionsAmountReductor.type,
                  [definition.transactionsAmountReductor.type.toLowerCase()]: definition
                    .transactionsAmountReductor.min,
                },
              },
            };
          } else {
            obj[definition._id] = {
              ruleDefinition: definition._id,
              value: {
                fieldsValues: [],
              },
            };
            definition.fields.map(field => {
              obj[definition._id].value.fieldsValues.push({
                name: field.name,
                value: null,
                default: field.default,
                identifier: definition.identifier,
              });
              return null;
            });
          }
        }
        return null;
      });
    this.setState({
      rulesDefinitions: array,
      rulesAnswers: obj,
    });
  }

  async getTargetRules(id) {
    const data = await RulesAPI.getTargetRules(
      this.props.baseUrl,
      id,
      this.props.token,
    );
    //console.log('CreateTarget.getTargetRules', data);
    this.setState({
      rulesTargets: data,
    });

    this.getRuleAnswers();
  }

  async getRuleAnswers() {
    const obj = { ...this.state.rulesAnswers };
    const rulesTargetArray = this.state.rulesTargets || [];

    await Promise.all(
      rulesTargetArray.map(async rule => {
        if (!rule.ruleDefinition.hideInCreation) {
          const response = await RulesAPI.getRule(
            this.props.baseUrl,
            rule._id,
            this.props.token,
          );
          if (response.status === 200) {
            const data = await response.json();
            if (rule.ruleDefinition.transactionsAmountReductor !== undefined) {
              obj[rule.ruleDefinition._id].value.transactionsAmountReductor[
                rule.ruleDefinition.transactionsAmountReductor.type.toLowerCase()
              ] =
                data.value.transactionsAmountReductor[
                  rule.ruleDefinition.transactionsAmountReductor.type.toLowerCase()
                ];
            } else {
              data.value.fieldsValues.map((field, index) => {
                obj[rule.ruleDefinition._id].value.fieldsValues[index].value =
                  field.value;
                return null;
              });
            }
          } else {
            console.error(
              'CreateTarget error recuperando las respuestas de las reglas ',
              response.status,
            );
          }
        }
      }),
    );
    this.setState({
      rulesAnswers: obj,
      answersSet: true,
    });
  }

  refreshConf(id) {
    this.getTarget(id);
    this.getTargetConfiguration(id);
    this.getTargetProducts(id);
    //this.getTargetRules(id)
    this.getContractCurrentData(id);
  }

  // renderSteps() {
  //   const { classes } = this.props;
  //   const array = [
  //     {
  //       label: '',
  //     },
  //     {
  //       label: '',
  //     },
  //     {
  //       label: '',
  //     },
  //     {
  //       label: '',
  //     },
  //     {
  //       label: '',
  //     },
  //     {
  //       label: '',
  //     },
  //     {
  //       label: '',
  //     },
  //   ];
  //   const steps = array.map((element, index) => {
  //     return (
  //       <Step key={index}>
  //         <StepIcon
  //           classes={{
  //             root: classes.targetConfig_stepLabel,
  //             active: classes.targetConfig_stepLabel_active,
  //             completed: classes.targetConfig_stepLabel_complete,
  //           }}
  //         />
  //       </Step>
  //     );
  //   });
  //   return steps;

  //   // da fuq? en que diablos estaba pensando?
  // }

  // handleStep(step) {
  //   this.setState({
  //     currentStep: this.state.currentStep - step,
  //   });
  // }

  updateProduct(product) {
    this.setState({
      products: product,
    });
  }

  render() {
    const { classes } = this.props;
    console.log('CreateTarget.render ', {
      props: this.props,
      state: this.state,
    });
    return (
      <Switch>
        <Route
          path={'/create/target/:type/Editar'}
          render={props =>
            this.state.temporary !== null &&
            this.state.temporaryConfiguration !== null &&
            this.state.temporarySchema !== null ? (
              <CreateTargetBasic
                {...this.props}
                baseUrl={this.props.baseUrl}
                type={this.props.match.params.type}
                founds={this.state.founds}
                temporary={this.state.temporary}
                temporaryConfiguration={this.state.temporaryConfiguration}
                token={this.props.token}
                refreshConf={this.refreshConf.bind(this)}
                history={this.props.history}
                getTarget={this.getTarget.bind(this)}
                next={
                  this.props.match.params.type === 'nextWish'
                    ? '/create/target/' +
                      this.props.match.params.type +
                      '/Inicial'
                    : '/create/target/' +
                      this.props.match.params.type +
                      '/products'
                }
                schema={this.state.temporarySchema}
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          path={'/create/target/retirement/products'}
          render={props =>
            this.state.products !== null &&
            this.state.founds !== null &&
            this.state.temporaryID !== null ? (
              <CreateTargetRetirement
                {...props}
                saveProdsForUser={this.props.saveProdsForUser}
                baseUrl={this.props.baseUrl}
                history={this.props.history}
                type={this.props.match.params.type}
                token={this.props.token}
                prev={'/create/types'}
                targetID={this.state.temporaryID}
                products={this.state.products}
                founds={this.state.founds}
                updateProduct={this.updateProduct.bind(this)}
                next={
                  '/create/target/' + this.props.match.params.type + '/Inicial' // '/Editar'
                }
                metadata={this.state.metadata}
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />

        <Route
          path={'/create/target/:type/products'}
          render={props =>
            this.state.products !== null &&
            this.state.founds !== null &&
            this.state.temporaryID !== null ? (
              <CreateTargetProducts
                {...props}
                baseUrl={this.props.baseUrl}
                history={this.props.history}
                type={this.props.match.params.type}
                token={this.props.token}
                prev={'/create/types'}
                targetID={this.state.temporaryID}
                products={this.state.products}
                founds={this.state.founds}
                updateProduct={this.updateProduct.bind(this)}
                next={
                  '/create/target/' + this.props.match.params.type + '/Inicial' // '/Editar'
                }
                metadata={this.state.metadata}
                saveProdsForUser={this.props.saveProdsForUser}
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />

        <Route
          path={'/create/target/:type/Inicial'}
          render={props =>
            this.state.temporary !== null &&
            this.state.temporaryConfiguration !== null &&
            this.state.temporarySchema !== null ? (
              <CreateTargetInitContribution
                {...props}
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                prev={
                  '/create/target/' + this.props.match.params.type + '/Editar'
                }
                targetID={this.state.temporaryID}
                configuration={this.state.temporaryConfiguration}
                schema={this.state.temporarySchema}
                refreshConf={this.refreshConf.bind(this)}
                type={this.props.match.params.type}
                next={
                  '/create/target/' + this.props.match.params.type + '/Cantidad'
                }
                nextRetirement={
                  '/create/target/' + this.props.match.params.type + '/Reglas'
                }
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          path={'/create/target/:type/Reglas'}
          render={props =>
            this.state.rulesTargets !== null &&
            this.state.rulesDefinitions !== null &&
            this.state.rulesAnswers !== null &&
            this.state.answersSet ? (
              <CreateTargetRules
                {...props}
                type={this.props.match.params.type}
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                id={this.state.temporaryID}
                rulesTarget={this.state.rulesTargets}
                rulesAnswers={this.state.rulesAnswers}
                rulesDefinitions={this.state.rulesDefinitions}
                refreshConf={this.refreshConf.bind(this)}
                prev={
                  '/create/target/' + this.props.match.params.type + '/Cantidad'
                }
                next={
                  '/create/target/' + this.props.match.params.type + '/Resumen'
                }
                history={this.props.history}
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          path={'/create/target/:type/Cargos'}
          render={props =>
            this.state.userBanks !== null ? (
              <CreateTargetAccounts
                {...props}
                banks={this.state.userBanks}
                idTarget={this.state.temporaryID}
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                history={this.props.history}
                refreshConf={this.refreshConf.bind(this)}
                prev={
                  '/create/target/' + this.props.match.params.type + '/Reglas'
                }
                next={
                  '/create/target/' + this.props.match.params.type + '/EndTour'
                }
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        {/*ºº Esta ruta se llama desde algún sitio?? */}
        <Route
          path={'/create/target/:type/EndTour'}
          render={props =>
            this.state.temporary !== null &&
            this.state.founds !== null &&
            this.state.products !== null &&
            this.state.userData !== null &&
            this.state.contractSchema !== null &&
            this.state.contractCurrentData !== null ? (
              <CreateTargetEnd
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                userData={this.state.userData}
                target={this.state.temporary}
                product={this.state.products}
                founds={this.state.founds}
                type={this.props.match.params.type}
                contractSchema={this.state.contractSchema}
                contractCurrentData={this.state.contractCurrentData}
                history={this.props.history}
                prev={
                  '/create/target/' + this.props.match.params.type + '/Cargos'
                }
                next={
                  '/create/target/' + this.props.match.params.type + '/Firma'
                }
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          path={'/create/target/:type/Firma'}
          render={props =>
            this.state.temporary !== null ? (
              <CreateTargetCreateContract
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                id={this.state.temporary._id}
                history={this.props.history}
                location={this.props.location}
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          path={'/create/target/:type/Cantidad'}
          render={props =>
            this.state.temporaryConfiguration !== null ? (
              <CreateTargetInitSummary
                baseUrl={this.props.baseUrl}
                token={this.props.token}
                history={this.props.history}
                id={this.state.temporaryID}
                match={this.props.match}
                temporaryConfiguration={this.state.temporaryConfiguration}
                prev={
                  '/create/target/' + this.props.match.params.type + '/Inicial'
                }
                next={
                  '/create/target/' + this.props.match.params.type + '/Reglas'
                }
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          path={'/create/target/:type/Resumen'}
          render={props =>
            this.state.temporaryConfiguration !== null &&
            this.state.founds !== null &&
            this.state.temporary !== null &&
            this.state.rulesTargets !== null &&
            this.state.products !== null &&
            this.state.rulesAnswers !== null ? (
              <CreateTargetSummary
                baseUrl={this.props.baseUrl}
                id={this.state.temporaryID}
                token={this.props.token}
                history={this.props.history}
                temporaryConfiguration={this.state.temporaryConfiguration}
                target={this.state.temporary}
                rules={this.state.rulesAnswers}
                rulesDef={this.state.rulesTargets}
                product={this.state.products}
                founds={this.state.founds}
                productIdentifier={this.state.productIdentifier}
                prev={
                  '/create/target/' + this.props.match.params.type + '/Reglas'
                }
                next={
                  '/create/target/' + this.props.match.params.type + '/Cargos'
                }
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
        <Route
          render={props =>
            this.state.founds !== null && this.state.surveyFetched ? (
              <CreateTargetStart
                {...props}
                history={this.props.history}
                founds={this.state.founds}
                products={this.state.products}
                new={this.state.new}
                createNew={this.setToZero.bind(this)}
                reset={this.setToZero.bind(this)}
                doSurvey={this.state.doSurvey}
                // TODO: revisar esta duplicado next y next2
                next={
                  '/create/target/' + this.props.match.params.type + '/Editar' // '/products'
                }
                next2={
                  '/create/target/' + this.props.match.params.type + '/Editar'
                }
              />
            ) : (
              <div className={classes.targetsConfig_loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )
          }
        />
      </Switch>
    );
  }
}

// const tabs = [
//   { label: 'Productos', icon: <ExtensionOutlined /> },
//   { label: 'Editar', icon: <Adjust /> },
//   { label: 'Inicial', icon: <Adjust /> },
//   { label: 'Reglas', icon: <EuroSymbol /> },
//   { label: 'Cargos', icon: <CreditCard /> },
//   { label: 'EndTour', icon: <Adjust /> },
//   { label: 'Firma', icon: <Adjust /> },
//   { label: 'Cantidad', icon: <Adjust /> },
//   { label: 'Resumen', icon: <Adjust /> },
// ];

const styles = theme => ({
  targetsTabs_tabs1_root: {
    position: 'absolute',
    width: '100%',
    padding: '0 5px 0 5px',
    bottom: 0,
  },
  targetsTabs_tabs1_indicator: {
    display: 'none',
  },
  targetsTabs_tab1_label: {},
  targetsTabs_tab1_textColorInherit: {
    opacity: 1,
  },
  targetsTabs_tab1_root: {
    color: theme.palette.primary.main,
    fontSize: 8,
  },
  targetsTabs_tab1_selected: {
    color: theme.palette.secondary.main + '!important',
  },
  targetsConfig_imgDiv: {
    position: 'relative',
    maxWidth: '100%',
    height: '25vh',
    minHeight: 142,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  targetsConfig_imgDiv_overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  targetsConfig_targetName: {
    paddingTop: 1,
    display: 'inline',
    fontSize: '0.85em',
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    padding: 10,
    boxSizing: 'border-box',
    transition: 0.2,
    zIndex: 99999,
  },
  targetsConfig_movilScreen: {
    minHeight: '640px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  targetsConfig_loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  targetsConfig_container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: '100%',
    maxHeight: 400,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  targetsConfig_img: {
    maxWidth: '100%',
  },
  targetsConfig_stepper: {
    height: '10vh',
    minHeight: 57,
    padding: 'unset',
    paddingLeft: 8,
    paddingRight: 8,
  },
  targetConfig_stepLabel_active: {
    color: theme.palette.primary.dark,
  },
  targetConfig_stepLabel_complete: {
    color: theme.palette.secondary.main,
  },
  targetConfig_stepLabel: {
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles, { withTheme: true })(CreateTarget);
