import React from 'react';
import config from './config';

export default function Tareas(props) {
  return (
    <svg
      width='14px'
      height='18px'
      viewBox='0 0 14 18'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ ...config, ...props.style }}
    >
      <title>ic_camisa</title>
      <g
        id='Page-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='ic_camisa'
          transform='translate(-5.000000, -3.000000)'
          fill={props.style.color}
          fill-rule='nonzero'
        >
          <path d='M18.7109375,5.109375 L16.4001007,5.109375 L15.3454132,3 L8.3655243,3 L7.3108368,5.109375 L5,5.109375 L5,18.8203125 L10.0274811,18.8203125 L11.8554688,21 L13.6834564,18.8203125 L18.7109375,18.8203125 L18.7109375,5.109375 Z M12.5842743,9.328125 L11.1266632,9.328125 L10.7393951,8.55331423 L11.8554688,7.43724061 L12.9716797,8.55331423 L12.5842743,9.328125 Z M12.6013031,6.69140625 L14.875061,4.41751097 L15.5152893,5.69810487 L14.7338867,8.82398986 L12.6013031,6.69140625 Z M11.8554688,5.94557189 L9.96458436,4.0546875 L13.7463531,4.0546875 L11.8554688,5.94557189 Z M8.83587646,4.41751097 L11.1096344,6.69140625 L8.9771881,8.82398986 L8.19564821,5.69810487 L8.83587646,4.41751097 Z M6.0546875,6.1640625 L7.22500609,6.1640625 L8.4056244,10.8869476 L9.95318604,9.33938597 L10.2656097,9.9642334 L9.7779541,17.765625 L6.0546875,17.765625 L6.0546875,6.1640625 Z M11.8554688,19.3586426 L10.8128662,18.1155396 L11.2962646,10.3828125 L12.4146729,10.3828125 L12.8980713,18.1155396 L11.8554688,19.3586426 Z M17.65625,17.765625 L13.9329834,17.765625 L13.4453278,9.9642334 L13.7577515,9.33938597 L15.3053131,10.8869476 L16.4859314,6.1640625 L17.65625,6.1640625 L17.65625,17.765625 Z'></path>
        </g>
      </g>
    </svg>
  );
}
