import React, { useEffect, useState } from 'react';
import SuggestComponent from './components/SuggestComponent';
import { TargetsAPI } from '../../../src-core/sdk/index';
import { useSelector } from 'react-redux';
import { BackendWrapper } from 'coinscrap-webapp-core';

import { dataManagerList, dataPlanList } from './plandata';
const PlanPicker = ({ onChange, value, style }) => {
  const store = useSelector(state => state.main);

  const [sources, setSources] = useState([]);
  const [plans, setPlans] = useState([]);
  const [listPlansNames, setListPlanNames] = useState([]);
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (source) {
      setListPlanNames(plans.filter(plan => plan.managerId === source?.id));
    }
  }, [source, plans]);

  // get Data
  useEffect(() => {
    (async () => {
      try {
        //   const response = await TargetsAPI.getManagerList(
        //     null,
        //     null,
        //     store.token,
        //   );
        // sources = await response.json();

        setSources(dataManagerList);

        const findCurrentSource = (dataManagerList || []).find(
          item => item.name.toLowerCase() === value?.managerName?.toLowerCase(),
        );

        if (findCurrentSource) {
          setSource(findCurrentSource);
        }
      } catch (e) {
        console.error('ERROR', e);
        let messageError = e.message.toString();
        messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
        setLoading(undefined);
        // openModal(() => <ModalError>{messageError}</ModalError>, {
        //   disableClickAway: true,
        //   closeButton: true,
        // })();
      }
      try {
        const response = await TargetsAPI.getPensionList(
          store.baseUrl,
          '',
          store.token,
        );

        const plans = await response.json();
        setPlans(dataPlanList);
      } catch (e) {
        console.error('ERROR', e);
        let messageError = e.message.toString();
        messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
        setLoading(undefined);
        // openModal(() => <ModalError>{messageError}</ModalError>, {
        //   disableClickAway: true,
        //   closeButton: true,
        // });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <SuggestComponent
        style={style}
        disabled={sources === null}
        onChange={source => {
          const sourceSelected = (sources || []).find(
            item => item.name.toLowerCase() === source.toLowerCase(),
          );

          if (sourceSelected) {
            setSource(sourceSelected);
          }
        }}
        value={source?.name}
        label='Busca tu gestora'
        type='autoSuggest'
        selectOptions={(sources || []).map(item => {
          return {
            label: item.name,
            value: item.name,
          };
        })}
      />
      {source && (
        <SuggestComponent
          style={style}
          onChange={plan => {
            console.log(plan);
            const sourcePlanSelected = listPlansNames.find(
              item => item.name.toLowerCase() === plan.toLowerCase(),
            );

            if (sourcePlanSelected) {
              onChange(sourcePlanSelected);
            }
          }}
          value={value?.name}
          label='Busca tu plan'
          type='autoSuggest'
          selectOptions={listPlansNames.map(item => {
            return {
              label: item.name,
              value: item.name,
            };
          })}
        />
      )}
    </>
  );
};

export default PlanPicker;
