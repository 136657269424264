import React from 'react';

const TestPdf = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <button
        onClick={() => {
          window.location.href =
            'https://pdfdownload.com/api.evo.coinscrap.com/api/documents/623649ef764b530011186fec/file?tokenTarget=eyJhbGciOiJIUzI1NiJ9.NjIzNjQ3Njg3NjRiNTMwMDExMTg2ZjZi.WRL03pBj1TP9aBfNH0a_PpcJiiCosMnTtB-NhqohHhE';
        }}
      >
        Download pdf
      </button>
    </div>
  );
};

export default TestPdf;
