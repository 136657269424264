import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Button } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import routes from '../../../../config/routes';
import colors from '../../../../config/colors';
import { StoreContext } from '../../../../pensionStore/Store';
import Check from '../../../common/svgs/Check';
import navConfig from '../../config/navigation';
// import { calculatePublicPensionYearlyAmountAtRetirement } from '../../calculations/index';
import { calculatePublicPensionYearlyAmountAtRetirement } from '@coinscrap/retirement-calculations';

import CurrencyDecimals from '../../../../libs/CurrencyDecimals';

export default props => {
  const [pensionAtRetirement, setPensionAtRetirement] = useState('');
  const context = useContext(StoreContext);
  const { state } = context;

  useEffect(() => {
    const pension = calculatePublicPensionYearlyAmountAtRetirement(
      state.simulation.typeEmployment,
      state.simulation.grossIncome,
      state.simulation.age,
      state.simulation.contribution,
    );
    setPensionAtRetirement(pension);
  }, []);

  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt>
        <View width85St flexSt>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Check style={{ color: colors.iconGreen }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt>
              ¡Enhorabuena!
            </Text>
            <Text centerSt font16St>
              A partir del año {state.simulation.year} y siguientes tu pensión
              podrá ser de aproximadamente{' '}
              {CurrencyDecimals(pensionAtRetirement, true, true)}
            </Text>
          </View>
        </View>

        <View>
          <View width85St>
            <Text font12St style={{ textAlign: 'justify' }}>
              * Cálculo realizado teniendo en cuenta el simulador de jubilación
              facilitado en la web de la sede de la Seguridad Social. Este
              servicio permite simular la cuantía aproximada con la que se va a
              jubilar. Permite simular situaciones futuras teniendo en cuenta
              los datos aportados por el usuario.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <Button
            pinkButtonSt
            onClick={() => {
              props.history.push(routes.retirementSimulationSavingsInfo);
            }}
          >
            CONTINUAR
          </Button>
        </View>
      </Footer>
    </>
  );
};
