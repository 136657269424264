import React, { Component } from 'react';
import { Utils } from '../../../resources/index';
// import { notificationsList_strings } from './text';
// import PriorityHigh from '@material-ui/icons/PriorityHigh';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import Check from '@material-ui/icons/Check';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

class NotificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: null,
      seen: null,
    };
  }

  componentDidMount() {
    const toggle = [];
    const seen = [];
    this.props.notifications.forEach(notification => {
      toggle.push(false);
      if (notification.isPending) {
        seen.push(false);
      } else {
        seen.push(true);
      }
    });
    this.setState({
      toggle: toggle.slice(),
      seen: seen.slice(),
    });
  }

  onToggle(index) {
    const tog = this.state.toggle;
    tog[index] = !tog[index];
    this.setState({
      toggle: tog,
    });
  }

  onSeen(index, id) {
    const vist = this.state.seen;
    vist[index] = true;
    this.setState({
      seen: vist,
    });
    this.props.checkNotification(id);
  }

  renderNotifications() {
    const { config, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.notifications.map((notification, index) => {
      return (
        <ListItem
          {...config.listItem1}
          key={notification._id}
          classes={transformClasses('notificationsList_listItem1', classes)}
        >
          <div className={classes.notificationsList_titleContainer}>
            <div className={classes.notificationsList_titleIconContainer}>
              {!this.state.seen[index] && (
                <SvgIcon
                  color='primary'
                  {...config.iconButton1}
                  classes={transformClasses(
                    'notificationsList_iconButton1',
                    classes,
                  )}
                  onClick={() => this.onSeen(index, notification._id)}
                >
                  <path d='M 12 24 C 5.373 24 0 18.627 0 12 C 0 5.373 5.373 0 12 0 C 18.627 0 24 5.373 24 12 C 24 18.627 18.627 24 12 24 Z M 12 22.286 C 17.681 22.286 22.286 17.681 22.286 12 C 22.286 6.319 17.681 1.714 12 1.714 C 6.319 1.714 1.714 6.319 1.714 12 C 1.714 17.681 6.319 22.286 12 22.286 Z M 11.143 9.429 L 12.857 9.429 L 12.857 18.857 L 11.143 18.857 L 11.143 9.429 Z M 12 5.143 C 12.71 5.143 13.286 5.718 13.286 6.429 C 13.286 7.139 12.71 7.714 12 7.714 C 11.29 7.714 10.714 7.139 10.714 6.429 C 10.714 5.718 11.29 5.143 12 5.143 Z' />
                </SvgIcon>
              )}
              {this.state.seen[index] && (
                <SvgIcon
                  color='primary'
                  {...config.iconButton2}
                  classes={transformClasses(
                    'notificationsList_iconButton2',
                    classes,
                  )}
                >
                  <path d='M18.5,10.6c-0.4,0-0.7,0.3-0.7,0.7V12c0,1.8-0.7,3.6-2,4.8c-1.3,1.3-3,2-4.8,2c0,0,0,0,0,0      c-3.8,0-6.9-3.1-6.9-6.9c0-1.8,0.7-3.6,2-4.8c1.3-1.3,3-2,4.8-2c0,0,0,0,0,0c1,0,1.9,0.2,2.8,0.6c0.4,0.2,0.8,0,0.9-0.4      c0.2-0.4,0-0.8-0.4-0.9C13.3,4,12.1,3.7,11,3.7c0,0,0,0,0,0c-2.2,0-4.3,0.9-5.8,2.4C3.6,7.7,2.7,9.8,2.7,12      c0,2.2,0.9,4.3,2.4,5.8c1.6,1.6,3.6,2.4,5.8,2.4c0,0,0,0,0,0c2.2,0,4.3-0.9,5.8-2.4c1.6-1.6,2.4-3.6,2.4-5.8v-0.7      C19.2,10.9,18.9,10.6,18.5,10.6z' />
                  <path d='M19.8,4.7c-0.3-0.3-0.7-0.3-1,0L11,12.5l-1.8-1.8c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l2.3,2.3      c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l8.3-8.3C20,5.4,20,5,19.8,4.7z' />
                </SvgIcon>
              )}
            </div>
            <div className={classes.notificationsList_titleTextContainer}>
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'notificationsList_typography2',
                  classes,
                )}
              >
                {new Date(notification.sendDate).toLocaleDateString('es-ES')}
              </Typography>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'notificationsList_typography1',
                  classes,
                )}
              >
                {notification.title}
              </Typography>
              <Typography
                {...config.typography3}
                classes={transformClasses(
                  'notificationsList_typography3',
                  classes,
                )}
              >
                {notification.body}
              </Typography>
            </div>
          </div>
        </ListItem>
      );
    });
    return elements;
  }

  render() {
    const { config, classes } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.toggle !== null ? (
      <List
        {...config.list1}
        classes={transformClasses('notificationsList_list1', classes)}
      >
        {this.renderNotifications()}
      </List>
    ) : (
      <div />
    );
  }
}

export default NotificationsList;
