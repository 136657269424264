import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';

class TabsWithState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.current,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, ...otherProps } = this.props;
    return (
      <Tabs
        {...otherProps}
        value={this.state.value}
        onChange={(...args) => this.handleChange(...args)}
        //fullWidth
        classes={classes}
      />
    );
  }
}

export default TabsWithState;
