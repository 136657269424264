import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';

import { PopoverCustom, PopoverCustomConfig } from '../../../ui/index';

import { referral_strings } from './text';
import { Utils } from '../../../resources/index';

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  openPopover(event) {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
    setTimeout(this.closePopover.bind(this), 2000);
  }

  closePopover() {
    this.setState({
      open: false,
      anchorEl: null,
    });
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  onCopy(event) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(this.props.info.referralCode);
      this.openPopover(event);
      return;
    }
    navigator.clipboard.writeText(this.props.info.referralCode);
    this.openPopover(event);
  }

  render() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const customPopopverConfig = {
      ...PopoverCustomConfig,
      id: 'copy-popover',
      disableRestoreFocus: true,
      children: (
        <Typography
          {...config.typography8}
          classes={transformClasses('referral_typography8', classes)}
        >
          {referral_strings['es'].referral_popover_text}
        </Typography>
      ),
    };
    return (
      <div className={classes.referral_container}>
        <div className={classes.referral_imgContainer} />
        <div className={classes.referral_textContainer}>
          <Typography
            {...config.typography1}
            classes={transformClasses('referral_typography1', classes)}
          >
            {referral_strings['es'].referral_info_text1}
          </Typography>
          <Typography
            {...config.typography2}
            classes={transformClasses('referral_typography2', classes)}
          >
            {this.props.info.branchProperties.contentDescription}
          </Typography>
        </div>
        <div className={classes.referral_countContainer}>
          <div className={classes.referral_friendsCountContainer}>
            <Typography
              {...config.typography3}
              classes={transformClasses('referral_typography3', classes)}
            >
              {this.props.info.friendUsers.length}
            </Typography>
            <Typography
              {...config.typography4}
              classes={transformClasses('referral_typography4', classes)}
            >
              {referral_strings['es'].referral_info_text2}
            </Typography>
          </div>
          <div className={classes.referral_moneyCountContainer}>
            <Typography
              {...config.typography5}
              classes={transformClasses('referral_typography5', classes)}
            >
              {Utils.formatCurrency(this.props.info.earnedMoney)}
            </Typography>
            <Typography
              {...config.typography6}
              classes={transformClasses('referral_typography6', classes)}
            >
              {referral_strings['es'].referral_info_text3}
            </Typography>
          </div>
        </div>
        <div className={classes.referral_copyContainer}>
          <Input
            {...config.input1}
            aria-owns={this.state.open ? 'copy-popover' : undefined}
            classes={transformClasses('referral_input1', classes)}
            value={this.props.info.referralCode}
            onClick={e => this.onCopy(e)}
          />
          <PopoverCustom
            config={customPopopverConfig}
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            classes={classes}
          />
          <Typography
            {...config.typography7}
            classes={transformClasses('referral_typography7', classes)}
          >
            {referral_strings['es'].referral_info_text4}
          </Typography>
        </div>
      </div>
    );
  }
}

export default Referral;
