import React from 'react';
import config from './config';

export default function Tareas(props) {
  return (
    <svg
      width='18px'
      height='18px'
      viewBox='0 0 18 18'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ ...config, ...props.style }}
    >
      <title>ic_inversion</title>
      <g
        id='Page-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='ic_inversion'
          transform='translate(-3.000000, -3.000000)'
          fill={props.style.color}
          fill-rule='nonzero'
        >
          <path d='M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,4.0702049 C16.3795049,4.0702049 19.9297951,7.6204951 19.9297951,12 C19.9297951,16.3795049 16.3795049,19.9297951 12,19.9297951 C7.6204951,19.9297951 4.0702049,16.3795049 4.0702049,12 C4.0702049,7.6204951 7.6204951,4.0702049 12,4.0702049 Z M12.9717757,9.37762686 C12.8208415,8.94889482 12.1729109,8.9394812 11.9897922,9.3309625 L11.9648816,9.39610419 L10.6560227,13.7112679 L10.5260498,13.7195646 L9.35442474,11.1099404 C9.19699169,10.7592812 8.68214182,10.7134258 8.44624515,11.0048969 L8.40543887,11.0635087 L6.80738999,13.7492106 L7.72704064,14.2087336 L8.73294821,12.5184095 L8.85614082,12.5254785 L10.2120156,15.5454921 C10.3913256,15.9448791 11.0029124,15.9329638 11.1797555,15.5581793 L11.2050552,15.4923636 L12.4471808,11.3972125 L12.5793077,11.3943051 L13.5613499,14.183817 C13.6965278,14.5677928 14.2541201,14.6282938 14.4931353,14.3113653 L14.5318566,14.2515078 L16.1606414,11.2905045 L16.2508715,11.260962 L17.1230769,11.6662412 L16.7578771,9.09230769 L14.350823,10.3795768 L15.1897115,10.7687772 L15.2213557,10.8653539 L14.2439721,12.6423845 L14.1174589,12.6319647 L12.9717757,9.37762686 Z'></path>
        </g>
      </g>
    </svg>
  );
}
