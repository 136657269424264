import React, { useContext, useEffect } from 'react';
import { View, Text, Button } from 'coinscrap-webapp-core';
import { calculateComplementaryPeriodicContribution } from '@coinscrap/retirement-calculations';
import Header from '../../../components/MyHeader';
import Footer from '../../../components/MyFooter';
import routes from '../../../config/routes';
import Flow from '../../../components/Flow';
import Tarta from '../../common/svgs/Tarta';
import Camisa from '../../common/svgs/Camisa';
import Fabrica from '../../common/svgs/Fabrica';
import Inversion from '../../common/svgs/Inversion';
import Billetes from '../../common/svgs/Billetes';
import Monedas from '../../common/svgs/Monedas';
import CurrencyDecimals from '../../../libs/CurrencyDecimals';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TargetsAPI } from '../../../src-core/sdk/index';

import { StoreContext } from '../../../pensionStore/Store';
import * as Types from '../../../pensionStore/types';
import navConfig from '../config/navigation';
import { useSelector } from 'react-redux';

export default props => {
  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  const { simulation, pensionPlan } = state;

  const store = useSelector(state => state.main);
  const { prodsForUser } = store;
  const flowParams = Flow.getParams();

  useEffect(() => {
    // if (!state.simulation.started) {
    //   props.history.push(navConfig.notStarted);
    // }

    if (flowParams.productName) {
      dispatch({
        type: Types.NEW_PLAN_SIMULATION,
        payload: {
          profileInvestor: flowParams.productName,
        },
      });
    }

    // state.simulation.typeEmployment &&
    //   setEmploymentType(state.simulation.typeEmployment);
  }, []);

  const saveToStore = () => {
    let findProduct;
    for (const property in prodsForUser) {
      if (prodsForUser[property].name === simulation.profileInvestor) {
        findProduct = prodsForUser[property];
      }
    }
    const PeriodicContribution = calculateComplementaryPeriodicContribution(
      100,
      simulation.typeEmployment,
      simulation.grossIncome,
      simulation.savings,
      simulation.age,
      simulation.contribution,
      0.03,
      0.02,
      83,
    );

    dispatch({
      type: Types.SAVE_SIMULATION,
      payload: {
        yearlyContribution: PeriodicContribution.neededYearlyContribution.toFixed(
          2,
        ),
        monthlyContribution: PeriodicContribution.neededMonthlyContribution.toFixed(
          2,
        ),
      },
    });
  };

  const saveObjective = async () => {
    saveToStore();
    let data;

    flowParams.flujo === 'transfer'
      ? (data = {
          // initialContribution: 50,
          retirementAge: 67,
          transferPensionPlan: pensionPlan.id,
        })
      : (data = {
          // initialContribution: simulation.savings,
          retirementAge: 67,
        });
    console.log(data);
    try {
      await TargetsAPI.setTargetConfiguration(
        store.baseUrl,
        flowParams.temporaryObjectiveId,
        store.token,
        data,
      );

      props.history.push(routes.retirementSimulationResults);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header
        title='Resumen simulación'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt spaceBetweenSt>
        <View>
          <View componentWrapperSt style={{ marginTop: 20, marginBottom: 20 }}>
            <View width85St topSt startSt>
              <Text font14St weight400St>
                TU JUBILACIÓN
              </Text>

              <SummaryCard
                Icon={
                  <Tarta style={{ color: 'white', width: 21, height: 21 }} />
                }
                label='Edad'
                input={`${state.simulation.age || '0'} años`}
              />
              <SummaryCard
                Icon={
                  <Camisa style={{ color: 'white', width: 16, height: 21 }} />
                }
                label='Años cotizados en la S.S'
                input={`${state.simulation.contribution || '0'} años`}
              />
            </View>
          </View>
          <View componentWrapperSt style={{ marginTop: 0, marginBottom: 20 }}>
            <View width85St topSt startSt>
              <Text font14St weight400St>
                TU PENSIÓN
              </Text>

              <SummaryCard
                Icon={
                  <Fabrica style={{ color: 'white', width: 21, height: 21 }} />
                }
                label='Trabajo'
                input={`Por cuenta ${state.simulation.typeEmployment || ''}`}
              />
              <SummaryCard
                Icon={
                  <Billetes style={{ color: 'white', width: 27, height: 21 }} />
                }
                label='Salario'
                input={`${state.simulation.grossIncome || '0'} € bruto/año`}
              />
            </View>
          </View>
          <View componentWrapperSt style={{ marginTop: 0, marginBottom: 20 }}>
            <View width85St topSt startSt>
              <Text font14St weight400St>
                TU AHORRO
              </Text>

              <SummaryCard
                Icon={
                  <Monedas style={{ color: 'white', width: 21, height: 21 }} />
                }
                label='Aportado'
                input={CurrencyDecimals(state.simulation.savings, true, true)}
              />
            </View>
          </View>
          <View componentWrapperSt style={{ marginTop: 0, marginBottom: 20 }}>
            <View width85St topSt startSt>
              <Text font14St weight400St>
                TU PERFIL INVERSOR
              </Text>

              <SummaryCard
                Icon={
                  <Inversion
                    style={{ color: 'white', width: 21, height: 21 }}
                  />
                }
                label='Perfil de riesgo'
                input={state.simulation.profileInvestor || 'No seleccionado'}
              />
            </View>
          </View>
        </View>

        {/* <View style={{ paddingBottom: 100, justifyContent: 'flex-end' }}>
          <View width85St>
            <Text font16St>
              A continuación, te vamos a mostrar la simulación de tus datos
              capitalizados al ano {state.simulation.year}.
            </Text>
          </View>
        </View> */}
      </View>
      <Footer>
        <View style={{ padding: 15 }}>
          <View
            flexRowSt
            onClick={() =>
              props.history.push(routes.retirementSimulationCalculations)
            }
            startSt
            style={{ marginBottom: 10, maxWidth: '310px' }}
          >
            <Text font16St>CÁLCULOS DE LA SIMULACIÓN</Text>
            <ArrowForwardIosIcon style={{ fill: 'white', fontSize: '20px' }} />
          </View>
          <Button pinkButtonSt onClick={() => saveObjective()}>
            FINALIZAR SIMULACIÓN{' '}
          </Button>
        </View>
      </Footer>
    </>
  );
};

const SummaryCard = ({ Icon, label, input }) => {
  return (
    <View
      summaryCardSt
      spaceBetweenSt
      flexRowSt
      style={{ backgroundColor: '#424242' }}
    >
      <View autoWidthSt flexRowSt>
        <View style={{ width: '30px' }}>{Icon}</View>
        <Text
          font16St
          style={{
            marginLeft: 8,
          }}
        >
          {label}
        </Text>
      </View>
      <Text font16St weight500St>
        {input}
      </Text>
    </View>
  );
};
