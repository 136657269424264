import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Utils } from '../../../resources/index';

class ListTargetsMovementsDetailsLines extends Component {
  renderListItemsTransactions() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.lines.map(element => {
      const createDate = new Date(element.transactionOn);
      return (
        <ListItem
          {...config.listItem1}
          key={element._id}
          classes={transformClasses(
            'listTargetMovementsDetailsLines_listItem1',
            classes,
          )}
        >
          <div className={classes.pinkSquare} />
          <div className={classes.movementsDetailsLines_confe_container}>
            <div className={classes.movementsDetailsLines_concepto_container}>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'listTargetMovementsDetailsLines_typography1',
                  classes,
                )}
              >
                {element.title}
              </Typography>
            </div>
            <div className={classes.movementsDetailsLines_fecha_container}>
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'listTargetMovementsDetailsLines_typography2',
                  classes,
                )}
              >
                {createDate.toLocaleDateString('es-ES')}
              </Typography>
            </div>
          </div>
          <div className={classes.movementsDetailsLines_ammount_container}>
            <Typography
              {...config.typography3}
              classes={transformClasses(
                'listTargetMovementsDetailsLines_typography3',
                classes,
              )}
            >
              {Utils.formatCurrency(element.currencyAmount)}
            </Typography>
          </div>
        </ListItem>
      );
    });
    return elements;
  }

  renderListItemsLines() {
    const { classes, config } = this.props;
    const transformClasses = Utils.transformClasses;
    const elements = this.props.lines.map(element => {
      const createDate = new Date(element.date);
      return (
        <ListItem
          {...config.listItem1}
          key={element._id}
          classes={transformClasses(
            'listTargetMovementsDetailsLines_listItem1',
            classes,
          )}
        >
          <div className={classes.pinkSquare} />
          <div className={classes.movementsDetailsLines_confe_container}>
            <div className={classes.movementsDetailsLines_concepto_container}>
              <Typography
                {...config.typography1}
                classes={transformClasses(
                  'listTargetMovementsDetailsLines_typography1',
                  classes,
                )}
              >
                {element.message}
              </Typography>
            </div>
            <div className={classes.movementsDetailsLines_fecha_container}>
              <Typography
                {...config.typography2}
                classes={transformClasses(
                  'listTargetMovementsDetailsLines_typography2',
                  classes,
                )}
              >
                {createDate.toLocaleDateString('es-ES')}
              </Typography>
            </div>
          </div>
          <div className={classes.movementsDetailsLines_ammount_container} />
        </ListItem>
      );
    });
    return elements;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.listTargetMovementsDetailsLines_divListContainer}>
        {this.props.type === 'transactions' ? (
          <List children={this.renderListItemsTransactions()} />
        ) : (
          <List children={this.renderListItemsLines()} />
        )}
      </div>
    );
  }
}

export default ListTargetsMovementsDetailsLines;
