import React from 'react';
import config from './config';

export default function Tareas(props) {
  return (
    <svg
      width='18px'
      height='17px'
      viewBox='0 0 18 17'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ ...config, ...props.style }}
    >
      <title>ic_check</title>
      <g
        id='Page-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='ic_check'
          transform='translate(-3.000000, -4.000000)'
          fill={props.style.color}
          fill-rule='nonzero'
        >
          <path d='M11.3619408,4 C12.5398533,4.00064865 13.6826022,4.24406273 14.7585402,4.72348264 C15.114751,4.88223096 15.2747966,5.29961954 15.1161166,5.65579613 C14.9573682,6.01197272 14.5400821,6.17212076 14.1838031,6.01337244 C13.2896916,5.61499952 12.3400011,5.4126893 11.3611556,5.41214307 L11.3611556,5.41214307 C9.50352722,5.41214307 7.76067556,6.13344079 6.44914137,7.44343871 C5.13661713,8.75446081 4.41320277,10.4980977 4.41214445,12.3531315 C4.40995953,16.1826529 7.52367969,19.2998894 11.353167,19.3020744 L11.353167,19.3020744 C13.2108295,19.3020744 14.953647,18.5807766 16.2651812,17.2707787 C17.5776713,15.9598249 18.3010857,14.216188 18.302144,12.3611201 L18.302144,12.3611201 L18.302144,11.6499618 C18.302144,11.2600213 18.6182409,10.9438903 19.0082156,10.9438903 C19.3981902,10.9438903 19.7142871,11.2600213 19.7142871,11.6499959 L19.7142871,11.6499959 L19.7142871,12.3615298 C19.7129898,14.5941797 18.8425028,16.6923545 17.2631448,18.2699031 C15.6850159,19.8462226 13.5875581,20.7142857 11.3572637,20.7142857 L11.3572637,20.7142857 C9.12007323,20.7129884 7.0219326,19.8425014 5.44434989,18.2631435 C3.86676717,16.6837855 2.99873822,14.5846207 3,12.3523804 C3.00129867,10.1200719 3.87178567,8.02189708 5.45114363,6.44438265 C7.02927258,4.86809723 9.12673041,4 11.3570247,4 L11.3570247,4 Z M19.2740251,4.97628506 C19.5497692,4.70057509 19.996825,4.70057509 20.2725691,4.97628506 C20.5483132,5.25202918 20.5483132,5.69908492 20.2725691,5.97482903 L20.2725691,5.97482903 L11.8564845,14.3909819 C11.7185954,14.528871 11.5378954,14.5977985 11.3571954,14.5977985 C11.1764955,14.5977985 10.9957614,14.528871 10.8579064,14.3909819 L10.8579064,14.3909819 L8.56257646,12.095652 C8.28683234,11.8199078 8.28683234,11.3728521 8.56257646,11.097108 C8.83828643,10.8213639 9.28537631,10.8213639 9.56108629,11.097108 L9.56108629,11.097108 L11.3571272,12.8931489 Z'></path>
        </g>
      </g>
    </svg>
  );
}
