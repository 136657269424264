import {connect} from 'react-redux'
import MenuHome from '../views/menuHome/menuHome.component'

function mapStateToProps(state){
    const { token, baseUrl } = state.main
    return {
        token,
        baseUrl
    }
}

const mapDispatchToProps = {
    
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuHome)