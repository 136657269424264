import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { Utils } from '../../src-core/resources/index';
// import {
//   ListTargetsForRules,
//   ListTargetsForRulesConfig,
//   ListTargetsForRulesStyles,
// } from '../../core/
import { ListTargetsForRules } from '../../src-core/targets/index';
import { ListTargetsForRulesConfig } from '../../src-core/targets/index';
import { ListTargetsForRulesStyles } from '../../src-core/targets/index';

//import { ListConfig, ListStyles } from '../../core/
import { ListConfig } from '../../src-core/ui/index';
import { ListStyles } from '../../src-core/ui/index';

//import { RulesAPI, TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';
import { RulesAPI } from '../../src-core/sdk/index';

import SwipeableViewsConfigurableStyles from '../swipeableViews/swipeableViewsConfigurable.style';
import Body from '../../components/MyBody';
import Header from '../../components/MyHeader';
import MyAnimation from '../../components/MyAnimation';

class ListConfRule extends Component {
  constructor(props) {
    super(props);
    console.log('ruleDefId: ', this.props.match.params.id);
    this.state = {
      ruleDefId: this.props.match.params.id,
      ruleDef: null,
      targets: null,
      //rules: null,
      rulesByUserAndRuleDef: null,
      loadDone: false,
    };
  }

  componentDidMount() {
    this.getRuleDef();
    //this.targetsUser(this.props.baseUrl, this.props.token);
  }

  /*
  async targetsUser(url, token) {
    const response = await TargetsAPI.userTargets(url, 'me', token)
    if (response.status === 200) {
      const data = await response.json()
      this.setState({
        userTargets: data
      })
    } else {
      console.log('error recuperando targets de usuario ', response.status)
    }
  }
  */

  /*
    Devuelve todas los tipos de reglas, RulDefs
  */
  /*
  async getRulesDef(url, token) {
    const response = await RulesAPI.getRulesDefinitions(url, token)
    if (response.status === 200) {
      const data = await response.json()
      // console.log('rule filtered: ', data.filter(element => element._id===this.props.match.params.id))
      this.setState({
        rules: data
      })
      data.forEach(element => {
        this.getRulesByRuleDef(url, token, element._id, data)
      })
    } else {
      console.log('error getting the user Rules')
    }
  }
  */

  /*
    Devuelve todas los tipos de reglas, RulDefs
  */
  /*
  async getRuleDef() {
    const response = await RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token);
    if (response.status === 200) {
      const data = await response.json()
      console.log('ruleDef filtered: ', data.filter(element => element._id===this.state.ruleDefId))
      this.setState({
        ruleDef: data.filter(element => element._id===this.state.ruleDefId)[0]
      })
      this.getRulesByUserAndRuleDef();
    } else {
      console.log('error getting the user Rules')
    }
  }
*/
  async getRuleDef() {
    RulesAPI.getRulesDefinitions(this.props.baseUrl, this.props.token).then(
      data => {
        console.log(
          'ruleDef filtered: ',
          data.filter(element => element._id === this.state.ruleDefId),
        );
        this.setState({
          ruleDef: data.filter(
            element => element._id === this.state.ruleDefId,
          )[0],
        });
        this.getRulesByUserAndRuleDef();
      },
    );
  }

  /*
    Carga la reglas de un usuario de un tipo de regla (RuleDef)
  */
  async getRulesByUserAndRuleDef() {
    //let obj = this.state.ruleDefsCount;
    // id = RuleDef - Tipo Regla
    //ºº RulesAPI.getRulesByDefinition Esta a medio cambiar al estilo nuevo
    const response = await RulesAPI.getRulesByDefinition(
      this.props.baseUrl,
      'me',
      this.state.ruleDefId,
      this.props.token,
    );
    if (response.status === 200) {
      const data = await response.json();
      console.log('getRulesByUserAndRuleDef data:', data);
      this.setState({
        rulesByUserAndRuleDef: data,
      });
      this.getTargetsByUser();
      /*
      obj[this.state.ruleDefId] = data;
      if (
        Object.keys(obj).map(e => {
          return e
        }).length === data.length
      ) {
        this.setState({
          loadDone: true,
          ruleDefsCount: obj
        })
      } else {
        this.setState({
          ruleDefsCount: obj
        })
      }
      */
    } else {
      console.log('error consiguiendo rules by definition');
    }
  }

  getTargetsByUser() {
    TargetsAPI.userTargets(this.props.baseUrl, 'me', this.props.token, false)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          targets: data.filter(
            t => t.status !== 'CANCELED' || t.status !== 'FINISHED',
          ),
        });
        console.log('getTargetsByUser', this.state.targets);
        this.setState({
          loadDone: true,
        });
      })
      .catch(error => {
        console.log('error getting the user Targets');
      });
  }

  onClickCloseWindow() {
    this.props.history.push('/home/reglas/listadoReglas');
  }

  render() {
    const { classes /*, theme*/ } = this.props;
    const transformClasses = Utils.transformClasses;
    return (
      //<div className={classes.movilScreen}>
      <Fragment>
        <Header title='Reglas' rigthAction={() => this.onClickCloseWindow()} />
        <Body vAlign='top'>
          {this.state.loadDone && this.state.targets !== null ? (
            <div className={classes.title_container}>
              <MyAnimation name={this.state.ruleDef.identifier} color='white' />
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listConfRule_title_typography1',
                  classes,
                )}
              >
                {this.state.ruleDef.name}
              </Typography>
              <Typography
                variant='body1'
                color='primary'
                classes={transformClasses(
                  'listConfRule_title_typography2',
                  classes,
                )}
              >
                {this.state.ruleDef.title}
                {/*animations[this.state.ruleDef.identifier].subText*/}
              </Typography>
            </div>
          ) : (
            <div />
          )}
          {this.state.loadDone && this.state.targets !== null ? (
            <ListTargetsForRules
              targets={this.state.targets}
              ruleDefId={this.state.ruleDefId} //where?
              rulesByUserAndRuleDef={this.state.rulesByUserAndRuleDef} //where
              token={this.props.token}
              baseUrl={this.props.baseUrl}
              history={this.props.history}
              classes={classes}
              config={ListTargetsForRulesConfig}
              listConfig={ListConfig}
              location={this.props.location}
              ruleDef={this.state.ruleDef}
            />
          ) : (
            <div />
          )}
        </Body>
      </Fragment>
    );
  }
}

const styles = theme => ({
  ...ListTargetsForRulesStyles(theme),
  ...SwipeableViewsConfigurableStyles(theme),
  ...ListStyles(theme),
  movilScreen: {
    minHeight: '640px',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  listConfRules_header_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '12px',
    marginBottom: '30px',
  },
  listTargetForRulesHeader: {
    display: 'flex',
    padding: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10vh',
    minHeight: 57,
  },
  listTargetForRulesHeader_iconButton: {
    padding: 'unset',
  },
  listTargetForRulesHeader_typography: {
    textTransform: 'uppercase',
    fontWieght: 600,
  },
  listTargetForRules_optionsContainer: {
    width: '100%',
    height: '10vh',
    minHeight: 57,
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    padding: '0 8px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  listTargetForRules_optionTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  listTargetForRules_optionText: {
    width: '32%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listTargetForRules_optionControlContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  listTargetForRules_optionControl: {
    width: '32%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listConfRule_title_typography1_root: {
    fontWeight: 100,
    fontSize: '45px',
    lineHeight: '45px',
    textAlign: 'center',
  },
  listConfRule_title_typography2_root: {
    fontWeight: 600,
    fontSize: '11px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

export default withStyles(styles, { withTheme: true })(ListConfRule);
