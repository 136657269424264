import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//import { TargetsAPI } from '../../core/
import { TargetsAPI } from '../../src-core/sdk/index';

import { Utils } from '../../src-core/resources/index';
import CircularProgressIcon from '@material-ui/core/CircularProgress';

import TargetRefundInfo from '../targetRefundInfo/targetRefundInfo.component';
import TargetRefundInfoConfig from '../targetRefundInfo/targetRefundInfo.config';
import TargetRefundInfoStyles from '../targetRefundInfo/targetRefundInfo.style';
import { View, Text } from 'coinscrap-webapp-core';

class TargetRefund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: null,
      target: null,
      index: 0,
    };
  }
  componentDidMount() {
    TargetsAPI.refundInfo(
      this.props.baseUrl,
      this.props.targetID,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.log('targetRefundInfo', data);
        this.setState({
          info: data,
        });
      })
      .catch(error => {
        console.log('error recuperando info de refund ', error);
      });

    TargetsAPI.getTargets(
      this.props.baseUrl,
      this.props.targetID,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        this.setState({
          target: data,
        });
      })
      .catch(error => {
        console.log('error recuperando los datos del objetivo ', error);
      });
  }
  render() {
    const { classes, baseUrl, token, targetID } = this.props;
    const transformClasses = Utils.transformClasses;
    return this.state.info === null || this.state.target === null ? (
      <CircularProgressIcon />
    ) : (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {this.state.target.type === 'retirement' ? (
          <View fullHeightSt justifyCenterSt style={{ marginTop: 20 }}>
            <Text boldSt>
              Para este tipo de producto( Plan de Pensiones) no existe
              reembolso, salvo casos excepcionales.
            </Text>
            {/* <View topSt style={{ marginTop: 15 }}>
              <Text leftSt font16St>
                POR TELÉFONO
              </Text>
              <Text leftSt font14St>
                Llámanos al{' '}
                <a href='tel:+34910900900' style={{ color: 'white' }}>
                  91 090 09 00
                </a>{' '}
                y habla directamente con uno de nosotros.
              </Text>
              <Text leftSt font16St style={{ marginTop: 10 }}>
                POR CORREO ELECTRÓNICO
              </Text>
              <Text leftSt font14St>
                Entonces no dudes en mandarnos un email a
                <a
                  href='mailto:contactoevo@evobanco.com'
                  style={{ color: 'white' }}
                >
                  {' contactoevo@evobanco.com'}
                </a>
              </Text>
            </View> */}
          </View>
        ) : (
          <>
            <Tabs
              id='tabs'
              classes={transformClasses('targetsTabs_tabs1', classes)}
              variable='fullWidth'
              textColor='primary'
              value={this.state.index}
              onChange={(e, newValue) => this.setState({ index: newValue })}
            >
              <Tab
                classes={transformClasses('targetsTabs_tab1', classes)}
                label='REEMBOLSO PARCIAL'
              />
              <Tab
                classes={transformClasses('targetsTabs_tab1', classes)}
                label='REEMBOLSO TOTAL'
              />
            </Tabs>

            <TargetRefundInfo
              info={this.state.info}
              moneyAmounts={this.state.target.moneyAmounts}
              type={this.state.target.type}
              classes={classes}
              config={TargetRefundInfoConfig}
              baseUrl={baseUrl}
              token={token}
              id={targetID}
              index={this.state.index}
            />
          </>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  ...TargetRefundInfoStyles(theme),
  targetRefundInfo_typographyPartial_root: {
    color: 'white',
  },
  targetRefundInfo_typography1_root: {
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '18px',
    color: theme.palette.secondary.main,
  },
  targetRefundInfo_typography2_root: {
    fontSize: '24px',
    fontWeight: 100,
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  targetRefundInfo_typography3_root: {
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '18px',
    color: theme.palette.secondary.main,
  },
  targetRefundInfo_typography4_root: {
    fontSize: '24px',
    fontWeight: 100,
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  targetRefundInfo_typography5_root: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: '16px',
    color: theme.palette.primary.main,
  },
  targetRefundInfo_input1_input: {
    textAlign: 'center',
    color: 'white',
  },
  targetRefundInfo_typography7_root: {
    fontSize: '14px',
    color: 'white',
    marginLeft: '8px',
  },
  targetRefundInfo_button1_root: {
    fontWeight: 600,
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  targetRefundInfo_gridItemContainer3_item: {
    marginTop: 10,
    color: 'white',
  },
  targetRefundInfo_button2_root: {
    width: '100%',
    fontWeight: 600,
    borderRadius: 'unset',
    boxShadow: 'unset',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    //backgroundColor: theme.palette.primary.main,
    padding: 12,
    overflow: 'auto',
  },
  targetsTabs_tabs1_root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
  },
  targetsTabs_tabs1_flexContainer: {
    display: 'flex',
    justifyContent: 'center',

    borderBottom: 'solid 1px rgba(255,255,255,.3)',
  },
  targetsTabs_tab1_textColorPrimary: {
    fontSize: '14px',
    color: 'rgba(255,255,255,.5)',
    textTransform: 'uppercase',
  },
});

export default withStyles(styles, { withTheme: true })(TargetRefund);
