import {
  OBJECTIVE_ID,
  NAME_IMAGE_RETIREMENT,
  USER,
  PRODS_FOR_USER,
} from './constants';
const TOKEN = 'TOKEN';

export function fetchToken(data, baseUrl) {
  return dispatch => {
    dispatch({
      type: TOKEN,
      payload: data.user.token,
    });
  };
}

export function saveObjectiveId(payload) {
  return dispatch => {
    dispatch({
      type: OBJECTIVE_ID,
      payload: payload,
    });
  };
}
export function saveNameAndImageRetirement(payload) {
  return dispatch => {
    dispatch({
      type: NAME_IMAGE_RETIREMENT,
      payload: payload,
    });
  };
}
export function saveUserObject(payload) {
  return dispatch => {
    dispatch({
      type: USER,
      payload: payload,
    });
  };
}
export function saveProdsForUser(payload) {
  return dispatch => {
    dispatch({
      type: PRODS_FOR_USER,
      payload: payload,
    });
  };
}
