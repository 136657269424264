/*
    Información de cabecera de el objetivo en todos los tabs.
*/
import React, { Component } from 'react';

//import { withStyles } from '@material-ui/core/styles';

//import { TargetsAPI } from '../components-core/sdk';
import { TargetsAPI } from '../../src-core/sdk/index';

import ProductCard from '../Product/ProductCard';

class FollowupInfo extends Component {
  // TODO: refactorizar esto y hacerlo solo en el followupMain,
  // lo esta recalculando en varios tabs.
  componentDidMount() {
    this.getProduct();
  }

  getProduct() {
    TargetsAPI.getTargetProductInfo(
      this.props.baseUrl,
      this.props.match.params.id,
      this.props.token,
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response.status;
        }
      })
      .then(data => {
        console.error('Get Product', data);
        this.props.updateProduct(data);
      })
      .catch(error => {
        console.log(
          'error recuperando datos del producto asociado al target ',
          error,
        );
      });
  }

  // filterFounds() {
  //   console.log('followupInfo.filterFounds', this.props);
  //   //let pos = null;
  //   // this.props.product.foundsConfiguration.map((config, index) => {
  //   //   if (config.percentage === 100) {
  //   //     pos = index;
  //   //   }
  //   //   return null;
  //   // });
  //   //return this.props.product.founds[pos];

  //   const foundCode = this.props.product.foundsConfiguration[0].foundCode;
  //   console.error(
  //     'filterFound',
  //     foundCode,
  //     this.props.product.founds.filter(el => el.code === foundCode)[0],
  //   );
  //   return this.props.product.founds.filter(el => el.code === foundCode)[0];
  // }

  render() {
    //const product = this.props.product ? this.filterFounds() : null;
    //console.log('folloupInfo prod', product);

    return (
      this.props.product && (
        <ProductCard
          title={this.props.product.title}
          name={this.props.product.name}
          riskFactor={this.props.product.riskFactor}
          profitability={this.props.product.profitability}
          historicalPdf={this.props.product.pdf_history}
          history={this.props.history}
          location={this.props.location}
          type={this.props.type}
          //maxRiskFactor={this.state.maxRiskFactor}
          //pdf_more={product.pdf_more}
          // productId={this.state.productId}
        />
      )
    );
  }
}

export default FollowupInfo;
