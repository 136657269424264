import React, { Component } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SwipeableViews from 'react-swipeable-views';
import { Utils } from '../../../resources/index';
import PropTypes from 'prop-types';

class SwipeWithStateNoAutomatic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      step: 0,
    };
  }

  handleStepChange = index => {
    /*if (index === 0) {
      index = this.props.last - 1
    } else if (index === this.props.last) {
      index = 1
    }*/
    this.setState({
      index,
      //step: (index) % 3
    });
  };

  render() {
    const { classes, theme, config } = this.props;
    const { index, step } = this.state;
    const transformClasses = Utils.transformClasses;
    const maxSteps = 3;

    return (
      <div className={classes.card_container}>
        <Card
          {...config.card1}
          classes={transformClasses('card_card1', classes)}
        >
          <CardContent
            {...config.cardContent1}
            classes={transformClasses('card_cardcontent1', classes)}
          >
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={index}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
              className={classes.swipeRules_swipeableViews}
            >
              {this.props.renderData()}
            </SwipeableViews>
          </CardContent>
        </Card>
        <MobileStepper
          steps={maxSteps}
          position='static'
          activeStep={step}
          classes={transformClasses('card_stepper', classes)}
          nextButton={<div />}
          backButton={<div />}
        />
      </div>
    );
  }
}

export default SwipeWithStateNoAutomatic;

SwipeWithStateNoAutomatic.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  renderData: PropTypes.func,
};
