import React, { useState, useContext, useEffect, useRef } from 'react';
import { View, Text, Enfatize, TextField } from 'coinscrap-webapp-core';
import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import colors from '../../../../config/colors';
import Flow from '../../../../components/Flow';

import CircularSlider from '@fseehawer/react-circular-slider';
import { getCurrency } from '../../../../libs/Currency';
import routes from '../../../../config/routes';
import { StoreContext } from '../../../../pensionStore/Store';
import * as Types from '../../../../pensionStore/types';
import Monedas from '../../../common/svgs/Monedas';
import navConfig from '../../config/navigation';
import {
  onChangeAmountHandler,
  amountStringToNumber,
} from '../../../../libs/currencyInput';

export default props => {
  const [savings, setSavings] = useState(0);
  const [savingsInput, setSavingsInput] = useState(25000);
  const [showInput, setShowInput] = useState(false);
  const savingsInputRef = useRef(null);

  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  const flowParams = Flow.getParams();
  useEffect(() => {
    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
    state.simulation.savings && setSavings(state.simulation.savings);
    state.simulation.savings && setSavingsInput(state.simulation.savings);
  }, []);

  useEffect(() => {
    if (showInput) {
      savingsInputRef.current.focus();
    }
  }, [showInput]);

  const onChangeSalary = value => {
    const number = onChangeAmountHandler(value, setSavingsInput);

    const number2 = amountStringToNumber(number);

    setSavings(number2);
  };

  const handleBlur = () => {
    setShowInput(false);
  };

  useEffect(() => {
    const { current } = savingsInputRef;

    if (!current) {
      return;
    }

    current.addEventListener('blur', handleBlur);

    return () => {
      current.removeEventListener('blur', handleBlur);
    };
  }, [handleBlur]);

  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt bodyAddTopPaddingSt>
        <View width93St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Monedas style={{ color: colors.iconYellow }} />
            </View>
            <Text
              headlineSt
              centerSt
              headlineSpaceSt
              style={{ marginBottom: 5, fontSize: 30 }}
            >
              {flowParams.flujo === 'transfer'
                ? '¿ Cuánto tienes ahorrado en productos para tu jubilación?'
                : '¿ Cuánto tienes ahorrado en productos para tu jubilación?'}
            </Text>
            {/* <Text style={{ marginBottom: 20 }}>
              {flowParams.flujo === 'transfer'
                ? 'En productos para tu jubilación.'
                : 'En productos para tu jubilación.'}
            </Text> */}
            <View
              style={{
                marginTop: 10,
                marginBottom: 15,
                position: 'relative',
              }}
            >
              <>
                <CircularSlider
                  dataIndex={savings}
                  width={200}
                  trackSize={14}
                  progressSize={14}
                  trackColor='#6d5e57'
                  progressColorTo='#febb00'
                  progressColorFrom='#febb00'
                  knobColor='#ffffff'
                  renderLabelValue={<View />}
                  label='IMPORTE'
                  min={0}
                  max={100000}
                  onChange={value => {
                    var result = Math.round(value / 100) * 100;
                    setSavings(result);
                  }}
                />
                <View
                  autoWidthSt
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: 3,
                  }}
                >
                  <Text font12St boldSt style={{ marginBottom: 7 }}>
                    IMPORTE
                  </Text>
                  {showInput ? (
                    <TextField
                      value={savingsInput}
                      inputRef={savingsInputRef}
                      salaryInputSt
                      onChange={e => onChangeSalary(e.target.value)}
                    />
                  ) : (
                    <Text
                      onClick={() => {
                        setSavingsInput(savings);
                        setShowInput(true);
                      }}
                      style={{ fontSize: 32, zIndex: 5 }}
                    >
                      {getCurrency(savings)}
                    </Text>
                  )}
                  {/* <Text style={{ fontSize: 32 }}>{getCurrency(savings)}</Text> */}
                  <View
                    style={{
                      borderTop: '1px solid rgba(255, 255, 255, 0.5)',
                      width: 130,
                      marginTop: 7,
                      paddingTop: 4,
                    }}
                  ></View>
                </View>
              </>
            </View>
          </View>
        </View>
        <View style={{ paddingBottom: 10, justifyContent: 'flex-end' }}>
          <View width85St>
            <Text centerSt font16St>
              Descontaremos de la cantidad total necesaria, tu saldo ya
              acumulado en estos productos.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View flexRowSt spaceBetweenSt footerSmallRoundButtonWrapperSt>
          <View autoWidthSt flexRowSt>
            <View
              autoWidthSt
              smallDotSt
              style={{ backgroundColor: '#febb00' }}
            />
            <Text style={{ marginTop: 10 }}>
              <Enfatize style={{ fontSize: '35px', fontWeight: 100 }}>
                5
              </Enfatize>
              /6
            </Text>
          </View>
          <View autoWidthSt flexRowSt>
            <View
              autoWidthSt
              footerRoundOkButtonSt
              // disabledButtonSt={}
              style={{ paddingTop: 5, fontWeight: 'bold' }}
              onClick={() => {
                dispatch({
                  type: Types.NEW_PLAN_SIMULATION,
                  payload: {
                    savings: savings,
                  },
                });
                props.history.push(
                  routes.retirementSimulationSavingsCongratulations,
                );
              }}
            >
              OK
            </View>
          </View>
        </View>
      </Footer>
    </>
  );
};
