import React, { useState, useEffect, useRef } from 'react';

import Input from './Input';

import MenuItem from '@material-ui/core/MenuItem';

import * as InputLabelConfig from './styles';

const SuggestComponent = ({
  label,
  onChange,
  value,
  center,
  selectOptions = [],

  disabled,
  style,

  showLabel = true,
  showTotalResults = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(
      selectOptions.map(item => {
        return {
          label: item.label,
          id: item.value,
        };
      }),
    );
  }, [selectOptions]);

  const suggestionRef = useRef(null);

  // custom autoSuggest
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(
    selectOptions.find(item => item.value === value)?.label || '',
  );

  useEffect(() => {
    setInput(selectOptions.find(item => item.value === value)?.label || '');
  }, [value, selectOptions]);

  const onChangeSuggest = e => {
    const userInput = e?.target?.value || e;
    console.log(userInput);
    if (userInput) {
      if (typeof userInput !== 'object') {
        // Filter our suggestions that don't contain the user's input
        const unLinked = suggestions.filter(
          suggestion =>
            suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) >
            -1,
        );
        setInput(userInput);
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
        onChange(userInput);
      } else {
        setInput('');
        setFilteredSuggestions(suggestions);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
        onChange('');
      }
    }
  };

  const onClickSuggest = e => {
    const text = e || value;
    const findSuggest = selectOptions.find(item => item.value === text);
    setFilteredSuggestions([]);
    setInput(findSuggest?.label);
    onChangeSuggest(findSuggest?.value);
    setShowSuggestions(false);
  };

  useEffect(() => {
    if (suggestionRef.current && filteredSuggestions.length > 0) {
      suggestionRef.current.focus();
    }
  }, [filteredSuggestions]);

  const SuggestionsListComponent = () => {
    console.log('filteredSuggestions', filteredSuggestions);
    return filteredSuggestions.length ? (
      <InputLabelConfig.SuggestionsUl id='suggestBox' tabIndex='0'>
        {showTotalResults && (
          <MenuItem
            value={0}
            key={'resultsCount'}
            disabled
            style={{ justifyContent: 'center' }}
          >
            <p style={{ fontSize: '1rem', color: '#fff', textAlign: 'center' }}>
              Resultados: {filteredSuggestions.length}
            </p>
          </MenuItem>
        )}

        {filteredSuggestions.map((suggestion, index) => {
          console.log({ suggestion });
          let className;
          // Flag the active suggestion with a class
          if (suggestion.id === value) {
            className = 'suggestion-active';
          }
          return (
            <InputLabelConfig.SuggestionsLi
              className={className}
              key={suggestion.id}
              onClick={() => onClickSuggest(suggestion.id, index)}
            >
              {suggestion.label}
            </InputLabelConfig.SuggestionsLi>
          );
        })}
      </InputLabelConfig.SuggestionsUl>
    ) : (
      <div className={'no-suggestions'}>
        <p style={{ color: '#f4137b' }}>No hay resultados</p>
      </div>
    );
  };

  return (
    <InputLabelConfig.InputLabelWrapper>
      {showLabel && <InputLabelConfig.Label>{label}</InputLabelConfig.Label>}

      <InputLabelConfig.InputLabelWrapper>
        <Input
          ref={suggestionRef}
          onBlur={e => {
            let related = e.relatedTarget ? e.relatedTarget.id : null;
            if (related === 'suggestBox') {
              return;
            }
            onClickSuggest(value || '');
          }}
          disabled={disabled}
          value={input}
          onChange={onChangeSuggest}
          placeholder={label}
          style={style}
        />
        {showSuggestions && input && <SuggestionsListComponent />}
      </InputLabelConfig.InputLabelWrapper>
    </InputLabelConfig.InputLabelWrapper>
  );
};

export default SuggestComponent;
