import React, { useState, useContext, useEffect } from 'react';
import { View, Text, Enfatize } from 'coinscrap-webapp-core';

import Header from '../../../../components/MyHeader';
import Footer from '../../../../components/MyFooter';
import colors from '../../../../config/colors';
import routes from '../../../../config/routes';
import { StoreContext } from '../../../../pensionStore/Store';
import * as Types from '../../../../pensionStore/types';
import Fabrica from '../../../common/svgs/Fabrica';
import navConfig from '../../config/navigation';

export default props => {
  const [employmentType, setEmploymentType] = useState('ajena');

  const context = useContext(StoreContext);
  const { state, dispatch } = context;

  useEffect(() => {
    if (!state.simulation.started) {
      props.history.push(navConfig.notStarted);
    }
    state.simulation.typeEmployment &&
      setEmploymentType(state.simulation.typeEmployment);
  }, []);

  return (
    <>
      <Header
        title='Simulador pensiones'
        rigthAction={() => props.history.push(navConfig.topRight)}
        leftAction={() => props.history.goBack()}
      />
      <View bodySt justifyCenterSt bodyAddTopPaddingSt>
        <View width93St>
          <View>
            <View style={{ marginBottom: 20 }}>
              <Fabrica style={{ color: colors.iconGreen }} />
            </View>
            <Text headlineSt centerSt headlineSpaceSt style={{ fontSize: 30 }}>
              ¿Trabajas por cuenta propia o ajena?
            </Text>

            <View flexRowSt style={{ marginBottom: 50 }}>
              <View
                transitionSt
                autoWidthSt
                style={{
                  borderRadius: '50%',
                  height: 120,
                  width: 120,
                  border:
                    employmentType === 'propia'
                      ? '5px solid #55dcbc'
                      : '5px solid #6d5c54',
                  marginRight: 15,
                }}
                onClick={() => setEmploymentType('propia')}
              >
                <Text
                  style={{
                    fontSize: 26,
                    color: '#55dcbc',
                  }}
                >
                  Propia
                </Text>
              </View>
              <View
                transitionSt
                autoWidthSt
                style={{
                  borderRadius: '50%',
                  height: 120,
                  width: 120,
                  border:
                    employmentType === 'ajena'
                      ? '5px solid #55dcbc'
                      : '5px solid #6d5c54',
                }}
                onClick={() => setEmploymentType('ajena')}
              >
                <Text
                  style={{
                    fontSize: 26,
                    color: '#55dcbc',
                  }}
                >
                  Ajena
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ paddingBottom: 10, justifyContent: 'flex-end' }}>
          <View width85St>
            <Text centerSt font16St>
              Tu edad nos permitirá conocer a partir de que años podrías
              jubilarte.
            </Text>
          </View>
        </View>
      </View>
      <Footer>
        <View flexRowSt spaceBetweenSt footerSmallRoundButtonWrapperSt>
          <View autoWidthSt flexRowSt>
            <View autoWidthSt smallDotSt />
            <Text style={{ marginTop: 10 }}>
              <Enfatize style={{ fontSize: '35px', fontWeight: 100 }}>
                3
              </Enfatize>
              /6
            </Text>
          </View>
          <View autoWidthSt flexRowSt>
            <View
              autoWidthSt
              footerRoundOkButtonSt
              style={{ paddingTop: 5, fontWeight: 'bold' }}
              onClick={() => {
                dispatch({
                  type: Types.NEW_PLAN_SIMULATION,
                  payload: {
                    typeEmployment: employmentType,
                  },
                });
                props.history.push(
                  routes.retirementSimulationPensionEmploymentSalary,
                );
              }}
            >
              OK
            </View>
          </View>
        </View>
      </Footer>
    </>
  );
};
