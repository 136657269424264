import ScenariosConfig from '../Simulator/ScenariosConfig';
//import { AST_LabeledStatement } from 'terser';

export default class Simulator {
  static config = null;

  projectScenario(config, params, edad, initialAmount, monthlyAmount, years) {
    this.productId = 'evo_caser_unit_linked';
    this.config = config;
    this.initialAmount = initialAmount;
    this.monthlyAmount = monthlyAmount;
    this.years = years;

    var historicPerformance = config.historicPerformance,
      typicalDeviationForYear = config.getTypicalDeviationForYear(this.years),
      fromRatio = historicPerformance + typicalDeviationForYear * params.from,
      toRatio = historicPerformance + typicalDeviationForYear * params.to,
      estimatedCosts = config.getEstimatedCosts(this.initialAmount),
      fromProject = this.project(fromRatio, estimatedCosts, edad),
      toProject = this.project(toRatio, estimatedCosts, edad),
      fromRatioTotal = fromRatio - estimatedCosts,
      toRatioTotal = toRatio - estimatedCosts,
      statusForPerformances = this.Project_getStatusForPerformances(
        fromRatioTotal,
        toRatioTotal,
      ),
      riskForPerformances = this.Project_getRiskForPerformances(
        fromRatioTotal,
        toRatioTotal,
      );

    return {
      name: params.name,
      from: fromProject,
      to: toProject,
      status: statusForPerformances,
      risk: riskForPerformances,
      probability: params.probability,
    };
  }

  project(ratio, estimatedCosts, edad) {
    if (((estimatedCosts = estimatedCosts || 0), void 0 === ratio)) {
      var a = this.portfolio(this.years, this.strategy);
      ratio = a.performance;
      estimatedCosts = a.getEstimatedCosts();
    }
    var isSavingsPlan = this.isSavingsPlan();
    return this.Project_project(
      this.initialAmount,
      this.monthlyAmount,
      this.years,
      ratio,
      estimatedCosts,
      edad,
      isSavingsPlan,
    );
  }

  // (this.initialAmount, this.monthlyAmount, this.years, e, n, i, r)
  Project_project(
    initialAmount,
    monthlyAmount,
    years,
    ratio,
    estimatedCosts,
    edad,
    isSavingsPlan,
  ) {
    this.productId = 'evo_caser_unit_linked';

    years = Number(years);
    monthlyAmount = Number(monthlyAmount) || 0;
    initialAmount = Number(initialAmount) || 0;
    ratio = Number(ratio);
    estimatedCosts = estimatedCosts || 0;
    edad = parseInt(edad || 18);
    isSavingsPlan = isSavingsPlan || void 0 === isSavingsPlan;
    var c = null,
      d = null,
      u = 12 * Number(years),
      p = monthlyAmount * u + initialAmount,
      f = 12 * monthlyAmount,
      m = f + initialAmount,
      v = initialAmount,
      g = Math.pow(1 + 0.01 * ratio, 1 / 12),
      h = Math.pow(1 + 0.01 * estimatedCosts, 1 / 12) - 1,
      b = 0,
      y = 0;
    if (isSavingsPlan) {
      d = this.getCost(edad);
      c = d;
      v -= d;
    }
    for (var w = 1; w <= u; w++) {
      var A = v * g,
        x = v * h,
        P = A - v - x,
        C = Math.round(w / 12);
      b += P;
      v = A + monthlyAmount - x;
      y += x;

      var _ = 0 === w % 12 && w !== u;
      if (isSavingsPlan && _) {
        var M = this.getCost(edad + C);
        c += M;
        v -= M;
      }
    }
    var z = ratio;
    if (estimatedCosts) {
      z = this.sumAnnualized(z, -1 * estimatedCosts);
    }
    var I = {
      performance: {
        raw: z,
        annual: z,
      },
      fees: {
        total: y,
        yearly: y / years,
        monthly: y / years / 12,
        annual: estimatedCosts,
      },
      profit: b,
      profitWithFees: b + y,
      invested: p,
      yearlyInvest: f,
      firstYearInvest: m,
      balance: {
        amount: v,
        digits: Math.round(v).toString().length,
      },
      insurance: null,
    };
    isSavingsPlan &&
      (I.insurance = {
        total: c,
        annual: c / years,
        firstYear: d,
      });

    //console.log('Project_project', I);
    return I;
  }

  Project_getStatusForPerformances(e, t) {
    var n = 'success';
    // return 0 > e && (n = 'warning'), 0 > t && (n = 'danger'), n;
    0 > e && (n = 'warning');
    0 > t && (n = 'danger');
    return n;
  }

  Project_getRiskForPerformances(e, t) {
    var i = this.Project_getStatusForPerformances(e, t);
    if ('danger' === i) return i;
    t - e > 15 && (i = 'warning');
    t - e > 20 && (i = 'danger');
    return i;
  }

  // Utiles_sumAnnualized() {
  //   return function(e) {
  //     return this.Utiles_a(e, 365.25);
  //   };
  //   //   (
  //   //   Array.prototype.slice
  //   //     .call(arguments)
  //   //     .map(n)
  //   //     .reduce(t),
  //   // );
  // }

  Utiles_a(e, t) {
    var n = Math.pow(1 + Math.abs(e), t) - 1;
    // return 0 > e && (n *= -1), 100 * n;
    0 > e && (n *= -1);
    return 100 * n;
  }

  isSavingsPlan() {
    return (
      this.productId === this.config.ids.ID_CASER_UNIT_LINKED ||
      this.productId === this.config.ids.ID_EVO_CASER_UNIT_LINKED ||
      this.productId === this.config.ids.ID_AHORROBOT_CASER_UNIT_LINKED
    );
  }

  /*
getEstimatedCosts() {
var e =
arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5;
return this.getCosts(e).total;
}

getBaseCosts() {
var e =
arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5;
return this.productId === this.config.ids.ID_CASER_PENSION_PLAN
? this.config.CASER_PENSION_PLAN_COSTS
: this.productId === this.config.ids.ID_EVO_CASER_UNIT_LINKED
? this.config.EVO_CASER_UNIT_LINKED_COSTS
: this.productId === this.config.ids.ID_INVERSIS_INVESTMENT
? this.getInvestmentPlanCosts(e)
: this.productId === this.config.ids.ID_AHORROBOT_CASER_UNIT_LINKED
? this.config.AHORROBOT_CASER_UNIT_LINKED_COSTS
: this.config.CASER_UNIT_LINKED_COSTS;
}
*/
  getDeviations() {
    return {
      pessimistic: {
        name: 'pessimistic',
        from: -2,
        to: -1,
        probability: 14,
      },
      expected: {
        name: 'expected',
        from: -1,
        to: 1,
        probability: 67,
      },
      optimistic: {
        name: 'optimistic',
        from: 1,
        to: 2,
        probability: 14,
      },
      standard: {
        name: 'standard',
        from: 0,
        to: 0,
        probability: 100,
      },
    };
  }

  getCost(t) {
    var e = {
      18: 0.199,
      19: 0.204,
      20: 0.208,
      21: 0.212,
      22: 0.216,
      23: 0.22,
      24: 0.223,
      25: 0.227,
      26: 0.224,
      27: 0.22,
      28: 0.216,
      29: 0.211,
      30: 0.206,
      31: 0.207,
      32: 0.211,
      33: 0.22,
      34: 0.237,
      35: 0.262,
      36: 0.292,
      37: 0.325,
      38: 0.359,
      39: 0.399,
      40: 0.442,
      41: 0.493,
      42: 0.551,
      43: 0.616,
      44: 0.684,
      45: 0.758,
      46: 0.838,
      47: 0.928,
      48: 1.027,
      49: 1.138,
      50: 1.261,
      51: 1.398,
      52: 1.538,
      53: 1.681,
      54: 1.828,
      55: 1.985,
      56: 2.152,
      57: 2.327,
      58: 2.498,
      59: 2.66,
      60: 2.815,
      61: 2.969,
      62: 3.124,
      63: 3.289,
      64: 3.475,
      65: 3.691,
      66: 4.078,
      67: 4.539,
      68: 5.087,
      69: 5.734,
      70: 6.493,
      71: 7.382,
      72: 8.426,
      73: 9.868,
      74: 11.622,
      75: 13.77,
      76: 16.02,
      77: 18.703,
      78: 21.896,
      79: 25.677,
      80: 29.033,
      81: 32.815,
      82: 37.051,
      83: 41.777,
      84: 47.034,
      85: 52.876,
      86: 59.331,
      87: 66.408,
      88: 74.086,
      89: 82.319,
      90: 91.036,
      91: 100.147,
      92: 109.572,
      93: 119.243,
      94: 129.112,
      95: 139.149,
      96: 149.33,
      97: 159.629,
      98: 170.013,
      99: 180.45,
      100: 190.909,
      101: 207.253,
      102: 220.071,
      103: 233.322,
      104: 247.013,
      105: 261.146,
      106: 275.718,
      107: 290.721,
      108: 306.136,
      109: 321.935,
      110: 338.075,
      111: 354.495,
      112: 361.057,
      113: 500,
    };
    return void 0 !== e[t] ? e[t] : 500;
  }

  getCosts() {
    var e =
        arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1e5,
      t = this.getBaseCosts(e);
    return (
      !0 === t.hasOwnProperty(this.tolerance) && (t = t[this.tolerance]), t
    );
  }

  sumAnnualized() {
    return (function(e) {
      return sumAnnualized_a(e, 365.25);
    })(
      Array.prototype.slice
        .call(arguments)
        .map(sumAnnualized_n)
        .reduce(sumAnnualized_t),
    );
  }

  getGraphColor(index) {
    var COLORS = [
      '#4dc9f6',
      '#f67019',
      '#f53794',
      '#537bc4',
      '#acc236',
      '#166a8f',
      '#00a950',
      '#58595b',
      '#8549ba',
    ];
    return COLORS[index % COLORS.length];
  }

  getGraphData(productName, impInicial, impMensual, años, edad, type) {
    type = type || 'amount';
    let config = new ScenariosConfig(productName);
    let scenarios = this.getDeviations();

    // console.log('Simulator.getGraphData', {
    //   productName,
    //   impInicial,
    //   impMensual,
    //   años,
    //   edad,
    //   type,
    // });

    let graphData = { datasets: [] };
    let serieOpt = [];
    let seriePes = [];
    let serieExpFrom = [];
    let serieExpTo = [];
    let labels = [];
    for (let i = 1; i <= años; i++) {
      let dataOpt = this.projectScenario(
        config,
        scenarios.optimistic,
        edad,
        impInicial,
        impMensual,
        i,
      );
      let dataPes = this.projectScenario(
        config,
        scenarios.pessimistic,
        edad,
        impInicial,
        impMensual,
        i,
      );
      let dataExp = this.projectScenario(
        config,
        scenarios.expected,
        edad,
        impInicial,
        impMensual,
        i,
      );

      //console.log('datos', i, /*dataOpt, dataPes, */ dataExp);

      if (type === 'percent') {
        serieOpt.push(
          (dataOpt.to.profitWithFees * 100) / dataOpt.to.balance.amount,
        );
        seriePes.push(
          (dataPes.from.profitWithFees * 100) / dataPes.from.balance.amount,
        );
        serieExpFrom.push(
          (dataExp.from.profitWithFees * 100) / dataExp.from.balance.amount,
        );
        serieExpTo.push(
          (dataExp.to.profitWithFees * 100) / dataExp.to.balance.amount,
        );
      } else if (type === 'amount') {
        serieOpt.push(dataOpt.to.balance.amount);
        seriePes.push(dataPes.from.balance.amount);
        serieExpFrom.push(dataExp.from.balance.amount);
        serieExpTo.push(dataExp.to.balance.amount);
      } else {
        console.error('getGraphData Type no contemplado: ', type);
      }

      labels.push(i);
    }

    const chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
      blanco: '#ffffffba',
      blancoClaro: '#ffffff4a',
    };

    let configDefault = {
      type: 'line',
      pointRadius: 0,
      pointHoverRadius: 0,
      hiddenLegend: true,
      pointStyle: 'circle',

      //showLine: false, // no line shown
    };
    graphData.datasets.push({
      backgroundColor: chartColors.blanco, //this.getGraphtransparentize(chartColors.red),
      data: serieExpFrom,
      label: 'ExpectedFrom',
      //fill: '-1',
      fill: false,
      ...configDefault,
    });
    graphData.datasets.push({
      backgroundColor: chartColors.blanco, //this.getGraphtransparentize(chartColors.red),
      data: serieExpTo,
      label: 'ExpectedTo',
      fill: '-1',
      ...configDefault,
    });
    graphData.datasets.push({
      backgroundColor: chartColors.blancoClaro, //this.getGraphtransparentize(chartColors.red),
      data: serieOpt,
      label: 'OptimisticTo',
      fill: false,
      ...configDefault,
    });
    graphData.datasets.push({
      backgroundColor: chartColors.blancoClaro, //this.getGraphtransparentize(chartColors.red),
      data: seriePes,
      label: 'PessimisticFrom',
      fill: '-1',
      ...configDefault,
    });

    graphData.labels = labels;
    return graphData;
  }
}

function sumAnnualized_a(e, t) {
  var n = Math.pow(1 + Math.abs(e), t) - 1;
  // return 0 > e && (n *= -1), 100 * n;
  0 > e && (n *= -1);
  return 100 * n;
}

function sumAnnualized_n(e) {
  return sumAnnualized_i(e, 365.25);
}

function sumAnnualized_i(e, t) {
  var n = Math.pow(1 + 0.01 * Math.abs(e), 1 / t) - 1;
  // return 0 > e && (n *= -1), n;
  0 > e && (n *= -1);
  return n;
}

function sumAnnualized_t(e, t) {
  return e + t;
}
